import { CartesianGrid, YAxis, XAxis, Tooltip, ComposedChart, ResponsiveContainer, Label, TooltipProps } from 'recharts';
import { TypeMapChart } from './utils/helpers';
import { PlotComponentDictionary, TypePlotComponentLabels } from './types';
import { renderXLabel, renderYLabel } from './utils/labels';
import { Props as PropsLabel } from 'recharts/types/component/Label';
import { TypeLegend } from './components/Legends';
import { onRenderPlotComponents } from './utils/chart';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { TypeTooltip, buildTooltipPlotComponents, validateTooltipProps } from './utils/tooltip';
import { TypeTick, renderXTick, renderYTick } from './utils/tick';

interface ChartProps<TMetaData> {
  title: string,
  plotComponents: TypeMapChart<TMetaData>
  data: PlotComponentDictionary[]
  axisLabels: TypePlotComponentLabels
  isYAxisLabelHidden?: boolean
  isXAxisLabelHidden?: boolean
  onRenderTitleValue: (value: string) => JSX.Element
  onRenderYLabelValue: (value: string) => JSX.Element
  onRenderXLabelValue: (value: string) => JSX.Element
  onRenderYAxisTickValue: (value: string, index: number, length: number) => JSX.Element
  onRenderXAxisTickValue: (value: string, index: number, length: number) => JSX.Element
  onRenderLegendValues: (values: TypeLegend<TMetaData>[]) => JSX.Element
  onRenderTooltipPlotComponentValues: (payload: TypeTooltip<TMetaData>[]) => JSX.Element
}

export const Chart = <TMetaData extends unknown>({
  title,
  plotComponents,
  data,
  axisLabels,
  isXAxisLabelHidden = false,
  isYAxisLabelHidden = false,
  onRenderTitleValue,
  onRenderYLabelValue,
  onRenderXLabelValue,
  onRenderYAxisTickValue,
  onRenderXAxisTickValue,
  onRenderLegendValues,
  onRenderTooltipPlotComponentValues
}: ChartProps<TMetaData>) => {


  /* Handlers */
  const onRenderXlabel = (props: PropsLabel) => {
    return renderXLabel(props, onRenderXLabelValue)
  }

  const onRenderYlabel = (props: PropsLabel) => {
    return renderYLabel(props, onRenderYLabelValue)
  }

  const onRenderTooltip = (props: TooltipProps<ValueType, NameType>) => {
    const validatedProps = validateTooltipProps(props)
    const tooltipPlotComponents: TypeTooltip<TMetaData>[] = buildTooltipPlotComponents(validatedProps.payload, plotComponents, validatedProps.label)
    if (tooltipPlotComponents.length === 0) {
      return <></>
    }
    return onRenderTooltipPlotComponentValues(tooltipPlotComponents)
  }

  const onRenderXTick = (props: TypeTick) => {
    return renderXTick(props, onRenderXAxisTickValue)
  }

  const onRenderYTick = (props: TypeTick) => {
    return renderYTick(props, onRenderYAxisTickValue)
  }


  /* Vars */
  const legendValues: TypeLegend<TMetaData>[] = Array.from(plotComponents.values())
    .map((chart) => (
      { id: chart.yLabelId, value: chart.yLabel, metadata: chart.metadata }))

  const plotComponentValues = Array.from(plotComponents.values())

  return (
    <div className='h-full w-full flex flex-col '>
      {/* Title */}
      {onRenderTitleValue(title)}
      {/* Chart */}
      <div className='flex-1 relative'>
        <div className='absolute top-0 bottom-0 left-0 right-0'>
          <ResponsiveContainer debounce={1}>
            <ComposedChart
              width={500}
              height={400}
              data={data}
              margin={{
                left: isYAxisLabelHidden ? 24 : 8,
                right: 28,
                top: 12,
                bottom: isXAxisLabelHidden ? 24 : 48,
              }}>
              <CartesianGrid stroke="#DBDDDF" />
              <Tooltip content={onRenderTooltip} />
              {onRenderPlotComponents<TMetaData>(plotComponentValues)}
              <YAxis
                axisLine={{ stroke: 'none' }}
                tickLine={{ stroke: 'none' }}
                domain={['auto', 'auto']}
                tick={onRenderYTick}
              >
                {
                  !isYAxisLabelHidden &&
                  <Label
                    value={axisLabels.yLabel}
                    content={onRenderYlabel}
                  />}
              </YAxis>
              <XAxis
                dataKey={axisLabels.xLabelId}
                axisLine={{ stroke: 'none' }}
                tickLine={{ stroke: 'none' }}
                interval={0}
                tick={onRenderXTick}
              >
                {
                  !isXAxisLabelHidden &&
                  <Label
                    value={axisLabels.xLabel}
                    content={onRenderXlabel}
                  />
                }
              </XAxis>
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      {/* Legend */}
      {onRenderLegendValues(legendValues)}
    </div>
  )
}