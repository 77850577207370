import { useMemo, memo, useEffect, useCallback } from "react"
import { SingleSelectFilter } from "../../../../climateui/components"
import { ILabel } from "../../../../climateui/types"

import DASHBOARD_VARIABLES from "../dashboardVariables"
import { useTranslate } from "@tolgee/react"

import {
    useQueryParam,
    withDefault,
    StringParam,
    ArrayParam,
} from "use-query-params"
import { useParams } from "react-router-dom"
import { IDashboardLocation, IVariable } from "../../../../types"
import { useAuth } from "../../../../providers"
import { LocationPinIcon } from "../../../../climateui/icons"

// Widgets

import VariableChartWidget from "../components/widgetLibrary/VariableChartWidget"
import { getQueryParam } from "../../../../utils/queryParams"
import useDashboardLocations from "../../../../hooks/Dashboards/useDashboardLocations"
import useDashboardVarieties from "../../../../hooks/Dashboards/useDashboardVarieties"
import useDashboardRisks from "../../../../hooks/Dashboards/useDashboardRisks"
import GenericDashboardFilters from "../components/Filters/GenericDashboardFilters"
import ToggleSelector, {
    TToggleOptions,
} from "../../../../climateui/components/Inputs/ToggleSelector"
import { useDashboardActions } from "../components/DashboardActionsProvider"
import { FeatureFlag } from "../../../../components"
import { objectMap } from "../../../../utils/transform"
import _ from "lodash"
import csvActions from "../actions/csv"
import CalendarWidgetWrapper from "../components/widgetLibrary/CalendarWidgetWrapper"
import DashNameandDescription from "./DashNameandDescription"
import { useDashboard } from "../../../../providers/DashboardProvider"

import AlertsTableWidgetWrapper from "../components/widgetLibrary/AlertsTableWidgetWrapper"

import { useFlagValue } from "../../../../hooks"
import {
    DEFAULT_DASHBOARD_PROMISE_HARVESTER_DELAY,
    formatDashboardLabel,
} from "../utils"

import DoneLoading from "../components/DoneLoading"
import useWidgetLifecycleTracker from "../../../../climateui/hooks/useWidgetLifecycleTracker"
import {
    getTimeWindowByGranularity,
    TimeResolutionValues,
} from "../../Alerts/utils"

const variables: Record<string, IVariable> = DASHBOARD_VARIABLES
const MERGE_DATASETS = ["forecast", "~climatology"]
const DATASETS_RENAME = {
    climatology: "~climatology",
}

export interface ILocationDashboardProps {
    customLocations?: IDashboardLocation[]
}

// >>>>>>>
// CSV
// >>>>>>>
const processCSV = (
    csv: Record<string, Record<string, string>>,
    header: string
) =>
    objectMap<Record<string, string>>(csv, (csvObj) =>
        objectMap<string>(csvObj, (csvStr) => csvStr.replace(header, ""))
    )

function LocationDashboard(props: ILocationDashboardProps) {
    const { t } = useTranslate()
    // Custom locations come from GenericDashboardView
    // They get passed as props to Location and Regional IDashboard
    const { customLocations } = props
    const { dashboardId } = useParams()
    const { workingDashboard } = useDashboard()

    const { user } = useAuth()
    const unitSystem = user?.unit_type ? "units_metric" : "units_imperial"

    // >>>>>>>>>
    // Locations
    // >>>>>>>>>
    const { loadingLocations, allLocationsDict, customLocationsDict } =
        useDashboardLocations(customLocations, dashboardId === "location")

    const LocationParam = withDefault(
        StringParam,
        // custom locations dict already has non empty
        // location ids
        Object.keys(customLocationsDict)[0]
    )
    const [selectedLocation, setSelectedLocation] = useQueryParam(
        "locId",
        LocationParam
    )

    // >>>>>>>>>
    // Scope
    // >>>>>>>>>

    const scopesDict: Record<string, string> = {
        daily: t("daily"),
        weekly: t("weekly"),
        monthly: t("monthly"),
    }
    const ScopeParam = withDefault(StringParam, "daily")
    const [selectedScope, setSelectedScope] = useQueryParam("scope", ScopeParam)

    // >>>>>>>>>
    // Assets and Varieties filter
    // >>>>>>>>>

    const { varietiesDict } = useDashboardVarieties(
        customLocations,
        customLocationsDict,
        selectedLocation,
        allLocationsDict,
        dashboardId === "location"
    )

    const VarietiesArrayParam = withDefault(ArrayParam, [])
    const [selectedVarieties, setSelectedVarieties] = useQueryParam(
        "varieties",
        VarietiesArrayParam
    )

    // >>>>>>>>>
    // Variables
    // >>>>>>>>>

    // Toggle options
    // Process the variables dictionary
    const filteredVariablesDict: TToggleOptions = {}

    // convert variables dictionary into variables options dict
    Object.keys(variables).forEach((key) => {
        // Remove precipitation sum
        if (key === "precipitation_sum") return
        filteredVariablesDict[key] = {
            key,
            value: variables[key].name,
            disabled: key === "max_wind_speed" && selectedScope !== "daily",
        }
    })

    // State
    const VariableArrayParam = withDefault(ArrayParam, [] as string[])
    const [selectedVariables, setSelectedVariables] = useQueryParam(
        "variables",
        VariableArrayParam
    )

    useEffect(() => {
        if (selectedVariables.length === 0) {
            setSelectedVariables(["temp_max", "temp_min", "precipitation"])
        }
    }, [dashboardId])

    // >>>>>>>>>
    // Risk profiles & Risk settings
    // >>>>>>>>>

    const {
        alertSettingsByVariable,
        loadingRisks,
        loadingAlertSettings,
        alertSettingsIds,
    } = useDashboardRisks(
        selectedLocation,
        selectedVarieties,
        selectedVariables,
        variables,
        varietiesDict,
        dashboardId === "location"
    )

    // >>>>>>>>>
    // Set defaults
    // >>>>>>>>>

    useEffect(() => {
        // default varieties
        setSelectedVarieties(Object.keys(varietiesDict))
    }, [selectedLocation])

    // >>>>>>>>>
    // Dashboard Filters
    // >>>>>>>>>

    // Filters to apply to all widgets
    const dashboardFilters = useMemo(() => {

        const filteredVariables = selectedVariables.includes(
            "precipitation" as never
        )
            ? [...selectedVariables, "precipitation_sum"]
            : selectedVariables

        return [
            {
                propName: "hazard_variables",
                value: filteredVariables,
                loading: false,
            },
            {
                propName: "location_ids",
                value: [selectedLocation],
                loading: loadingLocations,
            },
            {
                propName: "risk_settings_ids",
                value: alertSettingsIds,
                loading: loadingRisks || loadingAlertSettings,
            },
            {
                propName: "time_resolution", 
                value: selectedScope as TimeResolutionValues,
                loading: false,
            },
            {
                propName: "unit_system",
                value: user?.unit_type ? "metric" : "imperial",
                loading: false,
            },
        ]
    }, [
        selectedVariables,
        !loadingLocations &&
            !!selectedLocation &&
            selectedLocation.length > 0 &&
            selectedLocation,
        alertSettingsIds,
        selectedScope,
        loadingRisks,
        loadingAlertSettings,
        user?.unit_type,
    ])

    // TODO make this behaviour dynamic in the future
    const availableTempVariables = ["temp_max", "temp_min"].filter(
        (v: string) =>
            selectedVariables.length === 0 ||
            (selectedVariables as string[]).includes(v)
    )

    const shownVariables = Object.values(variables)
        .filter(
            (variable: IVariable) =>
                variable.value !== "temp_min" &&
                variable.value !== "temp_max" &&
                variable.value !== "precipitation_sum"
        )
        .filter((variable) => {
            return (
                selectedVariables.length === 0 ||
                selectedVariables.includes(variable.value as never)
            )
        })

    const paginationParam = getQueryParam("pagination")
    const isPaginated = !paginationParam ? true : paginationParam === "true"

    const chartDisplayOptions = {
        1: { key: 1, value: "directForecast" },
        0: { key: 1, value: "forecastDist" },
    }

    const DirectionalityParam = withDefault(ArrayParam, ["0"] as string[])
    const [showDirectionalityData, setShowDirectionalityData] = useQueryParam(
        "directionality",
        DirectionalityParam
    )

    // Logic for chart display toggle
    useEffect(() => {
        if (!selectedScope || selectedScope == "daily") {
            setShowDirectionalityData(["0"])
        }
    }, [selectedScope])

    useEffect(() => {
        if (selectedScope == "daily" && showDirectionalityData[0] === "1") {
            setSelectedScope("weekly")
        }
    }, [showDirectionalityData[0]])

    let chartVisualization = selectedScope === "daily" ? "line" : "candle"
    let climatologyVisualization = selectedScope == "daily" ? "area" : "candle"

    if (
        ["monthly", "weekly"].includes(selectedScope) &&
        showDirectionalityData[0] === "1"
    ) {
        chartVisualization = "directionality"
        climatologyVisualization = "directionality_quantiles"
    }

    /* >>>>> ACTIONS >>>>> */
    const { csvs, globalCSVHeader, csvPrefix, registerActions } =
        useDashboardActions()

    useEffect(() => {
        // Register CSVs actions
        registerActions({
            ...csvActions,
            exportCurrLocationHistoricalData: async () => {
                const headlessCSV = await csvActions.exportHistoricalData(
                    selectedLocation,
                    t
                )
                const currentLocation = allLocationsDict[selectedLocation]
                const labels = currentLocation.labels as ILabel[]
                // If the current location is not available, just return the headless version
                if (!currentLocation) return [undefined, headlessCSV]
                // Add location/granularity header
                let header =
                    t("location", "Location") +
                    "," +
                    currentLocation.name +
                    "\n"
                // Add labels
                header +=
                    t("labels", "Labels") +
                    ',"' +
                    labels.map(({ name }) => name).join(",") +
                    '"\n'
                // Add resolution
                header +=
                    t("resolution", "Resolution") + "," + t("daily") + "\n"

                return [currentLocation.name, header + "\n" + headlessCSV]
            },
        })
    }, [selectedLocation])
    const handleCSVParse = useCallback(
        (key: string) =>
            (
                csv: Record<string, Record<string, string>>,
                csvCustomHeader: string
            ) => {
                csvPrefix.current = allLocationsDict[selectedLocation]?.name
                globalCSVHeader.current = csvCustomHeader
                csvs.current[key] = processCSV(csv, csvCustomHeader)
                // Clean-up
                return () => delete csvs.current[key]
            },
        [selectedLocation, selectedVariables, csvs.current]
    )

    // >>>>>>>>>
    // Widget lifecycle tracker
    // >>>>>>>>>

    const reportFlagDelay =
        (useFlagValue("feature_alert_dashboards_promise_harvester_delay") as
            | number
            | undefined) ?? DEFAULT_DASHBOARD_PROMISE_HARVESTER_DELAY

    const { settled, register, load } = useWidgetLifecycleTracker({
        delay: reportFlagDelay,
    })

    return (
        <div className="w-full h-full">
            {/* Dashboard print label (required) */}
            <div
                className="hidden"
                id="current-page-label">
                {formatDashboardLabel(
                    allLocationsDict?.[selectedLocation]?.name ?? "",
                    selectedScope
                )}
            </div>
            {/* PDF REPORTS DIV */}
            {settled && <DoneLoading />}
            <div className="sticky top-0 z-30">
                <DashNameandDescription workingDashboard={workingDashboard} />
                <div className="pb-[14px] w-full flex flex-wrap gap-2 -mt-5 align-baseline bg-gray-1.5">
                    <SingleSelectFilter
                        icon={<LocationPinIcon />}
                        placeholder={t("locations", "Locations")}
                        selected={selectedLocation}
                        setSelected={(v) => setSelectedLocation(v.toString())}
                        leftRightClass="left-0"
                        options={
                            {
                                ...customLocationsDict,
                            } as { [key: string]: string }
                        }
                        canSearch
                    />
                    {/* THIS COMPONENT WILL BE DEPRECATED AFTER NEW DASHBOARD FILTER IMPLEMENTATION.
                    DID A FEW CHANGES JUST SO THAT IT WOULDN'T CRASH WITH THE NEW RADIO SELECTOR COMPONENT*/}
                    <GenericDashboardFilters
                        // asset / variety filter
                        setSelectedVarieties={setSelectedVarieties}
                        varietiesDict={varietiesDict}
                        selectedVarieties={selectedVarieties as string[]}
                        // variable filter
                        variablesDict={filteredVariablesDict}
                        selectedVariables={selectedVariables as string[]}
                        updateSelectedVariables={(currElement: string) => {
                            if (selectedVariables.includes(currElement)) {
                                const updateElements = selectedVariables.filter(
                                    (v) => v != currElement
                                )
                                setSelectedVariables(updateElements)
                                return
                            }

                            setSelectedVariables([
                                ...selectedVariables,
                                currElement,
                            ])
                        }}
                        // scope filter
                        selectedScope={selectedScope}
                        setSelectedScope={(scope: string) => {
                            // if scope != daily, remove max windspeed from selected variables
                            if (["monthly", "weekly"].includes(scope))
                                setSelectedVariables(
                                    selectedVariables.filter(
                                        (v) => v !== "max_wind_speed"
                                    )
                                )

                            setSelectedScope(scope)
                        }}
                        scopesDict={scopesDict}
                    />
                    <ToggleSelector
                        options={chartDisplayOptions}
                        selectedOptions={showDirectionalityData as string[]}
                        updateSelectedOptions={(currElement: string) => {
                            if (showDirectionalityData.includes(currElement)) {
                                const additionalOptions = Object.keys(
                                    chartDisplayOptions
                                ).filter((key) => key != currElement)
                                setShowDirectionalityData([
                                    additionalOptions[0],
                                ])
                                return
                            }
                            setShowDirectionalityData([currElement])
                        }}
                    />
                </div>
            </div>

            {/* NEW CALENDAR WIDGET */}
            <div className="break-inside-avoid break-after-auto mb-[14px]">
                <FeatureFlag flags={["feature_dashboard_calendar_widget"]}>
                    <CalendarWidgetWrapper
                        id="alert_calendar_widget"
                        onMount={register}
                        onLoad={load}
                        dashboardFilters={dashboardFilters}
                        selectors={{
                            title: t("alertsCalendarWidgetTitle"),
                            isPaginated,
                            columns: [],
                            $data: "alerts.results",
                        }}
                    />
                </FeatureFlag>
            </div>

            {/* Min and Max Temp Chart */}
            {(selectedVariables.length === 0 ||
                (selectedVariables as string[]).includes("temp_min") ||
                (selectedVariables as string[]).includes("temp_max")) && (
                <div
                    className="w-full mb-[14px] break-inside-avoid break-after-auto"
                    key={"temp_min_max"}>
                    <VariableChartWidget
                        id={`variable_chart_widget_temp_min_max`}
                        onMount={register}
                        onLoad={load}
                        selectors={{
                            title: t(
                                "minMaxTempChartTitle",
                                "Min and Max Temperature Chart"
                            ),
                            labelY: t(
                                "minAndMaxTempChartAxis",
                                `Min and Max Temperature ({ unit })`,
                                {
                                    unit: variables[availableTempVariables[0]]
                                        .chartConfig[unitSystem],
                                }
                            ),
                            units: variables[availableTempVariables[0]]
                                .chartConfig[unitSystem],
                            groupCandles: true,
                            centerPoints: ["weekly", "monthly"].includes(
                                selectedScope
                            ),
                            granularity: selectedScope,
                            directionalChart: showDirectionalityData[0] === "1",
                        }}
                        actions={{
                            setCustomHeader: (prevHeader: string) => {
                                return prevHeader
                            },
                            onCSVParse: handleCSVParse(_.uniqueId("variable_")),
                            datasetsRename: DATASETS_RENAME,
                            mergeDatasets: MERGE_DATASETS,
                        }}
                        dashboardFilters={[
                            // TODO make this dynamic in the future
                            dashboardFilters[1], // location filter
                            dashboardFilters[3], // time_resolution filter
                            dashboardFilters[4], // units filter
                            {
                                propName: "config",
                                value: availableTempVariables
                                    .map((tempVariable) => {
                                        const res = [
                                            {
                                                variable: tempVariable,
                                                dataset: "forecast",
                                                visualization:
                                                    chartVisualization,
                                                color: variables[tempVariable]
                                                    .chartConfig?.color,
                                            },
                                            {
                                                variable: tempVariable,
                                                color: [
                                                    "weekly",
                                                    "monthly",
                                                ].includes(selectedScope)
                                                    ? "#CCCCCC"
                                                    : variables[tempVariable]
                                                          .chartConfig?.color,
                                                visualization:
                                                    climatologyVisualization,
                                                dataset: "climatology",
                                            },
                                        ]

                                        return res
                                    })
                                    .flat(),
                            },
                            {
                                propName: "granularity",
                                value: selectedScope,
                            },
                            {
                                propName: "risk_settings_ids",
                                value: [
                                    ...(!selectedVariables ||
                                    availableTempVariables.includes("temp_max")
                                        ? alertSettingsByVariable["temp_max"]
                                        : []),
                                    ...(!selectedVariables ||
                                    availableTempVariables.includes("temp_min")
                                        ? alertSettingsByVariable["temp_min"]
                                        : []),
                                ],
                                loading: loadingRisks || loadingAlertSettings,
                            },
                        ]}
                    />
                </div>
            )}

            {shownVariables.map((variable) => {
                return (
                    <div
                        className="w-full mb-[14px] break-inside-avoid break-after-auto"
                        // Addition of hard coded (for now) title and Ylabel for precipitation
                        // Hopefully this will be changed in the future :)
                        key={variable.value}>
                        <VariableChartWidget
                            id={`variable_chart_widget_${variable.value}`}
                            onMount={register}
                            onLoad={load}
                            actions={{
                                translationFn: t,
                                datasetsRename: DATASETS_RENAME,
                                mergeDatasets: MERGE_DATASETS,
                                setCustomHeader: (prevHeader: string) => {
                                    return prevHeader
                                },
                                onCSVParse: handleCSVParse(
                                    _.uniqueId("variable_")
                                ),
                            }}
                            selectors={{
                                title: `${
                                    variable.value == "precipitation" &&
                                    selectedScope == "weekly"
                                        ? t("weeklyAggregatedPrecChartTitle")
                                        : variable.value == "precipitation" &&
                                          selectedScope == "monthly"
                                        ? t("monthlyAggregatedPrecChartTitle")
                                        : t(variable.label)
                                }`,
                                labelY: `${
                                    variable.value == "precipitation" &&
                                    selectedScope == "weekly"
                                        ? t("weeklyAggregatedPrec")
                                        : variable.value == "precipitation" &&
                                          selectedScope == "monthly"
                                        ? t("monthlyAggregatedPrec")
                                        : t(variable.label)
                                } (${variable.chartConfig[unitSystem]})`,
                                units: variable.chartConfig[unitSystem],
                                groupCandles: true,
                                centerPoints: ["weekly", "monthly"].includes(
                                    selectedScope
                                ),
                                granularity: selectedScope,
                                directionalChart:
                                    showDirectionalityData[0] === "1",
                            }}
                            dashboardFilters={[
                                // TODO make this dynamic in the future
                                dashboardFilters[1], // location filter
                                dashboardFilters[3], // time_resolution filter
                                dashboardFilters[4], // units filter
                                {
                                    propName: "config",
                                    value: [
                                        {
                                            variable: variable.value,
                                            color: variable.chartConfig.color,
                                            visualization: chartVisualization,
                                            dataset: "forecast",
                                        },
                                        {
                                            variable: variable.value,
                                            color: [
                                                "weekly",
                                                "monthly",
                                            ].includes(selectedScope)
                                                ? "#CCCCCC"
                                                : variables[variable.value]
                                                      .chartConfig?.color,
                                            visualization:
                                                climatologyVisualization,
                                            dataset: "climatology",
                                        },
                                    ],
                                    loading: false,
                                },
                                {
                                    propName: "granularity",
                                    value: selectedScope,
                                },
                                {
                                    propName: "risk_settings_ids",
                                    value: alertSettingsByVariable[
                                        variable.value
                                    ],
                                    loading:
                                        loadingRisks || loadingAlertSettings,
                                },
                            ]}
                        />
                    </div>
                )
            })}
            <div className="w-full mb-[14px]">
                <AlertsTableWidgetWrapper
                    id="alert_summary_table_widget"
                    onMount={register}
                    onLoad={load}
                    dashboardFilters={dashboardFilters}
                    selectors={{
                        title: t("alertSummaryTableWidgetTitle"),
                        isPaginated,
                    }}
                />
            </div>
        </div>
    )
}

export default memo(LocationDashboard)
