import { useTranslate } from "@tolgee/react"
import { useEffect, useState } from "react"
import { useCustomFlags } from "./flagsmith"

const usePaginationFlag = ({
    flagName = "feature_locations_pagination_options",
    rowTolgeeKey = "rows",
}: {
    flagName?: string
    rowTolgeeKey?: string
}) => {
    const { t } = useTranslate()

    const [pageSizeOpts, setPageSizeOpts] = useState<{ [key: number]: string }>(
        {
            10: `10 ${t(rowTolgeeKey, "rows").toLowerCase()}`,
            25: `25 ${t(rowTolgeeKey, "rows").toLowerCase()}`,
            50: `50 ${t(rowTolgeeKey, "rows").toLowerCase()}`,
        }
    )

    const { [flagName]: fsPageSizeOpts } = useCustomFlags([flagName])
    // const { [flagName]: fsPageSizeOpts } = useFlags([flagName]) // To test prod behavior

    useEffect(() => {
        try {
            // Update pagination page size options
            const arr = JSON.parse(fsPageSizeOpts.value as string) as number[]
            if (!arr || arr.length === 0) return
            // Reset pagination
            setPageSizeOpts(
                arr.reduce((prev: { [key: number]: string }, curr: number) => {
                    return {
                        ...prev,
                        [curr]: `${curr} ${t(
                            rowTolgeeKey,
                            "rows"
                        ).toLowerCase()}`,
                    }
                }, {})
            )
        } catch {
            // do nothing
        }
    }, [fsPageSizeOpts.value])

    return pageSizeOpts
}

export default usePaginationFlag
