import { useTranslate } from "@tolgee/react"
import {
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
} from "react"
import { Button } from "../../../../../climateui/components"
import { useOutsideComponentClickHandler } from "../../../../../climateui/hooks"
import { CancelIcon } from "../../../../../climateui/icons"
import { ToastContext } from "../../../../../climateui/providers"
import EmptyModal from "../../../../../climateui/providers/Modal/EmptyModal"
import { IBooleanDictionary } from "../../../../../climateui/types"

/* This component has potential to be used in other views other than assets */
const TemplateModal = ({
    children,
    isOpen,
    setIsOpen,
    title,
    onConfirm,
    cleanUp,
    selected,
    setSelected,
}: {
    children: ReactNode
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
    title: string
    onConfirm: () => Promise<unknown>
    cleanUp: () => void
    selected: IBooleanDictionary
    setSelected: Dispatch<SetStateAction<IBooleanDictionary>>
}) => {
    /* HOOKS > START */
    const modalRef = useOutsideComponentClickHandler(() => setIsOpen(false))
    const { t } = useTranslate()
    const { enqueueAlert } = useContext(ToastContext)
    /* HOOKS < END */

    /* LIFEHOOKS > START */
    useEffect(() => {
        if (!isOpen) cleanUp()
    }, [isOpen])
    /* LIFEHOOKS < END */

    return (
        <EmptyModal
            customStyle={{
                height: "auto",
            }}
            open={isOpen}
            modalRef={modalRef}>
            {/* HEADER */}
            <div className="flex flex-row items-center justify-between px-2 py-4 shrink grow-0">
                <h2 className="title-sm">{title}</h2>
                <span
                    role="button"
                    className="w-6 h-6 cursor-pointer fill-gray-60"
                    onClick={() => setIsOpen(false)}>
                    <CancelIcon />
                </span>
            </div>
            {/* BODY */}
            <div className="flex p-2 grow-0">{children}</div>
            {/* FOOTER */}
            <div className="flex flex-row justify-end p-2 shrink grow-0 gap-2">
                <Button
                    type="secondary"
                    label={t("cancel", "Cancel")}
                    onClick={() => setIsOpen(false)}
                />
                <Button
                    type="primary"
                    label={t("save", "Save")}
                    onClick={() =>
                        onConfirm().then(() => {
                            cleanUp()
                            setIsOpen(false)
                            enqueueAlert(
                                t(
                                    "nUpdated",
                                    "{count, plural, =1 {Updated successfully} other {{count} items updated successfully}}",
                                    {
                                        count: Object.keys(selected).length,
                                    }
                                )
                            )
                            setSelected({})
                        })
                    }
                />
            </div>
        </EmptyModal>
    )
}

export default TemplateModal
