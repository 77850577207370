import { ReactNode, RefObject } from "react"
import { ICoordinates } from "../../../types"
import { IMapHeatmapPoint, IStyleHeatmapConfig } from "./heatmap"
import { IMapLine, IStyleLinesConfig } from "./lines"
import { IMapPin } from "./pins"
import mapboxgl from "../mapboxgl"
import { colors } from "../../../utils/colors"

export const MAP_DEFAULT_STYLE =
    "mapbox://styles/geramv/cl5rcg0vj004k14pmk1c8wjsa"

export interface IMapStyleToggler {
    nextStyle: string
    styleMiniImgPath?: string
    // Map style dependant logic:
    heatmapConfig?: IStyleHeatmapConfig
    linesConfig?: IStyleLinesConfig
}

export type MapStyleToggler = Record<string, IMapStyleToggler>

export const MAP_STYLE_TOGGLER: MapStyleToggler = {
    "mapbox://styles/geramv/cl5k2k0ms001414ni9wxhbpsd": {
        nextStyle: MAP_DEFAULT_STYLE,
        styleMiniImgPath: "/images/dark-mini-map.png",
        heatmapConfig: {
            color: colors.cyan.DEFAULT,
        },
        linesConfig: {
            color: colors.gray[30],
        },
    },
    [MAP_DEFAULT_STYLE]: {
        nextStyle: "mapbox://styles/geramv/cl5k2k0ms001414ni9wxhbpsd",
        styleMiniImgPath: "/images/light-mini-map.png",
        heatmapConfig: {
            color: "#4CBFAD",
        },
        linesConfig: {
            color: colors.gray[60],
        },
    },
}

interface IMapInitializer {
    mapContainerRef: RefObject<HTMLDivElement>
    layer?: string
    center?: ICoordinates
    zoom?: number
    minZoom?: number
    maxZoom?: number
    renderWorldCopies?: boolean
}

export interface IMap {
    pins?: IMapPin[]
    heatmap?: IMapHeatmapPoint[]
    lines?: IMapLine[]
    children?: ReactNode
    mapDefaultStyle?: string
    mapStyleToggler?: MapStyleToggler
    canToggleMapStyle?: boolean
    styleChangeCallback?: (style: string) => void
    mapConfigs?: Partial<IMapInitializer>
    searchPlaceholder?: string
    coords?: ICoordinates
    setCoords?: (coords: ICoordinates) => void
    language?: string
    boundingBoxCallback?: (bounds?: mapboxgl.LngLatBounds) => void
    actionsDelay?: number
    doFitToPins?: boolean
    animate?: boolean
    hasControls?: boolean
    hasGeocoder?: boolean
    customFlyTo?: ICoordinates
    lockLatLon?: boolean
}

export const initializeMap = ({
    mapContainerRef,
    layer = MAP_DEFAULT_STYLE,
    center = { lat: 0, lon: 25 },
    zoom = 1.4,
    minZoom = 0,
    maxZoom = 15,
    renderWorldCopies = true,
}: IMapInitializer) => {
    if (!mapContainerRef || !mapContainerRef.current) return
    const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: layer,
        center,
        zoom,
        minZoom,
        maxZoom,
        renderWorldCopies,
        dragRotate: false,
        preserveDrawingBuffer: true,
        attributionControl: false, // Not working
    })
    map.dragRotate.disable()
    map.touchZoomRotate.disableRotation()

    map.on("load", () => {
        map.resize()
    })

    return map
}

export const removeSource = (map: mapboxgl.Map, sourceId: string) => {
    try {
        if (map.getSource(sourceId)) map.removeSource(sourceId)
    } catch {
        // Do nothing
    }
}

export const removeLayer = (map: mapboxgl.Map, layerId: string) => {
    try {
        if (map.getLayer(layerId)) map.removeLayer(layerId)
    } catch {
        // Do nothing
    }
}
