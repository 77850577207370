import * as React from "react"
import { SVGProps } from "react"

const MaxTemperatureIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="fill-inherit"
        {...props}>
        <path
            d="M18 13V5a3 3 0 0 0-6 0v8a5 5 0 1 0 6 0Zm-3-9a1 1 0 0 1 1 1v2h-2V5a1 1 0 0 1 1-1Z"
            fill={props.fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 11v2H6v-2H4V9h2V7h2v2h2v2H8Z"
            fill={props.fill}
        />
    </svg>
)

export default MaxTemperatureIcon
