import { FC, ReactElement } from 'react'

export type TypeLegend<TMetaData> = {
    id: string
    value: string
    metadata: TMetaData
}

export interface DefaultLegend {
    id: string
    value: string
    icon?: ReactElement
}

export const DefaultLegend: FC<DefaultLegend> = ({ id, value, icon }) => {
    return (
        <li key={id} className='flex justify-center items-center space-x-2'>
            <span>{icon && icon}</span>
            <span className='body-sm text-gray-90'>{value}</span>
        </li>
    )
}

export interface DefaultLegendsProps {
    values: DefaultLegend[]
}

export const DefaultLegends: FC<DefaultLegendsProps> = ({ values }) => {
    return (
        <div className='ml-16'>
            <ul className='px-16 flex flex-wrap space-x-4 justify-center items-center'>
                {values.map((value) => <DefaultLegend id={value.id} value={value.value} icon={value.icon} />)}
            </ul>
        </div>

    )
}

/* Helper functions */
export const onRenderDefaultLegends = (values: DefaultLegend[]) => {
    return (
        <DefaultLegends values={values} />
    )
}