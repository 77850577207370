import { useMemo } from "react"
import { WdgtCSVDict, IWdgtDataSeries, WdgtJSONData } from "../../types/widget"
import Papa from "papaparse"
import { PrimitiveAny } from "../../types"

const VARIABLE_UNIT_SEP = ":"
const ZERO_WIDTH_CHARS = /[\u200B-\u200D\uFEFF]/g

const prefixKeys = (obj: object, keyPrefix?: string) => {
    if (!obj) return {}

    return Object.entries(obj).reduce(
        (
            newObj: Record<string | number | symbol, PrimitiveAny>,
            [_key, val]
        ) => {
            let key = _key
            if (keyPrefix) key = `${keyPrefix}_${_key}`
            newObj[key] = val
            return newObj
        },
        {}
    )
}

const useCSV = ({
    datasets,
    mergeDatasets = [],
    datasetsRename = {},
    translationFn = (x) => x,
    setCustomHeader = (x) => x + "\n",
}: {
    datasets: IWdgtDataSeries[]
    mergeDatasets: string[]
    datasetsRename: Record<string, string>
    translationFn: (key: string) => string
    setCustomHeader: (oldHeader: string) => string
}) => {
    const getResolutionHeader = (granularity: string) => {
        return `${translationFn("resolution")}:,${translationFn(granularity)}\n`
    }
    const getVariableHeader = (_variable: string) => {
        const [resolution, variable, units] = _variable.split(VARIABLE_UNIT_SEP)
        const variableWithResolution = `${resolution}_${variable}`
        let header = ""
        header += `${translationFn("variable")}:,${translationFn(
            variableWithResolution
        )}\n`
        header += `${translationFn("variable_unit")}:,${translationFn(units)}\n`
        return header
    }
    const sortAndTranslateData = (
        jsonData: Record<string, Record<string, PrimitiveAny>>,
        key: string
    ) =>
        Object.keys(jsonData[key])
            .sort((a, b) => {
                if (b === "date" || a > b) return 1
                return -1
            })
            .reduce((prev: Record<string, PrimitiveAny>, childKey) => {
                prev[
                    translationFn(
                        childKey.replace(ZERO_WIDTH_CHARS, "")
                    ).replace(ZERO_WIDTH_CHARS, "")
                ] = jsonData[key][childKey]
                return prev
            }, {})
    return useMemo(() => {
        if (!datasets) return
        const seriesJSON = datasets.reduce((prev: WdgtJSONData, curr) => {
            const {
                granularity,
                dataset: __dataset,
                variable: _variable,
                units,
                points,
            } = curr
            const _dataset = datasetsRename[__dataset] ?? __dataset
            const dataset = mergeDatasets.includes(_dataset)
                ? mergeDatasets[0]
                : _dataset
            const variable = `${granularity}${VARIABLE_UNIT_SEP}${_variable}${VARIABLE_UNIT_SEP}${units}`
            points.forEach((point) => {
                // Initiate the granularity entry
                if (!prev[granularity]) {
                    prev[granularity] = {
                        [dataset]: { [variable]: {} },
                    }
                }
                // Initiate the dataset entry, for this granularity
                if (!prev[granularity][dataset]) {
                    prev[granularity][dataset] = {
                        [variable]: {},
                    }
                }
                // Initiate the variable entry, for this dataset
                if (!prev[granularity][dataset][variable]) {
                    prev[granularity][dataset][variable] = {
                        [point.date]: {},
                    }
                }
                prev[granularity][dataset][variable][point.date] = {
                    date: point.date,
                    ...prev[granularity][dataset][variable][point.date],
                    ...prefixKeys(point.attributes, _dataset),
                }
            })
            return prev
        }, {})
        return Object.entries(seriesJSON).reduce(
            (prev: WdgtCSVDict, [granularity, datasetsDict]) => {
                // Initiate granularity dict if it doesn't exist
                if (!prev[granularity]) prev[granularity] = {}
                const resolutionHeader = getResolutionHeader(granularity) + "\n"
                Object.entries(datasetsDict).forEach(
                    ([dataset, variablesDict]) => {
                        let csvStr = setCustomHeader(resolutionHeader)
                        Object.entries(variablesDict).forEach(
                            ([variable, jsonData]) => {
                                Object.keys(jsonData).forEach((dateKey) => {
                                    // Sort and translate JSONData just the first one
                                    jsonData[dateKey] = sortAndTranslateData(
                                        jsonData,
                                        dateKey
                                    )
                                })
                                // Get the headers for the variable
                                const variableHeader =
                                    getVariableHeader(variable)
                                // Append this variable parsed CSV
                                csvStr += `${variableHeader}${Papa.unparse(
                                    Object.values(jsonData)
                                )}\n\n`
                            }
                        )
                        prev[granularity][dataset] = csvStr
                    }
                )
                return prev
            },
            {}
        )
    }, [datasets])
}
export default useCSV
