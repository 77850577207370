import { useTranslate } from "@tolgee/react"
import { Dispatch, SetStateAction, useMemo } from "react"
import { IconSelect } from "../climateui/components/Inputs"
import MaxTemperatureIcon from "../climateui/icons/variableIcons/MaxTemperatureIcon"
import MeanHumidityIcon from "../climateui/icons/variableIcons/MeanHumidityIcon"
import MeanTemperatureIcon from "../climateui/icons/variableIcons/MeanTemperatureIcon"
import MinTemperatureIcon from "../climateui/icons/variableIcons/MinTemperatureIcon"
import SumPrecipitationIcon from "../climateui/icons/variableIcons/SumPrecipitation"
import {
    EvapotranspirationIcon,
    PrecipitationIcon,
    SoilTemperatureIcon,
    SolarRadiationIcon,
    WindSpeedIcon,
} from "../climateui/icons/weatherVariableIcons"
import { IBooleanDictionary } from "../climateui/types"

const VariablesIconSelect = ({
    selectedVariables,
    setSelectedVariables,
    enableMultiSelect,
    availableVariables = [],
    hiddenVariables = [],
}: {
    selectedVariables: IBooleanDictionary
    setSelectedVariables: Dispatch<SetStateAction<IBooleanDictionary>>
    enableMultiSelect: boolean
    availableVariables?: string[]
    hiddenVariables?: string[]
}) => {
    const { t } = useTranslate()

    const { availableVariablesSet, hiddenVariablesSet } = useMemo(() => {
        let availableVariablesSet = new Set()
        let hiddenVariablesSet = new Set()
        // you either have available variables or hidden variables
        if (availableVariables.length > 0)
            availableVariablesSet = new Set(availableVariables)
        else if (hiddenVariables.length > 0)
            hiddenVariablesSet = new Set(hiddenVariables)

        return { availableVariablesSet, hiddenVariablesSet }
    }, [hiddenVariables, availableVariables])

    const variableOpts = useMemo(() => {
        return Object.fromEntries(
            Object.entries({
                temp_max: {
                    label: t("maxTemperature", "Max Temperature"),
                    icon: <MaxTemperatureIcon />,
                },
                temp_min: {
                    label: t("minTemperature", "Min Temperature"),
                    icon: <MinTemperatureIcon />,
                },
                temp_mean: {
                    label: t("meanTemperature", "Mean Temperature"),
                    icon: <MeanTemperatureIcon />,
                },
                precipitation: {
                    label: t("precipitation", "Precipitation"),
                    icon: <PrecipitationIcon />,
                },
                precipitation_sum: {
                    label: t("sumPrecipitation", "Sum Precipitation"),
                    icon: <SumPrecipitationIcon />,
                },
                soil_temperature: {
                    label: t("soilTemperature", "Soil Temperature"),
                    icon: <SoilTemperatureIcon />,
                },
                humidity: {
                    label: t("meanHumidity", "Mean Humidity"),
                    icon: <MeanHumidityIcon />,
                },
                solar_radiation: {
                    label: t("solarRadiation", "Solar Radiation"),
                    icon: <SolarRadiationIcon />,
                },
                evapotranspiration: {
                    label: t("evapotranspiration", "Evapotranspiration"),
                    icon: <EvapotranspirationIcon />,
                },
                wind_speed: {
                    label: t("windSpeed", "Wind Speed"),
                    icon: <WindSpeedIcon />,
                },
            }).filter(
                ([key]) =>
                    (availableVariablesSet.size > 0 &&
                        availableVariablesSet.has(key)) ||
                    (hiddenVariablesSet.size > 0 &&
                        !hiddenVariablesSet.has(key)) ||
                    (availableVariablesSet.size === 0 &&
                        hiddenVariablesSet.size === 0)
            )
        )
    }, [availableVariablesSet, hiddenVariablesSet])
    return (
        <IconSelect
            options={variableOpts}
            enableMultiSelect={enableMultiSelect}
            selection={selectedVariables}
            setSelection={setSelectedVariables}
        />
    )
}
export default VariablesIconSelect
