import { ReactNode } from "react"

const graphLibrary: Record<string, () => ReactNode> = {
    OneTimeConsecutive: () => (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 80 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 72L79 72"
                stroke="#DBDDDF"
            />
            <path
                d="M53 72L79 72"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 72H26"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="26.5"
                y1="72"
                x2="26.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="65.5"
                y1="72"
                x2="65.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="13.5"
                y1="72"
                x2="13.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="52.5"
                y1="72"
                x2="52.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="39.5"
                y1="71"
                x2="39.5"
                y2="-2.13643e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="15.5"
                x2="79"
                y2="15.5"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="43.5"
                x2="79"
                y2="43.5"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="0.5"
                x2="79"
                y2="0.5"
                stroke="#F2F3F3"
            />
            <line
                x1="78.5"
                y1="72"
                x2="78.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <path
                d="M1 72L0.999998 36L0.999996 -3.21865e-06"
                stroke="#DBDDDF"
            />
            <path
                d="M52 72L40 17"
                stroke="#A3C4F3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="4 4"
            />
            <path
                d="M27 72L40 17"
                stroke="#A3C4F3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="4 4"
            />
            <circle
                cx="40"
                cy="16"
                r="3"
                fill="#E42437"
            />
        </svg>
    ),
    ConstantConsecutive: () => (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 80 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 72L79 72"
                stroke="#DBDDDF"
            />
            <path
                d="M1 72H40.2453"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="26.5"
                y1="72"
                x2="26.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="65.5"
                y1="72"
                x2="65.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="13.5"
                y1="72"
                x2="13.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="52.5"
                y1="72"
                x2="52.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="39.5"
                y1="71"
                x2="39.5"
                y2="-2.13643e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="43.5"
                x2="79"
                y2="43.5"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="15.5"
                x2="79"
                y2="15.5"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="0.5"
                x2="79"
                y2="0.5"
                stroke="#F2F3F3"
            />
            <line
                x1="78.5"
                y1="72"
                x2="78.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <path
                d="M1 72L0.999998 36L0.999996 -3.21865e-06"
                stroke="#DBDDDF"
            />
            <path
                d="M40 72.5V17"
                stroke="#A3C4F3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="4 4"
            />
            <path
                d="M40.5 16H79"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="40"
                cy="16"
                r="3"
                fill="#E42437"
            />
        </svg>
    ),
    OneTimeRolling: () => (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 80 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 72L79 72"
                stroke="#DBDDDF"
            />
            <path
                d="M53 72L79 72"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 72H26"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="26.5"
                y1="72"
                x2="26.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="65.5"
                y1="72"
                x2="65.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="13.5"
                y1="72"
                x2="13.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="52.5"
                y1="72"
                x2="52.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="39.5"
                y1="71"
                x2="39.5"
                y2="-2.13643e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="15.5"
                x2="79"
                y2="15.5"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="43.5"
                x2="79"
                y2="43.5"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="0.5"
                x2="79"
                y2="0.5"
                stroke="#F2F3F3"
            />
            <line
                x1="78.5"
                y1="72"
                x2="78.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <path
                d="M1 72L0.999998 36L0.999996 -3.21865e-06"
                stroke="#DBDDDF"
            />
            <path
                d="M52 72L40 17"
                stroke="#A3C4F3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="4 4"
            />
            <path
                d="M27 72L40 17"
                stroke="#A3C4F3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="4 4"
            />
            <circle
                cx="40"
                cy="16"
                r="3"
                fill="#E42437"
            />
        </svg>
    ),
    ConstantRolling: () => (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 79 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <line
                x1="26.5"
                y1="72"
                x2="26.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="65.5"
                y1="72"
                x2="65.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="13.5"
                y1="72"
                x2="13.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="52.5"
                y1="72"
                x2="52.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="39.5"
                y1="71"
                x2="39.5"
                y2="-2.13643e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="43.5"
                x2="79"
                y2="43.5"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="15.5"
                x2="79"
                y2="15.5"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="0.5"
                x2="79"
                y2="0.5"
                stroke="#F2F3F3"
            />
            <line
                x1="78.5"
                y1="72"
                x2="78.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <path
                d="M1 72L79 72"
                stroke="#DBDDDF"
            />
            <path
                d="M1 72L0.999998 36L0.999996 -3.21865e-06"
                stroke="#DBDDDF"
            />
            <path
                d="M14 71V17"
                stroke="#A3C4F3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="4 4"
            />
            <path
                d="M39 71V17"
                stroke="#A3C4F3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="4 4"
            />
            <path
                d="M53 71V17"
                stroke="#A3C4F3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="4 4"
            />
            <path
                d="M1 72H14"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M39 72H53"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14 16L39 16"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M53 16L78 16"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="14"
                cy="16"
                r="3"
                fill="#E42437"
            />
            <circle
                cx="53"
                cy="16"
                r="3"
                fill="#E42437"
            />
        </svg>
    ),
    ConstantSurplus: () => (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 82 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <line
                x1="65.5"
                y1="72"
                x2="65.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="26.5"
                y1="72"
                x2="26.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="43.5"
                x2="79"
                y2="43.5"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="15.5"
                x2="79"
                y2="15.5"
                stroke="#F2F3F3"
            />
            <path
                d="M1 72L79 72"
                stroke="#DBDDDF"
            />
            <line
                x1="13.5"
                y1="72"
                x2="13.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="52.5"
                y1="72"
                x2="52.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="39.5"
                y1="71"
                x2="39.5"
                y2="-2.13643e-08"
                stroke="#F2F3F3"
            />
            <line
                x1="1"
                y1="0.5"
                x2="79"
                y2="0.5"
                stroke="#F2F3F3"
            />
            <line
                x1="78.5"
                y1="72"
                x2="78.5"
                y2="-2.11646e-08"
                stroke="#F2F3F3"
            />
            <path
                d="M1 72L0.999998 36L0.999996 -3.21865e-06"
                stroke="#DBDDDF"
            />
            <path
                d="M13 44L39 16"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M52 44L78 16"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="26"
                cy="30"
                r="3"
                fill="#AC22CF"
            />
            <circle
                cx="65"
                cy="30"
                r="3"
                fill="#AC22CF"
            />
            <path
                d="M13 72L13 44"
                stroke="#A3C4F3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="4 4"
            />
            <path
                d="M52 72L52 44"
                stroke="#A3C4F3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="4 4"
            />
            <path
                d="M40 72L40 16"
                stroke="#A3C4F3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="4 4"
            />
            <circle
                cx="13"
                cy="44"
                r="3"
                fill="#E42437"
            />
            <circle
                cx="52"
                cy="44"
                r="3"
                fill="#E42437"
            />
            <path
                d="M1 72H13"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M40 72H52"
                stroke="#196CE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="40"
                cy="15"
                r="3"
                fill="#AC22CF"
            />
            <circle
                cx="79"
                cy="15"
                r="3"
                fill="#AC22CF"
            />
        </svg>
    ),
}
const ImpactGraph = ({ graph }: { graph: string }) => {
    const normGraph = graph.replace(/\.\w{2,3}$/, "")
    return <>{graphLibrary[normGraph]()}</>
}
export default ImpactGraph
