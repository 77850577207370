import { Row, Table } from "@tanstack/react-table"
import { useTranslate } from "@tolgee/react"
import { useMemo } from "react"
import { isValidResponse } from "../climateui/utils/http"
import { useCoordsRegionQuery } from "../hooks"
import { IInsightsLocation } from "../types"

export const getTableSelectStatus = <T,>(table: Table<T>) => {
    if (table.getIsAllRowsSelected()) return "full"
    if (table.getIsSomeRowsSelected()) return "half"
    return "empty"
}

export const getRowSelectStatus = <T,>(row: Row<T>): string => {
    if (row.getIsSelected()) return "full"
    if (row.getIsSomeSelected()) return "half"
    return "empty"
}

// Receives a location (latitude, longitude, region)
// if the location already has region we show it
// else we use the coords to get the region
export function RegionCell({
    partialLocation,
}: {
    partialLocation: Partial<IInsightsLocation>
}) {
    const { t } = useTranslate()

    const { latitude: lat, longitude: lon, region } = partialLocation

    const { data, isLoading, isFetching } = useCoordsRegionQuery({
        enabled: !region?.full_name,
        lat,
        lon,
    })

    const actualRegion = useMemo(() => {
        // For null and undefined
        if (lat == null || lon == null) return ""

        if (region?.full_name) return region?.full_name

        if (isLoading || isFetching) return t("loading", "Loading...")

        if (!data) return ""
        if (!isValidResponse(data)) return ""

        return data.data.full_name
    }, [region, data])

    return <>{actualRegion}</>
}
