import dashboardVariables from "../../../../views/Seasonal/Dashboards/dashboardVariables"

function ChartLegends(props) {
    const { label, t } = props
    {
        return (
            <div>
                {label.vis == "line" ||
                label.vis == "candle" ||
                label.vis == "directionality" ? (
                    <div className="flex items-center">
                        <div
                            style={{
                                backgroundColor: label.color,
                            }}
                            className="w-5 h-1 mx-2 border-0 rounded"></div>
                        <h1 className="m-1">
                            {label.customText ??
                                `${dashboardVariables[label.name].name} 
                                    ${t(label.type)}`}
                        </h1>
                    </div>
                ) : label.opacity == 0.2 && label.vis == "area" ? (
                    <div className="flex">
                        <div
                            style={{
                                backgroundColor: label.color,
                                opacity: label.opacity,
                            }}
                            className="p-2 m-2"></div>
                        <h1 className="m-1">{t("HistorialAvg90")}</h1>
                    </div>
                ) : label.opacity.toFixed(1) == 0.3 && label.vis == "area" ? (
                    <div className="flex">
                        <div
                            style={{
                                backgroundColor: label.color,
                                opacity: label.opacity,
                            }}
                            className="p-2 m-2"></div>
                        <h1 className="m-1">{t("HistorialAvg50")}</h1>
                    </div>
                ) : (
                    ""
                )}
            </div>
        )
    }
}

export default ChartLegends
