import { ReactNode } from "react"
import { useAccount } from "../../../../providers/AccountProvider"
import { IDashboard } from "../../../../types"

const TableDashboardView = ({
    children,
    workingDashboard,
}: {
    children: ReactNode | ReactNode[]
    workingDashboard: IDashboard
}) => {
    const { accountsObject, selectedAccount } = useAccount()
    let accountName
    if (accountsObject[selectedAccount as string]) {
        accountName = accountsObject[selectedAccount as string].name
    }

    const date = new Date()
    const todaysDate =
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()

    return (
        <>
            <div className="w-full ml-9 h-[25mm] fixed top-0 ">
                <img
                    alt="pdf_banner"
                    src="/images/dash_PDF_banner.svg"
                    className="scale-110 ml-4"></img>
                <h1 className="font-robot my-5 text-[18px] text-white absolute z-50 top-0 right-12">
                    {accountName}, {todaysDate}
                </h1>
            </div>

            <div className="w-full justify-center fixed bottom-0 h-[15mm] py-3 text-center text-[#4CBFAD] font-medium tracking-[0.195em] uppercase font-outfit bg-[#004E5D]">
                Climate.Ai
            </div>
            <table className="w-full break-inside-auto">
                <thead>
                    <div className="h-[25mm]"></div>
                </thead>
                <tbody>
                    <div className="break-inside-auto flex-col items-center p-8">
                        {children}
                    </div>
                </tbody>
                <tfoot>
                    {workingDashboard?.dtype == "Location" ? (
                        <div className="w-full h-[15mm]"></div>
                    ) : (
                        <div className="w-full h-[35mm]"></div>
                    )}
                </tfoot>
            </table>
        </>
    )
}

export default TableDashboardView
