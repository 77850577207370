import { GraphQLError } from "graphql"
import { useQuery } from "react-query"
import getGraphqlClient from "../../../climateui/utils/http/graphqlClient"
import {
    IDecadalStat,
    ISeasonalStat,
    IYieldOutlookResponse,
} from "../../../types"

export type DashboardType = "location" | "region"

type PastReportsResults = IYieldOutlookResponse<ISeasonalStat | IDecadalStat>[]
type PastReportsRawResponse = Record<
    string,
    {
        results: PastReportsResults
    }
>
const queries = {
    location: `#graphql
    query (
        $territoryIDs: [String],
        $initTime: String
        $units: String
        $startDate: String
        $endDate: String
    ) {
        # Location Dashboard Rolling Outlook Table
        outlook: yield_locations (filter: { location_ids: $territoryIDs }) {
            results {
                territory: location { id, name }
                stats: seasonal_stats(filter: {
                    init_time: $initTime
                    startDate: $startDate
                    endDate: $endDate
                    units: $units
                }) {
                    results {
                        init_time
                        outlook_mean
                    }
                }
            }
        }
    }
    `,
    region: `#graphql
    query (
        $territoryIDs: [String],
        $assetIDs: [String],
        $startDate: String,
        $initTime: String,
        $endDate: String,
        $units: String
    ) {
        # Regional Dashboard Rolling Outlook Table
        outlook: yield_outlook_model(
                filter: {
                    region_ids: $territoryIDs
                    asset_ids: $assetIDs
                }
        ) {
            results {
                territory: region { id, name }
                stats: seasonal_stats(
                    filter: {
                        init_time: $initTime
                        endDate: $endDate
                        startDate: $startDate
                        units: $units
                    }
                ) {
                    results {
                        init_time
                        outlook_mean
                    }
                }
            }
        }
    }
`,
}
export const useReports = ({
    statsType = "seasonal",
    dashboardType = "region",
    filters,
}: {
    statsType?: string
    dashboardType?: DashboardType
    filters?: {
        territoryIDs?: string[]
        assetIDs?: string[]
        startDate?: string
        initTime?: string
        endDate?: string
        units?: string
    }
}) =>
    useQuery<
        PastReportsRawResponse | undefined,
        GraphQLError,
        PastReportsResults | undefined
    >({
        queryKey: ["rollingYieldOutlookReports", statsType, filters],
        queryFn: async (): Promise<PastReportsRawResponse | undefined> => {
            const client = getGraphqlClient()

            try {
                return await client.request({
                    document: queries[dashboardType],
                    variables: filters,
                })
            } catch (err) {
                console.error("ROLLING YIELD OUTLOOK TABLE ERROR", err)
            }
        },
        select: (response) => response?.outlook.results,
        enabled: !!filters?.territoryIDs && filters.territoryIDs.length > 0,
    })
