import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { Routes, Route, BrowserRouter } from "react-router-dom"
import {
    AccountProvider,
    AuthGuard,
    AuthProvider,
    CustomReportsProvider,
    LocationsProvider,
    UIProvider,
    SudoProvider,
    PendoWrapper,
    UnitConversionProvider,
    LocalizationProvider,
    LabelsProvider,
    AssetsProvider,
    RiskProfilesProvider,
    SkinProvider,
    AnalogsQueueProvider,
    DashboardProvider,
} from "./providers"
import { ModalProvider, ToastProvider } from "./climateui/providers"
import { config, queryClient } from "./utils"
import {
    Login,
    RestorePasswordConfirmation,
    RestorePasswordEmail,
    RestorePasswordNewPassword,
    NotFound,
} from "./views"
import { AppLayout } from "./layouts"
import { protectedRoutes, renderAppRoutes } from "./utils/appRoutes"
import { Tolgee, TolgeeProvider, DevBackend, DevTools } from "@tolgee/react"
import { FormatIcu } from "@tolgee/format-icu"
import { QueryParamProvider } from "use-query-params"
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6"
import flagsmith from "flagsmith"
import { FlagsmithProvider } from "flagsmith/react"
import { OnboardingGuard } from "./providers/AuthGuards"
import ErrorBoundary from "./views/ErrorBoundary"

const tolgee = Tolgee()
    .use(FormatIcu())
    .use(DevBackend())
    .use(DevTools())
    .init(config.tolgee_config)

const tolgeeFallback = (
    <div className="w-full h-full bg-white grid place-content-center">
        <div className="loading-screen__loader" />
        <h1 className="montserrat text-[50px] text-gray-60"></h1>
    </div>
)

const App = () => {
    return (
        <div className="w-full h-screen">
            <ModalProvider>
                <BrowserRouter>
                    <QueryParamProvider
                        adapter={ReactRouter6Adapter}
                        options={{
                            enableBatching: true,
                            removeDefaultsFromUrl: false,
                        }}>
                        <QueryClientProvider client={queryClient}>
                            <ReactQueryDevtools initialIsOpen={false} />
                            <TolgeeProvider
                                tolgee={tolgee}
                                fallback={tolgeeFallback}>
                                <FlagsmithProvider
                                    options={config.flagsmith_config}
                                    flagsmith={flagsmith}>
                                    <LocalizationProvider>
                                        <AnalogsQueueProvider>
                                            <SkinProvider>
                                                <UIProvider>
                                                    <AuthProvider>
                                                        <SudoProvider>
                                                            <ToastProvider>
                                                                <ErrorBoundary>
                                                                    <Routes>
                                                                        <Route
                                                                            path="login"
                                                                            element={
                                                                                <Login />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="restore-password"
                                                                            element={
                                                                                <RestorePasswordEmail />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="restore-password/code"
                                                                            element={
                                                                                <RestorePasswordNewPassword />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="restore-password/confirmation"
                                                                            element={
                                                                                <RestorePasswordConfirmation />
                                                                            }
                                                                        />

                                                                        {/* INFO: Notice the AuthGuard Over all protectedRoutes */}
                                                                        <Route
                                                                            path="/"
                                                                            element={
                                                                                <AuthGuard>
                                                                                    {/* INFO: Here we'll inject all providers needed from the protected routes */}
                                                                                    <AccountProvider>
                                                                                        <AssetsProvider>
                                                                                            <OnboardingGuard>
                                                                                                <RiskProfilesProvider>
                                                                                                    <PendoWrapper>
                                                                                                        <LabelsProvider>
                                                                                                            <LocationsProvider>
                                                                                                                <CustomReportsProvider>
                                                                                                                    <UnitConversionProvider>
                                                                                                                        <DashboardProvider>
                                                                                                                            <AppLayout />
                                                                                                                        </DashboardProvider>
                                                                                                                    </UnitConversionProvider>
                                                                                                                </CustomReportsProvider>
                                                                                                            </LocationsProvider>
                                                                                                        </LabelsProvider>
                                                                                                    </PendoWrapper>
                                                                                                </RiskProfilesProvider>
                                                                                            </OnboardingGuard>
                                                                                        </AssetsProvider>
                                                                                    </AccountProvider>
                                                                                </AuthGuard>
                                                                            }>
                                                                            {renderAppRoutes(
                                                                                protectedRoutes
                                                                            )}
                                                                        </Route>
                                                                        <Route
                                                                            path="*"
                                                                            element={
                                                                                <NotFound
                                                                                    showLogo
                                                                                />
                                                                            }
                                                                        />
                                                                    </Routes>
                                                                </ErrorBoundary>
                                                            </ToastProvider>
                                                        </SudoProvider>
                                                    </AuthProvider>
                                                </UIProvider>
                                            </SkinProvider>
                                        </AnalogsQueueProvider>
                                    </LocalizationProvider>
                                </FlagsmithProvider>
                            </TolgeeProvider>
                        </QueryClientProvider>
                    </QueryParamProvider>
                </BrowserRouter>
            </ModalProvider>
        </div>
    )
}

export default App
