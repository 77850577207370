import { SVGProps } from "react"

const StackedVariablesIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 22 22"
        className="fill-inherit"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M10.082 5.04207H11.9154V8.70874H10.082V5.04207Z"
            fill={props.fill}
        />
        <path
            d="M11.9154 10.0846H10.082V11.918H11.9154V10.0846Z"
            fill={props.fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6463 0.649345C11.2883 0.291365 10.7079 0.291364 10.3499 0.649345L2.93841 8.06087C2.58043 8.41885 2.58043 8.99925 2.93841 9.35723L10.3499 16.7688C10.7079 17.1267 11.2883 17.1267 11.6463 16.7688L19.0578 9.35723C19.4158 8.99925 19.4158 8.41885 19.0578 8.06087L11.6463 0.649345ZM17.1133 8.70905L10.9981 2.59389L4.88295 8.70905L10.9981 14.8242L17.1133 8.70905Z"
            fill={props.fill}
        />
        <path
            d="M4.085 12.4147L10.9993 19.329L17.9136 12.4147L19.21 13.7111L11.6475 21.2736C11.4756 21.4455 11.2424 21.5421 10.9993 21.5421C10.7562 21.5421 10.523 21.4455 10.3511 21.2736L2.78864 13.7111L4.085 12.4147Z"
            fill={props.fill}
        />
    </svg>
)

export default StackedVariablesIcon
