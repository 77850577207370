import { useTranslate } from "@tolgee/react"
import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useQueries, UseQueryOptions } from "react-query"
import { NavLink, Outlet, useLocation, useSearchParams } from "react-router-dom"
import {
    IMapHeatmapPoint,
    IMap,
    IMapLine,
    IMapPin,
    MAP_DEFAULT_STYLE,
} from "../../../climateui/components/Map/utils"
import { IRoute } from "../../../climateui/types"
import { CAIResponse, isCustomResponse } from "../../../climateui/utils/http"
import getVariableIcon from "../../../climateui/utils/icons/getVariableIcon"
import { GenericPageHeader } from "../../../components"
import { HalfMapLayout } from "../../../layouts"
import { useAnalogsQueue } from "../../../providers"
import { useAccount } from "../../../providers/AccountProvider"
import { useAuthNavigation } from "../../../providers/AuthGuards"
import {
    ExplorationToolContext,
    useExplorationTool,
} from "../../../providers/ExplorationToolProvider"
import { LocalizationContext } from "../../../providers/LocalizationProvider"
import { LocationsContext } from "../../../providers/LocationsProvider"
import { IAnalog, IAnalogsSearchPayload } from "../../../types"
import { HOUR_MS } from "../../../utils/constants"
import { pointRegionPOST } from "../../../utils/networking"
import { explorationChildRoutes } from "../routes"
import {
    SearchBySimilarityMapLegend,
    formatAnalogsMonths,
    getExplorationAnalogMarkerPopup,
    getExplorationLocationsMarkerPopup,
    isValidAnalog,
    ExploreToolMyLocationsMapLegend,
} from "./explorationUtils"

const explorationPaths = ["/climate/exploration", "/climate/exploration/"]

function ExplorationToolTabs() {
    const { t } = useTranslate()
    return (
        <ul className="flex items-center w-full -mx-1 gap-5">
            {explorationChildRoutes.map((route: IRoute) => {
                return (
                    <li key={"subRoute-" + route.path}>
                        <NavLink
                            className={({ isActive }) =>
                                (isActive
                                    ? "text-accent font-bold border-accent"
                                    : "text-gray-90 border-transparent") +
                                " flex flex-row items-center w-full" +
                                " body-md pb-2 px-1 border-b-2" +
                                " whitespace-nowrap overflow-hidden"
                            }
                            to={route.path || ""}>
                            <span className="truncate">
                                {route.labelKey
                                    ? t(route.labelKey)
                                    : route.label || "FIX"}
                            </span>
                        </NavLink>
                    </li>
                )
            })}
        </ul>
    )
}

function ExplorationToolLayout() {
    const location = useLocation()
    const { selectedAccount } = useAccount()
    const { navigateToAllowed } = useAuthNavigation()
    const { rememberedAnalogsSearch, setRememberedAnalogsSearch } =
        useAnalogsQueue()
    const setParams = useSearchParams()[1]

    useEffect(() => {
        if (explorationPaths.includes(location.pathname) && selectedAccount)
            navigateToAllowed(explorationChildRoutes, "/climate/exploration")
    }, [selectedAccount, location])

    const { t } = useTranslate()
    const { monthNames } = useContext(LocalizationContext)
    const { filteredPageLocationPins, locations } = useContext(LocationsContext)
    const { currentMapStyleToggler, customFlyTo } = useContext(
        ExplorationToolContext
    )
    const {
        navigateToSearchBySimilarity,
        selectedLocationPin,
        mapStyleToggler,
        setCurrenMapStyleToggler,
    } = useExplorationTool()

    const { pins: pageLocationsPins }: IMap = useMemo(() => {
        const newPins: IMapPin[] = [...filteredPageLocationPins]
        newPins.forEach((pin) => {
            pin.data["regionName"] =
                pin.data?.region?.full_name || t("region", "Region")
            pin.data["popupText"] = t(
                "clickPinToSearchSimilarLocations",
                "Click pin to search similar locations"
            )
            pin.events = (marker) => ({
                click: () => {
                    marker.togglePopup() // Not sure why this is needed
                    if (rememberedAnalogsSearch)
                        setRememberedAnalogsSearch(undefined)
                    navigateToSearchBySimilarity(pin.id + "")
                },
                mouseenter: () => marker.togglePopup(),
                mouseleave: () => marker.togglePopup(),
            })
            pin.popup = getExplorationLocationsMarkerPopup
            pin.pinStyle = "map-pin.png"
        })
        return { pins: newPins }
    }, [filteredPageLocationPins])

    const [payload, setPayload] = useState<IAnalogsSearchPayload>()
    const [analogs, setAnalogs] = useState<IAnalog[]>([]) // In case we want to export

    useEffect(() => {
        if (location.pathname.includes("locations")) {
            setPayload(undefined)
            setAnalogs([])
        }
    }, [location])

    useEffect(() => {
        if (!payload && analogs.length === 0 && rememberedAnalogsSearch) {
            const loc = locations.find((loc) => {
                return (
                    loc.latitude === rememberedAnalogsSearch.lat &&
                    loc.longitude === rememberedAnalogsSearch.lon
                )
            })
            if (loc?.id) setParams({ locationId: loc.id })
            setPayload(rememberedAnalogsSearch)

            if (loc && !location.pathname.includes("search-by-similarity")) {
                setTimeout(() => {
                    navigateToSearchBySimilarity(loc?.id + "")
                }, 100)
                // Not clearing timeout because it was causing unwanted behavior
                // return () => clearTimeout(timeout)
            }
        }
    }, [rememberedAnalogsSearch, location.pathname])

    const buildPinFromAnalog = (analog: IAnalog): IMapPin => {
        return {
            ...analog,
            pinStyle: "map-pin.png",
            data: {
                ...analog,
            },
            popup: getExplorationAnalogMarkerPopup,
            events(marker) {
                return {
                    mouseenter: () => marker.togglePopup(),
                    mouseleave: () => marker.togglePopup(),
                }
            },
        }
    }
    const buildLineFromPin = (
        payload: IAnalogsSearchPayload,
        pin: IMapPin
    ): IMapLine => {
        return {
            points: [{ ...payload }, { ...pin }],
        }
    }

    const buildHeatmapFromAnalog = (analog: IAnalog): IMapHeatmapPoint => {
        return { ...analog }
    }

    const {
        analogsPins,
        lines,
        heatmap,
        analogsRows,
        regionQueries,
        analogsPerformed,
    } = useMemo<{
        analogsPins: IMapPin[]
        lines: IMapLine[]
        heatmap: IMapHeatmapPoint[]
        analogsRows: IAnalog[]
        regionQueries: UseQueryOptions[]
        analogsPerformed: boolean
    }>(() => {
        const analogsPins: IMapPin[] = []
        const lines: IMapLine[] = []
        const heatmap: IMapHeatmapPoint[] = []
        const analogsRows: IAnalog[] = []
        const regionQueries: UseQueryOptions[] = []
        let analogsPerformed = false

        const NUMBER_OF_ANALOGS = 10
        if (payload) {
            analogs.forEach((analog) => {
                heatmap.push(buildHeatmapFromAnalog(analog))

                if (
                    analogsRows.length < NUMBER_OF_ANALOGS &&
                    payload.exclusionRadius != null &&
                    isValidAnalog(
                        payload,
                        analogsPins,
                        analog,
                        +payload.exclusionRadius || 0,
                        true // always metric since its
                        // select just change UI and not value
                    )
                ) {
                    analogsPins.push(buildPinFromAnalog(analog))
                    analogsRows.push(analog)
                    const { lat, lon } = analog
                    regionQueries.push({
                        queryKey: ["pointRegion", lat, lon],
                        queryFn: () => pointRegionPOST({ lat, lon }),
                        staleTime: HOUR_MS,
                    })
                }
            })
            analogsPins.forEach((pin) => {
                lines.push(buildLineFromPin(payload, pin))
            })
            analogsPerformed = true
        }

        return {
            analogsPins,
            lines,
            heatmap,
            analogsRows,
            regionQueries,
            analogsPerformed,
        }
    }, [analogs, payload])

    const regionQueriesResults = useQueries(regionQueries)

    const variableNames = {
        t2m_max: t("maxTemperature", "Max Temperature"),
        t2m_min: t("minTemperature", "Min Temperature"),
        tp: t("precipitation", "Precipitation"),
    } as Record<string, string>

    const variableIcons = {
        t2m_max: getVariableIcon("t2m_max"),
        t2m_min: getVariableIcon("t2m_min"),
        tp: getVariableIcon("tp"),
    } as Record<string, ReactNode>

    const actualAnalogsPins = useMemo(() => {
        const color = currentMapStyleToggler.heatmapConfig?.color || "white"
        return regionQueriesResults.map((result, index) => {
            const newPin = {
                ...analogsPins[index],
                data: {
                    ...analogsPins[index].data,
                    averageSimilarity: t(
                        "averageSimilarity",
                        "Average Similarity"
                    ),
                    similarityScore: t("similarityScore", "Similarity Score"),
                    variableNames,
                    variableIcons,
                    color,
                },
            }
            if (result.isLoading || result.isFetching || !result.data)
                return newPin

            const response = result.data as CAIResponse
            if (!response || !isCustomResponse(response)) return newPin

            return {
                ...newPin,
                data: {
                    ...newPin.data,
                    regionName: response.data.full_name,
                },
            }
        })
    }, [regionQueriesResults, currentMapStyleToggler])

    const mapSelectedLocationAndAnalogsPins = useMemo(() => {
        if (!selectedLocationPin) return []

        let decades = ""
        if (payload?.reference_decades) {
            decades =
                payload.reference_decades[0] +
                "-" +
                payload.reference_decades[1]
        }
        const newSelectedLocationPin = {
            ...selectedLocationPin,
            data: {
                ...selectedLocationPin.data,
                ...payload,
                decades,
                months: formatAnalogsMonths(
                    payload?.months || [1, 12],
                    monthNames
                ),
            },
        }
        return [newSelectedLocationPin, ...actualAnalogsPins]
    }, [selectedLocationPin, actualAnalogsPins, payload])

    const { pins, legend } = useMemo(() => {
        if (
            location.pathname.includes("search-by-similarity") &&
            actualAnalogsPins.length !== 0
        )
            return {
                pins: mapSelectedLocationAndAnalogsPins,
                legend: <SearchBySimilarityMapLegend />,
            }
        return {
            pins: pageLocationsPins,
            legend: <ExploreToolMyLocationsMapLegend />,
        }
    }, [pageLocationsPins, mapSelectedLocationAndAnalogsPins, location])

    return (
        <HalfMapLayout
            contentWidthClasses="lg:w-[666px] w-full flex flex-col"
            mapProps={{
                pins,
                lines,
                heatmap,
                customFlyTo,
                mapDefaultStyle: MAP_DEFAULT_STYLE, // same as provider
                mapStyleToggler,
                styleChangeCallback(style) {
                    setCurrenMapStyleToggler(mapStyleToggler[style])
                },
                mapConfigs: {
                    minZoom: 1.1,
                    maxZoom: 18,
                    renderWorldCopies: false,
                },
                actionsDelay: 400,
                children: legend,
            }}>
            <div className="px-5 pt-3 grow-0 shrink-0">
                <GenericPageHeader
                    extraClasses="min-h-[30px]"
                    right={null}
                    bottom={<ExplorationToolTabs />}
                    pageTitle={t("locationDiscovery", "Location Discovery")}
                />
            </div>
            <div className="overflow-hidden grow">
                <Outlet
                    context={{
                        setPayload,
                        setAnalogs,
                        analogsRows,
                        payload,
                        analogsPerformed,
                    }}
                />
            </div>
        </HalfMapLayout>
    )
}

export default ExplorationToolLayout
