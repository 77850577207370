import {
    ReactNode,
    createContext,
    useContext,
    useState,
    useMemo,
    useEffect,
} from "react"
import { useQueries, UseQueryOptions } from "react-query"
import { CAIResponse, isCustomResponse } from "../climateui/utils/http"
import { IAnalogsSearchPayload } from "../types"
import { HOUR_MS, POLLING_DEFAULT_TIMEOUT } from "../utils/constants"
import { analogsGET } from "../utils/networking"

interface IAnalogsQueueContext {
    createAnalogsQuery: (
        location: string,
        payload: IAnalogsSearchPayload
    ) => void

    rememberedAnalogsSearch?: IAnalogsSearchPayload
    setRememberedAnalogsSearch: React.Dispatch<
        React.SetStateAction<IAnalogsSearchPayload | undefined>
    >
}

const AnalogsQueueContext = createContext<IAnalogsQueueContext>(
    {} as IAnalogsQueueContext
)
export const useAnalogsQueue = () => useContext(AnalogsQueueContext)

interface IAnalogsPerformed {
    location: string
    payload: IAnalogsSearchPayload
    query: UseQueryOptions
}

function AnalogsQueueProvider({ children }: { children: ReactNode }) {
    const [rememberedAnalogsSearch, setRememberedAnalogsSearch] =
        useState<IAnalogsSearchPayload>()

    const [analogsQueue, setAnalogsQueue] = useState<
        Record<string, IAnalogsPerformed>
    >({})

    const queries = useMemo(() => {
        return Object.values(analogsQueue).reduce(
            (acc: UseQueryOptions[], curr: IAnalogsPerformed) => [
                ...acc,
                curr.query,
            ],
            []
        )
    }, [analogsQueue])

    const results = useQueries(queries)

    const removeAnalogsQuery = (location: string) => {
        setAnalogsQueue((prev) => {
            const newPrev = { ...prev }
            delete newPrev[location]
            return newPrev
        })
    }

    useEffect(() => {
        const cleaning: (() => void)[] = []
        results.forEach((result, index) => {
            if (result.isLoading || result.isFetching || !result.data) return

            const response = result.data as CAIResponse
            if (!response || !isCustomResponse(response)) return

            if (response.data.location) {
                const timeout = setTimeout(() => {
                    result.refetch()
                }, +response.data.retryAfter * 1000 || POLLING_DEFAULT_TIMEOUT)
                cleaning.push(() => clearTimeout(timeout))
            } else {
                // TOAST that results are ready
                // (MAYBE a SessionNotificationsProvider)
                const queryKey = queries[index].queryKey
                if (queryKey) {
                    removeAnalogsQuery(queryKey[1] as string)
                }
            }
        })
        return () => cleaning.forEach((clean) => clean())
    }, [results])

    const createAnalogsQuery = (
        location: string,
        payload: IAnalogsSearchPayload
    ) => {
        setAnalogsQueue((prev) => ({
            ...prev,
            [location]: {
                location,
                payload,
                query: {
                    queryKey: ["analogs", location],
                    queryFn: () => analogsGET(location),
                    staleTime: HOUR_MS,
                },
            },
        }))
    }

    const providerValue = useMemo(
        () => ({
            createAnalogsQuery,
            rememberedAnalogsSearch,
            setRememberedAnalogsSearch,
        }),
        [rememberedAnalogsSearch]
    )

    return (
        <AnalogsQueueContext.Provider value={providerValue}>
            {children}
        </AnalogsQueueContext.Provider>
    )
}

export default AnalogsQueueProvider
