import * as React from "react"
import { SVGProps } from "react"

const MeanSoilTemperatureIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-inherit"
        viewBox="0 0 25 24"
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.676 20H2.5v2h5.77a6.577 6.577 0 0 1-1.594-2Zm10.055 2H22.5v-2h-4.176a6.576 6.576 0 0 1-1.593 2ZM19.05 17h3.45v-2h-3.762c.203.63.311 1.302.311 2ZM6.26 15a6.546 6.546 0 0 0-.31 2H2.5v-2h3.761Z"
            fill={props.fill}
        />
        <path
            d="M15.5 13V5a3 3 0 0 0-6 0v8a5 5 0 1 0 6 0Zm-3-9a1 1 0 0 1 1 1v4h-2V5a1 1 0 0 1 1-1Z"
            fill={props.fill}
        />
    </svg>
)

export default MeanSoilTemperatureIcon
