import { useQuery } from "react-query"
import { isEmpty } from "lodash"
import { IAccount } from "../types"
import { accountLocationsGRAPHQL } from "../utils/networking"

const MAX_QUERY_LOCATION_TRIES = 3

const useLocationsQuery = (
    selectedAccount: string | undefined,
    accountsObject: Record<string, IAccount>
) => {
    const { data, isLoading, isFetching, error, isError } = useQuery({
        queryKey: ["locations", selectedAccount, accountsObject],
        queryFn: () => {
            if (
                !selectedAccount ||
                isEmpty(accountsObject) ||
                !accountsObject[selectedAccount]
            )
                return

            const account_ids = [selectedAccount]
            accountsObject[selectedAccount]?.children?.forEach((child) =>
                account_ids.push(child.id as string)
            )

            return accountLocationsGRAPHQL(account_ids)
        },
        enabled: selectedAccount !== undefined || !isEmpty(accountsObject),
        retry: MAX_QUERY_LOCATION_TRIES,
    })

    return { data, error, isLoading, isFetching, isError }
}

export default useLocationsQuery

