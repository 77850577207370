import { useTranslate } from "@tolgee/react"
import { useAssets } from "../../../../providers"
import { columns } from "../RiskOverviewTableUtils"
import { IRiskCountryOverviewModel, IRiskOverview } from "../types"
import { Table } from "../../../../climateui/components"
import { useMemo } from "react"
import { RISK_BOUNDS } from "../riskOutlookUtils"

interface RiskOverviewWidgetProps {
    data: IRiskCountryOverviewModel[]
    loading: boolean
}

const RiskOverviewWidget: React.FC<RiskOverviewWidgetProps> = ({
    data,
    loading,
}) => {
    const { t } = useTranslate()
    const { allAssets } = useAssets()

    const processedData = useMemo(() => {
        if (!data) return []

        // get max risk score for each hazard
        const assetModelsRelationObj: Record<
            string,
            IRiskCountryOverviewModel[]
        > = {}

        data.forEach((riskModel: IRiskCountryOverviewModel) => {
            let max_value = -Infinity

            riskModel.default_geography.hazards.forEach((hazard) => {
                const current_value =
                    hazard.risk_outlook_seasonal_stats.plot[0].lastValue.y
                max_value = Math.max(max_value, current_value)
            })

            const assetId = riskModel.asset_id

            if (!assetModelsRelationObj[assetId])
                assetModelsRelationObj[assetId] = []

            assetModelsRelationObj[assetId].push({
                ...riskModel,
                highest_risk_score: max_value,
                bounds: RISK_BOUNDS,
            })
        })

        return Object.entries(assetModelsRelationObj)
            .map(([assetId, modelsArray]) => ({
                asset_id: assetId,
                asset_name: allAssets ? allAssets[assetId]?.name || "" : "",
                asset_models: modelsArray.sort(
                    (a, b) => b.highest_risk_score - a.highest_risk_score
                ),
            }))
            .sort(
                (a, b) =>
                    b.asset_models[0].highest_risk_score -
                    a.asset_models[0].highest_risk_score
            )
    }, [data, allAssets])

    return (
        <Table<IRiskOverview>
            data={processedData}
            columns={columns}
            noDataMessage={
                loading ? t("loadingCountryModels") : t("noModelsAvailable")
            }
        />
    )
}

export default RiskOverviewWidget
