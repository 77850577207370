const CollapseAllIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <g clipPath="url(#clip0_2664_7420)">
                <path
                    d="M7.40997 18.59L8.82997 20L12 16.83L15.17 20L16.58 18.59L12 14L7.40997 18.59ZM16.59 5.41L15.17 4L12 7.17L8.82997 4L7.40997 5.41L12 10L16.59 5.41Z"
                    fill={props.fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_2664_7420">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CollapseAllIcon
