import { useTimeline } from ".."

const daysPerWeek = 7

function Week({
    weekNumber,
    customWidth = daysPerWeek,
    showWeekNumber = false,
    whiteColorPercentage = 100,
}: {
    weekNumber: number
    customWidth?: number
    showWeekNumber?: boolean
    whiteColorPercentage?: number
}) {
    const { pxPerDay } = useTimeline()

    const showLabel = showWeekNumber ? customWidth > 5 : false

    return (
        <div
            className={
                "flex flex-row items-center justify-center h-full shrink-0 grow-0"
            }
            style={{
                width: pxPerDay * customWidth + "px",
                background:
                    "linear-gradient(90deg, white " +
                    whiteColorPercentage +
                    "%, #f7f8f8 0%)",
            }}>
            {showLabel && (
                <span className="font-roboto tracking-[0.4px] text-[10px] text-gray-60">
                    <>{weekNumber % 52 === 0 ? 52 : weekNumber % 52}</>
                </span>
            )}
        </div>
    )
}

function WeeklyTimeline({
    showWeekNumber = false,
}: {
    showWeekNumber?: boolean
}) {
    const { weeksOffset, weekNumbers } = useTimeline()

    return (
        <div
            className={
                "relative h-full flex flex-row items-center divide-x divide-gray-5/90 z-10"
            }>
            {weeksOffset > 0 && (
                <Week
                    weekNumber={weekNumbers[0] === 1 ? 52 : weekNumbers[0] - 1}
                    customWidth={weeksOffset}
                    showWeekNumber={showWeekNumber}
                />
            )}
            {weekNumbers.map((weekNumber: number, index: number) => {
                const customWidth =
                    index + 1 === weekNumbers.length && weeksOffset > 0
                        ? daysPerWeek - weeksOffset
                        : daysPerWeek

                return (
                    <Week
                        key={"week-" + weekNumber}
                        weekNumber={weekNumber}
                        customWidth={customWidth}
                        showWeekNumber={showWeekNumber}
                        whiteColorPercentage={100}
                    />
                )
            })}
        </div>
    )
}

export default WeeklyTimeline
