import { RefObject, useEffect, useState } from "react"
import { BoundingRect, EMPTY_RECT } from "../types/utility"
import { getRect } from "../utils/dom"

const rectToString = ({ x, y, width, height }: BoundingRect) =>
    `(${x.toFixed(2)},${y.toFixed(2)},${width.toFixed(2)},${height.toFixed(2)})`

const isEqualRect = (a: BoundingRect, b: BoundingRect) =>
    rectToString(a) === rectToString(b)

const useObserveBoundingRect = (
    ref: RefObject<HTMLElement | null>,
    opts: {
        events?: string[]
    } = {
        events: [],
    }
) => {
    const [rect, setRect] = useState<BoundingRect>(EMPTY_RECT)

    const rectCallback = (prevRect: BoundingRect) => {
        const newRect = getRect(ref) ?? EMPTY_RECT
        const isEqual = isEqualRect(prevRect, newRect)

        if (isEqual) return prevRect
        return newRect
    }

    const _update = () => {
        setRect(rectCallback)
    }

    // Bind event listener to update position on events array
    useEffect(() => {
        if (!opts.events || opts.events.length === 0) return

        opts.events.forEach((event) => {
            window.addEventListener(event, _update, true)
        })

        return () => {
            if (!opts.events || opts.events.length === 0) return
            opts.events.forEach((event) => {
                window.removeEventListener(event, _update)
            })
        }
    }, [])

    // NOTE: This is a copy with different return values of `rectCallback`.
    // This feels like a code-smell/antipattern and any feedback would be
    // highly appreciated

    // Update the position after each new render
    useEffect(() => {
        if (!ref.current) return
        const newRect = ref.current.getBoundingClientRect()
        if (isEqualRect(rect, newRect)) return
        setRect(newRect)
    })

    return rect
}
export default useObserveBoundingRect
