import { useEffect, useState } from "react"

/**
 * Hook to have a boolean state that will reset itself
 * after certain ms.
 */
function useTimedoutBooleanState(
    ms = 2000
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
    const [myBool, setMyBool] = useState(false)

    useEffect(() => {
        if (myBool) {
            const timeout = setTimeout(() => setMyBool(false), ms)
            return () => clearTimeout(timeout)
        }
    }, [myBool])

    return [myBool, setMyBool]
}

export default useTimedoutBooleanState
