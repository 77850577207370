export const EMPTY_RECT = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
}
export type BoundingRect = {
    top: number
    right: number
    bottom: number
    left: number
    width: number
    height: number
    x: number
    y: number
}
