interface ILabelAndInput {
    label: string
    input: any
    extraClasses?: string
}

function LabelAndInput({
    label = "",
    input,
    extraClasses = "w-full",
}: ILabelAndInput) {
    // INFO: input.props to detect if input element is disabled
    return (
        <div className={"flex flex-col items-stretch gap-1 " + extraClasses}>
            {label && label !== "" && (
                <label
                    className={[
                        "label-sm h-[18px]",
                        input?.props?.disabled
                            ? "text-gray-30"
                            : "text-gray-60",
                    ].join(" ")}>
                    {label}
                </label>
            )}
            {input}
        </div>
    )
}

export default LabelAndInput
