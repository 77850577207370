import { useMemo } from "react"
import { useTimeline } from ".."
import { useLocale } from "../../../providers"
import { daysBetween } from "../../../utils"
import { isValidDate } from "../../../views/Seasonal/PlanningTool/utils"

function DragDate() {
    const { monthNames } = useLocale()
    const { pxPerDay, dragAuxDate, timelineStartDate, isYearAgnostic } =
        useTimeline()

    const displayDate = useMemo(() => {
        if (!dragAuxDate || !isValidDate(dragAuxDate)) return ""

        if (!isYearAgnostic)
            return dragAuxDate.toISOString().split("T")[0].replaceAll("-", "/")

        const month = dragAuxDate.getMonth()
        const monthName = monthNames[month].slice(0, 3)
        return monthName + " " + dragAuxDate.getDate()
    }, [dragAuxDate, isYearAgnostic, monthNames])

    if (!dragAuxDate) return null

    const daysFromTimelineStart = daysBetween(timelineStartDate, dragAuxDate)

    const paddingLeftClass = daysFromTimelineStart > 5 ? "pl-8" : "pl-14"

    return (
        <div
            className={[
                "absolute bottom-0 z-50 -top-4 left-0",
                "w-0 border-r border-gray-30",
                "pointer-events-none",
            ].join(" ")}
            style={{
                left:
                    (daysFromTimelineStart > 1
                        ? daysFromTimelineStart * pxPerDay
                        : pxPerDay) + "px",
            }}>
            <div className="relative flex items-center justify-center">
                <div
                    className={[
                        "absolute z-full",
                        "pointer-events-none",
                        "bottom-full mb-2",
                        daysFromTimelineStart > 10 ? "" : paddingLeftClass,
                    ].join(" ")}>
                    <div
                        className={[
                            "absolute top-full left-1/2",
                            "border-[6px] border-gray-90 border-b-0 border-x-transparent",
                            "-translate-x-1/2",
                        ].join(" ")}></div>
                    <div
                        className={[
                            "bg-gray-90",
                            "font-roboto text-white text-[14px] leading-4",
                            "p-2 mt-2",
                            "rounded-sm elevation-2",
                            "whitespace-nowrap",
                            isYearAgnostic ? "w-20" : "",
                        ].join(" ")}>
                        {displayDate}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DragDate
