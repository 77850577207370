import { GraphQLClient, gql } from "graphql-request"
import { getUserToken } from "./index"

// declared outside so that only one instance is used
const client = new GraphQLClient(
    process.env.REACT_APP_QUERY_URL + "/graphql" || "",
    {
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
        },
        timeout: 60000,
    }
)

function getGraphqlClient() {
    //
    const userToken = getUserToken()

    // add user token to headers
    client.setHeader("Authorization", `Bearer ${userToken?.access_token}`)

    return client
}

export default getGraphqlClient
