import { isValidResponse } from "../../../climateui/utils/http"
import { useAssets } from "../../../providers"
import { IRiskProfile, IVariety, VarietyMetadataInput } from "../../../types"
import { riskProfileQuerySet } from "../../../utils/networking"

export const getRiskProfiles = async (varietyID: string) => {
    if (!varietyID) return
    const response = await riskProfileQuerySet.post("/search", {
        filter_by: {
            and: [
                {
                    field_name: "risk_profile.RiskProfile.varieties",
                    operator: "any",
                    field_value: {
                        field_name: "variety.Variety.id",
                        operator: "eq",
                        field_value: varietyID,
                    },
                },
            ],
        },
    })
    if (!isValidResponse(response)) return
    return response.data.data as IRiskProfile[]
}

export const useBulkDeleteVarieties = () => {
    const { deleteVariety } = useAssets()
    return async (varietiesIDs: string[]) => {
        if (varietiesIDs.length === 0) return
        // Retrieve all risk profiles related to these assets
        const gettingRiskProfiles = varietiesIDs.map(getRiskProfiles)
        const retrievedRiskProfiles = await Promise.all(gettingRiskProfiles)
        // Make a list of the risk profiles IDs to be deleted
        const queuedRiskProfiles = new Set<string>()
        // Filter out risk profiles that have other linked assets
        retrievedRiskProfiles.flat().forEach((riskProfile) => {
            // Exit if it is undefined
            if (!riskProfile) return
            const { id, varieties } = riskProfile
            // Exit if it is already in the list
            if (queuedRiskProfiles.has(id)) return
            // Remove from its varieties the ones queued for deletion
            const remainingVarieties = varieties.filter(
                (variety) => !varietiesIDs.includes(variety.id)
            )
            // Exit if there are remaining varieties
            if (remainingVarieties.length > 0) return
            // Add this risk profile to the deletion queue
            queuedRiskProfiles.add(id)
        })
        // Delete all the queued risk profiles
        await Promise.all(
            Array.from(queuedRiskProfiles).map((id) =>
                riskProfileQuerySet.delete(`/${id}`)
            )
        )
        // Delete all the queued varieties
        return Promise.all(varietiesIDs.map((id) => deleteVariety(id)))
    }
}

/**
 * Merges together attributes for asset varieties into input values
 */
export const mergeAttributes = (
    varieties: IVariety[]
): VarietyMetadataInput[] => {
    // Create a tracking record/dictionary
    const tracking: Record<string, { values: Set<string>; freq: number }> = {}
    // For each variety metadata, update the record
    varieties.forEach(({ variety_metadata }) => {
        variety_metadata.forEach(({ key, value }) => {
            // If the record doesn't exist yet, initiate it
            if (!tracking[key]) {
                tracking[key] = {
                    values: new Set([value]),
                    freq: 1,
                }
                // If the record exists, update the set and increase the frequency by one
            } else {
                tracking[key].values.add(value)
                tracking[key].freq += 1
            }
        })
    })
    return Object.entries(tracking).reduce(
        (prev: VarietyMetadataInput[], [key, { values, freq }]) => {
            let value: string | undefined = undefined
            if (values.size === 1 && freq === varieties.length)
                value = Array.from(values)[0]
            return [...prev, { key, value }]
        },
        []
    )
}
