import { gql } from "graphql-request"
import WidgetWrapper from "../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import YieldOutlookStageWidget from "../widgets/YieldOutlookStageWidget"
import { IYieldOutlookWidgetProps } from "./types"

const yieldOutlookStageQuery = gql`
    query ($country_id: String, $asset_ids: [String], $current_time: String) {
        yield_outlook_model(
            filter: { region_ids: [$country_id], asset_ids: $asset_ids }
        ) {
            results {
                region {
                    name
                }
                stages(filter: { current_date: $current_time }) {
                    stage_name
                    start_date
                    end_date
                }
            }
        }
    }
`

const YieldOutlookStage = (props: IYieldOutlookWidgetProps) => {
    const { filters, selectors } = props
    return (
        <WidgetWrapper
            selectedFilters={["country_id", "asset_ids", "current_time"]}
            component={YieldOutlookStageWidget}
            query={yieldOutlookStageQuery}
            filters={filters}
            selectors={selectors}
        />
    )
}
export default YieldOutlookStage
