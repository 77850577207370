import { useContext, useState } from "react"
import { TrashIcon, EditIcon } from "../../../../climateui/icons"
import { ModalContext, ToastContext } from "../../../../climateui/providers"
import { TableActions } from "../../../../climateui/components/Table/tableUtils"
import { IScheduler } from "../../../../types"
import { useTranslate } from "@tolgee/react"
import { useAccount } from "../../../../providers/AccountProvider"
import queryClient, { scheduleDELETE } from "../../../../utils/networking"
import { OpenScheduleForm } from "./NewScheduleForm"
import { useDashboard } from "../../../../providers/DashboardProvider"

const ScheduleTableActions = ({ rowData }: { rowData: IScheduler }) => {
    const { t } = useTranslate()
    const { confirmationModal } = useContext(ModalContext)
    const { enqueueAlert } = useContext(ToastContext)
    const { selectedAccount } = useAccount()
    const [openForm, setOpenForm] = useState(false)
    const { dashboardsObject } = useDashboard()

    const reloadSchedulesList = async () => {
        queryClient.invalidateQueries(["dashboard", rowData.dashboard_id])
        queryClient.invalidateQueries(["schedules", selectedAccount])
    }

    return (
        <>
            <TableActions
                rowData={rowData}
                actions={[
                    {
                        icon: <EditIcon />,
                        onClick: () => {
                            setOpenForm(true)
                        },
                        tooltip: t("editSchedule"),
                    },
                    {
                        tooltip: t("deleteSchedule"),
                        icon: <TrashIcon />,
                        onClick: () => {
                            confirmationModal({
                                title: t(
                                    "areYouSureDeleteSchedule",
                                    "Delete this custom schedule?"
                                ),
                                text: t(
                                    "scheduleWillBeDeletedPermanently",
                                    "This schedule will be deleted permanently."
                                ),
                                onContinueLabel: t("continue"),
                                onCancelLabel: t("cancel"),
                                onContinue: () => {
                                    scheduleDELETE(rowData.id).then(() => {
                                        queryClient.invalidateQueries([
                                            "dashboard",
                                            rowData.dashboard_id,
                                        ])
                                        queryClient.invalidateQueries([
                                            "schedules",
                                            selectedAccount,
                                        ])
                                        enqueueAlert(
                                            t(
                                                "SCHEDULEDeletedSuccessfully",
                                                "Schedule deleted successfully!"
                                            )
                                        )
                                    })
                                },
                            })
                        },
                    },
                ]}
            />
            {openForm ? (
                <OpenScheduleForm
                    isOpen={openForm}
                    sendNow={false}
                    onCancel={() => {
                        setOpenForm(false)
                    }}
                    schedule={rowData}
                    dashboard={dashboardsObject[rowData.dashboard_id as string]}
                    reloadSchedules={() => {
                        reloadSchedulesList().catch((err) => console.error(err))
                    }}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default ScheduleTableActions
