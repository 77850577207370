import { useEffect, useState } from "react"
import { useTimeline } from ".."
import { DraggableElement } from "../../../climateui/components"
import { ThreeDotsIcon } from "../../../climateui/icons"
import { ITimelineStage } from "../../../types"
import { daysBetween } from "../../../utils"
import { useTimelineRow } from "./TimelineRow"
import { moveItemDates } from "./utils"

function MoveAllHandle() {
    const {
        timelineStartDate,
        pxPerDay,
        setDragAuxDate,
        dragAuxDate,
        focusedStage,
        setFocusedStage,
    } = useTimeline()
    const { row, updateRow, setRowLeft, stageHeight } = useTimelineRow()

    const rowStages = row.stages ?? []

    const [dragging, setDragging] = useState<boolean>(false)
    const [minDate, setMinDate] = useState<Date>()

    useEffect(() => {
        if (!rowStages || rowStages.length === 0) {
            setMinDate(undefined)
            return
        }
        const auxMinDate = rowStages.reduce(
            (prev: ITimelineStage, curr: ITimelineStage) => {
                return prev.start_date < curr.start_date ? prev : curr
            }
        ).start_date
        setMinDate(auxMinDate)
    }, [rowStages])

    const auxMinDate =
        minDate && timelineStartDate < minDate ? minDate : timelineStartDate

    const menuStyle = {
        left:
            pxPerDay *
                daysBetween(
                    timelineStartDate,
                    dragAuxDate && dragging
                        ? dragAuxDate
                        : auxMinDate || new Date()
                ) +
            "px",
        height: stageHeight + "px",
    }

    const moveAll = (daysMoved: number) => {
        if (!minDate) return

        const auxDate = new Date(minDate)
        auxDate.setDate(auxDate.getDate() + daysMoved)
        // Line below causes strategies with stages in the past
        // to move to today when dragged.
        // if (daysBetween(timelineStartDate, auxDate) < 0) return

        const newStages = [...rowStages]
        // const newRisks = [...strategyRisks]
        newStages.forEach((stage) => {
            moveItemDates(stage, daysMoved)
        })
        updateRow({
            stages: newStages,
        })
    }

    return (
        <div
            className={[
                "absolute top-0 z-45",
                "flex flex-row items-center",
                !minDate ? "hidden" : "",
            ].join(" ")}
            style={menuStyle}>
            <DraggableElement
                draggable
                draggingCallback={(pxMoved: number) => {
                    if (focusedStage !== row.id) {
                        setFocusedStage(row.id)
                        // setFocusedPlannedRisk(strategy.id)
                    }
                    if (!dragging) setDragging(true)

                    const daysMoved = Math.round(pxMoved / pxPerDay)
                    const strategyDate = new Date(auxMinDate || new Date())
                    strategyDate.setDate(strategyDate.getDate() + daysMoved)
                    if (daysBetween(timelineStartDate, strategyDate) < 0) return

                    setDragAuxDate(strategyDate)
                    setRowLeft(pxPerDay * daysMoved)
                }}
                dragEndCallback={() => {
                    if (!auxMinDate || !dragAuxDate) return
                    const daysMoved = daysBetween(auxMinDate, dragAuxDate)
                    moveAll(daysMoved)
                    setDragging(false)
                    setRowLeft(0)
                    setFocusedStage(undefined)
                    setDragAuxDate(undefined)
                }}>
                <div className="flex flex-row items-center -ml-8 fill-gray-60">
                    <span className="w-5 h-5">
                        <ThreeDotsIcon />
                    </span>
                    <span className="w-5 h-5 -ml-[14px]">
                        <ThreeDotsIcon />
                    </span>
                </div>
            </DraggableElement>
        </div>
    )
}

export default MoveAllHandle
