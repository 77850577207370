import React from "react"

function DoneLoading() {
    return (
        // <div
        //     id="dashboard-done-loading"
        //     className="w-10 h-10 bg-accent"></div>
        <div
            id="dashboard-done-loading"
            className="w-0 h-0"></div>
    )
}

export default DoneLoading
