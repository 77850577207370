import { useMemo } from "react"
import CalendarView, { IBaseCalendarViewProps } from "./CalendarView"
import { ICalendarCell } from "./CalendarCell"
import { DateTime } from "luxon"
import { DAYS_IN_WEEK } from "."

const DateView = ({
    viewDate,
    onClick,
    yearAgnostic = false,
    validator,
    invalidMessage,
}: IBaseCalendarViewProps & {
    viewDate: DateTime
    yearAgnostic?: boolean
}) => {
    const monthStart = viewDate.startOf("month")
    const startOffset = monthStart.weekday % 7 || undefined
    const today = DateTime.now()

    // Create a days array skipping the 0th day
    const days = useMemo<ICalendarCell[]>(
        () =>
            [...Array(viewDate.daysInMonth).keys()].map((day) => {
                const date = monthStart.plus({ days: day })
                return {
                    date,
                    validator,
                    invalidMessage,
                    label: date.day,
                    current: yearAgnostic
                        ? false
                        : date.toISODate() === today.toISODate(),
                }
            }),
        [viewDate, validator, invalidMessage, yearAgnostic]
    )

    const header = !yearAgnostic
        ? ["S", "M", "T", "W", "T", "F", "S"]
        : undefined

    return (
        <CalendarView
            onClick={onClick}
            startOffset={startOffset}
            // TODO: Consider i18n and a different start day
            header={header}
            columns={DAYS_IN_WEEK}
            values={days}
        />
    )
}
export default DateView
