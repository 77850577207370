import { WorldSearchIcon, ClimateTrendsIcon } from "../../climateui/icons"
import YieldIcon from "../../climateui/icons/variableIcons/YieldIcon"
import { IRoute } from "../../climateui/types"
import ExplorationToolProvider from "../../providers/ExplorationToolProvider"
import NotFound from "../NotFound"
import YieldOutlookView from "./YieldOutlook"
import ClimateTrendsView from "./ClimateTrends"
import ExplorationToolLayout from "./ExplorationTool/ExplorationToolLayout"
import ExplorationToolLocations from "./ExplorationTool/ExplorationToolLocations"
import ExplorationToolSearchBySimilarity from "./ExplorationTool/ExplorationToolSearchBySimilarity"
import YieldOutlookDashboard from "./YieldOutlook/templates/YieldOutlookDashboard"

export const searchBySimilarityRoute = {
    path: "search-by-similarity",
    labelKey: "searchBySimilarity",
    element: <ExplorationToolSearchBySimilarity />,
}

export const explorationChildRoutes: IRoute[] = [
    {
        path: "locations",
        labelKey: "myLocations",
        element: <ExplorationToolLocations />,
    },
    searchBySimilarityRoute,
]

const climateChildRoutes: IRoute[] = [
    {
        path: "exploration",
        featureFlags: ["feature_exploration_tool"],
        accountPermissions: ["exploration_tool"],
        label: "Exploration",
        labelKey: "explorationLabel",
        icon: <WorldSearchIcon />,
        element: (
            <ExplorationToolProvider>
                <ExplorationToolLayout />
            </ExplorationToolProvider>
        ),
        children: explorationChildRoutes,
    },
    {
        path: "outlook",
        featureFlags: ["feature_adapt_yield_outlook"],
        accountPermissions: ["adapt_yield_outlook"],
        label: "Yield Outlook",
        labelKey: "yieldOutlookLabel",
        icon: <YieldIcon />,
        element: <YieldOutlookView />,
        children: [
            {
                path: ":assetId",
                labelKey: "assets",
                element: <YieldOutlookDashboard />,
            },
        ],
    },
    {
        path: "trends",
        label: "Climate Trends",
        featureFlags: ["feature_climate_trends"],
        accountPermissions: ["adapt_climate_trends"],
        labelKey: "climateTrends",
        icon: <ClimateTrendsIcon />,
        element: <ClimateTrendsView />,
    },
    {
        path: "*",
        element: <NotFound />,
    },
]

export default climateChildRoutes
