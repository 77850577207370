import { ColumnDef } from "@tanstack/react-table"
import { useTranslate } from "@tolgee/react"
import { DateTime } from "luxon"
import { ReactNode } from "react"
import { Table } from "../../../climateui/components"
import LoadingAnimation from "../../../climateui/components/LoadingAnimation"
import NoResultsState from "../../../climateui/components/NoResultsState"
import { useCustomFlags } from "../../../hooks"
import {
    IDecadalStat,
    ISeasonalStat,
    IYieldOutlookResponse,
} from "../../../types"
import { useReports } from "./network"
import {
    dateToColumn,
    getColumnDates,
    getIndexedData,
    getMostCurrentReportDate,
    INIT_DATE_FORMAT,
    YieldReportCell,
    CurrentReportHeader,
    RollingOutlookData,
    formatter,
    CELL_FALLBACK_VALUE,
    DEFAULT_WEEKS_NUM,
} from "./utils"

interface IFilter {
    propName: string
    value: unknown
    loading?: boolean
}
interface IRollingOutlookTableProps {
    filters: IFilter[]
    outlookDate?: string // expecting an UTC date
    weeks?: number
    show?: boolean
    loading?: boolean
    customColumns?: ColumnDef<RollingOutlookData, ReactNode>[]
    dashboardType?: "region" | "location"
}

interface IRollingYieldOutlookFilters {
    territoryIDs: string[]
    assetIDs: string[]
    outlookDateStr: string | undefined
    units: string | undefined
}
const getFilters = (filters: IFilter[]) => {
    return filters.reduce(
        (prev, curr) => {
            switch (curr.propName) {
                case "state_ids":
                case "location_ids":
                    prev.territoryIDs = curr.value as string[]
                    break
                case "init_time":
                    prev.outlookDateStr = curr.value as string
                    break
                case "asset_ids":
                    prev.assetIDs = curr.value as string[]
                    break
                case "units":
                    prev.units = curr.value as string
            }
            return prev
        },
        {
            territoryIDs: [],
            assetIDs: [],
            outlookDateStr: undefined,
            units: undefined,
        } as IRollingYieldOutlookFilters
    )
}

const RollingOutlookTable = ({
    filters,
    show = true,
    loading: isLoadingExternal = false,
    weeks: _weeks,
    customColumns = [],
    dashboardType = "region",
}: IRollingOutlookTableProps) => {
    const mostCurrentReportDate = getMostCurrentReportDate()
    const { territoryIDs, assetIDs, outlookDateStr, units } =
        getFilters(filters)
    const outlookDate =
        !outlookDateStr || outlookDateStr === mostCurrentReportDate.toISODate()
            ? mostCurrentReportDate.minus({ weeks: 1 })
            : DateTime.fromISO(outlookDateStr, {
                  zone: "utc",
              }).startOf("week")
    /* HOOKS ---------------------------------------------------------------- */
    const { t } = useTranslate()
    const { feature_rolling_yield_outlook_table_weeks: fsWeeksNum } =
        useCustomFlags(["feature_rolling_yield_outlook_table_weeks"])

    // Generate week dates
    const weeks = _weeks ?? ((fsWeeksNum.value as number) || DEFAULT_WEEKS_NUM)
    const weekDates = getColumnDates(outlookDate, weeks)

    const {
        data: pastReports,
        isLoading: isLoadingPastReports,
        isFetching: isFetchingPastReports,
        isFetched: isFetchedPastReports,
    } = useReports({
        filters: {
            territoryIDs,
            assetIDs,
            startDate: weekDates[0].toFormat(INIT_DATE_FORMAT),
            endDate: weekDates[weekDates.length - 1].toFormat(INIT_DATE_FORMAT),
            units,
        },
        dashboardType,
    })
    const {
        data: currentReport,
        isLoading: isLoadingCurrentReport,
        isFetching: isFetchingCurrentReport,
        isFetched: isFetchedCurrentReport,
    } = useReports({
        filters: {
            territoryIDs,
            assetIDs,
            initTime: mostCurrentReportDate.toFormat(INIT_DATE_FORMAT),
            units,
        },
        dashboardType,
    })
    /* ---------------------------------------------------------------------- */
    if (assetIDs.length === 0 || !show || !outlookDate.isValid) return null

    const isLoading =
        isLoadingPastReports ||
        isFetchingPastReports ||
        isLoadingCurrentReport ||
        isFetchingCurrentReport ||
        isLoadingExternal

    const isFetched = isFetchedPastReports || isFetchedCurrentReport

    let reports: IYieldOutlookResponse<ISeasonalStat | IDecadalStat>[] = []
    if (pastReports) reports = reports.concat(pastReports)
    if (currentReport) reports = reports.concat(currentReport)

    const weekColumns = weekDates.map(dateToColumn)
    const columns = [
        ...customColumns,
        ...weekColumns,
        // CURRENT REPORT COLUMN
        {
            id: "currentReport",
            accessorFn: (original: RollingOutlookData) => {
                const dateKey = mostCurrentReportDate.toFormat(INIT_DATE_FORMAT)
                const value = original[dateKey]
                if (typeof value === "number")
                    return `${formatter.format(value)} ${units}`
                return CELL_FALLBACK_VALUE
            },
            header: CurrentReportHeader,
            cell: YieldReportCell,
        },
    ]

    const indexedData = getIndexedData(reports)
    const data = territoryIDs
        .map((id) =>
            [...weekDates, mostCurrentReportDate].reduce(
                (row, currDate) => {
                    const dateKey = currDate.toFormat(INIT_DATE_FORMAT)
                    if (id && indexedData[id]?.stats?.[dateKey]) {
                        row[dateKey] =
                            indexedData[id]?.stats?.[dateKey]?.outlook_mean
                    }
                    return row
                },
                {
                    territory: indexedData[id]?.territory,
                } as RollingOutlookData
            )
        )
        .sort((a, b) => a.territory?.name?.localeCompare(b.territory?.name))

    const pagOptStr = t("outlooks", "outlooks")
    const paginationOptions = {
        5: `5 ${pagOptStr}`,
        10: `10 ${pagOptStr}`,
        25: `25 ${pagOptStr}`,
    }

    return (
        <div className="relative w-full h-full p-3 overflow-y-hidden bg-white border rounded-lg border-1 border-gray-14">
            <div className="sticky top-0 left-0 w-full pb-3">
                <h3
                    className={[
                        "label-lg",
                        isLoading ? "text-gray-30" : "",
                    ].join(" ")}>
                    {t("pastReportsMeanYield", "Past Reports (Mean Yield)")}
                </h3>
            </div>
            {isLoading && !isFetched && (
                <div className="flex justify-center mb-7 body-lg">
                    <LoadingAnimation />
                </div>
            )}
            {!isLoading && !isFetched && data.length === 0 && (
                <div className="mb-5">
                    <NoResultsState
                        tryAnotherFilterMessage={t(
                            "tryAnotherFilter",
                            "Try another set of filters"
                        )}
                        noResultsMessage={t("noResults", "No results")}
                    />
                </div>
            )}
            {!isLoading && isFetched && data.length > 0 && (
                <Table
                    data={data}
                    columns={columns}
                    paginationOptions={paginationOptions}
                />
            )}
        </div>
    )
}

export default RollingOutlookTable
