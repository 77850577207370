import { useTranslate } from "@tolgee/react"
import { categoryToColor, getHazardIcon } from "../riskOutlookUtils"
import { IRiskOutlookOverviewHazardModel } from "../../../../types"

const RiskItem = ({
    riskType,
    score,
    category,
}: IRiskOutlookOverviewHazardModel) => {
    const { t } = useTranslate()

    const classes = {
        iconFill: "gray-60",
        px: "18",
        size: "2",
        textSize: "body-md",
        textColor: "gray-90",
    }

    const colorClass = categoryToColor[category]
    const HazardIcon = getHazardIcon(riskType)

    return (
        <div className="flex w-[100%] max-w-[400px] justify-evenly pb-2 last-of-type:pb-0">
            <div className={`mr-2 w-6 h-6 fill-gray-60`}>
                {HazardIcon && <HazardIcon fill="inherit" />}
            </div>
            <div className={`flex-1 body-md text-gray-90`}>
                {t(riskType)} Index
            </div>
            <div className="flex items-center justify-center">
                <div
                    className={`bg-${colorClass} h-${classes.size} rounded-full w-${classes.size} mr-1`}></div>
                <div className={`${classes.textSize} text-${colorClass}`}>
                    {Math.round(score)}
                </div>
            </div>
        </div>
    )
}

export default RiskItem
