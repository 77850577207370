import { IBandPlotComponentDataSource } from '../../../entities/PlotComponents/types';
import { IAreaAttributes, TypeDataSourcePoints } from '../data/types';

/* 
    TODO:
    Think on a way to separate DTO implementation
    from datasource... Tooo coupleddd
*/

export class BandPlotComponentDTO implements IBandPlotComponentDataSource {
    id: string
    xLabel: string
    yLabel: string
    xLabelId: string
    yLabelId: string
    x: string[]
    y: {
        y0: number
        y1: number
    }[]

    constructor(
        id: string,
        xLabelId: string,
        xLabel: string,
        yLabelId: string,
        yLabel: string,
        points: TypeDataSourcePoints<IAreaAttributes>) {
        this.id = id
        this.xLabel = xLabel
        this.yLabel = yLabel
        this.xLabelId = xLabelId
        this.yLabelId = yLabelId

        const { x, y } = this.getComposedAxes(points)
        this.x = x
        this.y = y
    }

    /* 
        All the transformation from the data source points to the 
        - AreaPlotComponent 
        happens inside this function. 
    */
    private getComposedAxes(points: TypeDataSourcePoints<IAreaAttributes>): {
        x: string[]
        y: {
            y0: number
            y1: number
        }[]
    } {
        const x: string[] = []
        const y: {
            y0: number
            y1: number
        }[] = []

        for (let i = 0; i < points.length; i++) {
            x.push(points[i].date)

            const y0 =
                points[i].attributes['quantile_0.05'] != undefined ?
                    points[i].attributes['quantile_0.05'] :
                    points[i].attributes['quantile_0.25']
            const y1 =
                points[i].attributes['quantile_0.95'] != undefined ?
                    points[i].attributes['quantile_0.95'] :
                    points[i].attributes['quantile_0.75']

            if (y0 === undefined || y1 === undefined) {
                throw new Error('y0 and / or y1 are undefined in BandChartDTO.')
            }

            y.push({
                y0: y0,
                y1: y1,
            })
        }

        return { x, y }
    }
}