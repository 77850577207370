const BackToMenuIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M2.5 15V13.3333H13.3333V15H2.5ZM16.3333 14.1667L12.1667 10L16.3333 5.83333L17.5 7L14.5 10L17.5 13L16.3333 14.1667ZM2.5 10.8333V9.16667H10.8333V10.8333H2.5ZM2.5 6.66667V5H13.3333V6.66667H2.5Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default BackToMenuIcon
