import { Dispatch, SetStateAction, useEffect } from "react"
/* COMPONENTS */
import Condition, { ConditionForm } from "../components/HazardCondition"
import DSCondition from "./HazardConditionDS";

/* NETWORKING */
import { useRiskProfiles } from "../../../../providers/RiskProfilesProvider"

type Conditions = ConditionForm[][]
type RiskComponentMappingType = {
    [key: string]: React.ComponentType<any>;
};
type HazardFieldProps = {
    hazards: Conditions;
    updateHazards: Dispatch<SetStateAction<Conditions>>;
    onLoadStateChange?: (loadState: boolean) => void;
    canEditAll?: boolean;
    riskType: string;
};
export default function HazardField({
    hazards: conditions,
    updateHazards: updateConditions,
    onLoadStateChange,
    canEditAll = true,
    riskType,
}: HazardFieldProps) {
    /* QUERIES */
    const { hazardVariables, areHazardVariablesLoading: areVariablesLoading } =
        useRiskProfiles()

    useEffect(() => {
        if (!onLoadStateChange) return
        onLoadStateChange(areVariablesLoading)
    }, [areVariablesLoading])

    const RiskComponentMapping: RiskComponentMappingType = {
        'ds_defined': DSCondition,
        'custom': Condition,
    };
    const processedConditions = conditions.map(groupConditions =>
        riskType === 'ds_defined' ? groupConditions : groupConditions
    );

    const Component = RiskComponentMapping[riskType] || Condition;

    return (
        // This preventDefault is needed to avoid delegating the click event to the first SingleSelect
        <div onClick={(ev) => ev.preventDefault()}>
            {processedConditions.map((groupConditions, gid) => {
                return (
                    <div key={gid}>
                        <label className="label-sm text-gray-60">
                            <div className="flex items-center justify-between">
                            </div>
                            <div className="flex flex-col items-start">
                                <div key={gid}>
                                {groupConditions.map((condForm, id) => (
                                        <div key={id}>
                                                <Component
                                                    id={id}
                                                    hazardVariables={hazardVariables}
                                                    groupId={gid}
                                                    conditions={conditions}
                                                    updateConditions={updateConditions}
                                                    form={condForm}
                                                    canEditAll={canEditAll}
                                                />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </label>
                    </div>
                )
            })}
        </div>
    )
}
