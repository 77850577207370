import { ICoordinates } from "../../../types"
import { FeatureCollection, Feature } from "geojson"
import { removeLayer, removeSource } from "./map"

export interface IMapLine {
    points: ICoordinates[]
}

export interface IStyleLinesConfig {
    color: string
    width?: number
}

interface ILinesOptions extends Partial<IStyleLinesConfig> {
    map: mapboxgl.Map
    lines: IMapLine[]
}

const LINES_SOURCE_ID = "lines-source"
const LINES_LAYER_ID = "lines-layer"

export const setupLines = ({
    map,
    lines,
    color = "white",
    width = 1,
}: ILinesOptions) => {
    if (!lines || lines.length === 0) return

    const features: Feature[] = lines.map((line) => {
        return {
            type: "Feature",
            properties: {},
            geometry: {
                type: "LineString",
                coordinates: line.points.map((point) => {
                    return [point.lon, point.lat]
                }),
            },
        }
    })

    const data: FeatureCollection = {
        type: "FeatureCollection",
        features: features,
    }

    map.addSource(LINES_SOURCE_ID, {
        type: "geojson",
        data: data,
    })

    map.addLayer({
        id: LINES_LAYER_ID,
        type: "line",
        source: LINES_SOURCE_ID,
        layout: {
            "line-join": "round",
            "line-cap": "round",
        },
        paint: {
            "line-color": color,
            "line-width": width,
        },
    })
}

export const removeLines = (map: mapboxgl.Map) => {
    removeLayer(map, LINES_LAYER_ID)
    removeSource(map, LINES_SOURCE_ID)
}
