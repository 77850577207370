import { ReactNode } from "react"
import LoadingBar from "./LoadingBar"

interface ILoadingScreen {
    message: string
    element?: ReactNode
}

function LoadingScreen({
    message = "Loading...",
    element,
}: ILoadingScreen) {
    return (
        <div className="w-full h-full bg-white grid place-content-center text-center">
            <LoadingBar />
            {element}
            <h1 className="body-md text-gray-60">{message}</h1>
        </div>
    )
}

export default LoadingScreen
