import { useQuery } from "react-query"
import { isValidResponse } from "../climateui/utils/http"
import { arrToDict } from "../utils/transform"
import { IAsset } from "../types"
import { assetQuerySet } from "../utils/networking"

const useAssetsQuery = (selectedAccount?: string) => {
    return useQuery({
        queryKey: ["assets", selectedAccount],
        queryFn: () =>
            assetQuerySet.get(
                "",
                {},
                {},
                {
                    headers: {
                        "X-Fields": "{id,name,description,industry_id,meta}",
                    },
                }
            ),
        enabled: !!selectedAccount,
        select: (response) => {
            if (!isValidResponse(response)) return {}
            return arrToDict<IAsset>(response.data, "id")
        },
    })
}

export default useAssetsQuery
