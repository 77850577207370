/* REACT IMPORTS */
import { useContext, useEffect, useMemo, useState } from "react"

/* COMPONENTS */
import {
    Button,
    DebounceSearchInput,
    Table,
    TooltipV2,
} from "../../../climateui/components"
import RiskProfileForm from "./components/RiskProfileForm"

/* ICONS */
import {
    DownloadIcon,
    PlusIcon,
    TrashIcon,
    UploadIcon,
} from "../../../climateui/icons"

/* TABLE UTILS */
import {
    columns,
    riskProfileStagesCell,
    RISK_PROFILES_BULK_CSV_HEADER_COLUMN_DATA,
    TEMPLATE_RISK_CSV_DATA,
} from "./riskProfilesTableUtils"

/* BULK UPLOAD */
import BulkUploadCSVParser from "../../../components/Bulk Upload/BulkUploadCSVParser"
// TODO: Move these dependencies to the climateui and reimport
import {
    IBulkUploadStatus,
    ICSVDataCell,
} from "../../../components/Bulk Upload/bulkUploadUtils"
import {
    useRiskProfileToCSVRow,
    useRawRiskProfileParser,
    isParseError,
} from "./utils/csv"

/* OTHER UTILITIES */
import { saveAs } from "file-saver"
import { isValidResponse } from "../../../climateui/utils/http"
import BulkUploadRiskProfiles from "./BulkUploadRiskProfiles"

/* NETWORKING */
import { useMutation, useQuery } from "react-query"
import queryClient, {
    hazardVariableQuerySet,
    riskProfileQuerySet,
    impactFunctionQuerySet,
    rpLabelsQuerySet,
} from "../../../utils/networking"

/* TYPES */
import {
    IBooleanDictionary,
    ILabel,
    IPrimitivesDictionary,
} from "../../../climateui/types"

import {
    IHazardVariable,
    IImpactFunction,
    RiskProfileInput,
    IRiskProfile,
} from "../../../types"
import { useAccount } from "../../../providers/AccountProvider"
import { useRiskProfiles } from "../../../providers/RiskProfilesProvider"
import { useTranslate } from "@tolgee/react"
import { SortingState } from "@tanstack/react-table"
import { ModalContext, ToastContext } from "../../../climateui/providers"
import { bulkDelete, bulkUpdate, bulkUpdateLabels } from "./utils/bulkActions"
import { BulkProbabilityForm } from "./components/bulk"
import {
    useIsFlagEnabled,
    useMemoQuery,
    usePaginationFlag,
} from "../../../hooks"
import { BulkLabelsForm, GenericPageHeader } from "../../../components"
import { useAssets, useLocations } from "../../../providers"
import { useFilter } from "../hooks"
import _ from "lodash"
import { REMEMBERED_FILTERS } from "../../../utils/constants"
import { ResponsivePaddingWrapper } from "../../../layouts/TabLayout"

const formatter = Intl.NumberFormat(undefined, { maximumFractionDigits: 0 })
/* CONSTS < END */

const RiskProfilesTableView = () => {
    /* HOOKS > START */
    const { t } = useTranslate()
    const { selectedAccount } = useAccount()
    const { locationsObj } = useLocations()
    const { varieties } = useAssets()
    const varietiesOpts = useMemo(() => {
        if (!varieties) return {}
        return Object.values(varieties).reduce(
            (prev: Record<string, string>, { id, asset, name }) => {
                const fullName = asset ? `${asset.name} (${name})` : name
                return { ...prev, [id]: fullName }
            },
            {}
        )
    }, [varieties])

    /* RISK PROFILES PROVIDER */
    const {
        filteredRiskProfiles,
        filteredRiskProfilesInfo,
        loadingRisks,
        setRiskProfilesFilter,
        currentRiskProfile: riskProfile,
        setCurrentRiskProfileById,
        setIsFormOpen,
        isFormOpen,
        stageOpts,
    } = useRiskProfiles()

    const { confirmationModal } = useContext(ModalContext)
    const { enqueueAlert } = useContext(ToastContext)
    const riskProfileToCSVRow = useRiskProfileToCSVRow()
    /* HOOKS < END */

    /* STATE > START */

    /* BULK STATE */
    const [showBulkProbability, setShowBulkProbability] = useState(false)
    const [showBulkLabels, setShowBulkLabels] = useState(false)
    const [isBulkActionLoading, setBulkActionLoading] = useState(false)

    /* SORTING STATE */
    const [sorting, setSorting] = useState<SortingState>([])
    const [isSortingLocally, setIsSortingLocally] = useState<boolean>(false)

    /* SEARCH STATE */
    const [searchTerm, setSearchTerm] = useState<string>("")

    /* STATE < END */

    /* NETWORKING > START */
    const { mutateAsync: bulkUpdateLabelsMut } = useMutation(
        async ({
            riskProfiles,
            labels,
            replace,
        }: {
            riskProfiles: IRiskProfile[]
            labels: string[]
            replace?: boolean
        }) => bulkUpdateLabels(riskProfiles, labels, replace)
    )

    const pageSizeOpts = usePaginationFlag({
        flagName: "feature_risk_profiles_pagination_options",
        rowTolgeeKey: "riskProfiles",
    })

    const fsAssetsEnabled = useIsFlagEnabled("feature_assets")
    const isStageManagerEnabled = useIsFlagEnabled("feature_stage_manager")

    const { mutateAsync: bulkUpdateMut } = useMutation(
        async ({
            riskProfiles,
            payload,
        }: {
            riskProfiles: IRiskProfile[]
            payload: IPrimitivesDictionary
        }) => bulkUpdate(riskProfiles, payload)
    )

    const { mutateAsync: bulkDeleteMut } = useMutation(
        async (riskProfiles: IRiskProfile[]) => bulkDelete(riskProfiles)
    )
    /* LABELS QUERIES */
    const { data: labels } = useQuery<Record<string, string>>({
        queryKey: ["riskProfileLabels", selectedAccount],
        queryFn: () =>
            rpLabelsQuerySet.get("").then((response) => {
                if (!isValidResponse(response)) return
                return response.data.reduce(
                    (prev: Record<string, string>, curr: ILabel) => ({
                        ...prev,
                        [curr.id as string]: curr.name,
                    }),
                    {}
                )
            }),
        enabled: !!selectedAccount,
    })

    /* HAZARD PROFILES QUERIES */
    const { data: hazardVariables, isLoading: isHazardProfilesLoading } =
        useQuery<Record<string, string>>({
            queryKey: ["hazardVariablesObj", selectedAccount],
            queryFn: () =>
                hazardVariableQuerySet.get("").then((response) => {
                    if (!isValidResponse(response)) return
                    return response.data.reduce(
                        (
                            prev: Record<string, string>,
                            hazardVariable: IHazardVariable
                        ) => ({
                            ...prev,
                            [hazardVariable.id]: hazardVariable.readable_name,
                        }),
                        {}
                    )
                }),
            enabled: !!selectedAccount,
        })

    const hazardVariableOrder = useMemo(() => {
        if (!hazardVariables) return {}
        return Object.entries(hazardVariables)
            .sort((a, b) => {
                if (a[1] < b[1]) return -1
                if (a[1] > b[1]) return 1
                return 0
            })
            .reduce((prev: Record<string, number>, curr, idx) => {
                return { ...prev, [curr[0]]: idx }
            }, {})
    }, [hazardVariables])

    const { data: impactFunctions } = useQuery<Record<string, string>>(
        ["impactFunctions", "riskProfiles"],
        () =>
            impactFunctionQuerySet.get("/").then((response) => {
                if (!isValidResponse(response)) return
                return response.data.reduce(
                    (
                        prev: Record<string, string>,
                        impactFunction: IImpactFunction
                    ) => ({
                        ...prev,
                        [impactFunction.id]: impactFunction.readable_name,
                    }),
                    {}
                )
            })
    )
    /* NETWORKING < END */

    /* FILTER STATE */
    const [selectedLabels, LabelFilter] = useFilter({
        options: labels ?? {},
        placeholder: t("label", "Label"),
        rememberFilterKey: REMEMBERED_FILTERS.riskProfiles.labels,
    })
    const [selectedVarieties, VarietyFilter] = useFilter({
        options: varietiesOpts,
        placeholder: t("asset", "Asset"),
        rememberFilterKey: REMEMBERED_FILTERS.riskProfiles.varieties,
    })
    const [selectedHazards, HazardFilter] = useFilter({
        options: hazardVariables ?? {},
        placeholder: t("variable", "Variable"),
        rememberFilterKey: REMEMBERED_FILTERS.riskProfiles.variables,
    })
    const [selectedStages, StageFilter] = useFilter({
        options: stageOpts ?? {},
        placeholder: t("stage", "Stage"),
        rememberFilterKey: REMEMBERED_FILTERS.riskProfiles.stages,
    })
    const [rowSelection, setRowSelection] = useState<IBooleanDictionary>({})

    const includeFilter = (
        filterKey: string,
        selection: IBooleanDictionary
    ) => {
        const keys = Object.keys(selection)
        if (keys.length) {
            setRiskProfilesFilter((riskProfilesFilter) => ({
                ...riskProfilesFilter,
                [filterKey]: keys,
            }))
        } else {
            setRiskProfilesFilter((riskProfilesFilter) => ({
                ...riskProfilesFilter,
                [filterKey]: undefined,
            }))
        }
    }
    useEffect(() => {
        includeFilter("hazard_variables_ids", selectedHazards)
        includeFilter("variety_ids", selectedVarieties)
        includeFilter("labels", selectedLabels)
        includeFilter("stages", selectedStages)

        if (searchTerm) {
            setRiskProfilesFilter((riskProfilesFilter) => ({
                ...riskProfilesFilter,
                search_term: searchTerm,
            }))
        } else {
            setRiskProfilesFilter((riskProfilesFilter) => ({
                ...riskProfilesFilter,
                search_term: undefined,
            }))
        }
    }, [
        selectedAccount,
        searchTerm,
        selectedLabels,
        selectedHazards,
        selectedStages,
        selectedVarieties,
    ])

    // TODO: All this sorting section could use a revamp but we have to
    // define what is going to be sorted by the backend and what is going
    // to be sorted by the frontend before settling on a strategy.
    const sortingStrategies: Record<
        string,
        (a: IRiskProfile, b: IRiskProfile) => [string | number, string | number]
    > = {
        hazard_variable: (_a, _b) => {
            const hazardCountA = _a.hazard_profiles.length
            const hazardCountB = _b.hazard_profiles.length

            if (hazardCountA !== hazardCountB)
                return [hazardCountA, hazardCountB]

            const a =
                hazardVariableOrder[_a.hazard_profiles[0].hazard_variable.id]
            const b =
                hazardVariableOrder[_b.hazard_profiles[0].hazard_variable.id]
            if (a && b) return [a, b]
            // This is a fallback comparison, helpful with disabled variables
            return [
                _a.hazard_profiles[0].hazard_variable.readable_name,
                _b.hazard_profiles[0].hazard_variable.readable_name,
            ]
        },

        name: (a, b) => [a.name.toLowerCase(), b.name.toLowerCase()],
    }

    // Clear risk profiles filters
    useEffect(() => {
        setRiskProfilesFilter(undefined)
    }, [])

    const riskProfiles = useMemo(() => {
        const sortedRiskProfiles = _.cloneDeep(filteredRiskProfiles)
        /* SORTING */
        if (sorting.length > 0) {
            const colSort = sorting[0] // TODO: Add support for multisorting
            const sortStrategy = sortingStrategies[colSort.id]
            if (sortStrategy) {
                setIsSortingLocally(true)
                sortedRiskProfiles.sort(
                    (_a: IRiskProfile, _b: IRiskProfile) => {
                        const [a, b] = sortStrategy(_a, _b)
                        let orderVal = 0
                        if (a > b) orderVal = 1
                        else if (a < b) orderVal = -1
                        if (colSort.desc) orderVal *= -1
                        return orderVal
                    }
                )
                setIsSortingLocally(false)
            }
        }
        return sortedRiskProfiles
    }, [filteredRiskProfiles, sorting, locationsObj])

    useEffect(() => {
        setRowSelection({})
    }, [filteredRiskProfiles])

    const selectedRiskProfiles = useMemo(() => {
        return filteredRiskProfiles.filter(
            (riskProfile: IRiskProfile) => rowSelection[riskProfile.id]
        )
    }, [filteredRiskProfiles, rowSelection])

    // RISK PROFILE UPLOAD
    const { mutateAsync: addRiskProfile } = useMutation(
        (riskProfile: RiskProfileInput) =>
            riskProfileQuerySet.post(
                "/",
                riskProfile as unknown as IPrimitivesDictionary
            )
    )

    const [parsedBulkUploadData, setParsedBulkUploadData] =
        useState<ICSVDataCell[][]>()
    const [isBulkUploading, setIsBulkUploading] = useState(false)
    // Risk Profile Parser
    const { parser: riskProfileParser, parseOnlyLabels } =
        useRawRiskProfileParser()

    /**
 * TODO: Delete after rehaul completion
    function setNewBulkUploadFile(
        results: { data: ICSVDataCell[][] } | null,
        successAction: () => void
    ) {
        if (!results) return

        const data: ICSVDataCell[][] = []
        for (let i = 0; i < results.data.length; i++) {
            const rowData = results.data[i].join("")
            if (
                isEmptyString(rowData) || // ignore empty rows
                i === 0 // ignore header row
            )
                continue
            data.push(results.data[i])
        }

        setParsedBulkUploadData(data)
        setIsBulkUploading(false) // Close previous modal (where the file was uploaded)

        // File read successfully!
        successAction()
    }
    */

    const handleImport = async (
        data: ICSVDataCell[][],
        uploadStatusCallback: (status: IBulkUploadStatus) => void,
        doneUploadingCallback: (status: IBulkUploadStatus) => void
    ) => {
        const bulkStatus = {
            rows: [] as (string | number)[],
            current: 0,
        }
        const promises: Promise<unknown>[] = []
        await parseOnlyLabels(data)
        await Promise.all(
            data.map(async (rawRiskProfile: ICSVDataCell[], index: number) => {
                bulkStatus.rows[index] = "waiting"
                // Parse the risk profile into an object
                const riskProfile = await riskProfileParser(
                    rawRiskProfile,
                    hazardVariables ?? {},
                    impactFunctions ?? {},
                    selectedAccount as string
                )
                if (isParseError(riskProfile)) {
                    bulkStatus.rows[index] = riskProfile.error
                } else if (!riskProfile) {
                    bulkStatus.rows[index] = "error"
                } else {
                    promises.push(
                        addRiskProfile(riskProfile).then((res) => {
                            // It's a valid response
                            if (isValidResponse(res)) {
                                bulkStatus.rows[index] = res.status
                            }
                            // It's an AxiosError
                            else if (res?.response) {
                                bulkStatus.rows[index] = res.response.status
                            }
                            uploadStatusCallback(bulkStatus)
                        })
                    )
                }
                bulkStatus.current++
                uploadStatusCallback(bulkStatus)
            })
        )
        return Promise.all(promises).then(() =>
            doneUploadingCallback(bulkStatus)
        )
    }

    const handleCancel = () => {
        setParsedBulkUploadData(undefined)
        queryClient.invalidateQueries(["riskProfiles", selectedAccount])
    }

    /*
     * TODO: Delete this after rehaul completion
    const handleBulkUploadFileChange = (
        newFile: File,
        successAction: () => void
    ) => {
        // Parses CSV and calls the complete arrow function when done, passing in the results
        const parseConfig = {
            complete: (results: { data: ICSVDataCell[][] } | null) =>
                setNewBulkUploadFile(results, successAction),
        }
        Papa.parse(newFile, parseConfig)
    }
    */

    const downloadRiskProfiles = (riskProfiles: IRiskProfile[]) => {
        const csvData = riskProfiles.reduce(
            (prev: string, curr: IRiskProfile) =>
                `${prev}\n${riskProfileToCSVRow(curr)}`,
            ""
        )
        saveAs(
            new Blob([`${TEMPLATE_RISK_CSV_DATA[0].join(",")}${csvData}`], {
                type: "text/csv;charset=utf-8",
            }),
            "test.csv"
        )
    }

    const isLoading = (() =>
        loadingRisks ||
        isHazardProfilesLoading ||
        isBulkActionLoading ||
        isSortingLocally)()

    /* LABELS > NETWORK > START */
    const [allLabels] = useMemoQuery<ILabel[]>(
        ["riskProfilesLabels", selectedAccount],
        () => rpLabelsQuerySet.get(""),
        { enabled: !!selectedAccount },
        undefined,
        []
    )
    const { mutateAsync: editLabel } = useMutation(
        (label: ILabel) =>
            rpLabelsQuerySet.put(`/${label.id}`, {
                name: label.name,
                account_id: selectedAccount,
                color: label.color,
            } as IPrimitivesDictionary),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["riskProfilesLabels"])
            },
        }
    )
    const { mutateAsync: addNewLabel } = useMutation(
        (label: ILabel) =>
            rpLabelsQuerySet.post("", {
                ...label,
                account_id: selectedAccount,
            } as unknown as IPrimitivesDictionary),
        {
            onSuccess: (response) => {
                if (!isValidResponse(response)) return
                queryClient.invalidateQueries(["riskProfilesLabels"])
            },
        }
    )
    const { mutateAsync: deleteLabel } = useMutation(
        (labelID: string) => rpLabelsQuerySet.delete(`/${labelID}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["riskProfilesLabels"])
            },
        }
    )
    /* LABELS < NETWORK < END */

    const actualColumns = useMemo(() => {
        const newColumns = [...columns]
        if (isStageManagerEnabled)
            // 6 is the array position where we want the riskProfileStagesCell
            // dynamic column to be inserted and displayed
            newColumns.splice(4, 0, riskProfileStagesCell)
        return newColumns
    }, [isStageManagerEnabled])

    return (
        <ResponsivePaddingWrapper extraClasses="elevation-1 border-r border-gray-5 !pr-4">
            <div className="flex flex-col h-full grow gap-2">
                <GenericPageHeader
                    pageTitle={t("riskProfilesCOUNT", {
                        count: filteredRiskProfilesInfo?.count ?? 0,
                    })}
                    right={
                        <div className="flex flex-row flex-wrap items-center justify-end lg:jusitfy-between lg:flex-nowrap">
                            {/* UPLOAD BUTTON */}
                            <TooltipV2
                                content={t(
                                    "importRiskProfiles",
                                    "Import Risk Profiles"
                                )}
                                position="bottom">
                                <div
                                    className="w-6 h-6 mx-2 cursor-pointer"
                                    onClick={() => setIsBulkUploading(true)}>
                                    <UploadIcon fill="#666D74" />
                                </div>
                            </TooltipV2>

                            {/* DOWNLOAD BUTTON */}
                            {riskProfiles && riskProfiles.length > 0 && (
                                <TooltipV2
                                    content={t(
                                        "exportRiskProfiles",
                                        "Export Risk Profiles"
                                    )}
                                    position="bottom">
                                    <div
                                        onClick={() =>
                                            downloadRiskProfiles(riskProfiles)
                                        }
                                        className="w-6 h-6 mx-2 cursor-pointer">
                                        <DownloadIcon fill="#666D74" />
                                    </div>
                                </TooltipV2>
                            )}
                            <span className="w-24 sm:w-48 shrink-0">
                                <DebounceSearchInput
                                    onSearch={setSearchTerm}
                                    onSubmit={setSearchTerm}
                                    placeholder={t("search")}
                                />
                            </span>
                            {/* ADD BUTTON */}
                            <span className="ml-2">
                                <Button
                                    icon={<PlusIcon />}
                                    label={t(
                                        "newRiskProfile",
                                        "New Risk Profile"
                                    )}
                                    onClick={() => {
                                        setCurrentRiskProfileById(undefined)
                                        setIsFormOpen(true)
                                    }}
                                />
                            </span>
                        </div>
                    }
                    bottomSectionPadding=""
                    bottom={
                        <div className="flex flex-col gap-2 w-full">
                            <div className="flex flex-row flex-wrap items-center gap-1">
                                {fsAssetsEnabled && VarietyFilter}
                                {LabelFilter}
                                {HazardFilter}
                                {StageFilter}
                            </div>

                            {selectedRiskProfiles.length > 0 && (
                                <div className="flex flex-row w-full gap-2">
                                    <div className="px-4 label-lg rounded bg-gray-5 text-gray-90 flex flex-row items-center h-[32px]">
                                        {selectedRiskProfiles.length}{" "}
                                        {t("selected", "Selected")}
                                    </div>
                                    <Button
                                        disabled={isBulkActionLoading}
                                        label={t(
                                            "bulkAddLabel",
                                            "Bulk Add Label"
                                        )}
                                        type="secondary-small"
                                        onClick={() => setShowBulkLabels(true)}
                                    />
                                    <Button
                                        disabled={isBulkActionLoading}
                                        label={t(
                                            "editProbability",
                                            "Edit Probability"
                                        )}
                                        type="secondary-small"
                                        onClick={() =>
                                            setShowBulkProbability(true)
                                        }
                                    />
                                    <Button
                                        label={""}
                                        disabled={isBulkActionLoading}
                                        type="secondary-small"
                                        icon={<DownloadIcon />}
                                        onClick={() => {
                                            setBulkActionLoading(true)
                                            downloadRiskProfiles(
                                                selectedRiskProfiles
                                            )
                                            setBulkActionLoading(false)
                                        }}
                                    />
                                    <Button
                                        label={""}
                                        disabled={isBulkActionLoading}
                                        type="secondary-small"
                                        icon={<TrashIcon />}
                                        onClick={() => {
                                            confirmationModal({
                                                title: t(
                                                    "confirmBulkRiskProfileDelete",
                                                    "{count, plural, =1 {Do you want to delete {count} risk profile?} other {Do you want to delete {count} risk profiles?}}",
                                                    {
                                                        count: selectedRiskProfiles.length,
                                                    }
                                                ),
                                                text: isStageManagerEnabled
                                                    ? t(
                                                          "actionWillDeleteAssociatedAlertsAndFromAssetsTimelines",
                                                          "This action will delete associated alerts & remove risk profiles from the timelines of the associated assets. Do you want to continue?"
                                                      )
                                                    : t(
                                                          "actionWillDeleteAssociatedAlerts",
                                                          "This action will delete the associated alerts. Do you want to continue?"
                                                      ),
                                                onContinueLabel: t(
                                                    "delete",
                                                    "Delete"
                                                ),
                                                onContinue: () => {
                                                    setBulkActionLoading(true)
                                                    bulkDeleteMut(
                                                        selectedRiskProfiles
                                                    )
                                                        .then(() =>
                                                            queryClient.invalidateQueries(
                                                                [
                                                                    "riskProfiles",
                                                                    selectedAccount,
                                                                ]
                                                            )
                                                        )
                                                        .then(() => {
                                                            enqueueAlert(
                                                                t(
                                                                    "riskProfilesDeleteSuccess",
                                                                    "Risk Profiles deleted successfully"
                                                                )
                                                            )
                                                            setBulkActionLoading(
                                                                false
                                                            )
                                                        })
                                                },
                                            })
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    }
                />
                {isLoading && <div className="loading-screen__loader" />}
                {/* RISK PROFILE TABLE */}
                <div className="min-h-0 mt-3 grow">
                    <Table<IRiskProfile>
                        columns={actualColumns}
                        data={riskProfiles}
                        getRowId={({ id }) => id}
                        noDataMessage={t(
                            "noRiskProfiles",
                            "There are no risk profiles to display"
                        )}
                        paginationOptions={pageSizeOpts}
                        outOfText={t("of", "of")}
                        extraClasses={"overflow-y-scroll"}
                        state={{
                            rowSelection,
                            globalFilter: "",
                            hiddenColumns: !fsAssetsEnabled ? ["assets"] : [],
                            sorting,
                        }}
                        setSorting={setSorting}
                        setRowSelection={setRowSelection}
                        enableMultiSort={false}
                    />
                </div>
            </div>

            {/* BULK UPLOAD COMPONENTS */}
            <BulkUploadRiskProfiles
                isOpen={isBulkUploading}
                onBulkUploadFileChange={() => {
                    // TODO: Delete this when the rehaul is in place
                }}
                onCancel={() => {
                    // TODO: Delete this when the rehaul is in place
                    // handleCancel()
                    setIsBulkUploading(false)
                }}
            />
            <BulkUploadCSVParser
                uploadDataType="risk_profiles"
                tableHeaderColumnData={
                    RISK_PROFILES_BULK_CSV_HEADER_COLUMN_DATA
                }
                data={parsedBulkUploadData}
                modalDimensions={{ width: 861, height: 534 }}
                handleImport={handleImport}
                handleCancel={handleCancel}
                stayOnPage={true}
            />
            {/* RISK PROFILE FORM */}
            <RiskProfileForm
                riskProfile={riskProfile}
                onCancel={() => {
                    setIsFormOpen(false)
                    queryClient.invalidateQueries([
                        "riskProfiles",
                        selectedAccount,
                    ])
                }}
                isOpen={isFormOpen}
            />
            {/* BULK EDIT PROBABILITY */}
            <BulkProbabilityForm
                open={showBulkProbability}
                onCancel={() => setShowBulkProbability(false)}
                onSave={(probability) => {
                    setBulkActionLoading(true)
                    bulkUpdateMut({
                        riskProfiles: selectedRiskProfiles,
                        payload: { probability },
                    })
                        .then(() =>
                            queryClient.invalidateQueries([
                                "riskProfiles",
                                selectedAccount,
                            ])
                        )
                        .then(() => {
                            enqueueAlert(
                                t(
                                    "newBulkProbability",
                                    "{count, plural, =1 {Set {probability}% probability for this profile} other {Set {probability}% probability for {count} profiles}}",
                                    {
                                        count: selectedRiskProfiles.length,
                                        probability: formatter.format(
                                            probability * 100
                                        ),
                                    }
                                )
                            )
                            setShowBulkProbability(false)
                            setBulkActionLoading(false)
                        })
                }}
            />
            {/* BULK EDIT LABELS */}
            <BulkLabelsForm
                open={showBulkLabels}
                onCancel={() => setShowBulkLabels(false)}
                onSave={(labels, replace) => {
                    setBulkActionLoading(true)
                    bulkUpdateLabelsMut({
                        riskProfiles: selectedRiskProfiles,
                        labels: labels.map((label) => label.id ?? ""),
                        replace,
                    })
                        .then(() =>
                            queryClient.invalidateQueries([
                                "riskProfiles",
                                selectedAccount,
                            ])
                        )
                        .then(() => {
                            enqueueAlert(
                                t(
                                    "newLabelsAdded",
                                    "{count, plural, =1 { New labels added to this profile } other { New labels added to {count} profiles }}",
                                    { count: selectedRiskProfiles.length }
                                )
                            )
                            setShowBulkLabels(false)
                            setBulkActionLoading(false)
                        })
                }}
                allLabels={allLabels}
                editLabel={editLabel}
                addNewLabel={addNewLabel}
                deleteLabel={deleteLabel}
            />
        </ResponsivePaddingWrapper>
    )
}
export default RiskProfilesTableView
