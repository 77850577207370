import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
} from "react"
import { decodeToken, isExpired } from "react-jwt"
import {
    UseMutateAsyncFunction,
    useMutation,
    useQueryClient,
} from "react-query"
import {
    IPrimitivesDictionary,
    IRoute,
    IUser,
} from "../climateui/types"
import {
    CAIResponse,
    getUserToken,
    isValidResponse,
    storeUserToken,
} from "../climateui/utils/http"
import { IDecodedToken, IRole, IPermission } from "../types"
import {
    loginPOST,
    logoutPOST,
    resetQuerySet,
    roleListGET,
    tokenRefreshPOST,
    userGET,
} from "../utils/networking"
import { IRoutesFlags, useMemoQuery } from "../hooks"
import { arrToDict } from "../utils/transform"
import { useNavigate } from "react-router-dom"
import { ToastContext } from "../climateui/providers"
import { useTranslate } from "@tolgee/react"
import { LocalizationContext } from "./LocalizationProvider"
import { IAccountContext } from "./AccountProvider"
import { useFlagsmith } from "flagsmith/react"
import { datadogRum } from "@datadog/browser-rum"

/* CONSTS */
const REFRESH_BUFFER = 1000 // 1000ms

/* TYPES > START */
export interface IAuthContext {
    user: IUser | null
    login: (
        email: string,
        password: string
    ) => Promise<{ error: boolean; errorMessage: string }>
    logout: () => void
    isLoginLoading: boolean
    roles: Record<string, string[]>
    hasPermissions: (accountId: string, permissions: string[]) => boolean
    hasRole: (accountId: string, roleId: string) => boolean
    resetPassword: UseMutateAsyncFunction<
        unknown,
        unknown,
        IPrimitivesDictionary,
        unknown
    >
    requestPasswordReset: UseMutateAsyncFunction<
        unknown,
        unknown,
        string,
        unknown
    >
    setTokenFromResponse: (response: CAIResponse) => void
    lastUsedAccountID?: string
}
/* TYPES < END */
const AuthContext = createContext<IAuthContext>(
    {} as IAuthContext
)
export const useAuth = () => useContext(AuthContext)

export const isAllowedToRoute = (
    route: IRoute,
    acc: IAccountContext,
    auth: IAuthContext,
    routesFeatureFlags: IRoutesFlags
) => {
    if (
        route.featureFlags &&
        !routesFeatureFlags.allRouteFlagsEnabled(route.featureFlags)
    )
        return false

    if (
        route.accountPermissions &&
        !acc.hasPermissions(route.accountPermissions)
    )
        return false

    if (
        route.role &&
        acc.selectedAccount &&
        !auth.hasRole(acc.selectedAccount, route.role)
    )
        return false

    if (
        route.permissions &&
        acc.selectedAccount &&
        !auth.hasPermissions(acc.selectedAccount, route.permissions)
    )
        return false

    return true
}

const AuthProvider = ({ children }: { children: ReactNode }) => {
    /* HOOKS > START */
    const navigate = useNavigate()
    const { enqueueAlert } = useContext(ToastContext)
    const { changeLocale } = useContext(LocalizationContext)
    const flagsmith = useFlagsmith()
    const { t } = useTranslate()
    const queryClient = useQueryClient()
    /* HOOKS < END */
    /* STATE > START */
    const [user, setUser] = useState<IUser | null>(null)
    const [isRefreshing, setIsRefreshing] = useState<boolean>(true)
    const [decodedToken, setDecodedToken] = useState<IDecodedToken | undefined>()

    const setTokenFromResponse = (response: CAIResponse) => {
        if (!isValidResponse(response)) return
        // Check if it is a valid token
        const _token = decodeToken<IDecodedToken>(response.data.access_token)
        // TODO: Throw error / Inform user some way
        if (!_token) {
            return
        }
        if (!_token.account_id) {
            enqueueAlert(
                t(
                    "noAccountOnUser",
                    "You are not currently linked to any account, please contact our support team."
                )
            )
            return
        }

        setDecodedToken(_token)
        storeUserToken({
            access_token: response.data.access_token,
            refresh_token:
                response.data.refresh_token || getUserToken()?.refresh_token,
        })
        queryClient.invalidateQueries(["user"])
        setIsRefreshing(false)
    }
    /* STATE < END */

    /* NETWORKING > START */
    const reqUser = async () => {
        if (!decodedToken) return null
        return await userGET(decodedToken.sub)
    }
    const { mutateAsync: reqLogin, isLoading: isLoginLoading } =
        useMutation(loginPOST)
    const { mutateAsync: reqLogout } = useMutation(logoutPOST)
    const { mutateAsync: reqRefresh, isLoading: isTokenRefreshLoading } =
        useMutation(tokenRefreshPOST)
    const { mutateAsync: requestPasswordReset } = useMutation((email: string) =>
        resetQuerySet.post("", { email }, undefined, undefined, false)
    )
    const { mutateAsync: resetPassword } = useMutation(
        ({ token, password }: IPrimitivesDictionary) =>
            resetQuerySet.put("", { password }, { token }, undefined, false)
    )
    /* NETWORKING < END */

    /* UTILS > START */
    const deauth = () => {
        // Nullify all preexisting credentials
        storeUserToken(null)
        setDecodedToken(undefined)
        setUser(null)
    }
    const refreshSession = () => {
        setIsRefreshing(true)
        // Logout if the refresh token is expired
        if (isExpired(getUserToken()?.refresh_token as string)) {
            storeUserToken(null)
            setIsRefreshing(false)
            reqLogout().finally(() => navigate("/login"))
            return
        }
        reqRefresh().then((response) => {
            setTokenFromResponse(response)
        })
    }
    /* UTILS < END */

    /* MEMO FIELDS > START */
    const [, { isLoading: isLoadingUser }] = useMemoQuery(
        ["user", decodedToken],
        () => reqUser(),
        {
            enabled: decodedToken?.sub ? true : false,
        },
        (data) => {
            setUser(data as IUser)
            return data
        },
        null
    )
    const [allRoles, { isLoading: isLoadingRoles }] = useMemoQuery<
        Record<string, IRole>
    >(
        ["allRoles", user],
        () => roleListGET(),
        { enabled: decodedToken?.sub ? true : false },
        (data) => arrToDict(data as IRole[], "id"),
        {}
    )
    const roles = useMemo(() => {
        if (!decodedToken) return {}
        return decodedToken.roles.reduce(
            (prev: Record<string, string[]>, curr: string) => {
                const [accountId, roleId] = curr.split(":")
                if (!prev[accountId])
                    return { ...prev, [accountId]: roleId ? [roleId] : [] }
                prev[accountId].push(roleId)
                return prev
            },
            {}
        )
    }, [decodedToken, allRoles])
    const lastUsedAccountID = useMemo(() => {
        if (!decodedToken || !decodedToken.account_id) return
        return decodedToken.account_id
    }, [decodedToken])
    /* MEMO FIELDS < END */

    /* AUTH METHODS > START */
    const login = async (email: string, password: string) => {
        const response = await reqLogin({ email, password })
        if (!isValidResponse(response)) {
            if (response?.response?.status === 403) {
                enqueueAlert(
                    t(
                        "userNotActive",
                        "Your user is not active. Please contact our support team."
                    )
                )
                return {
                    error: true,
                    errorMessage: "",
                }
            } else if (response?.response?.status === 401) {
                return {
                    error: true,
                    errorMessage: t(
                        "incorrectCredentials",
                        "Incorrect credentials"
                    ),
                }
            }
            return {
                error: true,
                errorMessage: t(
                    "loginFailed",
                    "Login failed, please try again later."
                ),
            }
        }

        setTokenFromResponse(response)
        return {
            error: false,
            errorMessage: "",
        }
    }
    const logout = () => {
        reqLogout()
        deauth()
        localStorage.clear()
        flagsmith.logout()
        datadogRum.clearUser()
    }

    const hasPermissions = (accountId: string, permissions: string[]) => {
        // If the user is uberadmin, return true
        if (decodedToken?.uber_admin) return true
        // If the permissions are undefined or empty, then the user is granted permission
        if (!permissions || permissions.length === 0) return true
        // If the user has no relation to this account, then it has no permissions
        if (!roles[accountId]) return false
        const currRoles = roles[accountId]
        // If the user has no role for this account, then it has no permissions
        if (currRoles.length === 0) return false
        // Get the users available permissions
        const currPermissions = currRoles.reduce(
            (currPerms: Set<string>, role: string) => {
                if (allRoles[role])
                    allRoles[role].permissions.forEach(
                        (permission: IPermission) =>
                            currPerms.add(permission.name)
                    )
                return currPerms
            },
            new Set<string>()
        )
        // Determine if the user has ALL the queried permissions
        return permissions.every((permission) =>
            currPermissions.has(permission)
        )
    }

    const getRoleByName = (roleName: string) => {
        const normName = roleName.trim().toLowerCase()
        // Case-sensitive
        return Array.from<IRole>(Object.values(allRoles)).find(
            (role) => {
                return role.name.trim().toLowerCase() === normName
            }
        )
    }

    const hasRole = useCallback(
        (accountId: string, roleName: string): boolean => {
            // If the user is uberadmin, return true
            if (decodedToken?.uber_admin) return true
            // If the user has no relation to this account, then it has no permissions
            if (!roles[accountId]) return false
            // If the role doesn't exist, the user doesn't have it
            const role = getRoleByName(roleName)
            if (!role) return false
            const currRoles = roles[accountId]
            // If the user has no role for this account, then it has no permissions
            if (currRoles.length === 0) return false

            return currRoles.includes(role.id)
        },
        [roles, allRoles]
    )
    /* AUTH METHODS < END */

    /* LIFECYCLE HOOKS > START */
    // Update the app language
    useEffect(() => {
        if (user && user.language) changeLocale(user.language)
    }, [user])

    // Update the access token
    useEffect(() => {
        if (!decodedToken) return
        // timeout (ms) = token epoch expiration - current epoch - refresh buffer
        const timeout =
            decodedToken.exp * 1000 - new Date().getTime() - REFRESH_BUFFER
        setTimeout(refreshSession, timeout)
    }, [decodedToken])

    // Get token from localStorage
    useEffect(() => {
        if (decodedToken || isTokenRefreshLoading) return
        const tokens = getUserToken()
        if (!tokens || !tokens.refresh_token) return setIsRefreshing(false)
        refreshSession()
    }, [decodedToken])
    /* LIFECYCLE HOOKS < END */

    return (
        <AuthContext.Provider
            value={{
                user,
                login,
                logout,
                isLoginLoading:
                    isLoginLoading || // login request
                    isTokenRefreshLoading || // refresh token request
                    isRefreshing || // refresh token flow
                    isLoadingUser || // user data request
                    isLoadingRoles, // user roles request
                roles,
                hasPermissions,
                hasRole,
                resetPassword,
                requestPasswordReset,
                setTokenFromResponse,
                lastUsedAccountID,
            }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
