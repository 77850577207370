import { useQuery } from "react-query"
import { isValidResponse } from "../climateui/utils/http"
import { createGraphQLProxy, hazardVariablesGRAPHQL } from "../utils/networking"

const useHazardVariablesQuery = (selectedAccount: string | undefined) =>
    useQuery({
        queryKey: ["hazardVariables", selectedAccount],
        queryFn: async () => {
            if (!selectedAccount) return
            const response = await hazardVariablesGRAPHQL()
            if (!isValidResponse(response)) return []
            return response.data.data.hazard_variables.results.map(
                createGraphQLProxy
            )
        },
        enabled: !!selectedAccount,
    })
export default useHazardVariablesQuery
