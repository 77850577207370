import { ReactNode, useEffect, useState } from "react"
import { DraggableElement } from "../../../climateui/components"
import { IPrimitivesDictionary } from "../../../climateui/types"
import { useSeasonalCalendar } from "../../../providers/SeasonalCalendarProvider"
import { IPlannedRisk, IStage } from "../../../types"
import { daysBetween } from "../../../utils"
import { moveDragAuxDate, moveStartDate } from "./utils"

function DraggableItem({
    item,
    updateItem,
    isFocused,
    globalCalendarLeft,
    renderElement,
    draggingCallback,
    dragEndCallback,
}: {
    item: IPlannedRisk | IStage
    updateItem: (newItem: IPlannedRisk | IStage) => void
    isFocused: boolean
    globalCalendarLeft: number
    renderElement: ({
        cursorClass,
        mainStyle,
        setPxFromTimelineStart,
        daysFromTimelineStart,
        duration,
        setDuration,
    }: {
        cursorClass: string
        mainStyle: IPrimitivesDictionary
        setPxFromTimelineStart: (px: number) => void
        daysFromTimelineStart: number
        duration: number
        setDuration: (duration: number) => void
    }) => ReactNode
    draggingCallback?: (left: number) => void
    dragEndCallback?: () => void
}) {
    const {
        timelineStartDate,
        pxPerDay,
        setDragAuxDate,
        dragAuxDate,
        isEditingCalendar,
    } = useSeasonalCalendar()
    const daysFromTimelineStart = daysBetween(
        timelineStartDate,
        new Date(item.start_date ?? "")
    )

    const [cursorClass, setCursorClass] = useState<string>("cursor-grab")
    const [pxFromTimelineStart, setPxFromTimelineStart] = useState<number>(
        daysFromTimelineStart * pxPerDay + globalCalendarLeft
    )
    const [duration, setDuration] = useState<number>(item.duration ?? 0)

    const mainStyle = {
        width: pxPerDay * duration + "px",
        left: pxFromTimelineStart + "px",
    }

    useEffect(() => {
        setPxFromTimelineStart(
            pxPerDay * daysFromTimelineStart + globalCalendarLeft
        )
        setDuration(item.duration ?? 0)
    }, [
        item.start_date,
        item.duration,
        item.end_date,
        daysFromTimelineStart,
        globalCalendarLeft,
        pxPerDay,
    ])

    return (
        <DraggableElement
            draggable={isFocused && isEditingCalendar}
            draggingCallback={(pxMoved: number) => {
                setCursorClass("cursor-grabbing")
                const daysMoved = Math.round(pxMoved / pxPerDay)

                const itemStartDate = new Date(item.start_date ?? "")
                const movedDate = new Date(itemStartDate)
                movedDate.setDate(movedDate.getDate() + daysMoved)
                if (daysBetween(timelineStartDate, movedDate) < 0) return

                moveStartDate(
                    daysFromTimelineStart,
                    daysMoved,
                    1 - duration,
                    pxPerDay,
                    setPxFromTimelineStart
                )
                moveDragAuxDate(
                    (item.start_date ?? new Date())?.toISOString(),
                    timelineStartDate,
                    daysMoved,
                    0,
                    setDragAuxDate
                )
                draggingCallback?.(pxPerDay * daysMoved)
            }}
            dragEndCallback={() => {
                updateItem({
                    ...item,
                    start_date: dragAuxDate,
                })
                setDragAuxDate(undefined)
                setCursorClass("cursor-grab")
                dragEndCallback?.()
            }}>
            {renderElement({
                cursorClass,
                mainStyle,
                setPxFromTimelineStart,
                daysFromTimelineStart,
                duration,
                setDuration,
            })}
        </DraggableElement>
    )
}

export default DraggableItem
