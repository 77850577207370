import { ChevronArrow } from "../../icons"
import ChevronBarIcon from "../../icons/ChevronBarIcon"
import { SingleSelect } from "../Inputs"
import PaginationButton from "./PaginationButton"

interface IPaginationControls {
    selectedPageSize: number
    paginationOptions: { [key: string | number]: string }
    handlePageSizeChange: (size: number) => void

    handleFirstClick: () => void
    handlePrevClick: () => void
    handleNextClick: () => void
    handleLastClick: () => void

    backwardDisabled: boolean
    forwardDisabled: boolean

    currentPage: number
    totalPages: number

    outOfText: string

    changeCallback?: () => void
}

export default function PaginationControls({
    selectedPageSize,
    paginationOptions,
    handlePageSizeChange,
    handleFirstClick,
    handlePrevClick,
    handleNextClick,
    handleLastClick,
    backwardDisabled,
    forwardDisabled,
    currentPage,
    totalPages,
    outOfText = "of",
    changeCallback,
}: IPaginationControls) {
    return (
        <div className="flex flex-row items-center justify-end pt-2 shrink-0">
            <div>
                <SingleSelect
                    dropUp
                    selected={selectedPageSize}
                    options={paginationOptions}
                    setSelected={(_pageSize: number | string) => {
                        const size = +_pageSize
                        handlePageSizeChange(size)
                        if (changeCallback) changeCallback()
                    }}
                />
            </div>
            <div className="flex ml-2">
                <PaginationButton
                    icon={<ChevronBarIcon />}
                    extraClasses="rotate-90"
                    handleClick={() => {
                        handleFirstClick()
                        if (changeCallback) changeCallback()
                    }}
                    disabled={backwardDisabled}
                />
                <PaginationButton
                    icon={<ChevronArrow />}
                    extraClasses="rotate-90"
                    handleClick={() => {
                        handlePrevClick()
                        if (changeCallback) changeCallback()
                    }}
                    disabled={backwardDisabled}
                />
                <span className="h-6 px-2 text-center">
                    {currentPage} {outOfText} {totalPages}
                </span>
                <PaginationButton
                    icon={<ChevronArrow />}
                    extraClasses="-rotate-90"
                    handleClick={() => {
                        handleNextClick()
                        if (changeCallback) changeCallback()
                    }}
                    disabled={forwardDisabled}
                />
                <PaginationButton
                    icon={<ChevronBarIcon />}
                    extraClasses="-rotate-90"
                    handleClick={() => {
                        handleLastClick()
                        if (changeCallback) changeCallback()
                    }}
                    disabled={forwardDisabled}
                />
            </div>
        </div>
    )
}
