import { SVGProps } from "react"

const YieldIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        className="fill-inherit"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 2H2V22H22V20H18.2916C19.4725 18.9578 20.3432 17.5724 20.748 16C20.9125 15.3608 21 14.6906 21 14H19C16.913 14 15.0749 15.0655 14 16.6822C12.9251 15.0655 11.087 14 9 14H7C7 14.6906 7.08751 15.3608 7.25204 16C7.65676 17.5724 8.52754 18.9578 9.70835 20H4V14.4081L10.3239 7.95093L14.4373 11.0931L19 6.44587V9H21V3H15V5H17.6168L14.2476 8.43149L10.1325 5.28796L4 11.5497V2ZM12.9852 19.6533C12.8193 17.7208 11.2792 16.1807 9.34667 16.0148C9.95006 17.7077 11.2923 19.0499 12.9852 19.6533ZM15.0148 19.6533C15.1807 17.7208 16.7208 16.1807 18.6533 16.0148C18.0499 17.7077 16.7077 19.0499 15.0148 19.6533Z"
            fill={props.fill}
        />
    </svg>
)

export default YieldIcon
