import { SVGProps } from "react"

const RiskOutlookIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="fill-inherit" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 2H2V22H22V20H9.70835H4V14.4081L10.3239 7.95093L14.4373 11.0931L19 6.44587V9H21V3H15V5H17.6168L14.2476 8.43149L10.1325 5.28796L4 11.5497V2Z" fill={props.fill}/>
        <path d="M17.2887 11L21.2887 18H13.2887L17.2887 11ZM18.3705 9.49353C17.8891 8.66193 16.6884 8.66193 16.2069 9.49354L11.5 17.6237C11.0175 18.457 11.6189 19.5 12.5818 19.5H21.9957C22.9586 19.5 23.5599 18.457 23.0774 17.6237L18.3705 9.49353ZM18 16.375H16.5784V17.625L18 17.6237V16.375ZM18 12.625H16.5784V15.5L18 15.5V12.625Z" fill={props.fill}/>
    </svg>

)

export default RiskOutlookIcon


