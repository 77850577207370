import { CircularProgress } from "@mui/material"
const LoadingAnimation = ({ size = 20, thickness = 6 }) => {
    return (
        <div className="flex">
            <CircularProgress
                thickness={thickness}
                size={size}
                style={{
                    color: "#F06000",
                    alignSelf: "center",
                    justifySelf: "center",
                }}
            />
            <p className="font-normal text-gray-60 ml-[6px] animate-pulse">
                Loading...
            </p>
        </div>
    )
}

export default LoadingAnimation
