import { useContext, useEffect, useMemo, useState } from "react"
import { useTranslate } from "@tolgee/react"
import ImpactOption from "./ImpactOption"
import { useMemoQuery } from "../../../../hooks"
import { impactFunctionQuerySet } from "../../../../utils/networking"
import { IHazardVariable, IImpactFunction, ImpactProfileInput, IRiskProfile } from "../../../../types"
import NumberInput from "../../../../components/NumberInput"
import { UnitConversionContext } from "../../../../providers/UnitConversionProvider"
import { useRiskProfiles } from "../../../../providers/RiskProfilesProvider"

type ImpactFunctionChangeHandler = (impactFunctionField: {
    impactFunction?: IImpactFunction
    maxImpact?: number
    marginalImpact?: number
    initialImpact?: number
}) => void

interface ImpactValues {
    maxImpact: number
    marginalImpact: number
    initialImpact: number
}

export const DEFAULT_VALUES: Record<string, ImpactValues> = {
    default: {
        maxImpact: -0.5,
        marginalImpact: 0,
        initialImpact: -0.2,
    },
    OneTimeConsecutive: {
        initialImpact: -0.15,
        marginalImpact: 0,
        maxImpact: -0.15,
    },
    OneTimeRolling: {
        initialImpact: -0.15,
        marginalImpact: 0,
        maxImpact: -0.15,
    },
    ConstantConsecutive: {
        initialImpact: -0.05,
        marginalImpact: -0.02,
        maxImpact: -0.5,
    },
    ConstantRolling: {
        initialImpact: -0.05,
        marginalImpact: -0.02,
        maxImpact: -0.5,
    },
    ConstantSurplus: {
        initialImpact: -0.05,
        marginalImpact: -0.01,
        maxImpact: -0.5,
    },
}

const ImpactForm = ({
    initialValue,
    onChange,
    aggregation: _aggregation,
    riskProfile,
    hazardsSelected,
}: {
    initialValue?: ImpactProfileInput
    onChange?: ImpactFunctionChangeHandler
    aggregation?: string
    riskProfile?: IRiskProfile
    hazardsSelected?: string
}) => {
    /* STATE */
    const aggregation = useMemo(
        () => _aggregation?.toLowerCase(),
        [_aggregation]
    )
    const [selectedImpactFunction, setImpactFunction] =
        useState<IImpactFunction>()
    const [maxImpact, setMaxImpact] = useState<number | undefined>(
        DEFAULT_VALUES.default.maxImpact
    )
    const [marginalImpact, setMarginalImpact] = useState<number | undefined>(
        DEFAULT_VALUES.default.marginalImpact
    )
    const [initialImpact, setInitialImpact] = useState<number | undefined>(
        DEFAULT_VALUES.default.initialImpact
    )
    const [prevAggregation, setPrevAggregation] = useState<string>()

    const { getUnit } = useContext(UnitConversionContext)
    const { hazardVariablesObj } = useRiskProfiles()
    /* TOLGEE */
    const { t } = useTranslate()

    /* CONST */
    const isOneTimeConsecutive = selectedImpactFunction?.backend_name === "Heaviside"
    const getHazardVariable = (
        riskProfile?: IRiskProfile,
        hazardsSelected?: string,
        hazardVariablesObj?: Record<string, IHazardVariable>,
    ): string => {
        let hazardVariable = ''
        if (riskProfile?.hazard_profiles?.[0]?.hazard_variable) {
            hazardVariable = getUnit(riskProfile.hazard_profiles[0].hazard_variable)
        } else if (hazardsSelected && hazardVariablesObj?.[hazardsSelected]?.units_metric) {
            hazardVariable = hazardVariablesObj[hazardsSelected].units_metric
        }
        return hazardVariable
    }
    const hazardVariable = getHazardVariable(riskProfile, hazardsSelected, hazardVariablesObj)


    /* IMPACT */
    const [impactFunctions, { isLoading }] = useMemoQuery<
        IImpactFunction[]
    >(
        ["impactFunctions"],
        () => {
            return impactFunctionQuerySet.get("")
        },
        undefined,
        undefined,
        []
    )
    const filteredFunctions = useMemo(
        () =>
            impactFunctions.filter(
                (func) => func.aggregation.toLowerCase() === aggregation
            ),
        [impactFunctions, aggregation]
    )

    /* METHODS */
    const chooseFirstAvailFunc = () => {
        // If there are no functions, unset the selected impact function
        if (filteredFunctions.length === 0) {
            setImpactFunction(undefined)
            return undefined
        }
        // If the selected function is not within the filtered results, choose the first one
        if (
            !selectedImpactFunction ||
            !filteredFunctions.includes(selectedImpactFunction)
        ) {
            setImpactFunction(filteredFunctions[0])
            return filteredFunctions[0]
        }
        // else, return whatever the value for the selectedImpactFunction is
        return selectedImpactFunction
    }
    const setFunctionDefaults = (impactFunction?: IImpactFunction) => {
        let funcName = "default"
        // Extract the function name from the image prop, i.e.
        // OneTimeImpact.png => OneTimeImpact
        if (impactFunction)
            funcName = impactFunction.image.replace(/\.\w{2,3}$/, "")
        const impactValues = { ...DEFAULT_VALUES[funcName] }
        setMaxImpact(impactValues.maxImpact)
        setMarginalImpact(impactValues.marginalImpact)
        setInitialImpact(impactValues.initialImpact)
    }
    const resetDefault = () => {
        const selectedFunction = chooseFirstAvailFunc()
        setFunctionDefaults(selectedFunction)
        setPrevAggregation(aggregation)
    }
    // Populate info with initial values
    useEffect(() => {
        // If not initial value was provided, reset to the default
        if (!initialValue) return resetDefault()
        // If the impact functions are not loaded, there's nothing to do
        if (filteredFunctions.length === 0) return
        const initialFunc = filteredFunctions.find(
            (func) => func.id === initialValue?.impact_function_id
        )
        if (initialFunc) setImpactFunction(initialFunc)
        else if (
            !selectedImpactFunction ||
            !filteredFunctions.includes(selectedImpactFunction)
        ) {
            chooseFirstAvailFunc()
        }
        setMaxImpact(initialValue.max_impact)
        setMarginalImpact(initialValue.marginal_impact)
        setInitialImpact(initialValue.initial_impact)
    }, [initialValue, filteredFunctions])

    // Replace the default values when changing aggregation type
    useEffect(() => {
        if (prevAggregation && prevAggregation !== aggregation) resetDefault()
    }, [aggregation])

    // Report edits to onChange function
    useEffect(() => {
        if (onChange)
            onChange({
                impactFunction: selectedImpactFunction,
                maxImpact,
                marginalImpact,
                initialImpact,
            })
    }, [selectedImpactFunction, maxImpact, marginalImpact, initialImpact])

    const marginalMax = useMemo(() => {
        if (!initialImpact || !maxImpact) return 1
        if (maxImpact > initialImpact) return maxImpact // I believe it should be the diff
        return 0
    }, [initialImpact, maxImpact])
    const marginalMin = useMemo(() => {
        if (!initialImpact || !maxImpact) return -1
        if (maxImpact > initialImpact) return 0
        return maxImpact
    }, [initialImpact, maxImpact])

    useEffect(() => {
        if (initialValue?.impact_function_id && impactFunctions) {
            const impactFunc = impactFunctions.find(func => func.id === initialValue.impact_function_id)
            setImpactFunction(impactFunc)
        }
    }, [initialValue, impactFunctions])

    return (
        <div className="py-2 mt-2">
            {
                <div className="flex flex-col gap-1">
                    {/* IMPACT OPTION BUTTONS */}
                    <div className="flex flex-row items-end justify-between">
                        {isLoading && <span>{t("loading", "Loading")}</span>}
                        {!isLoading && (
                            <div className="flex flex-row gap-1">
                                {impactFunctions.map((impactFunc) => (
                                    <div key={impactFunc.id}>
                                        <ImpactOption
                                            readableName={t(
                                                impactFunc.readable_name
                                            )}
                                            icon={impactFunc.icon}
                                            image={impactFunc.image}
                                            selected={
                                                selectedImpactFunction?.id ===
                                                impactFunc.id
                                            }
                                            disabled={
                                                aggregation !==
                                                impactFunc.aggregation.toLowerCase()
                                            }
                                            onClick={() => {
                                                setImpactFunction(impactFunc)
                                                setFunctionDefaults(impactFunc)
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        {/* Using a regular button in favor of Button. Button extraClasses doesn't override styles
                        like font weight. Didn't modify the precedence of extraClasses to avoid weird behaviors in
                        other places */}

                        {/* button with no use*/}
                        {/* <button
                            onClick={resetDefault}
                            className={[
                                "text-sm text-accent underline",
                                "hover:text-accent-dark",
                            ].join(" ")}>
                            {t("resetDefault", "Reset Default")}
                        </button> */}
                    </div>
                    {/* IMPACT FUNCTION CONFIGURATION */}
                    <div className="p-3 border rounded border-gray-14 font-roboto text-gray-90 flex flex-row gap-2 items-center flex-wrap">
                        {/* TODO: Check if this structure works for other languages than 'en' and 'es' */}
                        <p>
                            {t(
                                "impactFunctionWhenTriggeredApply",
                                "When triggered, apply"
                            )}
                        </p>
                        <NumberInput
                            isPercentage
                            initialValue={initialImpact}
                            onChange={setInitialImpact}
                            min={maxImpact && maxImpact < 0 ? maxImpact : -1}
                            max={maxImpact && maxImpact >= 0 ? maxImpact : 1}
                        />
                        <p>{t("impactFunctionImpactThen", "impact, then")}</p>
                        <NumberInput
                            isPercentage
                            initialValue={marginalImpact}
                            onChange={setMarginalImpact}
                            min={marginalMin}
                            max={marginalMax}
                            disabled={isOneTimeConsecutive}
                        />
                        <p>
                            {selectedImpactFunction && selectedImpactFunction.backend_name === "Linear" && hazardVariable ?
                                `${t("forEachAdditional", "for each additional")} ${hazardVariable}, ${t("withAMaxLimitOf", "with a max limit of")}` :
                                t("impactFunctionForEachAdditionalConsecutiveDay", "for each additional consecutive day over the threshold, with a max limit of")
                            }
                        </p>
                        <NumberInput
                            isPercentage
                            initialValue={maxImpact}
                            onChange={setMaxImpact}
                            min={-1}
                            max={1}
                        />
                    </div>
                </div>
            }
        </div>
    )
}
export default ImpactForm
