const Logo = () => {
    return (
        <img
            src="/images/climateai/ClimateAi-new-logo.png"
            className="max-w-[200px] h-auto"
        />
    )
}

export default Logo
