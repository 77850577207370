import React from "react"
import { DefaultParamType, TFnType, useTranslate } from "@tolgee/react"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"

interface IAlertsCardProps {
    count: number | undefined
    alertType: string
    fillColor: string
}

const alertCategoryDescriptions: Record<string, string> = {
    warning: "warningAlertDescription",
    watch: "watchAlertDescription",
}

const LoadingState = ({ t }: { t: TFnType<DefaultParamType, string> }) => {
    return (
        <>
            <div className="flex items-center text-left body-md font-medium text-gray-90">
                <div className="-ml-[2px] body-md">
                    <LoadingAnimation
                        size={12}
                        thickness={6}
                    />
                </div>
            </div>
            <div className="ml-4 text-left body-sm font-light text-gray-60">
                {t("calculatingAlertsinLocs")}
            </div>
        </>
    )
}

const AlertsOrNoAlertsState = ({
    t,
    alertType,
    count,
    fillColor,
}: {
    t: TFnType<DefaultParamType, string>
    alertType: string
    count: number
    fillColor: string
}) => {
    return (
        <>
            <div className="flex items-center text-left body-md font-medium text-gray-90">
                {count !== 0 ? (
                    <>
                        <svg
                            className="mr-2"
                            width="9"
                            height="9"
                            viewBox="0 0 6 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle
                                cx="3"
                                cy="3"
                                r="3"
                                fill={fillColor}
                            />
                        </svg>
                        <span>
                            {t(alertType)} ({count})
                        </span>
                    </>
                ) : (
                    <span className="text-green">
                        {t("noCategoryAlerts", { category: t(alertType) })}
                    </span>
                )}
            </div>
            <div
                className="ml-4 text-left body-sm text-gray-60"
                style={{ marginLeft: count !== 0 ? "16px" : "0px" }}>
                {count !== 0
                    ? t(alertCategoryDescriptions[alertType])
                    : t("zeroAlertsDetected")}
            </div>
        </>
    )
}

const AlertsCard: React.FC<IAlertsCardProps> = ({
    count,
    alertType,
    fillColor,
}) => {
    const { t } = useTranslate()
    // TODO - discuss with Gerry if widths will vary depending on state
    // const width = alertType === "watch" ? "234px" : "195px"

    return (
        <div
            // style={{ width: width }}
            className={
                "pt-2 w-[210px] rounded-md border bg-white p-3 box-border flex flex-col justify-between border-gray-14 ml-3"
            }>
            {count === undefined ? (
                <LoadingState t={t} />
            ) : (
                <AlertsOrNoAlertsState
                    t={t}
                    alertType={alertType}
                    fillColor={fillColor}
                    count={count}
                />
            )}
        </div>
    )
}

export default AlertsCard
