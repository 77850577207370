import { useTranslate } from "@tolgee/react"
import {
    Button,
    DebounceSearchInput,
    Table,
} from "../../../climateui/components"
import { PlusIcon, TrashIcon } from "../../../climateui/icons"
import { useIsFlagEnabled, usePaginationFlag } from "../../../hooks"
import { useAssets } from "../../../providers"
import { IVariety } from "../../../types"
import { columns, timelineColumn } from "./assetsTableUtils"
import { VarietyForm } from "./components"
import { useContext, useMemo, useState } from "react"
import { useOutsideComponentClickHandler } from "../../../climateui/hooks"
import { useFilter } from "../hooks"
import { IBooleanDictionary } from "../../../climateui/types"
import { ModalContext, ToastContext } from "../../../climateui/providers"
import { useBulkDeleteVarieties } from "./utils"
import { EditAttributes, EditDescription } from "./components/bulkModals"
import { GenericPageHeader } from "../../../components"
import { ResponsivePaddingWrapper } from "../../../layouts/TabLayout"

const AssetsView = () => {
    /* HOOKS > START */
    const { t } = useTranslate()
    const paginationOptions = usePaginationFlag({
        flagName: "feature_assets_pagination_options",
        rowTolgeeKey: "assets",
    })
    const { varieties, assets, isLoadingVarieties } = useAssets() // USED HERE
    const assetsOpts = useMemo(() => {
        if (!assets) return {}
        return Object.values(assets).reduce(
            (prev: Record<string, string>, { id, name }) => ({
                ...prev,
                [id]: name,
            }),
            {}
        )
    }, [assets])
    const [selectedAssets, AssetFilter] = useFilter({
        options: assetsOpts,
        placeholder: t("asset", "Asset"),
    })
    const { confirmationModal } = useContext(ModalContext)
    const { enqueueAlert } = useContext(ToastContext)
    const bulkDeleteVarieties = useBulkDeleteVarieties()
    /* HOOKS < END */

    const canViewTimelineColumn = useIsFlagEnabled("feature_stage_manager")
    const actualColumns = useMemo(() => {
        const newColumns = [...columns]
        if (canViewTimelineColumn)
            newColumns.splice(newColumns.length - 1, 0, timelineColumn)

        return newColumns
    }, [canViewTimelineColumn])

    const varietyCount = Object.keys(varieties ?? {}).length
    const sortAlphabetically = (a: string, b: string) => {
        if (a > b) return 1
        if (b > a) return -1
        return 0
    }
    const sortBasedOnDefault = (a: IVariety, b: IVariety) => {
        if (a.is_default && !b.is_default) return -1
        if (!a.is_default && b.is_default) return 1
        return 0
    }
    const processedVarieties = useMemo(() => {
        // processed = sorted & filtered
        if (!varieties) return []
        return Object.values(varieties)
            .filter(({ asset }) => {
                if (Object.keys(selectedAssets).length === 0) return true
                return selectedAssets[asset.id]
            })
            .sort((varietyA, varietyB) => {
                const assetOrder = sortAlphabetically(
                    varietyA.asset.name,
                    varietyB.asset.name
                )
                return (
                    assetOrder ||
                    sortBasedOnDefault(varietyA, varietyB) ||
                    sortAlphabetically(varietyA.name, varietyB.name)
                )
            })
    }, [varieties, selectedAssets])

    const [isFormOpen, setIsFormOpen] = useState(false)
    const formRef = useOutsideComponentClickHandler(() => setIsFormOpen(false))

    /* BULK ACTIONS OPEN/CLOSE > START */
    const [isOpenBulkAttributes, setIsOpenBulkAttributes] = useState(false)
    const [isOpenBulkDescription, setIsOpenBulkDescription] = useState(false)
    /* BULK ACTIONS OPEN/CLOSE < END */

    /* TABLE STATE > START */
    const [selectedVarieties, setSelectedVarieties] =
        useState<IBooleanDictionary>({})
    const selectedVarietiesCount = Object.keys(selectedVarieties).length
    const [globalFilter, setGlobalFilter] = useState<string>("")
    /* TABLE STATE < END */

    return (
        <ResponsivePaddingWrapper extraClasses="elevation-1 border-r border-gray-5 !pr-4">
            <div className="flex flex-col h-full grow gap-2">
                <GenericPageHeader
                    pageTitle={t("assetsCOUNT", "Assets ({count})", {
                        count: varietyCount,
                    })}
                    right={
                        <div className="flex flex-row items-center gap-1">
                            <div className="w-40">
                                <DebounceSearchInput
                                    onSearch={setGlobalFilter}
                                    onSubmit={setGlobalFilter}
                                    placeholder={t("search")}
                                />
                            </div>
                            <Button
                                label={t("newAsset", "New Asset")}
                                icon={<PlusIcon />}
                                onClick={() => setIsFormOpen(true)}
                            />
                        </div>
                    }
                    bottomSectionPadding=""
                    bottom={
                        <div className="flex flex-col gap-2 w-full">
                            <div className="flex flex-row flex-wrap items-center gap-1">
                                {AssetFilter}
                            </div>

                            {selectedVarietiesCount > 0 && (
                                <div className="flex flex-row gap-1">
                                    {/* BULK COUNT */}
                                    <div className="px-4 label-lg rounded bg-gray-5 text-gray-90 flex flex-row items-center h-[32px]">
                                        {selectedVarietiesCount}{" "}
                                        {t("selected", "Selected")}
                                    </div>

                                    <div className="hidden">
                                        {/* BULK EDIT ATTRIBUTES BUTTON */}
                                        <Button
                                            type="secondary"
                                            label={t(
                                                "editAttributes",
                                                "Edit Attributes"
                                            )}
                                            customClasses={[
                                                "border-accent hover:border-accent-dark",
                                                "text-accent hover:text-accent-dark",
                                            ].join(" ")}
                                            onClick={() =>
                                                setIsOpenBulkAttributes(true)
                                            }
                                        />
                                        {/* BULK EDIT DESCRIPTION BUTTON */}
                                        <Button
                                            type="secondary"
                                            label={t(
                                                "editDescription",
                                                "Edit Description"
                                            )}
                                            customClasses={[
                                                "border-accent hover:border-accent-dark",
                                                "text-accent hover:text-accent-dark",
                                            ].join(" ")}
                                            onClick={() =>
                                                setIsOpenBulkDescription(true)
                                            }
                                        />
                                    </div>

                                    {/* BULK DELETE BUTTON */}
                                    <Button
                                        label={""}
                                        type="secondary-small"
                                        icon={<TrashIcon />}
                                        onClick={() => {
                                            confirmationModal({
                                                title: t(
                                                    "confirmBulkVarietiesDelete",
                                                    "{count, plural, =1 {Do you want to delete this asset and its linked risk profiles?} other {Do you want to delete {count} assets and their linked risk profiles?}}",
                                                    {
                                                        count: selectedVarietiesCount,
                                                    }
                                                ),
                                                text: t(
                                                    "actionCannotBeUndone",
                                                    "This action cannot be undone"
                                                ),
                                                onContinueLabel: t(
                                                    "delete",
                                                    "Delete"
                                                ),
                                                onContinue: () => {
                                                    bulkDeleteVarieties(
                                                        Object.keys(
                                                            selectedVarieties
                                                        )
                                                    ).then(() => {
                                                        // Report how many varieties were deleted
                                                        enqueueAlert(
                                                            t(
                                                                "varietiesDeletedSuccessfully",
                                                                "{ count, plural, =1 {Asset variety successfully deleted} other {{count} assets successfully deleted }}",
                                                                {
                                                                    count: selectedVarietiesCount,
                                                                }
                                                            )
                                                        )
                                                        // Reset varieties selection
                                                        setSelectedVarieties({})
                                                    })
                                                },
                                            })
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    }
                />
                {isLoadingVarieties && (
                    <div className="loading-screen__loader" />
                )}
                <div className="min-h-0 mt-3 grow">
                    <Table<IVariety>
                        data={processedVarieties}
                        columns={actualColumns}
                        paginationOptions={paginationOptions}
                        getRowId={({ id }) => id}
                        state={{
                            globalFilter,
                            hiddenColumns: [],
                            rowSelection: selectedVarieties,
                        }}
                        setRowSelection={setSelectedVarieties}
                        rowSelectionFilter={(row) => !row.original.is_default}
                    />
                </div>
            </div>
            {/* FORM */}
            <VarietyForm
                modalRef={formRef}
                isOpen={isFormOpen}
                setIsOpen={setIsFormOpen}
            />
            {/* BULK MODALS */}
            <EditAttributes
                isOpen={isOpenBulkAttributes}
                setIsOpen={setIsOpenBulkAttributes}
                setSelectedVarieties={setSelectedVarieties}
                selectedVarieties={selectedVarieties}
            />
            <EditDescription
                isOpen={isOpenBulkDescription}
                setIsOpen={setIsOpenBulkDescription}
                setSelectedVarieties={setSelectedVarieties}
                selectedVarieties={selectedVarieties}
            />
        </ResponsivePaddingWrapper>
    )
}

export default AssetsView
