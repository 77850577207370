import { useTranslate } from "@tolgee/react"
import { useNavigate } from "react-router-dom"
import { Button } from "../climateui/components"

function NotFound({ showLogo = false }) {
    const navigate = useNavigate()

    const { t } = useTranslate()

    return (
        <div className="h-full w-full relative flex flex-row items-center justify-center">
            {showLogo && (
                <div className="absolute top-5 w-44">
                    <img src="/images/climateai/ClimateAi-new-logo.png" />
                </div>
            )}
            <div className="flex flex-col items-center max-w-[360px] text-center gap-4">
                <div className="w-60 -mt-8">
                    <img src="/images/climateai/not-found.png" />
                </div>

                <h1 className="title-lg text-gray-90 pt-4">
                    {t("pageNotFound", "Page not found")}
                </h1>

                <p className="body-lg text-gray-60">
                    {t(
                        "weCouldntFindPage",
                        `We couldn’t find the specific link to this page, please double check the URL.`
                    )}
                </p>

                <div className="pt-6">
                    <Button
                        label={t("goBack", "Go back")}
                        onClick={() => navigate("/")}
                    />
                </div>
            </div>
        </div>
    )
}

export default NotFound
