// see: https://www.jameslmilner.com/posts/converting-rgb-hex-hsl-colors/
export function hexToHsl(hex: string): { h: number; s: number; l: number } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    if (!result) {
        throw new Error("Could not parse Hex Color")
    }

    const rHex = parseInt(result[1], 16)
    const gHex = parseInt(result[2], 16)
    const bHex = parseInt(result[3], 16)

    const r = rHex / 255
    const g = gHex / 255
    const b = bHex / 255

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)

    let h = (max + min) / 2
    let s
    let l = h

    if (max === min) {
        // Achromatic
        return { h: 0, s: 0, l }
    }

    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
        case r:
            h = (g - b) / d + (g < b ? 6 : 0)
            break
        case g:
            h = (b - r) / d + 2
            break
        case b:
            h = (r - g) / d + 4
            break
    }
    h /= 6

    s = s * 100
    s = Math.round(s)
    l = l * 100
    l = Math.round(l)
    h = Math.round(360 * h)

    return { h, s, l }
}

// see: https://www.jameslmilner.com/posts/converting-rgb-hex-hsl-colors/
export function hslToHex(hsl: { h: number; s: number; l: number }): string {
    const { h, s, l } = hsl

    const hDecimal = l / 100
    const a = (s * Math.min(hDecimal, 1 - hDecimal)) / 100
    const f = (n: number) => {
        const k = (n + h / 30) % 12
        const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)

        // Convert to Hex and prefix with "0" if required
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, "0")
    }
    return `#${f(0)}${f(8)}${f(4)}`
}

const newColor = (def: string, dark: string, medium: string, light: string) => {
    return {
        DEFAULT: def,
        dark,
        medium,
        light,
    }
}
// INFO: This is replicated in tailwind.config.js
// TODO: Declare once and use it everywhere
export const colors = {
    transparent: "transparent",
    current: "currentColor",
    accent: {
        DEFAULT: "var(--color-primary)",
        dark: "var(--color-primary-dark)",
        medium: "var(--color-primary-medium)",
        light: "var(--color-primary-light)",
    },
    gray: {
        100: "#000B18",
        90: "#1A232F",
        88: "#1F2834",
        86: "#242D38",
        78: "#3D4650",
        60: "#666D74",
        30: "#B3B6BA",
        20: "#C4C4C4",
        14: "#DBDDDF",
        10: "#E6E7E8",
        5: "#F2F3F3",
        3: "#F7F8F8",
        1: "#FBFBFC",
    },
    white: "#ffffff",
    orange: newColor("#F06000", "#BD4C00", "#F9BF99", "#FEEFE5"),
    red: newColor("#E42437", "#BF1728", "#F4A7AF", "#FCE9EB"),
    yellow: newColor("#FDB600", "#CC9300", "#FEE299", "#FFF8E5"),
    lime: newColor("#A9BF2C", "#859522", "#DDE5AB", "#F6F9EA"),
    green: newColor("#23AF41", "#1A8431", "#A7DFB3", "#E9F7EC"),
    emerald: newColor("#128756", "#0C5A39", "#A0CFBC", "#E7F3EE"),
    cyan: newColor("#0098B3", "#006C80", "#99D6E0", "#E5F5F7"),
    sky: newColor("#039CE2", "#0379B0", "#9AD7F3", "#E6F5FC"),
    blue: newColor("#196CE1", "#1456B3", "#A3C4F3", "#E8F0FC"),
    indigo: newColor("#5055D7", "#2D33C8", "#B9BBEF", "#EEEEFB"),
    purple: newColor("#8944E4", "#6E1FD6", "#D0B4F4", "#F3ECFC"),
    violet: newColor("#AC22CF", "#871BA2", "#DEA7EC", "#F7E9FA"),
    pink: newColor("#D532AD", "#B2248F", "#EEADDE", "#FBEAF7"),
    wine: newColor("#B91863", "#8C124B", "#E3A3C1", "#F8E8EF"),
}

export const colorsArr = [
    colors.cyan.DEFAULT,
    colors.violet.DEFAULT,
    colors.blue.DEFAULT,
    colors.purple.DEFAULT,
    colors.green.DEFAULT,
    colors.emerald.DEFAULT,
    colors.orange.DEFAULT,
    colors.sky.DEFAULT,
    colors.indigo.DEFAULT,
    colors.lime.DEFAULT,
    colors.pink.DEFAULT,
    colors.wine.DEFAULT,
]

export const formattedColorsMatrixForColorPicker = [
    [
        colors.orange.DEFAULT,
        colors.yellow.DEFAULT,
        colors.lime.DEFAULT,
        colors.green.DEFAULT,
        colors.emerald.DEFAULT,
    ],
    [
        colors.cyan.DEFAULT,
        colors.sky.DEFAULT,
        colors.blue.DEFAULT,
        colors.indigo.DEFAULT,
        colors.purple.DEFAULT,
    ],
    [
        colors.violet.DEFAULT,
        colors.pink.DEFAULT,
        colors.wine.DEFAULT,
        colors.red.DEFAULT,
        colors.gray[60],
    ],
]
