import _ from "lodash"
import { SearchIcon } from "../icons"
import {
    LegacyRef,
    MouseEventHandler,
    FocusEventHandler,
    KeyboardEventHandler,
    ReactNode,
} from "react"
import { defaultInputClasses } from "./Inputs/utils"

const DebounceSearchInput = ({
    onSearch = () => null,
    onSubmit = () => null,
    ms = 500,
    autoFocus = false,
    placeholder = "Search",
    defaultValue = "",
    onFocusOrTypeOrClick = () => null,
    inputRef = null,
    icon,
    disallowedCharacters = [],
    isSmall = false,
}: {
    onSearch?: (val: string) => void | null
    onSubmit?: (val: string) => void | null
    ms?: number
    autoFocus?: boolean
    placeholder?: string
    defaultValue?: string
    onFocusOrTypeOrClick?:
        | MouseEventHandler<HTMLInputElement>
        | FocusEventHandler<HTMLInputElement>
        | KeyboardEventHandler<HTMLInputElement>
    inputRef?: LegacyRef<HTMLInputElement> | null
    icon?: ReactNode
    disallowedCharacters?: string[]
    isSmall?: boolean
}) => {
    const debounceSearch = _.debounce(onSearch, ms)
    return (
        <div
            className={[
                "min-w-[100px]",
                "flex flex-row items-center",
                "px-[6px] relative overflow-hidden",
                isSmall ? "h-[32px]" : "h-[42px]",
            ].join(" ")}>
            <span
                className={
                    "h-[20px] w-[20px] z-10 grow-0 shrink-0 fill-gray-60 " +
                    (icon !== null ? "" : "pointer-events-none")
                }>
                {(icon && icon) || <SearchIcon />}
            </span>

            <input
                type="text"
                placeholder={placeholder}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                style={{
                    height: (isSmall ? 32 : 42) + "px",
                    borderRadius: (isSmall ? 4 : 6) + "px",
                }}
                className={[
                    ...defaultInputClasses,
                    "absolute inset-0 pl-8 pr-2",
                ].join(" ")}
                onKeyUp={(e) => {
                    const target = e.target as HTMLTextAreaElement
                    let sanitizedInput = target.value

                    for (let i = 0; i < disallowedCharacters.length; i++) {
                        sanitizedInput = sanitizedInput.replaceAll(
                            disallowedCharacters[i],
                            ""
                        )
                    }

                    ;(e.target as HTMLTextAreaElement).value = sanitizedInput

                    if (e.key === "Enter") {
                        onSearch(sanitizedInput)
                        onSubmit(sanitizedInput)
                    } else debounceSearch(sanitizedInput)
                    ;(
                        onFocusOrTypeOrClick as KeyboardEventHandler<EventTarget>
                    )(e)
                }}
                onFocus={
                    onFocusOrTypeOrClick as FocusEventHandler<HTMLInputElement>
                }
                onClick={
                    onFocusOrTypeOrClick as MouseEventHandler<HTMLInputElement>
                }
                ref={inputRef}
            />
        </div>
    )
}

export default DebounceSearchInput
