function extractContent(str: string): {
    value: string
    type: "expression" | "string" | "number" | "boolean"
} {
    // regular expression to match a quoted string, an unquoted string, or a number
    const regex = /(?:'([^']*)'|"([^"]*)"|([^\s]+))/g

    // loop over the matches
    let match
    while ((match = regex.exec(str))) {
        // extract the captured groups
        const quoted1 = match[1]
        const quoted2 = match[2]
        const unquoted = match[3]

        // check which group matched and return the corresponding content and type
        if (quoted1 !== undefined) {
            return { value: quoted1, type: "string" }
        } else if (quoted2 !== undefined) {
            return { value: quoted2, type: "string" }
        } else if (unquoted !== undefined) {
            // if the token is an unquoted string or a number, remove the leading and trailing whitespace
            // and all the internal spaces
            const value = unquoted.trim().replace(/\s/g, "")
            // check if the value is a number
            if (!isNaN(Number(value))) {
                return { value, type: "number" }
            } else if (value === "true" || value === "false") {
                // if the value is 'true' or 'false', return 'boolean' as the type
                return { value, type: "boolean" }
            } else {
                // if the value is not a number, it is an unquoted string
                return { value, type: "expression" }
            }
        }
    }

    throw new TypeError(`Couldn't convert string: ${str} to a readable type.`)
}

export default extractContent
