import { useTranslate } from "@tolgee/react"
import { useEffect, useState } from "react"
import { Button, DebounceSearchInput, Table } from "../climateui/components"
import { CancelIcon } from "../climateui/icons"
import EmptyModal from "../climateui/providers/Modal/EmptyModal"
import { useLocations } from "../providers"
import { useAccount } from "../providers/AccountProvider"
import { IInsightsLocation } from "../types"
import { buildLocationColumns } from "../views/Admin/Locations/adminLocationsTableUtils"
import LocationsTableFilters from "../views/Admin/Locations/components/LocationsTableFilters"

const columns = buildLocationColumns([
    "selection",
    "name",
    "region",
    "varieties",
])

interface ISelectLocationsModal {
    open: boolean
    close: () => void
    save: (locationsIds: string[]) => void
    prevSelectedIds?: string[]
}

function SelectLocationsModal({
    open,
    close,
    save,
    prevSelectedIds = [],
}: ISelectLocationsModal) {
    const { t } = useTranslate()
    const { selectedAccount } = useAccount()
    const { locations, loadingLocations } = useLocations()

    const [globalFilter, setGlobalFilter] = useState("")
    const [rowSelection, setRowSelection] = useState({})
    const [columnFilters, setColumnFilters] = useState<
        { id: string; value: string[] | unknown }[]
    >([])

    useEffect(() => {
        if (open && prevSelectedIds.length > 0) {
            setRowSelection(
                prevSelectedIds.reduce((acc, curr) => {
                    return { ...acc, [curr]: true }
                }, {})
            )
        }
        if (!open) {
            setGlobalFilter("")
            setRowSelection({})
            setColumnFilters([])
        }
    }, [open, prevSelectedIds])

    return (
        <EmptyModal
            customClasses="relative rounded-lg bg-white w-[671px] overflow-hidden"
            open={open}>
            <div className="pl-5 pr-4 h-[56px] flex flex-row items-center justify-between border-b border-gray-14">
                <h3 className="title-sm">
                    {t("importExistingLocations", "Import Existing Locations")}
                </h3>
                <span
                    className="w-6 h-6 fill-gray-60 hover:fill-gray-60/80 transition-all cursor-pointer"
                    onClick={() => close()}>
                    <CancelIcon />
                </span>
            </div>
            <div className="px-5 pt-3 pb-3">
                <div className="flex flex-row gap-2">
                    <div className="w-36">
                        <DebounceSearchInput
                            isSmall
                            placeholder={t("search")}
                            onSearch={setGlobalFilter}
                        />
                    </div>
                    <LocationsTableFilters
                        setColumnFilters={setColumnFilters}
                        columnFilters={columnFilters}
                        showLocationFilter={false}
                        showLabelFilter={false}
                    />
                </div>

                <div className="h-[340px] overflow-y-auto overflow-x-hidden mt-3">
                    <Table<IInsightsLocation>
                        columns={columns}
                        data={locations}
                        getRowId={(
                            location: IInsightsLocation,
                            index: number
                        ) => location?.id || index.toString()}
                        noDataMessage={
                            loadingLocations
                                ? t("loadingLocations", "Loading locations...")
                                : t(
                                      "thereAreNoLocationsClickOnNewLocation",
                                      "There are no locations, click on New location to add one."
                                  )
                        }
                        outOfText={t("of", "of")}
                        extraClasses={""}
                        state={{
                            rowSelection,
                            globalFilter,
                            columnFilters,
                            hiddenColumns: [],
                        }}
                        setRowSelection={setRowSelection}
                        setGlobalFilter={setGlobalFilter}
                        rowSelectionFilter={(row) => {
                            return row.original.account_id === selectedAccount
                        }}
                        setColumnFilters={setColumnFilters}
                        enableMultiSort={false}
                    />
                </div>
            </div>

            <div className="px-3 h-[64px] flex flex-row items-center justify-end border-t border-gray-14">
                <Button
                    onClick={() => {
                        save(Object.keys(rowSelection))
                    }}
                    label={t("importLocations")}
                    disabled={Object.keys(rowSelection).length === 0}
                />
            </div>
        </EmptyModal>
    )
}

export default SelectLocationsModal
