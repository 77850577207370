/*

    ¡ALTO AHÍ VAQUERO!

    This component is deprecated and will stop being supported, soon all instances will be relaced with

    <TooltipV2 /> 
    
    Found in src/climateui/components/ToolttipV2

    You've been warned.

*/

import { ReactNode } from "react"

function Tooltip({
    content,
    position = "bottom",
    wrapperClass,
    contentClass = "whitespace-nowrap",
    pointerEvents = false,
    doShow = true,
    white = false,
    children,
    customStyle = undefined,
}: {
    content: string
    position?: string
    wrapperClass?: string
    contentClass?: string
    pointerEvents?: boolean
    doShow?: boolean
    white?: boolean
    children?: ReactNode | ReactNode[]
    customStyle?: string
}) {
    let positionClassName = ""
    let tipClassName = ""

    const borderColor = (white && "border-white") || "border-gray-90"
    const bgColor = (white && "bg-white") || "bg-gray-90"
    const textColor = (white && "text-gray-90") || "text-white"

    if (position === "bottom") {
        positionClassName = "top-full"
        tipClassName = `top-[2px] ${borderColor} border-t-0 border-x-transparent left-1/2 -translate-x-1/2`
    } else if (position === "top") {
        positionClassName = "bottom-full mb-2"
        tipClassName = `top-full ${borderColor} border-b-0 border-x-transparent left-1/2 -translate-x-1/2`
    } else if (position === "right") {
        positionClassName = "left-full ml-2 mb-1"
        tipClassName = `right-full ${borderColor} border-l-0 border-y-transparent top-1/2 -translate-y-1/2 mt-[3.6px]`
    } else if (position === "left") {
        positionClassName = "right-full mr-2 mb-1"
        tipClassName = `left-full ${borderColor} border-r-0 border-y-transparent top-1/2 -translate-y-1/2 mt-[3.6px]`
    } else if (position === "top-right") {
        // Tooltip on the top, but the tip is on the left, content thrown to the right
        positionClassName = "bottom-full left-0 mb-2"
        tipClassName = `top-full ${borderColor} border-b-0 border-x-transparent left-[10%] -translate-x-1/2`
    } else if (position === "bottom-right") {
        // Tooltip on the top, but the tip is on the left, content thrown to the right
        positionClassName = "top-full left-0 mb-2"
        tipClassName = `top-[2px] ${borderColor} border-t-0 border-x-transparent left-[10%] -translate-x-1/2`
    }

    return (
        <div
            className={
                customStyle !== undefined
                    ? customStyle
                    : "relative flex items-center justify-start group transition-all duration-200"
            }>
            {children}
            {doShow && (
                <div
                    className={[
                        "invisible absolute z-full xgroup-hover:visible",
                        positionClassName,
                        pointerEvents
                            ? "pointer-events-auto"
                            : "pointer-events-none",
                        wrapperClass,
                    ].join(" ")}>
                    <div
                        className={["absolute border-[6px]", tipClassName].join(
                            " "
                        )}></div>
                    <div
                        className={[
                            `${bgColor} font-roboto ${textColor} text-[14px] leading-4 p-2 rounded-sm mt-2 elevation-2`,
                            contentClass,
                        ].join(" ")}>
                        {content}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Tooltip
