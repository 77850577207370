import { Row } from "@tanstack/react-table"
import { useMemo, useState, useEffect, ReactNode } from "react"
import Table from "../../Table/Table"
import { Link } from "react-router-dom"
import { interpolate } from "../../../utils/string"
import getVariableIcon from "../../../utils/icons/getVariableIcon"
import LoadingAnimation from "../../LoadingAnimation"
import NoResultsState from "../../NoResultsState"
import getColorAndSymbol from "../../../utils/icons/getColorAndSymbol"
import PaginatedTable from "../../Table/PaginatedTable"
import { useTranslate } from "@tolgee/react"
import AboveIcon from "../../../icons/directionalityIcons/AboveIcon"
import { DateTime } from "luxon"
import FixedElement from "../../FixedElement"

interface ITableWidgetColumnSelector {
    text?: string
    delimeter?: string
    value?: string
    unit?: string
    icon?: string
    title?: string
    description?: string
    startDate?: string
    endDate?: string
    onHover?: any
}

// type TableColumnType = "link" | "text" | "itemDescription"
type TableColumnType = "link" | "text" | "number"
export interface ITableWidgetColumn {
    // TODO define better depending on column type
    propName: string
    id?: string
    header?: string
    headerIcon?: string
    headerInfo?: ReactNode
    customCell?: (
        col: ITableWidgetColumn
    ) => (context: { row: Row<ITableData> }) => JSX.Element
    headerClass?: string
    type: TableColumnType
    selector?: ITableWidgetColumnSelector
    defaultValue?: string
    highlight?: boolean
    columns?: ITableWidgetColumn[]
}

export interface ITableData {
    [key: string]: any
}

export interface ITableWidgetProps {
    readonly data: ITableData[]
    readonly columns: ITableWidgetColumn[]
    readonly loading?: boolean
    readonly title?: string
    readonly error?: boolean
    readonly empty?: boolean
    readonly reload?: () => void
    // readonly __query?: string
    // readonly __data?: any
    // readonly __selectors?: any
    readonly footer?: ReactNode
    readonly isPaginated?: boolean
    readonly errorMessage: string
    readonly reloadMessage: string
    readonly noResultsMessage: string
    readonly tryAnotherFilterMessage: string
    readonly toggleNewDirectonalityLogic?: boolean
}

export const HeaderComponent = (props: { column: ITableWidgetColumn }) => {
    const { column } = props
    return (
        <div className="flex items-center">
            {column.headerIcon && (
                <div className="w-[18px] h-auto fill-gray-60 mr-1">
                    {getVariableIcon(column.headerIcon)}
                </div>
            )}
            <div className={column.headerClass}>{column.header}</div>
            {column.headerInfo}
        </div>
    )
}

export const defaultTableWidgetColumns = {
    link: {
        cell:
            ({ propName }: ITableWidgetColumn) =>
            ({ row }: { row: Row<ITableData> }) => {
                const { text = "", href = "" } = row.original[propName]

                return (
                    <Link
                        className="underline cursor-pointer"
                        // type="submit"
                        to={`${href}`}>
                        {text}
                    </Link>
                )
            },
    },
    number: {
        cell:
            ({ propName }: ITableWidgetColumn) =>
            ({ row }: { row: Row<ITableData> }) => {
                const { value, unit, icon } = row.original[propName]
                const formatter = Intl.NumberFormat(undefined, {
                    maximumFractionDigits: 1,
                    signDisplay: unit === "%" ? "always" : "auto",
                })
                let formattedValue = ""

                if (unit) {
                    formattedValue =
                        unit === "%"
                            ? formatter.format(+value * 100)
                            : formatter.format(+value)
                    formattedValue = `${formattedValue} ${unit}`
                }

                let iconComponent = icon ? getVariableIcon(icon) : undefined

                return (
                    <div className="flex items-center p-1 flex-nowrap whitespace-nowrap gap-1">
                        {iconComponent && (
                            <div className="w-[18px] h-[18px] fill-gray-60">
                                {iconComponent}
                            </div>
                        )}
                        <span>{formattedValue}</span>
                    </div>
                )
            },
    },
    text: {
        cell: ({ propName }: ITableWidgetColumn) => {
            return ({ row }: { row: Row<ITableData> }) => {
                // row.original[propName].something
                // render item description and icon
                if (!row.original[propName]) return

                const [showHover, setShowHover] = useState(false)

                const { title, icon, text, onHover, delimeter } =
                    row.original[propName]
                let iconComponent = null
                let textDescriptions: string[] = []

                if (title && title === "directionality" && icon) {
                    iconComponent = getColorAndSymbol(icon, "table", {
                        above: "#23AF41",
                        below: "#E42437",
                        within: "#CC9300",
                    })
                } else if (icon) iconComponent = getVariableIcon(icon)

                if (text && delimeter) {
                    textDescriptions = text.split(delimeter)
                } else if (text) {
                    textDescriptions = [text]
                }

                return (
                    <div className="flex flex-1">
                        <FixedElement
                            open={!!onHover && showHover}
                            position="right"
                            align="center"
                            parentElement={
                                <div
                                    className="flex items-start flex-1 py-2 space-x-1"
                                    role="button"
                                    tabIndex={0}
                                    onMouseEnter={() => setShowHover(true)}
                                    onMouseLeave={() => setShowHover(false)}>
                                    {icon && (
                                        <div className="flex-start">
                                            <div className="w-[20px] h-[20px] fill-gray-60">
                                                {iconComponent}
                                            </div>
                                        </div>
                                    )}
                                    <div className="flex flex-col max-w-[350px] text-sm">
                                        {textDescriptions.map((description) => (
                                            <p key={description}>
                                                {description}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            }>
                            <div className="bg-white p-2 rounded-lg shadow-[0_4px_20px_4px_rgba(0,20,60,0.1)]">
                                {onHover ? onHover(row.original.meta) : ""}{" "}
                            </div>
                        </FixedElement>
                    </div>
                )
            }
        },
    },
    date: {
        cell:
            ({ propName }: ITableWidgetColumn) =>
            ({ row }: { row: Row<ITableData> }) => {
                const { dates } = row.original[propName]
                const interval = dates.split(",")
                const showDates = interval.map((date: string) => {
                    let intervalDate = DateTime.fromISO(date, { setZone: true })
                    return intervalDate.toLocaleString({
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                    })
                })

                return <h1>{showDates.join(" - ")}</h1>
            },
    },
    icon: {
        cell:
            ({ propName }: ITableWidgetColumn) =>
            ({ row }: { row: Row<ITableData> }) => {
                const { value } = row.original[propName]
                // const forecast = false
                let DirectionalityIcon = (<AboveIcon />) as ReactNode
                if (value) {
                    DirectionalityIcon = getColorAndSymbol(value, "table", {
                        above: "#666D74",
                        below: "#666D74",
                        within: "#666D74",
                    })
                }
                return (
                    <div
                        className={`flex justify-center items-center font-roboto text-[20px] h-full w-full min-w-[100px] ${
                            value == -1
                                ? "bg-[#4CBFAD] bg-opacity-[20%]"
                                : value == 1
                                ? "bg-[#F06000] bg-opacity-[20%]"
                                : ""
                        }`}>
                        <div className="w-[22px]">{DirectionalityIcon}</div>
                    </div>
                )
            },
    },
}

const flattenMultiLevelHeaders = (
    columns: ITableWidgetColumn[],
    prevColumns: ITableWidgetColumn[]
) => {
    columns.forEach((current) => {
        if (current.selector) {
            prevColumns.push(current)
        } else if (current.columns) {
            prevColumns = flattenMultiLevelHeaders(current.columns, prevColumns)
        }
    })
    return prevColumns
}

function TableWidget(props: ITableWidgetProps) {
    const { t } = useTranslate()
    const {
        data,
        columns,
        loading,
        title,
        error,
        empty,
        reload,
        footer,
        isPaginated = true,
        errorMessage,
        reloadMessage,
        noResultsMessage,
        tryAnotherFilterMessage,
    } = props

    const pageSizeArr = [5, 10, 15]
    const [shownAlerts, setShownAlerts] = useState([] as ITableData[])

    const [pagination, setPagination] = useState({
        page: 1,
        per_page: pageSizeArr[0],
        all: false,
    })

    const pageSizeOpts = {
        5: `5 ${t("rows")}`,
        10: `10 ${t("rows")}`,
        15: `15 ${t("rows")}`,
    }

    const _columns = useMemo(() => {
        if (!columns || loading) return []

        return columns.map((col) => {
            const columnDefinition: {
                id: string
                header: any
                cell?: any
                columns?: {
                    id: string
                    header: any
                    cell: any
                }[]
            } = {
                id: col.propName,
                header: <HeaderComponent column={col} />,
                cell: col.customCell
                    ? col.customCell(col)
                    : defaultTableWidgetColumns[col.type].cell(col),
                columns: col.columns?.map((nestedCol) => {
                    return {
                        id: nestedCol.propName,
                        header: nestedCol.header ? (
                            <HeaderComponent column={nestedCol} />
                        ) : undefined,
                        cell: nestedCol.customCell
                            ? nestedCol.customCell(col)
                            : defaultTableWidgetColumns[nestedCol.type].cell(
                                  nestedCol
                              ),
                    }
                }),
            }
            return columnDefinition
        })
    }, [columns, loading])

    const _data = useMemo(() => {
        if (!data || loading) return []

        return data.map((dataRow) => {
            // iterate columns
            const _row: ITableData = { meta: dataRow }
            const flattenedColumns = flattenMultiLevelHeaders(columns, [])
            for (let col of flattenedColumns) {
                // new cell
                _row[col.propName] = {}

                // iterate selector keys
                for (let key in col.selector) {
                    const rawSelectorText =
                        col.selector[key as keyof ITableWidgetColumnSelector]

                    let processedText = ""
                    if (rawSelectorText)
                        try {
                            processedText = interpolate(
                                rawSelectorText,
                                dataRow
                            )

                            _row[col.propName][key] =
                                processedText || rawSelectorText

                            // if text couldn't be processed, set default value
                            if (
                                (!processedText ||
                                    processedText === rawSelectorText) &&
                                col.defaultValue !== undefined
                            )
                                _row[col.propName][key] = col.defaultValue
                        } catch (e) {
                            console.warn(col.propName, e)
                            _row[col.propName][key] = rawSelectorText
                        }
                }
            }

            return _row
        })
    }, [columns, data, loading])

    useEffect(() => {
        setPagination({ ...pagination, page: 1 })
    }, [data])

    useEffect(() => {
        setShownAlerts(
            _data.slice(
                (pagination.page - 1) * pagination.per_page,
                pagination.page * pagination.per_page
            )
        )
    }, [pagination])

    return (
        <div
            id="widget-table"
            className="relative w-full h-full overflow-x-auto overflow-y-hidden bg-white border rounded-lg border-1 border-gray-14">
            <div className="w-full h-[48px] pl-[14px] flex items-center sticky top-0 left-0 z-10 ">
                {loading ? (
                    <h3 className="label-lg text-gray-30">{title}</h3>
                ) : (
                    <h3 className="label-lg"> {title}</h3>
                )}
            </div>
            <div className="px-2.5 pb-2.5">
                {loading ? (
                    <div className="flex justify-center m-5 body-lg">
                        <LoadingAnimation />
                    </div>
                ) : !loading && (empty || data.length === 0) ? (
                    <div className="mb-5">
                        <NoResultsState
                            tryAnotherFilterMessage={
                                tryAnotherFilterMessage || ""
                            }
                            noResultsMessage={noResultsMessage || ""}
                        />
                    </div>
                ) : !loading && error ? (
                    <>
                        <p className="flex justify-center items-center text-[16px] mt-3">
                            {errorMessage}
                        </p>
                        <div className="flex items-center justify-center">
                            <button
                                onClick={reload}
                                className="text-[12px] underline text-accent mb-4">
                                {reloadMessage}
                            </button>
                        </div>
                    </>
                ) : _data.length <= 5 || !isPaginated ? (
                    <Table<ITableData>
                        columns={_columns}
                        data={_data}
                        extraClasses="bg-white min-w-[600px]"
                        footer={footer}
                    />
                ) : (
                    <PaginatedTable<ITableData>
                        onPageChange={({ page, pageSize: per_page }) => {
                            setPagination({
                                ...pagination,
                                page,
                                per_page,
                            })
                        }}
                        pageSizeOpts={pageSizeOpts}
                        initialPage={pagination.page}
                        initialPageSize={pagination.per_page}
                        totalRecords={_data.length}
                        columns={_columns}
                        data={shownAlerts}
                        extraClasses="bg-white min-w-[600px]"
                        footer={footer}
                    />
                )}
            </div>
        </div>
    )
}

export default TableWidget
