import { useTranslate } from "@tolgee/react"
import { useEffect, useState } from "react"
import { MultiSelectFilter } from "../../../climateui/components"
import { IBooleanDictionary } from "../../../climateui/types"

export interface IFilterDefinition {
    options: Record<string, string>
    placeholder: string
    rememberFilterKey?: string
}
export const useFilter = ({
    options,
    placeholder,
    rememberFilterKey,
}: IFilterDefinition): [IBooleanDictionary, JSX.Element] => {
    const [selected, setSelected] = useState<IBooleanDictionary>({})
    const { t } = useTranslate()

    // setup selected state
    useEffect(() => {
        if (!rememberFilterKey || !localStorage.getItem(rememberFilterKey))
            return

        const selectedArr: string[] =
            localStorage.getItem(rememberFilterKey)?.split(",") ?? []
        setSelected(
            selectedArr.reduce(
                (acc, curr) => {
                    acc[curr] = true
                    return acc
                },
                {} as Record<string, boolean>
            )
        )
    }, [rememberFilterKey])

    // store selected in localStorage
    useEffect(() => {
        if (!rememberFilterKey) return

        const selectedVal = Object.keys(selected).join(",")
        localStorage.setItem(rememberFilterKey, selectedVal)
    }, [selected])

    return [
        selected,
        <span key={placeholder}>
            <MultiSelectFilter
                filterName={placeholder}
                selected={selected}
                placeholder={placeholder}
                searchPlaceholder={t("search", "Search")}
                canSearch={true}
                showSelectedOnTop={true}
                options={options}
                leftRightClass="left-0"
                disabled={Object.keys(options).length === 0}
                setSelected={setSelected}
                sortByKey={false}
            />
        </span>,
    ]
}
