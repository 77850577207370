import { useState, useEffect, useMemo } from "react"
import LabelPicker from "./LabelPicker"
import { useLabels } from "../../../../providers/LabelsProvider"
import { EMPTY_LOCATION, REQUIRED_LOCATION_KEYS } from "../LocationsUtil"
import { LabelAndInput } from "../../../../climateui/components"
import { isValidResponse } from "../../../../climateui/utils/http"
import { useTranslate } from "@tolgee/react"
import {
    IInsightsLocation,
    ILocationEssentials,
    IVariety,
} from "../../../../types"
import { ICoordinates } from "../../../../climateui/types"
import { useCoordsRegionQuery } from "../../../../hooks"
import { MultiDropdownSelect } from "../../../../climateui/components/Inputs"
import { useAssets, useLocations } from "../../../../providers"
import { getIdOrSelfIfString } from "../../../../utils/networking"
import { areSameLocation } from "../../../../providers/LocationsProvider"

const inputClasses = [
    "w-full min-h-[36px] h-fit px-2",
    "border border border-gray-10 rounded-md",
    "font-roboto text-[16px] text-gray-90 placeholder:text-gray-30",
    "outline-none focus:outline-accent focus:outline-offset-0",
].join(" ")

export default function LocationForm({
    location,
    changeCallback,
    mapPinCoords,
    setMapPinCoords,
    assetsInputDisabled = false,
    lockLatLon = false,
}: {
    location: ILocationEssentials
    changeCallback: (isValid: boolean, location: ILocationEssentials) => void
    mapPinCoords: ICoordinates
    setMapPinCoords: (mapPinCoords: ICoordinates) => void
    assetsInputDisabled?: boolean
    lockLatLon?: boolean
}) {
    const { t } = useTranslate()
    const { locations } = useLocations()
    const { labels, queryLabels } = useLabels()
    const { varietiesOptions, varieties, caiDefaultVarieties } = useAssets()

    const [selectedVarieties, setSelectedVarieties] = useState<
        Record<string, boolean>
    >({})

    const [editableLocation, setEditableLocation] =
        useState<ILocationEssentials>({
            ...EMPTY_LOCATION,
        })

    const { data, isLoading, isFetching } = useCoordsRegionQuery({
        lat: editableLocation.latitude,
        lon: editableLocation.longitude,
    })

    const regionName = useMemo(() => {
        if (isLoading || isFetching) return t("loading", "Loading...")

        if (!data) return ""
        if (!isValidResponse(data)) return ""

        return data.data.full_name
    }, [data, isLoading, isFetching])

    const isReqPropValid = (prop: "latitude" | "longitude" | "name") => {
        if (
            editableLocation[prop] === "" ||
            editableLocation[prop] === null ||
            editableLocation[prop] === undefined
        ) {
            return false
        }
        return true
    }

    // Returns existing location if duplicate is found
    // undefined if location does not exist
    const checkIfLocationIsDuplicate = (
        location: Pick<IInsightsLocation, "latitude" | "longitude">
    ): IInsightsLocation | undefined => {
        return locations.find((loc) =>
            areSameLocation(
                {
                    lat: location.latitude,
                    lon: location.longitude,
                },
                {
                    lat: loc.latitude,
                    lon: loc.longitude,
                }
            )
        )
    }

    const duplicatedLocation = useMemo(() => {
        const sameCoordsLoc = checkIfLocationIsDuplicate({
            latitude: mapPinCoords.lat,
            longitude: mapPinCoords.lon,
        })
        if (editableLocation.id === sameCoordsLoc?.id) return undefined

        return sameCoordsLoc
    }, [mapPinCoords])

    const isFormValid = () => {
        const result = { isValid: true, error: "" }

        for (const reqKey of REQUIRED_LOCATION_KEYS) {
            if (!isReqPropValid(reqKey)) {
                result.isValid = false
                result.error =
                    result.error !== "" ? result.error + ", " + reqKey : reqKey
            }
        }
        if (duplicatedLocation) result.isValid = false

        return result
    }

    useEffect(() => {
        changeCallback(isFormValid().isValid, { ...editableLocation })
    }, [editableLocation])

    useEffect(() => {
        setEditableLocation({ ...location })

        setMapPinCoords({ lat: location.latitude, lon: location.longitude })

        const newSelectedVarieties: Record<string, boolean> = {}
        location.varieties.forEach(
            (variety) =>
                (newSelectedVarieties[getIdOrSelfIfString(variety)] = true)
        )
        setSelectedVarieties(newSelectedVarieties)
    }, [])

    useEffect(() => {
        setEditableLocation((prev) => ({
            ...prev,
            latitude: mapPinCoords.lat,
            longitude: mapPinCoords.lon,
        }))
    }, [mapPinCoords])

    useEffect(() => {
        const newVarieties: IVariety[] = []
        Object.keys(selectedVarieties).forEach((key) => {
            if (varieties?.[key]) newVarieties.push(varieties[key])
        })
        setEditableLocation((prev) => ({
            ...prev,
            varieties: newVarieties,
        }))
    }, [selectedVarieties])

    const constrainCoords = (value: number, constraint: number) => {
        return Math.min(Math.max(value, -constraint), constraint)
    }

    const handlePropInputChange = (value: number | string, prop: string) => {
        if (prop === "latitude" || prop === "longitude") {
            const newVal = constrainCoords(
                value as number,
                prop === "latitude" ? 90 : 180
            )
            setMapPinCoords({
                ...mapPinCoords,
                [prop === "latitude" ? "lat" : "lon"]: newVal,
            })
            setEditableLocation({
                ...editableLocation,
                [prop]: newVal,
            })
        } else {
            setEditableLocation({
                ...editableLocation,
                [prop]: value,
            })
        }
    }

    let assetsPlaceholder = t("selectAssetOptional", "Select asset (Optional)")
    const selectedAssetsCount = Object.keys(selectedVarieties).length
    const totalAssetsCount = Object.keys(varietiesOptions).length
    if (selectedAssetsCount === totalAssetsCount)
        assetsPlaceholder = t("allAssetsSelected", "All assets selected")
    else if (selectedAssetsCount > 0 && varieties)
        assetsPlaceholder = Object.keys(selectedVarieties)
            .map((varietyId) => {
                let variety: IVariety | undefined = varieties[varietyId]
                if (variety) return `${variety.asset.name} (${variety.name})`

                variety = caiDefaultVarieties?.[varietyId]
                if (variety) return `${variety.asset.name} (${variety.name})`
                throw new Error("No Variety found")
            })
            .join(", ")

    return (
        <div className="mt-3 mb-4 space-y-4">
            <div className="flex flex-row flex-wrap items-center w-full gap-2">
                <LabelAndInput
                    label={t("latitude")}
                    extraClasses="flex-1 min-w-[100px]"
                    input={
                        <input
                            onChange={(e) =>
                                handlePropInputChange(
                                    e.target.value,
                                    "latitude"
                                )
                            }
                            value={mapPinCoords.lat}
                            type="number"
                            placeholder="12.34567"
                            className={inputClasses}
                            disabled={lockLatLon}
                        />
                    }
                />
                <LabelAndInput
                    label={t("longitude")}
                    extraClasses="flex-1 min-w-[100px]"
                    input={
                        <input
                            onChange={(e) =>
                                handlePropInputChange(
                                    e.target.value,
                                    "longitude"
                                )
                            }
                            value={mapPinCoords.lon}
                            type="number"
                            placeholder="-87.65432"
                            className={inputClasses}
                            disabled={lockLatLon}
                        />
                    }
                />

                {duplicatedLocation && (
                    <div className="body-sm text-red">
                        {t(
                            "sameCoordsAsLOCATION",
                            'Same coordinates as location "{location}"',
                            {
                                location: duplicatedLocation.name,
                            }
                        )}
                    </div>
                )}
            </div>

            <LabelAndInput
                label={t("region")}
                input={
                    <input
                        value={regionName}
                        type="text"
                        disabled
                        className={inputClasses}
                    />
                }
            />

            <LabelAndInput
                label={t("locationName")}
                input={
                    <input
                        onChange={(e) =>
                            handlePropInputChange(e.target.value, "name")
                        }
                        value={editableLocation.name}
                        type="text"
                        className={inputClasses}
                    />
                }
            />

            <LabelAndInput
                label={t("assets", "Assets")}
                extraClasses={[
                    "[&_.ddBox]:h-[36px]",
                    "[&_.ddBox]:text-left",
                    "[&_.ddBox]:w-full",
                    "[&_.ddBox]:rounded-md", // ddBox to style inner DropdownSelects
                ].join(" ")}
                input={
                    <MultiDropdownSelect
                        canSearch
                        disabled={assetsInputDisabled}
                        placeholder={assetsPlaceholder}
                        searchPlaceholder={t("searchAsset", "Search Asset")}
                        options={varietiesOptions}
                        selected={selectedVarieties}
                        setSelected={setSelectedVarieties}
                    />
                }
            />

            <LabelAndInput
                label={t("labels")}
                input={
                    <div className={inputClasses}>
                        <LabelPicker
                            labels={labels}
                            refreshLabels={queryLabels}
                            isNewLocation
                            addLabelToLocCallback={(location) => {
                                setEditableLocation({ ...location })
                            }}
                            locationData={{
                                ...editableLocation,
                            }}
                            modifiable
                        />
                    </div>
                }
            />
        </div>
    )
}
