import { useState } from "react"
import { useQuery } from "react-query"
import { IUser } from "../climateui/types"
import { isValidResponse } from "../climateui/utils/http"
import { createGraphQLProxy, riskProfilesGRAPHQL } from "../utils/networking"

export interface IRiskProfilesFilter {
    risk_profile_id?: string
    account_ids?: string[]
    hazard_variables?: string[]
    hazard_variables_ids?: string[]
    variety_ids?: string[]
    search_term?: string
    labels?: string
    stages?: string[]
}
const DEFAULT_QUERY_RESULT = {
    results: [],
    info: {
        count: 0,
    },
}
const useRiskProfilesQuery = ({
    selectedAccount,
    user,
}: {
    selectedAccount: string | undefined
    user: IUser | null
}) => {
    const [filter, setFilter] = useState<IRiskProfilesFilter>()

    const filtersCopy = { ...filter }
    delete filtersCopy?.stages

    const queryResult = useQuery({
        queryKey: ["riskProfiles", selectedAccount, user?.id, filtersCopy],
        queryFn: async () => {
            if (!selectedAccount) return DEFAULT_QUERY_RESULT
            const response = await riskProfilesGRAPHQL(filtersCopy)
            if (!isValidResponse(response)) return DEFAULT_QUERY_RESULT

            // FE filter cant be done here to avoid same query multiple times
            // Implemented in RiskProfilesProvider > filteredRiskProfiles useMemo
            // if (stagesFilter.length > 0) {
            //     riskProfiles = riskProfiles.filter((riskProfile) => {
            //         return riskProfile.stages?.some((stage) =>
            //             stagesFilter.includes(stage.name)
            //         )
            //     })
            // }

            return {
                results:
                    response.data.data.risk_profiles.results.map(
                        createGraphQLProxy
                    ),
                info: response.data.data.risk_profiles.info,
            }
        },
        enabled: !!user && !!selectedAccount,
    })
    return { queryResult, filter, setFilter }
}

export default useRiskProfilesQuery
