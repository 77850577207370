import LoadingAnimation from "../../LoadingAnimation"
import NoResultsState from "../../NoResultsState"
import { ITableWidgetProps } from "../Table/TableWidget"

function WidgetStates(props: ITableWidgetProps) {
    const {
        data,
        loading,
        error,
        reload,
        errorMessage,
        reloadMessage,
        noResultsMessage,
        tryAnotherFilterMessage,
    } = props
    if (loading) {
        return (
            <div className="flex justify-center m-4 body-lg">
                <LoadingAnimation />
            </div>
        )
    } else if (data.length === 0) {
        return (
            <div className="m-4">
                <NoResultsState
                    noResultsMessage={noResultsMessage}
                    tryAnotherFilterMessage={tryAnotherFilterMessage}
                />
            </div>
        )
    } else if (error) {
        return (
            <div className="mt-36">
                <p className="flex justify-center items-center text-[16px] mt-3">
                    {errorMessage}
                </p>
                <div className="flex justify-center items-center">
                    <button
                        onClick={reload}
                        className="text-[12px] underline text-accent">
                        {reloadMessage}
                    </button>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default WidgetStates
