import { useEffect, useMemo, useRef, useState, ReactNode } from "react"

export interface IFixedRect {
    [key: string]: number
}

function getFixedRect(element: HTMLElement): IFixedRect {
    const { left, top, right, bottom, width, height } =
        element.getBoundingClientRect()

    return {
        left: left + window.scrollX,
        top: top + window.scrollY,
        right: window.innerWidth - (right + window.scrollX),
        bottom: window.innerHeight - (bottom + window.scrollY),
        width,
        height,
    }
}

export interface IFixedLayer {
    children?: ReactNode | ReactNode[]
    yPosition: string
    xPosition?: string
    inheritWidth?: boolean
}

function FixedLayer(props: IFixedLayer) {
    const {
        children,
        yPosition,
        xPosition = "left",
        inheritWidth = false,
    } = props

    const [visible, toggle] = useState(false)
    const containerRef = useRef(null)
    const contentRef = useRef(null)

    // containerRect
    const containerRect: IFixedRect = useMemo(() => {
        //
        return (
            (containerRef.current && getFixedRect(containerRef.current)) || {}
        )
    }, [containerRef.current])

    // contentRect
    const contentRect: IFixedRect = useMemo(() => {
        //
        return (contentRef.current && getFixedRect(contentRef.current)) || {}
    }, [contentRef.current])

    useEffect(() => {
        // show content once it fits
        // both rects get calculated after the first render
        if (!visible) toggle(true)
    }, [])

    let style = {} as { [key: string]: number }
    const availablePositions = [] // top | bottom

    // overlaps beginning of page?
    if (containerRect.top - contentRect.height > 0)
        availablePositions.push("top")
    // overlaps end of page?
    if (
        containerRect.top + containerRect.height + contentRect.height <
        window.innerHeight
    )
        availablePositions.push("bottom")

    // set selected position by props, otherwise select first pos
    const pos =
        (availablePositions.indexOf(yPosition) !== -1 && yPosition) ||
        availablePositions[0]

    //
    switch (pos) {
        case "top": {
            style = {
                bottom: containerRect.bottom + containerRect.height,
            }
            break
        }
        case "bottom": {
            style = {
                top: containerRect.top + containerRect.height,
            }
            break
        }
    }
    if (inheritWidth) style.width = containerRect.width
    style[xPosition] = containerRect[xPosition]

    return (
        <>
            <div
                className={
                    "absolute top-0 left-0 w-full h-full pointer-events-none" +
                    ((!visible && " opacity-0") || "")
                }
                ref={containerRef}>
                <div
                    className={
                        "fixed z-full pointer-events-auto transition-all"
                    }
                    style={style}
                    ref={contentRef}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default FixedLayer
