const DEFAULT_VALUES = {
    scaleX: 1,
    skewY: 0,
    skewX: 0,
    scaleY: 1,
    translateX: 0,
    translateY: 0,
}
export const getTransformValues = (element: HTMLElement) => {
    // Gather the transform css property and determine if it has pre-existing values
    const transformProp = window.getComputedStyle(element).transform
    if (!transformProp.startsWith("matrix")) return DEFAULT_VALUES
    const transformValues = transformProp.split(/[()]|,\s+/)
    const parsedValues = transformValues
        .slice(1, transformValues.length - 1)
        .map(parseFloat)
    if (parsedValues.some(isNaN)) return
    const [scaleX, skewY, skewX, scaleY, translateX, translateY] = parsedValues
    return {
        scaleX,
        skewY,
        skewX,
        scaleY,
        translateX,
        translateY,
    }
}
export const applyTransformOffset = ({
    element,
    scaleX = 0,
    skewY = 0,
    skewX = 0,
    scaleY = 0,
    translateX = 0,
    translateY = 0,
}: {
    element: HTMLElement
    scaleX?: number
    skewY?: number
    skewX?: number
    scaleY?: number
    translateX?: number
    translateY?: number
}) => {
    const transformValues = getTransformValues(element)
    if (!transformValues) return
    const {
        scaleX: _scaleX,
        skewY: _skewY,
        skewX: _skewX,
        scaleY: _scaleY,
        translateX: _translateX,
        translateY: _translateY,
    } = transformValues
    const newTransform = `matrix(
        ${scaleX + _scaleX},
        ${skewY + _skewY},
        ${skewX + _skewX},
        ${scaleY + _scaleY},
        ${translateX + _translateX},
        ${translateY + _translateY}
    )`
    element.style.transform = newTransform
}
export const setTransform = ({
    element,
    scaleX = 0,
    skewY = 0,
    skewX = 0,
    scaleY = 0,
    translateX = 0,
    translateY = 0,
}: {
    element: HTMLElement
    scaleX?: number
    skewY?: number
    skewX?: number
    scaleY?: number
    translateX?: number
    translateY?: number
}) => {
    element.style.transform = `matrix(
        ${scaleX},
        ${skewY},
        ${skewX},
        ${scaleY},
        ${translateX},
        ${translateY}
    )`
}
