import { useQuery } from "react-query"
import { HOUR_MS } from "../utils/constants"
import { analogsGET } from "../utils/networking"

const useAnalogsSearchLocationQuery = (analogsSearchLocation: string) => {
    return useQuery({
        queryKey: ["analogs", analogsSearchLocation],
        queryFn: () => analogsGET(analogsSearchLocation),
        enabled: analogsSearchLocation !== "",
        staleTime: HOUR_MS,
    })
}

export default useAnalogsSearchLocationQuery
