import { useTranslate } from "@tolgee/react"
import { Dispatch, SetStateAction } from "react"
import { Switch } from "../../../../climateui/components"

const PastReportsSwitch = ({
    selected,
    setSelected,
}: {
    selected: boolean | null | undefined
    setSelected: Dispatch<SetStateAction<boolean | null | undefined>>
}) => {
    const { t } = useTranslate()
    return (
        <div
            role="button"
            tabIndex={0}
            onClick={() => setSelected((selected) => !selected)}
            className={[
                "flex flex-row items-center",
                "transition-all duration-75",
                "h-[32px] min-w-[60px] px-[8px] w-fit max-w-[200px]",
                "border-[1px] rounded-sm border-gray-14",
                "cursor-pointer disabled:cursor-not-allowed",
                "focus:outline-accent",
                "bg-white text-gray-60 label-lg",
                "disabled:bg-gray-5 disabled:text-gray-30 disabled:fill-gray-30",
            ].join(" ")}>
            {t("pastReports", "Past Reports")}
            <div className="ml-[6px]">
                <Switch
                    checked={!!selected}
                    onChange={() => void 0}
                />
            </div>
        </div>
    )
}
export default PastReportsSwitch
