import { useTranslate } from "@tolgee/react"
import { ReactNode, useContext } from "react"
import { Map } from "../climateui/components"
import { IMap } from "../climateui/components/Map/utils"
import { LocalizationContext } from "../providers/LocalizationProvider"

function HalfMapLayout({
    contentWidthClasses = "lg:w-[700px] w-full",
    children,
    mapProps,
}: {
    contentWidthClasses?: string
    children?: ReactNode
    mapProps: IMap
}) {
    const { language } = useContext(LocalizationContext)
    const { t } = useTranslate()

    return (
        <div className="flex flex-row h-full min-h-0 grow">
            {/* SIDEBAR */}
            <div
                className={
                    "transition-all duration-100 " + contentWidthClasses
                }>
                {children}
            </div>
            {/* MAP */}
            <div className="hidden lg:block grow">
                <Map
                    {...mapProps}
                    language={language}
                    searchPlaceholder={t("search", "Search")}
                />
            </div>
        </div>
    )
}

export default HalfMapLayout
