import _ from "lodash"
import { useState, useCallback, useEffect, useRef } from "react"

const NULL_POSITION = -1
export const useArrowSelector = <T = unknown>(
    options: T[],
    active: boolean = false
) => {
    const [cursor, setCursor] = useState<number>(NULL_POSITION)
    const previousOptions = useRef<T[] | null>(null)

    const keyActionMap: Record<string, Function> = {
        ArrowUp: () =>
            setCursor((prev) => {
                let result = prev
                if (prev === NULL_POSITION) {
                    result = options.length - 1
                } else if (prev > 0) {
                    result = prev - 1
                }
                return result
            }),
        ArrowDown: () =>
            setCursor((prev) => {
                let result = prev
                if (prev < options.length - 1) {
                    result = prev + 1
                }
                return result
            }),
    }

    const handleKeyUp = (event: KeyboardEvent) => {
        if (!keyActionMap[event.key]) return
        keyActionMap[event.key]()
    }
    const handleKeyDown = (event: KeyboardEvent) => {
        if (keyActionMap[event.key]) event.preventDefault()
    }

    // Reset cursor on options change
    useEffect(() => {
        if (
            !previousOptions.current ||
            !_.isEqual(previousOptions.current, options)
        ) {
            previousOptions.current = options
            setCursor(NULL_POSITION)
        }
    }, [options])

    // Reset on close
    useEffect(() => {
        if (!active) setCursor(NULL_POSITION)
    }, [active])

    return {
        selected: options[cursor] as T | undefined,
        ref: useCallback(
            (node: HTMLElement | null) => {
                if (node && active) {
                    // Prevent Window Scroll
                    window.addEventListener("keydown", handleKeyDown)
                    // Capture arrow movements
                    window.addEventListener("keyup", handleKeyUp)
                } else {
                    // Remove prevent Window Scroll
                    window.removeEventListener("keydown", handleKeyDown)
                    // Remove capture arrow movements
                    window.removeEventListener("keyup", handleKeyUp)
                }
                return node
            },
            [options, active]
        ),
    }
}
