import {
    useState,
    createContext,
    useMemo,
    useContext,
    useCallback,
    useEffect,
} from "react"
import { PortalComponent } from "../../../climateui/components"
import { ChevronArrow, PlusIcon } from "../../../climateui/icons"
import { useTranslate } from "@tolgee/react"
import {
    ITimelineRiskProfileStage,
    ITimelineRow,
    ITimelineStage,
} from "../../../types"
import { useTimeline } from ".."
import TimelineStages from "./TimelineStages"
import { compareDates } from "../../../utils/dates"
import TimelineImportRiskProfiles from "./TimelineImportRiskProfiles"
import { wrapStage } from "./utils"
import { useRiskProfiles } from "../../../providers/RiskProfilesProvider"

interface ITimelineRowProvider {
    open: boolean
    rowLeft: number
    setRowLeft: React.Dispatch<React.SetStateAction<number>>
    updateRow: (newRow: Partial<ITimelineRow>) => void
    row: ITimelineRow
    totalLevels: number
    stagesLevels: Record<string, number>
    stageHeight: number
    riskProfilesIds: string[]
    stagesOptions: Record<string, string>
    baseSelectedStagesPerRP: Record<string, Record<string, boolean>>
    selectedStagesPerRP: Record<string, Record<string, boolean>>
    setSelectedStagesPerRP: React.Dispatch<
        React.SetStateAction<Record<string, Record<string, boolean>>>
    >
}

const TimelineRowContext = createContext({} as ITimelineRowProvider)

export const useTimelineRow = () => useContext(TimelineRowContext)

function TimelineRow({
    row,
    index,
}: {
    row: ITimelineRow
    index: number
}) {
    const { t } = useTranslate()
    const {
        openRows,
        setOpenRows,
        renderRowHeaders,
        renderRowItem,
        rows,
        setRows,
        canCollapse,
        timelineStartDate,
        isYearAgnostic,
    } = useTimeline()
    const { riskProfilesObj } = useRiskProfiles()

    const [importRPModalOpen, setImportRPModalOpen] = useState(false)
    const [rowLeft, setRowLeft] = useState(0)
    const [selectedStagesPerRP, setSelectedStagesPerRP] = useState<
        Record<string, Record<string, boolean>>
    >({})

    const toggle = (state: boolean) => {
        if (!row.id || !canCollapse) return
        setOpenRows((prev) => ({
            ...prev,
            [row.id]: state,
        }))
    }

    const rowOpen =
        openRows && row.id && openRows[row.id] ? openRows[row.id] : false

    const open = !canCollapse ? true : rowOpen

    const {
        sortedStages,
        totalLevels,
        stagesLevels,
        stageHeight,
        riskProfilesIds,
        relatedRiskProfiles,
        stagesOptions,
        baseSelectedStagesPerRP,
    } = useMemo(() => {
        const stagesMatrix: ITimelineStage[][] = []
        const sortedStages = [...row.stages]
        sortedStages.sort((a, b) => {
            if (
                a.wrappingCount !== undefined &&
                b.wrappingCount !== undefined
            ) {
                if (a.wrappingCount > b.wrappingCount) return -1
                if (a.wrappingCount < b.wrappingCount) return 1
            } else if (a.wrappingCount !== undefined) {
                if (a.wrappingCount > 0) return -1
                if (a.wrappingCount < 0) return 1
            } else if (b.wrappingCount !== undefined) {
                if (b.wrappingCount > 0) return 1
                if (b.wrappingCount < 0) return -1
            }

            if (a.start_date < b.start_date) return -1
            if (a.start_date > b.start_date) return 1
            return 0
        })
        const stagesOptions: Record<string, string> = {}
        const baseSelectedStagesPerRP: Record<
            string,
            Record<string, boolean>
        > = {}

        const relatedRiskProfiles: ITimelineRiskProfileStage[] = []
        const riskProfilesIds = new Set<string>([])

        for (const stage of sortedStages) {
            stagesOptions[stage.id] = stage.name
            stage.riskProfileStages.forEach((riskProfileStage) => {
                const rpid = riskProfileStage.risk_profile_id
                if (!riskProfilesIds.has(rpid))
                    relatedRiskProfiles.push(riskProfileStage)
                riskProfilesIds.add(rpid)

                if (!baseSelectedStagesPerRP[rpid])
                    baseSelectedStagesPerRP[rpid] = {}
                baseSelectedStagesPerRP[rpid] = {
                    ...baseSelectedStagesPerRP[rpid],
                    [stage.id]: true,
                }
            })
            if (open) wrapStage(stagesMatrix, stage, 0)
            else {
                if (!stagesMatrix[0]) stagesMatrix.push([])
                stagesMatrix[0].push(stage)
            }
        }

        const stagesLevels: Record<string, number> = {}
        const totalLevels = stagesMatrix.length
        stagesMatrix.forEach((row, index) => {
            row.forEach((stage) => {
                stagesLevels[stage.id] = index
                // for the gdd info add to show it in the same gdd row
                // {
                //   top: (totalLevels - index) * 36 + "px";
                // }
            })
        })
        return {
            stagesOptions,
            sortedStages,
            totalLevels,
            stagesLevels,
            stageHeight: totalLevels <= 1 ? 36 : 28,
            riskProfilesIds: Array.from(riskProfilesIds),
            relatedRiskProfiles,
            baseSelectedStagesPerRP,
        }
    }, [row, open])

    useEffect(() => {
        setSelectedStagesPerRP({ ...baseSelectedStagesPerRP })
    }, [baseSelectedStagesPerRP])

    const updateRow = useCallback(
        (newRow: Partial<ITimelineRow>) => {
            if (!rows || !setRows) return

            const newRows = [...rows]
            newRows[index] = { ...row, stages: sortedStages, ...newRow }
            setRows(newRows)
        },
        [rows, index, row, sortedStages]
    )

    useEffect(() => {
        if (!isYearAgnostic) return

        const endDate = new Date(timelineStartDate)
        endDate.setDate(endDate.getDate() + 365)
        let doUpdateStages = false
        const newStages = [...row.stages]

        row.stages.forEach((stage, index) => {
            const newStartDate = new Date(stage.start_date)

            if (compareDates(stage.start_date, timelineStartDate) === -1) {
                newStartDate.setDate(newStartDate.getDate() + 365)
                newStages[index] = {
                    ...stage,
                    start_date: newStartDate,
                    wrappingCount:
                        stage.wrappingCount !== undefined
                            ? stage.wrappingCount + 1
                            : 1,
                }
                doUpdateStages = true
            } else if (compareDates(stage.start_date, endDate) >= 0) {
                newStartDate.setDate(newStartDate.getDate() - 365)
                newStages[index] = {
                    ...stage,
                    start_date: newStartDate,
                    wrappingCount:
                        stage.wrappingCount !== undefined
                            ? stage.wrappingCount - 1
                            : -1,
                }
                doUpdateStages = true
            }
        })

        if (doUpdateStages) updateRow({ stages: newStages })
    }, [timelineStartDate, row.stages])

    const providerValue = useMemo(() => {
        return {
            open,
            rowLeft,
            setRowLeft,
            updateRow,
            row: { ...row, stages: sortedStages },
            totalLevels,
            stagesLevels,
            stageHeight,
            riskProfilesIds,
            relatedRiskProfiles,
            stagesOptions,
            selectedStagesPerRP,
            setSelectedStagesPerRP,
            baseSelectedStagesPerRP,
        }
    }, [
        open,
        rowLeft,
        updateRow,
        row,
        stagesLevels,
        sortedStages,
        relatedRiskProfiles,
        stagesOptions,
        selectedStagesPerRP,
        baseSelectedStagesPerRP,
    ])

    return (
        <TimelineRowContext.Provider value={providerValue}>
            <div className="flex flex-col">
                <div
                    style={{
                        height: (totalLevels || 1) * stageHeight + "px",
                    }}
                    className={
                        "flex flex-row items-start justify-start pl-1 pr-4 " +
                        canCollapse
                            ? "cursor-pointer"
                            : ""
                    }
                    onClick={() => toggle(!open)}>
                    <div
                        className="flex flex-row items-center justify-start text-left text-gray-90"
                        style={{
                            height: 36 + "px",
                        }}>
                        <span
                            className={[
                                "w-5 h-5 shrink-0 grow-0 mr-2",
                                "fill-gray-90",
                                "transition-all duration-75",
                                !canCollapse ? "opacity-0" : "",
                                !open ? "-rotate-90" : "",
                            ].join(" ")}>
                            <ChevronArrow />
                        </span>

                        {renderRowHeaders(row)}

                        {/*
                        {isEditing && <TRowOptions row={row} />}
                        */}
                    </div>
                </div>

                <div
                    className={[
                        "transition-all duration-75 border-t",
                        open
                            ? "border-gray-5 max-h-max"
                            : "max-h-0 border-transparent",
                    ].join(" ")}>
                    {relatedRiskProfiles.map((riskProfileStage) => (
                        <div key={riskProfileStage.risk_profile_id}>
                            {renderRowItem(
                                row,
                                updateRow,
                                riskProfileStage.risk_profile,
                                riskProfilesObj
                            )}
                        </div>
                    ))}
                    {/* Hidden while prod defines if we want the "Add Risk Profile" modal */}
                    <div
                        className="flex flex-row items-center h-10 pl-1 group hover:bg-gray-3 transition-all duration-75 body-md cursor-pointer hidden"
                        onClick={() => setImportRPModalOpen(true)}>
                        <div className="flex flex-row items-center gap-2 w-[280px] pr-2 pl-7">
                            <span className="w-5 h-5 fill-accent shrink-0 grow-0">
                                <PlusIcon />
                            </span>
                            <p className="text-accent">
                                {t("addRiskProfile", "Add Risk Profile")}
                            </p>
                        </div>
                    </div>
                </div>

                <PortalComponent portalId="timelinePortal">
                    <TimelineStages />
                </PortalComponent>
            </div>

            {/* Not actually hidden but users have no way of opening it */}
            <TimelineImportRiskProfiles
                open={importRPModalOpen}
                setOpen={setImportRPModalOpen}
            />
        </TimelineRowContext.Provider>
    )
}

export default TimelineRow
