import { CartesianViewBox } from 'recharts/types/util/types';
import { Props as PropsLabel } from 'recharts/types/component/Label';
import { isUndefined } from 'lodash';
import { ReactElement } from 'react';

const LABEL_HEIGHT = 40

export const renderYLabel = (props: PropsLabel, onComponentRender: (value: string) => ReactElement): JSX.Element => {
    const { y, height, value } = validateCartesianViewBoxProps(props.viewBox as CartesianViewBox, props.value)

    const xLabel = 0
    const yLabel = y + height
    const widthLabel = height
    const heightLabel = LABEL_HEIGHT

    return (
        <g>
            <foreignObject x={xLabel} y={yLabel} width={widthLabel} height={heightLabel} transform='rotate(-90)' style={{ transformOrigin: 'top left', transformBox: 'fill-box' }}>
                {onComponentRender(value)}
            </foreignObject>
        </g>
    )
}

export const renderXLabel = (props: PropsLabel, onComponentRender: (value: string) => ReactElement): JSX.Element => {
    const { y, height, width, value } = validateCartesianViewBoxProps(props.viewBox as CartesianViewBox, props.value)

    const xLabel = 68
    const yLabel = y + height
    const widthLabel = width
    const heightLabel = LABEL_HEIGHT

    return (
        <g>
            <foreignObject x={xLabel} y={yLabel} width={widthLabel} height={heightLabel}>
                {onComponentRender(value)}
            </foreignObject>
        </g>
    )
}

export const validateCartesianViewBoxProps = (viewBox: CartesianViewBox | undefined, value: string | number | undefined): { x: number, y: number, width: number, height: number, value: string } => {

    if (isUndefined(viewBox)) {
        throw new Error('viewBox is undefined inside validateCartesianViewBoxProps()')
    }

    if (isUndefined(value)) {
        throw new Error('value is undefined inside validateCartesianViewBoxProps()')
    }

    const { x, y, width, height } = viewBox

    if (isUndefined(x) || isUndefined(y) || isUndefined(width) || isUndefined(height)) {
        throw new Error('viewBox properties are undefined inside validateCartesianViewBoxProps()')
    }
    return { x, y, width, height, value: value.toString() }
}