import { ReactNode, Fragment, useState, useMemo } from "react"
import { Transition } from "@headlessui/react"
import { useTranslate } from "@tolgee/react"

interface ISectionItem {
    title?: string
    text: string
    src: string
    alt?: string
}
interface IOnboardingFeaturesPreviewProps {
    sections: {
        name: string
        icon: ReactNode
        items: ISectionItem[]
    }[]
}

function SectionItem({
    item,
    extraClasses = "",
}: {
    item: ISectionItem
    extraClasses?: string
}) {
    return (
        <div className={`flex items-center h-32 ml-12 ${extraClasses}`}>
            <div className="flex-grow pr-4 flex flex-col gap-y-2">
                {item.title && <h3 className="title-sm">{item.title}</h3>}
                <p className="body-lg text-gray-60">{item.text}</p>
            </div>
            <div className="w-1/4 h-full shrink-0">
                <img
                    src={item.src}
                    alt={item.alt ?? ""}
                    className="object-contain rounded-md border border-gray-14"
                />
            </div>
        </div>
    )
}

interface ITransitionSection {
    key: string
    element: ReactNode
}

function OnboardingFeaturesPreview({
    sections,
}: IOnboardingFeaturesPreviewProps) {
    const [transitionCount, setTransitionCount] = useState(0)
    const { signInLogo: Logo } = window.dashboard
    const { t } = useTranslate()

    const transitionSections = useMemo<ITransitionSection[]>(() => {
        const transitions: ITransitionSection[] = []

        transitions.push({
            key: "title",
            element: (
                <p className="title-sm shrink-0 mb-9">
                    {t(
                        "howToGetInsights",
                        "How to get insights from the platform?"
                    )}
                </p>
            ),
        })
        sections.forEach((section) => {
            const items = [...section.items]
            const firstItem = items.shift()

            transitions.push({
                key: section.name,
                element: (
                    <>
                        <div className="flex items-center gap-4 my-1">
                            <span className="w-7 h-7 fill-gray-60">
                                {section.icon}
                            </span>
                            <h2 className="title-lg">{section.name}</h2>
                            <div className="flex-grow border-t border-gray-14 ml-1" />
                        </div>
                        {firstItem && <SectionItem item={firstItem} />}
                    </>
                ),
            })
            items.forEach((item) => {
                transitions.push({
                    key: item.text,
                    element: (
                        <SectionItem
                            item={item}
                            extraClasses="mt-3.5"
                        />
                    ),
                })
            })
        })
        transitions.push({
            key: "logo",
            element: (
                <div className="flex flex-row items-center w-full justify-center py-7">
                    <Logo />
                </div>
            ),
        })

        return transitions
    }, [sections])

    return (
        <div className="flex flex-col items-center shrink-0">
            {transitionSections.map((section, index) => (
                <Transition
                    key={section.key}
                    appear
                    afterEnter={() => setTransitionCount((prev) => prev + 1)}
                    show={transitionCount > index - 1}
                    as={Fragment}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-[800ms]"
                        enterFrom="opacity-0 mt-10"
                        enterTo="opacity-100 mt-0">
                        <div className="w-full shrink-0">{section.element}</div>
                    </Transition.Child>
                </Transition>
            ))}
        </div>
    )
}

export default OnboardingFeaturesPreview
