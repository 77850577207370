import {
    useContext,
    createContext,
    ReactNode,
    SetStateAction,
    Dispatch,
    useState,
    useEffect,
    useMemo,
} from "react"
import { PrimitiveAny } from "../climateui/types"
import useRiskProfilesQuery, {
    IRiskProfilesFilter,
} from "../hooks/useRiskProfilesQuery"
import useHazardVariablesQuery from "../hooks/useHazardVariablesQuery"
import { IHazardVariable, IRiskProfile } from "../types"

import { arrToDict } from "../utils/transform"
import { useAccount } from "./AccountProvider"
import { useAuth } from "./AuthProvider"

export const isSameRiskProfile = (
    r1: IRiskProfile,
    r2: IRiskProfile,
    config?: {
        matchLabelsTODO: boolean
    }
) => {
    // We don't handle stacked risks yet
    if (r1.hazard_profiles.length > 1 || r2.hazard_profiles.length > 1)
        return false

    if (r1.probability !== r2.probability) return false

    const hp1 = r1.hazard_profiles[0]
    const ip1 = r1.impact_profile
    const hp2 = r2.hazard_profiles[0]
    const ip2 = r2.impact_profile

    if (hp1.hazard_variable.id !== hp2.hazard_variable.id) return false
    if (hp1.conditional !== hp2.conditional) return false
    if (hp1.type.toLowerCase() !== hp2.type.toLowerCase()) return false
    if (hp1.threshold !== hp2.threshold) return false
    if (hp1.window !== hp2.window) return false

    if (ip1.impact_function.id !== ip2.impact_function.id) return false
    if (ip1.initial_impact !== ip2.initial_impact) return false
    if (ip1.marginal_impact !== ip2.marginal_impact) return false
    if (ip1.max_impact !== ip2.max_impact) return false

    if (config?.matchLabelsTODO) {
        // The BE does labels matching but since in the FE this
        // is just used to detect already existing Risk Profile
        // suggestions, this logic was not required at the time
        // of implementation of this function.
    }

    return true
}

export interface IRiskProfilesContext {
    riskProfiles: IRiskProfile[]
    riskProfilesObj: Record<string, IRiskProfile>
    customRiskProfilesObj: Record<string, IRiskProfile>
    filteredRiskProfiles: IRiskProfile[]
    filteredRiskProfilesInfo: Record<string, PrimitiveAny>
    currentRiskProfile?: IRiskProfile
    setCurrentRiskProfile: Dispatch<
        SetStateAction<IRiskProfile | undefined>
    >
    setCurrentRiskProfileById: (riskProfileId?: string) => void
    isFormOpen: boolean
    setIsFormOpen: Dispatch<SetStateAction<boolean>>
    riskProfilesFilter: IRiskProfilesFilter | undefined
    setRiskProfilesFilter: Dispatch<
        SetStateAction<IRiskProfilesFilter | undefined>
    >
    loadingRisks: boolean
    hazardVariables: IHazardVariable[]
    hazardVariablesObj: Record<string, IHazardVariable>
    areHazardVariablesLoading: boolean

    stageOpts: Record<string, string>
    selectedHazard?: string
    setSelectedHazard?: Dispatch<SetStateAction<string | undefined>>
}

export const RiskProfilesContext = createContext(
    {} as IRiskProfilesContext
)

export const useRiskProfiles = () => useContext(RiskProfilesContext)

function RiskProfilesProvider({ children }: { children: ReactNode }) {
    const { user } = useAuth()
    const { selectedAccount } = useAccount()

    /* STATE > START */
    const [hazardVariablesObj, setHazardVariablesObj] = useState<
        Record<string, IHazardVariable>
    >({})
    const [allRiskProfiles, setAllRiskProfiles] = useState<
        IRiskProfile[]
    >([])
    /* STATE < END */
    const [selectedHazard, setSelectedHazard] = useState<string | undefined>(undefined);

    const {
        queryResult: { data: riskProfilesData, isLoading: loadingRisks },
        filter: riskProfilesFilter,
        setFilter: setRiskProfilesFilter,
    } = useRiskProfilesQuery({
        selectedAccount,
        user,
    })

    const { filteredRiskProfiles, filteredRiskProfilesInfo } = useMemo(() => {
        if (!riskProfilesData)
            return {
                filteredRiskProfiles: [],
                filteredRiskProfilesInfo: {},
            }

        let riskProfiles: IRiskProfile[] = riskProfilesData.results

        if (
            riskProfilesFilter?.stages &&
            riskProfilesFilter?.stages.length > 0
        ) {
            riskProfiles = riskProfiles.filter((riskProfile) =>
                riskProfile.stages?.some((stage) =>
                    riskProfilesFilter.stages?.includes(stage.name)
                )
            )
        }

        return {
            filteredRiskProfiles: riskProfiles,
            filteredRiskProfilesInfo: {
                count: riskProfiles.length ?? 0,
            } as Record<string, PrimitiveAny>,
        }
    }, [riskProfilesData, riskProfilesFilter?.stages])

    const areRiskProfilesFiltersEmpty = () => {
        return (
            !riskProfilesFilter?.labels &&
            !riskProfilesFilter?.account_ids &&
            !riskProfilesFilter?.search_term &&
            !riskProfilesFilter?.variety_ids &&
            !riskProfilesFilter?.risk_profile_id &&
            !riskProfilesFilter?.hazard_variables &&
            !riskProfilesFilter?.hazard_variables_ids &&
            !riskProfilesFilter?.stages
        )
    }

    useEffect(() => {
        if (!filteredRiskProfiles) return

        if (areRiskProfilesFiltersEmpty())
            setAllRiskProfiles([...filteredRiskProfiles])
    }, [filteredRiskProfiles])

    const allRiskProfilesObj = useMemo<
        Record<string, IRiskProfile>
    >(() => {
        if (!allRiskProfiles) return {}

        return arrToDict(allRiskProfiles, "id")
    }, [allRiskProfiles])

    const allCustomRiskProfilesObj = useMemo<Record<string, IRiskProfile>>(() => {
        if (!allRiskProfiles) return {}

        const filteredRisks = allRiskProfiles.filter((riskProfile) => {
            return riskProfile.type === "custom"
        })
        return arrToDict(filteredRisks, "id")
    }, [allRiskProfiles])

    const [currentRiskProfile, setCurrentRiskProfile] =
        useState<IRiskProfile>()
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false)

    function setCurrentRiskProfileById(riskProfileId?: string) {
        if (!riskProfileId) {
            setCurrentRiskProfile(undefined)
        } else {
            setCurrentRiskProfile(allRiskProfilesObj[riskProfileId])
        }
    }

    const {
        data: hazardVariables,
        isFetching: areHazardVariablesFetching,
        isLoading: areHazardVariablesLoading,
    } = useHazardVariablesQuery(selectedAccount)

    useEffect(() => {
        if (areHazardVariablesFetching && areHazardVariablesLoading)
            setHazardVariablesObj({})
        else if (hazardVariables)
            setHazardVariablesObj(
                hazardVariables.reduce(
                    (
                        prev: Record<string, IHazardVariable>,
                        variable: IHazardVariable
                    ) => ({
                        ...prev,
                        [variable.id]: variable,
                    }),
                    {} as Record<string, IHazardVariable>
                )
            )
    }, [hazardVariables, areHazardVariablesFetching, areHazardVariablesLoading])

    const stageOpts = useMemo(() => {
        const stageOpts: Record<string, string> = {}
        if (allRiskProfiles.length === 0) return stageOpts

        allRiskProfiles.forEach((riskProfile) => {
            riskProfile.stages?.forEach((stage) => {
                const stageName = stage.name.trim()
                stageOpts[stageName] = stageName
            })
        })

        return stageOpts
    }, [allRiskProfiles])

    const value = useMemo(
        () => ({
            riskProfiles: allRiskProfiles,
            riskProfilesObj: allRiskProfilesObj,
            customRiskProfilesObj: allCustomRiskProfilesObj,
            filteredRiskProfiles,
            filteredRiskProfilesInfo,
            currentRiskProfile,
            setCurrentRiskProfile,
            setCurrentRiskProfileById,
            isFormOpen,
            setIsFormOpen,
            loadingRisks,
            hazardVariables: hazardVariables ?? [],
            hazardVariablesObj,
            areHazardVariablesLoading,
            setRiskProfilesFilter,
            riskProfilesFilter,
            stageOpts,
            selectedHazard,
            setSelectedHazard
        }),
        [
            allRiskProfiles,
            allRiskProfilesObj,
            allCustomRiskProfilesObj,
            filteredRiskProfiles,
            currentRiskProfile,
            setCurrentRiskProfile,
            setCurrentRiskProfileById,
            isFormOpen,
            setIsFormOpen,
            loadingRisks,
            hazardVariables,
            hazardVariablesObj,
            setRiskProfilesFilter,
            riskProfilesFilter,
            areHazardVariablesLoading,
            stageOpts,
            selectedHazard,
            setSelectedHazard
        ]
    )

    return (
        <RiskProfilesContext.Provider value={value}>
            {children}
        </RiskProfilesContext.Provider>
    )
}

export default RiskProfilesProvider
