import { useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { Toggler, TooltipV2 } from "../../climateui/components"
import ChevronIcon from "../../climateui/icons/ChevronArrow"
import { useAccount } from "../../providers/AccountProvider"
import { useUI } from "../../providers/UIProvider"
import { getQueryParam } from "../../utils/queryParams"
import ChildRoutesSidebar from "./ChildRoutesSidebar"

const SideBarItem = ({ route, showTooltip, extraClasses }) => {
    const { secondSidebarOptions, setSameNavigationCount } = useUI()
    const location = useLocation()

    let pathName = location.pathname
    pathName = pathName.substring(1, pathName.length)
    if (pathName[pathName.length - 1] === "/")
        pathName = pathName.substring(0, pathName.length - 1)
    const paths = pathName.split("/")
    const firstPath = paths[0]
    const secondPath = paths[1]
    const lastPath = paths[paths.length - 1]

    const routeSecondSidebarOptions =
        secondSidebarOptions[firstPath]?.[route.path]
    const showChevron =
        routeSecondSidebarOptions && routeSecondSidebarOptions.routes.length > 0

    if (routeSecondSidebarOptions?.cannotGoBackToMain && !showChevron)
        return null

    return (
        <li className={extraClasses}>
            <TooltipV2
                containerClasses="w-full h-fit"
                content={route.label}
                position="right"
                doShow={showTooltip}>
                <NavLink
                    className={({ isActive }) =>
                        (isActive
                            ? "text-accent fill-accent font-bold"
                            : "text-gray-90 fill-gray-60") +
                        " flex flex-row items-center gap-3" +
                        " p-3 w-full overflow-hidden group" +
                        " hover:bg-gray-5 transition-all duration-50"
                    }
                    to={route.path}
                    onClick={(e) => {
                        if (
                            secondPath === route.path &&
                            routeSecondSidebarOptions?.cannotGoBackToMain
                        )
                            e.preventDefault()
                        if (lastPath === route.path) {
                            e.preventDefault()
                            setSameNavigationCount((prev) => prev + 1)
                        }
                    }}>
                    <div className="w-[32px] flex flex-row items-center justify-center shrink-0">
                        <span className="w-6">{route.icon}</span>
                    </div>
                    <div className="flex items-center justify-between w-full truncate body-md gap-1 whitespace-nowrap min-w-0">
                        <div className="truncate">{route.label}</div>
                        {showChevron && (
                            <span className="w-6 -rotate-90 -mr-2 opacity-0 group-hover:opacity-100 transition-all duration-50 shrink-0">
                                <ChevronIcon />
                            </span>
                        )}
                        {route.badgePortalId && (
                            <div
                                id={route.badgePortalId}
                                className="shrink-0"></div>
                        )}
                    </div>
                </NavLink>
            </TooltipV2>
        </li>
    )
}

const SideBar = ({ options = [] }) => {
    const {
        sidebarOpen,
        showSecondSidebar,
        setSidebarOpen,
        activeSecondSidebarOptions,
    } = useUI()
    const [showToggle, setShowToggle] = useState(false)
    const { selectedAccount } = useAccount()

    let displayClass = "lg:block"
    if (sidebarOpen) {
        displayClass = showToggle ? "block" : "lg:hidden"
    }

    // TODO change this in the future to a different parameter
    // pagination is not even related
    const paginationParam = getQueryParam("pagination")
    const isPaginated = !paginationParam ? true : paginationParam === "true"

    const doShowSecondSidebar =
        !!activeSecondSidebarOptions && showSecondSidebar

    return (
        <div
            className={[
                "shrink-0 grow-0 bg-white",
                "flex flex-row items-stretch z-20",
                sidebarOpen
                    ? "fixed md:relative bottom-0 top-0 md:inset-y-0 print:hidden"
                    : "relative",
                !isPaginated ? "hidden" : "",
            ].join(" ")}
            onMouseEnter={() => setShowToggle(true)}
            onMouseLeave={() => setShowToggle(false)}>
            {/* TOGGLER ------------------------------------------------------------- */}
            <div className={"absolute z-30 -right-3 top-4 " + displayClass}>
                <Toggler
                    open={sidebarOpen}
                    toggle={setSidebarOpen}
                />
            </div>

            {/* FIRST SIDEBAR ------------------------------------------------------- */}
            <div
                className={[
                    "transition-all duration-100 z-20",
                    "border-r border-gray-14",
                    sidebarOpen && doShowSecondSidebar ? "md:w-[56px]" : "",
                    !sidebarOpen ? "w-[56px]" : "w-[194px]",
                ].join(" ")}>
                <ul className="flex flex-col h-full overflow-hidden">
                    {options.map((route, index) => (
                        <SideBarItem
                            key={"route-" + route.path}
                            route={route}
                            accountID={selectedAccount}
                            showTooltip={!sidebarOpen}
                            extraClasses={index === 0 ? "mt-2" : ""}
                        />
                    ))}
                    {!sidebarOpen && (
                        <div className="p-2 grow md:hidden">
                            <div
                                className="w-full h-full overflow-hidden rounded-md bg-gray-5 grid place-content-center"
                                onClick={() => setSidebarOpen(true)}>
                                <div className="whitespace-nowrap animate-pulse -rotate-90 text-gray-30">
                                    Click to open
                                </div>
                            </div>
                        </div>
                    )}
                </ul>
            </div>

            {/* SECOND SIDEBAR ------------------------------------------------------ */}
            <ChildRoutesSidebar />
        </div>
    )
}

export default SideBar
