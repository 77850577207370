import {
    useState,
    createContext,
    useMemo,
    Dispatch,
    SetStateAction,
} from "react"
import { ArrowBottom } from "../../icons"
import ChevronIcon from "../../icons/ChevronArrow"
import DateView from "./DateView"
import MonthView from "./MonthView"
import { DateTime } from "luxon"
import YearView from "./YearView"
import { DATE, MONTH, YEAR, DECADE, DAYS_IN_WEEK, LUXON_VIEW_MODE_KEY } from "."
import { ICalendarCell } from "./CalendarCell"

export interface IDatePickerContext {
    selectedDate?: DateTime
    endDate?: DateTime
    currHoverDate?: DateTime
    setHoverDate: Dispatch<SetStateAction<DateTime | undefined>>
    range?: boolean | number
    rangeWeekdayStart?: number
    canGoUp?: boolean
    viewMode?: number
}
export type DateValidatorFn = (
    date: DateTime | undefined,
    context: Omit<IDatePickerContext, "setHoverDate">
) => boolean
export const DatePickerContext = createContext<IDatePickerContext>(
    {} as IDatePickerContext
)
interface IDatePickerProps {
    range?: boolean | number
    rangeWeekdayStart?: number
    initialDate?: DateTime
    initialEndDate?: DateTime
    validator?: DateValidatorFn
    invalidMessage?: string
    onChange?: (date?: DateTime, endDate?: DateTime) => void
    clearMessage?: string
    maxView?: number
    minView?: number
    yearAgnostic?: boolean
    canClearInput?: boolean
}

const DatePicker = ({
    range,
    rangeWeekdayStart,
    initialDate,
    initialEndDate,
    onChange,
    clearMessage = "Clear All",
    yearAgnostic = false,
    maxView: _maxView = DECADE,
    minView = DATE,
    canClearInput = true,
    validator,
    invalidMessage,
}: IDatePickerProps) => {
    const [viewDate, setViewDate] = useState(initialDate ?? DateTime.now())
    const [selectedDate, _setSelectedDate] = useState<DateTime | undefined>(
        initialDate
    )
    const [endDate, _setEndDate] = useState<DateTime | undefined>(
        initialEndDate
    )
    const maxView = yearAgnostic ? YEAR : _maxView
    const setSelectedDate = (date?: DateTime) => {
        _setSelectedDate(date)
        _setEndDate(undefined)
        if (onChange) onChange(date, undefined)
    }
    const setEndDate = (date?: DateTime) => {
        _setEndDate(date)
        if (onChange) onChange(selectedDate, date)
    }
    const setDateRange = (start?: DateTime, end?: DateTime) => {
        _setSelectedDate(start)
        _setEndDate(end)
        if (onChange) onChange(start, end)
    }
    const [viewMode, setViewMode] = useState(minView)

    /* STYLES > START */
    const classes = [
        "w-fit",
        "min-height-[270]",
        "flex",
        "flex-col",
        "items-center",
        "rounded-lg",
        "p-1 pb-3",
        "shadow-md",
        "bg-white",
    ]
    const arrowClasses = [
        "w-8",
        "h-8",
        "p-0.5",
        "grow-0",
        "fill-gray-60",
        "cursor-pointer",
    ]
    const dropDownClasses = useMemo(() => {
        const classes = ["w-6", "fill-gray-60"]
        if (viewMode !== DATE) classes.push("rotate-180")
        return classes
    }, [viewMode])

    const dropDownLabel = useMemo(() => {
        const startYear = Math.floor(viewDate.year / 10) * 10
        const endYear = startYear + 9
        switch (viewMode) {
            case YEAR:
                return `${startYear} - ${endYear}`
            case MONTH:
                if (yearAgnostic) return ""
                return viewDate.year
            case DATE:
            default:
                return viewDate.monthLong
        }
    }, [viewDate, viewMode])

    /* STYLES < END */

    /* METHODS & UTILS > START */
    const canGoUp = viewMode + 1 < maxView
    const canGoDown = viewMode - 1 >= minView

    const shiftView = (_value: number) => {
        const diffKey = LUXON_VIEW_MODE_KEY[viewMode + 1]
        let value = _value
        if (viewMode === YEAR) value *= 10

        setViewDate(
            viewDate.plus({
                [diffKey]: value,
            })
        )
    }
    const goUp = () => {
        if (viewMode + 1 > maxView) return
        setViewMode(viewMode + 1)
    }
    const goDown = () => {
        if (viewMode - 1 < minView) return
        setViewMode(viewMode - 1)
    }
    /* METHODS & UTILS < END */

    const [currHoverDate, setCurrHoverDate] = useState<DateTime | undefined>()
    const value = useMemo(
        () => ({
            selectedDate,
            currHoverDate,
            setHoverDate: setCurrHoverDate,
            range,
            rangeWeekdayStart,
            endDate,
            canGoUp,
            viewMode,
        }),
        [
            selectedDate,
            currHoverDate,
            setCurrHoverDate,
            range,
            rangeWeekdayStart,
            endDate,
            canGoUp,
            viewMode,
        ]
    )

    const setWeekdayRange = (date?: DateTime) => {
        if (typeof range !== "number") return
        const nonInclusiveRange = range - 1
        const dateCalcKey = LUXON_VIEW_MODE_KEY[viewMode]

        if (!rangeWeekdayStart) {
            setDateRange(date, date?.plus({ [dateCalcKey]: nonInclusiveRange }))
        } else if (date) {
            let startDate = date
            const diff = date.weekday - rangeWeekdayStart
            if (diff >= 0) {
                startDate = date.minus({ days: diff })
            } else if (diff < 0) {
                startDate = date.minus({
                    days: DAYS_IN_WEEK + diff,
                })
            }
            // -1 to exclude the upper bound
            setDateRange(
                startDate,
                startDate.plus({ [dateCalcKey]: nonInclusiveRange })
            )
        }
    }
    const dateViewOnClick = (cell: ICalendarCell) => {
        if (typeof range === "number") {
            setWeekdayRange(cell.date)
        } else if (!selectedDate || !range) {
            // Set the selected date or the first range date
            setSelectedDate(cell.date)
        } else if (range && cell.date) {
            // Set the range end date, unless it is earlier than the start
            if (!endDate && selectedDate < cell.date) {
                setEndDate(cell.date)
            } else if (!endDate && selectedDate > cell.date) {
                // const diffKey = LUXON_VIEW_MODE_KEY[viewMode + 1]
                // if (!diffKey) return setSelectedDate(cell.date)
                // setEndDate(cell.date.plus({ [diffKey]: 1 }))
                setDateRange(cell.date, selectedDate)
            } else setSelectedDate(cell.date)
        }
    }
    const onDateCellClick = (cell: ICalendarCell) => {
        // If there's no date do nothing
        if (!cell.date) return

        setViewDate(cell.date)
        if (canGoDown) return goDown()
        return dateViewOnClick(cell)
    }
    return (
        <DatePickerContext.Provider value={value}>
            <div className={classes.join(" ")}>
                {canGoUp && (
                    <div className="w-full flex flex-row justify-between items-center mt-3 mb-1.5">
                        <button
                            tabIndex={0}
                            className={arrowClasses.join(" ") + " rotate-90"}
                            onClick={() => shiftView(-1)}>
                            <ChevronIcon />
                        </button>
                        <div>
                            <button
                                tabIndex={0}
                                className="flex flex-row items-center mx-2 cursor-pointer grow"
                                onClick={goUp}>
                                <span>{dropDownLabel}</span>
                                <span className={dropDownClasses.join(" ")}>
                                    <ArrowBottom />
                                </span>
                            </button>
                        </div>
                        <button
                            tabIndex={0}
                            className={arrowClasses.join(" ") + " -rotate-90"}
                            onClick={() => shiftView(1)}>
                            <ChevronIcon />
                        </button>
                    </div>
                )}
                {viewMode === YEAR && (
                    <YearView
                        onClick={onDateCellClick}
                        viewDate={viewDate}
                    />
                )}
                {viewMode === MONTH && (
                    <MonthView
                        yearAgnostic={yearAgnostic}
                        onClick={onDateCellClick}
                        viewDate={viewDate}
                    />
                )}
                {viewMode === DATE && (
                    <DateView
                        yearAgnostic={yearAgnostic}
                        onClick={onDateCellClick}
                        viewDate={viewDate}
                        validator={validator}
                        invalidMessage={invalidMessage}
                    />
                )}
                {canClearInput && selectedDate && (
                    <button
                        className="self-end mr-1.5 text-accent"
                        onClick={() => {
                            setSelectedDate()
                        }}>
                        {clearMessage}
                    </button>
                )}
            </div>
        </DatePickerContext.Provider>
    )
}
export default DatePicker
