import { LegacyRef, ReactNode, Fragment } from "react"
import { Transition } from "@headlessui/react"

export interface IEmptyModal {
    children: ReactNode | ReactNode[]
    customClasses?: string
    customStyle?: Record<string, any>
    modalRef?: LegacyRef<HTMLDivElement> | null
    open?: boolean
}
function EmptyModal({
    children,
    customClasses = "w-[540px] h-[534px]",
    customStyle = {},
    modalRef = null,
    open = true,
}: IEmptyModal) {
    return (
        <Transition
            show={open}
            as={Fragment}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-75"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed flex flex-row justify-center items-center bg-gray-90/[.5] inset-0 z-full">
                    <div
                        ref={modalRef}
                        className={
                            "relative rounded-lg bg-white border border-gray-14 overflow-hidden " +
                            customClasses
                        }
                        style={customStyle}>
                        {children}
                    </div>
                </div>
            </Transition.Child>
        </Transition>
    )
}

export default EmptyModal
