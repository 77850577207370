export const applyTopSort = (
    selectedKeysList: string[],
    showSelectedOnTop: boolean,
    a: string,
    b: string
) => {
    // 'All' option should always come first
    if (b === "all") return 1
    if (a === "all") return -1
    if (!showSelectedOnTop) return 0
    // Selected options should come first
    if (selectedKeysList.includes(a) && !selectedKeysList.includes(b)) return -1
    if (!selectedKeysList.includes(a) && selectedKeysList.includes(b)) return 1
    return 0
}

export const applySortByKey = (_a: string, _b: string, sortByKey: boolean) => {
    // Sort alphabetically by key
    let isNumber = 1
    let a: string | number = _a
    let b: string | number = _b
    if (!isNaN(+_a) && !isNaN(+_b)) {
        a = +_a
        b = +_b
        isNumber = -1
    }
    if (!sortByKey) return 0
    if (a > b) return 1 * isNumber
    if (a < b) return -1 * isNumber
    return 0
}

export const applySortByVal = (
    _a: string,
    _b: string,
    options: Record<string, string>
) => {
    let isNumber = 1
    let a: string | number = options[_a]
    let b: string | number = options[_b]
    if (!isNaN(+a) && !isNaN(+b)) {
        a = +a
        b = +b
        isNumber = -1
    }
    // Sort alphabetically by value
    if (a > b) return 1 * isNumber
    if (a < b) return -1 * isNumber
    return 0
}

export const applyBottomSort = (
    disabledKeyList: string[],
    a: string,
    b: string
) => {
    // Sort disabled keys to the bottom
    if (disabledKeyList.includes(a) && !disabledKeyList.includes(b)) return 1
    if (!disabledKeyList.includes(a) && disabledKeyList.includes(b)) return -1
    return 0
}

export const getDropdownSorter =
    ({
        selectedKeysList,
        options,
        keepOrder,
        sortByKey,
        showSelectedOnTop,
        disabledKeysList,
    }: {
        selectedKeysList?: string[]
        options: Record<string, string>
        keepOrder: boolean
        sortByKey: boolean
        showSelectedOnTop?: boolean
        disabledKeysList?: string[]
    }) =>
        (a: string, b: string) => {
            if (keepOrder) return 0
            return (
                (selectedKeysList &&
                    showSelectedOnTop &&
                    applyTopSort(selectedKeysList, showSelectedOnTop, a, b)) ||
                (disabledKeysList &&
                    !showSelectedOnTop &&
                    applyBottomSort(disabledKeysList, a, b)) ||
                applySortByKey(a, b, sortByKey) ||
                applySortByVal(a, b, options)
            )
        }

export const conditionalToText = (conditional: string): string => {
    let textConditional = ''
    if (conditional === '<') {
        textConditional = 'Less than'
    } else if (conditional === '>') {
        textConditional = 'Greater than'
    } else if (conditional === '=') {
        textConditional = 'Equal to'
    }

    return textConditional
}