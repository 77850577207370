import {
    ChangeEventHandler,
    FocusEventHandler,
    KeyboardEventHandler,
    LegacyRef,
    ReactNode,
} from "react"
import { alwaysBorderRedClasses, defaultInputClasses } from "./utils"

interface IGenericInput {
    error?: string
    icon?: ReactNode
    disabled?: boolean
    handleChange?: ChangeEventHandler<HTMLInputElement>
    handleBlur?: FocusEventHandler
    handleKeyUp?: KeyboardEventHandler<HTMLInputElement>
    inputRef?: LegacyRef<HTMLInputElement> | null
    value?: string
    defaultValue?: string
    placeholder?: string
    type?: string
    id?: string
    name?: string
    cursorPointer?: boolean
}

function GenericInput({
    error = "",
    icon,
    disabled = false,
    handleChange,
    handleBlur,
    handleKeyUp,
    inputRef,
    value,
    defaultValue,
    placeholder,
    type,
    id,
    name,
    cursorPointer,
}: IGenericInput) {
    // INFO: Component for text, email, password and other simple text inputs
    return (
        <div className={"flex flex-col w-full gap-1"}>
            <div
                className={[
                    "flex flex-row items-center",
                    "relative overflow-hidden",
                    ...(icon ? defaultInputClasses : []),
                ].join(" ")}>
                {icon && (
                    <span className="h-[20px] w-[20px] z-10 grow-0 shrink-0 fill-gray-60 cursor-pointer">
                        {icon}
                    </span>
                )}
                <input
                    className={[
                        ...defaultInputClasses,
                        error !== "" ? alwaysBorderRedClasses : "",
                        icon !== undefined ? "border-0" : "",
                        cursorPointer ? "cursor-pointer" : "",
                    ].join(" ")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    ref={inputRef}
                    disabled={disabled}
                    value={value}
                    defaultValue={defaultValue}
                    type={type}
                    placeholder={placeholder}
                    id={id}
                    name={name}
                />
            </div>
            {error !== "" && (
                <div className="font-normal text-red body-sm">{error}</div>
            )}
        </div>
    )
}

export default GenericInput
