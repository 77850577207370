import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import {
    AltArrowLeft,
    CancelIcon,
    TrashIcon,
} from "../../../../climateui/icons"

export interface ILocationsFormHeader {
    doShowGoBack?: boolean
    doShowDelete?: boolean
    onDeleteLocation?: () => void
    title: string
    subtitle?: ReactNode
}

export default function LocationsFormHeader({
    doShowGoBack = false,
    doShowDelete = false,
    onDeleteLocation = () => void 0,
    title,
    subtitle,
}: ILocationsFormHeader) {
    const navigate = useNavigate()

    return (
        <>
            <div className="flex flex-row items-center gap-2">
                {doShowGoBack && (
                    <span
                        className="w-5 h-5 cursor-pointer"
                        onClick={() => navigate("../")}>
                        <AltArrowLeft />
                    </span>
                )}
                <div className="title-sm grow">{title}</div>
                {doShowDelete && (
                    <>
                        <span
                            className="ml-[100px] w-5 h-5 cursor-pointer"
                            onClick={() => {
                                onDeleteLocation()
                            }}>
                            <TrashIcon />
                        </span>
                        <span
                            className="w-5 h-5 cursor-pointer"
                            onClick={() => navigate("../")}>
                            <CancelIcon />
                        </span>
                    </>
                )}
            </div>
            {subtitle && <div className="body-md text-gray-60">{subtitle}</div>}
        </>
    )
}
