import React from "react"
import { Customized, Label, ReferenceArea, ReferenceLine } from "recharts"

const LEFT_PADDING = 60

export interface RenderRiskChartReferencesProps {
    lowerBound?: number
    upperBound?: number
}

const renderRiskChartReferences = ({
    lowerBound,
    upperBound,
}: RenderRiskChartReferencesProps) => {
    if (!lowerBound || !upperBound) {
        return null
    }

    return (
        <>
            {/* Reference Lines for Risk Outloks */}
            <Customized
                component={({ yAxisMap, xAxisMap }: any) => {
                    const y1 = yAxisMap[0].scale(0)
                    const y2 = yAxisMap[0].scale(lowerBound)
                    const x = xAxisMap[0].scale(xAxisMap[0].domain[0])
                    const middleY = (y1 + y2) / 2

                    return (
                        <g>
                            <text
                                x={LEFT_PADDING}
                                y={y1}
                                textAnchor="start"
                                fill="#666D74"
                                fontSize={12}>
                                Low
                            </text>
                        </g>
                    )
                }}
            />
            <ReferenceLine
                y={lowerBound}
                stroke={"#aaa"}
                strokeDasharray={"3 3"}
            />
            <Customized
                component={({ yAxisMap }: any) => {
                    const y1 = yAxisMap[0].scale(lowerBound)
                    const y2 = yAxisMap[0].scale(upperBound)
                    const middleY = (y1 + y2) / 2

                    return (
                        <g>
                            <text
                                x={LEFT_PADDING}
                                y={middleY + 4}
                                textAnchor="start"
                                fill="#666D74"
                                fontSize={12}>
                                Medium
                            </text>
                        </g>
                    )
                }}
            />
            <ReferenceLine
                y={upperBound}
                stroke={"#aaa"}
                strokeDasharray={"3 3"}
            />
            <Customized
                component={({ yAxisMap, xAxisMap }: any) => {
                    const y1 = yAxisMap[0].scale(upperBound)
                    const y2 = yAxisMap[0].scale(100)

                    return (
                        <g>
                            <text
                                x={LEFT_PADDING}
                                y={y2 + 12}
                                textAnchor="start"
                                fill="#666D74"
                                fontSize={12}>
                                High
                            </text>
                        </g>
                    )
                }}
            />

            {/* Reference Lines */}
            <Customized
                component={(payload: any) => {
                    return (
                        <>
                            {/* upper bound reference */}
                            <line
                                x1={LEFT_PADDING - 10}
                                y1={payload.yAxisMap[0].scale(
                                    payload.yAxisMap[0].domain[
                                        payload.yAxisMap[0].domain.length - 1
                                    ]
                                )}
                                x2={LEFT_PADDING - 10}
                                y2={payload.yAxisMap[0].scale(upperBound)}
                                stroke="#FF0000"
                                strokeWidth={4}
                            />
                            {/* middle bound reference */}
                            <line
                                x1={LEFT_PADDING - 10}
                                y1={payload.yAxisMap[0].scale(upperBound)}
                                x2={LEFT_PADDING - 10}
                                y2={payload.yAxisMap[0].scale(lowerBound)}
                                stroke="#FFE602"
                                strokeWidth={4}
                            />
                            {/* lower bound reference */}
                            <line
                                x1={LEFT_PADDING - 10}
                                y1={payload.yAxisMap[0].scale(lowerBound)}
                                x2={LEFT_PADDING - 10}
                                y2={payload.yAxisMap[0].scale(
                                    payload.yAxisMap[0].domain[0]
                                )}
                                stroke="#11C602"
                                strokeWidth={4}
                            />
                        </>
                    )
                }}
            />
        </>
    )
}

export default renderRiskChartReferences
