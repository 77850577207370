import http from "../../climateui/utils/http"
import config from "../config"

export const accountPlansGET = async ({ queryKey }) => {
    const accountId = queryKey[1]
    return await http.get(
        `/planning/?account_id=${accountId}`,
        true,
        {
            headers: {
                "X-Fields":
                    "{id,account_id,name,description,created_by,updated_at}",
            },
        },
        config.planning_platform
    )
}

export const prepopulatePlanPOST = (data) =>
    http.post(
        "/stage_catalog/prepopulate",
        data,
        true,
        {},
        config.planning_platform
    )

export const planPOST = (data) =>
    http.post("/planning/", data, true, {}, config.planning_platform)

const planMainXFields = "id,account_id,name,description,created_by,updated_at"
const plannedRiskXFields =
    "planned_risks{id,start_date,end_date,risk_profile_id,risk_setting_id,expected_impact_time_series,observed_impact_time_series}"
const stageXFields = `stages{id,name,color,start_date,end_date,${plannedRiskXFields}}`
const strategyXFields = `strategies{id,asset_variety_id,location_id,region_id,description,${stageXFields},${plannedRiskXFields}}`

export const planGET = (planId) =>
    http.get(
        `/planning/${planId}`,
        true,
        {
            headers: {
                "X-Fields": `{${planMainXFields},${strategyXFields}}`,
            },
        },
        config.planning_platform
    )

export const planPUT = (plan) =>
    http.put(`/planning/${plan.id}`, plan, true, {}, config.planning_platform)

export const planAlertSettingsPUT = (riskSettings) =>
    http.post(
        "/planning/to_alert_settings",
        riskSettings,
        true,
        {},
        config.planning_platform
    )

export const planDELETE = (planId) =>
    http.delete(`/planning/${planId}`, null, true, {}, config.planning_platform)

export const gddsGET = (lat, lon, params = {}) => {
    const queryParams = new URLSearchParams([
        ["lat", lat],
        ["lon", lon],
    ])

    for (const [key, value] of Object.entries(params)) {
        if (value !== null && value !== undefined) {
            queryParams.append(key, value)
        }
    }

    return http.get(
        `/plugin/var/gdd?${queryParams.toString()}`,
        true,
        {},
        config.planning_platform
    )
}
