const MagnitudeIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
            {...props}>
            <path
                d="M5 20V14H8V20H5ZM11 20V9H14V20H11ZM17 20V4H20V20H17Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default MagnitudeIcon
