import { useCallback, useEffect, useRef, useState } from "react"
import { FixedAlignment, FixedPosition } from "../components/FixedElement"
import { NullableElement } from "../types"
import { applyTransformOffset } from "../utils/css"
import _ from "lodash"

const hasSpaceTop = ({ top, height }: DOMRect, extraSpaceFactor: number) => {
    return top > extraSpaceFactor * height
}
const hasSpaceBottom = ({ top, height }: DOMRect, extraSpaceFactor: number) => {
    return window.innerHeight - top > extraSpaceFactor * height
}
const hasSpaceLeft = ({ left, width }: DOMRect, extraSpaceFactor: number) => {
    return left > extraSpaceFactor * width
}
const hasSpaceRight = ({ left, width }: DOMRect, extraSpaceFactor: number) => {
    return window.innerWidth - left > extraSpaceFactor * width
}

const DEFAULT_MARGIN = 20
const DEFAULT_DEBOUNCE_DELAY = 8
const hasSpace = (
    positionOrAlign: FixedAlignment,
    boundingRect: DOMRect,
    extraSpaceFactor: number
) => {
    const posOrAli = positionOrAlign.toLowerCase()
    switch (posOrAli) {
        case "top":
            return hasSpaceTop(boundingRect, extraSpaceFactor)
        case "bottom":
            return hasSpaceBottom(boundingRect, extraSpaceFactor)
        case "left":
            return hasSpaceLeft(boundingRect, extraSpaceFactor)
        case "right":
            return hasSpaceRight(boundingRect, extraSpaceFactor)
        default:
            return true
    }
}
const isSizeable = (element: Element | undefined | null) => {
    if (!element) return false
    const { width, height } = element.getBoundingClientRect()
    return width || height
}

const INVERSE_POSITIONS: Record<FixedPosition, FixedPosition> = {
    top: "bottom",
    bottom: "top",
    left: "right",
    right: "left",
}
const getFirstSizeableSibling = (element: NullableElement): NullableElement => {
    if (!element) return document.body
    const parentElement = element.parentElement
    if (!parentElement) return document.body
    for (const child of parentElement.children) {
        if (child === element) continue
        if (isSizeable(child)) return child as HTMLElement
    }
}
const getFirstSizeableSiblingOrParent = (element: NullableElement): Element => {
    if (!element) return document.body
    const sizeableSibling = getFirstSizeableSibling(element)
    if (sizeableSibling) return sizeableSibling
    const parentElement = element.parentElement
    if (!parentElement) return document.body
    if (isSizeable(parentElement)) return parentElement
    return getFirstSizeableSiblingOrParent(parentElement)
}

const OO_BOUNDS_ZERO_VALUE = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
}

const getOutOfBounds = (
    element?: Element,
    margin: number = DEFAULT_MARGIN
): { top: number; right: number; bottom: number; left: number } => {
    if (!element) return OO_BOUNDS_ZERO_VALUE

    if (element.clientWidth === 0 || element.clientHeight === 0) {
        if (element.firstElementChild)
            return getOutOfBounds(element.firstElementChild, margin)

        if (element.nextElementSibling)
            return getOutOfBounds(element.nextElementSibling, margin)

        return OO_BOUNDS_ZERO_VALUE
    }

    const { top, right, bottom, left } = element.getBoundingClientRect()

    const viewportWidth = window.visualViewport?.width ?? 0
    const viewportHeight = window.visualViewport?.height ?? 0
    return {
        top: top - margin,
        right: viewportWidth - (right + margin),
        bottom: viewportHeight - (bottom + margin),
        left: left - margin,
    }
}

const getIntoBoundsOffset = (
    element: NullableElement,
    margin: number = DEFAULT_MARGIN
) => {
    const offset = {
        top: 0,
        left: 0,
    }
    if (element) {
        const { top, right, bottom, left } = getOutOfBounds(element, margin)
        if (bottom < 0 && top > 0 && -bottom <= top) {
            offset.top = bottom
        } else if (top < 0 && bottom > 0 && -top <= bottom) {
            offset.top = -top
        }

        if (right < 0 && left > 0 && -right <= left) {
            offset.top = right
        } else if (left < 0 && right > 0 && -left <= right) {
            offset.left = -left
        }
    }
    return offset
}

/**
 * Hook that calculates a better position and alignment for
 * FixedElement ref depending on its position on the screen
 */

const applyOffset = _.debounce((element: NullableElement, margin?: number) => {
    if (!element) return
    const { top, left } = getIntoBoundsOffset(element, margin)
    if (top === 0 && left === 0) return
    applyTransformOffset({
        element,
        translateX: left,
        translateY: top,
    })
}, DEFAULT_DEBOUNCE_DELAY)
function useResponsiveFixedPositionAndAlignment(margin?: number) {
    const controllers = useRef({
        resize: new AbortController(),
        scroll: new AbortController(),
    })
    const [element, setElement] = useState<NullableElement>(null)

    const fixedElementRef = useCallback((_element: NullableElement) => {
        if (!_element) return
        setElement(_element)

        window.addEventListener(
            "resize",
            () => {
                applyOffset(element, margin)
            },
            { signal: controllers.current.resize.signal }
        )
        window.addEventListener("scroll", () => applyOffset(element, margin), {
            signal: controllers.current.scroll.signal,
        })
    }, [])

    // Clean-up
    useEffect(() => {
        return () =>
            Object.values(controllers.current).forEach((controller) =>
                controller.abort()
            )
    }, [])

    // NOTE: This feels like a code-smell/antipattern and any feedback would be
    // highly appreciated
    useEffect(() => {
        if (!element) return
        applyOffset(element, margin)
    })

    return {
        fixedElementRef,
    }
}

export default useResponsiveFixedPositionAndAlignment
