import { useEffect, useMemo, useState } from "react"

interface IPaginationProps {
    page: number
    per_page: number
    all: boolean
}

const pageSizeArr = [5, 10, 20]

// Note -- Opted to leave data with 'any[]' so that we can reuse this function
// regardless of the type of data stored in the input array

function usePaginationControls(data: any[]) {
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: pageSizeArr[0],
        all: false,
    } as IPaginationProps)

    const defaultPageSizeOpts: Record<number, string> = {}
    pageSizeArr.forEach((option) => {
        defaultPageSizeOpts[option] = `${option} ${"rows"}`
    })

    const totalNumAlerts = data ? Object.keys(data).length : 0
    const totalPages = Math.ceil(totalNumAlerts / pagination.per_page)

    function handlePageSizeChange(size: number) {
        setPagination({ ...pagination, page: 1, per_page: size })
    }

    function handleFirstClick() {
        setPagination({ ...pagination, page: 1 })
    }

    function handleLastClick() {
        setPagination({ ...pagination, page: totalPages })
    }

    function handlePageChange(next: boolean) {
        if (next && pagination.page !== totalPages) {
            setPagination({
                ...pagination,
                page: pagination.page + 1,
            })
        } else if (!next && pagination.page !== 1) {
            setPagination({
                ...pagination,
                page: pagination.page - 1,
            })
        }
    }

    const { startingIndex, endingIndex } = useMemo(() => {
        if (!data) return { startingIndex: 0, endingIndex: 0 }
        let startingIndex = 0
        let endingIndex = 0
        if (pagination.page !== 1) {
            startingIndex = (pagination.page - 1) * pagination.per_page
        }
        endingIndex = startingIndex + pagination.per_page
        if (endingIndex > totalNumAlerts) {
            endingIndex = totalNumAlerts
        }
        return { startingIndex, endingIndex }
    }, [pagination.page, pagination.per_page, data])

    useEffect(() => {
        setPagination({ ...pagination, page: 1 })
    }, [data])

    const paginationControls = useMemo(() => {
        return {
            ...pagination,
            startingIndex: startingIndex,
            endingIndex: endingIndex,
            totalPages: totalPages,
            defaultPageSizeOpts: defaultPageSizeOpts,
            onPageSizeChange: handlePageSizeChange,
            onFirstClick: handleFirstClick,
            onLastClick: handleLastClick,
            onPageChange: handlePageChange,
        }
    }, [startingIndex, endingIndex])

    return paginationControls
}

export default usePaginationControls
