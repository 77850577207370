import { useTranslate } from "@tolgee/react"
import { useMemo } from "react"
import { Table } from "../../../../climateui/components"
import { ITableWidgetProps } from "../../../../climateui/components/Widgets/Table/TableWidget"
import { useAssets } from "../../../../providers"
import { columns } from "../YieldOverviewTableUtils"
import { IYieldCountryOverviewModel, IYieldOverview } from "./YieldOverview"

function YieldOverviewWidget(props: ITableWidgetProps) {
    const { t } = useTranslate()
    const { data, loading } = props
    const { allAssets } = useAssets()

    const _data = useMemo(() => {
        const assetModelsRelationObj: Record<
            string,
            IYieldCountryOverviewModel[]
        > = {}
        const assetModelRelationOverview: IYieldOverview[] = []
        if (!data) return []
        data.forEach((yield_model) => {
            const assetId = yield_model?.asset_id
            if (!assetModelsRelationObj[assetId]) {
                assetModelsRelationObj[assetId] = []
            }
            assetModelsRelationObj[assetId].push(
                yield_model as IYieldCountryOverviewModel
            )
        })
        Object.entries(assetModelsRelationObj).forEach(
            ([assetId, modelsArray]) => {
                assetModelRelationOverview.push({
                    asset_id: assetId,
                    asset_name: allAssets ? allAssets[assetId].name : "",
                    asset_models: modelsArray,
                })
            }
        )
        return assetModelRelationOverview
    }, [data])

    return (
        <Table<IYieldOverview>
            data={_data}
            columns={columns}
            noDataMessage={
                loading ? t("loadingCountryModels") : t("noModelsAvailable")
            }
        />
    )
}

export default YieldOverviewWidget
