import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios"
import { IPrimitivesDictionary, PrimitiveAny } from "../../types"

const API_URL = process.env["REACT_APP_API_URL"]

export type CustomResponse = AxiosResponse

export type CAIResponse = CustomResponse | AxiosError | null | undefined

export const isCustomResponse = (value: CAIResponse): value is CustomResponse =>
    value !== undefined && value !== null

export const isValidResponse = (value: CAIResponse): value is CustomResponse =>
    isCustomResponse(value) && (value as CustomResponse).data

// TODO: Replace instances of this function with `isValidResponse`
export const areResTypeAndDataValid = (
    res: CustomResponse | AxiosError | null | undefined
) => {
    if (!res || !isCustomResponse(res) || !res.data) return false
    return true
}

export interface IRequestConfig extends AxiosRequestConfig {
    language: string
    lang: string
}
export function getUserToken(_project?: string) {
    const project = process.env.REACT_APP_PROJECT_NAME || _project
    if (!project) return null
    const tokens = {
        access_token: localStorage.getItem(`climateai-${project}-access-token`),
        refresh_token: localStorage.getItem(
            `climateai-${project}-refresh-token`
        ),
    }

    if (
        (tokens.access_token && tokens.access_token !== "undefined") ||
        (tokens.refresh_token && tokens.refresh_token !== "undefined")
    )
        return tokens
    return null
}
export function storeUserToken(
    data?: Record<string, PrimitiveAny | string[] | undefined> | null,
    _project?: string
) {
    const project = process.env.REACT_APP_PROJECT_NAME || _project
    if (!data) {
        localStorage.removeItem(`climateai-${project}-access-token`)
        localStorage.removeItem(`climateai-${project}-refresh-token`)
        return
    }
    localStorage.setItem(
        `climateai-${project}-access-token`,
        data.access_token as string
    )
    localStorage.setItem(
        `climateai-${project}-refresh-token`,
        data.refresh_token as string
    )
}

export function makeRequest(
    method = "",
    path = "",
    data?:
        | IPrimitivesDictionary
        | IPrimitivesDictionary[]
        | Record<string, any>
        | null,
    config: Partial<IRequestConfig> = {},
    requiresToken = true,
    apiURL = API_URL
) {
    // check if method is valid
    if (
        !(
            method &&
            typeof method === "string" &&
            ["get", "post", "put", "delete"].includes(method.toLowerCase())
        )
    )
        throw new TypeError("You must provide a valid Http Method.")

    // parse body
    const _body =
        method.toLowerCase() !== "get" && (data || config.data)
            ? { data: data || config.data || {} }
            : undefined
    // generate cancel controller
    const cancelSource = axios.CancelToken.source()

    const _request: Promise<CustomResponse | AxiosError | null> & {
        cancel?: () => void
    } = new Promise((resolve, reject) => {
        // get user token
        let userToken = null
        if (requiresToken)
            userToken = getUserToken() as { [key: string]: string }

        // check if auth is needed
        const auth = typeof config.auth === "undefined" || config.auth
        if (auth && requiresToken) {
            if (!userToken) throw new TypeError("User is not logged.")
        }

        // draft headers
        let headers: { [x: string]: string | number | boolean } = {
            "Content-Type": "application/json; charset=UTF-8",
            "Accept-Language": config.language ? config.language : "en",
            "lang": config.lang ? config.lang : "en",
        }
        if (requiresToken && userToken) {
            headers["Authorization"] =
                "Bearer " +
                (path === "/auth/refresh"
                    ? userToken.refresh_token
                    : userToken.access_token)
        }
        if (config.headers) {
            headers = { ...headers, ...config.headers }
        }

        // make request
        return axios
            .create({
                baseURL: config.baseURL || apiURL,
                method: method || config.method,
                timeout: config.timeout || 30000,
                // signal: cancelController.signal,
                cancelToken: cancelSource.token,
                headers,
            })
            .request({
                url: path,
                data: _body?.data,
                responseType: config.responseType,
            })
            .then((response) => {
                // Axios response
                resolve(response)
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    resolve(null)
                    return
                }

                console.error(
                    `An error occurred processing the following request: ${method.toUpperCase()} ${path}`,
                    err
                )
                // Axios error
                resolve(err as AxiosError)
            })
    })
    _request.cancel = cancelSource.cancel

    return _request
}
export const addQueryParams = (
    url: string,
    queryParams?: IPrimitivesDictionary
) => {
    // Check if queryParams is defined
    if (!queryParams) return url

    // Add query parameters delimiter
    url += "?"

    // Obtain all the key-value pairs
    const entries = Object.entries(queryParams)

    // Check if the length of the queryParams is greater than 0
    if (entries.length === 0) return url

    // If there are more parameters, add them too
    entries.forEach(([k, v], idx) => {
        if (idx > 0) url += "&"
        url += `${encodeURIComponent(k)}=${encodeURIComponent(v as string)}`
    })
    // Return the encoded parameter
    return url
}

type HTTPEmptyRequest = (
    path: string,
    requiresToken?: boolean,
    config?: Partial<IRequestConfig>,
    apiURL?: string
) => ReturnType<typeof makeRequest>
type HTTPRequest = (
    path: string,
    data?:
        | IPrimitivesDictionary
        | IPrimitivesDictionary[]
        | Record<string, any>
        | null,
    requiresToken?: boolean,
    config?: Partial<IRequestConfig>,
    apiURL?: string
) => ReturnType<typeof makeRequest>

class HTTPModule {
    private defaultAPIURL = ""
    private config = {}

    initializeHttpModule(apiURL: string, config: any) {
        this.config = config
        this.defaultAPIURL = apiURL
    }

    get(path: string, requiresToken = true, config: any, apiURL?: string) {
        if (!apiURL) apiURL = this.defaultAPIURL
        return makeRequest("GET", path, null, config, requiresToken, apiURL)
    }
    post(
        path: string,
        data: any,
        requiresToken = true,
        config: any,
        apiURL?: string
    ) {
        if (!apiURL) apiURL = this.defaultAPIURL
        return makeRequest("POST", path, data, config, requiresToken, apiURL)
    }
    put(
        path: string,
        data: any,
        requiresToken = true,
        config: any,
        apiURL?: string
    ) {
        if (!apiURL) apiURL = this.defaultAPIURL
        return makeRequest("PUT", path, data, config, requiresToken, apiURL)
    }
    delete(
        path: string,
        data: any,
        requiresToken = true,
        config: any,
        apiURL?: string
    ) {
        if (!apiURL) apiURL = this.defaultAPIURL
        return makeRequest("DELETE", path, data, config, requiresToken, apiURL)
    }
}

export default new HTTPModule()
export { QuerySet } from "./QuerySet"
