import CheckmarkIcon from "../../icons/CheckmarkIcon"

export interface ColorPickerIterface {
    selected?: string
    onColorSelect?: (color: string) => void
    colors?: string[][]
}

function ColorPicker({
    selected = "",
    onColorSelect = (color: string) => void 0,
    colors = [
        ["var(--color-primary)", "#1C9690", "#2187C1", "#8E3FF3", "#464B86"],
        ["#55DDE0", "#E43F6F", "#FF99C8", "#C9E4CA", "#FFCF00"],
    ],
}: ColorPickerIterface) {
    return (
        <div className="flex flex-col gap-1">
            {colors.map((row, i) => {
                return (
                    <div
                        className="flex flex-row gap-1"
                        key={`color-picker-div_${i}`}>
                        {row.map((color) => (
                            <button
                                key={`color-picker-item__${color}`}
                                className="w-5 h-5 rounded-sm cursor-pointer transition-all outline-0 hover:scale-110 fill-white"
                                style={{ backgroundColor: color }}
                                onClick={() => onColorSelect(color)}>
                                {color === selected && <CheckmarkIcon />}
                            </button>
                        ))}
                    </div>
                )
            })}
        </div>
    )
}

export default ColorPicker
