import { useCallback, useEffect, useRef, useState } from "react"

// TODO this will need to get more robust in the future, allowing the structure to
// hold additional information of the widgets beyond their id and loading state
export default function useWidgetLifecycleTracker(
    options: { delay: number } = { delay: 1000 }
) {
    // collection is a dictionary of widget ids whose value (at this moment) is just
    // a boolean tracking if the widget has loaded any information
    const [collection, setCollection] = useState<Record<string, boolean>>({})

    // settled is a flag indicating that all widgets in the collection have
    // finished their loading processes
    const [settled, setSettled] = useState(false)

    // isExecuting is another flag used to toggle the settled state with the purpose
    // of notifying the report generation once and avoid unintended behaviors for
    // consecutive renders
    const isExecuting = useRef(false)

    useEffect(() => {
        const isCollectionFull =
            Object.keys(collection).length !== 0 &&
            Object.values(collection).every((val) => {
                return val === true
            })

        if (
            // ignore if there's a process already running
            isExecuting.current ||
            // validate there are values in the collection to track in the first place
            !isCollectionFull ||
            // if widgets are settled already, skip this step
            (settled && isCollectionFull)
        )
            return

        // set execution flag
        isExecuting.current = true

        // chained timeouts atm
        setTimeout(() => {
            // first one to change the state as settled
            setSettled(() => true)
            setTimeout(() => {
                // second one to turn it off + to clean the state
                setSettled(() => false)
                // remove collections to avoid unintended behaviors in case
                // the dashboard reloads with different filters
                setCollection(() => ({}))
                // toggle off execution flag
                isExecuting.current = false
            }, 1000)
        }, options.delay)
        // }
    }, [collection])

    const changeStatus = useCallback((id: string, status: boolean) => {
        setCollection((collection) => {
            return {
                ...collection,
                [id]: status,
            }
        })
    }, [])

    //
    const register = (id: string) => {
        changeStatus(id, false)
    }
    const load = (id: string) => {
        changeStatus(id, true)
    }

    return { settled, register, load }
}
