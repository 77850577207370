import "./LoadingCircleIcon.css"

function LoadingCircleIcon(props) {
    return (
        <svg
            id="loadingCircle"
            width="100%"
            height="100%"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M14 7C14 8.49375 13.5222 9.94832 12.6364 11.1511C11.7505 12.3538 10.5032 13.2417 9.07672 13.6848C7.65023 14.128 6.11938 14.1032 4.70797 13.6141C3.29657 13.125 2.07863 12.1973 1.23222 10.9665C0.385812 9.73565 -0.0446849 8.26637 0.00366843 6.77341C0.0520217 5.28044 0.57669 3.8421 1.50097 2.66864C2.42525 1.49519 3.70066 0.648177 5.14076 0.251429C6.58085 -0.14532 8.1101 -0.0709945 9.50493 0.463538L8.78157 2.3511C7.78953 1.97092 6.70189 1.91806 5.67766 2.20024C4.65342 2.48242 3.74631 3.08483 3.08894 3.91942C2.43157 4.75401 2.05841 5.77701 2.02402 6.83884C1.98963 7.90068 2.29581 8.94567 2.8978 9.82105C3.49979 10.6964 4.36602 11.3563 5.36985 11.7041C6.37368 12.052 7.46246 12.0696 8.47702 11.7544C9.49158 11.4393 10.3787 10.8078 11.0087 9.95236C11.6387 9.09693 11.9786 8.06239 11.9786 7H14Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default LoadingCircleIcon
