import { useTranslate } from "@tolgee/react"
import { useEffect, ReactNode, useMemo, Fragment } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Banner, PortalComponent } from "../climateui/components"
import { IRoute } from "../climateui/types"
import { IRoutesFlags, useRoutesFeatureFlags } from "../hooks"
import { protectedRoutes } from "../utils/appRoutes"
import { ForbiddenView } from "../views"
import { IAccountContext, useAccount } from "./AccountProvider"
import { useAssets } from "./AssetsProvider"
import { useAuth, IAuthContext, isAllowedToRoute } from "./AuthProvider"
import { Transition } from "@headlessui/react"

interface IGuardProps {
    children: ReactNode
}
interface IRoleGuardProps extends IGuardProps {
    role: string
}
interface IPermissionsGuardProps extends IGuardProps {
    permissions: string[]
}
interface IFeatureFlagGuardProps extends IGuardProps {
    featureFlags: string[]
}

const firstAllowedChildRoute = (
    acc: IAccountContext,
    auth: IAuthContext,
    routesFeatureFlags: IRoutesFlags,
    routes?: IRoute[],
    path = ""
): string => {
    // If there are no routes, return the draft path
    if (!routes || routes.length === 0 || !acc.selectedAccount) return path

    // We assume that the user has access until proven otherwise
    let isAllowed = true

    // We keep track of the current route
    let currentRoute = {} as IRoute

    // Search accessible routes
    for (currentRoute of routes) {
        // Check if the user has the correct role
        isAllowed &&= isAllowedToRoute(
            currentRoute,
            acc,
            auth,
            routesFeatureFlags
        )
        // If the route is allowed, stop the search and go deeper
        if (isAllowed) break
        else isAllowed = true
    }

    return firstAllowedChildRoute(
        acc,
        auth,
        routesFeatureFlags,
        currentRoute.children,
        path + "/" + currentRoute.path
    )
}

export const useAuthNavigation = () => {
    const auth = useAuth()
    const acc = useAccount()
    const routesFeatureFlags = useRoutesFeatureFlags()

    const navigate = useNavigate()

    const navigateToAllowed = (
        routes: IRoute[] = protectedRoutes,
        path = ""
    ) => {
        navigate(
            firstAllowedChildRoute(acc, auth, routesFeatureFlags, routes, path)
        )
    }

    return {
        navigateToAllowed,
    }
}

interface IGenericGuard {
    isLoading: boolean
    isForbidden: boolean
    children: ReactNode
}
function GenericGuard({
    isLoading,
    isForbidden,
    children,
}: IGenericGuard) {
    if (isLoading) return <>{children}</>

    if (isForbidden) return <ForbiddenView />

    return <>{children}</>
}

export function RoleGuard({ children, role = "" }: IRoleGuardProps) {
    const auth = useAuth()
    const acc = useAccount()

    return (
        <GenericGuard
            isLoading={acc.loadingAccountInfo}
            isForbidden={
                !acc.selectedAccount || !auth.hasRole(acc.selectedAccount, role)
            }>
            {children}
        </GenericGuard>
    )
}

export function PermissionsGuard({
    children,
    permissions = [],
}: IPermissionsGuardProps) {
    const auth = useAuth()
    const acc = useAccount()

    return (
        <GenericGuard
            isLoading={acc.loadingAccountInfo || auth.isLoginLoading}
            isForbidden={
                !acc.selectedAccount ||
                !auth.hasPermissions(acc.selectedAccount, permissions)
            }>
            {children}
        </GenericGuard>
    )
}

export function AccountPermissionsGuard({
    children,
    permissions = [],
}: IPermissionsGuardProps) {
    const acc = useAccount()

    return (
        <GenericGuard
            isLoading={acc.loadingAccountInfo}
            isForbidden={
                !acc.selectedAccount || !acc.hasPermissions(permissions)
            }>
            {children}
        </GenericGuard>
    )
}

export function RouteFeatureFlagGuard({
    children,
    featureFlags = [],
}: IFeatureFlagGuardProps) {
    const acc = useAccount()
    const { allRouteFlagsEnabled } = useRoutesFeatureFlags()

    return (
        <GenericGuard
            isLoading={acc.loadingAccountInfo}
            isForbidden={
                !acc.selectedAccount || !allRouteFlagsEnabled(featureFlags)
            }>
            {children}
        </GenericGuard>
    )
}

export function AuthGuard({ children = [] }) {
    const auth = useAuth()

    // INFO: useLocation to go to the protected route that was blocked
    // If you try to go to /seasonal but requires auth,
    // you are redirected to login and then to /seasonal
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (auth.isLoginLoading || auth.user) return

        navigate("/login", { state: { from: location } })
    }, [auth.user, auth.isLoginLoading])

    return children
}

export function OnboardingGuard({ children = [] }) {
    const { selectedAccount, accountsObject, loadingAccountInfo } = useAccount()
    const auth = useAuth()
    const { isLoadingAccountAssets, accountAssets } = useAssets()
    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useTranslate()

    const isAdmin = useMemo(() => {
        if (!selectedAccount) return false

        return auth.hasRole(selectedAccount, "Admin")
    }, [selectedAccount])

    const { configuredAssets, totalAssets } = useMemo(() => {
        if (isLoadingAccountAssets) return {}
        if (!accountAssets) return {}

        const accountAssetsArray = Object.keys(accountAssets)
        const totalAssets = accountAssetsArray.length
        // const configuredAssets = 5
        const configuredAssets = accountAssetsArray.reduce(
            (acc, curr) => acc + (accountAssets[curr].is_setup ? 1 : 0),
            0
        )

        return {
            totalAssets,
            configuredAssets,
        }
    }, [accountAssets, isLoadingAccountAssets])

    useEffect(() => {
        if (!accountsObject || !selectedAccount) return
        if (loadingAccountInfo || isLoadingAccountAssets) return

        const account = accountsObject[selectedAccount]
        if (!account) return
        if (!account.is_onboarded) {
            if (isAdmin) {
                if (!location.pathname.includes("onboarding") && totalAssets)
                    navigate("/onboarding")
            } else {
                if (!location.pathname.includes("account-not-configured"))
                    navigate("/account-not-configured")
            }
            return
        }

        // Account is onboarded
        if (location.pathname.includes("account-not-configured")) navigate("/")
    }, [selectedAccount, accountsObject, location, loadingAccountInfo, isLoadingAccountAssets, totalAssets])

    return (
        <>
            {children}
            {isAdmin && configuredAssets !== totalAssets && (
                <>
                    <PortalComponent portalId="tab-global-banner">
                        <Transition
                            show={location.pathname.includes("admin")}
                            as={Fragment}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-200"
                                enterFrom="h-0"
                                enterTo="h-10"
                                leave="ease-in duration-200"
                                leaveFrom="h-10"
                                leaveTo="h-0">
                                <div>
                                    <Banner>
                                        <p>
                                            {t(
                                                "unconfiguredCOUNTAssets",
                                                "Urgent: Weather alerts inactive for {count} default assets! Take action now by configuring your assets",
                                                {
                                                    count:
                                                        (totalAssets ?? 0) -
                                                        (configuredAssets ?? 0),
                                                }
                                            )}
                                        </p>
                                        <p
                                            className="white-link"
                                            onClick={() =>
                                                navigate("/onboarding")
                                            }>
                                            {t("here", "here")}.
                                        </p>
                                    </Banner>
                                </div>
                            </Transition.Child>
                        </Transition>
                    </PortalComponent>

                    <PortalComponent portalId="admin">
                        <span className="h-[6px] w-[6px] rounded-full bg-red absolute top-1 right-1 animate-pulse"></span>
                    </PortalComponent>
                </>
            )}
        </>
    )
}
