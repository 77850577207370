import { Outlet } from "react-router-dom"
import { ResponsivePaddingWrapper } from "../../../layouts/TabLayout"

function PlanningTool() {
    return (
        <ResponsivePaddingWrapper>
            <Outlet />
        </ResponsivePaddingWrapper>
    )
}

export default PlanningTool
