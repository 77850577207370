import { useRef } from "react"

const RadioButton = ({
    selected = false,
    onChange = (val) => console.log(val),
    disabled = false,
}: {
    selected?: boolean
    onChange: (val: boolean) => void
    disabled?: boolean
}) => {
    const inputRef = useRef<HTMLInputElement>(null)

    // TODO: I think this is not the right way of using radio buttons

    return (
        <div
            className="relative w-[20px] h-[20px] border-2 rounded-full border-accent"
            onClick={() => {
                if (inputRef?.current) inputRef.current.click()
            }}>
            <input
                ref={inputRef}
                type="radio"
                checked={selected}
                disabled={disabled}
                onChange={() => {
                    onChange(!selected)
                }}
                className={
                    "appearance-none absolute inset-[3px] rounded-full cursor-pointer " +
                    (selected ? "bg-accent" : "bg-transparent")
                }
            />
        </div>
    )
}

export default RadioButton
