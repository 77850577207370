import {
    createContext,
    useEffect,
    useMemo,
    useState,
    ReactNode,
    useCallback,
    useContext,
} from "react"
import { IPrimitivesDictionary, ILabel, ICoordinates } from "../climateui/types"
import { IInsightsLocation } from "../types"
import { isValidResponse } from "../climateui/utils/http"
import { arrToDict } from "../utils/transform"
import { useAccount } from "./AccountProvider"
import { Row } from "@tanstack/react-table"
import { IMapPin } from "../climateui/components/Map/utils"
import { useAssets } from "./AssetsProvider"
import useLocationsQuery from "../hooks/useLocationsQuery"

export const areSameLocation = (loc1: ICoordinates, loc2: ICoordinates) => {
    // We could improve this to return true if
    // coordinates are 1 km away from each other.
    return loc1.lat === loc2.lat && loc1.lon === loc2.lon
}

export function locToPin(location: IInsightsLocation): IMapPin {
    return {
        lat: location.latitude,
        lon: location.longitude,
        id: location.id,
        pinStyle: "map-pin.png",
        data: location,
    }
}

function gqlLocToInsLoc(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gqlLocation: any
): IInsightsLocation {
    return {
        ...gqlLocation,
        varieties: gqlLocation.varieties?.results ?? [],
    }
}

interface LocationsInfoDigested {
    filteredLocations: IInsightsLocation[]
    filteredChildLocations: IInsightsLocation[]
    filteredPageLocations: IInsightsLocation[]
    filteredPageChildLocations: IInsightsLocation[]
    filteredLocationPins: IMapPin[]
    filteredChildLocationPins: IMapPin[]
    filteredPageLocationPins: IMapPin[]
    filteredPageChildLocationPins: IMapPin[]
}

export interface ILocationsContext {
    filteredLocations: IInsightsLocation[]
    filteredChildLocations: IInsightsLocation[]
    filteredLocationPins: IMapPin[]
    filteredChildLocationPins: IMapPin[]
    filteredPageLocations: IInsightsLocation[]
    filteredPageChildLocations: IInsightsLocation[]
    filteredPageLocationPins: IMapPin[]
    filteredPageChildLocationPins: IMapPin[]
    locations: IInsightsLocation[]
    childLocations?: IInsightsLocation[]
    loadingLocations: boolean
    locationAccounts: IPrimitivesDictionary
    locationRegions: IPrimitivesDictionary
    locationLabels: IPrimitivesDictionary
    locationVarieties: Record<string, string>
    tableFilteredCallback: (filteredRows: Row<IInsightsLocation>[]) => void
    locationsObj: Record<string, IInsightsLocation>
    canAddLocations: boolean
}

export const LocationsContext = createContext({} as ILocationsContext)

const setupLocationsRegion = (locations: IInsightsLocation[]) => {
    // Set the location region to the one with resolution 2
    locations.forEach((location) => {
        if (!location || !location.regions) return
        for (const currRegion of location.regions) {
            if (currRegion.resolution === 2) {
                location["region"] = currRegion
                break // To avoid unneeded loops
            }
        }
    })
}

export const useLocations = () => useContext(LocationsContext)

function LocationsProvider({ children }: { children: ReactNode }) {
    const {
        selectedAccount,
        accountsObject,
        loadingAccountInfo,
        maxLocationsAllowed,
    } = useAccount()
    const { assets } = useAssets()

    const [locationAccounts, setLocationAccounts] =
        useState<IPrimitivesDictionary>({})
    const [locationRegions, setLocationRegions] =
        useState<IPrimitivesDictionary>({})
    const [locationLabels, setLocationLabels] = useState<IPrimitivesDictionary>(
        {}
    )
    const [locationVarieties, setLocationVarieties] = useState<
        Record<string, string>
    >({})

    useEffect(() => {
        if (!selectedAccount) return
        // Reset everything on account change
        setLocationAccounts({})
        setLocationRegions({})
        setLocationLabels({})
        setLocationVarieties({})
    }, [selectedAccount])

    const { data, isLoading, isFetching } = useLocationsQuery(
        selectedAccount,
        accountsObject
    )

    const { locations, childLocations } = useMemo(() => {
        const result: {
            locations: IInsightsLocation[]
            childLocations: IInsightsLocation[]
        } = {
            locations: [],
            childLocations: [],
        }
        if (
            !data ||
            !isValidResponse(data) ||
            data.status !== 200 ||
            isLoading ||
            !assets
        )
            return result

        const results = data?.data?.data?.locations?.results ?? []

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        results.forEach((gqlLocation: any) => {
            const currentLocation = gqlLocToInsLoc(gqlLocation)
            // divide selectedAccount and child locations
            if (currentLocation.account_id === selectedAccount) {
                result.locations.push(currentLocation)
            } else {
                result.childLocations.push(currentLocation)
            }
        })
        setupLocationsRegion(result.locations)
        setupLocationsRegion(result.childLocations)

        return result
    }, [data])

    useEffect(() => {
        // Prepare IFilter information
        const newLocationAccounts = {} as IPrimitivesDictionary
        const newLocationRegions = {} as IPrimitivesDictionary
        const newLocationLabels = {} as IPrimitivesDictionary
        const newLocationAssetVarieties: Record<string, string> = {}

        const setupFiltersInfo = (locations: IInsightsLocation[]) => {
            if (locations.length === 0) return
            locations.forEach((loc) => {
                newLocationAccounts[loc.account_id] =
                    accountsObject[loc.account_id].name
                if (loc.region?.full_name) {
                    newLocationRegions[loc.region?.full_name] =
                        loc.region?.full_name
                }
                loc.labels.forEach((label) => {
                    newLocationLabels[(label as ILabel).id as string] = (
                        label as ILabel
                    ).name
                })
                loc.varieties?.forEach((variety) => {
                    const varietyName = `${variety.asset.name} (${variety.name})`
                    newLocationAssetVarieties[variety.id] = varietyName
                })
            })
        }

        setupFiltersInfo(locations)
        setupFiltersInfo(childLocations)

        // Set filter options
        setLocationAccounts(newLocationAccounts)
        setLocationRegions(newLocationRegions)
        setLocationLabels(newLocationLabels)
        setLocationVarieties(newLocationAssetVarieties)
    }, [locations, childLocations])

    const { locationsObj } = useMemo(() => {
        return { locationsObj: arrToDict(locations, "id") }
    }, [locations])

    const [locationsInfoDigested, setLocationsInfoDigested] =
        useState<LocationsInfoDigested>({
            filteredLocations: [],
            filteredChildLocations: [],
            filteredPageLocations: [],
            filteredPageChildLocations: [],
            filteredLocationPins: [],
            filteredChildLocationPins: [],
            filteredPageLocationPins: [],
            filteredPageChildLocationPins: [],
        })

    const tableFilteredCallback = useCallback(
        (
            filteredRows: Row<IInsightsLocation>[],
            filteredPageRows?: Row<IInsightsLocation>[] // If pagination is active in the Table
        ) => {
            const locationsEverything: LocationsInfoDigested = {
                filteredLocations: [],
                filteredChildLocations: [],
                filteredPageLocations: [],
                filteredPageChildLocations: [],
                filteredLocationPins: [],
                filteredChildLocationPins: [],
                filteredPageLocationPins: [],
                filteredPageChildLocationPins: [],
            }

            filteredRows.forEach((row) => {
                const location = row.original
                let pushToLocations:
                    | "filteredLocations"
                    | "filteredChildLocations" = "filteredLocations"
                let pushToPins:
                    | "filteredLocationPins"
                    | "filteredChildLocationPins" = "filteredLocationPins"
                if (location.account_id !== selectedAccount) {
                    pushToLocations = "filteredChildLocations"
                    pushToPins = "filteredChildLocationPins"
                }
                locationsEverything[pushToLocations]?.push(location)
                locationsEverything[pushToPins]?.push(locToPin(location))
            })

            if (filteredPageRows) {
                filteredPageRows.forEach((row) => {
                    const location = row.original
                    let pushToLocations:
                        | "filteredPageLocations"
                        | "filteredPageChildLocations" = "filteredPageLocations"
                    let pushToPins:
                        | "filteredPageLocationPins"
                        | "filteredPageChildLocationPins" =
                        "filteredPageLocationPins"
                    if (location.account_id !== selectedAccount) {
                        pushToLocations = "filteredPageChildLocations"
                        pushToPins = "filteredPageChildLocationPins"
                    }
                    locationsEverything[pushToLocations]?.push(location)
                    locationsEverything[pushToPins]?.push(locToPin(location))
                })
            }

            setLocationsInfoDigested(locationsEverything)
        },
        [selectedAccount]
    )

    const providerValue = useMemo(() => {
        const loadingLocations = isLoading || isFetching
        return {
            filteredPageLocations: locationsInfoDigested.filteredPageLocations,
            filteredPageLocationPins:
                locationsInfoDigested.filteredPageLocationPins,
            filteredPageChildLocations:
                locationsInfoDigested.filteredPageChildLocations,
            filteredPageChildLocationPins:
                locationsInfoDigested.filteredPageChildLocationPins,
            filteredLocations: locationsInfoDigested.filteredLocations,
            filteredLocationPins: locationsInfoDigested.filteredLocationPins,
            filteredChildLocations:
                locationsInfoDigested.filteredChildLocations,
            filteredChildLocationPins:
                locationsInfoDigested.filteredChildLocationPins,
            locations,
            childLocations,
            loadingLocations,
            locationAccounts,
            locationRegions,
            locationLabels,
            locationVarieties,
            tableFilteredCallback,
            locationsObj,
            canAddLocations:
                loadingAccountInfo ||
                loadingLocations ||
                locations.length < maxLocationsAllowed,
        }
    }, [
        locationsInfoDigested,
        locations,
        childLocations,
        locationAccounts,
        locationRegions,
        locationLabels,
        locationVarieties,
        isLoading,
        isFetching,
        loadingAccountInfo,
    ])

    return (
        <LocationsContext.Provider value={providerValue}>
            {children}
        </LocationsContext.Provider>
    )
}

export default LocationsProvider
