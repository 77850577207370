// eslint-disable-next-line @typescript-eslint/no-var-requires
let mapboxgl: any 

if (process.env.NODE_ENV === "test") {
    // Jest runner cannot compile webpack loaders
    mapboxgl = require("mapbox-gl")
} else {
    // Exclude from transpilation
    mapboxgl = require("!mapbox-gl")
}

// TODO make this an env variable in the future
mapboxgl.accessToken =
    "pk.eyJ1Ijoia2F0YW1hcmFuaTQiLCJhIjoiY2tpem9mNHdwMDMyajMwbWhrcDQyNXkzaCJ9.03jKhJk_Yldt76ypBNYVag"

export default mapboxgl
