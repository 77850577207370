import { useState, LegacyRef, ReactNode } from "react"
import { useOutsideComponentClickHandler } from "../climateui/hooks"
import { ArrowBottom } from "../climateui/icons"
import { OptionsDropdown } from "../climateui/components"

const SelectWithImage = ({
    selected,
    options = {},
    setSelected = () => null,
    valueKey = "",
    textAlignClass = "text-left",
    loading = false,
    placeholder = "",
    leftRightClass = "right-0",
    disabled = false,
    image = null,
}: {
    selected?: string | number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: any
    setSelected: (key?: string) => null | void
    valueKey?: string
    textAlignClass?: string
    loading?: boolean
    placeholder?: string
    leftRightClass?: string
    disabled?: boolean
    image?: ReactNode | null
}) => {
    const [open, toggle] = useState(false)
    const dropdownRef = useOutsideComponentClickHandler(() => toggle(false))

    const getDisplayValue = (key?: string | number) => {
        if (loading) return "Loading..."
        if (!key || options[key] === undefined)
            return placeholder || "Please select a value"
        if (valueKey !== "") return options[key][valueKey].replaceAll("_", " ")
        return options[key]
    }

    return (
        <div
            ref={dropdownRef as LegacyRef<HTMLDivElement> | undefined}
            className={"relative min-w-0 select-none w-fit text-gray-90"}>
            <button
                disabled={disabled}
                className={[
                    "flex flex-row items-center gap-1",
                    "transition-all duration-75",
                    "h-[42px] min-w-[80px] w-min",
                    "rounded-md",
                    "fill-gray-3",
                    "pl-2 pr-[6px]",
                    "hover:bg-white/[6%] cursor-pointer",
                    "focus:outline-accent",
                    open ? "bg-white/[6%]" : "",
                    textAlignClass,
                ].join(" ")}
                onClick={() => toggle(!open)}>
                <div className="flex flex-row items-center gap-2 grow">
                    {image}
                    <div className="truncate pointer-events-none grow whitespace-nowrap label-lg text-gray-3">
                        {getDisplayValue(selected)}
                    </div>
                </div>
                <span className={"shrink-0 grow-0 w-[22px]"}>
                    <ArrowBottom />
                </span>
            </button>
            <OptionsDropdown
                open={open}
                toggle={toggle}
                selected={selected}
                options={options}
                setSelected={setSelected}
                textAlignClass={textAlignClass}
                leftRightClass={leftRightClass}
            />
        </div>
    )
}

export default SelectWithImage
