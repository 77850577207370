/* eslint-disable @typescript-eslint/no-explicit-any */

const OptionsDropdown = ({
    open,
    toggle = () => null,
    selected,
    isSelected = (selected, key) => selected == key,
    options = {},
    setSelected = () => null,
    textAlignClass = "text-left",
    leftRightClass = "right-0",
}: {
    open: boolean
    toggle: (state: boolean) => null | void
    selected?: string | number
    isSelected?: (
        key: string | number,
        selected?: string | number
    ) => boolean | null
    options: any
    setSelected: (key?: string) => null | void
    textAlignClass?: string
    leftRightClass?: string
}) => {
    const getDisplayValue = (key: string | number) => {
        return options[key]
    }

    if (!open || !options) return null
    return (
        <div
            className={[
                "absolute top-full",
                leftRightClass,
                "bg-white z-30 rounded-lg elevation-2",
                "h-fit max-h-[400px] overflow-y-auto",
                "border-[1px] border-gray-10",
            ].join(" ")}>
            {Object.keys(options).map((key, index) => (
                <button
                    key={key + "-" + index}
                    className={[
                        "flex flex-row items-center",
                        "h-[36px] px-3 min-w-[40px] w-full",
                        "hover:bg-gray-5 cursor-pointer",
                        isSelected(key, selected) ? "bg-gray-5" : "", // == instead of === to compare integers with strings
                        textAlignClass,
                    ].join(" ")}
                    onClick={() => {
                        toggle(false)
                        setSelected(key)
                    }}>
                    <p className="w-full truncate pointer-events-none whitespace-nowrap body-lg">
                        {getDisplayValue(key)}
                    </p>
                </button>
            ))}
        </div>
    )
}

export default OptionsDropdown
