import EmptyModal from "../climateui/providers/Modal/EmptyModal"
import { CancelIcon, PlusIcon } from "../climateui/icons"
import { useTranslate } from "@tolgee/react"
import { Button, Checkbox } from "../climateui/components"
import { useEffect, useState } from "react"
import LabelsForm from "../climateui/components/Labels/LabelsForm"
import { ILabel } from "../climateui/types"
import LabelItem from "../climateui/components/Labels/LabelItem"
import { useOutsideComponentClickHandler } from "../climateui/hooks"
import { UseMutateAsyncFunction } from "react-query"
import { CustomResponse, isValidResponse } from "../climateui/utils/http"
import { AxiosError } from "axios"

const BulkLabelsForm = ({
    open,
    onCancel,
    onSave,
    allLabels,
    editLabel,
    addNewLabel,
    deleteLabel,
}: {
    open: boolean
    onCancel: () => void
    onSave: (labels: ILabel[], replace?: boolean) => void

    allLabels: ILabel[]
    editLabel: (label: ILabel) => void
    // addNewLabel: (label: ILabel) => Promise<ILabel | null>
    addNewLabel: UseMutateAsyncFunction<
        CustomResponse | AxiosError<unknown> | null,
        unknown,
        ILabel,
        unknown
    >
    deleteLabel: (labelId: string) => void
}) => {
    /* HOOKS > START */
    const { t } = useTranslate()
    /* HOOKS < END */

    /* STATE > START */
    const [replace, setReplace] = useState(false)
    const [openLabels, setOpenLabels] = useState(false)
    const [bulkLabels, _setBulkLabels] = useState<ILabel[]>([])
    const setBulkLabels = (labels: ILabel[]) => {
        _setBulkLabels(labels)
        setOpenLabels(false)
    }
    const modalRef = useOutsideComponentClickHandler(onCancel)
    const labelsRef = useOutsideComponentClickHandler(() =>
        setOpenLabels(false)
    )
    /* STATE < END */

    const removeLabel = (deletedLabel: ILabel) => {
        _setBulkLabels(
            bulkLabels.filter((label) => label.id !== deletedLabel.id)
        )
    }

    /* UTILS > START */
    useEffect(() => {
        setBulkLabels([])
        setReplace(false)
    }, [open])
    /* UTILS < END */
    return (
        <EmptyModal
            open={open}
            customClasses="w-[420px] relative rounded-lg bg-white">
            <div
                ref={modalRef}
                className="flex flex-col h-[215px]">
                {/* TITLE BAR */}
                <div className="flex flex-row justify-between flex-none p-4 border-b border-gray-14">
                    <h1 className="title-sm">
                        {t("bulkAddLabel", "Bulk Add Label")}
                    </h1>
                    <div
                        onClick={onCancel}
                        className="w-6 h-6 cursor-pointer fill-gray-60"
                        role="button">
                        <CancelIcon />
                    </div>
                </div>
                <div className="flex flex-row items-center p-4 pt-0 grow">
                    <div className="grow">
                        <div className="label-sm text-gray-60">
                            {t("labels", "Labels")}
                        </div>
                        <div className="flex flex-wrap items-center p-2 border rounded-lg gap-1 border-gray-14">
                            {bulkLabels.map((label) => (
                                <div key={label.name}>
                                    <LabelItem
                                        label={label}
                                        onDelete={(label) => removeLabel(label)}
                                    />
                                </div>
                            ))}
                            <div
                                onClick={() => setOpenLabels(true)}
                                className="w-6 h-6 cursor-pointer fill-gray-30">
                                <PlusIcon />
                            </div>
                            {openLabels && (
                                <div
                                    className="absolute z-full"
                                    ref={labelsRef}>
                                    <LabelsForm
                                        specialCharsLabel={t(
                                            "someSpecialCharsAreNotAllowed"
                                        )}
                                        pressEnterToCreateOneLabel={t(
                                            "pressEnterToCreateOne"
                                        )}
                                        notFoundLabel={t("noLabelsFound")}
                                        labels={allLabels}
                                        colors={[
                                            ["#F06000", "#0098B2", "#FDB600"],
                                            ["#A9BF2C", "#AC22CF", "#196CE1"],
                                            ["#23AF41", "#B91863", "#039CE2"],
                                        ]}
                                        onSelectExistingLabel={(
                                            label: ILabel
                                        ) =>
                                            setBulkLabels([
                                                label,
                                                ...bulkLabels,
                                            ])
                                        }
                                        onSelectNewLabel={(
                                            label: ILabel
                                        ) => {
                                            addNewLabel(label).then(
                                                (response) => {
                                                    if (
                                                        !isValidResponse(
                                                            response
                                                        )
                                                    )
                                                        return
                                                    setBulkLabels([
                                                        response.data[0],
                                                        ...bulkLabels,
                                                    ])
                                                }
                                            )
                                        }}
                                        onEditLabel={(label) =>
                                            editLabel(label)
                                        }
                                        onDeleteLabel={(label) => {
                                            if (label.id) deleteLabel(label.id)
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* BOTTOM BAR */}
                <div className="flex flex-row items-center justify-between flex-none px-4 py-2 border-t border-gray-14">
                    <div className="flex flex-row items-center cursor-pointer gap-1 text-gray-60">
                        <div className="w-5 h-5">
                            <Checkbox
                                status={replace ? "full" : "empty"}
                                onChange={(val) => setReplace(val === "full")}
                            />
                        </div>
                        <span
                            className="body-sm"
                            onClick={() => setReplace(!replace)}>
                            {t(
                                "replaceExistingLabels",
                                "Replace already assigned labels"
                            )}
                        </span>
                    </div>
                    <Button
                        onClick={() => onSave(bulkLabels, replace)}
                        label={t("add", "Add")}
                    />
                </div>
            </div>
        </EmptyModal>
    )
}

export default BulkLabelsForm
