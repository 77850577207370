import { ReactNode } from "react"
import { PortalComponent } from "../climateui/components"

function FullScreen({ children }: { children: ReactNode }) {
    /*
        INFO: Portal below was introduced to fix the sidebar
        showing over full-screen StepsNavigationLayout
    */

    return (
        <PortalComponent portalId="global-full-screen-element">
            <div className="fixed inset-0 z-full bg-white">{children}</div>
        </PortalComponent>
    )
}

export default FullScreen
