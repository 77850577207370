import http from "../../climateui/utils/http"
import { QueryClient } from "react-query"

export * from "./account"
export * from "./dashboard"
export * from "./label"
export * from "./location"
export * from "./plan"
export * from "./risk"
export * from "./user"
export * from "./alert"
export * from "./asset"
export * from "./analogs"
export * from "./yield"

export const triggeredAlertsPOST = (filters) =>
    http.post("/alert/alert/search/joined/", filters)

export const whiteLabelsGET = async () => http.get("/platform/", false)

export const createGraphQLProxy = (_target) => {
    // If it is falsy, i.e. 0, "", false, undefined or not an object, just return the property
    if (!_target || typeof _target !== "object") {
        return _target
    }
    return new Proxy(_target, {
        get(target, property) {
            // If it is an object with a results wrapper (with no info), flatten the result property
            if (
                target[property] &&
                typeof target[property] === "object" &&
                target[property].results &&
                !target[property].info
            ) {
                return target[property].results.map(createGraphQLProxy)
            }
            // If it is an regular array, try to convert the members into GraphQLProxies
            if (Array.isArray(target[property])) {
                return target[property].map(createGraphQLProxy)
            }
            // If it is a regular object, just wrap it in the GraphQLProxy
            return createGraphQLProxy(target[property])
        },
    })
}
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})
export default queryClient
