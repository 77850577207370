import { DateTime } from "luxon"
import { DAYS_IN_WEEK } from "."
import { ICalendarCell } from "./CalendarCell"

export const isWithinWeekdayHoveredRange = ({
    hoveredDate,
    currDate,
    range,
    rangeWeekdayStart,
}: {
    hoveredDate?: DateTime
    currDate?: DateTime
    range?: number | boolean
    rangeWeekdayStart?: number
}) => {
    if (
        !currDate ||
        !rangeWeekdayStart ||
        !hoveredDate ||
        typeof range !== "number"
    )
        return false
    let startDate = hoveredDate
    const diff = hoveredDate.weekday - rangeWeekdayStart
    if (diff >= 0) {
        startDate = hoveredDate.minus({ days: diff })
    } else if (diff < 0) {
        startDate = hoveredDate.minus({
            days: DAYS_IN_WEEK + diff,
        })
    }
    const endDate = startDate.plus({ days: range })
    return startDate <= currDate && currDate < endDate
}
