import { useTranslate } from "@tolgee/react"
import { useMemo } from "react"
import TableWidget, { ITableWidgetProps } from "./TableWidget"
import dashboardVariables from "../../../../views/Seasonal/Dashboards/dashboardVariables"
import {
    getDirection,
    getLargestAttribute,
    monthsArray,
} from "../SeasonalWidgets/genericChartWidgetUtils"

interface FooterItem {
    label: string
    image: string
    className?: string
}
const DirectionalTableFooter = ({
    footerItems,
}: {
    footerItems: FooterItem[]
}) => (
    <div className="flex justify-center font-roboto text-[12px] my-4 gap-4">
        {footerItems.map(({ label, image, className }) => (
            <div
                key={label}
                className="flex items-center gap-2">
                <img
                    alt={label}
                    src={image}
                    className={className}
                />
                <h1>{label}</h1>
            </div>
        ))}
    </div>
)

function DirectionalTableWidget(
    props: ITableWidgetProps & {
        footerItems?: FooterItem[]
    }
) {
    const {
        data,
        columns,
        title,
        loading,
        empty,
        error,
        reload,
        isPaginated = true,
        errorMessage,
        reloadMessage,
        noResultsMessage,
        tryAnotherFilterMessage,
        footerItems,
    } = props
    const { t } = useTranslate()

    const firstPoints = data?.[0]?.weather?.data?.[0]?.points || []

    // add offset so the date falls in the 1st of the
    // month at 00:00 hours
    const offset = new Date().getTimezoneOffset() / 60
    const firstDate = new Date(firstPoints?.[0]?.date)
    firstDate.setHours(firstDate.getHours() + offset)

    const startMonth = firstDate.getMonth()
    const endMonth = startMonth + firstPoints.length

    let availableMonths = monthsArray.slice(startMonth, endMonth)

    if (endMonth >= monthsArray.length && endMonth % monthsArray.length)
        availableMonths = availableMonths.concat(
            monthsArray.slice(0, endMonth % monthsArray.length)
        )

    const _data: any[] = []
    const variables: any = dashboardVariables

    data?.map((result) => {
        let weatherData: any = {}

        if (!result?.weather?.data) return

        for (let res of result.weather.data) {
            weatherData["variable"] = res.variable
            weatherData["readableName"] = variables[res.variable].title
            weatherData["points"] = availableMonths.map((month, i) => {
                const attributes: { [key: string]: number } = {
                    ...res.points?.[i]?.["attributes"],
                }
                delete attributes["ensemble_mean"]
                const largestAttr = getLargestAttribute(attributes)
                const direction = getDirection(largestAttr)

                return { [month]: direction }
            })
            _data.push({
                ...result,
                weather: { ...weatherData },
            })
        }
    })

    const _columns = useMemo(() => {
        // NOTE: This is only a hotfix change. @Sici has a more robust solution
        const DirectionalColumns = columns ?? []
        const varColumn: any[] = [
            {
                propName: "variable",
                header: t("variable"),
                type: "text",
                selector: {
                    icon: `{{ weather.variable }}`,
                    text: `{{ weather.readableName }}`,
                },
            },
        ]
        const monthsColumns: any[] = availableMonths.map((month, i) => {
            return {
                propName: month,
                header: t(month),
                headerClass: "text-center",
                type: "icon",
                defaultValue: "-",
                selector: {
                    value: `{{weather.points[${i}].${month}}}`,
                },
            }
        })
        return DirectionalColumns.concat(varColumn, monthsColumns)
    }, [data])

    return (
        <>
            <TableWidget
                data={_data}
                columns={_columns}
                loading={loading}
                title={title}
                error={error}
                empty={empty}
                reload={reload}
                footer={
                    footerItems && (
                        <DirectionalTableFooter footerItems={footerItems} />
                    )
                }
                isPaginated={isPaginated}
                errorMessage={errorMessage}
                reloadMessage={reloadMessage}
                noResultsMessage={noResultsMessage}
                tryAnotherFilterMessage={tryAnotherFilterMessage}
            />
        </>
    )
}

export default DirectionalTableWidget
