import { useRef, useState, ReactNode } from "react"
import useOutsideComponentClickHandler from "../../hooks/useOutsideComponentClickHandler"
import { ArrowBottom } from "../../icons"
import Slider from "../Slider"
import { alwaysBorderRedClasses, defaultInputClasses } from "./utils"

export const genericFormatDecades = (
    options: (string | number)[],
    indexes: number[]
) => options[indexes[0]] + " - " + options[indexes[1]]

const SliderSelectInput = ({
    icon = null,
    indexes,
    options = [],
    textAlignClass = "text-left",
    placeholder = "Select an option",
    leftRightClass = "right-0",
    disabled = false,
    error = "",
    dropUp = false,
    formatValue = genericFormatDecades,
    changeCallback,
}: {
    icon?: ReactNode
    indexes: number[]
    options?: string[] | number[]
    textAlignClass?: string
    placeholder?: string
    leftRightClass?: string
    disabled?: boolean
    error?: string
    dropUp?: boolean
    formatValue?: (options: (string | number)[], indexes: number[]) => string
    changeCallback: (indexes: number[]) => void
}) => {
    const [open, toggle] = useState(false)

    const bottomIndex = indexes[0] || 0
    const topIndex = indexes[1] || options.length

    const setRange = (bottom: number, top: number) => {
        changeCallback([bottom, top])
    }

    const dropdownRef = useOutsideComponentClickHandler(() => toggle(false))
    const textRef = useRef<HTMLDivElement | null>(null)

    const optHeight = 32
    const inputHeight = 42 // Based on `defaultInputClasses` in utils
    const inputBorder = 1 // Based on `defaultInputClasses` in utils

    let errorOpenClasses = "border-gray-14 hover:enabled:border-gray-30"
    if (error !== "") errorOpenClasses = alwaysBorderRedClasses
    else if (open) errorOpenClasses = "border-accent z-[1]"

    const selected =
        options[bottomIndex] !== undefined && options[topIndex] !== undefined
    const value = selected ? formatValue(options, indexes) : ""
    const hasOptions = options?.length > 0

    return (
        <div
            ref={dropdownRef}
            className="relative flex flex-col w-full min-w-0 gap-1">
            <button
                disabled={disabled}
                className={[
                    "flex flex-row items-center",
                    ...defaultInputClasses,
                    textAlignClass,
                    errorOpenClasses,
                ].join(" ")}
                onClick={() => toggle(!open)}>
                {icon !== null && (
                    <span className="shrink-0 grow-0 w-[20px] mr-1 -ml-1 fill-gray-60">
                        {icon}
                    </span>
                )}
                <div
                    className={
                        "truncate pointer-events-none grow whitespace-nowrap" +
                        (selected ? "" : "text-gray-30")
                    }
                    ref={textRef}>
                    {value !== "" ? value : placeholder}
                </div>
                <span className="shrink-0 grow-0 w-[20px]">
                    <ArrowBottom />
                </span>
            </button>
            {error !== "" && (
                <div className="font-normal text-red body-sm">{error}</div>
            )}
            {open && (
                <div
                    className={[
                        "absolute",
                        error !== "" ? `top-[42px]` : "top-full",
                        "font-normal body-lg text-gray-90",
                        leftRightClass,
                        "bg-white z-30 rounded-lg elevation-2",
                        "w-80 h-20 px-8 pt-4",
                        "border-[1px] border-gray-14",
                    ].join(" ")}
                    style={
                        dropUp
                            ? {
                                  top: `-${
                                      Object.keys(options).length * optHeight +
                                      Math.floor(inputHeight / 2) -
                                      inputBorder * 2
                                  }px`,
                              }
                            : {}
                    }>
                    {hasOptions ? (
                        <Slider
                            id="decades-slider"
                            selectedTopIndex={topIndex}
                            selectedBottomIndex={bottomIndex}
                            setSelectedIndexes={(
                                indexes: number | number[]
                            ) => {
                                if (typeof indexes === "number") return
                                setRange(indexes[0], indexes[1])
                            }}
                            options={options}
                            fitToWidth={false}
                            isDouble
                        />
                    ) : (
                        <button
                            disabled
                            className={[
                                "flex flex-row items-center",
                                "h-[32px] px-2 my-1 min-w-[40px] w-full",
                                "cursor-not-allowed",
                                textAlignClass,
                            ].join(" ")}>
                            <p className="w-full truncate pointer-events-none whitespace-nowrap body-md text-gray-30">
                                No available options
                            </p>
                        </button>
                    )}
                </div>
            )}
        </div>
    )
}

export default SliderSelectInput
