import { useTranslate } from "@tolgee/react"
import { MouseEvent, useContext, useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { DraggableElement } from "../../../climateui/components"
import { ThreeDotsIcon } from "../../../climateui/icons"
import { ModalContext } from "../../../climateui/providers"
import { colors } from "../../../climateui/utils/colors"
import { isCustomResponse } from "../../../climateui/utils/http"
import { useAccount } from "../../../providers/AccountProvider"
import { useAssets } from "../../../providers/AssetsProvider"
import { LocationsContext } from "../../../providers/LocationsProvider"
import { IStage, IStrategy, IStageLeft } from "../../../types"
import { gddsGET } from "../../../utils/networking"
import { daysBetween } from "../../../utils"
import GDD from "./GDD"
import Risks from "./Risks"
import Stage, { NewStage } from "./Stage"
import {
    EDITION_ACTIONS,
    moveItemDates,
    NEW_STAGE_DURATION,
    updateStageRiskProfiles,
} from "./utils"
import WeeklyTimeline from "./WeeklyTimeline"
import { useSeasonalCalendar } from "../../../providers/SeasonalCalendarProvider"
import { extractVarietyMetadata } from "../../../views/Seasonal/PlanningTool/utils"
import { HOUR_MS } from "../../../utils/constants"
import { useStrategy } from "./Strategy"
import { TODAY } from "../../../utils/dates"

function MoveAllHandle({
    strategy,
    updateStrategy,
    setGlobalPlanLeft,
}: {
    strategy: IStrategy
    updateStrategy: (strategy: IStrategy) => void
    setGlobalPlanLeft: (left: number) => void
}) {
    const {
        timelineStartDate,
        pxPerDay,
        setDragAuxDate,
        dragAuxDate,
        focusedStage,
        setFocusedStage,
        setFocusedPlannedRisk,
    } = useSeasonalCalendar()
    const strategyStages = strategy.stages ?? []
    const strategyRisks = strategy.planned_risks ?? []

    const [dragging, setDragging] = useState<boolean>(false)
    const [minDate, setMinDate] = useState<Date>()

    useEffect(() => {
        if (!strategyStages || strategyStages.length === 0) {
            setMinDate(undefined)
            return
        }
        const auxMinDate =
            strategyStages.reduce((prev_stage: IStage, curr_stage: IStage) => {
                return (prev_stage.start_date ?? TODAY) <
                    (curr_stage.start_date ?? TODAY)
                    ? prev_stage
                    : curr_stage
            }).start_date ?? TODAY
        setMinDate(auxMinDate)
    }, [strategyStages])

    const auxMinDate =
        minDate && timelineStartDate < minDate ? minDate : timelineStartDate

    const menuStyle = {
        left:
            pxPerDay *
            daysBetween(
                timelineStartDate,
                dragAuxDate && dragging
                    ? dragAuxDate
                    : auxMinDate || TODAY
            ) +
            "px",
    }

    const moveAll = (daysMoved: number) => {
        if (!minDate) return

        const auxDate = new Date(minDate)
        auxDate.setDate(auxDate.getDate() + daysMoved)
        // Line below causes strategies with stages in the past
        // to move to today when dragged.
        // if (daysBetween(timelineStartDate, auxDate) < 0) return

        const newStages = [...strategyStages]
        const newRisks = [...strategyRisks]
        newStages.forEach((stage) => {
            moveItemDates(stage, daysMoved)
        })
        newRisks.forEach((risk) => {
            moveItemDates(risk, daysMoved)
        })
        updateStrategy({
            stages: newStages,
            planned_risks: newRisks,
        })
    }

    return (
        <div
            className={[
                "absolute inset-y-0 z-45",
                "flex flex-row items-center",
                !minDate ? "hidden" : "",
            ].join(" ")}
            style={menuStyle}>
            <DraggableElement
                draggable
                draggingCallback={(pxMoved: number) => {
                    if (focusedStage !== strategy.id) {
                        setFocusedStage(strategy.id as string)
                        setFocusedPlannedRisk(strategy.id as string)
                    }
                    if (!dragging) setDragging(true)

                    const daysMoved = Math.round(pxMoved / pxPerDay)
                    const strategyDate = new Date(auxMinDate || new Date())
                    strategyDate.setDate(strategyDate.getDate() + daysMoved)
                    if (daysBetween(timelineStartDate, strategyDate) < 0) return

                    setDragAuxDate(strategyDate)
                    setGlobalPlanLeft(pxPerDay * daysMoved)
                }}
                dragEndCallback={() => {
                    if (!auxMinDate || !dragAuxDate) return
                    const daysMoved = daysBetween(auxMinDate, dragAuxDate)
                    moveAll(daysMoved)
                    setDragging(false)
                    setGlobalPlanLeft(0)
                    setFocusedStage(undefined)
                    setFocusedPlannedRisk(undefined)
                    setDragAuxDate(undefined)
                }}>
                <div className="flex flex-row items-center -ml-8 fill-gray-60">
                    <span className="w-5 h-5">
                        <ThreeDotsIcon />
                    </span>
                    <span className="w-5 h-5 -ml-[14px]">
                        <ThreeDotsIcon />
                    </span>
                </div>
            </DraggableElement>
        </div>
    )
}

function Stages({
    strategy,
    updateStrategy,
    strategyOrder,
    open,
}: {
    strategy: IStrategy
    updateStrategy: (strategy: IStrategy) => void
    strategyOrder: number
    open: boolean
}) {
    const { t } = useTranslate()
    const {
        timelineStartDate,
        pxPerDay,
        setDragAuxDate,
        setEditingStage,
        setFocusedStage,
        isEditingCalendar,
        deleteStageOrRisk,
    } = useSeasonalCalendar()
    const { stageHeight, totalLevels, stagesLevels, gddsDict, gddForecastEndDate, isGDDLoading } = useStrategy()
    const { confirmationModal } = useContext(ModalContext)
    const { locationsObj } = useContext(LocationsContext)
    const { varieties } = useAssets()
    const { hasPermissions } = useAccount()

    const stages = strategy.stages ?? []
    const stagesStartDate =
        stages.length > 0
            ? stages.reduce((prev: IStage, curr: IStage) => {
                return (prev.start_date ?? TODAY) <
                    (curr.start_date ?? TODAY)
                    ? prev
                    : curr
            }).start_date ?? TODAY
            : TODAY

    const [newStageStartDate, setNewStageStartDate] = useState<Date>()

    const newStageStartDateOffset = Math.round(NEW_STAGE_DURATION / 2)
    const handleHovering = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (!isEditingCalendar) return
        if (e.pageX === 0) return
        const boundingBox = e.currentTarget.getBoundingClientRect()
        const pxMoved = e.pageX - boundingBox.x
        const daysMoved = Math.round(pxMoved / pxPerDay)
        const newStartDate = new Date(timelineStartDate)
        newStartDate.setDate(
            newStartDate.getDate() + daysMoved - newStageStartDateOffset
        )
        setNewStageStartDate(newStartDate)
        setDragAuxDate(newStartDate)
    }

    const handleHoverEnd = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (!isEditingCalendar) return
        setNewStageStartDate(undefined)
        setDragAuxDate(undefined)
    }

    const newStage = () => {
        if (!newStageStartDate || !isEditingCalendar) return
        const endDate = new Date(newStageStartDate)
        endDate.setDate(endDate.getDate() + NEW_STAGE_DURATION)
        const newId = strategy.id + new Date().toISOString() // INFO: unique temp-FE id
        setFocusedStage(newId)
        setEditingStage({
            name: "",
            start_date: newStageStartDate,
            end_date: endDate,
            duration: NEW_STAGE_DURATION,
            strategyId: strategy.id,
            color: colors.gray[60], // TODO: Maybe random color
            id: newId,
            index: -1,
            action: EDITION_ACTIONS.added,
        })
    }

    const deleteStage = (index: number) => {
        if (!deleteStageOrRisk) return

        confirmationModal({
            title: t("areYouSureDeleteStage"),
            text: t("thisActionCannotBeUndone"),
            onContinueLabel: t("continue"),
            onCancelLabel: t("cancel"),
            onContinue: () => {
                const newStages = [...(stages ?? [])]
                const deletedThing = newStages[index]
                newStages.splice(index, 1)
                updateStrategy({
                    stages: newStages,
                })
                setEditingStage(undefined)
                deleteStageOrRisk(deletedThing, "stages")
            },
        })
    }

    const updateStage = (newStage: IStage, index: number) => {
        const newStages = [...(stages ?? [])]
        const newEndDate = new Date(newStage.start_date ?? "")
        newEndDate.setDate(newEndDate.getDate() + (newStage.duration ?? 0))
        newStage.end_date = newEndDate

        const prevStage = { ...newStages[index] }
        newStages[index] = {
            ...newStage,
            end_date: newEndDate,
            action: newStage.action ?? EDITION_ACTIONS.edited,
        }

        const newPlannedRisks = [...(strategy.planned_risks ?? [])]
        newPlannedRisks.forEach((plannedRisk) => {
            updateStageRiskProfiles(prevStage, newStage, plannedRisk)
        })

        updateStrategy({
            stages: newStages,
            planned_risks: newPlannedRisks,
        })
    }

    const [globalCalendarLeft, setGlobalPlanLeft] = useState<number>(0)
    const [stageLeft, setStageLeft] = useState<IStageLeft>()

    return (
        <div style={{ order: strategyOrder }}>
            {/* STAGES SECTION */}
            <div
                className="relative h-9"
                style={{
                    height: (totalLevels || 1) * stageHeight + "px",
                }}>
                {/* TIMELINE */}
                <WeeklyTimeline />
                {/* Add Stage hover section */}
                <div
                    className={
                        "absolute inset-0 z-20 " +
                        (isEditingCalendar ? "cursor-pointer" : "")
                    }
                    onMouseMove={handleHovering}
                    onMouseLeave={handleHoverEnd}
                    onClick={newStage}>
                    {/* Add Stage component */}
                    {newStageStartDate && isEditingCalendar && (
                        <NewStage
                            date={newStageStartDate}
                            duration={NEW_STAGE_DURATION}
                        />
                    )}
                </div>
                {/* MoveAllHandle */}
                {isEditingCalendar && (
                    <MoveAllHandle
                        strategy={strategy}
                        updateStrategy={updateStrategy}
                        setGlobalPlanLeft={setGlobalPlanLeft}
                    />
                )}
                {/* STAGES */}
                {stages.map((stage: IStage, index: number) => {
                    const _deleteStage = () => deleteStage(index)

                    return (
                        <Stage
                            key={"stage-item-" + stage.id}
                            stage={{
                                ...stage,
                                deleteStage: _deleteStage,
                            }}
                            globalCalendarLeft={globalCalendarLeft}
                            deleteStage={_deleteStage}
                            updateStage={(newStage: IStage) =>
                                updateStage(newStage, index)
                            }
                            setStageLeft={setStageLeft}
                            extraComponent={
                                hasPermissions(["gdd_on_planning_tool"]) && (
                                    <div
                                        className="absolute h-8 top-full left-full"
                                        style={{
                                            top:
                                                (totalLevels -
                                                    stagesLevels[
                                                    stage.id ?? ""
                                                    ]) *
                                                stageHeight +
                                                "px",
                                        }}>
                                        <GDD
                                            gddsDict={gddsDict}
                                            stage={stage}
                                            gddForecastEndDate={gddForecastEndDate}
                                            stagesStartDate={stagesStartDate}
                                            isLoading={isGDDLoading}
                                        />
                                    </div>
                                )
                            }
                        />
                    )
                })}
            </div>
            {/* GDD or strategy.description EMPTY WeeklyTimeline SECTION */}
            {strategy.stages && (
                <div className="relative h-9">
                    <WeeklyTimeline />
                </div>
            )}
            {/* RISKS SECTION */}
            <Risks
                strategy={strategy}
                updateStrategy={updateStrategy}
                globalCalendarLeft={globalCalendarLeft}
                open={open}
                stageLeft={stageLeft}
            />
        </div>
    )
}

export default Stages
