const ClimateTrendsIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            className="fill-inherit"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 17H7V10H9V17ZM13 17H11V7H13V17ZM17 17H15V13H17V17ZM19 19H5V5H19V19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
                fill={props.fill}
            />
        </svg>
    )
}
export default ClimateTrendsIcon
