// TODO: Move to yield outlook folder
import { useMemo } from "react"
import ShadedRegionsMap from "../../climateui/components/Map/ShadedRegionsMap"
import { IPolygonGroup } from "../../climateui/components/Map/ShadedRegionsMap/types"
import {
    getRiskCategoryCoding,
    RISK_BOUNDS,
} from "../../views/Seasonal/RiskOutlook/riskOutlookUtils"
import { IRiskCountryOverviewModel } from "../../views/Seasonal/RiskOutlook/types"

const riskCategoryCoding: Record<string, string> = {
    highRisk: "#E42437",
    mediumRisk: "#FDB600",
    lowRisk: "#23AF41",
}

const ShadedRegionMapWidget = (props: {
    __data: { yield_outlook_model: { results: IRiskCountryOverviewModel[] } }
    loading: boolean
    yProperty: string
}) => {
    const { __data, loading, yProperty } = props

    const { categorizedRegions, categorizedPins } = useMemo(() => {
        if (!__data?.yield_outlook_model)
            return { categorizedRegions: {}, categorizedPins: [] }

        let pins: { lng: number; lat: number; color: string }[] = []
        const data = __data.yield_outlook_model
            .results[0] as IRiskCountryOverviewModel

        let max_value = -Infinity
        let hazard_key = ""
        let max_risk_bounds: number[] = []
        // TODO: get locations from many geographies
        const locations: { lat: number; lon: number }[] = []

        data?.ds_locations_geographies?.forEach((location) => {
            if (!location.ds_location) return
            locations.push({
                lat: location.ds_location.lat,
                lon: location.ds_location.lon,
            })
        })

        data.default_geography.hazards.forEach((hazard) => {
            const value =
                hazard.risk_outlook_seasonal_stats.plot[0].lastValue[
                    yProperty as "y" | "y1"
                ]

            if (value > max_value) {
                max_value = value
                hazard_key = hazard.hazard_name
                max_risk_bounds = RISK_BOUNDS
            }
        })

        const riskCategory = getRiskCategoryCoding(max_value, max_risk_bounds)

        const shadedRegion: IPolygonGroup = {
            geojson: {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        geometry: data.region.geojson,
                        properties: {},
                    },
                ],
            },
            style: {
                fillColor: "#B3B6BA",
            },
        }

        pins = locations.map(({ lat, lon }) => ({
            lng: lon,
            lat,
            color: riskCategoryCoding[riskCategory],
        }))

        return {
            categorizedRegions: { [hazard_key]: shadedRegion },
            categorizedPins: pins,
        }
    }, [__data, yProperty])

    return (
        <ShadedRegionsMap
            showLegend={false}
            data={categorizedRegions}
            loading={loading}
            pins={categorizedPins}
        />
    )
}

export default ShadedRegionMapWidget
