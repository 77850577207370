import { ILinearPlotComponentDataSource } from "../../../entities/PlotComponents/types";
import { ILineAttributes, TypeDataSourcePoints } from "../data/types";


export class LinearPlotComponentDTO implements ILinearPlotComponentDataSource {
    id: string
    xLabel: string
    yLabel: string
    xLabelId: string
    yLabelId: string
    x: string[]
    y: number[]

    constructor(
        id: string,
        xLabelId: string,
        xLabel: string,
        yLabelId: string,
        yLabel: string,
        points: TypeDataSourcePoints<ILineAttributes>) {
        this.id = id
        this.xLabel = xLabel
        this.yLabel = yLabel
        this.xLabelId = xLabelId
        this.yLabelId = yLabelId

        const { x, y } = this.getComposedAxes(points)
        this.x = x
        this.y = y
    }

    /* 
        All the transformation from the data source points to the 
        - LinePlotComponent
        - DirectionalityPlotComponet 
        happens inside this function. 
    */
    private getComposedAxes(points: TypeDataSourcePoints<ILineAttributes>): {
        x: string[]
        y: number[]
    } {
        const x: string[] = []
        const y: number[] = []

        for (let i = 0; i < points.length; i++) {
            x.push(points[i].date)
            y.push(points[i].attributes['ensemble_mean'])
        }

        return { x, y }
    }
}