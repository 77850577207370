import { ArrowBottom } from "../../icons"

const SortButton = ({ sortDir }: { sortDir?: string | boolean }) => {
    return (
        <div
            className={[
                "absolute",
                "left-[0.5em] -bottom-3",
                "rounded-full",
                "cursor-pointer",
                "bg-white border-gray-30 border-[0.1em]",
                "w-6 h-6",
                "flex flex-col flex-nowrap items-center",
                "fill-gray-30",
            ].join(" ")}>
            <div
                className={[
                    sortDir === "asc" ? "fill-gray-60" : "",
                    "absolute -top-1",
                    "grow-0 h-full w-full rotate-180",
                ].join(" ")}>
                <ArrowBottom />
            </div>
            <div
                className={[
                    sortDir === "desc" ? "fill-gray-60" : "",
                    "absolute -bottom-1",
                    "grow-0 h-full w-full",
                ].join(" ")}>
                <ArrowBottom />
            </div>
        </div>
    )
}

export default SortButton
