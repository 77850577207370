import { AxiosError } from "axios"
import {
    CustomResponse,
    IRequestConfig,
    makeRequest,
    addQueryParams,
} from "./index"
import { IPrimitivesDictionary } from "../../types"

const TRAILING_SLASH_REGEX = /\/*$/g
const LEADING_SLASH_REGEX = /^\/*/g
const MULTIPLE_SLASH_REGEX = /\/{2,}/g

type PostBody =
    | IPrimitivesDictionary
    | IPrimitivesDictionary[]
    | Record<string, any>
    | null

interface IQuerySetConfig {
    path: string
    data?: PostBody
    queryParams?: IPrimitivesDictionary
    config?: Partial<IRequestConfig>
    requiresToken?: boolean
}
export class QuerySet {
    [key: string]: (
        path: string | IQuerySetConfig,
        data?: PostBody,
        queryParams?: IPrimitivesDictionary,
        config?: Partial<IRequestConfig>,
        requiresToken?: boolean
    ) => Promise<CustomResponse | AxiosError | null>
    constructor(
        _endpoint: string,
        methods: string[] = ["post", "get"],
        apiUrl?: string
    ) {
        const endpoint = _endpoint
            .replaceAll(TRAILING_SLASH_REGEX, "/")
            .replaceAll(LEADING_SLASH_REGEX, "")
        methods.forEach((_method) => {
            const method = _method.toLowerCase()
            this[method] = async (
                _path,
                _data,
                _queryParams,
                _config,
                _requiresToken = true
            ) => {
                let { path, data, queryParams, config, requiresToken } =
                    _path as IQuerySetConfig

                if (typeof _path === "string") {
                    path = _path
                    data = _data
                    queryParams = _queryParams
                    config = _config
                    requiresToken = _requiresToken
                }
                let fullPath =
                    endpoint +
                    path
                        .replaceAll(TRAILING_SLASH_REGEX, "/")
                        .replaceAll(LEADING_SLASH_REGEX, "")

                if (fullPath[fullPath.length - 1] !== "/") fullPath += "/"

                fullPath = fullPath.replaceAll(MULTIPLE_SLASH_REGEX, "/")

                return (await makeRequest(
                    method.toUpperCase(),
                    addQueryParams(fullPath, queryParams),
                    data,
                    config,
                    requiresToken,
                    apiUrl
                )) as CustomResponse
            }
        })
    }
}
