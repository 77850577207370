import { ResponsivePaddingWrapper } from "../../../../layouts/TabLayout"
import { useTranslate } from "@tolgee/react"
import { Button } from "../../../../climateui/components"
import { useDashboard } from "../../../../providers/DashboardProvider"

const DashboardTemplates = () => {
    const { t } = useTranslate()
    const { workingDashboard, setWorkingDashboard, goToStep } = useDashboard()

    const next = () => {
        goToStep("locations")
    }

    return (
        <ResponsivePaddingWrapper extraClasses="p-3 px-4 sm:p-3 sm:px-5 elevation-1 border-r border-gray-5 bg-gray-1.5">
            <div className="flex justify-between items-center -mt-3">
                <h1 className="font-poppins">{t("chooseTemplate")}</h1>
                <Button
                    label={t("continue")}
                    onClick={next}
                    disabled={!workingDashboard?.dtype ? true : false}
                />
            </div>
            <div className="grid grid-cols-2 gap-12 place-items-center mt-3 items-stretch">
                <div
                    key="regional"
                    className="w-1/2 justify-self-end self-auto">
                    <h1 className="title-lg text-center mb-5">
                        {t("regionalDashboard")}
                    </h1>
                    <img
                        className={
                            workingDashboard?.dtype == "Regional"
                                ? "border-[3px] border-accent rounded-[9px]"
                                : "hover:border-[3px] hover:border-accent hover:rounded-[9px]"
                        }
                        src="/images/portfolio-template.svg"
                        onClick={() => {
                            setWorkingDashboard({
                                ...workingDashboard,
                                dtype: "Regional",
                            })
                        }}
                    />
                    <div
                        key="description"
                        className="text-center mt-5">
                        <p className="font-roboto font-normal">
                            {t("regionalDescription")}
                        </p>
                    </div>
                </div>
                <div
                    key="location"
                    className="w-1/2 justify-self-start self-auto">
                    <h1 className="title-lg text-center mb-5">
                        {t("locationDashboard")}
                    </h1>
                    <img
                        className={
                            workingDashboard?.dtype == "Location"
                                ? "border-[3px] border-accent rounded-[9px]"
                                : "hover:border-[3px] hover:border-accent hover:rounded-[9px]"
                        }
                        src="/images/location-template.svg"
                        onClick={() => {
                            setWorkingDashboard({
                                ...workingDashboard,
                                dtype: "Location",
                            })
                        }}
                    />
                    <div
                        key="description"
                        className="text-center mt-5">
                        <p className="font-roboto font-normal">
                            {t("locationDescription")}
                        </p>
                    </div>
                </div>
            </div>
        </ResponsivePaddingWrapper>
    )
}

export default DashboardTemplates
