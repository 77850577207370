import { useTranslate } from "@tolgee/react"
import { useContext, useMemo, useState } from "react"
import {
    DebounceSearchInput,
    MultiSelectFilter,
    SingleSelectFilter,
} from "../../../climateui/components"
import { IBooleanDictionary, ILabel } from "../../../climateui/types"
import { RiskProfilesContext } from "../../../providers/RiskProfilesProvider"

function StageRisksTableFilters({
    setColumnFilters,
    columnFilters,
    setGlobalFilter,
    searchIndependentRow = true,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setColumnFilters: (newColumnFilters: any) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columnFilters: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setGlobalFilter: (newGlobalFilter: any) => void
    searchIndependentRow?: boolean
}) {
    const { t } = useTranslate()

    const { riskProfiles } = useContext(RiskProfilesContext)
    const { variableOptions, labelOptions } = useMemo(() => {
        const variableOptions: Record<string, string> = {
            all: "All",
        }
        const labelOptions: Record<string, string> = {}
        riskProfiles?.forEach((riskProfile) => {
            riskProfile?.hazard_profiles.forEach((hazardProfile) => {
                const hazardVariable = hazardProfile.hazard_variable
                variableOptions[hazardVariable.id] =
                    hazardVariable.readable_name
            })
            riskProfile?.labels?.forEach((label: ILabel) => {
                if (label.id) labelOptions[label.id] = label.name
            })
        })
        return {
            variableOptions,
            labelOptions,
        }
    }, [riskProfiles])

    const [selectedVariable, setSelectedVariable] = useState<string>("")
    const [selectedLabels, setSelectedLabels] = useState<IBooleanDictionary>({})

    return (
        <div className="flex flex-col gap-2">
            {searchIndependentRow && (
                <DebounceSearchInput
                    placeholder={t("searchByName", "Search by name")}
                    onSearch={setGlobalFilter}
                />
            )}
            <div className="flex flex-row gap-2 flex-wrap">
                {!searchIndependentRow && (
                    <div className="w-36">
                        <DebounceSearchInput
                            placeholder={t("search", "Search")}
                            onSearch={setGlobalFilter}
                            isSmall
                        />
                    </div>
                )}
                <SingleSelectFilter
                    leftRightClass="left-0"
                    selected={selectedVariable}
                    options={variableOptions}
                    setSelected={(newVariable) => {
                        if (newVariable === "all") newVariable = ""

                        setSelectedVariable(newVariable)
                        const tempObj = {
                            id: "variable",
                            value: newVariable,
                        }
                        setColumnFilters(columnFilters.concat([tempObj]))
                    }}
                    placeholder={t("variable", "Variable")}
                />
                <MultiSelectFilter
                    filterName={t("labels")}
                    leftRightClass="max-w-[180px]"
                    selected={selectedLabels}
                    options={labelOptions}
                    setSelected={(newLabels) => {
                        setSelectedLabels(newLabels)
                        const tempObj = {
                            id: "labels",
                            value: Object.keys(newLabels).map(
                                (labelId) => labelId
                            ),
                        }
                        setColumnFilters(columnFilters.concat([tempObj]))
                    }}
                    placeholder={t("labels", "Labels")}
                    searchPlaceholder={t("search")}
                />
            </div>
        </div>
    )
}

export default StageRisksTableFilters
