import { useTranslate } from "@tolgee/react"
import { PortalComponent, Tooltip } from "../../climateui/components"
import DragDate from "./components/DragDate"
import Strategy from "./components/Strategy"
import WeeklyTimeline from "./components/WeeklyTimeline"
import {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { EDITION_ACTIONS } from "./components/utils"
import { IStrategy } from "../../types"
import { getDaysInMonth } from "date-fns"
import { CollapseAllIcon, ExpandAllIcon } from "../../climateui/icons"
import { IBooleanDictionary } from "../../climateui/types"
import { useRiskProfiles } from "../../providers/RiskProfilesProvider"
import {
    FIXED_PX_PER_DAY_12_M,
    FIXED_PX_PER_DAY_6_M,
    useSeasonalCalendar,
} from "../../providers/SeasonalCalendarProvider"
import EditingStage from "./components/EditingStage"
import Month from "./components/Month"

const SeasonalCalendar = () => {
    const {
        modifiableStrategies,
        setModifiableStrategies,
        months,
        monthsOffset,
        pxPerDay,
        setPxPerDay,
        openStrategies,
        setOpenStrategies,
        isEditingCalendar,
    } = useSeasonalCalendar()
    const { customRiskProfilesObj: riskProfilesObj } = useRiskProfiles()

    const strategiesObj = modifiableStrategies

    const [lastUIWeeklyTimeline, setLastUIWeeklyTimeline] =
        useState<ReactNode>(null)

    useEffect(() => {
        // TODO: Find a less erizo way of doing this:
        // Always display the last UI WeeklyTimeline at the bottom
        // Tried:
        // - using order-last class (added it anyway in case 200 ms are not enough for some cases)
        setLastUIWeeklyTimeline(null)
        const timeout = setTimeout(() => {
            setLastUIWeeklyTimeline(
                <PortalComponent portalId="calendarMainPortal">
                    <div className="order-last grow shrink">
                        <WeeklyTimeline />
                    </div>
                </PortalComponent>
            )
        }, 100)
        return () => clearTimeout(timeout)
    }, [Object.keys(modifiableStrategies).length])

    const { t } = useTranslate()

    const allStrategiesOpen = useMemo(
        () =>
            Object.keys(strategiesObj).every((strategyId) => {
                return openStrategies[strategyId] || false
            }),
        [openStrategies, strategiesObj]
    )

    const toggleStrategiesOpen = useCallback(() => {
        let newOpenStatus = true
        if (allStrategiesOpen) newOpenStatus = false

        const newOpenStrategies: IBooleanDictionary = {}
        Object.keys(strategiesObj).forEach((strategyId) => {
            newOpenStrategies[strategyId] = newOpenStatus
        })

        setOpenStrategies(newOpenStrategies)
    }, [allStrategiesOpen, openStrategies, strategiesObj])

    const canTogglePlannedRisks =
        riskProfilesObj && Object.keys(riskProfilesObj).length > 0

    const calendarSectionRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        function handleWindowResize() {
            const calBoundingRect =
                calendarSectionRef.current?.getBoundingClientRect()
            const bodyWidth = document.body.clientWidth
            const calLeft = (calBoundingRect?.left ?? 0) + 22 // 2 pixels gray border (normal 20)
            const newPxPerDay = (bodyWidth - calLeft) / 365

            setPxPerDay(
                Math.max(
                    newPxPerDay,
                    isEditingCalendar
                        ? FIXED_PX_PER_DAY_12_M
                        : FIXED_PX_PER_DAY_6_M
                )
            )
        }
        handleWindowResize()

        window.addEventListener("resize", handleWindowResize)

        return () => window.removeEventListener("resize", handleWindowResize)
    }, [isEditingCalendar])

    return (
        <>
            <div className="relative flex flex-row items-stretch h-max min-h-full shrink-0 w-max">
                {/* LEFT NAV SECTION */}
                <div
                    className={[
                        "sticky left-0 z-full",
                        "flex flex-col shrink-0 grow-0",
                    ].join(" ")}>
                    {/* Header row */}
                    <div className="sticky top-0 z-40 border-b border-gray-14 box-border">
                        <div
                            className={[
                                "flex flex-row items-center justify-start",
                                "h-12 pl-1 pr-4",
                                "border-b border-r cursor-pointer",
                                "bg-gray-3 label-lg text-gray-90 border-gray-14",
                            ].join(" ")}>
                            <div
                                className="w-5 h-5 mr-2 fill-gray-60"
                                onClick={
                                    canTogglePlannedRisks
                                        ? toggleStrategiesOpen
                                        : () => null
                                }>
                                {canTogglePlannedRisks && (
                                    <Tooltip
                                        content={
                                            allStrategiesOpen
                                                ? t("collapseAll", {
                                                    gender: "female",
                                                })
                                                : t("expandAll", {
                                                    gender: "female",
                                                })
                                        }
                                        position="right">
                                        {allStrategiesOpen ? (
                                            <CollapseAllIcon />
                                        ) : (
                                            <ExpandAllIcon />
                                        )}
                                    </Tooltip>
                                )}
                            </div>
                            <h5 className="w-[140px] grow-0 shrink-0">
                                {t("locations", "Locations")}
                            </h5>
                            <h6 className="w-[120px] grow-0 shrink-0">
                                {t("assets", "Assets")}
                            </h6>
                            {!isEditingCalendar && (
                                <h6 className="w-[130px] shrink-0 grow-0">
                                    {t("impact", "Impact")}
                                </h6>
                            )}
                        </div>
                        <div className="flex flex-row items-center justify-end w-full pr-4 bg-white border-r h-7 border-gray-14 body-sm text-gray-60">
                            {!isEditingCalendar && (
                                <>
                                    <div className="w-[65px] grow-0 shrink-0">
                                        {t("observed", "Observed")}
                                    </div>
                                    <div className="w-[65px] grow-0 shrink-0">
                                        {t("forecast", "Forecast")}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    {/* Strategies row */}
                    <div className="flex flex-col bg-white border-r divide-y divide-gray-14 border-gray-14 grow">
                        {Object.keys(strategiesObj).map((strategyId, index) => (
                            <Strategy
                                strategy={strategiesObj[strategyId]}
                                strategyOrder={index}
                                updateStrategy={(
                                    newStrategy: Partial<IStrategy>
                                ) => {
                                    // function that updates the things you send to it,
                                    // if you only send stages, it only updates stages.
                                    setModifiableStrategies({
                                        ...strategiesObj,
                                        [strategyId]: {
                                            ...strategiesObj[strategyId],
                                            ...newStrategy,
                                            action:
                                                newStrategy.action ??
                                                strategiesObj[strategyId]
                                                    .action ??
                                                EDITION_ACTIONS.edited,
                                        },
                                    })
                                }}
                                key={strategyId}
                            />
                        ))}
                        <div className="bg-white grow shrink"></div>
                    </div>
                </div>

                <div
                    className="flex flex-row items-stretch bg-white"
                    style={{
                        width:
                            (isEditingCalendar ? 28 : 0) +
                            pxPerDay * 365 +
                            "px",
                        contain: "paint",
                    }}>
                    {/* CALENDAR LEFT "PADDING" SECTION */}
                    {isEditingCalendar && (
                        <div className="flex flex-col w-7 grow-0 shrink-0">
                            <div className="sticky top-0 z-50 grow-0 shrink-0">
                                <div className="w-full h-12 border-b bg-gray-3 border-gray-14"></div>
                                <div className="w-full bg-white border-b h-7 border-gray-14 box-content"></div>
                            </div>
                        </div>
                    )}

                    {/* ACTUAL CALENDAR SECTION */}
                    <div
                        className="flex flex-col items-stretch text-center bg-gray-5 text-gray-90"
                        ref={calendarSectionRef}>
                        <div className="sticky top-0 z-50 border-b border-gray-14 box-border">
                            {/* Months row */}
                            <div className="flex flex-row items-center h-12 divide-x divide-gray-14">
                                {monthsOffset > 0 && (
                                    <Month
                                        customWidth={monthsOffset}
                                        date={new Date()}
                                    />
                                )}
                                {months.map((monthDate, index) => {
                                    const customWidth =
                                        index + 1 === months.length &&
                                            monthsOffset > 0
                                            ? getDaysInMonth(monthDate) -
                                            monthsOffset -
                                            2
                                            : undefined

                                    return (
                                        <Month
                                            key={monthDate.toISOString()}
                                            customWidth={customWidth}
                                            date={monthDate}
                                        />
                                    )
                                })}
                            </div>

                            {/* Weeks row */}
                            <div className="h-7">
                                <WeeklyTimeline showWeekNumber={true} />
                            </div>
                        </div>

                        {/* Calendar content portal */}
                        <div
                            className="relative flex flex-col grow divide-y divide-gray-14"
                            style={{
                                width: pxPerDay * 365 + "px",
                            }}
                            id="calendarMainPortal">
                            <DragDate />
                        </div>

                        {/* Last WeeklyTimeline to show lines at the bottom (just UI) */}
                        {lastUIWeeklyTimeline}
                    </div>
                </div>
            </div>
            <EditingStage />
        </>
    )
}

export default SeasonalCalendar
