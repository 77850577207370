import { useTranslate } from "@tolgee/react"
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
    BackgroundAndCardLayout,
    Button,
    LabelAndInput,
} from "../../climateui/components"
import { GenericInput } from "../../climateui/components/Inputs"
import { isValidResponse } from "../../climateui/utils/http"
import { useToast } from "../../climateui/providers/Toast/ToastContextProvider"
import { useAuth } from "../../providers/AuthProvider"

const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
)

const RestorePasswordNewPassword = () => {
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [showRecoverPassword, setShowRecoverPassword] = useState(false)
    const { enqueueError } = useToast()

    const [urlCode, setUrlCode] = useState("")
    const location = useLocation()
    const navigate = useNavigate()
    const auth = useAuth()
    const { t } = useTranslate()

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const oobCode = queryParams.get("oobCode")
        if (!oobCode) {
            enqueueError("Restore password code not provided.")
            setTimeout(() => {
                navigate("/")
            }, 4000)
        }
        setUrlCode(oobCode)
    }, [])

    const handlePassword = async (event) => {
        event.preventDefault()
        setLoading(true)
        try {
            // TODO Link new auth microservice (firebase old)
            let result = await auth.resetPassword({
                token: urlCode,
                password: newPassword,
            })
            setLoading(false)

            if (isValidResponse(result)) {
                navigate("/restore-password/confirmation")
            } else {
                enqueueError(
                    t(
                        "errorResetingPassword",
                        "There was an error creating your new password."
                    )
                )
                setShowRecoverPassword(true)
            }
        } catch (error) {
            setLoading(false)
            enqueueError(error)
        }
    }

    const isValidPassword = (pwd1) => {
        const errors = []
        if (!new RegExp("^(?=.*[a-z])").test(pwd1))
            errors.push(t("passValOneLower"))
        if (!new RegExp("^(?=.*[A-Z])").test(pwd1))
            errors.push(t("passValOneUpper"))
        if (!new RegExp("^(?=.*[0-9])").test(pwd1))
            errors.push(t("passValOneNumber"))
        if (!new RegExp("^(?=.*[!@#$%^&*])").test(pwd1))
            errors.push(t("passValOneSpecial"))
        if (pwd1.length < 8) errors.push(t("passValLength"))
        if (errors.length > 0) {
            setError(t("passValBase") + " " + errors.join(", ") + ".")
            return false
        }
        setError("")
        return true
    }

    const returnPath = location.state ? location.state.prevPath : "/"

    return (
        <BackgroundAndCardLayout copyright={t("copyright")}>
            <div className="montserrat text-[16px] text-gray-90 font-semibold text-center leading-tight pb-2">
                {t("createNewPassword")}
            </div>
            <LabelAndInput
                label={t("newPasswordInputLabel")}
                input={
                    <GenericInput
                        handleChange={(e) => {
                            setNewPassword(e.target.value)
                            isValidPassword(e.target.value)
                        }}
                        value={newPassword}
                        placeholder="********"
                        type="password"
                        error={error}
                    />
                }
            />
            <LabelAndInput
                label={t("confirmNewPasswordInputLabel")}
                input={
                    <GenericInput
                        handleChange={(e) => {
                            setConfirmNewPassword(e.target.value)
                        }}
                        value={confirmNewPassword}
                        placeholder="********"
                        type="password"
                        error={
                            confirmNewPassword !== "" &&
                            newPassword !== "" &&
                            newPassword !== confirmNewPassword
                                ? t("passValDoNotMatch")
                                : ""
                        }
                    />
                }
            />

            <div className="flex flex-row self-stretch pt-4 pb-2 space-x-2">
                <Link
                    className="w-1/2"
                    to={returnPath}>
                    <Button
                        label={t("cancel")}
                        type="secondary"
                        extend={true}
                        onClick={() => null}
                    />
                </Link>
                <Button
                    extraClasses={"w-1/2" + (loading ? " cursor-wait" : "")}
                    label={t("continue")}
                    type="primary"
                    extend={true}
                    onClick={handlePassword}
                    disabled={
                        newPassword !== confirmNewPassword ||
                        //!strongRegex.test(newPassword) ||
                        loading
                    }
                />
            </div>

            {showRecoverPassword && (
                <Link
                    className="self-center no-underline body-md text-accent hover:text-accent-dark active:text-accent hover:underline"
                    to="/restore-password">
                    {t(
                        "redirectToForgotPassword",
                        "Resend email to recover password"
                    )}
                </Link>
            )}
        </BackgroundAndCardLayout>
    )
}

export default RestorePasswordNewPassword
