import { useTranslate } from "@tolgee/react"
import { useContext, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { SideBar } from "../components"
import { useRoutesFeatureFlags } from "../hooks"
import { useAuth } from "../providers"
import { useAccount } from "../providers/AccountProvider"
import { useAuthNavigation } from "../providers/AuthGuards"
import { isAllowedToRoute } from "../providers/AuthProvider"
import { LocalizationContext } from "../providers/LocalizationProvider"
import { useUI } from "../providers/UIProvider"

export function ResponsivePaddingWrapper({ children, extraClasses = "" }) {
    return (
        <div
            className={
                "overflow-hidden h-full w-full " +
                "p-3 px-4 sm:p-6 sm:px-8 " +
                extraClasses
            }>
            {children}
        </div>
    )
}

function TabLayout({
    sidebarOptions = [],
    children,
    withPadding = true,
    redirectPaths = [],
    basePath = "",
    hideOverflow = false,
}) {
    const auth = useAuth()
    const acc = useAccount()
    const { lastVisitedViewsPerTab } = useUI()
    const routesFeatureFlags = useRoutesFeatureFlags()

    const { navigateToAllowed } = useAuthNavigation()
    const { language } = useContext(LocalizationContext)
    const location = useLocation()
    const { t } = useTranslate()
    const navigate = useNavigate()

    const allowedSideBarOptions = useMemo(() => {
        const result = []
        sidebarOptions.forEach((option) => {
            const isAllowed = isAllowedToRoute(
                option,
                acc,
                auth,
                routesFeatureFlags
            )

            if (option.label && isAllowed)
                result.push({
                    ...option,
                    label: t(option.labelKey) || option.label,
                })
        })
        return result
    }, [auth.roles, acc.accountPermissions, acc.selectedAccount, language])

    useEffect(() => {
        if (redirectPaths.includes(location.pathname)) {
            const tabPath = basePath.replaceAll("/", "") // "seasonal" | "climate" ...
            if (lastVisitedViewsPerTab[tabPath])
                navigate(lastVisitedViewsPerTab[tabPath])
            else {
                navigateToAllowed(sidebarOptions, basePath)
            }
        }
    }, [acc.selectedAccount])

    return (
        <div className="flex flex-row w-full h-full min-h-0 grow">
            {/* SIDEBAR */}
            <SideBar options={allowedSideBarOptions} />
            {/* CONTENT */}
            {withPadding ? (
                <ResponsivePaddingWrapper>{children}</ResponsivePaddingWrapper>
            ) : (
                <div
                    className={
                        "w-full h-full bg-white " +
                        (hideOverflow ? "overflow-hidden " : "") +
                        "print:overflow-visible print:h-auto"
                    }>
                    {children}
                </div>
            )}
        </div>
    )
}

export default TabLayout
