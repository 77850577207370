import {
    Button,
    MultiSelectFilter,
    DatePicker,
    Table,
    DebounceSearchInput,
} from "../../../../climateui/components"
import ChevronIcon from "../../../../climateui/icons/ChevronArrow"
import {
    alertSettingsQuerySet,
    locationListSearchGET,
    riskProfileQuerySet,
    triggeredAlertsPOST,
} from "../../../../utils/networking"
import { SORTING_MAPPING, columns } from "./alertSettingsTableUtils"
import { useEffect, useRef, useState } from "react"
import {
    AlertSettingInput,
    IAlertSetting,
    IHazardProfile,
    IRegion,
    IInsightsLocation,
    ITrigger,
    IAccount,
    IRiskProfile,
    IAugmentedAlertSetting,
    toIAlertSettings,
    collapseAugmentedAlert,
} from "../../../../types"
import { Link, useNavigate, useParams } from "react-router-dom"
import { arrToDict, arrToRank } from "../../../../utils/transform"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { CAIResponse, isValidResponse } from "../../../../climateui/utils/http"
import { ThresholdExtract } from "../riskProfilesTableUtils"
import { useTranslate } from "@tolgee/react"
import LocationSelectionModal from "./components/LocationSelectionModal"
import { PlusIcon } from "../../../../climateui/icons"
import {
    IBooleanDictionary,
    ILabel,
    IPrimitivesDictionary,
} from "../../../../climateui/types"
import { IFilter, andFilterPayload } from "../../../../utils/filters"
import { INPUT_DATE_FORMAT } from "./consts"
import { useOutsideComponentClickHandler } from "../../../../climateui/hooks"
import { DateTime } from "luxon"
import { useAccount } from "../../../../providers/AccountProvider"
import { SortingState } from "@tanstack/react-table"
import LabelItem from "../../../../climateui/components/Labels/LabelItem"
import { useCustomFlags, useIsFlagEnabled } from "../../../../hooks"
import { PRODUCT_IDS } from "../../../../utils/constants"

export const AlertSettingsView = () => {
    /* HOOKS > START */
    const queryClient = useQueryClient()
    const { riskProfileId } = useParams()
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { selectedAccount, accountsObject } = useAccount()
    /* HOOKS < END */

    /* CONSTS > START */
    const DEFAULT_FILTER: IFilter = {
        filter_by: {
            and: [
                {
                    field_name: "risk_setting.RiskSetting.risk_profile_id",
                    operator: "eq",
                    field_value: riskProfileId || "none",
                },
                {
                    field_name: "risk_setting.RiskSetting.product_id",
                    operator: "in",
                    field_value: [
                        PRODUCT_IDS.ALERT_SETTINGS,
                        PRODUCT_IDS.STAGE_MANAGER,
                    ],
                },
            ],
        },
    }
    /* CONSTS < END */

    /* STATE > START */
    const regionOpts = useRef<Record<string, string>>()
    const labelsOpts = useRef<Record<string, string>>()

    const [childrenAccounts, setChildrenAccounts] = useState<
        IAccount[]
    >([])

    const [pageSizeArr, setPageSizeArr] = useState([10, 25, 50])
    const [pageSizeOpts, setPageSizeOpts] = useState<{ [key: number]: string }>(
        {
            10: `10 ${t("locations", "Locations").toLowerCase()}`,
            25: `25 ${t("locations", "Locations").toLowerCase()}`,
            50: `50 ${t("locations", "Locations").toLowerCase()}`,
        }
    )

    const { feature_alert_settings_pagination_options: fsPageSizeOpts } =
        useCustomFlags(["feature_alert_settings_pagination_options"])
    const isStageManagerEnabled = useIsFlagEnabled("feature_stage_manager")

    const [pagination, setPagination] = useState({
        page: 1,
        per_page: pageSizeArr[0],
        all: false,
    })

    const [sorting, setSorting] = useState<SortingState>([])

    const [regionFilter, setRegionFilter] = useState<IBooleanDictionary>({})

    const [statusFilter, setStatusFilter] = useState<IBooleanDictionary>()

    const [locationLabelsFilter, setLocationLabelsFilter] =
        useState<IBooleanDictionary>({})

    const [searchTerm, setSearchTerm] = useState<string>("")

    const [locationFiltersPayload, setLocationFiltersPayload] =
        useState<IFilter>()

    const { data: locations, isLoading: isLoadingLocations } = useQuery({
        queryKey: ["alertSettingsLocations", locationFiltersPayload],
        queryFn: () =>
            locationListSearchGET({
                ...locationFiltersPayload,
                pagination: {
                    all: true,
                },
            }).then(
                (res) =>
                    (isValidResponse(res)
                        ? res.data.data
                        : []) as IInsightsLocation[]
            ),
        enabled: !!locationFiltersPayload,
    })

    const [locationsOrder, setLocationsOrder] = useState<
        Record<string, number>
    >({})

    const [isSelectingLocations, setSelectingLocations] =
        useState<boolean>(false)

    const [rowSelection, setRowSelection] = useState({})

    /* STATE > BULK UPDATE > START */
    const [bulkUpdateDateRange, setBulkUpdateDateRange] = useState<{
        startDate?: DateTime
        endDate?: DateTime
    }>({
        startDate: undefined,
        endDate: undefined,
    })
    const [isBulkUpdatingSchedule, setIsBulkUpdatingSchedule] =
        useState<boolean>(false)

    const bulkDatePicker = useOutsideComponentClickHandler(() =>
        setIsBulkUpdatingSchedule(false)
    )
    /* STATE > BULK UPDATE < END */

    const [regions, setRegions] = useState<IRegion[]>([])
    const [regionsOrder, setRegionsOrder] = useState<Record<string, number>>({})
    const [filteredRegionLocations, setFilteredRegionLocations] = useState<
        IInsightsLocation[]
    >([])

    const [payloadFilter, setPayloadFilter] = useState<IFilter>(DEFAULT_FILTER)

    const [alertSettings, setAlertSettings] = useState<
        IAugmentedAlertSetting[]
    >([])

    const [selectedAlertSettings, setSelectedAlertSettings] = useState<
        IAugmentedAlertSetting[]
    >([])

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [locationsDict, setLocationsDict] = useState<
        Record<string, IInsightsLocation>
    >({})

    const {
        data: riskProfile,
        status: riskProfileStatus,
        isFetching: isRiskProfileFetching,
    } = useQuery({
        queryKey: ["currentRiskProfile", riskProfileId],
        queryFn: () => riskProfileQuerySet.get(`/${riskProfileId}/`),
        select: (res) => {
            if (!isValidResponse(res)) return
            return res.data as IRiskProfile
        },
    })
    const { data: alertSettingsResponse, isLoading: isLoadingAlerts } =
        useQuery({
            queryKey: ["alertSettings", payloadFilter],
            queryFn: () =>
                alertSettingsQuerySet.post("/search", {
                    ...payloadFilter,
                    pagination: {
                        all: true,
                    },
                }),
            enabled: !!payloadFilter && !!locations?.length,
        })
    const { data: processingRunsResponse } = useQuery({
        queryKey: ["alerts", alertSettingsResponse],
        queryFn: () => {
            let alertSettings: IAlertSetting[] = []
            if (isValidResponse(alertSettingsResponse)) {
                alertSettings = alertSettingsResponse.data
                    .data as IAlertSetting[]
            }
            return triggeredAlertsPOST({
                filter_by: {
                    and: [
                        {
                            field_name:
                                "processing_run.ProcessingRun.risk_setting_id",
                            operator: "in",
                            field_value: JSON.stringify(
                                alertSettings.map(
                                    (alertSetting) => alertSetting.id
                                )
                            ),
                        },
                        {
                            field_name: "alert.Alert.category",
                            operator: "ne",
                            field_value: 'No Action',
                        }
                    ],
                },
                pagination: {
                    all: true,
                },
            })
        },
    })
    /* STATE < END */

    /* EFFECTS > START */

    // Define locations order
    useEffect(() => {
        if (!locations || locations.length === 0 || sorting.length === 0) {
            setLocationsOrder({})
            return
        }
        const locationSorting = sorting.find((col) => col.id === "location")
        if (!locationSorting) {
            setLocationsOrder({})
            return
        }
        const accessorFn = SORTING_MAPPING[locationSorting.id]
        setLocationsOrder(
            arrToRank(
                locations
                    // TODO: Sort this through the backend
                    .sort((_a, _b) => {
                        const a = accessorFn(_a)
                        const b = accessorFn(_b)
                        const descFactor = locationSorting.desc ? -1 : 1
                        if (a < b) return -1 * descFactor
                        if (a > b) return 1 * descFactor
                        return 0
                    }),
                "id"
            )
        )
    }, [locations, sorting])
    // Define children accounts
    useEffect(() => {
        if (!selectedAccount || !accountsObject) setChildrenAccounts([])
        else
            setChildrenAccounts(accountsObject[selectedAccount]?.children ?? [])
    }, [selectedAccount, accountsObject])

    // Convert locations into a dictionary/map
    useEffect(() => {
        if (!locations) setLocationsDict({})
        else setLocationsDict(arrToDict(locations, "id"))
    }, [locations])

    // Get pagination options from FlagSmith
    useEffect(() => {
        try {
            // Update pagination page size options
            const arr = JSON.parse(fsPageSizeOpts.value as string) as number[]
            if (!arr || arr.length === 0) return
            setPageSizeArr(arr)
        } catch {
            // do nothing
        }
    }, [fsPageSizeOpts.value])

    // Update the table pagination
    useEffect(() => {
        // Reset pagination
        setPagination({
            ...pagination,
            page: 1,
            per_page: pageSizeArr[0],
        })
        setPageSizeOpts(
            pageSizeArr.reduce(
                (prev: { [key: number]: string }, curr: number) => {
                    return {
                        ...prev,
                        [curr]: `${curr} ${t(
                            "locations",
                            "Locations"
                        ).toLowerCase()}`,
                    }
                },
                {}
            )
        )
    }, [pageSizeArr])

    // Define the payload to filter locations
    useEffect(() => {
        if (!selectedAccount || !childrenAccounts)
            setLocationFiltersPayload(undefined)
        else {
            let filter = andFilterPayload(
                "location.Location.account_id",
                "in",
                JSON.stringify([
                    selectedAccount,
                    ...childrenAccounts.map((acc) => acc.id),
                ])
            )
            if (searchTerm?.length > 0) {
                filter = andFilterPayload(
                    "location.Location.name",
                    "icontains",
                    searchTerm,
                    filter
                )
            }
            const labelsIDs = Object.keys(locationLabelsFilter)
            if (labelsIDs.length > 0) {
                labelsIDs.forEach((labelID) => {
                    filter.filter_by.and?.push({
                        field_name: "location.Location.labels",
                        operator: "any",
                        field_value: {
                            field_name: "label.Label.id",
                            operator: "eq",
                            field_value: labelID,
                        },
                    })
                })
            }
            setLocationFiltersPayload(filter)
        }
    }, [searchTerm, selectedAccount, childrenAccounts, locationLabelsFilter])

    // Define regions
    useEffect(() => {
        if (!locations || locations.length === 0) {
            setRegions([])
            return
        }
        const regionsDict: Record<string, IRegion> = {}
        locations.forEach((loc) => {
            if (loc.regions) {
                loc.regions.forEach((reg) => {
                    if (reg.id && !regionsDict[reg.id]) {
                        regionsDict[reg.id] = reg
                    }
                })
            }
            if (loc.region) {
                if (loc.region.id && !regionsDict[loc.region.id]) {
                    regionsDict[loc.region.id] = loc.region
                }
            }
        })
        setRegions(Object.values(regionsDict))
    }, [locations])

    // Define regions order
    useEffect(() => {
        if (!regions || regions.length === 0 || sorting.length === 0) {
            setRegionsOrder({})
            return
        }
        const regionSorting = sorting.find((col) => col.id === "region")
        if (!regionSorting) {
            setRegionsOrder({})
            return
        }
        const accessorFn = SORTING_MAPPING[regionSorting.id]
        setRegionsOrder(
            arrToRank(
                regions.sort((_a, _b) => {
                    const a = accessorFn(_a)
                    const b = accessorFn(_b)
                    const descFactor = regionSorting.desc ? -1 : 1
                    if (a < b) return -1 * descFactor
                    if (a > b) return 1 * descFactor
                    return 0
                }),
                "id"
            )
        )
    }, [regions, sorting])

    // Define available locations based on region filters
    useEffect(() => {
        if (!regionFilter || !locations) {
            setFilteredRegionLocations([])
            return
        }
        const filteredRegions = Object.keys(regionFilter)
        if (filteredRegions.length === 0) {
            setFilteredRegionLocations(locations)
            return
        }
        setFilteredRegionLocations(
            locations.filter((loc: IInsightsLocation) => {
                // Prefering a for-loop instead of forEach to be able to return
                for (let i = 0; i < filteredRegions.length; i++) {
                    if (filteredRegions[i] === loc.region?.id) {
                        return true
                    }
                    const locationRegions = loc.regions?.map(
                        (region) => region.id
                    )
                    if (locationRegions?.includes(filteredRegions[i])) {
                        return true
                    }
                }
                return false
            })
        )
    }, [regionFilter, locations])

    // Define payload to filter alert settings
    useEffect(() => {
        let tmpFilter: IFilter = { ...DEFAULT_FILTER }
        if (
            filteredRegionLocations.length > 0 ||
            (regionFilter && Object.keys(regionFilter).length > 0)
        ) {
            tmpFilter = andFilterPayload(
                "risk_setting.RiskSetting.location_id",
                "in",
                JSON.stringify(
                    filteredRegionLocations.map(
                        (loc: IInsightsLocation) => loc.id
                    )
                ),
                tmpFilter
            )
        }
        if (statusFilter) {
            const statusArr = Object.keys(statusFilter)
            if (statusArr.length > 0) {
                tmpFilter = andFilterPayload(
                    "risk_setting.RiskSetting.status",
                    "in",
                    JSON.stringify(statusArr),
                    tmpFilter
                )
            }
        }
        setPayloadFilter(tmpFilter)
    }, [filteredRegionLocations, regionFilter, statusFilter])

    // Define preliminary alert settings
    const applyRegionOrdering = (
        regionsOrder: Record<string, number>,
        a: IAugmentedAlertSetting,
        b: IAugmentedAlertSetting
    ) => {
        if (Object.keys(regionsOrder).length > 0) {
            if (!a.location || !b.location) return 0
            const regionA = a.location.regions
                ? a.location.regions[0]
                : a.location.region
            const regionB = b.location.regions
                ? b.location.regions[0]
                : b.location.region
            if (!regionA && regionB?.id) return 1 - regionsOrder[regionB.id]
            if (regionA?.id && !regionB) return regionsOrder[regionA.id] - 1
            if (regionA?.id && regionB?.id)
                return regionsOrder[regionA.id] - regionsOrder[regionB.id]
        }
        return 0
    }
    const applyLocationOrdering = (
        locationsOrder: Record<string, number>,
        a: IAugmentedAlertSetting,
        b: IAugmentedAlertSetting
    ) => {
        if (Object.keys(locationsOrder).length > 0) {
            return locationsOrder[a.location_id] - locationsOrder[b.location_id]
        }
        return 0
    }
    // Default sorting function
    // active AlertSettings at the top then sort by name
    // if any other sorting is applied this will be ignored.
    const applyActiveOrdering = (
        a: IAugmentedAlertSetting,
        b: IAugmentedAlertSetting
    ) => {
        if (a.status === b.status) {
            if (a.location && b.location)
                return a.location.name.toLowerCase() >
                    b.location.name.toLowerCase()
                    ? 1
                    : -1
            return 0
        }
        return a.status === "active" ? -1 : 1
    }

    // Update labels filter options
    useEffect(() => {
        // If we have no alert settings, do nothing
        if (!alertSettings || alertSettings.length === 0) return
        // If we already have label options, do nothing
        if (labelsOpts.current) return
        labelsOpts.current = alertSettings.reduce(
            (prev: Record<string, string>, { location }) => {
                // If this alert setting doesn't have a location (highly unlikely), skip it
                if (!location) return prev
                let tmp = { ...prev }
                ;(location.labels as ILabel[]).forEach(
                    ({ id, name }) => {
                        tmp = { ...tmp, [id as string]: name }
                    }
                )
                return tmp
            },
            {}
        )
    }, [alertSettings])

    // Update regions filter options
    useEffect(() => {
        // If we have no alert settings, do nothing
        if (!alertSettings || alertSettings.length === 0) return
        // If we already have region options, do nothing
        if (regionOpts.current) return
        regionOpts.current = alertSettings.reduce(
            (prev: Record<string, string>, { location }) => {
                let tmp = { ...prev }
                // If this is a valid location for this account...
                if (location?.id && locationsDict[location.id]) {
                    const { region, regions } = location
                    if (regions) {
                        regions.forEach((reg) => {
                            tmp = { ...tmp, [reg.id as string]: reg.name }
                        })
                    }
                    if (region) {
                        tmp = { ...tmp, [region.id as string]: region.name }
                    }
                }
                return tmp
            },
            {}
        )
    }, [alertSettings, locationsDict])

    const getLastTriggeredAlert = (
        triggerResponse: CAIResponse | undefined,
        alerts: IAlertSetting[]
    ) => {
        if (!isValidResponse(triggerResponse)) return undefined
        // Turn the array into an ID based alert map
        const alertsDict = arrToDict(alerts, "id")
        // Get the lasted time an alert got triggered for this alert
        const rawLastTrigger = triggerResponse.data.data.reduce(
            (
                lastTrigger: DateTime | undefined,
                { processing_run }: ITrigger
            ) => {
                const { risk_setting_id: riskSettingId, ran_at: ranAt } =
                    processing_run
                if (!alertsDict[riskSettingId]) return lastTrigger
                const currLastTrigger = DateTime.fromISO(ranAt)
                if (!lastTrigger || currLastTrigger > lastTrigger) {
                    return currLastTrigger
                }
            },
            undefined
        )
        return rawLastTrigger?.toFormat("MMM dd, yyyy")
    }

    const chooseLatestAlert = (alerts: IAlertSetting[]) => {
        let latestAlert = alerts[0]
        alerts.forEach((alert) => {
            if (alert.updated_at > latestAlert.updated_at) {
                latestAlert = alert
            }
        })
        return latestAlert
    }
    // Define final alert settings (with last triggered info)
    useEffect(() => {
        // Return empty array if the network call fails
        if (!isValidResponse(alertSettingsResponse)) {
            setAlertSettings([])
            return
        }
        // Define where we'll store alerts grouped by location
        const alertsPerLocation: Record<string, IAlertSetting[]> = {}

        // Go through our alerts to assign them to a location group
        alertSettingsResponse.data.data.forEach(
            (alertSetting: IAlertSetting) => {
                const { location_id } = alertSetting
                if (alertsPerLocation[location_id]) {
                    // Append this alert setting to the existing group
                    alertsPerLocation[location_id].push(alertSetting)
                } else {
                    // Create the group with this alert setting as its first member
                    alertsPerLocation[location_id] = [alertSetting]
                }
            }
        )

        // Generate the augmented alert settings
        const augmentedAlerts: IAugmentedAlertSetting[] = []
        Object.values(alertsPerLocation).forEach((alerts) => {
            // Use the latest alert data as augmented alert information
            const { id, location_id, risk_profile_id, product_id, status } =
                chooseLatestAlert(alerts)
            // If the location is undefined, skip this alert
            const location = locationsDict[location_id]
            if (!location) return

            // Add augmented alert to the list
            augmentedAlerts.push({
                id,
                location_id,
                risk_profile_id,
                product_id,
                status,
                dates: alerts.reduce(
                    (prev: Record<string, [string, string]>, curr) => ({
                        ...prev,
                        [curr.id]: [curr.start_date, curr.end_date],
                    }),
                    {}
                ),
                createdAtDict: alerts.reduce(
                    (prev: Record<string, string>, curr) => ({
                        ...prev,
                        [curr.id]: curr.created_at,
                    }),
                    {}
                ),
                updatedAtDict: alerts.reduce(
                    (prev: Record<string, string>, curr) => ({
                        ...prev,
                        [curr.id]: curr.updated_at,
                    }),
                    {}
                ),
                location: locationsDict[location_id],
                lastTriggered: getLastTriggeredAlert(
                    processingRunsResponse,
                    alerts
                ),
            })
        })
        // Sort the augmented alerts
        augmentedAlerts.sort((a, b) => {
            if (
                Object.keys(regionsOrder).length === 0 &&
                Object.keys(locationsOrder).length === 0
            )
                return applyActiveOrdering(a, b)

            return (
                applyRegionOrdering(regionsOrder, a, b) ||
                applyLocationOrdering(locationsOrder, a, b) ||
                0
            )
        })
        setAlertSettings(augmentedAlerts)
    }, [
        alertSettingsResponse,
        processingRunsResponse,
        regionsOrder,
        locationsOrder,
        locationsDict,
    ])

    // Define selected alert settings
    useEffect(() => {
        const ids = Object.keys(rowSelection)
        // If there is no selection, return an empty array
        if (ids.length === 0) return
        setSelectedAlertSettings(
            alertSettings.filter(
                (alertSetting: IAugmentedAlertSetting) =>
                    ids.includes(alertSetting.id)
            )
        )
    }, [rowSelection, alertSettings])

    // Determine if the view is loading overall
    useEffect(
        () => setIsLoading(isLoadingAlerts || isLoadingLocations),
        [isLoadingAlerts, isLoadingLocations]
    )

    // Reset pagination after table change
    useEffect(() => {
        setPagination({
            ...pagination,
            per_page: pageSizeArr[0],
            page: 1,
        })
    }, [alertSettings, searchTerm, sorting])

    // Determine whether to stay on page or return to risk profiles list
    useEffect(() => {
        if (!selectedAccount) return
        if (isRiskProfileFetching && riskProfileStatus === "loading") return
        if (riskProfile?.account_id === selectedAccount) return
        navigate("/admin/risk-profiles/")
    }, [riskProfile, selectedAccount, isRiskProfileFetching, riskProfileStatus])
    /* EFFECTS < END */

    /* UTILS > START */
    const resetTable = () => {
        // Invalidate query
        queryClient.invalidateQueries(["alertSettings"])
        // Reset filter options
        regionOpts.current = undefined
        labelsOpts.current = undefined
    }
    const { mutateAsync: _bulkUpdate } = useMutation(
        (alerts: AlertSettingInput[]) => alertSettingsQuerySet.put("/", alerts),
        {
            onSettled: () => resetTable(),
        }
    )
    const { mutateAsync: deleteAlert } = useMutation((alertID: string) =>
        alertSettingsQuerySet.delete(`/${alertID}`)
    )

    const streamlineAlertSetting = (
        alertSetting: IAlertSetting
    ): Partial<IAlertSetting> => {
        const partialAlertSetting: Partial<IAlertSetting> = {
            ...alertSetting,
        }
        delete partialAlertSetting.created_at
        delete partialAlertSetting.updated_at
        delete partialAlertSetting.location
        return partialAlertSetting
    }
    const bulkUpdate = async (
        payload: IPrimitivesDictionary,
        collapseAlerts?: boolean
    ) => {
        if (selectedAlertSettings.length === 0) return undefined
        let pendingAlertSettings = selectedAlertSettings

        if (collapseAlerts) {
            let toBeDeleted: string[] = []
            pendingAlertSettings = selectedAlertSettings.map(
                (augmentedAlert) => {
                    const [collapsedAlert, removedAlerts] =
                        collapseAugmentedAlert(augmentedAlert)
                    toBeDeleted = toBeDeleted.concat(removedAlerts)
                    return collapsedAlert
                }
            )
            await Promise.all(
                toBeDeleted.map((alertID) => deleteAlert(alertID))
            )
        }

        const flattenedAlerts = pendingAlertSettings
            .map((augmentedAlert: IAugmentedAlertSetting) =>
                toIAlertSettings(augmentedAlert)
            )
            .flat()
        const filteredAlerts = flattenedAlerts.map(
            (filteredAlert: IAlertSetting) => ({
                ...streamlineAlertSetting(filteredAlert),
                ...payload,
            })
        )
        return await _bulkUpdate(filteredAlerts as AlertSettingInput[])
    }
    const bulkUpdateStatus = (active: boolean) =>
        bulkUpdate({
            status: active ? "active" : "inactive",
        }).finally(() => {
            queryClient.invalidateQueries(["riskProfiles", selectedAccount])
        })

    const bulkUpdateSchedule = (startDate?: string, endDate?: string) => {
        if (!!startDate === !!endDate) {
            bulkUpdate(
                {
                    start_date: startDate || "",
                    end_date: endDate || "",
                },
                true
            ).finally(() => {
                queryClient.invalidateQueries(["riskProfiles", selectedAccount])
                setIsBulkUpdatingSchedule(false)
            })
        }
    }
    const bulk = {
        activate: () => bulkUpdateStatus(true),
        deactivate: () => bulkUpdateStatus(false),
        clearSchedule: () => bulkUpdateSchedule(undefined, undefined),
        setSchedule: bulkUpdateSchedule,
    }
    const selectedCount = Object.keys(rowSelection).length
    /* UTILS < END */
    return (
        <div>
            {isLoading && (
                <div className="absolute left-0 loading-screen__loader" />
            )}
            <div className="flex flex-col flex-nowrap p-[1.6em] overflow-y-auto">
                {/* BREADCRUMB > START */}
                <div className="flex flex-row items-center text-sm font-roboto">
                    <Link
                        className="text-gray-60"
                        to="/admin/risk-profiles/">
                        {t("riskProfiles", "Risk Profiles")}
                    </Link>
                    <div className="w-5 h-5 m-1 -rotate-90 fill-gray-60">
                        <ChevronIcon />
                    </div>
                    {t("alertSettingsLabel", "Alert Settings")}
                </div>
                {/* BREADCRUMB < END */}
                {/* HEADER > START */}
                <div className="flex flex-row justify-between mb-3 flex-nowrap">
                    {/* TITLE > START */}
                    <div className="flex flex-col flex-nowrap">
                        <div className="flex flex-row flex-wrap items-center gap-2">
                            <h1 className="title-lg">{riskProfile?.name}</h1>
                            <div className="flex flex-wrap gap-1 shrink">
                                {riskProfile?.labels.map(
                                    (label: ILabel) => (
                                        <span key={label.id}>
                                            <LabelItem label={label} />
                                        </span>
                                    )
                                )}
                            </div>
                        </div>
                        <span className="flex flex-col body-md text-gray-60">
                            {riskProfile?.hazard_profiles.map(
                                (hazardProfile: IHazardProfile) => (
                                    <span key={hazardProfile.id}>
                                        <ThresholdExtract
                                            hazardProfile={hazardProfile}
                                            opts={{
                                                detailed: true,
                                            }}
                                            riskType={riskProfile.type}
                                        />
                                    </span>
                                )
                            )}
                        </span>
                    </div>
                    {/* TITLE < END */}
                    {/* CONTROLS > START */}
                    <div className="flex flex-row ml-1">
                        {/* CONTROLS > ADD BUTTON > START */}
                        <span className="w-48 mr-1">
                            <DebounceSearchInput
                                placeholder={t(
                                    "searchByLocation",
                                    "Search by location"
                                )}
                                onSearch={(term) => setSearchTerm(term)}
                            />
                        </span>
                        {!isStageManagerEnabled && (  
                            <Button
                            icon={<PlusIcon />}
                            label={t("addLocations", "Add Locations")}
                            onClick={() => setSelectingLocations(true)}
                            />
                        )}
                        {/* CONTROLS > ADD BUTTON < END */}
                    </div>
                    {/* CONTROLS < END */}
                </div>
                {/* HEADER < END */}
                {/* FILTERS > START */}
                <div className="flex flex-row gap-2">
                    <MultiSelectFilter
                        filterName={t("region")}
                        leftRightClass="left-0"
                        options={regionOpts.current ?? {}}
                        placeholder={t("regions", "Regions")}
                        searchPlaceholder={t("search")}
                        setSelected={setRegionFilter}
                        selected={regionFilter}
                        canSearch
                        showSelectedOnTop
                    />
                    <MultiSelectFilter
                        filterName={t("status")}
                        leftRightClass="left-0"
                        options={{
                            active: t("active", "Active"),
                            inactive: t("inactive", "Inactive"),
                        }}
                        placeholder={t("status", "Status")}
                        searchPlaceholder={t("search")}
                        setSelected={setStatusFilter}
                        selected={statusFilter}
                        canSearch
                        showSelectedOnTop
                    />
                    <MultiSelectFilter
                        filterName={t("label")}
                        leftRightClass="left-0"
                        options={labelsOpts.current ?? {}}
                        placeholder={t("labels", "Labels")}
                        searchPlaceholder={t("search")}
                        setSelected={setLocationLabelsFilter}
                        selected={locationLabelsFilter}
                        canSearch
                        showSelectedOnTop
                    />
                </div>
                {/* FILTERS < END */}
                {/* TABLE > START */}
                <div>
                    {/* TABLE CONTROLS > START */}
                    <div className="flex flex-row my-2">
                        {/* TABLE CONTROLS > BULK CONTROLS > START */}
                        {selectedCount > 0 && (
                            <div className="flex flex-row gap-2">
                                <div className="px-4 py-2 font-medium rounded bg-gray-5 text-gray-90">
                                    {selectedCount} {t("selected", "Selected")}
                                </div>
                                <Button
                                    label={t("bulkActivate", "Bulk Activate")}
                                    type="secondary"
                                    onClick={bulk.activate}
                                />
                                <Button
                                    label={t(
                                        "bulkDeactivate",
                                        "Bulk Deactivate"
                                    )}
                                    type="secondary"
                                    onClick={bulk.deactivate}
                                />
                                <span>
                                    {isBulkUpdatingSchedule && (
                                        <span
                                            ref={bulkDatePicker}
                                            className="fixed mt-12 z-full">
                                            <DatePicker
                                                range
                                                initialDate={
                                                    bulkUpdateDateRange.startDate
                                                }
                                                initialEndDate={
                                                    bulkUpdateDateRange.endDate
                                                }
                                                onChange={(
                                                    startDate,
                                                    endDate
                                                ) => {
                                                    setBulkUpdateDateRange({
                                                        startDate,
                                                        endDate,
                                                    })
                                                    bulk.setSchedule(
                                                        startDate?.toFormat(
                                                            INPUT_DATE_FORMAT
                                                        ),
                                                        endDate?.toFormat(
                                                            INPUT_DATE_FORMAT
                                                        )
                                                    )
                                                }}
                                            />
                                        </span>
                                    )}
                                    <Button
                                        label={t(
                                            "bulkSetSchedule",
                                            "Bulk Set Schedule"
                                        )}
                                        type="secondary"
                                        onClick={() =>
                                            setIsBulkUpdatingSchedule(true)
                                        }
                                    />
                                </span>
                                <Button
                                    label={t(
                                        "bulkClearSchedule",
                                        "Bulk Clear Schedule"
                                    )}
                                    type="secondary"
                                    onClick={bulk.clearSchedule}
                                />
                            </div>
                        )}
                        {/* TABLE CONTROLS > BULK CONTROLS < END */}
                    </div>
                    {/* TABLE CONTROLS < END */}
                    {/* TABLE BODY > START */}
                    <div className="h-[70vh] overflow-y-visible">
                        <Table<IAugmentedAlertSetting>
                            setSorting={setSorting}
                            data={alertSettings}
                            columns={columns}
                            getRowId={(orig) => orig.id}
                            state={{
                                rowSelection,
                                globalFilter: "",
                                hiddenColumns: [],
                                sorting,
                            }}
                            paginationOptions={pageSizeOpts}
                            setRowSelection={setRowSelection}
                            noDataMessage={t(
                                "noAlertsConfiguredForThisRisk",
                                "No alerts configured for this risk"
                            )}
                            outOfText={t("of", "of")}
                        />
                    </div>
                    {/* TABLE BODY < END */}
                </div>
                {/* TABLE < END */}
                {/* MODALS > START */}
                <LocationSelectionModal
                    riskProfileId={riskProfileId}
                    open={isSelectingLocations}
                    onClose={() => {
                        setSelectingLocations(false)
                        resetTable()
                    }}
                />
                {/* MODALS < END */}
            </div>
        </div>
    )
}
