import { Dispatch, SetStateAction, useMemo, useEffect, useContext } from "react"
import { useTranslate } from "@tolgee/react"

/* COMPONENTS */
import { SingleSelectFilter } from "../../../../climateui/components"
import Divider from "./Divider"

/* TYPES */
import { IHazardVariable } from "../../../../types"
import NumberInput from "../../../../components/NumberInput"
import { ModalContext } from "../../../../climateui/providers"
import { SingleDropdownSelect } from "../../../../climateui/components/Inputs"
import { useRiskProfiles } from "../../../../providers/RiskProfilesProvider"
import {CancelIcon, PlusIcon} from "../../../../climateui/icons";

export type ConditionForm = {
    hazard_variable_id: string
    conditional: string
    type: string
    threshold?: number
    window?: number
    aggregation: string
}

export const templateCondition = {
    hazard_variable_id: "",
    conditional: "",
    type: "",
    threshold: 0,
    window: 1,
    aggregation: "",
}

const MAX_THRESHOLDS = 5

const DSCondition = ({
    id,
    groupId,
    conditions,
    updateConditions,
    form,
    hazardVariables,
    canEditAll = true,
}: {
    id: number
    groupId: number
    conditions: ConditionForm[][]
    updateConditions: Dispatch<SetStateAction<ConditionForm[][]>>
    form?: ConditionForm
    hazardVariables?: IHazardVariable[]
    canEditAll?: boolean
}) => {
    // Tolgee
    const { t } = useTranslate()
    /* OPTIONS */
    const options = {
        comparison: {
            ">": t("above", "Above"),
            "<": t("below", "Below"),
        },
        recurrence: {
            consecutive: t("consecutiveDays", "Consecutive days"),
        },
    }

    const { confirmationModal } = useContext(ModalContext)
    const { setSelectedHazard } = useRiskProfiles()

    /* HAZARD VARIABLES */
    const hazardVariablesOpts = useMemo<Record<string, string>>(() => {
        if (!hazardVariables) return {}
        return hazardVariables.reduce((prev, variable) => {
            if (
                id > 0 &&
                !!conditions[groupId][0].hazard_variable_id &&
                conditions[groupId][0].aggregation !== variable.aggregation
            )
                return prev
            return {
                ...prev,
                [variable.id]: variable.readable_name,
            }
        }, {})
    }, [hazardVariables, conditions])

    const hazardVariableAggregation = useMemo(() => {
        if (!hazardVariables) return {}
        return hazardVariables.reduce(
            (prev, variable) => ({
                ...prev,
                [variable.id]: variable.aggregation,
            }),
            {} as Record<string, string>
        )
    }, [hazardVariables])

    const updateForm = (form: ConditionForm) => {
        const tmpConditions = [...conditions]
        tmpConditions[groupId][id] = { ...form }
        updateConditions(tmpConditions)
    }

    const addCondition = () => {
        const tmpConditions = [...conditions]
        tmpConditions[groupId].splice(id + 1, 0, { ...templateCondition })
        updateConditions(tmpConditions)
    }

    const removeCondition = () => {
        const tmpConditions = [...conditions]
        tmpConditions[groupId].splice(id, 1)
        updateConditions(tmpConditions)
    }

    const clearConditions = () => {
        const tmpConditions = [...conditions]
        tmpConditions[groupId].splice(0, tmpConditions[groupId].length - 1)
        updateConditions(tmpConditions)
    }

    /* MEMO > START */
    const isConsecutive = useMemo(
        () => form?.aggregation === "consecutive",
        [form?.aggregation]
    )
    /* MEMO < END */

    useEffect(() => {
        /* INIT FORM */
        if (!form || hazardVariablesOpts[form.hazard_variable_id]) return
        const firstHazardVariableId = Object.keys(hazardVariablesOpts)[0]
        updateForm({
            ...form,
            hazard_variable_id: firstHazardVariableId,
            type: form.type || "absolute",
            conditional: form.conditional || ">",
            aggregation: hazardVariableAggregation[firstHazardVariableId],
        })
    }, [conditions, form, hazardVariableAggregation, hazardVariables])

    if (!form) return <></>

    const updateFormHazardVariable = (selected?: string) => {
        if (!selected) return
        updateForm({
            ...form,
            hazard_variable_id: selected,
            aggregation: hazardVariableAggregation[selected],
        })
    }
    const name = `${t("SmartThreshold", "Smart Threshold")}`
    const handleSelectChange = (selected: any) => {
        if (
            conditions[groupId].length === 1 ||
            conditions[groupId][0].aggregation ===
                hazardVariableAggregation[selected]
        ) {
            updateFormHazardVariable(selected)
        } else {
            confirmationModal({
                title: t(
                    "changeAggregationTypeTitle",
                    "Are you sure you want to choose this variable?"
                ),
                text: t(
                    "changeAggregationTypeBody",
                    "Since it uses a different type of aggregation it will clear your other thresholds and impact functions."
                ),
                onContinue: () => {
                    clearConditions()
                    updateFormHazardVariable(selected)
                },
            })
        }
    }

    useEffect(() => {
        if (form.hazard_variable_id) {
            setSelectedHazard?.(form.hazard_variable_id)
        }
    }, [form.hazard_variable_id, setSelectedHazard])
    return (
        <div>
            {id > 0 && <Divider text="and" />}
            <span className="pb-1">{name}</span>
            <div className="flex flex-row px-2 my-1 border rounded border-gray-10">
                <div className="flex flex-col py-1">
                    <div className="relative flex flex-row flex-wrap items-center font-normal body-md text-gray-90">
                        {t("when")}
                        <span className="m-1">
                            <SingleDropdownSelect
                                options={hazardVariablesOpts}
                                selected={form.hazard_variable_id}
                                setSelected={handleSelectChange}
                                disabled={!canEditAll}
                            />
                        </span>
                        {t("isConsiderably", "is considerably")}
                        <span className="flex flex-row m-1">
                            <SingleSelectFilter
                                disabled={!canEditAll}
                                selected={form.conditional}
                                setSelected={(selected) =>
                                    updateForm({
                                        ...form,
                                        conditional: selected,
                                    })
                                }
                                options={options.comparison}
                            />
                        </span>
                        {t(
                            "locationHistoricalAverage",
                            "a location's historical average for"
                        )}
                        <div className="flex flex-row items-stretch h-8 m-1"></div>
                        <span className="flex flex-row items-center m-1">
                            <NumberInput
                                initialValue={form.window}
                                min={1}
                                isInteger
                                onChange={(val) => {
                                    updateForm({
                                        ...form,
                                        window: val,
                                    })
                                }}
                            />
                        </span>
                        {isConsecutive
                            ? t(
                                  "daysConsecutiveLikelyhood",
                                  "consecutive day(s) with high likelyhood."
                              )
                            : t(
                                  "daysRollingWindowLikelyhood",
                                  "day(s) with high likelyhood."
                              )}
                    </div>
                </div>
                {id > 0 && (
                    <div
                        className="relative cursor-pointer w-7 h-7 fill-gray-60"
                        onClick={() => removeCondition()}>
                        <CancelIcon />
                    </div>
                )}
            </div>
            {conditions[groupId].length < MAX_THRESHOLDS &&
                id === conditions[groupId].length - 1 && (
                    <div
                        id="hazard-condition-and-button"
                        role="button"
                        onClick={() => addCondition()}
                        className="flex flex-row items-center cursor-pointer text-accent">
                        <span className="w-6 h-6 fill-accent">
                            <PlusIcon />
                        </span>
                        {t("and", "And")}
                    </div>
                )}
        </div>
    )
}
export default DSCondition
