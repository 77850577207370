import { DASHBOARD_VARIABLES, DATA_SOURCE_VIZUALIZATION_OPTIONS, IPlotModelArea, IPlotModelCandle, IPlotModelGeneric, IPlotModelLine, } from "./types"

/* Data source typeguards */
export function isLineDataSource(dataSource: IPlotModelGeneric): dataSource is IPlotModelLine {
    return dataSource.visualization === DATA_SOURCE_VIZUALIZATION_OPTIONS.LINE
}

export function isAreaDataSource(dataSource: IPlotModelGeneric): dataSource is IPlotModelArea {
    return dataSource.visualization === DATA_SOURCE_VIZUALIZATION_OPTIONS.AREA
}

export function isCandleDataSource(dataSource: IPlotModelGeneric): dataSource is IPlotModelCandle {
    return dataSource.visualization === DATA_SOURCE_VIZUALIZATION_OPTIONS.CANDLE
}

/* Helpers */
export const getDashboardVariableEnumValue = (value: string): DASHBOARD_VARIABLES => {
    const dashboardVariables = Object.values(DASHBOARD_VARIABLES)
    const variableMatch = dashboardVariables.find(enumValue => enumValue === value)
    if (variableMatch === undefined) {
        throw new Error(`value ${value} is not part of the enum DASHBOARD_VARIABLES. Error found inside getDashboardVariableEnumValue().`)
    }
    return variableMatch as DASHBOARD_VARIABLES
}
