import { gql } from "graphql-request"
import WidgetWrapper from "../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import ExpectedChangeWidget from "../../views/Climate/YieldOutlook/components/widgetLibrary/ExpectedChangeWidget"
import { IYieldOutlookWidgetProps } from "./types"

const queries = {
    adapt: gql`
        query (
            $decade: Int
            $scenario: String
            $region_ids: [String]
            $asset_ids: [String]
        ) {
            yield_outlook_model(
                filter: { region_ids: $region_ids, asset_ids: $asset_ids }
            ) {
                results {
                    stats: decadal_stats(
                        filter: { scenario: $scenario, decade: $decade }
                    ) {
                        results {
                            scenario
                            decade
                            outlook_mean
                            deviation_mean
                        }
                    }
                }
            }
        }
    `,
    monitor: gql`
        query (
            $country_id: String
            $asset_ids: [String]
            $init_time: String
            $units: String
        ) {
            yield_outlook_model(
                filter: {
                    region_ids: [$country_id]
                    asset_ids: $asset_ids
                    seasonal_status: "active"
                }
            ) {
                results {
                    stats: seasonal_stats(
                        filter: { init_time: $init_time, units: $units }
                    ) {
                        results {
                            outlook_mean
                            deviation_mean
                        }
                    }
                }
            }
        }
    `,
}
const YieldOutlookChange = ({
    filters,
    selectors,
    type,
}: IYieldOutlookWidgetProps) => {
    return (
        <WidgetWrapper
            selectedFilters={[
                "decade",
                "scenario",
                "region_ids",
                "asset_ids",
                "country_id",
                "asset_ids",
                "init_time",
                "units",
            ]}
            component={ExpectedChangeWidget}
            query={queries[type]}
            filters={filters}
            selectors={selectors}
        />
    )
}
export default YieldOutlookChange
