import { useTranslate } from "@tolgee/react"
import {
    Button,
    Checkbox,
    LabelAndInput,
    MonthRangePicker,
    Slider,
} from "../../../../climateui/components"
import EmptyModal from "../../../../climateui/providers/Modal/EmptyModal"
import { IBooleanDictionary } from "../../../../climateui/types"
import { genericFormatDecades } from "../../../../climateui/components/Inputs/SliderSelectInput"
import { useLocale } from "../../../../providers"
import { capitalizeFirstCharacter } from "../../../../utils/wordHelper"
import { useUnits } from "../../../../providers/UnitConversionProvider"
import { CancelIcon, LocationPinIcon } from "../../../../climateui/icons"
import {
    MultiDropdownSelect,
    SingleDropdownSelect,
} from "../../../../climateui/components/Inputs"
import { ReactNode } from "react"
import { LegacyMultiSelectLikeToggler } from "../../../../climateui/components/Inputs/DropdownSelect/togglers"

interface IAdvancedSearchModalProps {
    showAdvancedMode: boolean
    advancedModeModalRef: React.LegacyRef<HTMLDivElement> | undefined
    setShowAdvancedMode: React.Dispatch<React.SetStateAction<boolean>>
    monthsRange: (number | null)[]
    setMonthsRange: React.Dispatch<React.SetStateAction<(number | null)[]>>
    locationId: string | null | undefined
    setLocationId: React.Dispatch<
        React.SetStateAction<string | null | undefined>
    >
    regions: IBooleanDictionary
    setRegions: React.Dispatch<React.SetStateAction<IBooleanDictionary>>
    scenario: string | null | undefined
    setScenario: React.Dispatch<React.SetStateAction<string | null | undefined>>
    variables: IBooleanDictionary
    setVariables: React.Dispatch<React.SetStateAction<IBooleanDictionary>>
    referenceDecades: number[]
    setReferenceDecades: React.Dispatch<React.SetStateAction<number[]>>
    discoveryDecades: number[]
    setDiscoveryDecades: React.Dispatch<React.SetStateAction<number[]>>
    exclusionRadius: string | null | undefined
    setExclusionRadius: React.Dispatch<
        React.SetStateAction<string | null | undefined>
    >
    canSubmit: boolean
    defaultChanges: boolean
    allVariablesCheckboxStatus: string
    DECADE_OPTIONS: number[]
    REGION_OPTIONS: Record<string, string>
    LOCATION_OPTIONS: Record<string, string>
    SCENARIO_OPTIONS: Record<string, string>
    VARIABLE_OPTIONS: Record<string, string>
    EXCLUSION_OPTIONS: Record<string, string>
    VARIABLE_ICONS: Record<string, ReactNode>
    resetToDefault: () => void
    buildAnalogRequest: () => void
}

function AdvancedSearchModal({
    showAdvancedMode,
    advancedModeModalRef,
    setShowAdvancedMode,
    monthsRange,
    setMonthsRange,
    locationId,
    setLocationId,
    regions,
    setRegions,
    scenario,
    setScenario,
    variables,
    setVariables,
    referenceDecades,
    setReferenceDecades,
    discoveryDecades,
    setDiscoveryDecades,
    exclusionRadius,
    setExclusionRadius,
    canSubmit,
    defaultChanges,
    allVariablesCheckboxStatus,
    REGION_OPTIONS,
    LOCATION_OPTIONS,
    SCENARIO_OPTIONS,
    VARIABLE_OPTIONS,
    DECADE_OPTIONS,
    EXCLUSION_OPTIONS,
    VARIABLE_ICONS,
    resetToDefault,
    buildAnalogRequest,
}: IAdvancedSearchModalProps) {
    const { t } = useTranslate()
    const { monthNames } = useLocale()
    const { unitName } = useUnits()

    return (
        <EmptyModal
            customClasses="relative rounded-lg bg-white w-[652px] h-fit"
            open={showAdvancedMode}
            modalRef={advancedModeModalRef}>
            <div className="flex flex-col h-full">
                <div className="h-[52px] flex flex-row justify-between items-center pl-5 pr-4">
                    <h2 className="title-sm">
                        {t("advancedSearch", "Advanced Search")}
                    </h2>
                    <span
                        className="w-5 h-5 cursor-pointer fill-gray-60 hover:fill-red"
                        onClick={() => setShowAdvancedMode(false)}>
                        <CancelIcon></CancelIcon>
                    </span>
                </div>

                <div className="flex flex-col p-5 grow gap-4 border-y border-gray-14">
                    <h4 className="font-medium label-lg">
                        {t("climateReference", "Climate Reference")}
                    </h4>

                    {/* LOC, REG, SCENARIO -------------------------------------------- */}
                    <div
                        className={[
                            "flex flex-row items-center gap-2",
                            "[&_.ddBox]:h-[42px]",
                            "[&_.ddBox]:text-left",
                            "[&_.ddBox]:w-full",
                            "[&_.ddBox]:rounded-md", // ddBox to style inner DropdownSelects
                        ].join(" ")}>
                        <LabelAndInput
                            extraClasses="w-1/3"
                            label={t("similarTo", "Similar to")}
                            input={
                                <SingleDropdownSelect
                                    options={LOCATION_OPTIONS}
                                    selected={locationId}
                                    setSelected={setLocationId}
                                    leadingIcon={
                                        locationId === "" ? undefined : (
                                            <LocationPinIcon />
                                        )
                                    }
                                    placeholder={t("select", "Select")}
                                />
                            }
                        />
                        <LabelAndInput
                            extraClasses="w-1/3"
                            label={t("inRegion", "In region")}
                            input={
                                <MultiDropdownSelect
                                    placeholder={t("global", "Global")}
                                    searchPlaceholder={t("search")}
                                    options={REGION_OPTIONS}
                                    selectAllOption={t("global", "Global")}
                                    selected={regions}
                                    setSelected={setRegions}
                                    customToggler={LegacyMultiSelectLikeToggler}
                                />
                            }
                        />
                        <LabelAndInput
                            extraClasses="w-1/3"
                            label={t("scenario", "Scenario")}
                            input={
                                <SingleDropdownSelect
                                    options={SCENARIO_OPTIONS}
                                    selected={scenario}
                                    setSelected={setScenario}
                                />
                            }
                        />
                    </div>

                    {/* VARIABLES -------------------------------------------- */}
                    <div className="grid grid-cols-3 gap-y-2">
                        <div
                            onClick={() => {
                                const varOptsArr = Object.keys(VARIABLE_OPTIONS)
                                if (
                                    varOptsArr.length ===
                                    Object.keys(variables).length
                                )
                                    setVariables({})
                                else {
                                    const newSelected: IBooleanDictionary =
                                        varOptsArr.reduce(
                                            (acc, curr) => ({
                                                ...acc,
                                                [curr]: true,
                                            }),
                                            {} as IBooleanDictionary
                                        )
                                    setVariables(newSelected)
                                }
                            }}
                            className={[
                                "flex flex-row gap-1 items-center",
                                "cursor-pointer",
                                "col-span-3",
                            ].join(" ")}>
                            <span className="w-7 h-7">
                                <Checkbox
                                    extraWrapperClasses=""
                                    status={allVariablesCheckboxStatus}
                                    onChange={() => null}
                                />
                            </span>
                            <div className="flex flex-row items-center ml-2 gap-2">
                                <label className="pointer-events-none body-lg">
                                    {t("allVariables", "All Variables")}
                                </label>
                            </div>
                        </div>
                        {Object.keys(VARIABLE_OPTIONS).map((key) => (
                            <div
                                key={key}
                                onClick={() => {
                                    const newSelectedVariables = {
                                        ...variables,
                                    }
                                    if (!variables[key]) {
                                        newSelectedVariables[key] = true
                                    } else {
                                        delete newSelectedVariables[key]
                                    }
                                    setVariables(newSelectedVariables)
                                }}
                                className={[
                                    "flex flex-row gap-1 items-center",
                                    "cursor-pointer",
                                    key === "0" ? "col-span-3" : "",
                                ].join(" ")}>
                                <span className="w-7 h-7">
                                    <Checkbox
                                        checked={variables[key] === true}
                                        onChange={() => null}
                                    />
                                </span>
                                <div className="flex flex-row items-center ml-2 gap-2">
                                    <span className="w-5 h-5 fill-gray-60 shrink-0">
                                        {VARIABLE_ICONS[key]}
                                    </span>
                                    <label className="pointer-events-none body-lg">
                                        {VARIABLE_OPTIONS[key]}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>

                    <h4 className="font-medium label-lg">
                        {t("timePeriod", "Time Period")}
                    </h4>

                    {/* REFERENCE DECADES -------------------------------------------- */}
                    <div className="h-16">
                        <LabelAndInput
                            extraClasses="w-full relative"
                            label={t(
                                "decadesOfReference",
                                "Decades of reference"
                            )}
                            input={
                                <>
                                    <Slider
                                        id="reference-decades-slider"
                                        selectedTopIndex={referenceDecades[1]}
                                        selectedBottomIndex={
                                            referenceDecades[0]
                                        }
                                        setSelectedIndexes={(
                                            indexes: number | number[]
                                        ) => {
                                            if (typeof indexes === "number")
                                                return
                                            setReferenceDecades(indexes)
                                        }}
                                        options={DECADE_OPTIONS}
                                        isDouble
                                    />
                                    <span className="absolute top-0 right-0 body-lg">
                                        {genericFormatDecades(
                                            DECADE_OPTIONS,
                                            referenceDecades
                                        )}
                                    </span>
                                </>
                            }
                        />
                    </div>

                    {/* MONTHS -------------------------------------------- */}
                    <MonthRangePicker
                        startMonthIndex={monthsRange[0]}
                        endMonthIndex={monthsRange[1]}
                        handleChange={setMonthsRange}
                        MONTH_NAMES={monthNames}
                        startMonthPlaceholder={
                            monthsRange[0] !== null &&
                            monthNames[monthsRange[0]]
                                ? monthNames[monthsRange[0]]
                                : t("start", "Start")
                        }
                        endMonthPlaceholder={
                            monthsRange[1] !== null &&
                            monthNames[monthsRange[1]]
                                ? monthNames[monthsRange[1]]
                                : t("end", "End")
                        }
                        prev={capitalizeFirstCharacter(t("from", "From"))}
                        mid={t("to", "to").toLowerCase()}
                    />

                    {/* DISCOVERY DECADES -------------------------------------------- */}
                    <div className="h-16">
                        <LabelAndInput
                            extraClasses="w-full relative"
                            label={t(
                                "decadesOfDiscovery",
                                "Decades of discovery"
                            )}
                            input={
                                <>
                                    <Slider
                                        id="discovery-decades-slider"
                                        selectedTopIndex={discoveryDecades[1]}
                                        selectedBottomIndex={
                                            discoveryDecades[0]
                                        }
                                        setSelectedIndexes={(
                                            indexes: number | number[]
                                        ) => {
                                            if (typeof indexes === "number")
                                                return
                                            setDiscoveryDecades(indexes)
                                        }}
                                        options={DECADE_OPTIONS}
                                        isDouble
                                    />
                                    <span className="absolute top-0 right-0 body-lg">
                                        {genericFormatDecades(
                                            DECADE_OPTIONS,
                                            discoveryDecades
                                        )}
                                    </span>
                                </>
                            }
                        />
                    </div>

                    {/* EXCLUSION RADIUS -------------------------------------------- */}
                    <div className="flex flex-row items-center gap-1">
                        <p className="body-md">
                            {t(
                                "excludeResultsWithin",
                                "Exclude results within"
                            )}
                        </p>
                        <div className="w-22">
                            <SingleDropdownSelect
                                options={EXCLUSION_OPTIONS}
                                selected={exclusionRadius}
                                setSelected={setExclusionRadius}
                            />
                        </div>
                        <p className="body-md">
                            {unitName === "metric"
                                ? t("kilometers", "Kilometers").toLowerCase()
                                : t("miles", "Miles").toLowerCase()}
                        </p>
                    </div>
                </div>

                <div className="flex flex-row items-center justify-between pl-5 pr-2 h-14">
                    <button
                        disabled={!defaultChanges}
                        onClick={resetToDefault}
                        className="label-sm link"
                        style={{ textDecorationLine: "unset" }}>
                        {t("resetToDefault", "Reset to default")}
                    </button>
                    <Button
                        disabled={!canSubmit}
                        label={t("search", "Search")}
                        onClick={buildAnalogRequest}></Button>
                </div>
            </div>
        </EmptyModal>
    )
}

export default AdvancedSearchModal
