import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react"
import {
    areResTypeAndDataValid,
    CustomResponse,
} from "../../../climateui/utils/http"
import { useAccount } from "../../../providers/AccountProvider"
import { useAssets } from "../../../providers/AssetsProvider"
import { useLocations } from "../../../providers/LocationsProvider"
import { useRiskProfiles } from "../../../providers/RiskProfilesProvider"
import { usePlanningTool } from "../../../providers/PlanningToolProvider"
import {
    prepopulatePlanPOST,
    planGET,
    planningToolTimelinePOST,
} from "../../../utils/networking"
import { useTranslate } from "@tolgee/react"
import {
    buildStrategies,
    buildStrategiesObjFromBE,
    mergePrepopulates,
    sortStrategies,
} from "./utils"
import { useAreAllFlagsEnabled, useMemoQuery } from "../../../hooks"
import _ from "lodash"
import {
    IPlan,
    IPlannedRisk,
    IStage,
    IStrategy,
} from "../../../types"
import { POLLING_DEFAULT_TIMEOUT } from "../../../utils/constants"
import { useLocation } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { EDITION_ACTIONS } from "../../../components/SeasonalCalendar/components/utils"
import { useToast } from "../../../climateui/providers/Toast/ToastContextProvider"
import { arrToDict } from "../../../utils/transform"

function formatDuplicatedStrategyWithCopyDescription(
    str: string,
    newCount: number
): string {
    // Extract the number from the string using a regular expression
    const match = str.match(/\d+/)
    if (match) {
        const prevCount = parseInt(match[0])
        // Replace occurrences of number prevCount with number newCount in the string
        return str.replace(new RegExp(String(prevCount), "g"), String(newCount))
    }
    return str
}
function formatDuplicatedStrategyDescription(text: string): string {
    const match = text.match(/^(.*?)\s*\(\d+\)\s*(.*?)$/)
    if (match && match[1]) {
        match.shift()
        return match
            .reduce((acc, curr) => {
                if (curr === "") return acc
                return [...acc, curr]
            }, [] as string[])
            .join(", ")
    }
    return text
}

export interface IPlanContext {
    performPrepopulation: () => Promise<void>
    modifiableStrategiesObj: Record<string, IStrategy>
    originalStrategiesObj: Record<string, IStrategy>
    setModifiableStrategiesObj: (
        strategiesObj: Record<string, IStrategy>
    ) => void
    setLocationAssetIdStrategyMap: (
        locationAssetIdStrategyMap: Record<string, string[]>
    ) => void
    locationAssetIdStrategyMap: Record<string, string[]>
    isEditingPlan: boolean
    workingPlan?: IPlan
    setWorkingPlan: React.Dispatch<
        React.SetStateAction<IPlan | undefined>
    >
    deleteStageOrRisk: (
        stageOrRisk: IStage | IPlannedRisk,
        type: "stages" | "risks"
    ) => void
    deletedStagesOrRisks: (IStage | IPlannedRisk)[]
    recalculatePlanData: () => void
    deleteStrategy: (strategy: IStrategy) => void
    duplicateStrategy: (strategy: IStrategy) => void
    isStageManagerEnabled: boolean
}

const PlanContext = createContext({} as IPlanContext)
export const usePlan = () => useContext(PlanContext)

function PlanProvider({
    children,
    isEditingPlan = true,
}: {
    children: ReactNode
    isEditingPlan?: boolean
}) {
    const { t } = useTranslate()
    const { activePlanId, goToStep } = usePlanningTool()
    const { selectedAccount } = useAccount()
    const { locationsObj } = useLocations()
    const { riskProfilesObj } = useRiskProfiles()
    const { varieties } = useAssets()
    const { enqueueAlert } = useToast()

    const [workingPlan, setWorkingPlan] = useState<IPlan>()

    const [modifiableStrategiesObj, setModifiableSortedStrategiesObj] =
        useState<Record<string, IStrategy>>({})
    const [locationAssetIdStrategyMap, setLocationAssetIdStrategyMap] =
        useState<Record<string, string[]>>({})
    const [originalStrategiesObj, setOriginalStrategiesObj] = useState<
        Record<string, IStrategy>
    >({})
    // Array of deleted stages or risks for the plan PUT
    const [deletedStagesOrRisks, setDeletedStagesOrRisks] = useState<
        (IStage | IPlannedRisk)[]
    >([])

    const isStageManagerEnabled = useAreAllFlagsEnabled([
        "feature_stage_manager",
        "ops_stage_manager_are_varieties_migrated",
    ])

    useEffect(() => {
        setWorkingPlan(undefined)
        setOriginalStrategiesObj({})
    }, [activePlanId])

    const [plan, { refetch: refetchPlan }] = useMemoQuery<IPlan>(
        ["plan", activePlanId],
        () => planGET(activePlanId),
        {
            enabled: !!activePlanId,
        },
        undefined,
        {}
    )

    const setModifiableStrategiesObj = (
        strategiesObj: Record<string, IStrategy>
    ) => {
        setModifiableSortedStrategiesObj(sortStrategies(strategiesObj))
    }

    const location = useLocation()

    useEffect(() => {
        const pathName = location.pathname.split("/")
        if (
            !pathName.includes("new") &&
            !pathName.includes("edit") &&
            !_.isEqual(originalStrategiesObj, modifiableStrategiesObj)
        )
            setModifiableStrategiesObj(_.cloneDeep(originalStrategiesObj))
    }, [location])

    useEffect(() => {
        setModifiableStrategiesObj(_.cloneDeep(originalStrategiesObj))
    }, [originalStrategiesObj])

    const sameLocationAssetCount = useMemo(() => {
        const sameLocationAssetCount: Record<string, number> = {}
        Object.values(modifiableStrategiesObj).forEach((strategy) => {
            if (strategy.location_id && strategy.asset_variety_id) {
                const key = `${strategy.location_id}-${strategy.asset_variety_id}`
                sameLocationAssetCount[key] =
                    (sameLocationAssetCount[key] ?? 0) + 1
            }
        })
        return sameLocationAssetCount
    }, [modifiableStrategiesObj])

    const recalculatePlanData = () => {
        const {
            newStrategiesObj,
            newLocationAssetIdStrategyMap,
            newSelectedLocationsAssetVarieties,
        } = buildStrategiesObjFromBE(
            plan.strategies || [],
            riskProfilesObj,
            locationsObj,
            varieties || {}
        )

        setWorkingPlan({
            ...plan,
            selectedLocationsAssetVarieties: newSelectedLocationsAssetVarieties,
        })
        setOriginalStrategiesObj(sortStrategies(newStrategiesObj))
        setLocationAssetIdStrategyMap(newLocationAssetIdStrategyMap)
    }

    const [calculatingImpactRetries, setCalculatingImpactRetries] = useState(0)
    const [isCalculatingImpacts, setIsCalculatingImpacts] = useState(false)

    useEffect(() => {
        let cleaning = () => {
            return
        }

        const allImpacts = Object.values(originalStrategiesObj)
            .map((strategy) => {
                const planned_risks = strategy.planned_risks ?? []
                return planned_risks.map((planned_risk) => [
                    planned_risk.observed_impact,
                    planned_risk.expected_impact,
                ])
            })
            .flat(2)

        let missingImpacts = 0
        allImpacts.forEach((impact) => {
            if (impact === undefined) {
                missingImpacts += 1
            }
        })

        if (missingImpacts !== 0) {
            setIsCalculatingImpacts(() => true)

            const timeout = setTimeout(
                () => {
                    if (calculatingImpactRetries === 0 && !isEditingPlan) {
                        enqueueAlert(
                            t(
                                "calculatingImpactsWait",
                                "We are calculating your plan impacts. Please wait."
                            )
                        )
                    }
                    setCalculatingImpactRetries((prev) => prev + 1)
                    if (!isEditingPlan) {
                        refetchPlan()
                    }
                },
                Math.min(POLLING_DEFAULT_TIMEOUT * 2, missingImpacts * 1000)
            )
            // 2s wait time for each missing risk impacts (observed and expected) / top 8s
            // small plans will retreive data faster and big plans will wait a little longer

            cleaning = () => clearTimeout(timeout)
        } else {
            if (isCalculatingImpacts && calculatingImpactRetries > 0) {
                enqueueAlert(
                    t("calculatingImpactsDone", "Finished calculating impacts.")
                )
                setIsCalculatingImpacts(() => false)
            }
        }

        return () => cleaning()
    }, [originalStrategiesObj, calculatingImpactRetries])

    useEffect(() => {
        if (
            plan &&
            !_.isEmpty(plan) &&
            riskProfilesObj &&
            locationsObj &&
            !_.isEmpty(locationsObj) &&
            varieties &&
            !_.isEmpty(varieties)
        ) {
            recalculatePlanData()
        }
    }, [plan, riskProfilesObj, locationsObj, varieties])

    const prepopulateFromStageManager = (
        auxStrategiesObj: Record<string, IStrategy>
    ) => {
        const varietyIdsToQuery = new Set<string>()
        const result: IStrategy[] = []

        Object.values(auxStrategiesObj).forEach(
            (strategy: IStrategy) => {
                if (strategy.asset_variety_id)
                    varietyIdsToQuery.add(strategy.asset_variety_id)
                result.push({
                    asset_id: strategy.asset_id,
                    region_id: strategy.region_id,
                    location_id: strategy.location_id,
                    asset_variety_id: strategy.asset_variety_id,
                })
            }
        )

        return planningToolTimelinePOST(Array.from(varietyIdsToQuery)).then(
            (res) => {
                if (!areResTypeAndDataValid(res) || !res) {
                    // TODO: Show error
                    return
                }

                const data = (res as CustomResponse).data || []
                const dataObj = arrToDict<IStrategy>(data, "variety_id")

                result.forEach((strategy) => {
                    if (strategy.asset_variety_id) {
                        const dataForVariety =
                            dataObj[strategy.asset_variety_id]
                        if (dataForVariety) {
                            const copyStagesArray: IStage[] = []
                            dataForVariety.stages?.forEach((stage) => {
                                copyStagesArray.push({
                                    ...stage,
                                })
                            })
                            strategy.stages = copyStagesArray
                        }
                    }
                })

                return result
            }
        )
    }

    const performPrepopulation = async () => {
        const {
            newStrategiesObj: auxStrategiesObj,
            newLocationAssetIdStrategyMap,
        } = buildStrategies(
            workingPlan?.selectedLocationsAssetVarieties || {},
            modifiableStrategiesObj,
            locationAssetIdStrategyMap,
            locationsObj,
            riskProfilesObj,
            varieties || {}
        )

        let data: IStrategy[] = []

        if (isStageManagerEnabled) {
            data = (await prepopulateFromStageManager(auxStrategiesObj)) || []
        } else {
            const res = await prepopulatePlanPOST({
                account_id: selectedAccount,
                strategies: Object.values(auxStrategiesObj),
            })

            if (!areResTypeAndDataValid(res)) return // TODO: Show error
            data = (res as CustomResponse).data || []
        }

        const newStrategiesObj = mergePrepopulates(
            data,
            auxStrategiesObj,
            newLocationAssetIdStrategyMap,
            riskProfilesObj
        )
        setModifiableStrategiesObj(newStrategiesObj)
        setLocationAssetIdStrategyMap(newLocationAssetIdStrategyMap)
        goToStep("stages-risks")
    }

    // Function to keep track of deleted stages or risks
    // for the strategy PUT and the action flags
    const deleteStageOrRisk = (
        stageOrRisk: IStage | IPlannedRisk,
        type: "stages" | "risks"
    ) => {
        if (stageOrRisk.action === EDITION_ACTIONS.added) return
        const newDeletedThings = [...deletedStagesOrRisks]
        newDeletedThings.push({ ...stageOrRisk, type })
        setDeletedStagesOrRisks(newDeletedThings)
    }

    const deleteStrategy = (strategy: IStrategy) => {
        const newModifiableStrategiesObj = _.cloneDeep(modifiableStrategiesObj)
        const strategyId = strategy.id ?? ""
        delete newModifiableStrategiesObj[strategyId]

        const locationId = strategy.location_id ?? ""
        const assetVarietyId = strategy.asset_variety_id ?? ""
        const locationAssetId = locationId + assetVarietyId

        const newLocationAssetIdStrategyMap = _.cloneDeep(
            locationAssetIdStrategyMap
        )
        const index =
            newLocationAssetIdStrategyMap[locationAssetId].indexOf(strategyId)
        newLocationAssetIdStrategyMap[locationAssetId].splice(index, 1)

        if (newLocationAssetIdStrategyMap[locationAssetId].length === 0) {
            // locationAssetIdStrategyMap logic
            delete newLocationAssetIdStrategyMap[locationAssetId]

            // selectedLocationsAssetVarieties logic
            const newSelectedLocationAssetVarieties = {
                ...workingPlan?.selectedLocationsAssetVarieties,
            }
            delete newSelectedLocationAssetVarieties[locationId][assetVarietyId]
            if (
                Object.keys(newSelectedLocationAssetVarieties[locationId])
                    .length === 0
            ) {
                delete newSelectedLocationAssetVarieties[locationId]
            }
            setWorkingPlan({
                ...workingPlan,
                selectedLocationsAssetVarieties:
                    newSelectedLocationAssetVarieties,
            })
        }

        setModifiableStrategiesObj(newModifiableStrategiesObj)
        setLocationAssetIdStrategyMap(newLocationAssetIdStrategyMap)
    }

    const setDuplicatedStrategyDescription = (
        duplicated: IStrategy
    ) => {
        if (!duplicated.location_id || !duplicated.asset_variety_id) return

        const key = `${duplicated.location_id}-${duplicated.asset_variety_id}`
        if (duplicated.description) {
            let copyString = ""
            for (const c of t("copy", "Copy")) {
                // remove weird empty tolgee characters
                if (c.charCodeAt(0) < 127) copyString += c
            }
            if (duplicated.description.includes(copyString)) {
                duplicated.description =
                    formatDuplicatedStrategyWithCopyDescription(
                        duplicated.description,
                        sameLocationAssetCount[key]
                    )
            } else {
                duplicated.description =
                    formatDuplicatedStrategyDescription(
                        duplicated.description
                    ) + ` (${sameLocationAssetCount[key]})`
            }
        } else {
            duplicated.description = t("copyN", "Copy {N}", {
                N: sameLocationAssetCount[key],
            })
        }
    }

    const duplicateStrategy = (strategy: IStrategy) => {
        const strategyId = uuidv4()
        const newStrategy = _.cloneDeep(strategy)
        newStrategy.id = strategyId
        newStrategy.action = EDITION_ACTIONS.added

        setDuplicatedStrategyDescription(newStrategy)

        const oldNewStageIds: Record<string, string> = {}
        newStrategy.stages?.forEach((stage) => {
            stage.strategyId = strategyId
            const oldId = stage.id ?? ""
            stage.id = uuidv4()
            oldNewStageIds[oldId] = stage.id
            stage.action = EDITION_ACTIONS.added
        })
        newStrategy.planned_risks?.forEach((risk) => {
            risk.strategyId = strategyId
            risk.id = uuidv4()
            risk.frontend_id = uuidv4()
            risk.action = EDITION_ACTIONS.added
            if (risk.stage_id) risk.stage_id = oldNewStageIds[risk.stage_id]
            delete risk.expected_impact
            delete risk.expected_impact_time_series
            delete risk.observed_impact
            delete risk.observed_impact_time_series
            delete risk.location_name
        })

        const newModifiableStrategiesObj = { ...modifiableStrategiesObj }
        newModifiableStrategiesObj[strategyId] = newStrategy

        const locationAssetId =
            (strategy.location_id ?? "") + (strategy.asset_variety_id ?? "")
        const newLocationAssetIdStrategyMap = { ...locationAssetIdStrategyMap }
        newLocationAssetIdStrategyMap[locationAssetId].push(strategyId)

        setModifiableStrategiesObj(newModifiableStrategiesObj)
        setLocationAssetIdStrategyMap(newLocationAssetIdStrategyMap)
    }

    return (
        <PlanContext.Provider
            value={{
                performPrepopulation,
                deletedStagesOrRisks,
                deleteStageOrRisk,
                isEditingPlan,
                locationAssetIdStrategyMap,
                modifiableStrategiesObj,
                originalStrategiesObj,
                setLocationAssetIdStrategyMap,
                setModifiableStrategiesObj,
                setWorkingPlan,
                workingPlan,
                recalculatePlanData,
                deleteStrategy,
                duplicateStrategy,
                isStageManagerEnabled,
            }}>
            {children}
        </PlanContext.Provider>
    )
}

export default PlanProvider
