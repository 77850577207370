import { LocationPinIcon } from "../../../../climateui/icons"
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
} from "react"
import { SingleSelectFilter } from "../../../../climateui/components"
import { useTranslate } from "@tolgee/react"
import { useLocations } from "../../../../providers"
import _, { uniqueId } from "lodash"
import VariablesIconSelect from "../../../../components/VariablesIconSelect"
import { useCustomFlags } from "../../../../hooks"
import { StringParam, useQueryParam, withDefault } from "use-query-params"
import { BooleanDictionaryParam, StringSetArrayParam } from "./utils"
import { useAccount } from "../../../../providers/AccountProvider"

const DEFAULT_MAX_LOCATIONS = 2

const MIN_DECADE = 2010
const MAX_DECADE = 2060
const DECADE_STEP = 10
const decades = Array.from(
    { length: (MAX_DECADE - MIN_DECADE) / DECADE_STEP + 1 },
    (_, idx) => MIN_DECADE + DECADE_STEP * idx
)
const decadeOptions = decades.reduce(
    (prev: Record<string, string>, currDecade) => {
        prev[currDecade.toString()] = `${currDecade} - ${currDecade + 9}`
        return prev
    },
    {}
)

// TrendFilters
export interface ITrendFilters {
    selectedLocations: string[]
    selectedVariables: string[]
    selectedDecade: string
    selectedScenario: string
}
const TrendFilters = ({
    setFilters,
    filters,
}: {
    filters: ITrendFilters
    setFilters: Dispatch<SetStateAction<ITrendFilters>>
}) => {
    const { t } = useTranslate()
    const { selectedAccount, prevSelectedAccount } = useAccount()
    const {
        feature_climate_trends_comparable_locations: fsMaxComparableLocations,
    } = useCustomFlags(["feature_climate_trends_comparable_locations"])
    const MAX_COMPARED_LOCATIONS =
        (fsMaxComparableLocations.value as number) || DEFAULT_MAX_LOCATIONS

    // Locations Filter
    const { locations, locationsObj } = useLocations()
    // If the locations array is empty, return an empty element

    const LocationsParam = useMemo(
        () => ({
            ...StringSetArrayParam,
            decode: (params: string | (string | null)[] | null | undefined) => {
                const prelimArr = Array.from(StringSetArrayParam.decode(params))
                // Filter by locationsObj
                return new Set<string>(
                    prelimArr.filter((locID) => !!locationsObj[locID])
                )
            },
        }),
        [locationsObj]
    )
    const [selectedLocations, setSelectedLocations] = useQueryParam(
        "locations",
        LocationsParam
    )
    const selectedLocationsArr = useMemo(
        () => Array.from(selectedLocations),
        [selectedLocations]
    )
    const getLocationOpts = useCallback(
        (selectionIdx: number) => {
            const previouslySelected = new Set(
                selectedLocationsArr.slice(0, selectionIdx)
            )
            return locations.reduce(
                (prevOpts: Record<string, string>, currLoc) => {
                    // Exit if the location has no id
                    if (!currLoc.id || previouslySelected.has(currLoc.id))
                        return prevOpts
                    // Retrieve the region name
                    let region = currLoc.region?.name
                    // If the location has no `region` prop, try to get them from `regions`
                    if (!region && currLoc.regions) {
                        region = currLoc.regions
                            .map((region) => region.name)
                            .join(",")
                    }
                    // Set the string to the location name
                    prevOpts[currLoc.id] = currLoc.name
                    // If the location does have a region, concat it to the name
                    if (region) prevOpts[currLoc.id] += ` (${region})`
                    return prevOpts
                },
                {}
            )
        },
        [locations, selectedLocationsArr]
    )
    const smartSetSelectedLocations = (
        selected: string,
        selectionIdx: number
    ) => {
        setSelectedLocations((oldSelection) => {
            const oldSelectionArr = Array.from(oldSelection)
            const prevSelected = oldSelectionArr[selectionIdx]
            if (!selected) {
                const newSelection = new Set(oldSelectionArr)
                if (prevSelected) newSelection.delete(prevSelected)
                return newSelection
            }
            oldSelectionArr[selectionIdx] = selected
            return new Set(oldSelectionArr)
        })
    }

    // Variables Filter
    const [selectedVariables, setSelectedVariables] = useQueryParam(
        "variables",
        BooleanDictionaryParam
    )

    // Decade Filter
    const decadeParam = withDefault(StringParam, MIN_DECADE.toString())
    const [selectedDecade, setSelectedDecade] = useQueryParam(
        "decade",
        decadeParam
    )

    // Scenario Filter
    const scenarioOpts = useMemo(
        () => ({
            low: t("lowCO2emissions", "Low CO2 emissions"),
            middle: t("middleOfTheRoad", "Middle of the Road"),
            high: t("highCO2emissions", "High CO2 emissions"),
        }),
        []
    )
    const scenarioParam = withDefault(StringParam, Object.keys(scenarioOpts)[1])
    const [selectedScenario, setSelectedScenario] = useQueryParam(
        "scenario",
        scenarioParam
    )

    // Set variable default values
    useEffect(() => {
        if (!selectedVariables || Object.keys(selectedVariables).length === 0) {
            setSelectedVariables({ temp_max: true })
        }
    }, [])
    // Clear locations on selected account change
    useEffect(() => {
        if (!prevSelectedAccount || selectedAccount === prevSelectedAccount)
            return
        setSelectedLocations(new Set())
    }, [selectedAccount, prevSelectedAccount])

    // Update Filters
    useEffect(() => {
        const filteredSelectedVariables = Object.keys(selectedVariables).filter(
            (key) => !!selectedVariables[key]
        )
        if (
            _.isEqual(selectedLocationsArr, filters.selectedLocations) &&
            _.isEqual(filteredSelectedVariables, filters.selectedVariables) &&
            selectedDecade === filters.selectedDecade &&
            selectedScenario === filters.selectedScenario
        )
            return
        setFilters({
            selectedLocations: selectedLocationsArr,
            selectedVariables: filteredSelectedVariables,
            selectedDecade,
            selectedScenario,
        })
    }, [
        selectedLocationsArr,
        selectedVariables,
        selectedDecade,
        selectedScenario,
    ])
    if (!locations.length) return null
    return (
        <div className="flex flex-row flex-wrap gap-2">
            <div className="flex flex-row flex-nowrap gap-2">
                {Array.from({ length: MAX_COMPARED_LOCATIONS }).map(
                    (_, idx) => {
                        const currSelection = selectedLocationsArr[idx]
                        return (
                            <SingleSelectFilter
                                key={uniqueId()}
                                canSearch={true}
                                options={getLocationOpts(idx)}
                                selected={currSelection}
                                disabled={
                                    idx > 0 && selectedLocations.size < idx
                                }
                                clearable={
                                    idx > 0 || selectedLocations.size > 1
                                }
                                setSelected={(selected) => {
                                    smartSetSelectedLocations(selected, idx)
                                }}
                                placeholder={
                                    `${t("location", "Location")}  ${
                                        idx + 1
                                    } ` +
                                    (idx > 0
                                        ? `(${t("optional", "Optional")})`
                                        : "")
                                }
                                icon={<LocationPinIcon />}
                                firstOptAsDefault={true}
                                sortByKey={false}
                                leftRightClass="left-0"
                            />
                        )
                    }
                )}
            </div>
            <VariablesIconSelect
                selectedVariables={selectedVariables}
                setSelectedVariables={(_selectedVariables) => {
                    setSelectedVariables(_selectedVariables)
                }}
                enableMultiSelect={false}
                // hiddenVariables={["temp_max"]}
                availableVariables={[
                    "temp_max",
                    "temp_mean",
                    "temp_min",
                    "precipitation",
                ]}
            />
            <SingleSelectFilter
                selected={selectedDecade}
                setSelected={setSelectedDecade}
                sortByKey={false}
                options={decadeOptions}
            />
            <SingleSelectFilter
                selected={selectedScenario}
                setSelected={setSelectedScenario}
                options={scenarioOpts}
                keepOrder={true}
            />
        </div>
    )
}

export default TrendFilters
