import http from "../../climateui/utils/http"

export const labelDELETE = async (label_id) => {
    return await http.delete(`/location/label/${label_id}`)
}

export const labelPOST = async (label) => {
    return await http.post("/location/label/", label)
}

export const labelPUT = async (data) => {
    return await http.put(`/location/label/${data.label_id}`, data.label)
}

export const accountLabelsGET = async (account_id) => {
    return await http.get(`/location/label/account/${account_id}`)
}
