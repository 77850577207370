import { isValidResponse } from "../../climateui/utils/http"
import {
    AlertSettingInput,
    IAlertSetting,
    IInsightsLocation,
    IRiskProfile,
    IVariety,
} from "../../types"
import { PRODUCT_IDS } from "../../utils/constants"
import {
    alertSettingsQuerySet,
    locationPUT,
    rpLabelsQuerySet,
} from "../../utils/networking"

export interface IRiskProfileAndStage {
    riskProfile: IRiskProfile
    stageName: string
}

export async function getAlreadyActiveAlerts(
    riskProfileIds: string[],
    locationIds: string[]
) {
    // TODO: can be reused in DashboardAssets
    return alertSettingsQuerySet
        .post("/search", {
            filter_by: {
                and: [
                    {
                        field_name: "risk_setting.RiskSetting.risk_profile_id",
                        field_value: riskProfileIds,
                        operator: "in",
                    },
                    {
                        field_name: "risk_setting.RiskSetting.product_id",
                        operator: "in",
                        field_value: [
                            PRODUCT_IDS.ALERT_SETTINGS,
                            PRODUCT_IDS.STAGE_MANAGER,
                        ],
                    },
                    {
                        field_name: "risk_setting.RiskSetting.location_id",
                        operator: "in",
                        field_value: locationIds,
                    },
                ],
            },
            pagination: {
                all: true,
            },
        })
        .then((response) => {
            if (!isValidResponse(response)) return []
            return response.data.data
        })
}

export async function addVarietyToLocations(
    locations: IInsightsLocation[],
    variety: IVariety
) {
    // filter locations that don't have the variety
    const locationsToUpdate = locations.filter((location) => {
        const varietyIds = location.varieties
            ? location.varieties.map((variety) => variety.id)
            : []
        return !varietyIds.includes(variety.id)
    })

    // create requests to update locations' varieties
    const requests = locationsToUpdate.map((location) => {
        const updatedVarieties = location.varieties
            ? [...location.varieties, variety]
            : [variety]
        const args = {
            location_id: location.id,
            payload: {
                ...location,
                varieties: updatedVarieties,
            },
        }
        return locationPUT(args)
    })
    return Promise.all(requests)
}

/**
 * Creates or updates Risk Settings for the given locations and risk profiles.
 * @param locationsRiskProfileRelation - Object containing location ids as keys and risk profile ids as values.
 * @param existingLocationsRiskProfileRelation - Object containing location ids as keys and risk profile ids as values.
 * @param activeAlerts - Array of existing active alerts.
 */
export async function createOrUpdateRiskSettings(
    locationsRiskProfileRelation: Record<string, string[]>,
    existingLocationsRiskProfileRelation: Record<string, string[]>,
    activeAlerts: AlertSettingInput[]
) {
    // TODO: potentially reuse in DashboardAssets i.e. getRiskSettings()
    const alertsIds: string[] = []

    const riskSettingsToCreate: AlertSettingInput[] = []
    const riskSettingsToUpdate: AlertSettingInput[] = []

    // Get list of new Risk Settings to create
    Object.keys(locationsRiskProfileRelation).forEach((locationId) => {
        const riskProfilesList = locationsRiskProfileRelation[locationId]

        riskProfilesList.forEach((riskProfileId) => {
            const shouldCreateRiskSetting =
                !existingLocationsRiskProfileRelation[locationId]?.includes(
                    riskProfileId
                )
            if (shouldCreateRiskSetting) {
                riskSettingsToCreate.push({
                    risk_profile_id: riskProfileId,
                    location_id: locationId,
                    product_id: PRODUCT_IDS.ALERT_SETTINGS,
                    status: "active",
                    start_date: "01-01",
                    end_date: "12-31",
                })
            }
        })
    })

    // Get list of existing Risk Settings to update
    activeAlerts.forEach((alert: AlertSettingInput) => {
        riskSettingsToUpdate.push({
            id: alert.id,
            risk_profile_id: alert.risk_profile_id,
            location_id: alert.location_id,
            product_id: PRODUCT_IDS.ALERT_SETTINGS,
            status: "active",
            start_date: "01-01",
            end_date: "12-31",
        })
    })

    // Create Risk Settings and accumulate ids
    if (riskSettingsToCreate.length != 0) {
        const newAlertsActivated: IAlertSetting[] =
            await alertSettingsQuerySet
                .post("/", riskSettingsToCreate)
                .then((response) => {
                    if (!isValidResponse(response)) return []
                    return response.data
                })
        newAlertsActivated.forEach((alert) => {
            alertsIds.push(alert.id)
        })
    }

    // Update Risk Settings and accumulate ids
    if (riskSettingsToUpdate.length != 0) {
        const updatedAlerts: IAlertSetting[] =
            await alertSettingsQuerySet
                .put("/", riskSettingsToUpdate)
                .then((response) => {
                    if (!isValidResponse(response)) return []
                    return response.data
                })
        if (updatedAlerts) {
            updatedAlerts.forEach((alert) => {
                alertsIds.push(alert.id)
            })
        }
    }

    return alertsIds
}

export async function getRiskProfileLabels() {
    const response = await rpLabelsQuerySet.get("")
    if (!isValidResponse(response)) return []
    return response.data
}
