import { useState } from "react"
import { CancelIcon, LoadingCircleIcon } from "../icons"

export interface IChip {
    value: string
    onRemove?: (ev?: Event) => void
    onClick?: (ev?: Event) => void
    id?: string | number
    color?: string
}

const Chip = ({ value, onRemove, id, color = "", onClick }: IChip) => {
    const [removing, setRemoving] = useState(false)

    return (
        <div
            key={id}
            className={[
                "flex flex-row items-center group",
                "relative h-[20px]",
                "rounded-sm overflow-hidden",
                "shrink w-fit",
                color === ""
                    ? "border border-accent-medium bg-accent-light text-accent" +
                      (onRemove || onClick ? " hover:border-accent" : "")
                    : "",
                onRemove ? "pl-1 pr-[2px]" : "px-2",
                onClick ? "cursor-pointer" : "",
            ].join(" ")}
            style={
                color === ""
                    ? {}
                    : {
                          color,
                      }
            }
            onClick={(e) => {
                e.stopPropagation()
                if (onClick) onClick()
            }}>
            {color !== "" && (
                <>
                    <div
                        className={
                            "absolute inset-0 border rounded-sm pointer-events-none" +
                            (onRemove || onClick ? " group-hover:hidden" : "")
                        }
                        style={{
                            borderColor: color,
                            opacity: 0.4,
                        }}
                    />
                    <div
                        className={
                            "absolute inset-0 hidden border rounded-sm pointer-events-none" +
                            (onRemove || onClick ? " group-hover:block" : "")
                        }
                        style={{
                            borderColor: color,
                            opacity: 1,
                        }}
                    />
                    <div
                        className="absolute inset-0 border border-transparent rounded-sm pointer-events-none"
                        style={{
                            backgroundColor: color,
                            opacity: 0.1,
                        }}
                    />
                </>
            )}

            <div className="truncate pointer-events-none whitespace-nowrap label-sm">
                {value}
            </div>
            {onRemove && (
                <button
                    className={[
                        "w-4 h-4 ml-1",
                        color === ""
                            ? "fill-accent-medium hover:fill-accent"
                            : "opacity-[.4] hover:opacity-100",
                    ].join(" ")}
                    style={
                        color === ""
                            ? {}
                            : {
                                  fill: color,
                              }
                    }
                    onClick={(e) => {
                        e.stopPropagation()
                        setRemoving(true)
                        onRemove()
                    }}>
                    {removing ? <LoadingCircleIcon /> : <CancelIcon />}
                </button>
            )}
        </div>
    )
}

export default Chip
