// TODO: Move to yield outlook folder
import { useTranslate } from "@tolgee/react"
import { useMemo } from "react"
import ShadedRegionsMap from "../../climateui/components/Map/ShadedRegionsMap"
import { IPolygonGroup } from "../../climateui/components/Map/ShadedRegionsMap/types"
import { IYieldStat } from "../../types"
import IWidgetDefaultProps from "../../views/Seasonal/Dashboards/components/widgetLibrary/WidgetDefaultProps"
import {
    getDirectionOutlook,
    getIsMeanInMass,
    getLargestTercile,
} from "../../utils/transform"
import { useIsFlagEnabled } from "../../hooks"

interface IYieldOutlookRegion {
    id: string
    geojson: GeoJSON.Geometry
    resolution: number
    name: string
}

interface IYieldOutlookModel {
    region: IYieldOutlookRegion
    stats: {
        results: IYieldStat[]
    }
}

interface IYieldOutlookResponse {
    yield_outlook_model?: { results: IYieldOutlookModel[] }
}

function ShadedRegionMapsWidget(
    props: IWidgetDefaultProps & {
        __data: IYieldOutlookResponse
        loading?: boolean
        toggleNewDirectonalityLogic: boolean
        simpleDirectionality?: boolean
        directionality?: "-2" | "-1" | "0" | "1"
    }
) {
    const { t } = useTranslate()
    const translatedCategories: Record<number, string> = {
        "-2": t("noSignal", "No signal"),
        "-1": t("belowNormal", "Below normal"),
        "0": t("withinNormal", "Within normal"),
        "1": t("aboveNormal", "Above normal"),
    }

    const isNewDirectionalityToggleEnabled = !!useIsFlagEnabled(
        "experiment_yield_outlook_new_directionality_logic"
    )

    const categorizedRegions = useMemo(() => {
        const results = props.__data.yield_outlook_model?.results
        if (!results || results.length === 0) return {}

        const categories: Record<string, IPolygonGroup> = {
            [translatedCategories[-1]]: {
                geojson: {
                    type: "FeatureCollection",
                    features: [],
                },
                style: {
                    fillColor: "#E42437",
                },
            },
            [translatedCategories[0]]: {
                geojson: {
                    type: "FeatureCollection",
                    features: [],
                },
                style: {
                    fillColor: "#FDB600",
                },
            },
            [translatedCategories[1]]: {
                geojson: {
                    type: "FeatureCollection",
                    features: [],
                },
                style: {
                    fillColor: "#23AF41",
                },
            },
        }

        if (isNewDirectionalityToggleEnabled) {
            categories[translatedCategories[-2]] = {
                geojson: {
                    type: "FeatureCollection",
                    features: [],
                },
                style: {
                    fillColor: "gray",
                },
            }
        }

        console.log("props")
        console.log(props)

        results.forEach((outlook) => {
            // Validate that the information is actually there
            if (!outlook?.region?.geojson || !outlook?.stats?.results?.length)
                return

            const region = outlook.region
            const geojson = region.geojson

            if (props.simpleDirectionality) {
                ;(
                    categories[
                        translatedCategories[
                            props.directionality as "-2" | "-1" | "0" | "1"
                        ]
                    ].geojson as {
                        features: GeoJSON.GeoJSON[]
                    }
                ).features.push({
                    type: "Feature",
                    geometry: geojson,
                    properties: {},
                })
                return
            }

            const stats = outlook?.stats?.results[0]
            if (!stats) return

            const { tercile_probabilities: tercileProbabilities } = stats

            // let maxVal = 0
            // let category = translatedCategories[1]

            let directionOutlook = 0

            if (props.toggleNewDirectonalityLogic) {
                directionOutlook = getDirectionOutlook(tercileProbabilities)
                if (directionOutlook && directionOutlook !== -2) {
                    directionOutlook = getIsMeanInMass(
                        directionOutlook,
                        stats.outlook_mean,
                        stats.historical
                    )
                }
            } else {
                directionOutlook =
                    getLargestTercile(tercileProbabilities)?.[0] || 0
            }

            ;(
                categories[translatedCategories[directionOutlook]].geojson as {
                    features: GeoJSON.GeoJSON[]
                }
            ).features.push({
                type: "Feature",
                geometry: geojson,
                properties: {},
            })
        })

        return categories
    }, [props.__data, props.toggleNewDirectonalityLogic])
    return (
        <ShadedRegionsMap
            title={t("directionalYieldOutlook", "Directional Yield Outlook")}
            data={categorizedRegions}
            loading={props.loading}
        />
    )
}

export default ShadedRegionMapsWidget
