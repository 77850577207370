import { ReactNode } from "react"

function Banner({ children }: { children: ReactNode }) {
    return (
        <div className="w-full h-10 bg-accent text-white flex flex-row gap-1 items-center justify-center body-md">
            {children}
        </div>
    )
}

export default Banner
