import { ReactNode, useState } from "react"
import { useOutsideComponentClickHandler } from "../hooks"
import FixedElement, {
    FixedAlignment,
    FixedPosition,
    FixedXAlignment,
    FixedYAlignment,
} from "./FixedElement"

//
// TIP ------------------------------------------------------------------------
//
const TIP_POSITION: Record<FixedPosition, string> = {
    top: "bottom-0 border-b-0 border-x-transparent",
    bottom: "top-0 border-t-0 border-x-transparent",
    left: "right-0 border-r-0 border-y-transparent",
    right: "left-0 border-l-0 border-y-transparent",
}
const TIP_X_ALIGNMENT: Record<FixedXAlignment, string> = {
    left: "left-4",
    LEFT: "right-3.5",
    right: "right-4",
    RIGHT: "left-3.5",
    center: "left-1/2 -translate-x-1/2",
}
const TIP_Y_ALIGNMENT: Record<FixedYAlignment, string> = {
    top: "top-4",
    TOP: "bottom-3.5",
    bottom: "bottom-4",
    BOTTOM: "top-3.5",
    center: "top-1/2 -translate-y-1/2",
}
const getTipPositionAlignment = (
    position: FixedPosition,
    align: FixedAlignment
) =>
    position === "top" || position === "bottom"
        ? TIP_X_ALIGNMENT[align as FixedXAlignment]
        : TIP_Y_ALIGNMENT[align as FixedYAlignment]

//
// TOOLTIP V2 -----------------------------------------------------------------
//
function TooltipV2({
    children,
    content,
    contentClass = "whitespace-nowrap",
    contentContainerClass = "",
    position = "bottom",
    align = "center",
    doShow = true,
    isWhite = false,
    containerClasses = "h-fit w-fit grow-0 shrink-0",
}: Readonly<{
    children: ReactNode
    content: ReactNode
    contentClass?: string
    contentContainerClass?: string
    position?: FixedPosition
    align?: FixedAlignment
    doShow?: boolean
    isWhite?: boolean
    containerClasses?: string
}>) {
    const bgColor = isWhite ? "bg-white" : "bg-gray-90"
    const borderColor = isWhite ? "border-white" : "border-gray-90"
    const textColor = isWhite ? "text-gray-90" : "text-white"

    const tipClasses =
        TIP_POSITION[position] + " " + getTipPositionAlignment(position, align)

    const [isOpen, setIsOpen] = useState(false)

    const ref = useOutsideComponentClickHandler(
        () => isOpen && setIsOpen(false)
    )

    return (
        <div
            className={containerClasses}
            ref={ref}
            role="tooltip"
            aria-label="Tooltip Toggle"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}>
            <FixedElement
                open={isOpen && doShow}
                position={position}
                align={align}
                parentVisibilityThreshold={0.5}
                animationDelayClass="delay-[400ms]"
                pointerEventsNone
                parentElement={children}>
                <div className={["p-1", contentContainerClass].join(" ")}>
                    {/* p-1 to leave space around the tooltip tip */}
                    <div className="relative p-[6px]">
                        {/* p-[6px] to leave space around the tooltip for the tip */}
                        <span
                            className={[
                                "absolute border-[6px]",
                                borderColor,
                                tipClasses,
                            ].join(" ")}></span>

                        <div
                            className={[
                                bgColor,
                                textColor,
                                "overflow-hidden",
                                "label-sm font-[600] p-2 rounded-sm elevation-2",
                                contentClass,
                            ].join(" ")}>
                            {content}
                        </div>
                    </div>
                </div>
            </FixedElement>
        </div>
    )
}

export default TooltipV2
