import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { TrashIcon, EditIcon } from "../../../../climateui/icons"
import { ModalContext, ToastContext } from "../../../../climateui/providers"
import { TableActions } from "../../../../climateui/components/Table/tableUtils"
import { IDashboard } from "../../../../types"
import { useTranslate } from "@tolgee/react"
import { useAccount } from "../../../../providers/AccountProvider"
import queryClient, { dashboardDELETE } from "../../../../utils/networking"
import { useDashboard } from "../../../../providers/DashboardProvider"
import { areResTypeAndDataValid } from "../../../../climateui/utils/http"

const DashboardTableActions = ({
    rowData,
}: {
    rowData: IDashboard
}) => {
    const navigate = useNavigate()
    const { t } = useTranslate()
    const { confirmationModal } = useContext(ModalContext)
    const { enqueueAlert } = useContext(ToastContext)
    const { selectedAccount } = useAccount()

    const { setWorkingDashboard } = useDashboard()

    return (
        <TableActions
            rowData={rowData}
            actions={[
                {
                    icon: <EditIcon />,
                    onClick: () => {
                        if (selectedAccount === rowData.account_id) {
                            setWorkingDashboard({ ...rowData })
                            navigate(rowData.id + "/edit/locations")
                        }
                    },
                    tooltip: t("editDashboard"),
                },
                {
                    tooltip: t("deleteDashboard"),
                    icon: <TrashIcon />,
                    onClick: () => {
                        confirmationModal({
                            title: t(
                                "areYouSureDeleteDashboard",
                                "Delete this custom dashboard?"
                            ),
                            text: t(
                                "dashboardWillBeDeletedPermanently",
                                "This dashboard will be deleted permanently."
                            ),
                            onContinueLabel: t("continue"),
                            onCancelLabel: t("cancel"),
                            onContinue: () => {
                                dashboardDELETE(rowData.id)
                                    .then((res) => {
                                        if (
                                            res?.status == 204 ||
                                            areResTypeAndDataValid(res)
                                        ) {
                                            queryClient.invalidateQueries([
                                                "dashboards",
                                                selectedAccount,
                                            ])
                                            enqueueAlert(
                                                t(
                                                    "DASHBOARDDeletedSuccessfully",
                                                    "Dashboard deleted successfully!",
                                                    {
                                                        dashboard:
                                                            rowData.title ||
                                                            t(
                                                                "theDashboard",
                                                                "The dashboard"
                                                            ),
                                                    }
                                                )
                                            )
                                        }
                                    })
                                    .catch(() => {
                                        enqueueAlert(
                                            t(
                                                "DASHBOARDDeletionFailed",
                                                "Error deleting dashboard."
                                            )
                                        )
                                    })
                            },
                        })
                    },
                },
            ]}
        />
    )
}

export default DashboardTableActions
