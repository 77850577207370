import { useTranslate } from "@tolgee/react"
import { useMemo } from "react"
import { IRoute } from "../../climateui/types"
import { IAsset, IAssetModel } from "../../types"
import { useCustomFlags } from "../flagsmith"

interface ITerritoryConfig {
    supportedAssets: Set<string> // assets IDs
}

// DISCUSSION: Sub routes should be handled in their respective
// providers, we could only abstract the logic for the asset matching
// but appart from that, the routing should be handled independently
export const useSubRoutes = (
    assets: Record<string, IAsset> | undefined,
    assetModels: IAssetModel[] | undefined,
    inSeasonalPage: boolean,
    opts?: {
        locations?: ITerritoryConfig
    }
) => {
    const { t } = useTranslate()
    const { feature_monitor_yield_outlook__locations: featureFlagLocations } =
        useCustomFlags(["feature_monitor_yield_outlook__locations"])

    const distinctAssets = useMemo<(IAsset | undefined)[]>(() => {
        if (!assets || !assetModels) return []
        const ids = new Set<string>()

        // Get distinct asset ids from models
        assetModels.forEach((model) => {
            if (model.newest_seasonal_date || !inSeasonalPage) {
                ids.add(model.asset_id)
            }
        })

        // List of ordered asset objects
        return Array.from(ids)
            .map((id) => assets[id])
            .sort((a, b) => {
                if (!a?.name || !b?.name) return 0
                return a.name.localeCompare(b.name)
            })
    }, [assets, assetModels, inSeasonalPage])

    return useMemo(() => {
        const routes: IRoute[] = []
        distinctAssets.forEach((asset) => {
            if (!asset) return
            const { id, name } = asset
            if (!inSeasonalPage) {
                // in Climate/Adapt page
                routes.push({
                    path: id,
                    label: t("yieldOutlookDashboard", {
                        asset: name,
                    }),
                })
            } else {
                routes.push({
                    path: id,
                    label: t("regionalYield", "{asset} Regional Yield", {
                        asset: name,
                    }),
                })
                if (
                    featureFlagLocations.enabled &&
                    (!opts || opts?.locations?.supportedAssets.has(asset.id))
                ) {
                    routes.push({
                        path: "locations/" + id,
                        label: t("locationYield", "{asset} Location Yield", {
                            asset: name,
                        }),
                    })
                }
            }
        })
        return routes
    }, [distinctAssets, opts?.locations?.supportedAssets?.size, inSeasonalPage])
}
