import { useContext, useEffect, useMemo, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { IMap, IMapPin } from "../../../climateui/components/Map/utils"
import { ICoordinates,  } from "../../../climateui/types"
import { HalfMapLayout } from "../../../layouts"
import { LocationsContext } from "../../../providers/LocationsProvider"

const adminLocationsPaths = ["/admin/locations", "/admin/locations/"]

function AdminLocationsLayout() {
    const location = useLocation()

    const [rowSelection, setRowSelection] = useState<Record<string, string>>(
        {}
    )
    const [mapPinCoords, setMapPinCoords] = useState({ lat: 10, lon: 10 })

    const {
        filteredPageLocationPins,
        filteredPageChildLocationPins,
        filteredPageLocations,
    } = useContext(LocationsContext)

    useEffect(() => {
        setTimeout(() => {
            const _event = new CustomEvent("force-resize", { bubbles: true })
            window.dispatchEvent(_event)
        }, 100)
    }, [location.pathname])

    const { pins }: IMap = useMemo(() => {
        const newPins: IMapPin[] = [
            ...filteredPageLocationPins,
            ...filteredPageChildLocationPins,
        ]
        newPins.forEach((pin) => {
            if (pin.id && rowSelection[pin.id]) {
                pin.pinStyle = "map-pin-white.png"
            } else {
                pin.pinStyle = "map-pin.png"
            }
        })
        return { pins: newPins }
    }, [filteredPageLocationPins, filteredPageChildLocationPins, rowSelection])

    const mapAddEditMode =
        location.pathname.includes("add") || location.pathname.includes("edit")

    return (
        <HalfMapLayout
            contentWidthClasses={
                adminLocationsPaths.includes(location.pathname)
                    ? "lg:w-[820px] w-full"
                    : "w-full lg:w-[340px]"
            }
            mapProps={{
                pins: mapAddEditMode ? [] : pins,
                ...(mapAddEditMode
                    ? {
                          coords: mapPinCoords,
                          setCoords: (coords: ICoordinates) => {
                              setMapPinCoords(coords)
                          },
                      }
                    : {}),
                mapConfigs: {
                    minZoom: 1.1,
                    maxZoom: 18,
                    renderWorldCopies: false,
                },
            }}>
            <Outlet
                context={{
                    map: [mapPinCoords, setMapPinCoords],
                    row: [rowSelection, setRowSelection],
                    filter: filteredPageLocations,
                }}
            />
        </HalfMapLayout>
    )
}

export default AdminLocationsLayout
