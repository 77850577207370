import CalendarView from "./CalendarView"
import { ICalendarCell } from "./CalendarCell"
import { DateTime } from "luxon"
const YearView = ({
    viewDate,
    onClick,
}: {
    viewDate: DateTime
    onClick?: (cell: ICalendarCell) => void
}) => {
    const yearStart = viewDate.startOf("year")
    const decadeStart = yearStart.set({
        year: Math.floor(yearStart.year / 10) * 10,
    })
    const today = DateTime.now().startOf("year")

    // Create a days array skipping the 0th day
    const years: ICalendarCell[] = [...Array(10).keys()].map((year) => {
        const date = decadeStart.plus({ years: year })
        return {
            date,
            size: {
                width: 20,
                height: 20,
            },
            label: date.year,
            current: date.toISODate() === today.toISODate(),
        }
    })

    return (
        <CalendarView
            onClick={onClick}
            columns={3}
            values={years}
        />
    )
}
export default YearView
