import { SVGProps } from "react"

const LowTemperatureIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 18 18"
        className="fill-inherit"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.5 8.25H13.3725L15.8025 5.82L14.745 4.755L11.25 8.25H9.75V6.75L13.245 3.255L12.18 2.1975L9.75 4.6275V1.5H8.25V4.6275L5.82 2.1975L4.755 3.255L8.25 6.75V8.25H6.75L3.255 4.755L2.1975 5.82L4.6275 8.25H1.5V9.75H4.6275L2.1975 12.18L3.255 13.245L6.75 9.75H8.25V11.25L4.755 14.745L5.82 15.8025L8.25 13.3725V16.5H9.75V13.3725L12.18 15.8025L13.245 14.745L9.75 11.25V9.75H11.25L14.745 13.245L15.8025 12.18L13.3725 9.75H16.5V8.25Z"
            fill={props.fill}
        />
    </svg>
)

export default LowTemperatureIcon
