import { useTranslate } from "@tolgee/react"
import {
    MultiSelectFilter,
    SingleSelectFilter,
} from "../../../../climateui/components"
import { CancelIcon, FilterIcon } from "../../../../climateui/icons"
import { IPrimitivesDictionary } from "../../../../climateui/types"
import { IDashboard, IScheduleFilters } from "../../../../types"
import dashboardVariables from "../../Dashboards/dashboardVariables"

const ScheduleFilters = (props: {
    dashboard: IDashboard
    scheduleFilters: IScheduleFilters
    rowSelection: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deleteFilterSet: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateFilterSet: any
    locationsRegionsDict: IPrimitivesDictionary
}) => {
    const { t } = useTranslate()
    const {
        dashboard,
        scheduleFilters,
        rowSelection,
        deleteFilterSet,
        updateFilterSet,
        locationsRegionsDict,
    } = props

    const scopesDict = {
        daily: t("daily"),
        weekly: t("weekly"),
        monthly: t("monthly"),
    } as IPrimitivesDictionary

    const distributionDict = {
        0: "Forecast Distribution",
        1: "Directional Forecast",
    }

    // Variables
    const variablesDict = {} as IPrimitivesDictionary
    Object.values(dashboardVariables).forEach((v) => {
        if (v.value != "precipitation_sum") variablesDict[v.value] = v.name
    })

    return (
        <>
            {dashboard.dtype === "Location" ? (
                <SingleSelectFilter
                    placeholder={"Location"}
                    selected={scheduleFilters.locId}
                    setSelected={(v) =>
                        updateFilterSet(
                            rowSelection,
                            scheduleFilters,
                            "locId",
                            v.toString()
                        )
                    }
                    leftRightClass="left-0"
                    options={
                        {
                            ...locationsRegionsDict,
                        } as { [key: string]: string }
                    }
                    canSearch={false}
                />
            ) : (
                <MultiSelectFilter
                    filterName={t("country")}
                    icon={<FilterIcon />}
                    placeholder={"Country"}
                    searchPlaceholder={t("search")}
                    leftRightClass="left-0"
                    selected={scheduleFilters.regions}
                    setSelected={(v) => {
                        updateFilterSet(
                            rowSelection,
                            scheduleFilters,
                            "regions",
                            v
                        )
                    }}
                    options={
                        {
                            ...locationsRegionsDict,
                        } as { [key: string]: string }
                    }
                />
            )}

            <MultiSelectFilter
                filterName={t("variable")}
                placeholder={t("variables", "Variables")}
                searchPlaceholder={t("search")}
                selected={scheduleFilters.variables}
                setSelected={(v) =>
                    updateFilterSet(
                        rowSelection,
                        scheduleFilters,
                        "variables",
                        v
                    )
                }
                leftRightClass="left-0"
                options={
                    {
                        ...variablesDict,
                    } as { [key: string]: string }
                }
                canSearch={false}
            />
            <SingleSelectFilter
                placeholder={t("scopes")}
                selected={scheduleFilters.scope}
                setSelected={(v) =>
                    updateFilterSet(
                        rowSelection,
                        scheduleFilters,
                        "scope",
                        v.toString()
                    )
                }
                leftRightClass="left-0"
                options={
                    {
                        ...scopesDict,
                    } as { [key: string]: string }
                }
                canSearch={false}
                keepOrder
            />
            {dashboard?.dtype == "Location" ? (
                <SingleSelectFilter
                    placeholder="Distribution"
                    selected={scheduleFilters.directionality}
                    setSelected={(d) =>
                        updateFilterSet(
                            rowSelection,
                            scheduleFilters,
                            "directionality",
                            d.toString()
                        )
                    }
                    options={
                        { ...distributionDict } as {
                            [key: string]: string
                        }
                    }
                    canSearch={false}
                />
            ) : (
                <></>
            )}
            {rowSelection !== 0 ? (
                <button
                    className="w-[20px] hover:fill-accent fill-accent-medium"
                    onClick={() => {
                        deleteFilterSet(rowSelection)
                    }}>
                    <CancelIcon />
                </button>
            ) : (
                <></>
            )}
        </>
    )
}

export default ScheduleFilters
