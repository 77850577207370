import React from "react"

interface LegendInterface {
    type: "line" | "area" | "dot" | "line-dashed"
    label: string
    color: string
}

interface RenderCustomLegendProps {
    legends?: LegendInterface[]
}

const renderCustomLegend = ({ legends }: RenderCustomLegendProps) => {
    if (!legends || legends.length === 0) {
        return null
    }
    return (
        <div className="flex justify-end items-center">
            {legends.map((legend, index) => (
                <div
                    key={index}
                    className={`flex items-center ${
                        index !== legends.length - 1 ? "mr-5" : ""
                    }`}>
                    {legend.type === "area" && (
                        <div
                            className="w-5 h-5 opacity-30 mr-1"
                            style={{ backgroundColor: legend.color }}
                        />
                    )}
                    {legend.type === "line" && (
                        <div
                            className="w-4 h-0.5 mr-1"
                            style={{ backgroundColor: legend.color }}
                        />
                    )}
                    {legend.type === "line-dashed" && (
                        <div
                            className="w-4 h-0 border-b-2 border-dashed mr-1 mt-px"
                            style={{ borderColor: legend.color }}
                        />
                    )}
                    {legend.type === "dot" && (
                        <div
                            className="w-2 h-2 rounded-full mr-1"
                            style={{ backgroundColor: legend.color }}
                        />
                    )}
                    <span className="text-sm text-gray-60">{legend.label}</span>
                </div>
            ))}
        </div>
    )
}

export default renderCustomLegend
