const PinSettingsIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.55 10.5L15.85 12H17.85L18.15 10.5C18.35 10.4167 18.5375 10.3292 18.7125 10.2375C18.8875 10.1458 19.0667 10.0333 19.25 9.9L20.7 10.35L21.7 8.65L20.55 7.65C20.5833 7.45 20.6 7.24167 20.6 7.025C20.6 6.80833 20.5833 6.6 20.55 6.4L21.7 5.4L20.7 3.65L19.25 4.15C19.0667 4 18.8875 3.875 18.7125 3.775C18.5375 3.675 18.35 3.58333 18.15 3.5L17.85 2H15.85L15.55 3.5C15.35 3.58333 15.1625 3.67083 14.9875 3.7625C14.8125 3.85417 14.6333 3.96667 14.45 4.1L13 3.65L12 5.35L13.15 6.35C13.1167 6.58333 13.1 6.8 13.1 7C13.1 7.2 13.1167 7.41667 13.15 7.65L12 8.65L13 10.35L14.45 9.9C14.6333 10.0333 14.8125 10.1458 14.9875 10.2375C15.1625 10.3292 15.35 10.4167 15.55 10.5ZM18.2625 8.4125C17.8708 8.80417 17.4 9 16.85 9C16.3 9 15.8292 8.80417 15.4375 8.4125C15.0458 8.02083 14.85 7.55 14.85 7C14.85 6.45 15.0458 5.97917 15.4375 5.5875C15.8292 5.19583 16.3 5 16.85 5C17.4 5 17.8708 5.19583 18.2625 5.5875C18.6542 5.97917 18.85 6.45 18.85 7C18.85 7.55 18.6542 8.02083 18.2625 8.4125Z"
                fill={props.fill}
            />
            <path
                d="M10 5C6.325 5 3 7.898 3 12.38C3 15.368 5.33625 18.905 10 23C13.8472 19.622 16.1106 16.6237 16.7852 14H14.6968C14.4724 14.6431 14.1123 15.3656 13.5793 16.1738C12.7591 17.4174 11.5774 18.7907 10 20.2924C8.42257 18.7907 7.24087 17.4174 6.42071 16.1738C5.38241 14.5994 5 13.3501 5 12.38C5 9.01116 7.42096 7 10 7V5Z"
                fill={props.fill}
            />
            <path
                d="M12 12C12 13.1046 11.1046 14 10 14C8.89543 14 8 13.1046 8 12C8 10.8954 8.89543 10 10 10C11.1046 10 12 10.8954 12 12Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default PinSettingsIcon
