import { FC } from "react"

const ErrorHeader: FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => (
    <div className={`${className ? className : ''} w-full p-5 flex flex-col items-center justify-start`}{...props} {...props} />
)

const ErrorContent: FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => (
    <div className={`${className ? className : ''} w-full p-5 flex-1 flex flex-col items-center justify-start`} {...props} />
)

const ErrorFooter: FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => (
    <div className={`${className ? className : ''} w-full p-3.5 flex flex-col items-center justify-start bg-gray-5`} {...props} />
)

const ErrorTitle: FC<React.HTMLAttributes<HTMLHeadElement>> = ({ className, ...props }) => (
    <h1 className={`${className ? className : ''} title-lg`} {...props} />
)

const ErrorDescription: FC<React.HTMLAttributes<HTMLParagraphElement>> = ({ className, ...props }) => (
    <p role="paragraph" className={`${className ? className : ''} body-lg text-gray-60`} {...props} />
)

/* Main Wrapper */
const ErrorWrapper: FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
    return (
        <div className={`${className ? className : ''} w-full h-full flex flex-col`} {...props} />
    )
}

export {
    ErrorWrapper,
    ErrorHeader,
    ErrorContent,
    ErrorFooter,
    ErrorTitle,
    ErrorDescription,
}