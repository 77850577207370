import { useTolgee } from "@tolgee/react"
import { createContext, useEffect, ReactNode, useContext } from "react"
import { MONTH_NAMES_TOLGEE } from "../utils/constants"

export interface LocalizationContextInteface {
    changeLocale: (localeCode: string) => void
    language: string
    monthNames: string[]
}

export const LocalizationContext = createContext<LocalizationContextInteface>(
    {} as LocalizationContextInteface
)

export const SUPPORTED_LANGUAGES_OBJ: Record<string, string> = {
    en: "English (US)",
    es: "Spanish (MX)",
    zh: "Chinese",
    ja: "Japanese",
    nl: "Dutch",
    pt: "Portuguese",
}
const SUPPORTED_LANGUAGES = Object.keys(SUPPORTED_LANGUAGES_OBJ)

export const useLocale = () => useContext(LocalizationContext)

function LocalizationProvider({ children }: { children: ReactNode }) {
    const tolgee = useTolgee(["language"])

    const language = tolgee.getLanguage() ?? "en"

    const isLocaleCodeValid = (localeTwoLettersCode: string) => {
        if (!localeTwoLettersCode || localeTwoLettersCode.length !== 2)
            return false

        return SUPPORTED_LANGUAGES.includes(localeTwoLettersCode.toLowerCase())
    }

    useEffect(() => {
        let lang = "en"

        // window.navigator.language
        const wnl = window.navigator.language?.substring(0, 2)
        if (isLocaleCodeValid(wnl)) lang = wnl

        // localStorage language
        const lsl = localStorage.getItem("language") as string
        if (isLocaleCodeValid(lsl)) lang = lsl

        tolgee.changeLanguage(lang)
    }, [])

    const changeLocale = (localeCode: string) => {
        if (!isLocaleCodeValid(localeCode)) return
        tolgee.changeLanguage(localeCode)
        // INFO: Store localeCode in localStorage
        // to remember lang on next login screens
        localStorage.setItem("language", localeCode)
    }

    return (
        <LocalizationContext.Provider
            value={{
                changeLocale,
                language,
                monthNames: MONTH_NAMES_TOLGEE.map((name) => tolgee.t(name)),
            }}>
            {children}
        </LocalizationContext.Provider>
    )
}

export default LocalizationProvider

// INFO: We have all of the following user language
// providers for outer users (see wnl constant)
// console.log(window.navigator.language) // <- WORKS
// console.log(window.navigator.userLanguage)
// console.log(window.navigator.browserLanguage)
// console.log(window.navigator.systemLanguage)
