import { useQuery } from "react-query"
import { seasonalYieldOutlookLocationGRAPHQL } from "../../utils/networking"
import { useMemo } from "react"
import {
    IAsset,
    IAssetModel,
    ISeasonalStat,
    IYieldOutlookLocationGQL,
    IInsightsLocation,
} from "../../types"
import { isValidResponse } from "../../climateui/utils/http"

export interface IAssetLocationModel {
    location?: IInsightsLocation
    model?: IAssetModel
    asset?: IAsset
    yield_location?: IYieldOutlookLocationGQL
}

export const getIDsFilter = (
    filterIDs: string[],
    isFilteringByLocation = false
) => {
    if (!isFilteringByLocation)
        return {
            field_name: "location.Location.regions",
            operator: "any",
            field_value: {
                field_name: "region.Region.id",
                operator: "in",
                field_value: filterIDs,
            },
        }
    return {
        field_name: "location.Location.id",
        operator: "in",
        field_value: filterIDs,
    }
}

export interface IRawSeasonalStat {
    model_id: string
    init_time: string
    data: {
        yield_outlook_mean: number
        yield_deviation_mean: number
        yield_outlook_distribution: {
            "0.05": number
            "0.25": number
            "0.50": number
            "0.75": number
            "0.95": number
        }
        tercile_probabilities: {
            "prob_0.00-0.33": number
            "prob_0.33-0.67": number
            "prob_0.67-1.00": number
        }
        impact_drivers: {
            name: string
            mean_impact: number
        }[]
        historical_baseline: {
            yield_mean: number
            yield_terciles: number[]
        }
    }
}
export const formatSeasonalStat = (
    rawStat: IRawSeasonalStat
): ISeasonalStat => {
    return {
        model_id: rawStat.model_id,
        outlook_distribution: rawStat.data.yield_outlook_distribution,
        deviation_mean: rawStat.data.yield_deviation_mean,
        historical: {
            yield_terciles: [
                ...rawStat.data.historical_baseline.yield_terciles,
            ],
        },
        impact_drivers: rawStat.data.impact_drivers,
        outlook_mean: rawStat.data.yield_outlook_mean,
        tercile_probabilities: {
            "0": rawStat.data.tercile_probabilities["prob_0.00-0.33"],
            "0.33": rawStat.data.tercile_probabilities["prob_0.33-0.67"],
            "0.66": rawStat.data.tercile_probabilities["prob_0.67-1.00"],
        },
        init_time: rawStat.init_time,
        scenario: "",
    }
}

type LocationModelsParams = {
    // assets?: Record<string, IAsset>
    // locations?: string[]
    // countries?: string[]
    assetModels?: IAssetModel[]
}
const useLocationModels = (
    isSeasonal: boolean,
    params: LocationModelsParams
) => {
    const cropModelIds = params?.assetModels?.map((m) => m.id) ?? []

    // fetch models from query service
    const yieldOutlookLocationQuery = useQuery({
        queryKey: ["yieldOutlookLocationModels", cropModelIds],
        queryFn: () =>
            seasonalYieldOutlookLocationGRAPHQL({
                crop_model_ids: cropModelIds,
                seasonal_status: "active",
            }),
        enabled: isSeasonal && cropModelIds.length > 0,
    })

    // validate and get clean loc models
    const locationModels = useMemo(() => {
        if (!isValidResponse(yieldOutlookLocationQuery.data)) return
        const _models = (yieldOutlookLocationQuery?.data?.data?.data
            ?.yield_locations?.results ?? []) as IYieldOutlookLocationGQL[]
        return _models
    }, [yieldOutlookLocationQuery.data])

    return {
        assetLocationModels: [],
        locationModels,
    }
}

export default useLocationModels
