import { Route } from "react-router-dom"
import {
    PermissionsGuard,
    RoleGuard,
    AccountPermissionsGuard,
    RouteFeatureFlagGuard,
} from "../providers"
import {
    AdminTab,
    ClimateTab,
    CustomTab,
    SeasonalTab,
    OnboardingFlow,
    AccountNotOnboarded,
} from "../views"
import adminChildRoutes from "../views/Admin/routes"
import climateChildRoutes from "../views/Climate/routes"
import customChildRoutes from "../views/Custom/routes"
import seasonalChildRoutes from "../views/Seasonal/routes"
import onboardingChildRoutes from "../views/Onboarding/routes"
import { IRoute } from "../climateui/types"

// INFO: The routes or childRoutes that we want to
// show in the TopBar or SideBar must have a labelKey attr

export const seasonalRoutes = {
    path: "seasonal",
    label: "Seasonal",
    labelKey: "monitorLabel",
    // permissions: ["seasonal"],
    accountPermissions: ["seasonal"],
    element: <SeasonalTab />,
    // featureFlags: ["ui_seasonal_tab_enabled"],
    children: seasonalChildRoutes,
}
const climateRoutes: IRoute = {
    path: "climate",
    label: "Climate",
    labelKey: "adaptLabel",
    // permissions: ["climate"],
    featureFlags: ["feature_climate_product"],
    accountPermissions: ["climate"],
    element: <ClimateTab />,
    // featureFlags: ["ui_climate_tab_enabled"],
    children: climateChildRoutes,
}
const customRoutes = {
    path: "custom",
    label: "Custom",
    labelKey: "customLabel",
    accountPermissions: ["custom"],
    element: <CustomTab />,
    featureFlags: ["ui_custom_tab_enabled"],
    children: customChildRoutes,
}
const adminRoutes = {
    path: "admin",
    label: "Admin",
    labelKey: "adminLabel",
    role: "Admin",
    element: <AdminTab />,
    // featureFlags: ["ui_admin_tab_enabled"],
    children: adminChildRoutes,
}
export const onboardingRoutes = {
    path: "onboarding",
    element: <OnboardingFlow />,
    role: "Admin",
    children: onboardingChildRoutes,
}

export const protectedRoutes: IRoute[] = [
    seasonalRoutes,
    climateRoutes,
    customRoutes,
    adminRoutes,
    onboardingRoutes,
    // This NotFound blocks the App.js NotFound Route.
    // We'll be using the outer App.js NotFound Route for now.
    // {
    //     path: "*",
    //     element: <NotFound />,
    // },
    {
        path: "account-not-configured",
        element: <AccountNotOnboarded />,
    },
]

export function renderAppRoutes(routes?: IRoute[]) {
    if (!routes || routes.length === 0) return null

    return routes.map((route) => {
        let element = route.element

        if (route.permissions)
            element = (
                <PermissionsGuard permissions={route.permissions}>
                    {element}
                </PermissionsGuard>
            )

        if (route.accountPermissions)
            element = (
                <AccountPermissionsGuard permissions={route.accountPermissions}>
                    {element}
                </AccountPermissionsGuard>
            )

        if (route.role)
            element = <RoleGuard role={route.role}>{element}</RoleGuard>

        if (route.featureFlags)
            element = (
                <RouteFeatureFlagGuard featureFlags={route.featureFlags}>
                    {element}
                </RouteFeatureFlagGuard>
            )

        if (route.index)
            return (
                <Route
                    key={"route-" + route.path}
                    element={element}
                    index
                />
            )

        return (
            <Route
                key={"route-" + route.path}
                element={element}
                path={route.path}>
                {renderAppRoutes(route.children)}
            </Route>
        )
    })
}
