import {
    IDashboard,
    IInsightsLocation,
    IDashboardLocation,
} from "../../../types"
import { useEffect, useMemo, useState } from "react"
import { Row } from "@tanstack/react-table"
import { Translate } from "../../../components"
import { useNavigate } from "react-router-dom"
import DashboardTableActions from "./components/DashboardTableActions"
import { formatDate } from "../../../utils/transform"
import { TruncatedToCopyCell } from "../../../climateui/components/Table/tableUtils"
import { useTranslate } from "@tolgee/react"
import { useDashboard } from "../../../providers/DashboardProvider"
import { MultiSelectFilter } from "../../../climateui/components"
import { IBooleanDictionary } from "../../../climateui/types"
import { useLocations } from "../../../providers"
import { useUI } from "../../../providers/UIProvider"

function DashboardNameCell({ name, id }: { name: string; id: string }) {
    const navigate = useNavigate()
    const { setShowSecondSidebar } = useUI()

    return (
        <button
            onClick={() => {
                navigate(id + "")
                setShowSecondSidebar(true)
            }}
            className="text-left underline cursor-pointer">
            {name}
        </button>
    )
}

function DescriptionCell({ description }: { description: string }) {
    const { t } = useTranslate()
    return (
        <TruncatedToCopyCell
            value={description}
            copySuccessMessage={t("SOMETHINGCopiedToClipboard", {
                something: t("dashboardDescription"),
            })}
            maxWidthClass="max-w-[280px]"
            tooltipPosition="top"
        />
    )
}

function DashboardTypeCell({ dtype }: { dtype: string }) {
    const { t } = useTranslate()
    return (
        <div className="body-md">
            {" "}
            {dtype == "Location"
                ? t("locationDashboard")
                : t("regionalDashboard")}
        </div>
    )
}

export const columns = [
    {
        //Dashboard's globalFilter only works when adding accesorFn, otherwise no filtering is done.
        accessorFn: (row: IDashboard) => {
            return row.title + " " + row.id
        },
        id: "title",
        filterFn: (
            row: Row<IDashboard>,
            columnId: string,
            filterValue: string[]
        ) => {
            if (!filterValue.length) return true
            return filterValue.includes(row.original.id + "")
        },
        header: () => <Translate labelKey="name" />,
        cell: ({ row }: { row: Row<IDashboard> }) => (
            <DashboardNameCell
                name={row.original.title || "Unnamed Dashboard"}
                id={(row.original.id || "") as string}
            />
        ),
        meta: {
            sortable: true,
        },
    },
    {
        id: "description",
        filterFn: (
            row: Row<IDashboard>,
            columnId: string,
            filterValue: string[]
        ) => {
            if (!filterValue.length) return true
            return filterValue.includes(row.original.id + "")
        },
        header: () => <Translate labelKey="description" />,
        cell: ({ row }: { row: Row<IDashboard> }) => {
            return (
                <DescriptionCell description={row.original.description || ""} />
            )
        },
        meta: {
            sortable: true,
        },
    },
    {
        id: "created_by_email",
        filterFn: (
            row: Row<IDashboard>,
            columnId: string,
            filterValue: string[]
        ) => {
            if (!filterValue.length) return true
            // TODO: filter date not string
            return filterValue.includes(row.original.id + "")
        },
        header: () => <Translate labelKey="createdBy" />,
        cell: ({ row }: { row: Row<IDashboard> }) => {
            return (
                <div className="body-md">{row.original.created_by_email}</div>
            )
        },
        meta: {
            sortable: true,
        },
    },
    {
        id: "updated_by_email",
        filterFn: (
            row: Row<IDashboard>,
            columnId: string,
            filterValue: string[]
        ) => {
            if (!filterValue.length) return true
            // TODO: filter date not string
            return filterValue.includes(row.original.id + "")
        },
        header: () => <Translate labelKey="lastUpdated" />,
        cell: ({ row }: { row: Row<IDashboard> }) => {
            let date_str = ""
            if (row.original.updated_at) {
                date_str = formatDate(row.original.updated_at)
            }
            return (
                <>
                    <div className="body-md">
                        {row.original.updated_by_email}
                    </div>
                    <div className="text-[11px] text-gray-60"> {date_str} </div>
                </>
            )
        },
        meta: {
            sortable: true,
        },
    },
    {
        id: "source_template",
        filterFn: (
            row: Row<IDashboard>,
            columnId: string,
            filterValue: string[]
        ) => {
            if (!filterValue.length) return true
            // TODO: filter date not string
            return filterValue.includes(row.original.id + "")
        },
        header: () => <Translate labelKey="sourceTemplate" />,
        cell: ({ row }: { row: Row<IDashboard> }) => {
            return <DashboardTypeCell dtype={row.original.dtype as string} />
        },
    },
    {
        id: " ",
        filterFn: (
            row: Row<IDashboard>,
            columnId: string,
            filterValue: string[]
        ) => {
            if (!filterValue.length) return true
            return filterValue.includes(row.original.id + "")
        },
        cell: ({ row }: { row: Row<IDashboard> }) => {
            return <DashboardTableActions rowData={row.original} />
        },
    },
]

const LocationAssetsSelection = ({
    location,
}: {
    location: IInsightsLocation
}) => {
    const { t } = useTranslate()
    const { workingDashboard, setWorkingDashboard } = useDashboard()
    const { locationVarieties } = useLocations()
    const locationVarietiesOptions: Record<string, string> = {}

    location.varieties.forEach((variety) => {
        locationVarietiesOptions[variety.id] = locationVarieties[
            variety.id
        ] as string
    })

    //Get Varieties Ids linked to Dashboard Locations to get preselected varieties on first render
    const dashLocationVarietiesDict = useMemo(() => {
        const dashboardLocation =
            (workingDashboard?.locations as IDashboardLocation[]).find(
                (dashLocation) => {
                    return dashLocation.location_id == location.id
                }
            ) ?? {}
        const varietiesDict: IBooleanDictionary = {}
        dashboardLocation.varieties?.forEach((variety) => {
            varietiesDict[variety] = true
        })

        return varietiesDict
    }, [location])

    const [dashboardLocationsVarieties, setDashboardLocationsVarieties] =
        useState(dashLocationVarietiesDict)
    useEffect(() => {
        // Adding/deleting varieties triggers useEffect to update workingDashboard
        const updatedLocations: IDashboardLocation[] = []
        workingDashboard?.locations?.forEach((dashLoc: IDashboardLocation) => {
            if (dashLoc.location_id == location.id) {
                dashLoc.varieties = Object.keys(dashboardLocationsVarieties)
                updatedLocations.push(dashLoc)
            } else {
                updatedLocations.push(dashLoc)
            }
        })
        setWorkingDashboard({
            ...workingDashboard,
            locations: [...updatedLocations],
        })
    }, [dashboardLocationsVarieties])

    return (
        <MultiSelectFilter
            filterName={t("assets")}
            options={locationVarietiesOptions}
            setSelected={setDashboardLocationsVarieties}
            selected={dashboardLocationsVarieties}
            placeholder={"Assign Assets"}
            searchPlaceholder={"Search"}
            leftRightClass="left-0"
            shouldWrapToTop
            canSearch
        />
    )
}

const assetColumn = {
    accessorKey: "assets",
    header: () => <Translate labelKey="assets" />,
    cell: ({ row }: { row: Row<IInsightsLocation> }) => (
        <LocationAssetsSelection location={row.original as IInsightsLocation} />
    ),
}

export const dashboardAssetsPageColumns = [
    "name",
    "region",
    "labels",
    assetColumn,
]
