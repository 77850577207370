export { default as DatePicker } from "./DatePicker"
export const DATE = 0
export const MONTH = 1
export const YEAR = 2
export const DECADE = 3
export const DAYS_IN_WEEK = 7

export const LUXON_VIEW_MODE_KEY: Record<number, string> = {
    [DATE]: "days",
    [MONTH]: "months",
    [YEAR]: "years",
    [DECADE]: "years",
}
