import { useEffect, useMemo, useRef, useState } from "react"
import { ParseResult, ColumnsConfig, ParsedRow, RowError } from "./types"
import Papa from "papaparse"
import { CSVParser } from "./CSVParser"
import EmptyModal from "../../climateui/providers/Modal/EmptyModal"
import { Button } from "../../climateui/components"
import { useOutsideComponentClickHandler } from "../../climateui/hooks"
import { useTranslate } from "@tolgee/react"
import { CancelIcon } from "../../climateui/icons"
import ParserTable from "./ParserTable"
import { LOADING, UNKNOWN } from "./consts/cellStatus"
import _ from "lodash"
import { removeZeroWidthChars } from "./utils"

const BulkUpload = <T,>({
    columns,
    onParse,
}: {
    columns: ColumnsConfig<T>
    onParse: (
        items: [T | undefined, (newStatus: string) => void][],
        cleanup?: () => void
    ) => Promise<void>
}) => {
    const { t } = useTranslate()
    const [hasChanged, setHasChanged] = useState<boolean>(true)
    const [rawData, setRawData] = useState<Record<string, string>[]>([])
    const [rowErrors, setRowErrors] = useState<RowError[]>([])

    const [csvFile, setCSVFile] = useState<File>()
    const [isOpen, setIsOpen] = useState(false)
    const [errors, setErrors] = useState<string[]>([])
    const cleanUp = () => {
        setIsOpen(false)
        setHasChanged(true)
    }
    const modalRef = useOutsideComponentClickHandler(cleanUp)

    const parser = useMemo<CSVParser<T>>(
        () =>
            new CSVParser(columns, {
                emptyCell: t("emptyCell", "Missing value"),
                nestedValuesMismatch: t(
                    "nestedValuesMismatch",
                    "Mismatched nested values"
                ),
                prefetching: t("prefetching", "Fetching preliminary data..."),
                parsing: t("parsing", "Parsing rows..."),
                hasErrors: t(
                    "hasErrors",
                    "Errors were produced during parsing"
                ),
            }),
        [csvFile, columns]
    )
    // Clean-up after CSV file change
    useEffect(() => {
        setRawData([])
        setRowErrors([])
    }, [csvFile])
    const aggregatedData: ParsedRow[] = useMemo(
        () =>
            rawData
                ? rawData.map((row, idx) =>
                      Object.entries(row).reduce((prev: ParsedRow, [k, v]) => {
                          prev[k] = [v, rowErrors[idx]?.[k]]
                          return prev
                      }, {})
                  )
                : [],
        [rawData, rowErrors]
    )

    const updateData = (idx: number, col: string, newValue: string) => {
        // Get the whole raw row
        const rawRow = { ...rawData[idx] }
        // Update the value for the rawRow
        rawRow[col] = newValue
        // Update the state
        setRawData((prevRawData) => {
            prevRawData[idx] = rawRow
            return [...prevRawData]
        })
        setHasChanged(true)

        // If this cell has no errors, exit you're done
        if (!rowErrors[idx]) return

        // Create a copy of the row errors for this row
        const currRowErrors = { ...rowErrors[idx] }
        // Set the value for this error to undefined
        currRowErrors[col] = undefined
        // Update the errors state
        setRowErrors((prevRowErrors) => {
            prevRowErrors[idx] = currRowErrors
            return [...prevRowErrors]
        })
    }

    const getUpdateStatusCallback = (idx: number) => (newStatus: string) => {
        setRawData((prevRawData) => {
            const newRawData = [...prevRawData]
            newRawData[idx] = {
                ...newRawData[idx],
                rowStatus: newStatus,
            }
            return newRawData
        })
    }

    // When the CSV file changes, try to parse the CSV
    useEffect(() => {
        // Reset errors
        setErrors([])

        // If undefined, report file not found
        if (!csvFile) return setErrors([t("fileNotFound", "File not found")])

        // Try to parse the CSV file
        Papa.parse(csvFile, {
            complete: ({ data: _data, meta }: ParseResult) => {
                const subsetColumns = _.intersection(
                    parser.columns,
                    meta.fields
                )
                const subsetDiff = _.difference(parser.columns, subsetColumns)
                if (subsetDiff.length > 0)
                    return setErrors([
                        t("missingXColumns", "Missing {columns} columns", {
                            columns: JSON.stringify(subsetDiff, null, 2),
                        }),
                    ])
                // Set the initial status of all the rows
                setRawData(
                    _data.map((row: Record<string, string>) => ({
                        ...row,
                        rowStatus: UNKNOWN,
                    }))
                )
            },
            header: true,
            transformHeader: (header: string) =>
                removeZeroWidthChars(header.trim()),
            skipEmptyLines: "greedy",
        })
        setHasChanged(true)
    }, [csvFile])

    const parse = () => {
        setRawData(
            rawData.map((row) => ({
                ...row,
                rowStatus: LOADING,
            }))
        )
        parser.parse(rawData).then(({ results, errors }) => {
            // Set the status of all rows as loading until onParse is done
            // Update the row errors
            setRowErrors(errors)

            // Add a callback to update each status
            return onParse(
                results.map((item, idx) => [
                    item,
                    getUpdateStatusCallback(idx),
                ]),
                cleanUp
            )
        })
        setHasChanged(false)
    }
    const inputRef = useRef<HTMLInputElement>(null)

    return (
        <div>
            {!isOpen && (
                <div
                    onClick={() =>
                        csvFile
                            ? setCSVFile(undefined)
                            : inputRef.current?.click()
                    }
                    onDrop={(event) => {
                        event.preventDefault()
                        if (!event.dataTransfer.files) setCSVFile(undefined)
                        else {
                            setCSVFile(event.dataTransfer.files[0])
                            setIsOpen(true)
                        }
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    className={[
                        "group flex justify-center items-center",
                        "cursor-pointer text-center",
                        "w-full h-16 p-1.5",
                        "border-dashed border border-gray-14 rounded-md",
                        "bg-gray-3 text-gray-30 hover:bg-gray-5 body-lg font-normal",
                    ].join(" ")}>
                    {!csvFile &&
                        t(
                            "dragDropFileInstructions",
                            "Click here or drop file to upload"
                        )}
                    {csvFile && (
                        <div className="flex items-center justify-center w-full">
                            {csvFile.name}
                            <span className="w-5 h-5 mx-1 fill-gray-30 group-hover:fill-red">
                                <CancelIcon />
                            </span>
                        </div>
                    )}
                    <input
                        ref={inputRef}
                        type="file"
                        accept=".csv"
                        onChange={(event) => {
                            if (!event.target.files) setCSVFile(undefined)
                            else {
                                setCSVFile(event.target.files[0])
                                setIsOpen(true)
                            }
                        }}
                        className="hidden"
                    />
                </div>
            )}
            {isOpen && (
                <EmptyModal
                    customClasses="relative rounded-lg bg-white w-[80vw] h-[90vh]"
                    modalRef={modalRef}>
                    <div className="flex flex-col h-full divide-y divide-gray-14 gap-1">
                        {/* HEADER */}
                        <div className="flex flex-col p-3 gap-3 grow-0">
                            <div className="flex flex-row items-center justify-between">
                                <div className="flex flex-col">
                                    <span className="font-medium title-lg">
                                        {csvFile?.name ?? "New upload"}
                                    </span>
                                    <span className="body-md text-gray-60">
                                        {t(
                                            "clickCellEdit",
                                            "Click on a cell to edit it"
                                        )}
                                    </span>
                                </div>
                                <span
                                    onClick={cleanUp}
                                    className="w-6 h-6 cursor-pointer fill-gray-60 hover:fill-gray-90">
                                    <CancelIcon />
                                </span>
                            </div>
                            {errors.length > 0 && (
                                <div className="p-2 text-sm rounded-lg bg-red-light text-red">
                                    <span className="font-semibold">
                                        {t("errors", "Errors")}
                                    </span>
                                    <ul className="list-disc list-inside">
                                        {errors.map((error) => (
                                            <li key={error}>{error}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                        {/* BODY */}
                        <div className="w-full h-[50%] grow">
                            <ParserTable
                                columns={parser.columns}
                                data={aggregatedData}
                                updateData={updateData}
                            />
                        </div>
                        {/* FOOTER */}
                        <div className="flex flex-row items-center justify-between p-3 grow-0">
                            <div>{errors.length === 0}</div>
                            <Button
                                disabled={!hasChanged || errors.length > 0}
                                label={t("finishImportBtn", "Finish Import")}
                                onClick={parse}
                            />
                        </div>
                    </div>
                </EmptyModal>
            )}
        </div>
    )
}

export default BulkUpload
