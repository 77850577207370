const PercentageIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M7.5 11C6.53333 11 5.70833 10.6583 5.025 9.975C4.34167 9.29167 4 8.46667 4 7.5C4 6.53333 4.34167 5.70833 5.025 5.025C5.70833 4.34167 6.53333 4 7.5 4C8.46667 4 9.29167 4.34167 9.975 5.025C10.6583 5.70833 11 6.53333 11 7.5C11 8.46667 10.6583 9.29167 9.975 9.975C9.29167 10.6583 8.46667 11 7.5 11ZM7.5 9C7.91667 9 8.27083 8.85417 8.5625 8.5625C8.85417 8.27083 9 7.91667 9 7.5C9 7.08333 8.85417 6.72917 8.5625 6.4375C8.27083 6.14583 7.91667 6 7.5 6C7.08333 6 6.72917 6.14583 6.4375 6.4375C6.14583 6.72917 6 7.08333 6 7.5C6 7.91667 6.14583 8.27083 6.4375 8.5625C6.72917 8.85417 7.08333 9 7.5 9ZM16.5 20C15.5333 20 14.7083 19.6583 14.025 18.975C13.3417 18.2917 13 17.4667 13 16.5C13 15.5333 13.3417 14.7083 14.025 14.025C14.7083 13.3417 15.5333 13 16.5 13C17.4667 13 18.2917 13.3417 18.975 14.025C19.6583 14.7083 20 15.5333 20 16.5C20 17.4667 19.6583 18.2917 18.975 18.975C18.2917 19.6583 17.4667 20 16.5 20ZM16.5 18C16.9167 18 17.2708 17.8542 17.5625 17.5625C17.8542 17.2708 18 16.9167 18 16.5C18 16.0833 17.8542 15.7292 17.5625 15.4375C17.2708 15.1458 16.9167 15 16.5 15C16.0833 15 15.7292 15.1458 15.4375 15.4375C15.1458 15.7292 15 16.0833 15 16.5C15 16.9167 15.1458 17.2708 15.4375 17.5625C15.7292 17.8542 16.0833 18 16.5 18ZM5.4 20L4 18.6L18.6 4L20 5.4L5.4 20Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default PercentageIcon
