import { FC } from "react"
import { IconProps } from "./types"

export const AreaIcon: FC<IconProps> = ({ backgroundColor, opacity }) => {
    return (
        <div
            style={{
                backgroundColor: backgroundColor,
                opacity: opacity,
            }}
            className="p-2 m-2" />
    )
}
