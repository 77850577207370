import { ReactNode } from "react"

const BackgroundAndCardLayout = ({
    backgroundImage = "var(--background-signin)",
    copyright = "all rights reserved",
    children,
}: {
    backgroundImage?: string
    copyright?: string
    children: ReactNode | ReactNode[]
}) => {
    const { signInLogo: Logo } = window.dashboard
    const currentYear = new Date().getFullYear()

    return (
        <div
            className="w-full h-full bg-cover bg-center bg-no-repeat sm:py-[80px] overflow-y-auto text-gray-90"
            style={{ backgroundImage }}>
            <div
                className={[
                    "bg-white sm:rounded-lg sm:border-[1px] border-gray-14",
                    "px-4 py-8 sm:px-[40px] sm:pt-[20px] sm:pb-[30px]",
                    "h-full sm:h-fit w-full sm:max-w-[400px]",
                    "sm:mx-auto",
                    "overflow-auto",
                ].join(" ")}>
                <div
                    className={[
                        "w-full max-w-[400px] min-w-[240px] mx-auto",
                        "flex flex-col items-center space-y-[18px]",
                        "pt-4",
                    ].join(" ")}>
                    {Logo && <Logo />}

                    {children}

                    <div className="body-sm text-gray-30">
                        &#9400;ClimateAi {currentYear}, {copyright}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BackgroundAndCardLayout
