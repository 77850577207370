import { useTranslate } from "@tolgee/react"
import { gql } from "graphql-request"
import { useCallback, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import {
    ArrayParam,
    StringParam,
    useQueryParam,
    withDefault,
} from "use-query-params"
import { SingleSelectFilter } from "../../../../climateui/components"
import WidgetWrapper from "../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import { GenericPageHeader } from "../../../../components"
import { useAccount, useAssets } from "../../../../providers"
import { useYieldOutlook } from "../../YieldOutlook/provider"
import { IRegion } from "../../../../types"
import YieldOutlookTableWidget, {
    getDirectionalityHighlightColor,
} from "../../../../components/YieldOutlook/YieldOutlookTableWidget"
import DirectionalityHover from "../../../../climateui/components/Widgets/SeasonalWidgets/DirectionalityHover"
import YieldOutlookMap from "../../../../components/YieldOutlook/YieldOutlookMap"
import YieldOutlookChart from "../../../../components/YieldOutlook/YieldOutlookChart"
import YieldOutlookSummary from "../../../../components/YieldOutlook/YieldOutlookSummary"
import YieldOutlookChange from "../../../../components/YieldOutlook/YieldOutlookChange"
import useYieldUnits from "../../../../hooks/YieldOutlook/useYieldUnits"
import {
    MultiDropdownSelect,
    SingleDropdownSelect,
} from "../../../../climateui/components/Inputs"
import { FilterIcon } from "../../../../climateui/icons"

const renderYieldDirectionalityHoverWrapper = (d: any) => {
    const probabilities = {
        "prob_0-0.33":
            d?.meta?.data?.tercile_probabilities?.["prob_0.00-0.33"] ?? 0,
        "prob_0.33-0.67":
            d?.meta?.data?.tercile_probabilities?.["prob_0.33-0.67"] ?? 0,
        "prob_0.67-1":
            d?.meta?.data?.tercile_probabilities?.["prob_0.67-1.00"] ?? 0,
    }
    const quantiles = {
        "quantile_0.33":
            d?.meta?.data?.historical_baseline?.yield_terciles?.[0] ?? 0,
        "quantile_0.67":
            d?.meta?.data?.historical_baseline?.yield_terciles?.[1] ?? 0,
    }
    return (
        <DirectionalityHover
            directionality={d.directionality}
            personalizedColor={
                getDirectionalityHighlightColor(d?.directionality) ?? ""
            }
            title={"Directional Yield Outlook"}
            description={d.meta.decade + " - " + (parseInt(d.meta.decade) + 9)}
            rightHeader={"Probability"}
            probabilities={probabilities}
            quantiles={quantiles}
            units={"%"}
        />
    )
}

const yieldOutlookChartConfigs = {
    processItems: false,
    directionalChart: true,
    centerTicks: true,
    centerPoints: true,
    customHover: renderYieldDirectionalityHoverWrapper,
    lineAreaChartProps: {
        tooltip: null,
        labelY: "Yield Impact (%)",
        yBottomOffset: 0,
        yTopOffset: 0,
        svgHeight: 300,
        yTicksCount: 3,
        xTickFormat: (d: Date) => {
            const next = new Date(d)
            next.setFullYear(d.getFullYear() + 9)

            return `${d.getFullYear()} - ${next.getFullYear()}`
        },
        // TODO this is hardcoded for now, the plot adjusting to the data
        // correctly should be part of the chart refactor
        xDateMin: new Date("Jan 1, 2010"),
        xDateMax: new Date("Jan 1, 2070"),
    },
}

const getRegionsAndStatesDict = (
    availableStates: IRegion[],
    countries: Record<string, IRegion>
) => {
    const statesRegionsDict: Record<string, Record<string, string>> = {}
    const regionsDict: Record<string, string> = {}
    const countriesIdsForAssetModel = new Set()
    availableStates.forEach((state) => {
        countriesIdsForAssetModel.add(state.parent_id)
    })

    if (Object.keys(countries)?.length > 0) {
        Object.keys(countries).forEach((country) => {
            if (country && countriesIdsForAssetModel.has(country)) {
                regionsDict[country] = countries[country].name
            }
        })
    }

    if (availableStates?.length > 0) {
        availableStates.forEach((region: IRegion) => {
            if (region.id && region.parent_id) {
                if (!statesRegionsDict[region.parent_id]) {
                    statesRegionsDict[region.parent_id] = {}
                }
                statesRegionsDict[region.parent_id][region.id] = region.name
            }
        })
    }

    return { statesRegionsDict, regionsDict }
}

function YieldOutlookDashboard() {
    const { t } = useTranslate()
    const { allAssets } = useAssets()
    const { assetId } = useParams()
    const { selectedAccount } = useAccount()
    const assetModelName = allAssets?.[assetId as string]?.name
    const { states, assetModels, countries, isLoading } = useYieldOutlook()
    // const { options: unitOptions } = useYieldUnits()
    const getStateOptions = useCallback(
        (parentRegionID: string | undefined) => {
            let filteredStates: IRegion[] = states
            if (parentRegionID) {
                filteredStates = states.filter(
                    ({ parent_id }) => parent_id === parentRegionID
                )
            }
            return filteredStates.reduce(
                (prev, { id, name }) => {
                    if (id) {
                        prev[id] = name
                    }
                    return prev
                },
                {} as Record<string, string>
            )
        },
        [states]
    )
    const countriesOpts = useMemo(() => {
        if (!countries) return {}
        const entries = Object.entries(countries)
        if (entries.length === 0) return
        return entries.reduce(
            (prev, [id, country]) => {
                prev[id] = country.name
                return prev
            },
            {} as Record<string, string>
        )
    }, [countries])

    const regionsInModelsIds = useMemo(() => {
        if (!assetId || !assetModels) return []
        const modelsPerAssetId = assetModels.filter((model) => {
            return model.asset_id === assetId
        })
        return modelsPerAssetId.map((model) => model.region_id)
    }, [assetId, assetModels])

    const { statesRegionsDict, regionsDict } = useMemo(() => {
        if (regionsInModelsIds.length === 0)
            return { statesRegionsDict: {}, regionsDict: {} }
        return getRegionsAndStatesDict(
            states.filter((state) => {
                return regionsInModelsIds?.includes(state.id as string)
            }),
            countries
        )
    }, [regionsInModelsIds, states, countries])

    /*
     * Will be used later when adding schedules to Yield Dashboards :)
     * const yieldOutlookURL = new URL(window.location.href)
     * const urlParams = new URLSearchParams(yieldOutlookURL.search)
     */

    const regionParam = withDefault(StringParam, Object.keys(regionsDict)[0])
    const [selectedRegion, setSelectedRegion] = useQueryParam(
        "region",
        regionParam
    )

    const statesParam = withDefault(ArrayParam, [])
    const [selectedStates, setSelectedStates] = useQueryParam(
        "state",
        statesParam
    )
    const selectedStatesOrAll = useMemo(() => {
        if (selectedStates?.length > 0) return selectedStates
        if (selectedRegion) return Object.keys(getStateOptions(selectedRegion))
        return []
    }, [selectedStates, selectedRegion])

    const decadeParam = withDefault(StringParam, "2030")
    const [selectedDecade, setSelectedDecade] = useQueryParam(
        "decade",
        decadeParam
    )

    const scenarioParam = withDefault(StringParam, "mid_emission")
    const [selectedScenario, setSelectedScenario] = useQueryParam(
        "scenario",
        scenarioParam
    )

    const { unitOptions, units, setUnits } = useYieldUnits(
        assetModels ?? [],
        selectedRegion,
        assetId ?? "",
        isLoading
    )

    useEffect(() => {
        setSelectedStates([])
    }, [selectedAccount])

    //Yield Outlook IDashboard
    const MIN_DECADE = 2010
    const MAX_DECADE = 2060
    const DECADE_STEP = 10
    const decades = Array.from(
        { length: (MAX_DECADE - MIN_DECADE) / DECADE_STEP + 1 },
        (_, idx) => MIN_DECADE + DECADE_STEP * idx
    )
    const decadeOptions = decades.reduce(
        (prev: Record<string, string>, currDecade) => {
            prev[currDecade.toString()] = `${currDecade} - ${currDecade + 9}`
            return prev
        },
        {}
    )
    const emissionScenariosDict = {
        low_emission: t("lowCO2emissions", "Low CO2 Emissions"),
        mid_emission: t("middleOfTheRoad", "Middle of the Road"),
        high_emission: t("highCO2emissions", "High CO2 Emissions"),
    }
    const yieldDashboardFilters = useMemo(() => {
        return [
            {
                propName: "decade",
                value: +selectedDecade,
                loading: false,
            },
            {
                propName: "scenario",
                value: selectedScenario,
                loading: false,
            },
            {
                propName: "asset_ids",
                value: [assetId],
            },
        ]
    }, [
        selectedDecade,
        selectedRegion,
        selectedStates,
        selectedScenario,
        assetId,
    ])

    const chartSelectors = useMemo(
        () => ({
            title: t("directionalYieldOutlookOverTime"),
            $items: "yield_outlook_model.results[0].stats.plot",
            ...yieldOutlookChartConfigs,
        }),
        [yieldOutlookChartConfigs]
    )

    return (
        <div
            className="w-full h-full p-8 overflow-y-scroll bg-gray-1.5"
            id="yield-dashboard-container">
            <GenericPageHeader
                pageTitle={
                    assetModelName && !isLoading ? (
                        <div>
                            {t("yieldOutlookDashboard", {
                                asset: assetModelName,
                            })}
                        </div>
                    ) : (
                        <div className="text-gray-30">
                            {t("loadingYieldDashboard")}
                        </div>
                    )
                }
            />
            <div className="mb-[14px] flex flex-wrap gap-2 align-baseline -mt-5">
                <SingleSelectFilter
                    key="region"
                    placeholder={t("region", "Region")}
                    leftRightClass="left-0"
                    selected={selectedRegion}
                    setSelected={(v) => {
                        setSelectedRegion(v)
                        setSelectedStates([])
                    }}
                    options={countriesOpts}
                />
                <MultiDropdownSelect
                    key="states"
                    searchPlaceholder={t("searchState", "Search state")}
                    canSearch
                    placeholder={t("state", "State")}
                    selected={selectedStates}
                    setSelected={setSelectedStates}
                    options={getStateOptions(selectedRegion)}
                    trailingIcon={<FilterIcon />}
                />
                <SingleSelectFilter
                    key="decade"
                    placeholder={t("decade", "Decade")}
                    leftRightClass="left-0"
                    options={decadeOptions}
                    sortByKey={false}
                    selected={selectedDecade}
                    setSelected={(v) => {
                        setSelectedDecade(v.toString())
                    }}
                />
                <SingleSelectFilter
                    key="emissionsScenario"
                    placeholder={t("emissionScenarios", "Emission Scenarios")}
                    leftRightClass="left-0"
                    options={emissionScenariosDict}
                    selected={selectedScenario}
                    setSelected={setSelectedScenario}
                    keepOrder
                />
                <SingleDropdownSelect
                    options={unitOptions}
                    selected={units.replace("/", "_")}
                    setSelected={setUnits}
                />
            </div>
            <div className="flex gap-[14px] mb-[14px]">
                <div className="w-3/4 bg-white border rounded-lg font-roboto border-1 border-gray-14">
                    <YieldOutlookMap
                        type="adapt"
                        filters={[
                            ...yieldDashboardFilters,
                            {
                                propName: "region_ids",
                                value:
                                    selectedStates.length === 0 &&
                                    statesRegionsDict[selectedRegion]
                                        ? Object.keys(
                                              statesRegionsDict[selectedRegion]
                                          )
                                        : selectedStates,
                                loading: isLoading,
                            },
                        ]}
                    />
                </div>

                <div className="flex flex-wrap w-1/2">
                    <YieldOutlookChart
                        type="adapt"
                        filters={[
                            ...yieldDashboardFilters,
                            {
                                propName: "minDecade",
                                value: 2010,
                                loading: false,
                            },
                            {
                                propName: "maxDecade",
                                value: 2070,
                                loading: false,
                            },
                            {
                                propName: "region_ids",
                                value: selectedRegion,
                                loading: isLoading,
                            },
                            {
                                propName: "configs",
                                value: [
                                    {
                                        visualization: "line",
                                        color: "#F06000",
                                        date: "date",
                                        y: "ensemble_mean",
                                        directionality: "directionality",
                                    },
                                    {
                                        visualization: "area",
                                        opacity: 0.1,
                                        color: "#F06000",
                                        date: "date",
                                        y0: "q0.05",
                                        y1: "q0.95",
                                    },
                                    {
                                        visualization: "area",
                                        opacity: 0.1,
                                        color: "#F06000",
                                        date: "date",
                                        y0: "q0.25",
                                        y1: "q0.75",
                                    },
                                ],
                                loading: false,
                            },
                        ]}
                        selectors={chartSelectors}
                    />
                    <div className="w-full flex gap-x-[14px]">
                        <YieldOutlookSummary
                            type="adapt"
                            filters={[
                                ...yieldDashboardFilters,
                                {
                                    propName: "region_ids",
                                    value: selectedRegion,
                                    loading: isLoading,
                                },
                            ]}
                            selectors={{
                                title: t("countryYieldOutlookWidgetTitle"),
                                $data: "yield_outlook_model.results[0].stats",
                            }}
                        />
                        <YieldOutlookChange
                            type="adapt"
                            filters={[
                                ...yieldDashboardFilters,
                                {
                                    propName: "region_ids",
                                    value: selectedRegion,
                                    loading: isLoading,
                                },
                            ]}
                            selectors={{
                                $data: "yield_outlook_model.results[0].stats",
                                title: t("expectedChangeWidgetTitle"),
                                units,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div>
                <WidgetWrapper
                    component={YieldOutlookTableWidget}
                    selectedFilters={[
                        "decade",
                        "scenario",
                        "region_ids",
                        "asset_ids",
                    ]}
                    query={gql`
                        query (
                            $decade: Int
                            $scenario: String
                            $region_ids: [String]
                            $asset_ids: [String]
                        ) {
                            yield_outlook_model(
                                filter: {
                                    region_ids: $region_ids
                                    asset_ids: $asset_ids
                                }
                            ) {
                                results {
                                    territory: region {
                                        id
                                        name
                                        resolution
                                    }
                                    stats: decadal_stats(
                                        filter: {
                                            scenario: $scenario
                                            decade: $decade
                                        }
                                    ) {
                                        results {
                                            scenario
                                            decade
                                            tercile_probabilities
                                            outlook_distribution
                                            impact_drivers {
                                                name
                                                mean_impact
                                            }
                                            historical {
                                                yield_terciles
                                            }
                                            deviation_mean
                                            outlook_mean
                                        }
                                    }
                                }
                            }
                        }
                    `}
                    selectors={{
                        title: t("yieldWidgetTable"),
                        $data: "yield_outlook_model.results[]",
                        errorMessage: t("errorLoadingData"),
                        reloadMessage: t("clickReload"),
                        noResultsMessage: t("noResultCurrentFilters"),
                        tryAnotherFilterMessage: t("tryAnotherFilter"),
                        columns: [
                            // LOCATION
                            {
                                propName: "territory",
                                header: `${t("region", "Region")}`,
                                type: "text",
                                selector: {
                                    text: "{{ territory.name }}",
                                },
                                columns: [
                                    {
                                        propName: "territory",
                                        type: "text",
                                    },
                                ],
                            },
                        ],
                        units,
                    }}
                    filters={[
                        ...yieldDashboardFilters,
                        {
                            propName: "region_ids",
                            value: selectedStatesOrAll,
                            loading: isLoading,
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default YieldOutlookDashboard
