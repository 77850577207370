import { useTranslate } from "@tolgee/react"
import { useContext, useEffect, useMemo, useState } from "react"
import {
    Button,
    DebounceSearchInput,
    Table,
} from "../../../../climateui/components"
import { IPrimitivesDictionary } from "../../../../climateui/types"
import { HalfMapLayout } from "../../../../layouts"
import { ResponsivePaddingWrapper } from "../../../../layouts/TabLayout"
import { useAuth } from "../../../../providers"
import { useAccount } from "../../../../providers/AccountProvider"
import { LocationsContext } from "../../../../providers/LocationsProvider"
import { useDashboard } from "../../../../providers/DashboardProvider"
import { IInsightsLocation, IDashboardLocation } from "../../../../types"
import { buildLocationColumns } from "../../../Admin/Locations/adminLocationsTableUtils"
import LocationsTableFilters from "../../../Admin/Locations/components/LocationsTableFilters"
import { IMap, IMapPin } from "../../../../climateui/components/Map/utils"
import { usePaginationFlag } from "../../../../hooks"

const dashboardLocationsColumns = [
    "selection",
    "name",
    "region",
    "varieties",
    "labels",
]
const columns = buildLocationColumns(dashboardLocationsColumns)

function DashboardLocations() {
    const auth = useAuth()
    const { selectedAccount } = useAccount()
    const { goToStep, workingDashboard, setWorkingDashboard } = useDashboard()
    const {
        locations,
        loadingLocations,
        tableFilteredCallback,
        filteredPageLocationPins,
    } = useContext(LocationsContext)
    const [globalFilter, setGlobalFilter] = useState("")
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [rowSelection, setRowSelection] = useState<any>({})
    const [columnFilters, setColumnFilters] = useState<
        { id: string; value: string[] | unknown }[]
    >([])

    useEffect(() => {
        const locationsArray = workingDashboard?.locations || []
        if (
            workingDashboard?.locations &&
            Object.keys(workingDashboard.locations).length > 0
        ) {
            const newRowSelection: IPrimitivesDictionary = {}
            for (const obj in locationsArray) {
                newRowSelection[locationsArray[obj].location_id as string] =
                    true
            }
            setRowSelection(newRowSelection)
        }
    }, [workingDashboard?.locations])

    const { t } = useTranslate()

    const [doTableFilterCallbackCount, setDoTableFilterCallbackCount] =
        useState(0)

    useEffect(() => {
        // INFO: doTableFilterCallbackCount is useful to update the map pins
        // to what the table is showing. Maybe this could be done less erizo.
        // TODO: IMPROVE
        setDoTableFilterCallbackCount(doTableFilterCallbackCount + 1)
    }, [locations, globalFilter, columnFilters])

    const next = () => {
        // Add selected locations to working Dashoard, including its varieties
        const dashboardLocationsIds =
            workingDashboard?.locations &&
            (workingDashboard?.locations as IDashboardLocation[]).map(
                (location) => location.location_id
            )
        //locationsToAdd --> Objects Array with info about locations linked to dashboard
        const locationsToAdd: IDashboardLocation[] = []

        Object.keys(rowSelection).forEach((key) => {
            // Validation --> If location is not already part of dashboard, the following
            // object is sent, including an addition of preselected varieties linked to the specific loc.
            if (!dashboardLocationsIds?.includes(key)) {
                //Get each location's linked varieties
                const loc =
                    locations.find((location) => {
                        return location.id == key
                    }) || ({} as IInsightsLocation)
                const varietiesPerLoc = loc.varieties.map(
                    (variety) => variety.id
                )
                locationsToAdd.push({
                    location_id: key,
                    asset: "",
                    active: true,
                    varieties: varietiesPerLoc,
                })
            }

            // Validation --> If location is already part of dashboard, the location object is sent
            // with no changes or additions of information.
            else {
                const dashLocation =
                    (workingDashboard?.locations &&
                        workingDashboard?.locations?.find((location) => {
                            return location.location_id == key
                        })) ||
                    ([] as IDashboardLocation)

                // NOTE: When editing dashboarrds

                locationsToAdd.push({
                    location_id: key,
                    asset: "",
                    active: true,
                    varieties:
                        workingDashboard?.id &&
                        dashLocation.varieties &&
                        dashLocation.varieties?.length != 0 &&
                        dashLocation.varieties[0].variety_id
                            ? dashLocation.varieties?.map(
                                  // TODO @sici fix dashboard variety interface
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  (variety: any) => variety.variety_id
                              )
                            : dashLocation.varieties,
                })
            }
        })
        setWorkingDashboard({
            ...workingDashboard,
            locations: locationsToAdd,
        })
        goToStep("assets")
    }

    const prev = () => {
        goToStep("template")
    }

    const { pins }: IMap = useMemo(() => {
        const newPins: IMapPin[] = [...filteredPageLocationPins]
        newPins.forEach((pin) => {
            if (pin.id && rowSelection[pin.id]) {
                pin.pinStyle = "map-pin-white.png"
            } else {
                pin.pinStyle = "map-pin.png"
            }
        })
        return { pins: newPins }
    }, [filteredPageLocationPins, rowSelection])

    const pageSizeOpts = usePaginationFlag({
        rowTolgeeKey: "locations",
    })

    // >>>>>>>>>>>>>>>>>
    // SORTING LOCATIONS
    // <<<<<<<<<<<<<<<<<
    const sortedLocation = useMemo(
        () =>
            [...locations].sort((locationA, locationB) => {
                // SORT BY SELECTION
                const locationASelected = locationA.id
                    ? rowSelection[locationA.id]
                    : false
                const locationBSelected = locationB.id
                    ? rowSelection[locationB.id]
                    : false
                if (locationASelected && !locationBSelected) return -1
                if (!locationASelected && locationBSelected) return 1

                // SORT ALPHABETICALLY
                if (locationA.name > locationB.name) return 1
                if (locationA.name < locationB.name) return -1
                return 0
            }),
        [locations, rowSelection]
    )

    return (
        <div className="w-full h-[82%]">
            <div className="flex items-center justify-between p-3 px-5">
                <h1 className="ml-3 font-poppins">
                    {t("clickCheckboxLocations")}
                </h1>
                <div className="flex justify-between px-3">
                    <Button
                        type="secondary"
                        label={t("back")}
                        onClick={prev}
                        extraClasses="mr-2"
                    />
                    <Button
                        label={t("continue")}
                        disabled={
                            !rowSelection ||
                            Object.keys(rowSelection)?.length === 0
                        }
                        onClick={next}
                    />
                </div>
            </div>
            <HalfMapLayout
                mapProps={{
                    pins: pins,
                    mapConfigs: {
                        minZoom: 1.1,
                        maxZoom: 18,
                        renderWorldCopies: false,
                    },
                }}>
                <ResponsivePaddingWrapper extraClasses="sm:p-3 sm:px-5 elevation-1 ">
                    <div className="flex flex-col h-full -mt-6 grow gap-2 ">
                        <div className="flex items-center justify-between">
                            <div className="flex justify-start w-3/5 gap-2">
                                <LocationsTableFilters
                                    setColumnFilters={setColumnFilters}
                                    columnFilters={columnFilters}
                                />
                            </div>
                            <div className="w-48">
                                <DebounceSearchInput
                                    placeholder={t("search")}
                                    onSearch={setGlobalFilter}
                                />
                            </div>
                        </div>

                        <div className="overflow-y-auto grow">
                            <Table<IInsightsLocation>
                                data={sortedLocation}
                                columns={columns}
                                paginationOptions={pageSizeOpts}
                                state={{
                                    rowSelection,
                                    globalFilter,
                                    columnFilters,
                                    hiddenColumns: [],
                                }}
                                getRowId={(
                                    location: IInsightsLocation,
                                    index: number
                                ) => location?.id ?? index.toString()}
                                setRowSelection={setRowSelection}
                                setGlobalFilter={setGlobalFilter}
                                setColumnFilters={setColumnFilters}
                                noDataMessage={
                                    loadingLocations
                                        ? t(
                                              "loadingLocations",
                                              "Loading locations..."
                                          )
                                        : t("thereAreNoLocationsROLE", {
                                              role: auth.hasRole(
                                                  selectedAccount ?? "",
                                                  "Admin"
                                              )
                                                  ? "Admin"
                                                  : "User",
                                          })
                                }
                                extraClasses=""
                                tableFilteredCallback={tableFilteredCallback}
                                doTableFilterCallbackCount={
                                    doTableFilterCallbackCount
                                }
                            />
                        </div>
                    </div>
                </ResponsivePaddingWrapper>
            </HalfMapLayout>
        </div>
    )
}

export default DashboardLocations
