import Tooltip from "../climateui/components/Tooltip"
import { AltArrowLeft } from "../climateui/icons"

import { ReactNode } from "react"

export interface IGenericPageHeader {
    pageTitle: ReactNode
    pageSubtitle?: ReactNode
    goBackFunction?: () => void
    right?: ReactNode
    bottom?: ReactNode
    extraClasses?: string
    bottomSectionPadding?: string
}

const GenericPageHeader = ({
    pageTitle,
    pageSubtitle,
    goBackFunction,
    right,
    bottom,
    extraClasses,
    bottomSectionPadding = "pt-4",
}: IGenericPageHeader) => {
    return (
        <>
            <div
                className={
                    "flex flex-row items-center justify-between gap-2 text-gray-90 min-h-[52px]  " +
                    extraClasses
                }>
                <div className="flex flex-row items-center w-full min-w-0 space-x-2">
                    {goBackFunction && (
                        <Tooltip
                            content="Go Back"
                            position="bottom">
                            <span
                                className={
                                    "w-6 hover:bg-gray-5 rounded-lg hover:scale-[1.04] transition-all duration-100 cursor-pointer " +
                                    (pageSubtitle ? "-mt-3" : "")
                                }
                                onClick={goBackFunction}>
                                <AltArrowLeft />
                            </span>
                        </Tooltip>
                    )}
                    <div className="flex flex-col w-full">
                        <h2 className="title-lg text-gray-90 whitespace-nowrap">
                            {pageTitle}
                        </h2>
                        {pageSubtitle && (
                            <div className="body-md text-gray-60">
                                {pageSubtitle}
                            </div>
                        )}
                    </div>
                </div>
                <div>{right}</div>
            </div>
            <div
                className={
                    "flex flex-row flex-wrap items-center gap-1 " +
                    bottomSectionPadding
                }>
                {bottom}
            </div>
        </>
    )
}

export default GenericPageHeader
