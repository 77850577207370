import { IVariety, VARIETY_EMPTY_INITIAL_DATE } from "../types"

export function isAccountDefaultVariety(
    variety: IVariety,
    account_id: string
) {
    return variety.account_id === account_id && variety.is_default
}

export function isCaiDefaultVariety(variety: IVariety) {
    return isAccountDefaultVariety(variety, "Default")
}

export function hasInitialDate(variety: IVariety) {
    return (
        variety.initial_date &&
        variety.initial_date !== VARIETY_EMPTY_INITIAL_DATE
    )
}
