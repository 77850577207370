import { SVGProps } from "react"

const AboveIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 22 22"
        className={"fill-inherit " + props.className}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 3.91504L1.70703 7.20801L0.292819 5.79379L5.99993 0.0866866L11.707 5.79379L10.2928 7.20801L7 3.91519L7 13.501L5 13.501L5 3.91504Z"
            fill={props.fill}
        />
    </svg>
)

export default AboveIcon
