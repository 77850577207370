import { useState, Fragment, ReactNode } from "react"
import { ArrowBottom, UserIcon } from "../climateui/icons"
import { Transition } from "@headlessui/react"
import { useOutsideComponentClickHandler } from "../climateui/hooks"
import { useNavigate } from "react-router-dom"

const optionClasses = [
    "mt-1 transition-all w-full",
    "flex flex-row items-center justify-between",
    "text-gray-60 fill-gray-60",
    "text-left whitespace-nowrap",
    "hover:font-semibold",
    "hover:text-gray-90",
    "hover:fill-gray-90",
    "hover:translate-x-1",
].join(" ")

interface IDropdownOption {
    to?: string
    onClick: () => void
    label: string
    icon?: ReactNode
}

function UserCircleMenuDropdownOption({
    option,
    toggle,
}: {
    option: IDropdownOption
    toggle: (toToggle: boolean) => void
}) {
    const navigate = useNavigate()

    return (
        <button
            onClick={() => {
                toggle(false)
                if (option.to) navigate(option.to)
                else option.onClick()
            }}
            className={optionClasses}>
            <label className="label-lg">{option.label}</label>
            {option.icon && <span className="w-4 h-4">{option.icon}</span>}
        </button>
    )
}

function UserCircleMenuDropdown({
    firstLetter,
    name,
    colorClass = "bg-accent",
    options,
}: {
    firstLetter?: string
    name?: string
    colorClass?: string
    options: IDropdownOption[]
}) {
    const [open, toggle] = useState(false)

    const dropdownRef = useOutsideComponentClickHandler(() => {
        if (open) toggle(false)
    })

    return (
        <div
            className="relative"
            ref={dropdownRef}>
            <div
                className="flex flex-row items-center cursor-pointer gap-0"
                onClick={() => toggle(!open)}>
                <div
                    className={[
                        "h-9 w-9 rounded-full select-none",
                        "flex flex-row items-center justify-center",
                        "uppercase text-white title-sm",
                        colorClass,
                    ].join(" ")}>
                    {/* TODO: Check how does the UserIcon look*/}
                    {firstLetter || <UserIcon fill="#FFF" />}
                </div>
                <div className="w-6 h-6 fill-gray-3">
                    <ArrowBottom />
                </div>
            </div>

            <Transition
                show={open}
                as={Fragment}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-200"
                    enterFrom="opacity-0 mt-0"
                    enterTo="opacity-100 mt-2"
                    leave="ease-in duration-75"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div
                        className={[
                            "absolute right-2 top-full",
                            "w-fit p-2 z-50",
                            "bg-white rounded-lg elevation-2",
                            "border border-gray-10",
                        ].join(" ")}>
                        <div className="w-full pb-1 mb-2 border-b label-lg border-gray-14">
                            {name ?? "User"}
                        </div>

                        {options.map((option) => (
                            <UserCircleMenuDropdownOption
                                key={"profile-option-" + option.label}
                                option={option}
                                toggle={toggle}
                            />
                        ))}
                    </div>
                </Transition.Child>
            </Transition>
        </div>
    )
}

export default UserCircleMenuDropdown
