import BelowIcon from "../../icons/directionalityIcons/BelowIcon"
import WithinIcon from "../../icons/directionalityIcons/WithinIcon"
import { AsteriskIcon } from "../../icons"

export interface IDirectionalityPalette {
    noSignal?: string
    below: string
    within: string
    above: string
}

function getColorAndSymbol(
    value: string,
    display: string,
    colorPalette: IDirectionalityPalette
) {
    let tableDirectionalityProps = ""
    let chartDirecionalityProps = "bg-white rounded-full p-[6px]"
    switch (value) {
        case "-2":
            return (
                <AsteriskIcon
                    fill={colorPalette?.noSignal}
                    className={
                        display === "chart"
                            ? chartDirecionalityProps
                            : tableDirectionalityProps
                    }
                />
            )
        case "-1":
            return (
                <BelowIcon
                    fill={colorPalette?.below}
                    className={
                        display === "chart"
                            ? chartDirecionalityProps
                            : tableDirectionalityProps
                    }
                />
            )

        case "0":
            return (
                <WithinIcon
                    fill={colorPalette?.within}
                    className={
                        display === "chart"
                            ? chartDirecionalityProps
                            : tableDirectionalityProps
                    }
                />
            )
        case "1":
            return (
                <BelowIcon
                    fill={colorPalette?.above}
                    className={`${
                        display === "chart"
                            ? chartDirecionalityProps
                            : tableDirectionalityProps
                    }
                     rotate-180`}
                />
            )
        default:
            return <div></div>
    }
}

export default getColorAndSymbol
