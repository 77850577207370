import { useTranslate } from "@tolgee/react"
import SearchWidget from "../../../../../climateui/components/Widgets/SeasonalWidgets/SearchWidget"

function ExpectedChangeWidget(props: any) {
    const { title, loading, data, units } = props
    const formatter = Intl.NumberFormat(undefined, {
        maximumFractionDigits: 2,
    })
    const { t } = useTranslate()

    let yieldOutlookMeanValue = ""
    let yieldDeviationMeanValue = ""
    let expectedWidgetValueString = ""

    if (data?.results[0]) {
        const results = data.results[0]
        yieldOutlookMeanValue = formatter.format(results.outlook_mean)
        yieldDeviationMeanValue = formatter.format(results.deviation_mean * 100)
        expectedWidgetValueString = `${yieldDeviationMeanValue}% (${yieldOutlookMeanValue} ${units})`
    } else {
        expectedWidgetValueString = "_"
    }

    return (
        <SearchWidget
            title={title}
            value={expectedWidgetValueString}
            description={t("vsHistorialBaseline")}
            loading={loading}
            errorMessage={t("errorLoadingData")}
            reloadMessage={t("clickReload")}
            noResultsMessage={t("noResultCurrentFilters")}
            tryAnotherFilterMessage={t("tryAnotherFilter")}
            {...props}
        />
    )
}

export default ExpectedChangeWidget
