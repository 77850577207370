import { useState, useContext, useMemo, useEffect, ChangeEvent, useRef } from "react"
import {
    HazardProfileInput,
    IImpactFunction,
    ImpactProfileInput,
    IRiskProfile,
} from "../../../../types"
import { useTranslate } from "@tolgee/react"
import Lottie from "lottie-react"
import logoSpinner from "../../../../components/ClimateAi_Symbol_Animation.json"

/* COMPONENTS */
import {
    Button,
    LabelAndInput,
    TooltipV2,
} from "../../../../climateui/components"
import {
    GenericInput,
    MultiSelect,
} from "../../../../climateui/components/Inputs"
import LabelsForm from "../../../../climateui/components/Labels/LabelsForm"
import LabelItem from "../../../../climateui/components/Labels/LabelItem"
import HazardsField from "./HazardsField"
import { CancelIcon, PlusIcon, TrashIcon } from "../../../../climateui/icons"

/* HOOKS & CONTEXT */
import { useOutsideComponentClickHandler } from "../../../../climateui/hooks"

/* TYPES */
import { ConditionForm, templateCondition } from "./HazardCondition"
import {
    IBooleanDictionary,
    ILabel,
    IPrimitivesDictionary,
} from "../../../../climateui/types"

/* NETWORKING */
import { useQuery, useMutation, useQueryClient } from "react-query"
import {
    impactFunctionQuerySet,
    riskProfileQuerySet,
    rpLabelsQuerySet,
} from "../../../../utils/networking"
import {
    CustomResponse,
    isValidResponse,
} from "../../../../climateui/utils/http"
import { AxiosError } from "axios"
import { ModalContext, ToastContext } from "../../../../climateui/providers"
import { arrToDict, dataOrFallback } from "../../../../utils/transform"
import { useAccount } from "../../../../providers/AccountProvider"
import ImpactForm, { DEFAULT_VALUES } from "./ImpactForm"
import { UnitConversionContext } from "../../../../providers/UnitConversionProvider"
import { useRiskProfiles } from "../../../../providers/RiskProfilesProvider"
import { useAssets } from "../../../../providers"
import { useIsFlagEnabled, useMemoQuery } from "../../../../hooks"
import EmptyModal from "../../../../climateui/providers/Modal/EmptyModal"

export interface IRiskProfilePayload {
    account_id: string
    name: string
    type: string
    hazard_profiles: HazardProfileInput[]
    impact_profile?: ImpactProfileInput
    probability?: number
    labels: string[]
    varieties: string[]
}

interface IRiskProfileForm {
    onCancel: () => void
    riskProfile?: IRiskProfile
    showFullVersion?: boolean
    isOpen: boolean
    bottomLine?: string
    customOnSave?: (payload: IRiskProfilePayload) => void
}

const getImpactDefaults = (impactFunction?: IImpactFunction) => {
    const funcName = impactFunction ? impactFunction.image.replace(/\.\w{2,3}$/, "") : "default"
    return { ...DEFAULT_VALUES[funcName] }
}

function RiskProfileForm({
    onCancel = () => void 0,
    riskProfile,
    showFullVersion = true,
    isOpen,
    bottomLine,
    customOnSave,
}: IRiskProfileForm) {
    const { t } = useTranslate()
    const { hazardVariablesObj } = useRiskProfiles()
    const { convertUnits } = useContext(UnitConversionContext)
    const queryClient = useQueryClient()
    const { selectedAccount } = useAccount()
    const { enqueueAlert } = useContext(ToastContext)
    const { varietiesOptions } = useAssets()
    const isVarietiesEnabled = useIsFlagEnabled("ui_admin_assets_page_enabled")
    const isStageManagerEnabled = useIsFlagEnabled("feature_stage_manager")
    const [selectedVarieties, setSelectedVarieties] = useState<
        Record<string, boolean>
    >({})

    const [name, setName] = useState<string>("")
    const [type, setType] = useState<string>("ds_defined")
    const [probability, setProbability] = useState<number | undefined>(0.8)
    const [hazards, updateHazards] = useState<ConditionForm[][]>([
        [{ ...templateCondition }],
    ])

    const riskProfileRef = useRef<string | undefined>(undefined);
    const [areHazardsLoading, setAreHazardsLoading] = useState(true)
    const [impact, setImpact] = useState<ImpactProfileInput>()
    const { confirmationModal } = useContext(ModalContext)
    const { selectedHazard } = useRiskProfiles()
    /* LABELS ------------------------------------------------------------- */
    const [openedLabels, setOpenLabels] = useState(false)
    const [labels, setLabels] = useState<Record<string, ILabel>>({})
    /* TABS ------------------------------------------------------------- */
    const [activeTab, setActiveTab] = useState<"risk_tab" | "impact_tab">(
        "risk_tab"
    )
    const includeLabel = (label: ILabel) => {
        if (!label.id) return
        setOpenLabels(false)
        if (labels[label.id]) return
        setLabels({
            ...labels,
            [label.id]: label,
        })
    }
    /* IMPACT */
    const [impactFunctions] = useMemoQuery<IImpactFunction[]>(
        ["impactFunctions"],
        () => { return impactFunctionQuerySet.get("") },
        undefined,
        undefined,
        []
    )
    /* FORM ------------------------------------------------------------- */
    const resetForm = () => {
        setType('ds_defined')
        setActiveTab('risk_tab')
        setName('')
        setLabels({})
        setSelectedVarieties({})
        updateHazards([[{ ...templateCondition }]])
        setImpact({
            impact_function_id: impactFunctions[0]?.id,
            max_impact: -0.5,
            marginal_impact: -0.02,
            initial_impact: -0.05,
        })
    }
    const formCancel = (riskProfile?: IRiskProfile) => {
        setActiveTab('risk_tab')
        if (!riskProfile) {
            resetForm()
            onCancel()
            return
        }
        if (riskProfile.id == riskProfileRef.current) {
            setName(riskProfile.name)
            onCancel()
            return
        }
        setName('')
        setType('ds_defined')
        resetForm()
        onCancel()
    }
    /* HANDLERS > START */
    const addNewLabelCallback = async (
        response: CustomResponse | AxiosError | null
    ) => {
        const labels = dataOrFallback<undefined>(response, undefined)
        if (labels) {
            includeLabel(labels[0])
        }
        await queryClient.invalidateQueries(["riskProfilesLabels"])
    }
    /* HANDLERS < END */
    const removeLabel = (label: ILabel) => {
        if (!label.id || !labels[label.id]) return
        const labelsCopy = { ...labels }
        delete labelsCopy[label.id]
        setLabels(labelsCopy)
    }
    const labelsRef = useOutsideComponentClickHandler(() =>
        setOpenLabels(false)
    )
    const { data: allLabels, isLoading: areLabelsLoading } = useQuery({
        queryKey: ["riskProfilesLabels", selectedAccount],
        queryFn: () => rpLabelsQuerySet.get("/"),
        enabled: !!selectedAccount,
        select: (response) => {
            if (!isValidResponse(response)) return []
            return response.data
        },
    })
    const { mutateAsync: addNewLabel } = useMutation(
        (label: ILabel) =>
            rpLabelsQuerySet.post("/", {
                ...label,
                account_id: selectedAccount,
            }),
        {
            onSuccess: addNewLabelCallback,
        }
    )
    const { mutateAsync: editLabel } = useMutation(
        (label: ILabel) =>
            rpLabelsQuerySet.put(`/${label.id}`, {
                name: label.name,
                account_id: selectedAccount,
                color: label.color,
            } as IPrimitivesDictionary),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["riskProfilesLabels"])
            },
        }
    )
    const { mutateAsync: deleteLabel } = useMutation(
        (labelID: string) => rpLabelsQuerySet.delete(`/${labelID}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["riskProfilesLabels"])
            },
        }
    )
    /* ------------------------------------------------------ END LABELS */
    /* QUERIES */
    const aggregation = useMemo(() => {
        if (!hazards || hazards.length === 0) return
        const allAggregations = new Set<string>()
        hazards.forEach((hazardGroup) =>
            hazardGroup.forEach((hazard) => {
                allAggregations.add(hazard.aggregation)
            })
        )
        // TODO: If more than one aggregation is supported, change the line below
        return Array.from(allAggregations)[0]
    }, [hazards])

    /* PAYLOAD & REQUEST */
    /* TODO:
     * - Remove map function from hazard_profiles
     * - Use another operator besides OR
     **/
    const payload = useMemo<IRiskProfilePayload>(
        // In the threshold conversion, we access the first element of haz
        // because of pending support to hazard profiles.
        () => {
            return {
                account_id: selectedAccount ?? "",
                name,
                type,
                // TODO: For OR support revisit this parsing (e.g. remove the .flat())
                hazard_profiles: hazards.flat().map((haz, idx) => {
                    let logical_op = "START"
                    if (idx > 0) logical_op = "AND"
                    let threshold = undefined
                    if (haz.threshold !== undefined) {
                        if (haz.type.toLowerCase() === "relative") {
                            threshold = haz.threshold
                        } else {
                            threshold = convertUnits(
                                haz.threshold,
                                hazardVariablesObj[haz.hazard_variable_id],
                                "resetUnits"
                            )
                        }
                    }
                    return {
                        ...haz,
                        logical_op,
                        threshold,
                    }
                }),
                impact_profile: impact,
                probability,
                labels: Object.keys(labels),
                varieties: Object.keys(selectedVarieties),
            }
        },
        [
            name,
            labels,
            hazards,
            impact,
            probability,
            selectedAccount,
            selectedVarieties,
        ]
    )
    const { mutateAsync: addRiskProfile } = useMutation(
        () =>
            riskProfileQuerySet.post({
                path: "/",
                data: payload,
            }),
        {
            onSuccess: (data) => {
                if (!isValidResponse(data)) {
                    if (data?.response?.status == 409) {
                        enqueueAlert(
                            t(
                                "duplicateRiskProfile",
                                "Couldn't create the risk profile because there is an existing one with these parameters."
                            )
                        )
                    } else {
                        enqueueAlert(
                            t("somethingWentWrong", "Something went wrong...")
                        )
                    }
                    return
                }
                enqueueAlert(
                    t(
                        "riskProfileCreation",
                        "Risk profile created successfully"
                    )
                )
                onCancel()
            },
        }
    )
    const { mutateAsync: editRiskProfile } = useMutation(
        (riskProfile: IRiskProfile) =>
            riskProfileQuerySet.put({
                path: `/${riskProfile.id}`,
                data: payload,
            }),
        {
            onSuccess: () => {
                enqueueAlert(
                    t("riskProfileEdition", "Risk profile edited successfully")
                )
                queryClient.invalidateQueries(["locations"])
                onCancel()
            },
        }
    )
    const { mutateAsync: _deleteRiskProfile } = useMutation(
        (riskProfile: IRiskProfile) =>
            riskProfileQuerySet.delete(`/${riskProfile.id}/`),
        {
            onSuccess: () => {
                enqueueAlert(
                    t("riskProfileDeleted", "Risk profile successfully deleted")
                )
                queryClient.invalidateQueries(["locations"])
                onCancel()
            },
        }
    )
    const deleteRiskProfile = (riskProfile: IRiskProfile) => {
        confirmationModal({
            title: t("riskProfileDeletion", "Risk Profile Deletion"),
            text: t(
                "deletionConfirmationRiskProfile",
                "Are you sure you want to delete this risk profile?"
            ),
            onContinue: () => {
                _deleteRiskProfile(riskProfile)
            },
        })
    }

    const submitRiskProfile = () => {
        if (customOnSave && riskProfile) return customOnSave(payload)
        if (!riskProfile) {
            addRiskProfile()
            resetForm()
            return
        }
        setActiveTab('risk_tab')
        return editRiskProfile(riskProfile)
    }

    useEffect(() => {
        if (!riskProfile) return resetForm()
        setName(riskProfile.name)
        setProbability(riskProfile.probability)
        setType(riskProfile.type)

        // TODO: Update to support stacked alerts
        updateHazards([
            riskProfile.hazard_profiles.map((hazard) => ({
                hazard_variable_id: hazard.hazard_variable.id,
                conditional: hazard.conditional,
                type: hazard.type,
                threshold:
                    hazard.type.toLowerCase() === "relative"
                        ? hazard.threshold ?? 0
                        : convertUnits(
                            hazard.threshold,
                            hazard.hazard_variable,
                            "convertUnits"
                        ),
                window: hazard.window,
                aggregation: hazard.hazard_variable.aggregation,
            })),
        ])
        if (riskProfile.impact_profile.impact_function?.id) {
            setImpact({
                impact_function_id:
                    riskProfile.impact_profile.impact_function.id,
                marginal_impact: riskProfile.impact_profile.marginal_impact,
                max_impact: riskProfile.impact_profile.max_impact,
                initial_impact: riskProfile.impact_profile.initial_impact,
            })
        }
        setLabels(arrToDict(riskProfile.labels, "id"))
        setSelectedVarieties(
            riskProfile.varieties.reduce((prev: IBooleanDictionary, { id }) => {
                return {
                    ...prev,
                    [id]: true,
                }
            }, {})
        )
        riskProfileRef.current = riskProfile?.id
    }, [riskProfile])

    /* IS LOADING */
    const isLoading = useMemo(
        () => areLabelsLoading && areHazardsLoading,
        [areLabelsLoading, areHazardsLoading]
    )
    /* IS VALID */
    const isValid = useMemo(() => {
        const isValid =
            payload.impact_profile?.impact_function_id !== undefined &&
            payload.impact_profile?.initial_impact !== undefined &&
            payload.impact_profile?.max_impact !== undefined &&
            payload.impact_profile?.marginal_impact !== undefined &&
            payload.name &&
            payload.probability &&
            payload.hazard_profiles.every(({ threshold, window }) => {
                return threshold !== undefined && window !== undefined
            })
        return isValid
    }, [payload])

    const modalRef = useOutsideComponentClickHandler(() => onCancel())
    function getClassNames(showFullVersion: boolean) {
        const baseClasses = ["flex", "flex-col", "shrink", "relative"]
        return baseClasses
            .concat(
                showFullVersion ? "" : `[&_#hazard-condition-and-button]:hidden`
            )
            .join(" ")
    }

    return (
        <EmptyModal
            open={isOpen}
            modalRef={modalRef}
            customClasses="relative flex flex-col flex-nowrap rounded-lg bg-white w-[648px] overflow-hidden max-h-[80%]">
            <div className="pl-5 pr-4 h-[68px] flex flex-row items-center justify-between border-b border-gray-14 grow-0 shrink-0">
                <div className="flex flex-col gap-0">
                    <h3 className="title-sm">
                        {!riskProfile && t("newRiskProfile")}
                        {riskProfile && t("editRiskProfile")}
                    </h3>
                    {isVarietiesEnabled &&
                        isStageManagerEnabled &&
                        showFullVersion && (
                            <p className="body-sm text-gray-60">
                                {(!riskProfile ||
                                    (riskProfile &&
                                        Object.keys(selectedVarieties)
                                            .length === 0)) &&
                                    t(
                                        "riskProfileAssetAssociationInstructions",
                                        "To associate this Risk Profile to an asset go to Admin > Assets > Timeline"
                                    )}
                                {riskProfile &&
                                    Object.keys(selectedVarieties).length >
                                    0 && (
                                        <TooltipV2
                                            doShow={isStageManagerEnabled}
                                            position="bottom"
                                            align="left"
                                            contentClass="w-56"
                                            content={t(
                                                "youCanEditRiskProfileAssetsIn",
                                                "You can edit the associated assets in Admin > Assets > Timeline"
                                            )}>
                                            {Object.keys(selectedVarieties)
                                                .map((varietyId) => {
                                                    return varietiesOptions[
                                                        varietyId
                                                    ]
                                                })
                                                .join(", ")}
                                        </TooltipV2>
                                    )}
                            </p>
                        )}
                </div>
                <span className="flex flex-row items-center jusitfy-between">
                    {showFullVersion && riskProfile && (
                        <span
                            onClick={() => deleteRiskProfile(riskProfile)}
                            className="w-6 h-6 mx-2 cursor-pointer fill-gray-60">
                            <TrashIcon />
                        </span>
                    )}
                    <span
                        className="w-6 h-6 cursor-pointer fill-gray-60 hover:fill-gray-60/80 transition-all"
                        onClick={() => formCancel(riskProfile)}>
                        <CancelIcon />
                    </span>
                </span>
            </div>

            <div className="relative flex flex-col px-5 py-4 overflow-y-auto shrink">
                {/* LOADING OVERLAY */}
                {isLoading && (
                    <div className="absolute flex items-center justify-center w-full h-full rounded-lg z-full bg-white/60">
                        {/* TODO: implement whitelabel logic. Ask Marco. */}
                        <div className="w-20">
                            <Lottie animationData={logoSpinner} />
                        </div>
                    </div>
                )}

                <div className="relative flex flex-col w-full grow gap-2">
                    {/* Tab navigation */}
                    {/* Only needed if type is custom */}
                    {showFullVersion && type === "custom" && (
                        <div className="flex items-center w-full -mx-1 border-b gap-5 border-gray-10">
                            <button
                                className={` flex flex-row items-center pb-2 px-1 border-b-2 whitespace-nowrap overflow-hidden truncate body-sm ${
                                    activeTab === "risk_tab"
                                    ? "text-accent border-accent font-bold"
                                    : "text-gray-90 border-transparent"
                                    }`}
                                onClick={() => setActiveTab("risk_tab")}>
                                Condition
                            </button>
                            <button
                                className={`flex flex-row items-center pb-2 px-1 border-b-2 whitespace-nowrap overflow-hidden truncate body-sm ${
                                    activeTab === "impact_tab"
                                    ? "text-accent border-accent font-bold"
                                    : "text-gray-90 border-transparent"
                                    }`}
                                onClick={() => setActiveTab("impact_tab")}>
                                Impact Function
                            </button>
                        </div>
                    )}

                    {activeTab === "risk_tab" && (
                        <div className="flex flex-row items-stretch gap-2">
                            {/* NAME */}
                            <LabelAndInput
                                label={t("name", "Name")}
                                input={
                                    <GenericInput
                                        name="name"
                                        id="name"
                                        placeholder=""
                                        type="text"
                                        value={name}
                                        handleChange={(
                                            event: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            setName(event.target.value)
                                        }}
                                    />
                                }
                            />
                            {/* ASSETS */}
                            {isVarietiesEnabled &&
                                !isStageManagerEnabled &&
                                showFullVersion ? (
                                <div className="flex flex-col gap-1">
                                    <label className="-ml-1/2 label-sm text-gray-60">
                                        {t("asset", "Assets")}
                                    </label>
                                    <div>
                                        <MultiSelect
                                            options={varietiesOptions}
                                            selected={selectedVarieties}
                                            setSelected={setSelectedVarieties}
                                            sortByKey={false}
                                            placeholder={t(
                                                "selectAsset",
                                                "Select asset"
                                            )}
                                            searchPlaceholder={t(
                                                "searchAsset",
                                                "Search Asset"
                                            )}
                                            canSearch={true}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                    {/* TODO: Solve for possible overflow issue when implenting stacked alerts
                                    Previous `className`: "flex flex-col shrink max-h-[400px] overflow-y-scroll scrollbar-none"
                                */}

                    {activeTab === "risk_tab" &&
                        (type === "ds_defined" ? (
                            <div className={getClassNames(showFullVersion)}>
                                {/* THRESHOLDS */}
                                <HazardsField
                                    hazards={hazards}
                                    updateHazards={updateHazards}
                                    onLoadStateChange={setAreHazardsLoading}
                                    canEditAll={showFullVersion}
                                    riskType={type}
                                />
                                {/* IMPACT FUNCTION */}
                                <div className="hidden">
                                    <ImpactForm
                                        initialValue={impact}
                                        onChange={({ impactFunction }) => {
                                            if (impactFunction !== undefined) {
                                                const { initialImpact, marginalImpact, maxImpact } = getImpactDefaults(impactFunction)
                                                setImpact({
                                                    impact_function_id: impactFunction.id,
                                                    max_impact: maxImpact,
                                                    marginal_impact: marginalImpact,
                                                    initial_impact: initialImpact,
                                                });
                                            }
                                        }}
                                        aggregation={aggregation}
                                    />
                                </div>
                            </div>
                        ) : type === 'custom' ? (
                            <div className={getClassNames(showFullVersion)}>
                                {/* THRESHOLDS */}
                                <HazardsField
                                    hazards={hazards}
                                    updateHazards={updateHazards}
                                    onLoadStateChange={setAreHazardsLoading}
                                    canEditAll={showFullVersion}
                                    riskType={type}
                                />
                            </div>
                        ) : null)}



                    {/* IMPACT FUNCTION */}
                    {showFullVersion && activeTab === "impact_tab" && (
                        <div>
                            <ImpactForm
                                initialValue={impact}
                                onChange={({
                                    impactFunction,
                                    maxImpact: max_impact,
                                    initialImpact: initial_impact,
                                    marginalImpact: marginal_impact,
                                }) => {
                                    if (
                                        impactFunction !== undefined &&
                                        max_impact !== undefined &&
                                        initial_impact !== undefined &&
                                        marginal_impact !== undefined
                                    ) {
                                        setImpact({
                                            impact_function_id: impactFunction.id,
                                            max_impact,
                                            marginal_impact,
                                            initial_impact,
                                        })
                                    }
                                }}
                                aggregation={aggregation}
                                riskProfile={riskProfile}
                                hazardsSelected={selectedHazard}
                            />
                            <div className="body-sm text-gray-60">
                                <h1>{t('impactFunctionUsedPlanningTool', "Impact function is used in the Planning Tool to calculate the estimated impact when this Risk Profile is triggered in one of your plans.")}</h1>

                            </div>
                        </div>
                    )}

                    {showFullVersion && (
                        <div className="tab-titles">
                            {type === "ds_defined" ? (
                                <div>
                                    <div className="body-sm tex-gray-60">
                                        <h1>
                                            {t(
                                                "smartThresholdCalibratedByLocation",
                                                "Smart Threshold is calibrated by location and for each stage period using the location’s weather history so you can receive relevant alerts for abnormal conditions ahead."
                                            )}
                                        </h1>
                                    </div>
                                    <button
                                        onClick={() => setType("custom")}
                                        className={[
                                            "text-xs font-medium text-accent underline mt-3",
                                            "hover:text-accent-dark",
                                        ].join(" ")}>
                                        Customize condition
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={() => {
                                        setType("ds_defined")
                                        setActiveTab("risk_tab")
                                    }}
                                    className={[
                                        "text-xs font-medium text-accent underline mt-3",
                                        "hover:text-accent-dark",
                                    ].join(" ")}>
                                    Reset Default
                                </button>
                            )}
                        </div>
                    )}

                    {showFullVersion && (
                        <div className="flex flex-row w-full">
                            {/* LABELS */}
                            <div className="flex flex-col grow shrink">
                                <label className="-ml-1/2 label-sm text-gray-60">
                                    {t(
                                        "labelsOptional",
                                        "Labels (Optional)"
                                    )}
                                </label>
                                <div className="flex flex-row flex-wrap items-center justify-start px-2 py-[0.33rem] mt-1 border rounded-md border-gray-14 gap-1 min-h-[2.66rem]">
                                    {Object.values(labels).map(
                                        (label) => (
                                            <span
                                                key={label.id}>
                                                <LabelItem
                                                    key={
                                                        label.id
                                                    }
                                                    label={
                                                        label
                                                    }
                                                    onDelete={(
                                                        label
                                                    ) =>
                                                        removeLabel(
                                                            label
                                                        )
                                                    }
                                                />
                                            </span>
                                        )
                                    )}
                                    <span
                                        role="button"
                                        onClick={() =>
                                            setOpenLabels(true)
                                        }
                                        className="w-6 h-6 cursor-pointer fill-gray-30">
                                        <PlusIcon />
                                    </span>
                                    {openedLabels && (
                                        <span
                                            className="absolute z-full"
                                            ref={labelsRef}>
                                            <LabelsForm
                                                labels={
                                                    allLabels
                                                }
                                                colors={[
                                                    [
                                                        "#F06000",
                                                        "#0098B2",
                                                        "#FDB600",
                                                    ],
                                                    [
                                                        "#A9BF2C",
                                                        "#AC22CF",
                                                        "#196CE1",
                                                    ],
                                                    [
                                                        "#23AF41",
                                                        "#B91863",
                                                        "#039CE2",
                                                    ],
                                                ]}
                                                onSelectExistingLabel={(
                                                    label
                                                ) => {
                                                    includeLabel(
                                                        label
                                                    )
                                                }}
                                                onSelectNewLabel={(
                                                    label
                                                ) => {
                                                    addNewLabel(
                                                        label
                                                    )
                                                }}
                                                onDeleteLabel={(
                                                    label
                                                ) => {
                                                    if (
                                                        !label.id
                                                    )
                                                        return
                                                    deleteLabel(
                                                        label.id
                                                    )
                                                }}
                                                onEditLabel={(
                                                    label
                                                ) => {
                                                    editLabel(
                                                        label
                                                    )
                                                }}
                                                searchLabel={t(
                                                    "search"
                                                )}
                                                specialCharsLabel={t(
                                                    "someSpecialCharsAreNotAllowed"
                                                )}
                                                notFoundLabel={t(
                                                    "noLabelsFound"
                                                )}
                                                pressEnterToCreateOneLabel={t(
                                                    "pressEnterToCreateOne"
                                                )}
                                            />
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="pl-5 pr-3 h-[64px] flex flex-row items-center justify-between border-t border-gray-14 grow-0 shrink-0">
                <div className="flex-grow">
                    {bottomLine && (
                        <p className="body-sm text-gray-60">{bottomLine}</p>
                    )}
                </div>
                <Button
                    onClick={submitRiskProfile}
                    disabled={!isValid}
                    label={t("save", "Save")}
                />
            </div>
        </EmptyModal>
    )
}

export default RiskProfileForm
