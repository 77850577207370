import http, { QuerySet } from "../../climateui/utils/http"

export const userAccountRelationGET = (userID) =>
    http.get(`/user/${userID}/account/relation`)

export const roleListGET = async () => {
    return await http.get("/role/", true, {})
}

export const userGET = async (user_id) => {
    return await http.get(`/user/${user_id}`, true, {})
}

export const loginPOST = async (credentials) => {
    return await http.post("/auth/login", credentials, false, {})
}

export const tokenRefreshPOST = async () => {
    return await http.post("/auth/refresh", undefined, true, {})
}

export const resetQuerySet = new QuerySet("/user/reset_password", [
    "post",
    "put",
])

export const logoutPOST = async () => {
    return await http.post("/auth/logout", {}, true, {})
}

export const userPUT = async (data) => {
    return await http.put(`/user/${data.user_id}`, data.user, true, {})
}
