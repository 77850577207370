const AddLocationIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M12.9575 6.60059C11.8832 4.986 10.076 3.99902 8 3.99902C4.65 3.99902 2 6.56902 2 10.199C2 12.539 3.95 15.639 8 19.339C9.96276 17.5459 11.4323 15.8937 12.4257 14.4006H14.7612C13.4879 16.7067 11.2334 19.2388 8 21.999C2.67 17.449 0 13.519 0 10.199C0 5.21902 3.8 1.99902 8 1.99902C11.0737 1.99902 13.9332 3.72364 15.2458 6.60059H12.9575Z"
                fill={props.fill}
            />
            <path
                d="M19 4.7998H17V9.5H12V11.5H17V16.7998H19V11.5H24V9.5H19V4.7998Z"
                fill={props.fill}
            />
            <path
                d="M8 12C6.9 12 6 11.1 6 10C6 8.9 6.9 8 8 8C9.1 8 10 8.9 10 10C10 11.1 9.1 12 8 12Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default AddLocationIcon
