import { Outlet } from "react-router-dom"
import TabLayout from "../../layouts/TabLayout"
import climateChildRoutes from "./routes"

const climatePaths = ["/climate", "/climate/"]

function ClimateTab() {
    return (
        <TabLayout
            withPadding={false}
            redirectPaths={climatePaths}
            basePath="/climate"
            sidebarOptions={climateChildRoutes}>
            <Outlet />
        </TabLayout>
    )
}

export default ClimateTab
