import { useTranslate } from "@tolgee/react"
import { useAccount } from "../providers/AccountProvider"
import { InsightsLocationOnboarding, IInsightsLocation } from "../types"
import { TEMPLATE_LOCATION_CSV_DATA } from "../views/Admin/Locations/LocationsUtil"
import { LOCATIONS_CSV_HEADERS } from "../views/Admin/Locations/adminLocationsTableUtils"
import {
    CSV_MULTIPLE_THINGS_SEPARATOR,
    downloadTemplateCSV,
} from "../utils/csv"
import { useCallback, useMemo } from "react"

import BulkUpload from "./BulkUpload"
import { removeZeroWidthChars, useToFloat } from "./BulkUpload/utils"
import { ColumnsConfig } from "./BulkUpload/types"
import { ERROR, UPLOADED } from "./BulkUpload/consts/cellStatus"

interface IBulkUploadLocations {
    handleLocationAddition: (
        location: IInsightsLocation
    ) => Promise<boolean>
    doneCallback: (wasSuccessful: boolean) => void
    hideLabels?: boolean
    cleanupAtTheEnd?: boolean
}

function BulkUploadLocations({
    handleLocationAddition,
    doneCallback,
    hideLabels = false,
    cleanupAtTheEnd = true,
}: IBulkUploadLocations) {
    const { t } = useTranslate()
    const { selectedAccount } = useAccount()

    const toFloat = useToFloat()
    const locationsTableCSVHeaders = LOCATIONS_CSV_HEADERS()

    const { csvTemplateHeaders, csvTemplateData, bulkColumns } = useMemo(() => {
        const csvTemplateHeaders = [...locationsTableCSVHeaders]
        const csvTemplateData = [...TEMPLATE_LOCATION_CSV_DATA]

        const bulkColumns: ColumnsConfig<InsightsLocationOnboarding> = {
            account_id: selectedAccount ?? "",
            external_id: selectedAccount ?? "",
            varieties: [],
            timezone: "",
            name: {
                headerName: locationsTableCSVHeaders[0], // locationName
            },
            latitude: {
                headerName: locationsTableCSVHeaders[1], // latitude
                toValue: async (lat) => toFloat(lat),
            },
            longitude: {
                headerName: locationsTableCSVHeaders[2], // longitude
                toValue: async (lat) => toFloat(lat),
            },
            labels: hideLabels
                ? []
                : {
                      headerName: locationsTableCSVHeaders[3], // labels
                      optional: true,
                      toValue: async (rawLabels) =>
                          rawLabels
                              ?.trim()
                              .split(CSV_MULTIPLE_THINGS_SEPARATOR)
                              .map((label) => label.trim()) || [],
                  },
        }

        if (hideLabels) {
            csvTemplateHeaders.pop() // labels is the last element
            csvTemplateData.pop() // labels is the last element
        }

        return {
            csvTemplateHeaders,
            csvTemplateData,
            bulkColumns,
        }
    }, [locationsTableCSVHeaders, hideLabels])

    const handleParse = useCallback(
        async (
            items: [
                InsightsLocationOnboarding | undefined,
                (newStatus: string) => void
            ][],
            cleanup?: () => void
        ) => {
            if (!selectedAccount) return Promise.reject()

            let everythingOk = true
            for (const [item, updateStatus] of items) {
                // If undefined, register that as an error
                if (!item) {
                    updateStatus(ERROR)
                    continue
                }

                // Upload location
                item.account_id = selectedAccount
                item.external_id = `A${selectedAccount}L${item.name}`
                item.varieties = []
                const locationAdded = await handleLocationAddition(item)
                if (!locationAdded) {
                    updateStatus(ERROR)
                    everythingOk = false
                    continue
                }

                updateStatus(UPLOADED)
            }

            if (everythingOk && cleanup && cleanupAtTheEnd) {
                cleanup()
            }
            doneCallback(everythingOk)
        },
        [cleanupAtTheEnd]
    )

    if (!selectedAccount) return null

    return (
        <div className="flex flex-col w-full gap-1.5">
            <div className="label-sm text-gray-60">
                {t(
                    "bulkUploadInstructions",
                    "Import multiple locations using our"
                )}{" "}
                <a
                    id="csv_template_download_link"
                    href="#"
                    onClick={(e) =>
                        downloadTemplateCSV(
                            e.currentTarget,
                            [csvTemplateHeaders, csvTemplateData],
                            removeZeroWidthChars(
                                t(
                                    "locationsBulkUploadTemplateCsv",
                                    "Location Bulk Upload Template.csv"
                                )
                            )
                        )
                    }
                    className="link">
                    {t("bulkUploadTemplate")}
                </a>
            </div>

            <div>
                <BulkUpload<InsightsLocationOnboarding>
                    onParse={handleParse}
                    columns={bulkColumns}></BulkUpload>
            </div>
        </div>
    )
}

export default BulkUploadLocations
