import { useMemo } from "react"
import { Table } from "../../climateui/components"
import EditableCell from "./EditableCell"
import StatusCell from "./StatusCell"
import { ParsedRow } from "./types"

const ParserTable = ({
    columns: _columns,
    data,
    updateData,
}: {
    columns: string[]
    data: ParsedRow[]
    updateData: (idx: number, col: string, newValue: string) => void
}) => {
    const columns = useMemo(
        () => [
            {
                header: undefined,
                accessorKey: "rowStatus",
                cell: StatusCell,
            },
            ..._columns.map((column) => ({
                accessorKey: column,
                cell: EditableCell,
                meta: {
                    updateData,
                },
            })),
        ],
        [_columns, updateData]
    )
    return (
        <Table
            columns={columns}
            data={data}
        />
    )
}

export default ParserTable
