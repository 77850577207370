import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { IBooleanDictionary } from "../../../../../climateui/types"
import { useTranslate } from "@tolgee/react"
import VarietyAttributesForm from "../VarietyAttributesForm"
import { useAssets } from "../../../../../providers"
import { mergeAttributes } from "../../utils"
import {
    VarietyMetadataInput,
    IVarietyMetadata,
} from "../../../../../types"
import { TemplateModal } from "."

const EditAttributes = ({
    isOpen,
    setIsOpen,
    selectedVarieties,
    setSelectedVarieties,
}: {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
    selectedVarieties: IBooleanDictionary
    setSelectedVarieties: Dispatch<SetStateAction<IBooleanDictionary>>
}) => {
    /* HOOKS > START */
    const { t } = useTranslate()
    const { varieties, defaultAttributes, editVariety } = useAssets()
    /* HOOKS < END */

    /* STATE > START */
    const [newAttrs, setNewAttrs] = useState<VarietyMetadataInput[]>([])
    /* STATE < END */

    /* BULK EDIT ATTRIBUTES > START */
    const settleAttrsChanges = (
        oldAttrs: IVarietyMetadata[],
        newAttrs: VarietyMetadataInput[]
    ) => {
        // Convert the old attributes into a record/dictionary
        const oldAttrsDict: Record<string, string> = oldAttrs.reduce(
            (prev, { key, value }) => ({
                ...prev,
                [key]: value,
            }),
            {}
        )
        // For each new attribute, return either the new value or the old one if undefined
        const results: VarietyMetadataInput[] = []
        newAttrs.forEach((attr) => {
            const val = attr.value ?? oldAttrsDict[attr.key]
            if (!val) return
            results.push({
                ...attr,
                value: val,
            })
        })
        return results
    }
    const applyAttributes = () =>
        Promise.all(
            Object.keys(selectedVarieties).map((id) => {
                if (!varieties?.[id]) return
                const {
                    variety_metadata,
                    account_id,
                    asset_id,
                    name,
                    description,
                } = varieties[id]
                return editVariety({
                    id,
                    variety: {
                        asset_id,
                        account_id,
                        name,
                        description,
                        variety_metadata: settleAttrsChanges(
                            variety_metadata,
                            newAttrs
                        ),
                    },
                })
            })
        )
    /* BULK EDIT ATTRIBUTES < END */

    /* MEMO > START */
    const initialAttributes = useMemo(() => {
        // If we don't have loaded varieties, exit
        if (!varieties) return []
        // Get the full model of only defined varieties
        const hydratedVarieties = Object.keys(selectedVarieties)
            .map((id) => varieties[id])
            .filter((variety) => !!variety)
        return mergeAttributes(hydratedVarieties)
    }, [varieties, selectedVarieties])
    /* MEMO < END */

    return (
        <TemplateModal
            title={t("bulkEditAttributes", "Bulk Edit Attributes")}
            isOpen={isOpen}
            selected={selectedVarieties}
            setSelected={setSelectedVarieties}
            setIsOpen={setIsOpen}
            cleanUp={() => setNewAttrs([])}
            onConfirm={applyAttributes}>
            <VarietyAttributesForm
                initialAttributes={initialAttributes}
                options={defaultAttributes}
                onChange={setNewAttrs}
            />
        </TemplateModal>
    )
}
export default EditAttributes
