import { useEffect, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { IMapPin } from "../../../climateui/components/Map/utils"
import FullScreenMapLayout from "../../../layouts/FullMapLayout"

export interface IFullMapContext {
    mapPins: IMapPin[]
    setMapPins: (pins: IMapPin[]) => void
}

function AlertsLayout() {
    const location = useLocation()
    const [mapPins, setMapPins] = useState<IMapPin[]>([])

    useEffect(() => {
        setTimeout(() => {
            const _event = new CustomEvent("force-resize", { bubbles: true })
            window.dispatchEvent(_event)
        }, 100)
    }, [location.pathname])

    return (
        <div className="flex flex-row h-full min-h-0 grow">
            <div className="flex-grow">
                <FullScreenMapLayout
                    mapProps={{
                        pins: mapPins,
                        mapConfigs: {
                            minZoom: 1.1,
                            maxZoom: 15,
                        },
                    }}>
                    <Outlet context={{ mapPins, setMapPins }} />
                </FullScreenMapLayout>
            </div>
        </div>
    )
}

export default AlertsLayout
