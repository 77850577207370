import { useContext, useEffect, useState, KeyboardEvent } from "react"
import {
    Button,
    LabelAndInput,
    RadioButton,
    TogglableRightSidePanel,
} from "../../../climateui/components"
import { CancelIcon } from "../../../climateui/icons"
import { ModalContext } from "../../../climateui/providers"
import queryClient, { userPUT } from "../../../utils/networking"
import {
    EMPTY_USER,
    REQUIRED_USER_PROPS,
    USER_TIMEZONES,
} from "../usersTableUtils"

// TYPES
import { IPrimitivesDictionary, IUser } from "../../../climateui/types"
import _ from "lodash"
import { SUPPORTED_LANGUAGES_OBJ } from "../../../providers/LocalizationProvider"
import {
    GenericInput,
    SingleSelect,
} from "../../../climateui/components/Inputs"
import { useTranslate } from "@tolgee/react"
import { useMutation } from "react-query"

const UserForm = (props: {
    doShow: boolean
    editedUser: IUser
    onCancel?: () => void
}) => {
    const { doShow, editedUser, onCancel = () => void 0 } = props

    const { infoModal } = useContext(ModalContext)
    const { mutate: editUser } = useMutation(userPUT)

    const [user, setUser] = useState<IUser>(EMPTY_USER)
    const [showErrors, setShowErrors] = useState(false)
    const [cleanUserName, setCleanUserName] = useState(
        editedUser ? editedUser.username : ""
    )

    const { t } = useTranslate()

    const validateForm = () => {
        let valid = true
        for (const [key, value] of Object.entries(user)) {
            if (value === null && REQUIRED_USER_PROPS.includes(key)) {
                valid = false
            }
        }
        setShowErrors(!valid)
        if (!valid) return

        if (editedUser) {
            // Keep only the properties we are interested in
            const cleanUser = _.pick(user, REQUIRED_USER_PROPS)
            editUser(
                { user: cleanUser, user_id: user.id },
                {
                    onSuccess: async () => {
                        queryClient.invalidateQueries(["user"])
                        onCancel()
                    },
                    onError: async () => {
                        infoModal({
                            title: t("error"),
                            text: t("somethingWentWrong"),
                            onContinueLabel: t("ok"),
                        })
                    },
                }
            )
        }
    }

    const showErrorForProp = (propName: string) => {
        const dictUser = user as unknown as IPrimitivesDictionary
        return (
            showErrors &&
            dictUser[propName] === null &&
            REQUIRED_USER_PROPS.includes(propName)
        )
    }

    useEffect(() => {
        setUser(editedUser ? editedUser : EMPTY_USER)
        if (!doShow) {
            setUser(EMPTY_USER)
            setShowErrors(false)
        }
    }, [doShow])

    const translatedLangOptions: Record<string, string> = {}
    Object.keys(SUPPORTED_LANGUAGES_OBJ).forEach((langCode) => {
        translatedLangOptions[langCode] = t(SUPPORTED_LANGUAGES_OBJ[langCode])
    })

    return (
        <TogglableRightSidePanel
            doShow={doShow}
            onCancel={onCancel}>
            <div className="w-full h-full px-5">
                <div className="flex items-center justify-between p-5 -mx-5 border-b border-gray-10">
                    <h3 className="font-semibold title-sm text-gray-90">
                        {editedUser ? t("editUser") : t("newUser")}
                    </h3>
                    <span
                        className="w-5 h-5 cursor-pointer fill-gray-60 hover:fill-gray-90 hover:scale-110 transition-all duration-100 shrink-0"
                        onClick={onCancel}>
                        <CancelIcon />
                    </span>
                </div>
                <div className="flex flex-col pt-5 gap-5">
                    <div>
                        <LabelAndInput
                            label={t("name")}
                            input={
                                <GenericInput
                                    type="text"
                                    defaultValue={
                                        editedUser ? editedUser.username : ""
                                    }
                                    value={cleanUserName}
                                    handleChange={(e) => {
                                        if (!e.target.value.includes("\\")) {
                                            setCleanUserName(e.target.value)
                                        }
                                    }}
                                    handleKeyUp={(
                                        e: KeyboardEvent // from react
                                    ) => {
                                        const target =
                                            e.target as HTMLInputElement
                                        if (!target.value.includes("\\")) {
                                            setUser({
                                                ...user,
                                                username: target.value,
                                            })
                                        }
                                    }}
                                    error={
                                        showErrorForProp("username")
                                            ? t("nameIsRequired")
                                            : undefined
                                    }
                                />
                            }
                        />
                        <div className="text-[#666D74] pl-1 text-[14px] font-roboto italic">
                            (Up to 50 characters, no \”)
                        </div>
                    </div>
                    <LabelAndInput
                        label={t("email")}
                        input={
                            <GenericInput
                                type="text"
                                defaultValue={
                                    editedUser ? editedUser.email : ""
                                }
                                handleKeyUp={(
                                    e: KeyboardEvent // from react
                                ) => {
                                    const target = e.target as HTMLInputElement
                                    setUser({
                                        ...user,
                                        email: target.value,
                                    })
                                }}
                                error={
                                    showErrorForProp("email")
                                        ? t("emailIsRequired")
                                        : undefined
                                }
                            />
                        }
                    />
                    {!editedUser && (
                        <LabelAndInput
                            label={t("password")}
                            input={
                                <GenericInput
                                    error={
                                        showErrorForProp("password")
                                            ? t("passwordIsRequired")
                                            : undefined
                                    }
                                    type="password"
                                    defaultValue=""
                                    handleKeyUp={(
                                        e: KeyboardEvent // from react
                                    ) => {
                                        const target =
                                            e.target as HTMLInputElement
                                        setUser({
                                            ...user,
                                            password: target.value,
                                        })
                                    }}
                                />
                            }
                        />
                    )}
                    <LabelAndInput
                        label={t("timezone")}
                        input={
                            <SingleSelect
                                options={USER_TIMEZONES}
                                placeholder=" "
                                canSearch
                                leftRightClass="inset-x-0"
                                selected={user.timezone as string}
                                setSelected={(selected) => {
                                    setUser({
                                        ...user,
                                        timezone: selected as string,
                                    })
                                }}
                                error={
                                    showErrorForProp("timezone")
                                        ? t("timezoneIsRequired")
                                        : undefined
                                }
                            />
                        }
                    />
                    <LabelAndInput
                        label={t("unitType")}
                        input={
                            <div className="flex flex-row space-x-[40px] items-center">
                                <div
                                    className="flex flex-row items-center cursor-pointer gap-2"
                                    onClick={() => {
                                        setUser({
                                            ...user,
                                            unit_type: true,
                                        })
                                    }}>
                                    <RadioButton
                                        selected={user.unit_type}
                                        onChange={() => null}
                                    />
                                    SI (metric)
                                </div>
                                <div
                                    className="flex flex-row items-center cursor-pointer gap-2"
                                    onClick={() => {
                                        setUser({
                                            ...user,
                                            unit_type: false,
                                        })
                                    }}>
                                    <RadioButton
                                        selected={!user.unit_type}
                                        onChange={() => null}
                                    />
                                    US (Imperial)
                                </div>
                            </div>
                        }
                    />
                    <LabelAndInput
                        label={t("language")}
                        input={
                            <SingleSelect
                                options={translatedLangOptions}
                                placeholder=""
                                selected={user.language}
                                setSelected={(selected) => {
                                    setUser({
                                        ...user,
                                        language: selected as string,
                                    })
                                }}
                                error={
                                    showErrorForProp("language")
                                        ? t("languageIsRequired")
                                        : undefined
                                }
                            />
                        }
                    />
                    <Button
                        extraClasses="mt-4"
                        extend
                        onClick={validateForm}
                        label={t("save")}
                    />
                </div>
            </div>
        </TogglableRightSidePanel>
    )
}

export default UserForm
