import { DateTime } from "luxon"
import React from "react"
import {
    Line,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    ComposedChart,
    ReferenceLine,
    Label,
    Legend,
    Customized,
} from "recharts"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"
import renderRiskChartReferences from "./renderRiskChartReferences"
import renderCustomLegend from "./renderCustomLegend"
import TooltipV2 from "../../../../climateui/components/TooltipV2"
import InfoIcon from "../../../../climateui/icons/InfoIcon"
import { Stage } from "../types"
import renderRiskChartStages from "./renderRiskChartStages"
import { AxisInterval } from "recharts/types/util/types"
import { getHazardIcon, getProbabilityHoverItems } from "../riskOutlookUtils"
import ProbabilityHover from "../../../../climateui/components/Widgets/SeasonalWidgets/ProbabilityHover"
import RiskOutlookIcon from "../../../../climateui/icons/variableIcons/RiskOutlookIcon"

interface Point {
    id: string
    date: string
    y?: number
    y0?: number
    y1?: number
}

interface Plot {
    id: string
    visualization: "line" | "area"
    color: string
    points: Point[]
}

interface LegendInterface {
    type: "line" | "area" | "dot" | "line-dashed"
    label: string
    color: string
}

interface RiskOutlookChartProps {
    data: {
        plot: Plot[]
    }
    isHistorical?: boolean
    stages: Stage[]
    dottedSeparatorDate?: string
    highlightedDate?: string
    showDots?: boolean
    verticalLineDate?: string
    verticalLineColor?: string
    adjustYAxis?: boolean
    domain?: [number, number]
    xTickFormatter?: (value: { date: string }) => string
    xAxisInterval?: AxisInterval | number
    // yTickFormatter?: (value: { y: number }) => string
    loading?: boolean
    lowerBound?: number
    upperBound?: number
    legends?: LegendInterface[]
    xLabel?: string
    // yLabel?: string
    xTooltipLabel?: string
    yTooltipLabel?: string
    description?: string // New property
    tooltip?: string
    title?: string
    icon?: string
}

const RiskOutlookChart: React.FC<RiskOutlookChartProps> = ({
    data,
    isHistorical,
    stages,
    dottedSeparatorDate,
    highlightedDate,
    verticalLineDate,
    verticalLineColor = "#000000", // Default color
    // mainColor = "#D0D0D0",
    showDots = true,
    adjustYAxis = true,
    domain,
    xTickFormatter,
    xAxisInterval,
    // yTickFormatter,
    loading,
    lowerBound,
    upperBound,
    legends,
    xLabel,
    // yLabel,
    xTooltipLabel,
    yTooltipLabel,
    description, // New prop
    tooltip,
    title,
    icon,
}) => {
    if (loading) {
        return (
            <div className="flex justify-center items-center mt-36 body-lg">
                <LoadingAnimation />
            </div>
        )
    }

    if (!data?.plot || data.plot?.length === 0) {
        return <div>No data available for the chart.</div>
    }

    const transformedData = data.plot.flatMap((plot) =>
        plot.points.map((point) => ({
            ...point,
            [plot.id]:
                plot.visualization === "area" ? [point.y0, point.y1] : point.y,
        }))
    )

    if (transformedData.length === 0) {
        return <div>No data points available for the chart.</div>
    }

    // TODO ?
    const Icon = getHazardIcon(icon ?? "")

    return (
        <>
            <div className="flex items-center mt-2 mb-2">
                <span className="ml-4 mr-2 w-6 h-6 fill-gray-60">
                    <Icon />
                </span>
                <p className="label-lg text-gray-78">{title}</p>
                <TooltipV2
                    position="top"
                    contentClass="w-[182px]"
                    content={<>{tooltip}</>}>
                    <div className="w-[18px] h-[18px] ml-1 fill-gray-60 cursor-pointer">
                        <InfoIcon />
                    </div>
                </TooltipV2>
            </div>
            <div>
                {description && (
                    <div className="pl-12 mb-2.5 text-left text-sm text-gray-60">
                        {description}
                    </div>
                )}
                <ResponsiveContainer
                    width="100%"
                    height={400}>
                    <ComposedChart
                        data={transformedData}
                        margin={{ left: 50, bottom: stages ? 50 : 0 }}>
                        <Legend
                            verticalAlign="top"
                            align="right"
                            layout="horizontal"
                            content={() => renderCustomLegend({ legends })}
                        />
                        <XAxis
                            axisLine={false}
                            tickLine={false}
                            padding={{ right: 5, left: 5 }}
                            dataKey="date"
                            type="category"
                            allowDuplicatedCategory={false}
                            strokeWidth={0}
                            interval={xAxisInterval ?? 0}
                            fontSize={14}
                            tickFormatter={(value) => {
                                return xTickFormatter
                                    ? xTickFormatter(value)
                                    : value
                            }}>
                            {xLabel && (
                                <Label
                                    value={xLabel}
                                    position="insideBottom"
                                />
                            )}
                        </XAxis>

                        <YAxis
                            padding={{ top: 10, bottom: 10 }}
                            domain={
                                adjustYAxis
                                    ? [
                                          (dataMin: number) =>
                                              Math.min(
                                                  dataMin,
                                                  ...transformedData.flatMap(
                                                      (point) =>
                                                          Object.values(
                                                              point
                                                          ).filter(
                                                              (
                                                                  value
                                                              ): value is number =>
                                                                  typeof value ===
                                                                  "number"
                                                          )
                                                  )
                                              ) ?? 0,
                                          (dataMax: number) =>
                                              Math.max(
                                                  dataMax,
                                                  ...transformedData.flatMap(
                                                      (point) =>
                                                          Object.values(
                                                              point
                                                          ).filter(
                                                              (
                                                                  value
                                                              ): value is number =>
                                                                  typeof value ===
                                                                  "number"
                                                          )
                                                  )
                                              ) ?? 0,
                                      ]
                                    : domain
                            }
                            axisLine={false}
                            tickLine={false}
                            strokeWidth={0}
                            tick={false}></YAxis>

                        <Customized
                            component={({ yAxisMap }: any) => {
                                const y1 = yAxisMap[0].scale(100)
                                const y2 = yAxisMap[0].scale(0)
                                const middleY = (y1 + y2) / 2

                                return (
                                    <g>
                                        <text
                                            x={10}
                                            y={middleY}
                                            textAnchor="middle"
                                            fill="black"
                                            fontWeight={500}
                                            fontSize={14}
                                            origin={0}
                                            transform={`rotate(-90, 10, ${
                                                middleY - 20
                                            })`}>
                                            Severity
                                        </text>
                                        <foreignObject
                                            x={12.5}
                                            y={middleY + 15}
                                            width={20}
                                            height={20}>
                                            <TooltipV2
                                                position="top"
                                                contentClass="w-[182px]"
                                                content={
                                                    <>
                                                        Our severity levels are
                                                        based on the frequency
                                                        of events, as compared
                                                        to historical extremes:
                                                        <br />
                                                        <br />
                                                        · High: Conditions more
                                                        extreme than a
                                                        once-in-10-year event.
                                                        <br />
                                                        <br />
                                                        · Medium: Conditions
                                                        more severe than a
                                                        once-in-4-year event.
                                                        <br />
                                                        <br />· Low/None:
                                                        Conditions that are not
                                                        considered severe.
                                                    </>
                                                }>
                                                <div className="w-[18px] h-[18px] ml-1 fill-gray-60 cursor-pointer">
                                                    <InfoIcon />
                                                </div>
                                            </TooltipV2>
                                        </foreignObject>
                                    </g>
                                )
                            }}
                        />

                        {data.plot.map((plot) => {
                            if (plot.visualization === "line") {
                                return (
                                    <React.Fragment key={`fragment_${plot.id}`}>
                                        <Line
                                            type="linear"
                                            dataKey={plot.id}
                                            stroke={plot.color}
                                            strokeWidth={2}
                                            dot={
                                                showDots
                                                    ? (props) => {
                                                          const {
                                                              cx,
                                                              cy,
                                                              payload,
                                                          } = props

                                                          if (
                                                              DateTime.fromISO(
                                                                  payload.date
                                                              )
                                                                  .startOf(
                                                                      "day"
                                                                  )
                                                                  .equals(
                                                                      DateTime.fromISO(
                                                                          highlightedDate ??
                                                                              ""
                                                                      ).startOf(
                                                                          "day"
                                                                      )
                                                                  )
                                                          ) {
                                                              return (
                                                                  <circle
                                                                      cx={cx}
                                                                      cy={cy}
                                                                      r={5}
                                                                      fill={
                                                                          plot.color
                                                                      }
                                                                  />
                                                              )
                                                          }
                                                          return (
                                                              <circle
                                                                  cx={cx}
                                                                  cy={cy}
                                                                  r={2}
                                                                  fill={
                                                                      plot.color
                                                                  }
                                                                  opacity={0.5}
                                                              />
                                                          )
                                                      }
                                                    : false
                                            }
                                            strokeDasharray={"0"}
                                            data={transformedData.filter(
                                                (point) => {
                                                    return (
                                                        !dottedSeparatorDate ||
                                                        DateTime.fromFormat(
                                                            point.date,
                                                            "dd-MM-yyyy"
                                                        ) <=
                                                            DateTime.fromISO(
                                                                dottedSeparatorDate ??
                                                                    ""
                                                            )
                                                    )
                                                }
                                            )}
                                        />
                                        {/* use for later */}
                                        {dottedSeparatorDate && (
                                            <Line
                                                type="linear"
                                                dataKey={plot.id}
                                                stroke={plot.color}
                                                dot={
                                                    showDots
                                                        ? (props) => {
                                                              const {
                                                                  cx,
                                                                  cy,
                                                                  payload,
                                                              } = props
                                                              if (
                                                                  payload.date ===
                                                                  highlightedDate
                                                              ) {
                                                                  return (
                                                                      <circle
                                                                          cx={
                                                                              cx
                                                                          }
                                                                          cy={
                                                                              cy
                                                                          }
                                                                          r={5}
                                                                          fill={
                                                                              plot.color
                                                                          }
                                                                      />
                                                                  )
                                                              }
                                                              return (
                                                                  <circle
                                                                      cx={cx}
                                                                      cy={cy}
                                                                      r={2}
                                                                      fill={
                                                                          plot.color
                                                                      }
                                                                  />
                                                              )
                                                          }
                                                        : false
                                                }
                                                strokeWidth={2}
                                                strokeDasharray="3 3"
                                                data={transformedData.filter(
                                                    (point) => {
                                                        return (
                                                            DateTime.fromFormat(
                                                                point.date,
                                                                "dd-MM-yyyy"
                                                            ).toMillis() >=
                                                            DateTime.fromISO(
                                                                dottedSeparatorDate ??
                                                                    ""
                                                            ).toMillis()
                                                        )
                                                    }
                                                )}
                                            />
                                        )}
                                    </React.Fragment>
                                )
                            } else if (plot.visualization === "area") {
                                return (
                                    <Area
                                        dot={false}
                                        key={plot.id}
                                        type="linear"
                                        dataKey={plot.id}
                                        stroke={"none"}
                                        fill={plot.color}
                                        fillOpacity={0.3}
                                        // baseValue={"dataMin"}
                                    />
                                )
                            }
                            return null
                        })}
                        <Tooltip
                            allowEscapeViewBox={{ y: true }}
                            isAnimationActive={false}
                            wrapperStyle={{
                                zIndex: 1000,
                            }}
                            formatter={(value, name) => {
                                if (typeof value === "number") {
                                    return [value.toFixed(2), name]
                                }
                                return [value, name]
                            }}
                            content={({ payload, ...args }: any) => {
                                const date = payload?.[0]?.payload?.date
                                const yMin = payload?.[0]?.payload?.yMin
                                const yMid = payload?.[0]?.payload?.yMid
                                const yMax = payload?.[0]?.payload?.yMax
                                const value = payload?.[0]?.payload?.y
                                const seasonLabel =
                                    payload?.[0]?.payload?.meta?.season_label ??
                                    "Not available"

                                if (isHistorical && date != highlightedDate) {
                                    return (
                                        <div className="bg-white p-2.5 rounded shadow border border-gray-10">
                                            <ProbabilityHover
                                                title=""
                                                rightTitle={seasonLabel}
                                                leftTitle={"Season"}
                                                items={[
                                                    {
                                                        title: "Severity",
                                                        description:
                                                            "Historical",
                                                        value:
                                                            value?.toFixed(2) ??
                                                            "",
                                                        icon: () => (
                                                            <RiskOutlookIcon />
                                                        ),
                                                    },
                                                ]}
                                            />
                                        </div>
                                    )
                                }

                                return (
                                    <div className="bg-white p-2.5 rounded shadow border border-gray-10">
                                        <ProbabilityHover
                                            title="Probability Breakdown by Severity"
                                            leftHeader="Severity"
                                            rightHeader={
                                                value?.toFixed(2) ?? ""
                                            }
                                            leftTitle={
                                                isHistorical ? "Season" : "Date"
                                            }
                                            rightTitle={
                                                isHistorical
                                                    ? seasonLabel
                                                    : date
                                            }
                                            items={getProbabilityHoverItems(
                                                yMin ?? 0,
                                                yMid ?? 0,
                                                yMax ?? 0,
                                                lowerBound ?? 0,
                                                upperBound ?? 0
                                            )}
                                        />
                                    </div>
                                )
                            }}
                        />
                        {renderRiskChartReferences({
                            lowerBound: lowerBound ?? 50,
                            upperBound: upperBound ?? 20,
                        })}
                        {verticalLineDate && (
                            <ReferenceLine
                                x={DateTime.fromISO(verticalLineDate).toFormat(
                                    "dd-MM-yyyy"
                                )}
                                stroke={verticalLineColor}
                                strokeWidth={2}>
                                <Label
                                    position={"bottom"}
                                    offset={7}
                                    fontWeight={500}
                                    fontSize={14}>
                                    Today
                                </Label>
                            </ReferenceLine>
                        )}

                        {/* Stages */}
                        {stages && renderRiskChartStages(stages)}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </>
    )
}

export default RiskOutlookChart
