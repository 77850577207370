import { useTranslate } from "@tolgee/react"
import { useAssetDependentSteps } from "../components/OnboardingAssetDependentLayoutProvider"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useMutation } from "react-query"
import queryClient, {
    accountIsOnboardedPOST,
    locationPOST,
    riskProfileQuerySet,
    rpLabelsQuerySet,
    stageQuerySet,
} from "../../../utils/networking"
import { useToast } from "../../../climateui/providers/Toast/ToastContextProvider"
import { CustomResponse, isValidResponse } from "../../../climateui/utils/http"
import ProgressBar from "../../../components/ProgressBar"
import {
    AlertSettingInput,
    IAlertSetting,
    IDashboardLocation,
    IInsightsLocation,
    RiskProfileInput,
    IRiskProfile,
    riskProfileToInput,
    VarietyInput,
    IVariety,
    varietyMetadataToInput,
    IVarietyStage,
    VARIETY_EMPTY_INITIAL_DATE,
} from "../../../types"
import {
    addVarietyToLocations,
    IRiskProfileAndStage,
    getRiskProfileLabels,
    getAlreadyActiveAlerts,
    createOrUpdateRiskSettings,
} from "../utils"
import { matchRiskSettingsToProcessingRuns } from "../../Seasonal/Dashboards/utils"
import {
    useAssets,
    useAuth,
    useLocations,
    useAccount,
} from "../../../providers"
import { useDashboard } from "../../../providers/DashboardProvider"
import OnboardingFeaturesPreview from "../components/OnboardingFeaturesPreview"
import { BellIcon, DashboardIcon } from "../../../climateui/icons"
import { colors, colorsArr } from "../../../climateui/utils/colors"
import { toLabelName } from "../../../utils/wordHelper"
import { arrToDict } from "../../../utils/transform"
import { ILabel } from "../../../climateui/types"
import { useAreAllFlagsEnabled } from "../../../hooks"
import { formatYearAgnosticDate } from "../../../utils/dates"

const REQUIRED_OPERATIONS = 4
const ALERTS_PROCESSING_TIME_SECONDS = 30

function OnboardingFinishSetup() {
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { enqueueAlert } = useToast()
    const { user } = useAuth()
    const { selectedAccount, accountsObject } = useAccount()
    const { locationsObj } = useLocations()
    const { accountAssets, updateAccountAssetIsSetup, addVariety } = useAssets()
    const { createOrEditDashboard } = useDashboard()

    const {
        asset,
        activeDefaultVariety,
        riskProfilesForDefaultVariety,
        assetNewLocations,
        setAssetNewLocations,
        selectedExistingLocationsIds,
        setSelectedExistingLocationsIds,
        isSetupProcessing,
        setIsSetupProcessing,
        initialDate,
        suggestedStagesAndRiskProfiles,
    } = useAssetDependentSteps()

    // REQUIRED OPERATIONS
    const [locationsCompleted, setLocationsCompleted] = useState(false)
    const [riskProfilesCompleted, setRiskProfilesCompleted] = useState(false)
    const [riskSettingsCompleted, setRiskSettingsCompleted] = useState(false)
    const [alertsProgress, setAlertsProgress] = useState(0)

    const canViewStageManager = useAreAllFlagsEnabled([
        "feature_stage_manager",
        "ops_stage_manager_are_varieties_migrated",
    ])

    const currentProgress = useMemo(() => {
        // keep track of completed operations
        return (
            Number(locationsCompleted) +
            Number(riskProfilesCompleted) +
            Number(riskSettingsCompleted) +
            Math.min(alertsProgress, ALERTS_PROCESSING_TIME_SECONDS) /
                ALERTS_PROCESSING_TIME_SECONDS
        )
    }, [
        locationsCompleted,
        riskProfilesCompleted,
        riskSettingsCompleted,
        alertsProgress,
    ])

    function notifyUserAndExit(message: string) {
        enqueueAlert(message)
        navigate("/")
    }
    function notifyUserAndGoBack(message: string) {
        enqueueAlert(message)
        navigate("../locations")
    }

    const { mutateAsync: addLocation } = useMutation(locationPOST)
    const { mutateAsync: addRiskProfileLabel } = useMutation(
        (label: ILabel) =>
            rpLabelsQuerySet.post("", {
                ...label,
                account_id: selectedAccount,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["riskProfilesLabels"])
            },
        }
    )
    const { mutateAsync: addRiskProfile } = useMutation(
        (payload: RiskProfileInput) => {
            return riskProfileQuerySet.post({ path: "", data: payload })
        }
    )
    const { mutateAsync: updateRiskProfile } = useMutation(
        ({
            riskProfileId,
            payload,
        }: {
            riskProfileId: string
            payload: RiskProfileInput
        }) => {
            return riskProfileQuerySet.put({
                path: `/${riskProfileId}`,
                data: payload,
            })
        }
    )
    const { mutateAsync: markUserAsOnboarded } = useMutation(
        () => accountIsOnboardedPOST(selectedAccount as string, true),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["accounts"])
            },
            onError: () => {
                notifyUserAndGoBack(
                    t(
                        "thereWasAnErrorMarkingUserAsOnboarded",
                        "There was a problem while processing your onboarding. Please try again."
                    )
                )
            },
        }
    )

    /** Handles creation of new locations.
     * Manages location state consistency in case of failure
     * Returns a list of the created location objects */
    async function createNewLocations(varietyId: string) {
        function updateLocationsState(
            createdLocations: IInsightsLocation[],
            pendingLocations: IInsightsLocation[]
        ) {
            const createdLocationIds = createdLocations.map(
                (location) => location.id as string
            )
            // Move any created locations to existing locations
            setSelectedExistingLocationsIds((prev: string[]) =>
                prev.concat(createdLocationIds)
            )
            setAssetNewLocations(pendingLocations)
            queryClient.invalidateQueries(["locations"])
        }

        // Create locations in DB one by one
        const locationsPending = [...assetNewLocations]
        const createdLocations: IInsightsLocation[] = []

        while (locationsPending.length > 0) {
            const location = locationsPending[0]
            try {
                // Create location with varieties
                const response = await addLocation({
                    ...location,
                    varieties: [varietyId],
                })
                if (!isValidResponse(response) || !response.data?.[0].id) {
                    throw new Error()
                }

                // move successfully created location to existing locations
                createdLocations.push(response.data[0])
                locationsPending.shift()
            } catch (e) {
                updateLocationsState(createdLocations, locationsPending)
                enqueueAlert(
                    t(
                        "thereWasAnErrorCreatingLocations",
                        "There was a problem while creating locations. Please try again."
                    )
                )
                navigate("../locations")
                break
            }
        }

        updateLocationsState(createdLocations, [])
        return createdLocations
    }

    /* Adds current variety to the selected existing locations' associated varieties */
    async function udpateSelectedExistingLocations(variety: IVariety) {
        const selectedExistingLocations = Object.values(locationsObj).filter(
            (location) =>
                location.id &&
                selectedExistingLocationsIds.includes(location.id)
        )

        await addVarietyToLocations(selectedExistingLocations, variety)
        queryClient.invalidateQueries(["locations"])
    }

    /** Updates an existing risk profile's varieties and labels based on the given payload */
    async function updateExistingRiskProfile(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        existingRiskProfile: any,
        payload: RiskProfileInput
    ) {
        const existingLabels = existingRiskProfile.labels.map(
            (label: ILabel) => label.id as string
        )
        const updatedLabels = [
            ...new Set([...existingLabels, ...payload.labels]),
        ]
        const existingVarieties = existingRiskProfile.varieties.map(
            (variety: string) => variety
        )
        const updatedVarieties = [
            ...new Set([...existingVarieties, ...payload.varieties]),
        ]
        const updatePayload = {
            ...payload,
            labels: updatedLabels.filter((label) => !!label),
            varieties: updatedVarieties.filter((variety) => !!variety),
        }
        await updateRiskProfile({
            riskProfileId: existingRiskProfile.id,
            payload: updatePayload,
        })
    }

    /**
     * Handle creation of risk profiles.
     * If a profile already exists, update its labels and varieties.
     * Returns a list of both updated and created risk profile's ids */
    async function createOrUpdateRiskProfiles(
        riskProfilesWithLabelsAndVarieties: IRiskProfile[],
        variety: IVariety
    ) {
        const riskProfileIds: string[] = []
        const riskProfileIdsMap: Record<string, string> = {}

        for (const riskProfile of riskProfilesWithLabelsAndVarieties) {
            // build risk profile payload with account_id
            const payload: RiskProfileInput = riskProfileToInput({
                ...riskProfile,
                varieties: [
                    variety,
                    ...(riskProfile.varieties ? riskProfile.varieties : []),
                ],
                account_id: selectedAccount as string,
            })
            let response = await addRiskProfile(payload)

            let isDuplicate = false
            if (!isValidResponse(response)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((response as any)?.response?.status === 409)
                    isDuplicate = true
                if (!isDuplicate) {
                    enqueueAlert(
                        t(
                            "thereWasAnErrorCreatingRiskProfiles",
                            "There was a problem while creating your risk profiles. Please try again."
                        )
                    )
                    navigate("../locations")
                    break
                }
            }

            response = response as CustomResponse
            if (isDuplicate) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                response = (response as any)?.response as CustomResponse
                // duplicate risk profile, we must update it
                const existingRiskProfile = response.data[0]
                await updateExistingRiskProfile(existingRiskProfile, payload)
            }
            const riskProfileId = response.data[0].id
            riskProfileIds.push(riskProfileId)
            riskProfileIdsMap[riskProfile.id] = riskProfileId
        }

        queryClient.invalidateQueries(["riskProfiles"])
        return { riskProfileIds, riskProfileIdsMap }
    }

    /** Returns a mapping of each location's existing risk profiles relationships */
    function getExistingLocationsRiskProfilesRelation(
        existingAlerts: IAlertSetting[],
        locationsRiskProfileRelation: Record<string, string[]>
    ) {
        // TODO: reuse in DashboardAssets
        const existingLocationsRiskProfileRelation: Record<string, string[]> =
            {}

        existingAlerts.forEach((alert: AlertSettingInput) => {
            const locationsRiskProfileExists = locationsRiskProfileRelation[
                alert.location_id
            ].includes(alert.risk_profile_id)
            if (locationsRiskProfileExists) {
                // handle new keys
                if (!existingLocationsRiskProfileRelation[alert.location_id])
                    existingLocationsRiskProfileRelation[alert.location_id] = []

                existingLocationsRiskProfileRelation[alert.location_id].push(
                    alert.risk_profile_id
                )
            }
        })

        return existingLocationsRiskProfileRelation
    }

    function invalidateDashboardRelevantQueries() {
        // TODO: reuse in DashboardAssets
        // invalidate risk profiles provider
        queryClient.invalidateQueries([
            "riskProfiles",
            selectedAccount,
            user?.id,
        ])

        // invalidate alert settings provider
        queryClient.invalidateQueries(["alertsAlertSettings", selectedAccount])

        // also invalidate locations query to know which locations
        // have new varieties associated (through risk settings)
        queryClient.invalidateQueries([
            "locations",
            selectedAccount,
            accountsObject,
        ])
    }

    function getRegionNamesFromLocations(
        newLocations: IInsightsLocation[],
        newAndExistingLocationIds: string[]
    ) {
        const regionNameSet = new Set<string>()

        newAndExistingLocationIds.forEach((locationId) => {
            const loc = locationsObj[locationId]
            if (loc?.region?.name) {
                regionNameSet.add(loc.region?.name)
            }
        })

        newLocations.forEach((location) => {
            if (location.region?.name) {
                regionNameSet.add(location.region?.name)
            } else if (location.regions?.[0]?.name) {
                regionNameSet.add(location.regions[0].name)
            }
        })

        return Array.from(regionNameSet)
    }

    function buildLocationsForDashboard(
        locationIds: string[],
        varietyId: string
    ) {
        return locationIds.map((locationId) => {
            let locationVarieties = []
            if (locationsObj[locationId]) {
                locationVarieties = locationsObj[locationId].varieties.map(
                    (variety) => variety.id
                )
                locationVarieties.push(varietyId)
            } else {
                locationVarieties = [varietyId]
            }
            return {
                location_id: locationId,
                asset: "",
                active: true,
                varieties: locationVarieties,
            } as IDashboardLocation
        })
    }

    /**
     * Creates the label entities required for the given risk profiles.
     * Returns the risk profiles with their updated label ids array.
     */
    async function populateRiskProfileLabels(
        riskProfilesAndStage: IRiskProfileAndStage[],
        locationRegions: string[]
    ) {
        const result: IRiskProfile[] = []
        const labels = await getRiskProfileLabels()
        const existingLabels = arrToDict<ILabel>(labels, "name")

        // Create region labels for all risk profiles
        const regionLabelsForRiskProfiles: ILabel[] = []
        for (const regionName of locationRegions) {
            const regionLabelName = toLabelName(regionName)
            if (existingLabels[regionLabelName]) {
                regionLabelsForRiskProfiles.push(
                    existingLabels[regionLabelName]
                )
                continue
            }

            const response = await addRiskProfileLabel({
                name: regionLabelName,
                color: colors.cyan.DEFAULT,
            })

            if (isValidResponse(response) && response.data?.[0].id) {
                existingLabels[regionLabelName] = response.data[0]
                regionLabelsForRiskProfiles.push(response.data[0])
            }
        }

        // Create stage labels for individual risk profiles
        for (const { stageName } of riskProfilesAndStage) {
            const stageLabelName = toLabelName(stageName)
            if (existingLabels[stageLabelName]) continue

            const response = await addRiskProfileLabel({
                name: stageLabelName,
                color: colors.indigo.DEFAULT,
            })

            if (isValidResponse(response) && response.data?.[0].id) {
                existingLabels[stageLabelName] = response.data[0]
            }
        }

        // Add regions and stage labels to risk profiles
        for (const { riskProfile, stageName } of riskProfilesAndStage) {
            const riskProfileLabels = [...regionLabelsForRiskProfiles]
            const stageLabel = existingLabels[toLabelName(stageName)]
            if (stageLabel) riskProfileLabels.push(stageLabel)

            result.push({
                ...riskProfile,
                labels: riskProfileLabels,
            })
        }

        return result
    }

    /**
     * Validates that the processing runs for the alerts have been created and executed.
     * Returns true if successful, false if timed out after 30 seconds.
     */
    async function processDashboardsAlerts(
        locationsRiskProfileRelation: Record<string, string[]>,
        riskIds: string[],
        alertsIds: string[],
        waitingCallback?: () => void
    ) {
        function validateProcessingRuns() {
            return new Promise((resolve) => {
                let validationInProcess = false
                const startTime = Date.now()

                // interval for validating the processing runs have been created
                // and executed, only executes once at a time every n seconds
                const interval = setInterval(() => {
                    if (validationInProcess) return

                    validationInProcess = true

                    matchRiskSettingsToProcessingRuns(
                        locationsRiskProfileRelation,
                        riskIds,
                        alertsIds
                    ).then((result) => {
                        if (!!result || Date.now() - startTime > 30 * 1000) {
                            // if we got a successful match or waited for more
                            // than 30 seconds
                            clearInterval(interval)
                            resolve(result)
                        } else {
                            waitingCallback?.()
                        }

                        validationInProcess = false
                    })
                }, 2000)
            })
        }

        const didMatch = await validateProcessingRuns()
        return didMatch
    }

    /**
     * Creates a portfolio regional dashboard and navigates to it, exiting the onboarding flow.
     * If there is an error, it navigates home and displays an informative alert for the user.
     */
    async function createOnboardingDashboard(
        locationsRiskProfileRelation: Record<string, string[]>,
        varietyId: string
    ) {
        // Create dashboard
        const varietyName =
            activeDefaultVariety?.full_name ??
            activeDefaultVariety?.asset.name ??
            activeDefaultVariety?.name
        // Build locations for dashboard with varieties ids
        const locationsForDashboard = buildLocationsForDashboard(
            Object.keys(locationsRiskProfileRelation),
            varietyId
        )

        const dashboard = {
            description: "",
            account_id: selectedAccount as string,
            updated_by_email: user?.email,
            created_by_email: user?.email,
            locations: locationsForDashboard,
        }
        const locationDashboard = {
            title: varietyName
                ? t(
                      "VARIETYLocationDashboardCreated",
                      "Location Seasonal Monitoring Dashboard",
                      { variety: varietyName }
                  )
                : "",
            dtype: "Location",
            ...dashboard,
        }
        const regionalDashboard = {
            title: varietyName
                ? t(
                      "VARIETYPortfolioDashboardCreated",
                      "Portfolio Seasonal Monitoring Dashboard",
                      { variety: varietyName }
                  )
                : "",
            dtype: "Regional",
            ...dashboard,
        }
        try {
            // TODO: evaluate if the dashboard provider is really required in this view.
            // Create and navigate to dashboard if succesful
            await createOrEditDashboard(locationDashboard, false)
            const success = await createOrEditDashboard(regionalDashboard)
            if (!success) {
                notifyUserAndExit(
                    t(
                        "dashboardCouldNotBeCreated",
                        "A dashboard could not be created at this time. Don't worry, you can create one for your asset in the Dashboards tab."
                    )
                )
            }
        } catch (e) {
            notifyUserAndExit(
                t(
                    "thereWasAnErrorFinishingDashboard",
                    "There was an problem when finalizing your dashboard. Don't worry, you can create one for your asset in the Dashboards tab."
                )
            )
        }
    }

    async function markAssetAsSetup() {
        // get the current asset's id
        const assetId = asset?.id
        if (accountAssets && assetId) {
            // fetch the account_asset id
            const accountAsset = accountAssets[assetId]

            await updateAccountAssetIsSetup({
                id: accountAsset.id,
                asset_id: assetId,
                is_setup: true,
            })
        }
    }

    async function createVarietyTimeline(
        varietyId: string,
        riskProfileIdsMap: Record<string, string>
    ) {
        const alertsIds: string[] = []

        for (let i = 0; i < suggestedStagesAndRiskProfiles.length; i++) {
            const stageRiskProfile = suggestedStagesAndRiskProfiles[i]
            const newStage = {
                variety_id: varietyId,
                name: stageRiskProfile.name,
                days_from_start: stageRiskProfile.days_from_start,
                duration: stageRiskProfile.duration,
                color: colorsArr[i % colorsArr.length],
                // In case we don't want to link Stage-RiskProfiles.
                // risk_profile_ids: !initialDate
                //     ? []
                //     : stageRiskProfile.stage_risk_profiles.map((srp) => {
                //           return riskProfileIdsMap[srp.risk_profile_id]
                //       }),
                risk_profile_ids: stageRiskProfile.stage_risk_profiles.map(
                    (srp) => {
                        return riskProfileIdsMap[srp.risk_profile_id]
                    }
                ),
            }
            const response = await stageQuerySet.post({
                path: "",
                data: newStage,
            })
            if (!response || !isValidResponse(response))
                throw new Error("errorCreatingStage")

            const stage = response.data[0] as IVarietyStage
            stage.stage_risk_profiles.forEach((srp) => {
                if (srp.risk_setting_ids)
                    alertsIds.push(...srp.risk_setting_ids)
            })
        }

        return alertsIds
    }

    async function createAccountDefaultVariety() {
        if (!asset || !selectedAccount) return
        const variety: VarietyInput = {
            asset_id: asset.id,
            account_id: selectedAccount,
            name: t("default", "Default"),
            description: "",
            initial_date: formatYearAgnosticDate(
                initialDate,
                VARIETY_EMPTY_INITIAL_DATE
            ),
            variety_metadata: activeDefaultVariety?.variety_metadata
                ? activeDefaultVariety.variety_metadata.map((metadata) =>
                      varietyMetadataToInput(metadata)
                  )
                : [],
            is_default: true,
        }

        const response = await addVariety(variety)
        if (!response || !isValidResponse(response))
            throw new Error("errorCreatingVariety")

        return response.data[0] as IVariety
    }

    /** Creates and updates the necessary objects before dashboard creation,
     * then executes the dashboard creation process.
     */
    async function prepareDashboardCreation() {
        if (isSetupProcessing) return
        setIsSetupProcessing(true)
        setAlertsProgress(0)

        // VARIETY ============================================================
        let variety = activeDefaultVariety
        if (canViewStageManager) variety = await createAccountDefaultVariety()

        if (!variety) return

        // LOCATIONS ==========================================================
        await udpateSelectedExistingLocations(variety)
        const newLocations = await createNewLocations(variety.id)
        setLocationsCompleted(true)
        const newLocationIds = newLocations.map(
            (location) => location.id as string
        )
        const newAndExistingLocationIds = [
            ...new Set([...newLocationIds, ...selectedExistingLocationsIds]),
        ]

        // RISK PROFILES ======================================================
        const regionNames = getRegionNamesFromLocations(
            newLocations,
            newAndExistingLocationIds
        )
        const riskProfilesWithLabels = await populateRiskProfileLabels(
            riskProfilesForDefaultVariety,
            regionNames
        )

        const { riskProfileIds, riskProfileIdsMap } =
            await createOrUpdateRiskProfiles(riskProfilesWithLabels, variety)
        setRiskProfilesCompleted(true)

        // RISK SETTINGS (ALERTS) =============================================
        // Build mapping of location -> [riskProfiles of variety]
        const locationsRiskProfileRelation = newAndExistingLocationIds.reduce(
            (result, locationId) => {
                // link all locations to the suggested risk profiles
                return {
                    ...result,
                    [locationId]: riskProfileIds,
                }
            },
            {} as Record<string, string[]>
        )
        let alertsIds = []
        if (canViewStageManager) {
            // VARIETY TIMELINE ===================================================
            alertsIds = await createVarietyTimeline(
                variety.id,
                riskProfileIdsMap
            )
        } else {
            const alreadyActiveAlerts = await getAlreadyActiveAlerts(
                riskProfileIds,
                newAndExistingLocationIds
            ) // Build mapping of location -> [riskProfiles] that have existing alerts
            const existingLocationsRiskProfileRelation =
                getExistingLocationsRiskProfilesRelation(
                    alreadyActiveAlerts,
                    locationsRiskProfileRelation
                )
            // Activate Risk Settings (alerts) for the given locations and profiles
            // alertsIds is a list of the created or updated alerts ids
            alertsIds = await createOrUpdateRiskSettings(
                // compare to getRiskSettings
                locationsRiskProfileRelation,
                existingLocationsRiskProfileRelation,
                alreadyActiveAlerts
            )
        }
        setRiskSettingsCompleted(true)

        // RISK SETTINGS PROCESSING RUNS ======================================
        // Wait for new Risk Settings' processing runs to complete
        await processDashboardsAlerts(
            locationsRiskProfileRelation,
            riskProfileIds,
            alertsIds,
            () => setAlertsProgress((prev) => prev + 1)
        )
        // show full progress bar
        setAlertsProgress(ALERTS_PROCESSING_TIME_SECONDS)

        // ACCOUNT-ASSET ======================================================
        await markAssetAsSetup()
        await markUserAsOnboarded()

        // DASHBOARD ==========================================================
        await createOnboardingDashboard(
            locationsRiskProfileRelation,
            variety.id
        )
        invalidateDashboardRelevantQueries()

        setIsSetupProcessing(false)
    }

    useEffect(() => {
        // Runs once on mount
        if (
            !riskProfilesForDefaultVariety ||
            riskProfilesForDefaultVariety.length == 0 ||
            ((!assetNewLocations || assetNewLocations.length == 0) &&
                (!selectedExistingLocationsIds ||
                    selectedExistingLocationsIds.length == 0))
        ) {
            // missing data, go back
            navigate("/onboarding")
        } else {
            prepareDashboardCreation()
        }
    }, [])

    return (
        <div className="fixed inset-0 z-30 bg-white overflow-auto">
            <div className="h-12 w-full"></div>

            <div className="flex flex-col items-center gap-y-6 sticky top-0 py-6 bg-white/40 backdrop-blur">
                <div className="text-center headline-sm">
                    {t(
                        "settingUpAccountWait",
                        "Setting up your account, please wait..."
                    )}
                </div>
                <div>
                    <ProgressBar
                        progress={currentProgress}
                        sections={REQUIRED_OPERATIONS}
                        widthClass="w-[650px]"
                        transitionClass="transition-all duration-1000"
                    />
                </div>
            </div>

            <div className="h-10 w-full"></div>

            <div className="max-w-[878px] mx-auto">
                <OnboardingFeaturesPreview
                    sections={[
                        {
                            name: t("alertsLabel", "Alerts"),
                            icon: <BellIcon />,
                            items: [
                                {
                                    text: t(
                                        "onboardingAlertsDescription",
                                        "Identify which locations are experiencing weather-related risks and notify your team."
                                    ),
                                    src: "/images/alerts-preview.png",
                                    alt: "Preview of Alerts Page",
                                },
                            ],
                        },
                        {
                            name: t("dashboardsLabel", "Dashboards"),
                            icon: <DashboardIcon />,
                            items: [
                                {
                                    title: t(
                                        "locationDashboardLabel",
                                        "Location Dashboard"
                                    ),
                                    text: t(
                                        "onboardingLocationDashboardDescription",
                                        "Focus on a location to monitor risks, trends and historical abnormalities."
                                    ),
                                    src: "/images/location-dashboard-preview.png",
                                    alt: "Preview of Location Dashboard",
                                },
                                {
                                    title: t(
                                        "portfolioDashboardLabel",
                                        "Portfolio Dashboard"
                                    ),
                                    text: t(
                                        "onboardingPortfolioDashboardDescription",
                                        "Understand how near-term weather will affect your operations across multiple locations."
                                    ),
                                    src: "/images/portfolio-dashboard-preview.png",
                                    alt: "Preview of Portfolio Dashboard",
                                },
                            ],
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default React.memo(OnboardingFinishSetup)
