import { IAreaPlotComponent, IBandPlotComponentDataSource, IConfigAreaPlotComponent, PlotComponentTypes } from "./types"

export class AreaPlotComponent<TMetaData> implements IAreaPlotComponent<TMetaData> {
    id: string
    config: IConfigAreaPlotComponent
    x: string[]
    y: {
        y0: number
        y1: number
    }[]
    type: PlotComponentTypes.Area
    xLabel: string
    yLabel: string
    xLabelId: string
    yLabelId: string
    metadata: TMetaData

    constructor(plotDataSource: IBandPlotComponentDataSource, metadata: TMetaData, config: IConfigAreaPlotComponent) {
        this.id = plotDataSource.id
        this.type = PlotComponentTypes.Area
        this.xLabel = plotDataSource.xLabel
        this.yLabel = plotDataSource.yLabel
        this.xLabelId = plotDataSource.xLabelId
        this.yLabelId = plotDataSource.yLabelId
        this.x = plotDataSource.x
        this.y = plotDataSource.y
        this.config = config
        this.metadata = metadata
    }
}
