import { useTranslate } from "@tolgee/react"
import { useMemo } from "react"
import { TooltipV2 } from "../../../climateui/components"
import DirectionalityHover from "../../../climateui/components/Widgets/SeasonalWidgets/DirectionalityHover"
import TableWidget, {
    ITableWidgetProps,
    ITableWidgetColumn,
} from "../../../climateui/components/Widgets/Table/TableWidget"
// import { InfoIcon } from "../../../climateui/icons"
import { IYieldStat } from "../../../types"
import {
    getLargestTercile,
    getDirectionOutlook,
    getIsMeanInMass,
} from "../../../utils/transform"
import { getColumns } from "./utils"
import { InfoIcon } from "../../../climateui/icons"

const NINETY_PCT_CONFIDENCE_DESC_FALLBACK = `
The 90% confidence range
is delimited by the 5th and
95th percentiles of the yield
distribution produced by our
probabilistic weather forecasts
`
const RELATIVE_PRODUCTION_DESC_FALLBACK = `
Based on average 
of last 5 years 
production volume & 
represent relative 
contribution to 
national production
`
const formatter = Intl.NumberFormat(undefined, {
    maximumFractionDigits: 1,
})

export interface ImpactDriverObject {
    name: string
    mean_impact: number
}

export const getDirectionalityDescription = (direction: number) => {
    switch (direction) {
        case -2:
            return "noSignal"
        case -1:
            return "belowNormal"
        case 0:
            return "withinNormal"
        case 1:
            return "aboveNormal"
    }
}
export const getDirectionalityHighlightColor = (directionality: number) => {
    switch (directionality) {
        case -2:
            return "#b3b6ba"
        case -1:
            return "#E42437"
        case 0:
            return "#FDB600"
        case 1:
            return "#23AF41"
    }
}

type ITerritory = {
    name: string
}
type YieldDataRow = {
    territory: ITerritory
    stats: { results: IYieldStat[] }
}

const DirectionalityHoverWrapper = (
    modelData: YieldDataRow & {
        directionality: {
            direction: number
        }
        units: string
    }
) => {
    const { t } = useTranslate()
    return (
        <DirectionalityHover
            title={t("mostLikelyScenario", "Most Likely Scenario")}
            translateFn={t}
            description={modelData?.territory?.name}
            rightHeader={t("probability")}
            directionality={modelData?.directionality?.direction}
            personalizedColor={
                getDirectionalityHighlightColor(
                    modelData?.directionality?.direction
                ) ?? ""
            }
            units={modelData?.units}
            quantiles={{
                "quantile_0.33":
                    modelData?.stats?.results[0]?.historical?.yield_terciles[0],
                "quantile_0.67":
                    modelData?.stats?.results[0]?.historical?.yield_terciles[1],
            }}
            probabilities={modelData?.stats?.results[0]?.tercile_probabilities}
        />
    )
}

const get90PctConfidenceColumn = (stat: IYieldStat) => {
    const distribution = stat.outlook_distribution
    if (!distribution) return "-"
    const lowerBound = formatter.format(distribution["0.05"])
    const upperBound = formatter.format(distribution["0.95"])
    return `${lowerBound} - ${upperBound}`
}

function YieldOutlookTableWidget(props: ITableWidgetProps & { units: string }) {
    const {
        data,
        columns: customColumns,
        title,
        loading,
        empty,
        error,
        reload,
        errorMessage,
        reloadMessage,
        noResultsMessage,
        tryAnotherFilterMessage,
        units,
        toggleNewDirectonalityLogic,
    } = props
    const { t } = useTranslate()

    const _data = data
        ?.map((result) => {
            // Exit if there are no results
            if (result.stats.results.length === 0) return {}
            // Extract the list of impact drivers
            const impactDriversArray: [] =
                result.stats.results[0]?.impact_drivers || []
            // Convert into object to show value
            const impactDriversData = impactDriversArray.reduce(
                (
                    prev: Record<string, unknown>,
                    current: ImpactDriverObject
                ) => {
                    prev[current.name] = current.mean_impact
                    return prev
                },
                {}
            )

            const tercileProbabilities: Record<string, number> =
                result?.stats.results[0]?.tercile_probabilities || {}

            // Remove else if approved by DS
            let directionOutlook: number | undefined = 0
            if (toggleNewDirectonalityLogic) {
                directionOutlook = getDirectionOutlook(tercileProbabilities)
                if (directionOutlook && directionOutlook !== -2) {
                    directionOutlook = getIsMeanInMass(
                        directionOutlook,
                        result?.stats.results[0].outlook_mean,
                        result?.stats.results[0].historical
                    )
                }
            } else {
                directionOutlook = getLargestTercile(tercileProbabilities)?.[0]
            }

            return {
                ...result,
                production_fraction_state: result?.production_fraction_state,
                directionality: {
                    direction: directionOutlook ?? "",
                    description: t(
                        getDirectionalityDescription(
                            directionOutlook as number
                        ) ?? ""
                    ),
                },
                impact_drivers: impactDriversData,
                ninetyPctConfidence: get90PctConfidenceColumn(
                    result?.stats.results[0]
                ),
                units,
            }
        })
        .filter((row) => {
            const yieldRow = row as YieldDataRow
            return !!yieldRow && yieldRow.territory && yieldRow.stats
        })
        // Sort alphabetically by territory name
        .sort((a, b) => {
            const { production_fraction_state: pfsA } = a as {
                production_fraction_state: string
            }
            const { production_fraction_state: pfsB } = b as {
                production_fraction_state: string
            }

            const numA = Number.parseInt(pfsA)
            const numB = Number.parseInt(pfsB)

            if (Number.isNaN(numB)) return 1
            if (Number.isNaN(numA)) return -1

            if (numA < numB) return 1
            if (numA > numB) return -1
            return 0
        })

    const _columns = useMemo(() => {
        if (!customColumns) return []
        const defaultColumns = getColumns({ t, units })
        const ninetyPctConfidenceColumn: ITableWidgetColumn = {
            propName: "ninetyPctConfidence",
            header: t("90pctConfidence", "90% Confidence"),
            headerInfo: (
                <TooltipV2
                    position="top"
                    contentClass="w-[182px]"
                    content={t(
                        "90pctConfidenceInfo",
                        NINETY_PCT_CONFIDENCE_DESC_FALLBACK
                    )}>
                    <div className="w-[18px] h-[18px] ml-1 fill-gray-60 cursor-pointer">
                        <InfoIcon />
                    </div>
                </TooltipV2>
            ),
            type: "text",
            columns: [
                {
                    propName: "ninetyPctConfidence",
                    type: "text",
                    selector: {
                        text: "{{ ninetyPctConfidence }}",
                        // TODO: Get unit dynamically
                        unit: units,
                    },
                    customCell:
                        // DISCUSSION: We should find a way to use components
                        // to leverage features like providers and avoid this
                        // unncessary bootstrapping


                            (col) =>
                            ({ row }) => {
                                const value = row.original[col.propName].text
                                const unit = row.original[col.propName].unit
                                return (
                                    <div className="px-2 text-gray-60 body-sm">
                                        {value === "-"
                                            ? "-"
                                            : value + " " + unit}
                                    </div>
                                )
                            },
                },
            ],
        }
        const impactDriverColumn: ITableWidgetColumn = {
            propName: "impact_drivers",
            header: t("yieldImpactDrivers", "Yield Impact Drivers"),
            headerInfo: (
                <div className="w-[18px] h-[18px] ml-1 fill-gray-60 cursor-pointer">
                    {/* TODO: Uncomment and delete span when VAL-717 is merged */}
                    {/* <InfoIcon /> */}
                    <span id="regional-yield-outlook-impact-drivers"></span>
                </div>
            ),
            type: "number",
            columns: ["heat", "drought", "rad", "cold"].map((driver) => ({
                propName: driver,
                type: "number",
                selector: {
                    value: `{{ impact_drivers.${driver}_impact }}`,
                    icon: driver,
                    unit: "%",
                },
            })),
        }
        const directionalOutlookColumn: ITableWidgetColumn = {
            propName: "directionalOutlook",
            header: t("mostLikelyScenario", "Most Likely Scenario"),
            type: "text",
            selector: {
                title: "directionality",
                text: "{{ directionality.description }}",
                icon: "{{ directionality.direction }}",
                onHover: DirectionalityHoverWrapper,
            },
            columns: [
                {
                    propName: "directionalOutlook",
                    type: "text",
                },
            ],
        }

        return [
            ...customColumns,
            directionalOutlookColumn,
            defaultColumns.yieldOutlook,
            ninetyPctConfidenceColumn,
            defaultColumns.historicalDeviation,
            impactDriverColumn,
        ]
    }, [data])

    return (
        <TableWidget
            data={_data || []}
            columns={_columns}
            loading={loading}
            title={title}
            error={error}
            empty={empty}
            reload={reload}
            errorMessage={errorMessage}
            reloadMessage={reloadMessage}
            noResultsMessage={noResultsMessage}
            tryAnotherFilterMessage={tryAnotherFilterMessage}
        />
    )
}

export function RegionalYieldOutlookTableWidget(
    props: ITableWidgetProps & { units: string }
) {
    const { t } = useTranslate()
    const { columns = [], ...rest } = props

    return (
        <YieldOutlookTableWidget
            columns={[
                ...columns,
                {
                    propName: "production_fraction_state",
                    header: t("relativeProduction", "Relative Production"),
                    type: "text",
                    headerInfo: (
                        <TooltipV2
                            position="top"
                            contentClass="w-[182px]"
                            content={t(
                                "relativeProductionInfo",
                                RELATIVE_PRODUCTION_DESC_FALLBACK
                            )}>
                            <div className="w-[18px] h-[18px] ml-1 fill-gray-60 cursor-pointer">
                                <InfoIcon />
                            </div>
                        </TooltipV2>
                    ),
                    selector: {
                        text: "{{ production_fraction_state }}",
                    },
                    columns: [
                        {
                            propName: "production_fraction_state",
                            type: "text",
                        },
                    ],
                },
            ]}
            {...rest}
        />
    )
}

export default YieldOutlookTableWidget
