import { SVGProps } from "react"

const HeatIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 18 18"
        className="fill-inherit"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 5.25C6.93 5.25 5.25 6.93 5.25 9C5.25 11.07 6.93 12.75 9 12.75C11.07 12.75 12.75 11.07 12.75 9C12.75 6.93 11.07 5.25 9 5.25ZM8.25 0.75V3.75H9.75V0.75H8.25ZM8.25 14.25V17.25H9.75V14.25H8.25ZM17.25 8.25H14.25V9.75H17.25V8.25ZM3.75 8.25H0.75V9.75H3.75V8.25ZM12.18 13.245L14.0325 15.0975L15.09 14.04L13.2375 12.1875L12.18 13.245ZM2.9025 3.96L4.755 5.8125L5.8125 4.755L3.96 2.9025L2.9025 3.96ZM4.755 12.18L2.9025 14.0325L3.96 15.09L5.8125 13.2375L4.755 12.18ZM14.04 2.9025L12.1875 4.755L13.245 5.8125L15.0975 3.96L14.04 2.9025Z"
            fill={props.fill}
        />
    </svg>
)

export default HeatIcon
