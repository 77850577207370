import { useTranslate } from "@tolgee/react"
import { useState } from "react"
import { GenericPageHeader } from "../../../components"
import DashboardView from "../../Seasonal/Dashboards/components/DashboardView"
import { TrendFilters, TrendsChartWidget } from "./components"
import { ITrendFilters } from "./components/TrendFilters"

const ClimateTrendsView = () => {
    const { t } = useTranslate()
    const [filters, setFilters] = useState<ITrendFilters>({
        selectedLocations: [],
        selectedVariables: [],
        selectedDecade: "",
        selectedScenario: "",
    })
    return (
        <DashboardView>
            <div className="flex flex-col h-[100vh]">
                <GenericPageHeader
                    pageTitle={t("climateTrends", "Climate Trends")}
                />
                <TrendFilters
                    filters={filters}
                    setFilters={setFilters}
                />
                <div className="my-2">
                    <TrendsChartWidget
                        filters={filters}
                        selectors={{
                            title: `Monthly Decadal`,
                            labelY: t(filters.selectedVariables[0]),
                            groupCandles: true,
                            centerPoints: true,
                            granularity: "monthly",
                            directionalChart: false,
                        }}
                    />
                </div>
            </div>
        </DashboardView>
    )
}

export default ClimateTrendsView
