import { getDaysInMonth } from "date-fns"
import { useLocale } from "../../../providers/LocalizationProvider"
import { useSeasonalCalendar } from "../../../providers/SeasonalCalendarProvider"

const Month = ({ date, customWidth }: { date: Date; customWidth?: number }) => {
    const { pxPerDay } = useSeasonalCalendar()
    const { monthNames } = useLocale()

    const monthLength = getDaysInMonth(date)
    const monthName = monthNames[date.getMonth()]
    const monthYear = date.getMonth() === 0 ? " " + date.getFullYear() : ""

    const actualMonthDays = customWidth ?? monthLength
    const showLabel = customWidth ? customWidth > 12 : true

    return (
        <div
            className="flex flex-row items-center justify-center h-full border-b shrink-0 grow-0 bg-gray-3 border-gray-14"
            style={{
                width: pxPerDay * actualMonthDays + "px",
            }}>
            <div className="flex flex-row items-center gap-2">
                {showLabel && (
                    <>
                        <div className="label-lg text-gray-90">
                            {monthName.slice(0, 3)}
                        </div>
                        {monthYear !== "" && (
                            <div className="font-normal label-lg text-gray-60">
                                {monthYear}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default Month
