import {
    ReactNode,
    useContext,
    useMemo,
    createContext,
    MutableRefObject,
    useRef,
    useCallback,
} from "react"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncActionFn = (...args: any[]) => Promise<any>

interface IDashboardActionsContext {
    csvs: MutableRefObject<
        Record<string, Record<string, Record<string, string>>>
    >
    globalCSVHeader: MutableRefObject<string>
    csvPrefix: MutableRefObject<string>
    registerActions: (newActions: Record<string, AsyncActionFn>) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    performAction: <T = unknown>(action: string, payload?: T) => Promise<any>
}

const DashboardActionsContext = createContext<IDashboardActionsContext>(
    {} as IDashboardActionsContext
)

export const useDashboardActions = () => useContext(DashboardActionsContext)

export const DashboardActionsProvider = ({
    children,
}: {
    children: ReactNode
}) => {
    /* >>>>>>>>>>>>>>>
     * CSVs exports (Legacy)
    /* <<<<<<<<<<<<<<< */
    const csvs = useRef<Record<string, Record<string, Record<string, string>>>>(
        {}
    )
    const globalCSVHeader = useRef<string>("")
    const csvPrefix = useRef<string>("")

    /* >>>>>>>>>>>>>>>
     * Actions
    /* <<<<<<<<<<<<<<< */
    const actions = useRef<Record<string, AsyncActionFn>>({})
    const registerActions = useCallback(
        (newActions: Record<string, AsyncActionFn>) => {
            // NOTE: This registration doesn't consider name collisions. They are going to be
            // overwritten
            actions.current = {
                ...actions.current,
                ...newActions,
            }
        },
        [actions.current]
    )
    const performAction = useCallback(
        <T = unknown,>(action: string, payload?: T) =>
            actions.current[action](payload),
        [actions.current]
    )

    const value = useMemo(
        () => ({
            csvs,
            globalCSVHeader,
            csvPrefix,
            registerActions,
            performAction,
        }),
        [csvs, globalCSVHeader, csvPrefix, registerActions, performAction]
    )

    return (
        <DashboardActionsContext.Provider value={value}>
            {children}
        </DashboardActionsContext.Provider>
    )
}
