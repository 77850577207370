import CalendarCell, { ICalendarCell } from "./CalendarCell"
import { DateValidatorFn } from "./DatePicker"

export interface IBaseCalendarViewProps {
    startOffset?: number
    validator?: DateValidatorFn
    invalidMessage?: string
    onClick?: (cell: ICalendarCell) => void
}
export interface ICalendarViewProps extends IBaseCalendarViewProps {
    header?: string[]
    columns: number
    values: ICalendarCell[]
}

const CalendarView = ({
    header,
    columns,
    startOffset,
    values,
    onClick,
}: ICalendarViewProps) => {
    const classes = [
        "w-full",
        "place-items-center",
        "grid",
        "gap-[0.375em]",
        "mb-1.5",
    ]
    return (
        <div
            style={{
                gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
            }}
            className={classes.join(" ")}>
            {header?.map((label, idx) => (
                <CalendarCell
                    isHeader
                    key={label + idx}
                    label={label}
                />
            ))}
            {startOffset &&
                [...Array(startOffset).keys()].map((key) => (
                    <CalendarCell key={key} />
                ))}
            {values.map((cell, idx) => (
                <CalendarCell
                    onClick={onClick}
                    key={cell.date?.toISO() ?? idx}
                    {...cell}
                />
            ))}
        </div>
    )
}
export default CalendarView
