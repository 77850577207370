const tolgeeAPIData = {
    apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
    apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
}
const tolgeeStaticData = {
    staticData: {
        en: () => import("../i18n/en.json"),
        es: () => import("../i18n/es.json"),
        zh: () => import("../i18n/zh.json"),
        pt: () => import("../i18n/pt.json"),
        ja: () => import("../i18n/ja.json"),
        nl: () => import("../i18n/nl.json"),
    },
}

// ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
const backendURL = process.env.REACT_APP_API_URL ?? "https://api-dev.climate.ai"
const platformURLs = {
    api_url: backendURL + "/",
    risk_platform: backendURL + "/risk/",
    asset_platform: backendURL + "/asset/",
    location_platform: backendURL + "/location/",
    planning_platform: backendURL + "/planning/",
    exploration_platform: backendURL + "/exploration/",
    dashboard_platform: backendURL + "/dashboard/",
    report_platform: backendURL + "/dashboard_reports/",
    yield_platform: backendURL + "/yield_outlook/",
    custom_reports: backendURL + "/report/",
}

const devUtilities = {
    tolgee_config: {
        ...tolgeeAPIData,
    },
    flagsmith_config: {
        environmentID: "HcaWkTkf86BzujrZcBdxYy",
    },
}

const stageUtilities = {
    tolgee_config: {
        // Remember to update files before deployment
        ...tolgeeStaticData,
    },
    flagsmith_config: {
        environmentID: "Sp8yrJczvGGV3RQVNwhr6b",
    },
}

const prodUtilities = {
    tolgee_config: {
        // Remember to update files before deployment
        ...tolgeeStaticData,
    },
    flagsmith_config: {
        environmentID: "MunSpuvwUFP6bKqfvot33J",
    },
}

const commonDevEnvironments = {
    ...platformURLs,
    ...devUtilities,
}

const stageEnv = {
    ...platformURLs,
    ...stageUtilities,
}

const prodEnv = {
    ...platformURLs,
    ...prodUtilities,
}

// ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
const environments = {
    local: commonDevEnvironments,
    dev: commonDevEnvironments,
    development: commonDevEnvironments,
    stage: stageEnv,
    staging: stageEnv,
    prod: prodEnv,
    production: prodEnv,
}

let config = environments.dev // Default used env
const configName = process.env.REACT_APP_ENV
if (environments[configName]) config = environments[configName]
// Starting the app with no options = "yarn start"
// used to set config.env to "sh"
// The following line avoids that:
config.env = configName === "sh" ? "dev" : configName

const SKIN_OPTIONS = ["template", "skip"]
config.skin = "template"
if (SKIN_OPTIONS.includes(process.env.REACT_APP_SKIN)) {
    config.skin = process.env.REACT_APP_SKIN
}

config.tolgee_config = {
    language: "en",
    ...config.tolgee_config,
}

export default config
