import { useContext } from "react"
import { useLocation } from "react-router-dom"
import { TableActions } from "../../../../climateui/components/Table/tableUtils"
import { IPlan } from "../../../../types"
import { useTranslate } from "@tolgee/react"
import { ModalContext, ToastContext } from "../../../../climateui/providers"
import { TrashIcon, EditIcon } from "../../../../climateui/icons"
import { useAccount } from "../../../../providers/AccountProvider"
import queryClient, { planDELETE } from "../../../../utils/networking"
import { areResTypeAndDataValid } from "../../../../climateui/utils/http"
import { usePlanningTool } from "../../../../providers/PlanningToolProvider"

const PlanTableActions = ({ rowData }: { rowData: IPlan }) => {
    const { t } = useTranslate()
    const { confirmationModal } = useContext(ModalContext)
    const { enqueueAlert } = useContext(ToastContext)
    const { selectedAccount } = useAccount()
    const location = useLocation()
    const { goToEditPlan } = usePlanningTool()

    const handleDelete = () => {
        planDELETE(rowData.id)
            .then((res) => {
                if (res?.status != 200 || !areResTypeAndDataValid(res)) return

                enqueueAlert(
                    t("PLANDeletedSuccessfully", "Plan deleted successfully.", {
                        plan: rowData.name || t("thePlan", "Plan"),
                    })
                )
            })
            .catch(() => {
                enqueueAlert(t("PLANDeletionFailed", "Error deleting plan."))
            })
            .finally(() => {
                queryClient.invalidateQueries(["plans", selectedAccount])
            })
    }

    return (
        <TableActions
            rowData={rowData}
            actions={[
                {
                    icon: <EditIcon />,
                    tooltip: t("edit"),
                    onClick: () => {
                        if (selectedAccount === rowData.account_id) {
                            goToEditPlan(
                                rowData.id as string,
                                location.pathname
                            )
                        }
                    },
                },
                {
                    icon: <TrashIcon />,
                    tooltip: t("delete"),
                    onClick: () => {
                        confirmationModal({
                            title: t(
                                "areYouSureDeletePlan",
                                "Delete this operational plan?"
                            ),
                            text: t(
                                "planWillBeDeletedPermanently",
                                "This plan will be deleted permanently."
                            ),
                            onContinueLabel: t("continue"),
                            onCancelLabel: t("cancel"),
                            onContinue: handleDelete,
                        })
                    },
                },
            ]}
        />
    )
}

export default PlanTableActions
