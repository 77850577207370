import { ReactElement } from "react";
import { TickFormatter } from "recharts/types/cartesian/CartesianAxis";

export type TypeTick = {
    index: number
    payload: {
        coordinate: number
        index: number
        offset: number
        value: string
    }
    visibleTicksCount: number
    tickFormatter: TickFormatter
    x: number
    y: number
    stroke: string
    fill: string
    textAnchor: string
    verticalAnchor: string
    width: number
    height: number

}

export const renderYTick = (props: TypeTick, onRenderYAxisTickValue: (value: string, index: number, length: number) => ReactElement) => {
    const { width, height, visibleTicksCount, x, y, payload, index } = props

    const tickWidth = width
    const tickHeight = height / visibleTicksCount
    const tickX = x - tickWidth
    const tickY = y - (tickHeight / 2)

    return (
        <g>
            <foreignObject x={tickX} y={tickY} width={tickWidth} height={tickHeight}>
                {onRenderYAxisTickValue(payload.value, index, visibleTicksCount)}
            </foreignObject>
        </g>
    )

}

export const renderXTick = (props: TypeTick, onRenderXAxisTickValue: (value: string, index: number, length: number) => ReactElement) => {
    const { width, height, visibleTicksCount, x, y, payload, index } = props

    const tickWidth = width / visibleTicksCount
    const tickHeight = height
    const tickX = x - (tickWidth / 2)
    const tickY = y

    return (
        <g>
            <foreignObject x={tickX} y={tickY} width={tickWidth} height={tickHeight}>
                {onRenderXAxisTickValue(payload.value, index, visibleTicksCount)}
            </foreignObject>
        </g>
    )
}

