import { Dispatch, SetStateAction } from "react"
import Checkbox from "../../Checkbox"
import { IDropdownOpt, setSelectedByKey } from "../DropdownSelect/utils"
import { SelectionStatus } from "../../../utils/constants"

const DropdownOption = ({
    optKey,
    opt: { label, icon },
    selected = SelectionStatus.EMPTY,
    setSelected,
    toggle,
    multi,
    hovered,
}: {
    optKey: string
    opt: IDropdownOpt
    selected: SelectionStatus
    setSelected: Dispatch<SetStateAction<Set<string>>>
    toggle: () => void
    multi: boolean
    hovered: boolean
}) => {
    return (
        <button
            tabIndex={0}
            onClick={() => {
                setSelected(setSelectedByKey(optKey))
                if (!multi) toggle()
            }}
            className={[
                "py-[8px] px-[10px] body-md",
                "flex flex-row items-center",
                "cursor-pointer",
                "first:pt-2 first:mt-0",
                "last:pb-2 last:mb-0",
                "hover:bg-gray-3",
                hovered ? "bg-gray-3" : "",
            ].join(" ")}>
            <div
                className={[
                    "relative h-full -left-[10px] bg-gray-30 transition",
                    !multi && selected === SelectionStatus.FULL
                        ? "opacity-100"
                        : "opacity-0",
                ].join(" ")}>
                &nbsp;
            </div>
            {multi && (
                <span className="w-6 h-6 mr-1">
                    <Checkbox
                        status={selected}
                        onChange={() => void 0}></Checkbox>
                </span>
            )}
            {icon}
            {label && <div className={icon ? "ml-1" : ""}>{label}</div>}
        </button>
    )
}
export default DropdownOption
