import { Table, Row } from "@tanstack/react-table"
import { T } from "@tolgee/react"
import { Switch, TooltipV2 } from "../../../../climateui/components"
import { CheckboxCell } from "../../../../climateui/components/Table/tableUtils"
import { useMutation, useQueryClient } from "react-query"
import { alertSettingsQuerySet } from "../../../../utils/networking"
import {
    AlertSettingInput,
    IInsightsLocation,
    IRegion,
    IAugmentedAlertSetting,
    toIAlertSettings,
} from "../../../../types"
import {
    ILabel,
    IPrimitivesDictionary,
} from "../../../../climateui/types"
import AlertSchedules from "./components/AlertSchedule"
import { formatDate } from "../../../../utils/transform"
import {
    getRowSelectStatus,
    getTableSelectStatus,
} from "../../../../utils/tables"
import LabelItem from "../../../../climateui/components/Labels/LabelItem"
import { PRODUCT_IDS } from "../../../../utils/constants"
import { useAccount } from "../../../../providers"
import { useIsFlagEnabled } from "../../../../hooks"
import { InfoIcon } from "../../../../climateui/icons"


const parseStatus = (status: string) => {
    const stat = status.trim().toLowerCase()
    switch (stat) {
        case "active":
        case "enable":
        case "enabled":
            return true
        case "inactive":
        case "disable":
        case "disabled":
        case "deleted":
        default:
            return false
    }
}

export const SORTING_MAPPING: Record<
    string,
    (item: unknown) => string | number | boolean
> = {
    location: (location) => (location as IInsightsLocation).name,
    region: (region) => (region as IRegion).name,
}

export const AlertSettingSwitchCell = ({
    alertSetting,
}: {
    alertSetting: IAugmentedAlertSetting
}) => {
    const queryClient = useQueryClient()
    const { selectedAccount } = useAccount()
    const isStageManagerEnabled = useIsFlagEnabled("feature_stage_manager")

    const { mutateAsync: editAlert } = useMutation(
        ({ alertId, data }: { alertId: string; data: AlertSettingInput }) =>
            alertSettingsQuerySet.put(
                `/${alertId}`,
                data as unknown as IPrimitivesDictionary
            )
    )
    const toggleStatus = (alertSetting: IAugmentedAlertSetting) => {
        Promise.all(
            toIAlertSettings(alertSetting).map((_alertSetting) => {
                const alertSetting = { ..._alertSetting }
                delete alertSetting.location
                return editAlert({
                    alertId: alertSetting.id,
                    data: {
                        ...alertSetting,
                        product_id:
                            alertSetting.product_id ||
                            PRODUCT_IDS.ALERT_SETTINGS,
                        status: parseStatus(alertSetting.status)
                            ? "inactive"
                            : "active",
                    },
                })
            })
        ).finally(() => {
            queryClient.invalidateQueries(["alertSettings"])
            queryClient.invalidateQueries(["riskProfiles", selectedAccount])
        })
    }
    return (
        <div className="flex flex-row items-center gap-3">
        {
            !isStageManagerEnabled &&
            (
                <span className="grow-0">
                    <Switch
                        checked={parseStatus(alertSetting.status)}
                        onChange={() => toggleStatus(alertSetting)}
                        size="md"
                    />
                </span>
            )
        }
            <span className="grow-0">
                {alertSetting.location?.name ?? "Untitled"}
            </span>
        </div>
    )
}

export const AlertScheduleHeading = () => {
    const isStageManagerEnabled = useIsFlagEnabled("feature_stage_manager")

    return (
        <div
            className="flex flex-row">
            <T keyName="alertSchedule">Alert Schedule</T>
            {isStageManagerEnabled && (
                <TooltipV2 
                    content={<>Adjust in the Assets Section</>}
                    position="top"
                >
                    <div className="w-[18px] h-[18px] ml-1 fill-gray-60 cursor-pointer">
                        <InfoIcon />
                    </div>
                </TooltipV2>
            )}
        </div>
    ) 
}

export const columns = [
    {
        id: "select",
        header: ({
            table,
        }: {
            table: Table<IAugmentedAlertSetting>
        }) => (
            <CheckboxCell<IAugmentedAlertSetting>
                rowData={null}
                status={getTableSelectStatus(table)}
                onChange={table.getToggleAllRowsSelectedHandler()}
                isGlobal
            />
        ),
        cell: ({ row }: { row: Row<IAugmentedAlertSetting> }) => (
            <CheckboxCell
                rowData={row.original}
                status={getRowSelectStatus(row)}
                onChange={row.getToggleSelectedHandler()}
            />
        ),
    },
    {
        id: "location",
        cell: ({ row }: { row: Row<IAugmentedAlertSetting> }) => {
            return <AlertSettingSwitchCell alertSetting={row.original} />
        },
        header: () => <T keyName="location">Location</T>,
        meta: {
            sortable: true,
        },
    },
    {
        id: "labels",
        cell: ({ row }: { row: Row<IAugmentedAlertSetting> }) => (
            <div className="flex flex-wrap max-w-[20em] gap-1 my-2">
                {row.original.location &&
                    (row.original.location.labels as ILabel[]).map(
                        (label) => (
                            <span key={label.name}>
                                <LabelItem label={label} />
                            </span>
                        )
                    )}
            </div>
        ),
        header: () => <T keyName="labels">Labels</T>,
        meta: {
            sortable: false,
        },
    },
    {
        id: "region",
        cell: ({ row }: { row: Row<IAugmentedAlertSetting> }) => {
            const regions = row.original.location?.regions
                ?.map((region) => region.name)
                .join(", ")
            return <>{regions ?? "-"}</>
        },
        header: () => <T keyName="region">Region</T>,
        meta: {
            sortable: true,
        },
    },
    {
        id: "lastTriggered",
        cell: ({ row }: { row: Row<IAugmentedAlertSetting> }) => {
            let dateStr = "-"
            if (row.original.lastTriggered) {
                dateStr = formatDate(row.original.lastTriggered)
            }
            return <>{dateStr}</>
        },
        header: () => <T keyName="lastTriggered">Last Triggered</T>,
    },
    {
        id: "alertSchedule",
        cell: ({ row }: { row: Row<IAugmentedAlertSetting> }) => (
            <AlertSchedules row={row} />
        ),
        header: () => <AlertScheduleHeading/>,
    },
]
