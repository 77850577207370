import { ReactNode } from "react"
import { useAreAllFlagsEnabled } from "../hooks"

interface IFFProps {
    children: ReactNode
    flags: string[]
}

// To block features. NOT routes.
export default function FeatureFlag({ children, flags }: IFFProps) {
    const allFlagsEnabled = useAreAllFlagsEnabled(flags)

    return allFlagsEnabled ? <>{children}</> : null
}
