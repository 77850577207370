import { useTranslate } from "@tolgee/react"
import Lottie from "lottie-react"
import climateAILogoIconAnimation from "../../../../components/ClimateAi_Symbol_Animation.json"
import { useCallback, useContext, useMemo, useState } from "react"
import { Button, Table } from "../../../../climateui/components"
import EmptyModal from "../../../../climateui/providers/Modal/EmptyModal"
import { GenericPageHeader } from "../../../../components"
import { ResponsivePaddingWrapper } from "../../../../layouts/TabLayout"
import { useDashboard } from "../../../../providers/DashboardProvider"
import { LocationsContext } from "../../../../providers/LocationsProvider"
import { IInsightsLocation } from "../../../../types"
import { buildLocationColumns } from "../../../Admin/Locations/adminLocationsTableUtils"
import { dashboardAssetsPageColumns } from "../DashboardsTableUtils"
import { useQueryClient } from "react-query"
import { useAccount } from "../../../../providers/AccountProvider"
import { useAuth } from "../../../../providers"

const DashboardAssets = () => {
    const { t } = useTranslate()
    const { locations } = useContext(LocationsContext)
    const { workingDashboard, goToStep, createOrEditDashboard } = useDashboard()
    const [openLoadingModal, setOpenLoadingModal] = useState(false)

    const { selectedAccount, accountsObject } = useAccount()
    const queryClient = useQueryClient()

    const dashboardAssetsColumns = buildLocationColumns(
        dashboardAssetsPageColumns,
        {
            labels: {
                expandable: true,
            },
        }
    )

    const prev = () => {
        goToStep("locations")
    }

    const selectedLocations = useMemo(() => {
        const filteredLocationsIds: string[] = []
        workingDashboard?.locations?.forEach((loc) => {
            filteredLocationsIds.push(loc.location_id as string)
        })

        return locations.filter((loc) => {
            return filteredLocationsIds.includes(loc.id as string)
        })
    }, [workingDashboard?.locations])

    const onFinishDashboardCreation = useCallback(async () => {
        setOpenLoadingModal(true)

        await createOrEditDashboard()

        await Promise.all([
            // also invalidate locations query to know which locations
            // have new varieties associated (through risk settings)
            queryClient.invalidateQueries([
                "locations",
                selectedAccount,
                accountsObject,
            ]),
        ])

        setOpenLoadingModal(false)
    }, [createOrEditDashboard])

    return (
        <>
            <ResponsivePaddingWrapper extraClasses="p-3 sm:p-3 sm:px-5 elevation-1 border-r border-gray-5 bg-gray-1.5 bg-white">
                <div className="flex flex-col h-full overflow-hidden grow gap-2">
                    <GenericPageHeader
                        pageTitle={<h1>Assign assets to each location</h1>}
                        right={
                            <div className="flex">
                                <Button
                                    type="secondary"
                                    label={t("back")}
                                    onClick={prev}
                                    extraClasses="mr-2"
                                />
                                <Button
                                    label={t("continue")}
                                    onClick={onFinishDashboardCreation}
                                />
                            </div>
                        }
                        bottom={undefined}
                    />
                    <div className="overflow-y-auto  grow">
                        <Table<IInsightsLocation>
                            data={selectedLocations}
                            columns={dashboardAssetsColumns}
                            //paginationOptions={pageSizeOpts}
                        />
                    </div>
                </div>
            </ResponsivePaddingWrapper>
            {openLoadingModal ? (
                <EmptyModal
                    open={openLoadingModal}
                    customClasses="h-[200px] w-[360px] relative rounded-lg bg-white p-4">
                    <div className="w-16">
                        <Lottie animationData={climateAILogoIconAnimation} />
                    </div>
                    <h3 className="mt-2 title-sm text-gray-90">
                        {t("updatingDash", "Updating Dashoard...")}
                    </h3>
                    <p className="mt-3 body-md text-gray-60">
                        {t(
                            "messageUpdateDash",
                            "Please wait a moment while we save your changes."
                        )}
                    </p>
                </EmptyModal>
            ) : (
                <></>
            )}
        </>
    )
}

export default DashboardAssets
