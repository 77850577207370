import { DatePicker, FixedElement } from "../../../../../climateui/components"
import { YEAR } from "../../../../../climateui/components/DatePicker"
import { useState, useContext } from "react"
import { DateTime, Duration } from "luxon"
import {
    CalendarIcon,
    ChevronArrow,
    PlusIcon,
} from "../../../../../climateui/icons"
import {
    AlertSettingInput,
    IAlertSetting,
    IAugmentedAlertSetting,
    toIAlertSettings,
} from "../../../../../types"
import { Row } from "@tanstack/react-table"
import { useOutsideComponentClickHandler } from "../../../../../climateui/hooks"
import { useMutation, useQueryClient } from "react-query"
import { alertSettingsQuerySet } from "../../../../../utils/networking"
import { IPrimitivesDictionary } from "../../../../../climateui/types"
import { INPUT_DATE_FORMAT } from "../consts"
import { T, useTranslate } from "@tolgee/react"
import { ToastContext } from "../../../../../climateui/providers"
import { isValidResponse } from "../../../../../climateui/utils/http"
import { PRODUCT_IDS } from "../../../../../utils/constants"
import { useAccount } from "../../../../../providers"
import { useIsFlagEnabled } from "../../../../../hooks"

const DAYS_IN_YEAR = 365
export const parseDate = (monthDate: string) => {
    const date = DateTime.fromFormat(monthDate, INPUT_DATE_FORMAT)
    if (!date.isValid) return undefined
    return date
}
const formatDate = (date?: DateTime | string) => {
    if (!date) return
    if (typeof date === "string") return parseDate(date)?.toFormat("MMM dd")
    return date?.toFormat("MMM dd")
}
const getAlertDuration = (alertSetting: IAlertSetting) => {
    // It is assumed that alerts never span over a year in duration
    const startDate = parseDate(alertSetting.start_date)
    const endDate = parseDate(alertSetting.end_date)
    if (!startDate || !endDate) return Duration.fromMillis(0)
    return endDate.diff(startDate)
}

const AlertSchedule = ({
    isDisabled,
    totalAlerts,
    alertSetting,
}: {
    isDisabled: boolean
    totalAlerts: number
    alertSetting: IAlertSetting
}) => {
    /* CONSTS > START */
    const { id, start_date, end_date, product_id } = alertSetting
    /* CONSTS < END */

    /* HOOKS > START */
    const { t } = useTranslate()
    const { enqueueAlert } = useContext(ToastContext)
    const { selectedAccount } = useAccount()
    const queryClient = useQueryClient()
    /* HOOKS < END */

    /* STATE > START */
    const [editing, setEditing] = useState(false)
    const datePickerRef = useOutsideComponentClickHandler(() =>
        setEditing(false)
    )
    /* STATE < END */

    /* METHODS & UTILS > START */
    const toggleEditing = () => setEditing(!editing)
    const isRangeSet = start_date && end_date
    const isFullYear =
        getAlertDuration(alertSetting).as("days") + 1 >= DAYS_IN_YEAR
    /* METHODS & UTILS < END */

    /* NETWORKING > START */
    const { mutateAsync: deleteAlert } = useMutation(({ id }: { id: string }) =>
        alertSettingsQuerySet.delete(`/${id}`)
    )
    const { mutateAsync: editAlert } = useMutation(
        ({ data }: { data: AlertSettingInput }) =>
            alertSettingsQuerySet.put(
                `/${id}`,
                data as unknown as IPrimitivesDictionary
            )
    )
    /* NETWORKING < END */

    return (
        <div className="shrink-0">
            <FixedElement
                parentElement={null}
                open={editing}>
                <div
                    ref={datePickerRef}
                    className="absolute flex justify-end w-96 z-full -translate-x-full">
                    <DatePicker
                        range
                        initialDate={parseDate(start_date)}
                        initialEndDate={parseDate(end_date)}
                        maxView={YEAR}
                        onChange={(startDate, endDate) => {
                            if (!startDate && !endDate && totalAlerts > 1) {
                                deleteAlert({ id: alertSetting.id })
                                    .then((res) => {
                                        if (!isValidResponse(res)) {
                                            enqueueAlert(
                                                t(
                                                    "alertSettingsUpdateError",
                                                    "Something went wrong! Couldn't update the alert settings."
                                                )
                                            )
                                        } else {
                                            enqueueAlert(
                                                t(
                                                    "alertSettingUpdateSuccess",
                                                    "Alert settings updated successfully!"
                                                )
                                            )
                                        }
                                    })
                                    .finally(() => {
                                        queryClient.invalidateQueries([
                                            "alertSettings",
                                        ])
                                        queryClient.invalidateQueries([
                                            "riskProfiles",
                                            selectedAccount,
                                        ])
                                        setEditing(false)
                                    })
                            } else if (!!startDate === !!endDate) {
                                const payload: AlertSettingInput = alertSetting
                                editAlert({
                                    data: {
                                        ...payload,
                                        product_id:
                                            product_id ||
                                            PRODUCT_IDS.ALERT_SETTINGS,
                                        start_date:
                                            startDate?.toFormat(
                                                INPUT_DATE_FORMAT
                                            ) ?? "",
                                        end_date:
                                            endDate?.toFormat(
                                                INPUT_DATE_FORMAT
                                            ) ?? "",
                                    },
                                })
                                    .then(() =>
                                        enqueueAlert(
                                            t(
                                                "alertSettingUpdateSuccess",
                                                "Alert settings updated successfully!"
                                            )
                                        )
                                    )
                                    .catch(() =>
                                        enqueueAlert(
                                            t(
                                                "alertSettingsUpdateError",
                                                "Something went wrong! Couldn't update the alert settings."
                                            )
                                        )
                                    )
                                    .finally(() => {
                                        queryClient.invalidateQueries([
                                            "alertSettings",
                                        ])
                                        queryClient.invalidateQueries([
                                            "riskProfiles",
                                            selectedAccount,
                                        ])
                                        setEditing(false)
                                    })
                            }
                        }}
                    />
                </div>
            </FixedElement>
            {/* CALENDAR TOGGLE BUTTON */}
            <button
                disabled={isDisabled}
                className={[
                    "w-fit min-w-full flex flex-row items-center justify-start rounded border-[1px] border-gray-14 py-1 pl-1.5 pr-1 text-gray-60",
                    !isRangeSet || isFullYear ? "opacity-50" : "",
                    isDisabled ? "bg-gray-3" : "cursor-pointer"
                ].join(" ")}
                onClick={toggleEditing}>
                {isRangeSet && (
                    <span className="whitespace-nowrap">
                        {formatDate(start_date)} - {formatDate(end_date)}
                    </span>
                )}
                {!isRangeSet && <T keyName="notSchedule">No schedule</T>}
                <span className="block w-6 h-6 ml-2 fill-gray-60">
                    <CalendarIcon />
                </span>
            </button>
        </div>
    )
}

const AlertSchedules = ({
    row,
}: {
    row: Row<IAugmentedAlertSetting>
}) => {
    /* CONSTS > START */
    const DEFAULT_VISIBLE_SCHEDULES = 2
    /* CONSTS < END */

    /* HOOKS > START */
    const { enqueueAlert } = useContext(ToastContext)
    const { selectedAccount } = useAccount()
    const { t } = useTranslate()
    const queryClient = useQueryClient()
    /* HOOKS < END */

    /* STATE > START */
    const [adding, setAdding] = useState<boolean>(false)
    const [visibleSchedules, setVisibleSchedules] = useState<number>(
        DEFAULT_VISIBLE_SCHEDULES
    )
    const datePickerRef = useOutsideComponentClickHandler(() =>
        setAdding(false)
    )
    const { risk_profile_id, location_id, product_id, status } = row.original
    const isStageManagerEnabled = useIsFlagEnabled("feature_stage_manager")
    /* STATE < END */

    /* NETWORKING > START */
    const { mutateAsync: addAlert } = useMutation(
        ({
            startDate,
            endDate,
        }: {
            startDate?: DateTime
            endDate?: DateTime
        }) =>
            alertSettingsQuerySet.post("", [
                {
                    risk_profile_id,
                    location_id,
                    product_id,
                    status,
                    start_date: startDate?.toFormat(INPUT_DATE_FORMAT) ?? "",
                    end_date: endDate?.toFormat(INPUT_DATE_FORMAT) ?? "",
                },
            ])
    )
    /* NETWORKING < END */

    const alertSettingInterfaces = toIAlertSettings(row.original).sort(
        (a, b) => {
            const startDateA = parseDate(a.start_date)
            const startDateB = parseDate(b.start_date)
            if (!startDateA || !startDateB) return 0
            return startDateA.diff(startDateB).as("milliseconds")
        }
    )
    const noSchedules = alertSettingInterfaces.every(
        (alertSetting) => !alertSetting.start_date && !alertSetting.end_date
    )
    const showAddButton =
        !noSchedules && // There's already a schedule set
        alertSettingInterfaces.length <= visibleSchedules && // The alert count doesn't exceed the visible limit
        // The only set alert, doesn't cover the whole year
        !(
            alertSettingInterfaces.length === 1 &&
            getAlertDuration(alertSettingInterfaces[0]).as("days") + 1 >=
                DAYS_IN_YEAR
        ) && !isStageManagerEnabled
    return (
        <div className="flex flex-row flex-wrap gap-1">
            {alertSettingInterfaces
                .slice(0, visibleSchedules)
                .map((alertSetting) => (
                    <div key={alertSetting.id}>
                        <AlertSchedule
                            isDisabled={isStageManagerEnabled}
                            totalAlerts={alertSettingInterfaces.length}
                            alertSetting={alertSetting}
                        />
                    </div>
                ))}
            <FixedElement
                parentElement={null}
                open={adding}>
                <div
                    ref={datePickerRef}
                    className="absolute flex justify-end w-64 z-full -translate-x-full">
                    <DatePicker
                        range
                        maxView={YEAR}
                        onChange={(startDate, endDate) => {
                            if (!!startDate === !!endDate) {
                                addAlert({
                                    startDate,
                                    endDate,
                                })
                                    .then((res) => {
                                        if (isValidResponse(res)) {
                                            enqueueAlert(
                                                t(
                                                    "alertSettingUpdateSuccess",
                                                    "Alert settings updated successfully!"
                                                )
                                            )
                                        } else {
                                            enqueueAlert(
                                                t(
                                                    "alertSettingsUpdateError",
                                                    "Something went wrong! Couldn't update the alert settings."
                                                )
                                            )
                                        }
                                    })
                                    .catch(() =>
                                        enqueueAlert(
                                            t(
                                                "alertSettingsUpdateError",
                                                "Something went wrong! Couldn't update the alert settings."
                                            )
                                        )
                                    )
                                    .finally(() => {
                                        queryClient.invalidateQueries([
                                            "alertSettings",
                                        ])
                                        queryClient.invalidateQueries([
                                            "riskProfiles",
                                            selectedAccount,
                                        ])
                                        setAdding(false)
                                    })
                            }
                        }}
                    />
                </div>
            </FixedElement>
            {showAddButton && (
                <button
                    onClick={() => {
                        setAdding(true)
                    }}
                    className="flex flex-row items-center justify-start rounded border-[1px] border-gray-14 w-fit px-1 text-gray-60 cursor-pointer fill-gray-60">
                    <div className="w-6 h-6">
                        <PlusIcon />
                    </div>
                </button>
            )}
            {alertSettingInterfaces.length > DEFAULT_VISIBLE_SCHEDULES && (
                <button
                    className={[
                        "flex flex-row items-center justify-start cursor-pointer rounded border-[1px] border-gray-14 w-fit py-1 px-1 text-gray-60",
                        visibleSchedules === DEFAULT_VISIBLE_SCHEDULES
                            ? "-rotate-90"
                            : "rotate-90",
                    ].join(" ")}
                    onClick={() =>
                        setVisibleSchedules(
                            visibleSchedules === DEFAULT_VISIBLE_SCHEDULES
                                ? Number.MAX_SAFE_INTEGER
                                : DEFAULT_VISIBLE_SCHEDULES
                        )
                    }>
                    <div className="w-6 h-6 fill-gray-60">
                        <ChevronArrow />
                    </div>
                </button>
            )}
        </div>
    )
}
export default AlertSchedules
