import FixedElement from "./FixedElement"

export type {
    FixedAlignment,
    FixedPosition,
    FixedXAlignment,
    FixedYAlignment,
} from "./utils"

export default FixedElement
