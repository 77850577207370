import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    OnChangeFn,
    Row,
    SortingState,
    useReactTable,
} from "@tanstack/react-table"
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react"
import { fuzzyFilter } from "./tableUtils"
import PaginationControls from "./PaginationControls"
import TableHeader from "./utilityComponents/TableHeader"
import { ICustomTableState } from "./types"

export interface IReactTable<T> {
    data: T[]
    columns: ColumnDef<T, ReactNode>[]
    state?: ICustomTableState
    setRowSelection?: Dispatch<SetStateAction<Record<string, boolean>>>
    setGlobalFilter?: Dispatch<SetStateAction<string>>
    setColumnFilters?: Dispatch<
        SetStateAction<{ id: string; value: unknown }[]>
    >
    rowSelectionFilter?: (rowData: Row<T>) => boolean
    extraClasses?: string
    allFilteredMessage?: string
    noDataMessage?: string
    tableFilteredCallback?: (
        filteredRows: any,
        filteredPageRows?: any
    ) => void | null
    doTableFilterCallbackCount?: number
    getRowId?: (originalRow: T, index: number, parent?: Row<T>) => string
    customFilterFn?: (row: Row<T>, columnId: string, value: string) => boolean
    setSorting?: OnChangeFn<SortingState>
    enableMultiSort?: boolean
    sortDescFirst?: boolean
    hideHeaders?: boolean
    paginationOptions?: Record<string, string>
    outOfText?: string
    footer?: ReactNode
    scrollId?: string
}

function Table<T>({
    data,
    columns,
    state,
    setRowSelection,
    setGlobalFilter,
    customFilterFn, // TODO: Make this work
    setColumnFilters,
    rowSelectionFilter,
    setSorting,
    extraClasses = "",
    allFilteredMessage = "No matching results",
    noDataMessage = "No data available",
    tableFilteredCallback = () => null,
    doTableFilterCallbackCount = 0,
    getRowId = undefined,
    sortDescFirst = false,
    hideHeaders = false,
    paginationOptions,
    outOfText = "of",
    footer = null,
    scrollId,
}: IReactTable<T>): JSX.Element {
    const table = useReactTable<T>({
        data,
        columns,
        state: {
            ...state,
        },
        enableRowSelection: rowSelectionFilter,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: customFilterFn ?? fuzzyFilter<T>,
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        manualSorting: !!state?.sorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        ...(paginationOptions
            ? {
                  getPaginationRowModel: getPaginationRowModel(),
              }
            : {}),
        getRowId: getRowId,
        debugTable: false,
        sortDescFirst,
    })

    // Dummy state to trick react state updates scheduler
    // and always have the latest table info
    const [dummy, setDummy] = useState<number>(0)

    useEffect(() => {
        if (paginationOptions) {
            table.setPageSize(+Object.keys(paginationOptions)[0])
            setDummy((prev) => prev + 1)
        }
    }, [paginationOptions])

    useEffect(() => {
        if (paginationOptions) {
            tableFilteredCallback(
                table.getFilteredRowModel().rows,
                table.getPaginationRowModel().rows
            )
        } else {
            tableFilteredCallback(table.getRowModel().rows)
        }
    }, [doTableFilterCallbackCount, dummy])

    return (
        <div className="flex flex-col h-full">
            <div
                className={
                    "overflow-x-auto " +
                    extraClasses +
                    (paginationOptions ? "" : " grow")
                }>
                <table className="relative w-full border-separate table-auto text-gray-90 border-spacing-0">
                    {!hideHeaders && (
                        <thead className="font-bold label-lg">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <TableHeader
                                            key={header.id}
                                            state={state}
                                            headerGroup={headerGroup}
                                            header={header}
                                        />
                                    ))}
                                </tr>
                            ))}
                        </thead>
                    )}
                    <tbody className="font-normal body-lg">
                        {table.getRowModel().rows.map((row) => (
                            <tr
                                key={row.id}
                                className={
                                    "text-[14px] group" +
                                    (row.getIsSelected()
                                        ? " bg-gray-5"
                                        : " bg-white hover:bg-gray-3") +
                                    ((row.original as any).rowClass
                                        ? " " + (row.original as any).rowClass
                                        : "")
                                }>
                                {row.getVisibleCells().map(
                                    (cell) =>
                                        !state?.hiddenColumns?.includes(
                                            cell.id.split("_")[1]
                                        ) && (
                                            <td
                                                key={cell.id}
                                                className={
                                                    "h-16 text-[14px] border-b border-gray-14 pl-1"
                                                }>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        )
                                )}
                            </tr>
                        ))}
                        {table.getRowModel().rows.length === 0 && (
                            <tr>
                                <td
                                    className="h-16 px-2 text-center border-b border-gray-14"
                                    colSpan={columns.length}>
                                    {state &&
                                    (state.globalFilter !== "" ||
                                        (state.columnFilters &&
                                            state.columnFilters.length !== 0))
                                        ? allFilteredMessage
                                        : noDataMessage}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {/* TABLE FOOTER */}
                {footer}
            </div>

            {paginationOptions && (
                <PaginationControls
                    selectedPageSize={table.getState().pagination.pageSize}
                    paginationOptions={paginationOptions}
                    handlePageSizeChange={table.setPageSize}
                    handleFirstClick={() => table.setPageIndex(0)}
                    handlePrevClick={table.previousPage}
                    handleNextClick={table.nextPage}
                    handleLastClick={() =>
                        table.setPageIndex(table.getPageCount() - 1)
                    }
                    changeCallback={() => {
                        setDummy((prev) => prev + 1)
                    }}
                    backwardDisabled={!table.getCanPreviousPage()}
                    forwardDisabled={!table.getCanNextPage()}
                    currentPage={table.getState().pagination.pageIndex + 1}
                    totalPages={table.getPageCount()}
                    outOfText={outOfText}
                />
            )}
        </div>
    )
}

export default Table
// <tfoot>
//     {table.getFooterGroups().map((footerGroup) => (
//         <tr key={footerGroup.id}>
//             {footerGroup.headers.map((header) => (
//                 <th key={header.id}>
//                     {header.isPlaceholder
//                         ? null
//                         : flexRender(
//                               header.column.columnDef.footer,
//                               header.getContext()
//                           )}
//                 </th>
//             ))}
//         </tr>
//     ))}
// </tfoot>
