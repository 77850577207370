import { useRef, useState } from "react"
import { CancelIcon } from "../../../../climateui/icons"
import { TEMPLATE_LOCATION_CSV_DATA } from "../LocationsUtil"
import {
    BULK_UPLOAD_FILE_EXTENSIONS,
    handleDragOver,
    handleFileChange,
    handleFileDrop,
    showError,
} from "../../../../components/Bulk Upload/bulkUploadUtils"
import { useTranslate } from "@tolgee/react"
import { LOCATIONS_CSV_HEADERS } from "../adminLocationsTableUtils"
import { downloadTemplateCSV } from "../../../../utils/csv"

export interface IBulkUploadLocations {
    onBulkUploadFileChange: (file: File, successAction: () => void) => void
}

function BulkUploadLocations({
    onBulkUploadFileChange,
}: IBulkUploadLocations) {
    const [bulkUploadFile, setBulkUploadFile] = useState<
        File | null | undefined
    >(null)
    const [bulkUploadFileError, setBulkUploadFileError] = useState("")

    const bulkUploadFileInputRef = useRef<HTMLInputElement>(null)

    const { t } = useTranslate()
    const locationsTableCSVHeaders = LOCATIONS_CSV_HEADERS()

    return (
        <>
            <div className="mt-3 label-sm text-gray-60">
                {t("bulkUploadInstructions")}{" "}
                <a
                    id="csv_template_download_link"
                    href="#"
                    onClick={(e) =>
                        downloadTemplateCSV(
                            e.currentTarget,
                            [
                                locationsTableCSVHeaders,
                                TEMPLATE_LOCATION_CSV_DATA,
                            ],
                            "Location Bulk Upload Template.csv"
                        )
                    }
                    className="link">
                    {t("bulkUploadTemplate")}
                </a>
            </div>
            <div className="pt-[8px]">
                <div
                    onDrop={(e) =>
                        handleFileDrop(
                            e,
                            setBulkUploadFile,
                            setBulkUploadFileError,
                            onBulkUploadFileChange
                        )
                    }
                    onDragOver={(e) => handleDragOver(e)}
                    className={
                        "grid place-items-center" +
                        " w-full min-h-[64px] h-full p-1" +
                        " border-dashed border border-gray-14 rounded-md" +
                        " text-gray-30 text-[16px] body-lg font-normal text-center" +
                        " cursor-pointer bg-gray-3 hover:bg-gray-5" +
                        (showError(bulkUploadFileError)
                            ? " border-red-500"
                            : "")
                    }
                    onClick={() =>
                        bulkUploadFile
                            ? setBulkUploadFile(null)
                            : bulkUploadFileInputRef?.current?.click()
                    }>
                    {bulkUploadFile ? (
                        <>
                            {bulkUploadFile.name}
                            <div className="w-[20px]">
                                <CancelIcon />
                            </div>
                        </>
                    ) : (
                        t("dragDropFileInstructions")
                    )}
                </div>
                <input
                    onClick={() => {
                        if (bulkUploadFileInputRef.current)
                            bulkUploadFileInputRef.current.value = ""
                    }}
                    onChange={(e) =>
                        handleFileChange(
                            e,
                            setBulkUploadFile,
                            setBulkUploadFileError,
                            onBulkUploadFileChange
                        )
                    }
                    ref={bulkUploadFileInputRef}
                    type="file"
                    accept={BULK_UPLOAD_FILE_EXTENSIONS.join(", ")}
                    className="hidden"
                />
                {showError(bulkUploadFileError) && (
                    <span className="block text-red-500 text-[12px] pt-[1px] w-[292px] text-center">
                        {bulkUploadFileError}
                    </span>
                )}
            </div>
        </>
    )
}

export default BulkUploadLocations
