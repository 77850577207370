import {
    LocationPinIcon,
    WarnTriangleIcon,
    BoxIcon,
} from "../../climateui/icons"
import AddEditLocationsView from "./Locations/AddEditLocationsView"
import AdminLocationsLayout from "./Locations/AdminLocationsLayout"
import LocationsTableView from "./Locations/LocationsTableView"
import { IRoute } from "../../climateui/types"
import { AlertSettingsView } from "./RiskProfiles/AlertSettings/AlertSettingsView"
import RiskProfilesTableView from "./RiskProfiles/RiskProfilesTableView"
import { Outlet } from "react-router-dom"
import AssetsView from "./Assets"
import NotFound from "../NotFound"
import VarietyTimeline from "./Assets/Timeline/VarietyTimeline"
import RiskProfilesTab from "./RiskProfiles/RiskProfilesTab"

const adminChildRoutes: IRoute[] = [
    {
        path: "assets",
        label: "Assets",
        labelKey: "assets",
        icon: <BoxIcon />,
        element: <Outlet />,
        featureFlags: ["ui_admin_assets_page_enabled"],
        children: [
            {
                path: "",
                element: <AssetsView />,
            },
            {
                path: ":varietyId/timeline",
                featureFlags: ["feature_stage_manager"],
                element: <VarietyTimeline />,
            },
        ],
    },
    {
        path: "locations",
        label: "Locations",
        labelKey: "locationsLabel",
        icon: <LocationPinIcon />,
        element: <AdminLocationsLayout />,
        children: [
            {
                path: "",
                element: <LocationsTableView />,
            },
            {
                path: "add",
                element: <AddEditLocationsView />,
            },
            {
                path: "edit/:locationId",
                element: <AddEditLocationsView />,
            },
        ],
    },
    {
        path: "risk-profiles",
        label: "Risk Profiles",
        labelKey: "riskProfilesLabel",
        icon: <WarnTriangleIcon />,
        element: <RiskProfilesTab />,
        children: [
            {
                path: "",
                element: <RiskProfilesTableView />,
            },
            {
                path: ":riskProfileId/alerts",
                element: <AlertSettingsView />,
            },
        ],
    },
    // {
    //     path: "users",
    //     label: "Users",
    //     labelKey: "usersLabel",
    //     icon: <UserIcon />,
    //     element: <div>Users</div>,
    // },
    {
        path: "*",
        element: <NotFound />,
    },
]

export default adminChildRoutes
