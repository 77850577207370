import { useTranslate } from "@tolgee/react"
import { useMemo, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { DebounceSearchInput, TooltipV2 } from "../../climateui/components"
import { BackToMenuIcon, PlusIcon } from "../../climateui/icons"
import { useUI } from "../../providers/UIProvider"

function ChildRoutesSidebar() {
    const { t } = useTranslate()
    const navigate = useNavigate()
    const [search, setSearch] = useState("")
    const {
        showSecondSidebar,
        setShowSecondSidebar,
        sidebarOpen,
        activeSecondSidebarOptions,
    } = useUI()

    const routesToDisplay = useMemo(() => {
        const searchLowerCase = search.toLowerCase()
        return activeSecondSidebarOptions?.routes.filter(
            (route) => route.label?.toLowerCase().includes(searchLowerCase)
        )
    }, [search, activeSecondSidebarOptions])

    return (
        <div
            className={[
                "transition-all duration-100 z-20 hidden md:block",
                "border-r",
                sidebarOpen && showSecondSidebar && activeSecondSidebarOptions
                    ? "w-[208px] border-gray-14"
                    : "w-[0px] border-r-0 border-transparent",
            ].join(" ")}>
            {activeSecondSidebarOptions && (
                <div className="flex flex-col h-full gap-3 py-3.5 overflow-hidden">
                    <div className="flex flex-row items-center justify-between pl-3 pr-4 fill-gray-60 w-full">
                        <TooltipV2
                            doShow={
                                !activeSecondSidebarOptions.cannotGoBackToMain
                            }
                            content={t("backToMainPage", "Back to main page")}
                            position="bottom">
                            <button
                                disabled={
                                    activeSecondSidebarOptions.cannotGoBackToMain
                                }
                                className={[
                                    "group flex flex-row items-center gap-1.5",
                                    "fill-gray-60 p-1 rounded-sm min-w-0",
                                    "enabled:hover:bg-gray-3 enabled:cursor-pointer enabled:hover:underline",
                                    "disabled:fill-gray-30",
                                ].join(" ")}
                                onClick={() => {
                                    navigate(
                                        activeSecondSidebarOptions.createRoutePath()
                                    )
                                    setShowSecondSidebar(false)
                                }}>
                                <div className="w-5 h-5 shrink-0">
                                    <BackToMenuIcon />
                                </div>
                                <div className="label-lg whitespace-nowrap truncate">
                                    {activeSecondSidebarOptions.title}
                                </div>
                            </button>
                        </TooltipV2>
                        {activeSecondSidebarOptions.plusButtonTooltip !==
                            "" && (
                            <TooltipV2
                                content={
                                    activeSecondSidebarOptions.plusButtonTooltip
                                }
                                position="bottom">
                                <button
                                    className="w-5 h-5 shrink-0 hover:fill-gray-90 transition-all cursor-pointer"
                                    onClick={() =>
                                        navigate(
                                            activeSecondSidebarOptions.createRoutePath(
                                                "new"
                                            )
                                        )
                                    }>
                                    <PlusIcon />
                                </button>
                            </TooltipV2>
                        )}
                    </div>

                    <div className="px-3.5">
                        <DebounceSearchInput
                            isSmall
                            placeholder={t("search")}
                            onSearch={setSearch}
                        />
                    </div>

                    <ul className="grow overflow-y-auto">
                        {activeSecondSidebarOptions.routes.length === 0 && (
                            <li className="flex flex-row items-center p-[6px] px-5 w-full overflow-hidden">
                                <span className="whitespace-nowrap truncate body-sm">
                                    {activeSecondSidebarOptions.emptyMsg ??
                                        t(
                                            "noRoutesAvailable",
                                            "No routes available"
                                        )}
                                </span>
                            </li>
                        )}
                        {search !== "" &&
                            routesToDisplay &&
                            routesToDisplay.length === 0 && (
                                <li className="flex flex-row items-center p-[6px] px-5 w-full overflow-hidden">
                                    <span className="whitespace-nowrap truncate body-sm">
                                        {t("noResults")}
                                    </span>
                                </li>
                            )}
                        {routesToDisplay?.map((route) => {
                            const routePath =
                                activeSecondSidebarOptions.createRoutePath(
                                    route
                                )
                            const normalClasses =
                                route.customClasses?.normal ?? "text-gray-90"
                            const activeClasses =
                                route.customClasses?.active ??
                                "text-accent font-bold"
                            return (
                                <li key={"subRoute-" + route.path}>
                                    <TooltipV2
                                        containerClasses="w-full h-fit"
                                        doShow
                                        contentClass="pointer-events-none"
                                        content={route.label ?? ""}
                                        position="right">
                                        <NavLink
                                            className={({ isActive }) =>
                                                (isActive
                                                    ? activeClasses
                                                    : normalClasses) +
                                                " flex flex-row items-center p-[6px] pl-6 pr-3.5 hover:bg-gray-5 w-full"
                                            }
                                            to={routePath}>
                                            <span className="whitespace-nowrap truncate body-sm">
                                                {route.label}
                                            </span>
                                        </NavLink>
                                    </TooltipV2>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default ChildRoutesSidebar
