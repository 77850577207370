import { ReactNode } from "react"

interface IPaginationButton {
    icon: ReactNode
    extraClasses?: string
    handleClick: () => void
    disabled?: boolean
}

export default function PaginationButton({
    icon,
    extraClasses = "",
    handleClick,
    disabled = false,
}: IPaginationButton) {
    return (
        <button
            disabled={disabled}
            onClick={handleClick}
            className={[
                "w-6 h-6",
                "cursor-pointer fill-gray-60",
                "disabled:cursor-not-allowed disabled:fill-gray-30",
                extraClasses,
            ].join(" ")}>
            {icon}
        </button>
    )
}
