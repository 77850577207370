import { BoundingRect } from "../../types/utility"

export type FixedPosition = "top" | "bottom" | "left" | "right"
export type FixedYAlignment = "top" | "bottom" | "TOP" | "BOTTOM" | "center"
export type FixedXAlignment = "left" | "right" | "LEFT" | "RIGHT" | "center"
export type FixedAlignment = FixedXAlignment | FixedYAlignment

export const PORTAL_DIV_ID = "cai-fixed-portal"
export const getOrCreatePortal = () => {
    let fixedLayer = document.getElementById(PORTAL_DIV_ID)

    if (!fixedLayer) {
        fixedLayer = document.createElement("div")
        document.body.append(fixedLayer)
        fixedLayer.id = PORTAL_DIV_ID
        fixedLayer.style.position = "absolute"
        fixedLayer.style.top = "0"
        fixedLayer.style.left = "0"
        fixedLayer.style.width = "0px"
        fixedLayer.style.height = "0px"
        fixedLayer.style.zIndex = "999999999"
        fixedLayer.style.overflow = "visible"
    }

    return fixedLayer
}

const getChildPosition = (
    position: FixedPosition,
    parentRect: BoundingRect | undefined,
    childRect: BoundingRect | undefined
):
    | {
          top?: number
          left?: number
      }
    | undefined => {
    if (!parentRect || !childRect) return
    const { height: parentHeight, width: parentWidth } = parentRect
    const { height: childHeight, width: childWidth } = childRect
    switch (position) {
        case "top":
            return {
                top: -childHeight,
            }
        case "right":
            return { left: parentWidth }
        case "left":
            return {
                left: -parentWidth - childWidth,
            }
        case "bottom":
            return { top: parentHeight }
    }
}

const getChildAlignment = (
    position: FixedPosition,
    alignment: FixedAlignment,
    parentRect: BoundingRect | undefined,
    childRect: BoundingRect | undefined
):
    | {
          top?: number
          left?: number
      }
    | undefined => {
    if (!parentRect || !childRect) return
    const {
        top: parentTop,
        right: parentRight,
        bottom: parentBottom,
        left: parentLeft,
        height: parentHeight,
        width: parentWidth,
    } = parentRect
    const {
        top: childTop,
        right: childRight,
        bottom: childBottom,
        left: childLeft,
        height: childHeight,
        width: childWidth,
    } = childRect
    switch (alignment) {
        case "TOP":
            return { top: parentTop - childBottom }
        case "top":
            return { top: parentTop - childTop }
        case "RIGHT":
            return { left: parentRight - childLeft }
        case "right":
            return { left: parentRight - childRight }
        case "center":
            if (position === "top" || position === "bottom")
                return { left: parentWidth / 2 - childWidth / 2 }
            else return { top: parentHeight / 2 - childHeight / 2 }
        case "BOTTOM":
            return { top: parentBottom - childTop }
        case "bottom":
            return { top: parentBottom - childBottom }
        case "LEFT":
            return { left: parentLeft - childRight }
        case "left":
            return { left: parentLeft - childLeft }
    }
}

export const getChildPosAndAlign = (
    position: FixedPosition,
    alignment: FixedAlignment,
    parentRect: BoundingRect | undefined,
    childRect: BoundingRect | undefined
) => ({
    ...getChildPosition(position, parentRect, childRect),
    ...getChildAlignment(position, alignment, parentRect, childRect),
})
