import { useNavigate } from "react-router-dom"
import { BackgroundAndCardLayout, Button } from "../../climateui/components"
import { useTranslate } from "@tolgee/react"

const RestorePasswordConfirmation = () => {
    const navigate = useNavigate()
    const { t } = useTranslate()
    return (
        <BackgroundAndCardLayout copyright={t("copyright")}>
            <div className="pb-2 leading-tight text-center text-gray-90 title-sm">
                {t("passwordChangedSuccess")}
            </div>
            <Button
                label={t("login")}
                type="primary"
                extend={true}
                onClick={() => {
                    navigate("/login")
                }}
            />
        </BackgroundAndCardLayout>
    )
}

export default RestorePasswordConfirmation
