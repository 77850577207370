import { SVGProps } from "react"

const DroughtIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 18 18"
        className="fill-inherit"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.75 6C5.82107 6 7.5 4.32107 7.5 2.25H6.75C4.67893 2.25 3 3.92893 3 6H3.75Z"
            fill={props.fill}
        />
        <path
            d="M15.75 13.5H2.25V15H15.75V13.5Z"
            fill={props.fill}
        />
        <path
            d="M14.25 9.75C12.1789 9.75 10.5 8.07107 10.5 6H11.25C13.3211 6 15 7.67893 15 9.75H14.25Z"
            fill={props.fill}
        />
        <rect
            x="8.25"
            y="2.25"
            width="1.5"
            height="11.25"
            fill={props.fill}
        />
    </svg>
)

export default DroughtIcon
