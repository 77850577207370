function OrSeparator({ label = "Or" }) {
    return (
        <div className="flex flex-row items-center w-full py-3 gap-2">
            <div className="h-1 border-b-2 grow border-gray-14"></div>
            <div className="label-lg text-gray-60">{label}</div>
            <div className="h-1 border-b-2 grow border-gray-14"></div>
        </div>
    )
}

export default OrSeparator
