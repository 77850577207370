import { Tooltip } from "../../../climateui/components"
import { useLocations } from "../../../providers"
import { IInsightsLocation } from "../../../types"

function AssetsVarietiesCell({
    rowData,
}: {
    rowData: IInsightsLocation
}) {
    const { locationVarieties } = useLocations()

    const assets = rowData.assets
    const formattedAssets = assets?.map((asset) => asset.name).join(", ") ?? ""

    if (rowData.id) {
        const varieties = rowData.varieties

        const formattedVarieties = varieties
            .map((variety) => locationVarieties[variety.id])
            .join(", ")

        let formattedValue: string | string[] = []
        if (varieties.length > 0) formattedValue.push(formattedVarieties)
        if (
            assets &&
            assets.length > 0 &&
            !formattedVarieties.includes(formattedAssets)
        )
            formattedValue.push(formattedAssets)
        formattedValue = formattedValue.join(", ")

        return (
            <Tooltip
                content={formattedValue}
                contentClass="w-64"
                doShow={varieties.length > 2}>
                <p className="line-clamp-3 w-full">{formattedValue}</p>
            </Tooltip>
        )
    }

    if (!assets || assets.length === 0) return null

    return (
        <Tooltip
            content={formattedAssets}
            contentClass="w-64"
            doShow={assets.length > 2}>
            <p className="line-clamp-3 w-full">{formattedAssets}</p>
        </Tooltip>
    )
}

export default AssetsVarietiesCell
