import { useCallback, useEffect, useState } from "react"
import ChevronIcon from "../../../../climateui/icons/ChevronArrow"
import { TooltipV2 } from "../../../../climateui/components"
import { useTranslate } from "@tolgee/react"
import { useSearchParams } from "react-router-dom"
import { SingleDropdownSelect } from "../../../../climateui/components/Inputs"
import { CalendarIcon } from "../../../../climateui/icons"

/* CONSTS > START */
export const NEXTTWOWEEKS = 0
export const NEXTSIXWEEKS = 1
export const NEXTSIXMONTHS = 2
const scope: { [key: number]: string } = {
    [NEXTTWOWEEKS]: "daily",
    [NEXTSIXWEEKS]: "weekly",
    [NEXTSIXMONTHS]: "monthly",
}
/* CONSTS < END */

interface IAlertDateNavigatorProps {
    onChange?: (timeResolution: string) => void
    defaultMode?: number
    isLoading?: boolean
    setScope?: (scope: number) => void
}

const AlertDateNavigator: React.FC<IAlertDateNavigatorProps> = ({
    onChange,
    defaultMode = NEXTTWOWEEKS,
    setScope,
}) => {
    const { t } = useTranslate()
    const [queryParams, setQueryParams] = useSearchParams()
    const [timeScope, setTimeScope] = useState(defaultMode)

    const handleSelectMode = useCallback(
        (newSelected: any) => {
            const newMode = Number.parseInt(newSelected)
            if (!isNaN(newMode)) {
                setTimeScope(newMode)
                setScope?.(newMode)
                if (onChange) {
                    onChange(scope[newMode]) // Pass the time resolution string
                }
                queryParams.set("scope", scope[newMode])
                setQueryParams(queryParams)
            }
        },
        [setScope, onChange, queryParams, setQueryParams]
    )

    const navigateDate = (direction: number) => {
        const newMode = (timeScope + direction + 3) % 3
        if (!isNaN(newMode)) {
            setTimeScope(newMode)
            setScope?.(newMode)
            if (onChange) {
                onChange(scope[newMode])
            }
            queryParams.set("scope", scope[newMode])
            setQueryParams(queryParams)
        }
    }

    const goBack = () => {
        navigateDate(-1)
    }

    const goForward = () => {
        navigateDate(1)
    }

    return (
        <TooltipV2
            content={scope[timeScope]}
            position="top">
            <div className="flex items-center bg-white border rounded-sm gap-1 border-gray-14">
                <div className="flex items-center mx-1">
                    {/* BACK ARROW */}
                    <button
                        onClick={goBack}
                        className="w-5 h-5 cursor-pointer transform rotate-90">
                        <ChevronIcon fill="#666D74" />
                    </button>
                    {/* FORWARD ARROW */}
                    <button
                        onClick={goForward}
                        className="w-5 h-5 cursor-pointer transform -rotate-90">
                        <ChevronIcon fill="#666D74" />
                    </button>
                </div>
                <SingleDropdownSelect
                    options={{
                        [NEXTTWOWEEKS]: t("daily"),
                        [NEXTSIXWEEKS]: t("weekly"),
                        [NEXTSIXMONTHS]: t("monthly"),
                    }}
                    selected={timeScope.toString()}
                    setSelected={handleSelectMode}
                    trailingIcon={<CalendarIcon />}
                />
            </div>
        </TooltipV2>
    )
}

export default AlertDateNavigator
