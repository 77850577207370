import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useMemo,
    useState,
} from "react"
import {
    UseMutateAsyncFunction,
    useMutation,
    useQueryClient,
} from "react-query"
import { CAIResponse } from "../climateui/utils/http"
import {
    IAccountAsset,
    AssetInput,
    IAsset,
    IVarietyAttribute,
    VarietyInput,
    IVariety,
} from "../types"
import {
    accountAssetQuerySet,
    assetQuerySet,
    varietyQuerySet,
} from "../utils/networking/asset"
import { useTranslate } from "@tolgee/react"
import useAssetsQuery from "../hooks/useAssetsQuery"
import useCAIDefaultVarietiesQuery from "../hooks/useCAIDefaultVarietiesQuery"
import useVarietiesQuery from "../hooks/useVarietiesQuery"
import { useAccount } from "./AccountProvider"
import useAccountAssetsQuery from "../hooks/useAccountAssetsQuery"
import { arrToDict } from "../utils/transform"

type CustomMutateFunction<T> = UseMutateAsyncFunction<
    CAIResponse,
    unknown,
    T,
    unknown
>

export interface IAssetsContext {
    accountAssets: Record<string, IAccountAsset> | undefined
    assets: Record<string, IAsset> | undefined
    allAssets: Record<string, IAsset> | undefined
    varieties: Record<string, IVariety> | undefined
    caiDefaultVarieties: Record<string, IVariety> | undefined
    varietiesPerAssetId: Record<string, IVariety[]>
    varietiesOptions: Record<string, string>
    addAsset: CustomMutateFunction<AssetInput>
    editAsset: CustomMutateFunction<{ id: string; asset: AssetInput }>
    deleteAsset: CustomMutateFunction<string>
    addVariety: CustomMutateFunction<VarietyInput>
    editVariety: CustomMutateFunction<{ id: string; variety: VarietyInput }>
    deleteVariety: CustomMutateFunction<string>
    defaultAttributes: Record<string, IVarietyAttribute>
    selectedVariety: IVariety | undefined
    setSelectedVariety: Dispatch<SetStateAction<IVariety | undefined>>
    isLoadingAssets: boolean
    isLoadingVarieties: boolean
    isFetchingVarieties: boolean
    isLoadingAccountAssets: boolean
    updateAccountAssetIsSetup: CustomMutateFunction<{
        id: string
        asset_id: string
        is_setup: boolean
    }>
}

export const AssetsContext = createContext({} as IAssetsContext)
export const useAssets = () => useContext(AssetsContext)

function AssetsProvider({ children }: { children: ReactNode }) {
    const { t } = useTranslate()
    const queryClient = useQueryClient()
    const invalidateQueries = (response: CAIResponse) => {
        queryClient.invalidateQueries("assets")
        queryClient.invalidateQueries("varieties")
        return response
    }

    const [selectedVariety, setSelectedVariety] = useState<IVariety>()

    // This list could be retrieved from the backend perhaps
    const defaultAttributes = {
        gdd_base_temp: {
            name: t("gdd_base_temp", "GDD Base Temp"),
            unit: "C",
        },
        gdd_max_threshold: {
            name: t("gdd_max_threshold", "GDD Max Threshold"),
            unit: "C",
        },
        gdd_min_threshold: {
            name: t("gdd_min_threshold", "GDD Min Threshold"),
            unit: "C",
        },
    }

    const { selectedAccount } = useAccount()

    const { data: accountAssets, isLoading: isLoadingAccountAssets } =
        useAccountAssetsQuery(selectedAccount)

    const { data: allAssets, isLoading: isLoadingAssets } =
        useAssetsQuery(selectedAccount)

    const assets = useMemo(() => {
        if (!allAssets || !accountAssets) return {}

        const setupAssets = Object.values(
            allAssets as Record<string, IAsset>
        ).filter((asset) => {
            return (accountAssets as Record<string, IAccountAsset>)[
                asset.id
            ]?.is_setup
        })
        return arrToDict<IAsset>(setupAssets, "id")
    }, [allAssets, accountAssets])

    const { data: caiDefaultVarieties } = useCAIDefaultVarietiesQuery(allAssets)

    const {
        data: varieties,
        isLoading: isLoadingVarieties,
        isFetching: isFetchingVarieties,
    } = useVarietiesQuery(assets)

    const varietiesPerAssetId = useMemo(() => {
        if (!assets || !varieties) return {}

        return Object.keys(varieties).reduce((result, currVarId) => {
            const currVariety = varieties[currVarId]
            const assetId = currVariety.asset.id

            if (!result[assetId]) result[assetId] = []

            result[assetId].push(currVariety)
            return result
        }, {} as Record<string, IVariety[]>)
    }, [assets, varieties])

    const varietiesOptions: Record<string, string> = useMemo(
        () =>
            varieties
                ? Object.entries(varieties)
                      .sort(([, varietyA], [, varietyB]) => {
                          if (varietyA.name > varietyB.name) return -1
                          if (varietyA.name < varietyB.name) return 1
                          return 0
                      })
                      .reduce((acc: Record<string, string>, [id, value]) => {
                          acc[id] = `${value.asset.name} (${value.name})`
                          return acc
                      }, {})
                : {},
        [varieties]
    )

    /* ASSETS > START */
    const { mutateAsync: addAsset } = useMutation({
        mutationFn: (asset: AssetInput) =>
            assetQuerySet.post("/", asset).then(invalidateQueries),
    })
    const { mutateAsync: editAsset } = useMutation({
        mutationFn: ({ id, asset }: { id: string; asset: AssetInput }) =>
            assetQuerySet.put(`/${id}`, asset).then(invalidateQueries),
    })
    const { mutateAsync: deleteAsset } = useMutation({
        mutationFn: (id: string) =>
            assetQuerySet
                .post("/delete", { payload: id })
                .then(invalidateQueries),
    })
    /* ASSETS < END */

    /* ASSET VARIETIES > START */
    const { mutateAsync: addVariety } = useMutation({
        mutationFn: (variety: VarietyInput) =>
            varietyQuerySet.post("/", variety).then(invalidateQueries),
    })
    const { mutateAsync: editVariety } = useMutation({
        mutationFn: ({ id, variety }: { id: string; variety: VarietyInput }) =>
            varietyQuerySet.put(`/${id}`, variety).then(invalidateQueries),
    })
    const { mutateAsync: deleteVariety } = useMutation({
        mutationFn: (id: string) =>
            varietyQuerySet.delete(`/${id}`).then(invalidateQueries),
    })
    /* ASSET VARIETIES < END */

    /* ACCOUNT_ASSET > START */
    const { mutateAsync: updateAccountAssetIsSetup } = useMutation({
        mutationFn: ({
            id,
            asset_id,
            is_setup,
        }: {
            id: string
            asset_id: string
            is_setup: boolean
        }) =>
            accountAssetQuerySet
                .put(`/${id}`, {
                    account_id: selectedAccount,
                    asset_id: asset_id,
                    is_setup: is_setup,
                    is_contracted: true,
                })
                .then((response: CAIResponse) => {
                    queryClient.invalidateQueries([
                        "accountAssets",
                        selectedAccount,
                    ])
                    return response
                }),
    })
    /* ACCOUNT_ASSET < END */

    const value = useMemo(
        () => ({
            accountAssets,
            assets,
            allAssets,
            varieties,
            caiDefaultVarieties,
            varietiesOptions,
            addAsset,
            editAsset,
            deleteAsset,
            addVariety,
            editVariety,
            deleteVariety,
            defaultAttributes,
            selectedVariety,
            setSelectedVariety,
            varietiesPerAssetId,
            isLoadingAssets,
            isLoadingVarieties,
            isFetchingVarieties,
            isLoadingAccountAssets,
            updateAccountAssetIsSetup,
        }),
        [
            accountAssets,
            assets,
            allAssets,
            varieties,
            caiDefaultVarieties,
            varietiesOptions,
            addAsset,
            editAsset,
            deleteAsset,
            addVariety,
            editVariety,
            deleteVariety,
            defaultAttributes,
            selectedVariety,
            setSelectedVariety,
            varietiesPerAssetId,
            isFetchingVarieties,
        ]
    )

    return (
        <AssetsContext.Provider value={value}>
            {children}
        </AssetsContext.Provider>
    )
}

export default AssetsProvider
