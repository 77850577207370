import { FC } from 'react'

interface DefaultTitleProps {
    value: string
}

export const DefaultTitle: FC<DefaultTitleProps> = ({ value }) => {
    return (
        <h3 className='pb-4 label-lg text-gray-90'>{value}</h3>
    )
}

/* Helper functions */
export const onRenderDefaultTitle = (value: string) => {
    return (
        <DefaultTitle value={value} />
    )
}