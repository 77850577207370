import { useFlags } from "flagsmith/react"
import { IFlagsmithFeature } from "flagsmith/types"
import { useCallback, useMemo } from "react"
import { IRoute } from "../climateui/types"
import { protectedRoutes } from "../utils/appRoutes"

const env = process.env.REACT_APP_ENV
const isLocalDevelopment = env === "local" || env === "sh"

export const useCustomFlags = (flagKeys: string[]) => {
    // Make actual Flagsmith API call
    const flags = useFlags(flagKeys) as Record<string, IFlagsmithFeature>

    if (isLocalDevelopment) {
        // Generate default flag object for local development
        const defaultFlag = {
            enabled: true,
            value: 0,
        }
        const defaultFlags: Record<string, IFlagsmithFeature> = {}

        flagKeys.forEach((flagKey) => {
            defaultFlags[flagKey] = defaultFlag
        })

        return defaultFlags
    }

    // Check if flags were returned for all requested keys
    flagKeys.forEach((flagKey) => {
        if (!flags[flagKey]) {
            // Flag not found, set it to the default flag
            flags[flagKey] = {
                enabled: true,
                value: 0,
            }
        }
    })

    return flags
}

export const useIsFlagEnabled = (flagKey: string) => {
    const flags = useCustomFlags([flagKey])
    return flags[flagKey].enabled
}

export const useFlagValue = (flagKey: string) => {
    const flags = useCustomFlags([flagKey])
    return flags[flagKey].value
}

// For code that need multiple features enabled. NOT routes.
export const useAreAllFlagsEnabled = (flagKeys?: string[]) => {
    const flags = useCustomFlags(flagKeys ?? [])

    if (!flagKeys || flagKeys.length === 0) return true

    return flagKeys.every((flagKey) => flags[flagKey].enabled)
}

const flatRoutesFeatureFlags = (routes?: IRoute[]) => {
    const allFeatureFlags: string[] = []

    routes?.forEach((route) => {
        if (route.featureFlags && route.featureFlags.length > 0)
            allFeatureFlags.push(...route.featureFlags)

        if (route.children && route.children.length > 0)
            allFeatureFlags.push(...flatRoutesFeatureFlags(route.children))
    })

    return allFeatureFlags
}

export interface IRoutesFlags {
    allRouteFlagsEnabled: (flagKeys: string[]) => boolean
}

export const useRoutesFeatureFlags = (
    routes: IRoute[] = protectedRoutes
): IRoutesFlags => {
    const allRouteFlags = useCustomFlags(flatRoutesFeatureFlags(routes))

    const isRouteFlagEnabled = (flagKey: string) => {
        return allRouteFlags[flagKey].enabled
    }

    const allRouteFlagsEnabled = useCallback(
        (flagKeys?: string[]) => {
            if (!flagKeys || flagKeys.length === 0) return true

            return flagKeys.every((flagKey) => isRouteFlagEnabled(flagKey))
        },
        [allRouteFlags]
    )

    const hookValue: IRoutesFlags = useMemo(
        () => ({ allRouteFlagsEnabled }),
        [allRouteFlagsEnabled]
    )

    return hookValue
}
