import { useTranslate } from "@tolgee/react"
import { useState, Fragment, ReactNode } from "react"
import { Transition } from "@headlessui/react"
import { DraggableElement, Tooltip } from "../../../climateui/components"
import { useOutsideComponentClickHandler } from "../../../climateui/hooks"
import { PlusIcon, ThreeDotsIcon } from "../../../climateui/icons"
import { daysBetween } from "../../../utils"
import { MIN_STAGE_DURATION, moveDragAuxDate, moveStartDate } from "./utils"
import DraggableItem from "./DraggableItem"
import { IStage, IStageLeft } from "../../../types"
import { colors } from "../../../climateui/utils/colors"
import { useSeasonalCalendar } from "../../../providers/SeasonalCalendarProvider"
import { isValidDate } from "../../../views/Seasonal/PlanningTool/utils"
import { useStrategy } from "./Strategy"

const STAGE_POPUP_LEFT_MIN_PX = 70

function StageOptions({
    stage,
    daysFromTimelineStart,
    setPxFromTimelineStart,
    setDuration,
    duration,
    updateStage,
    deleteStage,
    doShowOptions = false,
}: {
    stage: IStage
    daysFromTimelineStart: number
    setPxFromTimelineStart: (px: number) => void
    setDuration: (duration: number) => void
    duration: number
    updateStage: (stage: IStage) => void
    deleteStage: () => void
    doShowOptions?: boolean
}) {
    const {
        timelineStartDate,
        pxPerDay,
        setDragAuxDate,
        dragAuxDate,
        setEditingStage,
    } = useSeasonalCalendar()
    const stageDuration = stage.duration ?? 0
    const [optionsOpen, setOptionsOpen] = useState<boolean>(false)
    const optionsRef = useOutsideComponentClickHandler(() =>
        setOptionsOpen(false)
    )

    const { t } = useTranslate()

    const popupPositionClass =
        daysBetween(timelineStartDate, stage.end_date || new Date()) *
            pxPerDay >
            STAGE_POPUP_LEFT_MIN_PX
            ? "right-0"
            : "left-0"

    if (!doShowOptions)
        return (
            <div className="relative w-6 h-6 overflow-visible cursor-default shrink-0"></div>
        )

    return (
        <>
            <div className="relative w-6 h-6 overflow-visible cursor-default shrink-0">
                <span
                    className="w-6 h-6 cursor-pointer hover:opacity-75 transition-all duration-75"
                    onClick={() => {
                        setOptionsOpen(true)
                    }}>
                    <ThreeDotsIcon />
                </span>

                <div ref={optionsRef}>
                    <Transition
                        show={optionsOpen}
                        as={Fragment}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-75"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <ul
                                className={[
                                    "absolute z-40 overflow-hidden",
                                    popupPositionClass,
                                    "flex flex-col items-stretch",
                                    "py-2 w-fit elevation-2",
                                    "text-left body-md text-gray-90",
                                    "bg-white border rounded-lg border-gray-14",
                                ].join(" ")}>
                                <li
                                    className="px-2 py-1 cursor-pointer whitespace-nowrap hover:bg-gray-3"
                                    onClick={() => {
                                        setEditingStage(stage)
                                        setOptionsOpen(false)
                                    }}>
                                    {t("editStage", "Edit Stage")}
                                </li>
                                <li
                                    className="px-2 py-1 cursor-pointer whitespace-nowrap hover:bg-gray-3"
                                    onClick={() => {
                                        deleteStage()
                                        setOptionsOpen(false)
                                    }}>
                                    {t("deleteStage", "Delete Stage")}
                                </li>
                            </ul>
                        </Transition.Child>
                    </Transition>
                </div>
            </div>
            <DraggableElement
                draggable
                draggingCallback={(pxMoved: number) => {
                    const daysMoved = Math.round(pxMoved / pxPerDay)
                    if (stageDuration - daysMoved < MIN_STAGE_DURATION) return

                    const stageStartDate = new Date(stage.start_date ?? "")
                    if (!isValidDate(stageStartDate)) return

                    const movedDate = new Date(stageStartDate)
                    movedDate.setDate(movedDate.getDate() + daysMoved)
                    if (daysBetween(timelineStartDate, movedDate) < 0) return

                    moveStartDate(
                        daysFromTimelineStart,
                        daysMoved,
                        0,
                        pxPerDay,
                        setPxFromTimelineStart
                    )
                    moveDragAuxDate(
                        stageStartDate.toISOString(),
                        timelineStartDate,
                        daysMoved,
                        0,
                        setDragAuxDate
                    )
                    setDuration(stageDuration - daysMoved)
                }}
                dragEndCallback={() => {
                    updateStage({
                        ...stage,
                        start_date: dragAuxDate ?? timelineStartDate,
                        duration,
                    })
                    setDragAuxDate(undefined)
                }}>
                <span
                    className="absolute w-2 h-2 rounded-full cursor-col-resize -left-1"
                    style={{
                        backgroundColor: stage.color,
                    }}></span>
            </DraggableElement>
            <DraggableElement
                draggable
                draggingCallback={(pxMoved: number) => {
                    const daysMoved = Math.round(pxMoved / pxPerDay)
                    if (stageDuration + daysMoved < MIN_STAGE_DURATION) return

                    const stageDate = new Date(stage.start_date ?? "")
                    stageDate.setDate(
                        stageDate.getDate() + stageDuration + daysMoved
                    )
                    setDragAuxDate(stageDate)
                    setDuration(stageDuration + daysMoved)
                }}
                dragEndCallback={() => {
                    updateStage({
                        ...stage,
                        duration,
                    })
                    setDragAuxDate(undefined)
                }}>
                <span
                    className="absolute w-2 h-2 rounded-full cursor-col-resize -right-1"
                    style={{
                        backgroundColor: stage.color,
                    }}></span>
            </DraggableElement>
        </>
    )
}

function StageColorBoxes({
    isFocused,
    color,
}: {
    isFocused: boolean
    color: string
}) {
    const boxesClasses =
        "absolute inset-0 border rounded-sm pointer-events-none"

    return (
        <>
            <div
                className={[
                    boxesClasses,
                    isFocused ? "hidden" : "group-hover:hidden",
                ].join(" ")}
                style={{
                    borderColor: color,
                    opacity: 0.4,
                }}></div>
            <div
                className={[
                    boxesClasses,
                    isFocused ? "block" : " hidden group-hover:block",
                ].join(" ")}
                style={{
                    borderColor: color,
                    opacity: 1,
                }}></div>
            <div
                className={[boxesClasses, "border-transparent"].join(" ")}
                style={{
                    backgroundColor: color,
                    opacity: 0.12,
                }}></div>
        </>
    )
}

function Stage({
    stage,
    updateStage,
    deleteStage,
    globalCalendarLeft,
    extraComponent = undefined,
    setStageLeft,
}: {
    stage: IStage
    updateStage: (newStage: IStage) => void
    deleteStage: () => void
    globalCalendarLeft: number
    extraComponent?: ReactNode
    setStageLeft?: React.Dispatch<React.SetStateAction<IStageLeft | undefined>>
}) {
    const {
        focusedStage,
        setFocusedStage,
        setFocusedPlannedRisk,
        isEditingCalendar,
        timelineStartDate,
        setEditingStage,
    } = useSeasonalCalendar()
    const { stagesLevels, stageHeight } = useStrategy()

    const isFocused = stage.id === focusedStage
    const allFocused = focusedStage === stage.strategyId

    let doShowTooltip = false
    if (stage.start_date && stage.start_date < timelineStartDate)
        doShowTooltip = true

    const focusedClass = isFocused || allFocused ? "z-40" : "cursor-pointer"

    return (
        <DraggableItem
            draggingCallback={(left: number) => {
                setStageLeft?.({ stage_id: stage.id ?? "", left })
            }}
            dragEndCallback={() => {
                setStageLeft?.(undefined)
            }}
            item={stage}
            updateItem={updateStage}
            isFocused={isFocused}
            globalCalendarLeft={globalCalendarLeft}
            renderElement={({
                cursorClass,
                mainStyle,
                daysFromTimelineStart,
                duration,
                setDuration,
                setPxFromTimelineStart,
            }) => {
                return (
                    <div
                        className="absolute flex items-center justify-center inset-y-0"
                        style={{
                            ...mainStyle,
                            height: stageHeight + "px",
                            top:
                                stageHeight * stagesLevels[stage.id ?? ""] +
                                "px",
                        }}>
                        <Tooltip
                            customStyle="absolute flex items-center justify-center group transition-all duration-200 inset-0"
                            content={stage.name ?? ""}
                            doShow={doShowTooltip}
                            position="right"
                            wrapperClass="">
                            <div
                                className={[
                                    "absolute inset-0",
                                    "flex flex-row items-center justify-between",
                                    "rounded-sm pl-2 group body-md",
                                    "transition-all duration-75",
                                    "z-30",
                                    !isEditingCalendar &&
                                        focusedStage === stage.id
                                        ? "z-40"
                                        : "",
                                    isEditingCalendar
                                        ? cursorClass + " " + focusedClass
                                        : "z-30",
                                ].join(" ")}
                                onMouseEnter={() => {
                                    setFocusedStage((stage.id as string) ?? "")
                                    setFocusedPlannedRisk(undefined)
                                }}
                                onDoubleClick={() => {
                                    if (isEditingCalendar) {
                                        setEditingStage({
                                            ...stage,
                                            deleteStage,
                                        })
                                    }
                                }}
                                style={{
                                    color: stage.color,
                                    fill: stage.color,
                                }}>
                                <div
                                    className={[
                                        "truncate grow",
                                        "whitespace-nowrap",
                                        doShowTooltip
                                            ? "text-right"
                                            : "text-left",
                                    ].join(" ")}>
                                    {stage.name}
                                </div>
                                {/* INFO: For background and border colors (Pure UI) */}
                                <StageColorBoxes
                                    isFocused={
                                        (isFocused || allFocused) &&
                                        isEditingCalendar
                                    }
                                    color={stage.color ?? ""}
                                />
                                <StageOptions
                                    doShowOptions={
                                        isFocused && isEditingCalendar
                                    }
                                    stage={stage}
                                    daysFromTimelineStart={
                                        daysFromTimelineStart
                                    }
                                    setPxFromTimelineStart={
                                        setPxFromTimelineStart
                                    }
                                    setDuration={setDuration}
                                    duration={duration}
                                    updateStage={updateStage}
                                    deleteStage={deleteStage}
                                />

                                {extraComponent}
                            </div>
                        </Tooltip>
                    </div>
                )
            }}
        />
    )
}

export function NewStage({ date, duration }: { date: Date; duration: number }) {
    const { pxPerDay, timelineStartDate } = useSeasonalCalendar()
    const { stageHeight } = useStrategy()
    const daysFromTimelineStart = daysBetween(timelineStartDate, date)

    const mainStyle = {
        width: pxPerDay * duration + "px",
        left: daysFromTimelineStart * pxPerDay + "px",
        height: stageHeight + "px",
    }

    return (
        <div
            className={[
                "absolute left-0 inset-y-0",
                "flex flex-row items-center justify-center",
                "rounded-sm group body-md",
                "pointer-events-none",
                "fill-gray-30 text-gray-90",
            ].join(" ")}
            style={mainStyle}>
            <span className="w-6 h-6">
                <PlusIcon />
            </span>
            <StageColorBoxes
                isFocused={false}
                color={colors.gray[60]}
            />
        </div>
    )
}

export default Stage
