import { useMemo, useEffect, useState, RefObject } from "react"

/**
 * Hook that tells you if ref is visible by threshold
 * 0.5 = half of the element is visible
 * 1 = entire element is visible
 */
function useIsRefVisible(ref: RefObject<HTMLElement>, threshold = 0.5) {
    const [isIntersecting, setIsIntersecting] = useState(false)

    const observer = useMemo(
        () =>
            new IntersectionObserver(
                ([entry]) => setIsIntersecting(entry.isIntersecting),
                { threshold }
            ),
        [ref, threshold]
    )

    useEffect(() => {
        if (!ref.current) return

        observer.observe(ref.current)
        return () => observer.disconnect()
    }, [])

    return isIntersecting
}

export default useIsRefVisible
