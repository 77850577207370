import { ReactNode } from "react"
import { createPortal } from "react-dom"

function PortalComponent({
    children,
    portalId,
}: {
    children: ReactNode
    portalId: string
}) {
    const element = document.getElementById(portalId)

    return element ? createPortal(children, element) : null
}

export default PortalComponent
