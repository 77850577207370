import { useMemo } from "react"
import { QueryFunctionContext, useQuery, UseQueryResult } from "react-query"
import {
    CAIResponse,
    CustomResponse,
    isValidResponse,
} from "../climateui/utils/http"

// TODO: remove any of line 25
// type CustomUseQueryResult = UseQueryResult<CAIResponse, unknown> & {
//     data: CAIResponse
// }

export const useMemoQuery = <Output,>(
    queryKey: unknown[],
    queryFn: (context: QueryFunctionContext) => Promise<CAIResponse>,
    queryOpts?: Record<string, unknown>,
    transformData?: (data: unknown) => Output,
    fallbackValue = {} as Output
): [Output, UseQueryResult, boolean] => {
    const queryResponse = useQuery({
        ...queryOpts,
        queryKey,
        queryFn,
    })

    const [data, result] = useMemo<[Output, UseQueryResult]>(() => {
        if (!isValidResponse(queryResponse.data))
            return [fallbackValue, queryResponse]
        const result = queryResponse as UseQueryResult<CustomResponse, unknown>
        if (transformData) {
            result.data = {
                ...queryResponse.data,
                data: transformData(queryResponse.data.data),
            }
        }
        return [result.data ? result.data.data : fallbackValue, result]
    }, [queryResponse.data, queryResponse.isLoading])

    return [
        data,
        result,
        queryResponse.isLoading ||
            queryResponse.isFetching ||
            queryResponse.isIdle,
    ]
}
