import { useQuery } from "react-query"
import { isValidResponse } from "../climateui/utils/http"
import { arrToDict } from "../utils/transform"
import { IAccountAsset } from "../types"
import { accountAssetQuerySet } from "../utils/networking"

const useAccountAssetsQuery = (selectedAccount?: string) => {
    return useQuery({
        queryKey: ["accountAssets", selectedAccount],
        queryFn: () =>
            accountAssetQuerySet.get(
                "",
                {},
                {},
                {
                    headers: {
                        "X-Fields": "{id,account_id,asset_id,is_setup}",
                    },
                }
            ),
        enabled: !!selectedAccount,
        select: (response) => {
            if (!isValidResponse(response)) return {}

            return arrToDict<IAccountAsset>(response.data, "asset_id")
        },
    })
}

export default useAccountAssetsQuery
