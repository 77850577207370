const WarnTriangleIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M12 4.99L19.53 18H4.47L12 4.99ZM13.7309 3.98965C12.9605 2.65908 11.0395 2.65908 10.2691 3.98965L2.73804 16.9979C1.96611 18.3313 2.92823 20 4.46889 20H19.5311C21.0718 20 22.0339 18.3313 21.262 16.9979L13.7309 3.98965ZM13 15H11V17H13V15ZM13 9H11V13H13V9Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default WarnTriangleIcon
