import {
    IColDef,
    isCSVError,
    isColDef,
    ColumnsConfig,
    isColConfig,
    IMessages,
    RowError,
    isRowError,
} from "./types"
import { removeZeroWidthChars, transposeObjToArr } from "./utils"

export class CSVParser<T> {
    properties: ColumnsConfig<T>
    propertiesEntries: [string, unknown][]
    columns: string[]
    prefetchValues: Record<string, unknown>
    nestedParsers: Record<string, CSVParser<unknown>> // eslint-disable-line no-use-before-define
    messages?: IMessages

    static reservedKeys = ["nestedDelimiter"]

    static getColumns = (properties: ColumnsConfig<unknown>): string[] =>
        Object.values(properties).reduce((prev: string[], definition) => {
            if (isColDef(definition)) {
                prev.push(removeZeroWidthChars(definition.headerName.trim()))
            } else if (isColConfig(definition)) {
                prev = prev.concat(CSVParser.getColumns(definition))
            }
            return prev
        }, [])

    constructor(_properties: ColumnsConfig<T>, messages?: IMessages) {
        this.properties = _properties
        this.propertiesEntries = Object.entries(_properties).filter(
            ([propName]) => !CSVParser.reservedKeys.includes(propName)
        )
        this.columns = CSVParser.getColumns(this.properties)
        this.prefetchValues = {}
        this.nestedParsers = {}
        this.messages = messages
    }

    parseCellAsColDef = async (
        item: Record<string, unknown>,
        row: Record<string, string>,
        idx: number,
        propName: string,
        { headerName: _headerName, toValue, optional }: IColDef<unknown>,
        rowErrors: RowError[]
    ) => {
        const headerName = removeZeroWidthChars(_headerName.trim())
        // Make sure that the cell is not empty
        if (!optional && !row[headerName]) {
            rowErrors[idx] = {
                ...rowErrors[idx],
                [headerName]: {
                    code: 404,
                    message: this.messages?.emptyCell ?? "<empty>",
                },
            }
            return
        }
        let value: unknown = row[headerName]
        if (toValue) {
            const _value = await toValue(
                row[headerName],
                this.prefetchValues[propName]
            )
            if (isCSVError(_value)) {
                rowErrors[idx] = {
                    ...rowErrors[idx],
                    [headerName]: _value,
                }
                return
            }
            value = _value
        }
        item[propName] = value
    }
    parseCellAsColConfig = async (
        item: Record<string, unknown>,
        row: Record<string, string>,
        idx: number,
        propName: string,
        { nestedDelimiter }: ColumnsConfig<unknown[]>,
        rowErrors: RowError[]
    ) => {
        // Retrieve existing parser, if possible
        const nestedParser = this.nestedParsers[propName]

        const { results, errors } = await nestedParser.parse(
            [row],
            nestedDelimiter
        )

        // Merge together cell errors
        if (errors.length > 0) {
            const transposedErr = transposeObjToArr(errors) as RowError

            rowErrors[idx] = {
                ...rowErrors[idx],
                ...transposedErr,
            }
        } else {
            item[propName] = nestedDelimiter ? results : results[0]
        }
    }
    parseCellAsDefault = (
        item: Record<string, unknown>,
        propName: string,
        definition: unknown
    ) => {
        item[propName] = definition
    }

    parseRow = async (
        row: Record<string, string>,
        idx: number,
        rowErrors: RowError[]
    ): Promise<T | undefined> => {
        const item: Record<string, unknown> = {}
        await Promise.all(
            this.propertiesEntries.map(async ([propName, definition]) => {
                if (isColDef(definition)) {
                    await this.parseCellAsColDef(
                        item,
                        row,
                        idx,
                        propName,
                        definition,
                        rowErrors
                    )
                } else if (isColConfig(definition)) {
                    await this.parseCellAsColConfig(
                        item,
                        row,
                        idx,
                        propName,
                        definition,
                        rowErrors
                    )
                } else if (definition) {
                    this.parseCellAsDefault(item, propName, definition)
                }
            })
        )
        if (rowErrors[idx] && Object.keys(rowErrors[idx]).length > 0) return

        return item as T
    }

    prefetchData = async () =>
        Promise.all(
            this.propertiesEntries.map(async ([propName, definition]) => {
                // If it is a column defintion
                if (isColDef(definition)) {
                    // Exit if a prefetch method has not being defined
                    if (!definition.prefetch) return
                    const result = await definition.prefetch()
                    // Exit if the result of the prefetch is undefined
                    if (!result) return
                    this.prefetchValues = {
                        ...this.prefetchValues,
                        [propName]: result,
                    }
                } else if (isColConfig(definition)) {
                    const nestedParser = new CSVParser(
                        definition,
                        this.messages
                    )
                    nestedParser.prefetchData()
                    this.nestedParsers[propName] = nestedParser
                }
            })
        )

    splitIntoSubRows = (
        row: Record<string, string>,
        nestedDelim: string | RegExp
    ): Record<string, string>[] | RowError => {
        // Keep track of the lengths
        const subRowCounts = new Set<number>()
        // Keep track of errors
        const errors: RowError = {}

        // For each column try to split the values based on the delimiter
        const splitCols = Object.entries(row).reduce(
            (prev: Record<string, string[]>, [key, val]) => {
                // Update the subRowCounts set
                const splitValues = val.split(nestedDelim)
                // If the set doesn't have this value and it already has two, report an error
                if (
                    (splitValues.length > 1 && !subRowCounts.has(1)) ||
                    (subRowCounts.size == 2 &&
                        !subRowCounts.has(splitValues.length))
                ) {
                    errors[key] = {
                        code: 400,
                        message:
                            this.messages?.nestedValuesMismatch ??
                            "Nested values mismatch",
                    }
                } else {
                    subRowCounts.add(splitValues.length)
                }

                // Update the column with the split values
                prev[key] = splitValues
                return prev
            },
            {}
        )

        if (Object.keys(errors).length) return errors

        // Determine the length of the subrows
        const subRowLength = Math.max(...subRowCounts)

        return Object.entries(splitCols).reduce(
            (prev: Record<string, string>[], [column, values]) => {
                for (let idx = 0; idx < subRowLength; idx++) {
                    // Determine what should be the value based on the field length
                    const value = values.length > 1 ? values[idx] : values[0]

                    // Create the object if it doesn't exist
                    if (!prev[idx]) prev[idx] = {}
                    prev[idx][column] = value
                }
                return prev
            },
            []
        )
    }

    splitNestedData = (
        rawData: Record<string, string>[],
        rowErrors: RowError[],
        nestedDelim?: string | RegExp
    ) => {
        // If there's no delimiter, return the data without any manipulation
        if (!nestedDelim) return rawData
        // For each row, split into sub rows and return
        return rawData
            .map((row) => this.splitIntoSubRows(row, nestedDelim))
            .flat()
            .filter((rowOrError, idx) => {
                if (!isRowError(rowOrError)) return true
                // Add the error to the rowErrors
                rowErrors[idx] = rowOrError
                return false
            }) as Record<string, string>[]
    }

    parse = async (
        rawData: Record<string, string>[],
        nestedDelimiter?: string | RegExp
    ): Promise<{ results: (T | undefined)[]; errors: RowError[] }> => {
        // Create an array to store the errors
        const rowErrors: RowError[] = []

        // Get all the prefetched values
        await this.prefetchData()

        // Process data if needed
        const data = this.splitNestedData(rawData, rowErrors, nestedDelimiter)

        // Get all the parsed rows
        const res = await Promise.all(
            data.map((row, idx) => this.parseRow(row, idx, rowErrors))
        )

        return { results: res, errors: rowErrors }
    }
}
