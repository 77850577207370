import { useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useOutletContext, useParams } from "react-router-dom"
import { Button } from "../../../climateui/components"
import { OrSeparator } from "../../../components"
import { ResponsivePaddingWrapper } from "../../../layouts/TabLayout"
import { LocationsContext } from "../../../providers/LocationsProvider"
import {
    EMPTY_LOCATION,
    isEmptyString,
    LOCATION_BULK_CSV_HEADER_COLUMN_DATA,
} from "./LocationsUtil"
import Papa from "papaparse"
import queryClient, {
    locationPOST,
    locationDELETE,
    locationPUT,
} from "../../../utils/networking"
import LocationsFormHeader from "./components/LocationsFormHeader"
import { LabelsContext } from "../../../providers/LabelsProvider"
import {
    IInsightsLocation,
    ILocationEssentials,
} from "../../../types"
import { ModalContext, ToastContext } from "../../../climateui/providers"
import BulkUploadCSVParser from "../../../components/Bulk Upload/BulkUploadCSVParser"
import {
    IBulkUploadStatus,
    ICSVDataCell,
} from "../../../components/Bulk Upload/bulkUploadUtils"
import { useTranslate } from "@tolgee/react"
import { useAccount } from "../../../providers/AccountProvider"
import { useMutation } from "react-query"
import LocationForm from "./components/LocationForm"
import { CSV_MULTIPLE_THINGS_SEPARATOR } from "../../../utils/csv"
import { default as OldBUL } from "./components/BulkUploadLocations"
import { isValidResponse } from "../../../climateui/utils/http"
import { AxiosError } from "axios"

function AddEditLocationsView() {
    const { selectedAccount, maxLocationsAllowed } = useAccount()
    const {
        map: [mapPinCoords, setMapPinCoords],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = useOutletContext<any>()
    const [canSubmitLocationForm, setCanSubmitLocationForm] = useState<{
        state: boolean
        editedLocation: ILocationEssentials | null
    }>({
        state: false,
        editedLocation: null,
    })
    const { confirmationModal } = useContext(ModalContext)
    const { enqueueAlert } = useContext(ToastContext)
    const {
        locations: locationsList,
        locationsObj,
        canAddLocations,
    } = useContext(LocationsContext)
    const { generateBulkUploadLabelStructure } = useContext(LabelsContext)
    const navigate = useNavigate()
    const { mutate: addLocation } = useMutation(locationPOST)
    const { mutate: editLocation } = useMutation(locationPUT)
    const { mutate: deleteLocation } = useMutation(locationDELETE)
    const [parsedBulkUploadData, setParsedBulkUploadData] =
        useState<ICSVDataCell[][]>()
    const [isUploadingSingleLoc, setIsUploadingSingleLoc] = useState(false)
    const { locationId } = useParams()
    const isEditing = locationId !== undefined
    const { t } = useTranslate()

    useEffect(() => {
        if (locationId === undefined && !canAddLocations) navigate("../")
    }, [locationId, canAddLocations])

    function setNewBulkUploadFile(
        results: { data: ICSVDataCell[][] } | null,
        successAction: () => void
    ) {
        if (!results) return

        const data: ICSVDataCell[][] = []
        for (let i = 0; i < results.data.length; i++) {
            const rowData = results.data[i].join("")
            if (
                isEmptyString(rowData) || // ignore empty rows
                i === 0 // ignore header row
            )
                continue
            data.push(results.data[i])
        }

        setParsedBulkUploadData(data)
        successAction()
    }

    const handleBulkUploadFileChange = (
        newFile: File,
        successAction: () => void
    ) => {
        // Parses CSV and calls the complete arrow function when done, passing in the results
        const parseConfig = {
            complete: (results: { data: ICSVDataCell[][] } | null) =>
                setNewBulkUploadFile(results, successAction),
        }
        Papa.parse(newFile, parseConfig)
    }

    async function uploadNextLocation(
        status: IBulkUploadStatus,
        locations: IInsightsLocation[],
        index: number,
        uploadStatusCallback: (status: IBulkUploadStatus) => void,
        doneUploadingCallback: (status: IBulkUploadStatus) => void,
        locsRemaining: number
    ) {
        // Check for end of upload sequence
        if (index === status.rows.length) {
            // We're done, hit the end of sequence callback and return early
            doneUploadingCallback(status)
            return
        }

        const nextLocation = locations[index]

        // Update current index
        status.current = index
        // Set loc status to waiting, so that the loading circle starts spinning on the table
        status.rows[index] = "waiting"
        // Update status
        uploadStatusCallback(status)

        if (locsRemaining === 0) {
            // eslint-disable-next-line require-atomic-updates
            status.rows[index] = "limitReached"
            uploadNextLocation(
                status,
                locations,
                index + 1,
                uploadStatusCallback,
                doneUploadingCallback,
                locsRemaining
            )
            return
        }
        // Hit the endpoint to add the loc
        const res = await locationPOST(nextLocation)

        if (res?.status !== 201) {
            // There was a backend problem; Code from the bottom of the function will not be hit
            // Continue process from the catch statement
            // Set the status text
            // Note: This eslint disabling is to ignore the race condition warning error; Since we aways 'await' for one
            //       request to finish before firing another one, it will 100% for sure never happen : )
            if ((res as AxiosError).response?.status === 409) {
                // eslint-disable-next-line require-atomic-updates
                status.rows[index] = 409
            } else {
                // eslint-disable-next-line require-atomic-updates
                status.rows[index] = "error"
            }

            // Update status
            uploadStatusCallback(status)

            // Upload next location
            uploadNextLocation(
                status,
                locations,
                index + 1,
                uploadStatusCallback,
                doneUploadingCallback,
                locsRemaining
            )
            return
        }

        // Get out if we didnt get a response
        if (res === undefined) return

        // Set the status text
        // Note: This eslint disabling is to ignore the race condition warning error; Since we aways 'await' for one
        //       request to finish before firing another one, it will 100% for sure never happen : )
        if ((res?.status as number) < 200 && (res?.status as number) > 299)
            // eslint-disable-next-line require-atomic-updates
            status.rows[index] = "error"
        // eslint-disable-next-line require-atomic-updates
        else status.rows[index] = res?.status as number

        // Update status
        uploadStatusCallback(status)

        // Upload next location
        uploadNextLocation(
            status,
            locations,
            index + 1,
            uploadStatusCallback,
            doneUploadingCallback,
            locsRemaining - 1
        )
    }

    const handleImport = async (
        data: ICSVDataCell[][],
        uploadStatusCallback: (status: IBulkUploadStatus) => void,
        doneUploadingCallback: (status: IBulkUploadStatus) => void
    ) => {
        // Generate loc array with correct structure so that it fits the endpoint's standards
        const locationsToAdd = [] as IInsightsLocation[]
        const status = { rows: [], current: 0 } as IBulkUploadStatus
        // Get new location label IDs
        // this involved creating all labels that don't exist yet for this account (but are mentioned in the CSV)
        const newLocLabels = await generateBulkUploadLabelStructure(
            data.map((rowData) => {
                return (rowData[3].value as string)
                    .replaceAll(" ", CSV_MULTIPLE_THINGS_SEPARATOR)
                    .split(CSV_MULTIPLE_THINGS_SEPARATOR)
            })
        )

        data.forEach((rowData, i) => {
            const newLocation = {} as IInsightsLocation
            newLocation.name = rowData[0].value as string
            newLocation.external_id = `A${selectedAccount}L${newLocation.name}`
            newLocation.account_id = selectedAccount as string
            newLocation.latitude = Number(rowData[1].value)
            newLocation.longitude = Number(rowData[2].value)
            newLocation.timezone = "TODO: GET TIMEZONE FROM REGION"
            newLocation.labels = (newLocLabels as string[][])
                ? (newLocLabels as string[][])[i]
                : []
            locationsToAdd.push(newLocation)
            status.rows.push("on queue") // So that these rows don't show as loading yet
        })

        // Update status
        uploadStatusCallback(status)

        const locsRemaining = maxLocationsAllowed - locationsList.length
        // Start the upload sequence
        uploadNextLocation(
            status,
            locationsToAdd,
            0,
            uploadStatusCallback,
            doneUploadingCallback,
            locsRemaining
        )
    }

    const handleAddSingleLocation = async (
        location: IInsightsLocation,
        uploadStatusCallback: (status: string) => void,
        doneUploadingCallback: () => void,
        errorCallback: () => void
    ) => {
        // Prepare loc object so that it fits the endpoint's standards
        location.account_id = selectedAccount as string
        location.external_id = `A${selectedAccount}L${location.name}`
        delete location.region

        // Update status
        uploadStatusCallback("waiting")
        setIsUploadingSingleLoc(true)

        // Hit endpoint to add location
        addLocation(location, {
            onSuccess: async (data) => {
                if (!isValidResponse(data)) {
                    if (data?.response?.status == 409) {
                        enqueueAlert(
                            t(
                                "duplicateLocation",
                                "Couldn't create the location because there is an existing one with these coordinates."
                            )
                        )
                    }
                    errorCallback()
                    setIsUploadingSingleLoc(false)
                    return
                }
                // On success, hit the done callback
                doneUploadingCallback()
                setIsUploadingSingleLoc(false)
                navigate("/admin/locations", {
                    state: {
                        navigationData: {
                            forceReload: true,
                        },
                    },
                })
                enqueueAlert(t("locationAddedSuccessfully"))
            },
            onError: async () => {
                // On error, hit the error callback
                errorCallback()
                setIsUploadingSingleLoc(false)
            },
        })
    }

    const handleCancel = () => {
        setParsedBulkUploadData(undefined)
    }

    const handleSubmitForm = () => {
        if (!isEditing) {
            handleAddSingleLocation(
                canSubmitLocationForm.editedLocation as IInsightsLocation,
                () => null,
                () => navigate("../"),
                () => null
            )
        } else {
            const payload =
                canSubmitLocationForm.editedLocation as IInsightsLocation

            const location_id = payload.id
            setIsUploadingSingleLoc(true)
            editLocation(
                { location_id: location_id, payload: payload },
                {
                    onSuccess: async () => {
                        setIsUploadingSingleLoc(false)
                        queryClient.invalidateQueries("locations")
                        queryClient.invalidateQueries("riskProfiles")
                        navigate("../")
                    },
                    onError: async (data) => {
                        setIsUploadingSingleLoc(false)
                        console.error(data)
                        // On error, hit the error callback
                        // errorCallback()
                    },
                }
            )
        }
    }

    const location = useMemo(() => {
        if (!locationId || !locationsObj || !locationsObj[locationId])
            return {
                ...EMPTY_LOCATION,
            }
        return {
            ...locationsObj[locationId],
        }
    }, [locationsObj, locationId])

    return (
        <ResponsivePaddingWrapper extraClasses="overflow-y-auto flex-row justify-center w-full sm:flex">
            <div className="sm:max-w-[400px] min-w-0">
                {!isEditing && (
                    <>
                        <LocationsFormHeader
                            doShowGoBack
                            title={t("uploadLocation")}
                        />
                        <OldBUL
                            onBulkUploadFileChange={handleBulkUploadFileChange}
                        />
                        <OrSeparator label={t("or")} />
                    </>
                )}
                <LocationsFormHeader
                    doShowDelete={isEditing}
                    onDeleteLocation={() => {
                        const locToDelete = locationsList.filter(
                            (loc) => loc.id === locationId
                        )[0]
                        confirmationModal({
                            title: t("areYouSureDeleteLOCATION", {
                                location: locToDelete.name,
                            }),
                            text: t("thisActionCantBeUndone"),
                            onContinueLabel: t("delete"),
                            onCancelLabel: t("cancel"),
                            onContinue: () => {
                                deleteLocation(locToDelete.id, {
                                    onSuccess: async () => {
                                        queryClient.invalidateQueries(
                                            "locations"
                                        )
                                        enqueueAlert(
                                            `${locToDelete.name}` +
                                                t("locationDeletedSuccess")
                                        )
                                        navigate("../")
                                    },
                                    onError: async (data) => {
                                        console.error(data)
                                        // On error, hit the error callback
                                        // errorCallback()
                                    },
                                })
                            },
                        })
                    }}
                    title={isEditing ? t("editLocation") : t("addNewLocation")}
                    subtitle={
                        <div className="hidden lg:block">
                            {t("mapPinClickInstructions")}
                        </div>
                    }
                />
                <LocationForm
                    mapPinCoords={mapPinCoords}
                    setMapPinCoords={setMapPinCoords}
                    location={location}
                    changeCallback={(isValid, location) =>
                        setCanSubmitLocationForm({
                            state: isValid,
                            editedLocation: location,
                        })
                    }
                />
                <Button
                    label={isEditing ? t("save") : t("addLocation")}
                    disabled={
                        !canSubmitLocationForm.state || isUploadingSingleLoc
                    }
                    onClick={handleSubmitForm}
                    extend
                />
            </div>
            <BulkUploadCSVParser
                uploadDataType="locations"
                modalDimensions={{ width: 540, height: 534 }}
                tableHeaderColumnData={LOCATION_BULK_CSV_HEADER_COLUMN_DATA}
                data={parsedBulkUploadData}
                handleImport={handleImport}
                handleCancel={handleCancel}
            />
        </ResponsivePaddingWrapper>
    )
}

export default AddEditLocationsView
// <BulkUploadLocations
//     handleLocationAddition={async (
//         location: IInsightsLocation
//     ) => {
//         const response = await locationPOST(location)
//         if (!isValidResponse(response)) return false
//         console.log(location)
//         return true
//     }}
//     doneCallback={(wasSuccessful) => {
//         if (wasSuccessful)
//             enqueueAlert(
//                 t(
//                     "locationsAddedSuccessfully",
//                     "Locations Added Successfully"
//                 )
//             )
//         // else Do nothing
//         queryClient.invalidateQueries(["locations"])
//     }}
// />
