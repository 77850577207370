const BlocksIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 2H16C17.6569 2 19 3.34315 19 5C19 6.65685 17.6569 8 16 8H6C4.34314 8 3 6.65685 3 5C3 3.34315 4.34315 2 6 2ZM6 4C5.44772 4 5 4.44772 5 5C5 5.55228 5.44772 6 6 6H16C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H6Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 9H18C19.6569 9 21 10.3431 21 12C21 13.6569 19.6569 15 18 15H10C8.34315 15 7 13.6569 7 12C7 10.3431 8.34315 9 10 9ZM10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13H18C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11H10Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 16H12C13.6569 16 15 17.3431 15 19C15 20.6569 13.6569 22 12 22H6C4.34315 22 3 20.6569 3 19C3 17.3431 4.34315 16 6 16ZM6 18C5.44772 18 5 18.4477 5 19C5 19.5523 5.44772 20 6 20H12C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18H6Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default BlocksIcon
