import * as React from "react"
import { SVGProps } from "react"

const MeanWindSpeedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-inherit"
        viewBox="0 0 25 24"
        {...props}>
        <path
            d="M15 18c0 1.65-1.35 3-3 3s-3-1.35-3-3h2c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1H2.5v-2H12c1.65 0 3 1.35 3 3Zm4.5-12.5C19.5 3.57 17.93 2 16 2s-3.5 1.57-3.5 3.5h2c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5S16.83 7 16 7H2.5v2H16c1.93 0 3.5-1.57 3.5-3.5ZM19 11H2.5v2H19c.83 0 1.5.67 1.5 1.5S19.83 16 19 16v2c1.93 0 3.5-1.57 3.5-3.5S20.93 11 19 11Z"
            fill={props.fill}
        />
    </svg>
)

export default MeanWindSpeedIcon
