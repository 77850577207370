import { useTranslate } from "@tolgee/react"
import SearchWidget from "../../climateui/components/Widgets/SeasonalWidgets/SearchWidget"

function YieldOutlookStageWidget(props: any) {
    const { title, loading, data } = props

    const { t } = useTranslate()

    let currentSeason = ""
    let region = ""
    if (data) {
        currentSeason = data.stages?.[0]?.stage_name
        region = data.region?.name
    }

    return (
        <SearchWidget
            title={title}
            value={t(currentSeason)}
            description={region}
            loading={loading}
            errorMessage={t("errorLoadingData")}
            reloadMessage={t("clickReload")}
            noResultsMessage={t("noResultCurrentFilters")}
            tryAnotherFilterMessage={t("tryAnotherFilter")}
        />
    )
}

export default YieldOutlookStageWidget
