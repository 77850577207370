import { useAuth, useLocations } from "../../../../providers"
import queryClient, {
    dashboardPUT,
    getDashboardPDF,
} from "../../../../utils/networking"
import {
    areResTypeAndDataValid,
    CustomResponse,
} from "../../../../climateui/utils/http"
import Lottie from "lottie-react"
import climateAILogoIconAnimation from "../../../../components/ClimateAi_Symbol_Animation.json"
import {
    useRef,
    useState,
    useEffect,
    RefObject,
    useContext,
    FocusEvent,
    KeyboardEvent,
    useCallback,
} from "react"
import { IDashboard } from "../../../../types"
import { ToastContext } from "../../../../climateui/providers"
import { useTranslate } from "@tolgee/react"
import { useAccount } from "../../../../providers/AccountProvider"
import { GenericPageHeader } from "../../../../components"
import { Button } from "../../../../climateui/components"
import {
    CalendarIcon,
    DownloadIcon,
    EditIcon,
    EmailIcon,
    FileIcon,
} from "../../../../climateui/icons"
import { saveAs } from "file-saver"
import { useNavigate } from "react-router-dom"
import { useDashboard } from "../../../../providers/DashboardProvider"
import EmptyModal from "../../../../climateui/providers/Modal/EmptyModal"
import { OpenScheduleForm } from "../../Scheduler/components/NewScheduleForm"
import OpenScheduleDashboardForm from "../../Scheduler/components/ScheduleDashboardList"
import { useIsFlagEnabled } from "../../../../hooks"
import dashboardVariables from "../dashboardVariables"
import { useDashboardActions } from "../components/DashboardActionsProvider"
import { downloadBlob } from "../../../../climateui/utils"
import { DEFAULT_DASHBOARD_TEMPLATE_IDS } from "../utils"

export interface IDashNameandDescriptionProps {
    workingDashboard?: IDashboard
}

function DashNameandDescription(props: IDashNameandDescriptionProps) {
    const auth = useAuth()
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { enqueueAlert } = useContext(ToastContext)
    const { selectedAccount } = useAccount()

    const { workingDashboard } = props
    const { setWorkingDashboard, setOriginalDashboard } = useDashboard()
    const [openLoadingModal, setOpenLoadingModal] = useState(false)
    const [openScheduleModal, setOpenScheduleModal] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const [showDownloadOptions, setShowDownloadOptions] = useState(false)
    const [schedulerNow, setSchedulerNow] = useState(false)
    const [isNameTyping, setIsNameTyping] = useState(false)
    const [isDescTyping, setIsDescTyping] = useState(false)
    //schedulesTest used to test FE for dashboard's schedules list
    const { locationsObj } = useLocations()

    const openCloseScheduleModal = () => {
        setOpenScheduleModal(!openScheduleModal)
    }

    const getPDFReport = () => {
        const project = process.env.REACT_APP_PROJECT_NAME
        const refresh_token = localStorage.getItem(
            `climateai-${project}-refresh-token`
        ) as string
        const dashboard_url = new URL(window.location.href)
        const urlParams = new URLSearchParams(dashboard_url.search)

        if (selectedAccount) {
            urlParams.set("account-id", selectedAccount)
            urlParams.set("pagination", "false")
        }

        dashboard_url.search = urlParams.toString()
        if (!dashboard_url.href.includes("variables")) {
            Object.keys(dashboardVariables).forEach((variable) => {
                dashboard_url.search += "&variables=" + variable
            })
        }
        // When we are running locally, we want the url to point to dev
        if (dashboard_url.href.includes("localhost")) {
            const split_url = dashboard_url.href.split("seasonal")
            dashboard_url.href =
                "https://climate-platform-dev.web.app/seasonal" + split_url[1]
        }

        const request_body = {
            dashboard_url: dashboard_url.href,
            refresh_token: refresh_token,
        }

        // Too much nonsense, just get the value from the label
        const fileName =
            (document.getElementById("current-page-label")?.innerHTML ??
                "default_report") + ".pdf"

        getDashboardPDF(request_body)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((res: any) => {
                const pdf_blob = new Blob([res.data], {
                    type: "application/pdf",
                })
                saveAs(pdf_blob, fileName)
                setOpenLoadingModal(false)
            })
            .catch((error) => {
                console.log(error)
                setOpenLoadingModal(false)
            })
    }

    const editDashboardName = (key: string, target: string) => {
        let _editedDashboard = {} as IDashboard
        _editedDashboard = {
            id: workingDashboard?.id,
            [key]: target,
            updated_by_email: auth.user?.email,
        }

        delete _editedDashboard.report_schedules

        setWorkingDashboard({
            ...workingDashboard,
            ..._editedDashboard,
        })

        dashboardPUT(_editedDashboard).then((res) => {
            if (!areResTypeAndDataValid(res)) return // TODO: Show error
            const data = (res as CustomResponse).data || []
            if (data.id) {
                queryClient.invalidateQueries([
                    "dashboard",
                    workingDashboard?.id,
                ])
                queryClient.invalidateQueries(["dashboards", selectedAccount])
                enqueueAlert(
                    t(
                        "DASHBOARDEditedSuccessfully",
                        "Dashboard edited successfully!",
                        {
                            dashboard:
                                workingDashboard?.title ??
                                t("theDashboard", "The dashboard"),
                        }
                    )
                )
            }
        })
    }

    const nameRef = useRef<HTMLInputElement>(null)
    const descriptionRef = useRef<HTMLInputElement>(null)

    const handleKeyPress = (
        keyCode: string,
        inputRef: RefObject<HTMLInputElement>,
        prevValue: string
    ) => {
        if (keyCode === "Enter") {
            if (inputRef == nameRef) {
                setIsNameTyping(false)
            } else {
                setIsDescTyping(false)
            }
            inputRef.current?.blur()
        } else if (keyCode === "Escape") {
            if (inputRef.current) inputRef.current.value = prevValue
            inputRef.current?.blur()
        }
    }

    useEffect(() => {
        setShowDownloadOptions(false)
        if (nameRef.current)
            nameRef.current.value = workingDashboard?.title ?? ""
        if (descriptionRef.current)
            descriptionRef.current.value = workingDashboard?.description ?? ""
    }, [workingDashboard])

    const reloadSchedulesList = async () => {
        queryClient.invalidateQueries(["dashboard", workingDashboard?.id])
        queryClient.invalidateQueries(["schedules", selectedAccount])
    }

    // TODO remove this flag
    const isSendPDFEnabled = useIsFlagEnabled("feature_dashboard_send_pdf_now")

    const { csvs, globalCSVHeader, csvPrefix, performAction } =
        useDashboardActions()
    const downloadHistoricalData = () => {
        setShowDownloadOptions(false)
        performAction("exportCurrLocationHistoricalData").then(
            ([locationName, csvStr]: [
                locationName: string | undefined,
                csvStr: string
            ]) => {
                // Append the necessary charset info
                const blob = "data:text/csv;charset=utf-8," + csvStr
                // Build the file name
                let fileName = "historical_data.csv"
                if (locationName) fileName = locationName + "_" + fileName
                // Download the CSV
                downloadBlob(blob, fileName)
            }
        )
    }
    const downloadAllCSVs = useCallback(() => {
        // Merge charts
        // i.e. { resolution: { dataset: csvString } }
        const allCharts: Record<string, Record<string, string[]>> = {}
        Object.values(csvs.current).forEach((chartCSV) => {
            // Resolutions
            Object.entries(chartCSV).forEach(([resolution, datasets]) => {
                if (!allCharts[resolution]) allCharts[resolution] = {}
                // Datasets (e.g. forecast, climatology)
                Object.entries(datasets).forEach(([datasetName, csvStr]) => {
                    if (!allCharts[resolution][datasetName])
                        allCharts[resolution][datasetName] = []
                    allCharts[resolution][datasetName].push(csvStr)
                })
            })
        })

        // Export files per resolution, per dataset
        Object.entries(allCharts).forEach(([resolution, datasets]) => {
            Object.entries(datasets).forEach(([datasetName, csvArr]) => {
                let fileName = `${resolution}_${datasetName}.csv`
                if (csvPrefix.current)
                    fileName = `${csvPrefix.current}_${fileName}`
                const blob = `data:text/csv;charset=utf-8,${
                    globalCSVHeader.current
                }\n\n${csvArr.join("\n\n")}`
                downloadBlob(blob, fileName)
            })
        })
    }, [csvs.current])

    const isDefaultDashboard = DEFAULT_DASHBOARD_TEMPLATE_IDS.includes(
        workingDashboard?.id ?? ""
    )

    const COMMON_INPUT_CLASSES = `pl-1 overflow-hidden bg-transparent border border-transparent focus:outline-none focus:border-accent focus:cursor-text rounded-md placeholder:text-gray-30 ${
        !isDefaultDashboard && " hover:border-gray-14 cursor-pointer"
    }`
    return (
        <>
            <GenericPageHeader
                pageTitle={
                    <div className="flex max-w-full min-w-0 w-fit group mt-2">
                        <input
                            ref={nameRef}
                            placeholder={t("clickAddName")}
                            onInput={(e) => {
                                e.currentTarget.style.width =
                                    e.currentTarget.value.length + "ch"
                            }}
                            onClick={() => setIsNameTyping(true)}
                            onBlur={(e: FocusEvent<HTMLInputElement>) => {
                                setIsNameTyping(false)
                                if (e.target.value == workingDashboard?.title) {
                                    return
                                }
                                editDashboardName("title", e.target.value || "")
                            }}
                            onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
                                handleKeyPress(
                                    e.code,
                                    nameRef,
                                    workingDashboard?.title ?? ""
                                )
                            }}
                            className={`${COMMON_INPUT_CLASSES} pr-8 min-w-[150px]`}
                            style={{
                                width: workingDashboard?.title?.length
                                    ? workingDashboard?.title?.length + 2 + "ch"
                                    : "24ch",
                            }}
                            disabled={isDefaultDashboard}
                        />
                        {isNameTyping || isDefaultDashboard ? (
                            <></>
                        ) : (
                            <span
                                className={[
                                    workingDashboard?.title
                                        ? "fill-gray-100 hidden group-hover:block"
                                        : "fill-gray-30",
                                    "w-[20px] z-50 -ml-6 shrink-0",
                                ].join(" ")}>
                                <EditIcon />
                            </span>
                        )}
                    </div>
                }
                pageSubtitle={
                    <div className="flex max-w-full min-w-0 w-fit group">
                        <input
                            ref={descriptionRef}
                            placeholder={t("clickToAddDescription")}
                            onClick={() => setIsDescTyping(true)}
                            onBlur={(e: FocusEvent<HTMLInputElement>) => {
                                setIsDescTyping(false)
                                if (
                                    e.target.value ==
                                    workingDashboard?.description
                                ) {
                                    return
                                }
                                editDashboardName(
                                    "description",
                                    e.target.value || ""
                                )
                            }}
                            onInput={(e) => {
                                e.currentTarget.style.width =
                                    e.currentTarget.value.length + "ch"
                            }}
                            onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
                                handleKeyPress(
                                    e.code,
                                    descriptionRef,
                                    workingDashboard?.description ?? ""
                                )
                            }}
                            className={`${COMMON_INPUT_CLASSES} pr-6 min-w-[150px]`}
                            style={{
                                width: workingDashboard?.description?.length
                                    ? workingDashboard?.description?.length +
                                      2 +
                                      "ch"
                                    : "30ch",
                            }}
                            disabled={isDefaultDashboard}
                        />
                        {isNameTyping || isDefaultDashboard ? (
                            <></>
                        ) : (
                            <span
                                className={[
                                    workingDashboard?.description
                                        ? "fill-gray-100 hidden group-hover:block"
                                        : "fill-gray-30",
                                    "w-[15px] -ml-6 shrink-0",
                                ].join(" ")}>
                                <EditIcon />
                            </span>
                        )}
                    </div>
                }
                right={
                    <div className="flex flex-row items-center ml-6 gap-2 print:hidden">
                        {!isDefaultDashboard && (
                            <button
                                className="w-6 h-6 cursor-pointer fill-gray-60 hover:fill-accent-dark disabled:fill-gray-30"
                                onClick={() => {
                                    setOriginalDashboard({
                                        ...workingDashboard,
                                    })
                                    navigate("edit/locations")
                                }}>
                                <EditIcon />
                            </button>
                        )}
                        <div className="relative flex flex-col items-end">
                            <button
                                onClick={() => {
                                    setShowDownloadOptions(!showDownloadOptions)
                                }}
                                className="w-6 h-6 cursor-pointer fill-gray-60 hover:fill-accent-dark disabled:fill-gray-30">
                                <DownloadIcon />
                            </button>
                            {showDownloadOptions && (
                                <div className="rounded z-10 mt-[42px] absolute flex flex-col bg-white shadow-md items-start">
                                    <Button
                                        icon={<FileIcon />}
                                        label={t(
                                            "downloadDashboardAsPDF",
                                            "Download dashboard as PDF"
                                        )}
                                        type="tertiary"
                                        extraClasses="text-gray-60 fill-gray-60"
                                        onClick={() => {
                                            setOpenLoadingModal(true)
                                            getPDFReport()
                                        }}
                                    />
                                    {Object.values(csvs.current).length > 0 && (
                                        <Button
                                            icon={<FileIcon />}
                                            label={t(
                                                "downloadVariableChartsAsCSV",
                                                "Download variable charts as CSV"
                                            )}
                                            type="tertiary"
                                            extraClasses="text-gray-60 fill-gray-60"
                                            onClick={() => {
                                                setShowDownloadOptions(false)
                                                downloadAllCSVs()
                                            }}
                                        />
                                    )}
                                    {Object.values(csvs.current).length > 0 && (
                                        <Button
                                            icon={<FileIcon />}
                                            label={t(
                                                "downloadYearHistoryAsCSV",
                                                "Download 1 year history as CSV"
                                            )}
                                            type="tertiary"
                                            extraClasses="text-gray-60 fill-gray-60"
                                            onClick={downloadHistoricalData}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <Button
                            icon={<EmailIcon />}
                            type="secondary"
                            label={t("Share")}
                            onClick={() => {
                                setSchedulerNow(true)
                                setOpenForm(true)
                            }}
                            disabled={!isSendPDFEnabled}
                        />
                        {!isDefaultDashboard && (
                            <div className="relative flex justify-end">
                                <Button
                                    icon={<CalendarIcon />}
                                    label={t("justSchedule", "Schedule")}
                                    onClick={() => {
                                        workingDashboard?.report_schedules
                                            ?.length == 0
                                            ? setOpenForm(true)
                                            : openCloseScheduleModal()
                                        setShowDownloadOptions(false)
                                    }}
                                />
                                {openScheduleModal ? (
                                    <OpenScheduleDashboardForm
                                        onCancel={() => {
                                            setOpenScheduleModal(false)
                                        }}
                                        newSchedule={() => {
                                            setOpenForm(true)
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    </div>
                }
                bottom={undefined}
                extraClasses={"pb-5 print:-mt-10 max-w-full bg-gray-1.5"}
            />
            {openLoadingModal ? (
                <EmptyModal
                    open={openLoadingModal}
                    customClasses="h-[200px] w-[360px] relative rounded-lg bg-white p-4">
                    <div className="w-16">
                        <Lottie animationData={climateAILogoIconAnimation} />
                    </div>
                    <h3 className="mt-2 title-sm text-gray-90">
                        {t("generatingPDF", "Generating PDF Report...")}
                    </h3>
                    <p className="mt-3 body-md text-gray-60">
                        {t(
                            "generatingPDFReport",
                            "Please wait a moment while we generate your report."
                        )}
                    </p>
                </EmptyModal>
            ) : (
                <></>
            )}
            {openForm ? (
                <OpenScheduleForm
                    isOpen={openForm}
                    sendNow={schedulerNow}
                    dashboard={workingDashboard}
                    onCancel={() => {
                        setOpenForm(false)
                        setOpenScheduleModal(false)
                        setShowDownloadOptions(false)
                        setSchedulerNow(false)
                    }}
                    reloadSchedules={() => {
                        reloadSchedulesList().catch((err) => console.error(err))
                    }}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default DashNameandDescription
