const ExpandAllIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <g clipPath="url(#clip0_2664_7419)">
                <path
                    d="M12 5.83L15.17 9L16.58 7.59L12 3L7.40997 7.59L8.82997 9L12 5.83ZM12 18.17L8.82997 15L7.41997 16.41L12 21L16.59 16.41L15.17 15L12 18.17Z"
                    fill={props.fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_2664_7419">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ExpandAllIcon
