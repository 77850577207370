import { useContext } from "react"
import { CheckboxCell } from "../../../climateui/components/Table/tableUtils"
import { LabelsContext } from "../../../providers/LabelsProvider"
import AdminLocationsTableActions from "./components/AdminLocationsTableActions"
import LabelPicker from "./components/LabelPicker"

import { Row, Table } from "@tanstack/react-table"
import { ILabel } from "../../../climateui/types"
import { IInsightsLocation } from "../../../types"
import { Translate } from "../../../components"
import { useAccount } from "../../../providers/AccountProvider"
import { useLocation } from "react-router-dom"
import { useTranslate } from "@tolgee/react"
import {
    getTableSelectStatus,
    getRowSelectStatus,
    RegionCell,
} from "../../../utils/tables"
import { useLocations } from "../../../providers/LocationsProvider"
import { Tooltip } from "../../../climateui/components"
import { CSV_MULTIPLE_THINGS_SEPARATOR } from "../../../utils/csv"

function AccountCell({ rowData }: { rowData: IInsightsLocation }) {
    const { accountsObject } = useAccount()
    return (
        <div className="max-w-[100px] w-[100px]">
            {accountsObject[rowData.account_id]?.name}
        </div>
    )
}

function CommonTextCell({
    text,
    extraClasses,
}: {
    text: string
    extraClasses: string
}) {
    return <div className={extraClasses}>{text}</div>
}

function LabelCell({
    rowData,
    modifiable = false,
    expandable = false,
}: {
    rowData: IInsightsLocation
    modifiable: boolean
    expandable: boolean
}) {
    const { labels, queryLabels } = useContext(LabelsContext)
    const { selectedAccount } = useAccount()

    return (
        <LabelPicker
            locationData={rowData}
            labels={labels}
            refreshLabels={queryLabels}
            modifiable={selectedAccount === rowData.account_id && modifiable}
            expanded={expandable}
        />
    )
}

function VarietyCell({ rowData }: { rowData: IInsightsLocation }) {
    const { locationVarieties } = useLocations()

    const varieties = rowData.varieties
    if (!varieties || varieties.length === 0) return null

    const formattedVarieties = varieties
        .map((variety) => locationVarieties[variety.id])
        .join(", ")

    return (
        <Tooltip
            content={formattedVarieties}
            contentClass="max-w-64"
            doShow={varieties.length > 2}>
            <p className="line-clamp-3">{formattedVarieties}</p>
        </Tooltip>
    )
}

const getMaxWidthForLocationsTables = (pathname: string) => {
    if (pathname.includes("/admin/locations")) return "max-w-[100px]"
    else if (pathname.includes("/climate/exploration")) return "max-w-[100px]"
    else if (pathname.includes("/assets")) return ""
    else if (pathname.includes("/seasonal/plans/")) return "max-w-[140px]"
    return ""
}

function LocationNameCell({ rowData }: { rowData: IInsightsLocation }) {
    const { pathname } = useLocation()
    return (
        <CommonTextCell
            text={rowData.name}
            extraClasses={
                "whitespace-nowrap truncate " +
                getMaxWidthForLocationsTables(pathname)
            }
        />
    )
}

const LABELS_COMMONS = {
    accessorKey: "labels",
    header: () => (
        <Translate
            labelKey="labels"
            customClass="text-left"
        />
    ),
    filterFn: (
        row: Row<IInsightsLocation>,
        _: string,
        filterValue: string[]
    ) => {
        if (!filterValue.length) return true
        return row.original.labels
            .map((label) => (label as ILabel).id)
            .some((label) => filterValue.includes(label as string))
    },
}

interface ILabelColumnConfigs {
    modifiable?: boolean
    expandable?: boolean
}
interface IBuildLocationColumnsConfigs {
    labels?: ILabelColumnConfigs
}
const LOCATION_COLUMNS: (
    configs: IBuildLocationColumnsConfigs
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Record<string, any> = (configs) => ({
    selection: {
        id: "selection",
        header: ({ table }: { table: Table<IInsightsLocation> }) => (
            <CheckboxCell<IInsightsLocation>
                rowData={null}
                status={getTableSelectStatus(table)}
                isDisabled={(rowData) => {
                    const { selectedAccount } = useAccount()
                    return (
                        rowData !== undefined &&
                        rowData !== null &&
                        rowData.account_id !== selectedAccount
                    )
                }}
                onChange={table.getToggleAllPageRowsSelectedHandler()}
                isGlobal
            />
        ),
        cell: ({ row }: { row: Row<IInsightsLocation> }) => (
            <CheckboxCell
                rowData={row.original}
                status={getRowSelectStatus(row)}
                isDisabled={() => {
                    const { selectedAccount } = useAccount()
                    return row.original.account_id !== selectedAccount
                }}
                onChange={row.getToggleSelectedHandler()}
            />
        ),
    },
    name: {
        accessorKey: "name",
        filterFn: (
            row: Row<IInsightsLocation>,
            _: string,
            filterValue: string[]
        ) => {
            if (!filterValue.length) return true
            return filterValue.includes(row.original.id as string)
        },
        header: () => <Translate labelKey="location" />,
        cell: ({ row }: { row: Row<IInsightsLocation> }) => (
            <LocationNameCell rowData={row.original} />
        ),
    },
    region: {
        accessorKey: "region",
        filterFn: (
            row: Row<IInsightsLocation>,
            _: string,
            filterValue: string[]
        ) => {
            if (!row.original.id) return false
            if (!filterValue.length) return true
            // TODO: region filter using id instead of full_name
            return filterValue.includes(
                row.original.region?.full_name as string
            )
        },
        header: () => <Translate labelKey="region" />,
        cell: ({ row }: { row: Row<IInsightsLocation> }) => (
            <RegionCell partialLocation={row.original} />
        ),
    },
    account: {
        id: "account",
        accessorKey: "account",
        header: () => <Translate labelKey="account" />,
        filterFn: (
            row: Row<IInsightsLocation>,
            _: string,
            filterValue: string[]
        ) => {
            if (!filterValue.length) return true
            return filterValue.includes(row.original.account_id)
        },
        cell: ({ row }: { row: Row<IInsightsLocation> }) => (
            <AccountCell rowData={row.original} />
        ),
    },
    varieties: {
        id: "varieties",
        header: () => <Translate labelKey="assets" />,
        cell: ({ row }: { row: Row<IInsightsLocation> }) => (
            <VarietyCell rowData={row.original} />
        ),
        filterFn: (
            row: Row<IInsightsLocation>,
            _: string,
            filterValue: string[]
        ) => {
            if (filterValue.length === 0) return true
            for (const variety of row.original.varieties) {
                if (filterValue.includes(variety.id)) return true
            }
            return false
        },
    },
    labels: {
        ...LABELS_COMMONS,
        cell: ({ row }: { row: Row<IInsightsLocation> }) => (
            <LabelCell
                rowData={row.original}
                modifiable={configs.labels?.modifiable || false}
                expandable={configs.labels?.expandable || false}
            />
        ),
        filterFn: (
            row: Row<IInsightsLocation>,
            _: string,
            filterValue: string[]
        ) => {
            if (filterValue.length === 0) return true
            for (const label of row.original.labels) {
                let labelID = ""
                if (typeof label === "string") labelID = label
                else labelID = label.id || ""
                if (filterValue.includes(labelID)) return true
            }
            return false
        },
    },
    actions: {
        id: "actions",
        header: "",
        cell: ({ row }: { row: Row<IInsightsLocation> }) => (
            <AdminLocationsTableActions rowData={row.original} />
        ),
    },
})

export const defaultLocationColumns = [
    "selection",
    "name",
    "region",
    "account",
    "varieties",
    "labels",
    "actions",
]
export const buildLocationColumns = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: any[] = defaultLocationColumns,
    configs: IBuildLocationColumnsConfigs = {
        labels: {
            modifiable: false,
            expandable: false,
        },
    }
) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any[] = []
    columns.forEach((column) => {
        if (typeof column === "string") {
            const columns = LOCATION_COLUMNS(configs)
            if (columns[column]) result.push(columns[column])
        } else result.push(column)
    })
    return result
}

export const formatLocationsCSVData = (
    locations: IInsightsLocation[]
) => {
    return locations.map((location) => [
        location.name.replaceAll(",", ";"),
        location.latitude,
        location.longitude,
        location.labels
            .map((label: string | ILabel) => {
                // labels should be formatted at this point
                if (typeof label === "string") return label
                return label.name
            })
            .join(CSV_MULTIPLE_THINGS_SEPARATOR),
    ])
}

export const LOCATIONS_CSV_HEADERS = () => {
    const { t } = useTranslate()
    return [
        t("locationName", "Location Name"),
        t("latitude", "Latitude"),
        t("longitude", "Longitude"),
        t("labels", "Labels"), // labels must be the last element
    ]
}
