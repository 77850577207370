import { useCallback, useRef, useState } from "react"
import { Cell, Row, Table } from "@tanstack/react-table"
import { ParsedRow, IValueError } from "./types"
import { useTranslate } from "@tolgee/react"
import { GenericInput } from "../../climateui/components/Inputs"
import { useOutsideComponentClickHandler } from "../../climateui/hooks"

const DIALOG_X_MARGIN = 10
const DIALOG_Y_MARGIN = 10
const MAX_SINGLE_LINE_LEN = 8

const EditableCell = ({
    cell,
    row,
    getValue,
}: {
    cell: Cell<ParsedRow, unknown>
    row: Row<ParsedRow>
    table: Table<ParsedRow>
    getValue: () => any // eslint-disable-line @typescript-eslint/no-explicit-any
    renderValue: () => any // eslint-disable-line @typescript-eslint/no-explicit-any
}) => {
    const { index } = row
    const { column } = cell
    const { t } = useTranslate()
    const columnName = (column.columnDef as { accessorKey: string }).accessorKey
    const value = getValue()
    const [isEditing, setIsEditing] = useState(false)
    const [editedValue, setEditedValue] = useState<string>(value[0])
    let errors: IValueError[] | undefined = undefined
    if (value[1]) {
        if (value[1].length) {
            errors = value[1]
        } else {
            errors = [value[1]]
        }
    }
    const updateData = column.columnDef.meta?.updateData
    const update = () => {
        if (!updateData) return
        updateData(index, columnName, editedValue)
        setIsEditing(false)
    }
    const dialogRef = useRef<HTMLDivElement>(null)
    const cellRef = useRef<HTMLDivElement>(null)
    const followMouse = useCallback((ev: MouseEvent) => {
        if (!dialogRef.current) return
        const dialog = dialogRef.current
        const rect = dialog.getBoundingClientRect()
        // Determine if the pointer position warrants a change
        const yPointerBias =
            ev.pageY < window.innerHeight / 2
                ? DIALOG_Y_MARGIN
                : -(rect.height + DIALOG_Y_MARGIN)
        const xPointerBias =
            ev.pageX < window.innerWidth / 2
                ? DIALOG_X_MARGIN
                : -(rect.width + DIALOG_X_MARGIN)
        // Reposition the dialog based on the mouse position
        dialog.style.left = xPointerBias + ev.pageX + "px"
        dialog.style.top = yPointerBias + ev.pageY + "px"
    }, [])

    const hideDialog = () => {
        if (!cellRef.current || !dialogRef.current) return
        // Remove event listener
        document.body.removeEventListener("mousemove", followMouse)
        // Reset style
        dialogRef.current.style.visibility = "hidden"
        dialogRef.current.style.top = "auto"
        dialogRef.current.style.left = "auto"
        // Re-append to parent
        cellRef.current.append(dialogRef.current)
    }
    const showDialog = () => {
        if (!dialogRef.current) return
        // Append to the body
        document.body.append(dialogRef.current)
        // Follow the mouse
        dialogRef.current.style.visibility = "visible"
        document.body.addEventListener("mousemove", followMouse)
    }

    const inputRef = useOutsideComponentClickHandler(update)
    return (
        <div
            ref={cellRef}
            onMouseOver={showDialog}
            onMouseEnter={showDialog}
            onMouseOut={hideDialog}
            onMouseLeave={hideDialog}
            onClick={hideDialog}
            className={[
                "flex flex-col justify-center",
                "w-full h-full py-2",
                "group",
                value[1] ? "bg-red-light text-red" : "",
            ].join(" ")}>
            {isEditing && !!columnName && !!updateData && (
                <div
                    ref={inputRef}
                    className={[
                        value[0].length <= MAX_SINGLE_LINE_LEN ? "w-24" : "",
                    ].join(" ")}>
                    {value[0].length <= MAX_SINGLE_LINE_LEN && (
                        <GenericInput
                            type="text"
                            defaultValue={value[0]}
                            handleChange={(ev) => {
                                const target = ev.nativeEvent
                                    .target as HTMLInputElement
                                setEditedValue(target?.value.trim())
                            }}
                            handleBlur={update}
                        />
                    )}
                    {value[0].length > MAX_SINGLE_LINE_LEN && (
                        <textarea
                            autoFocus={true}
                            className={[
                                "rounded-md border border-gray-14 p-1",
                                "font-normal body-lg text-gray-90 placeholder:text-gray-30 fill-gray-60",
                                "transition-all duration-75",
                                "enabled:hover:border-gray-30 enabled:hover:z-[1]",
                                "enabled:active:border-accent enabled:focus:border-accent",
                                "focus:outline-none",
                            ].join(" ")}
                            defaultValue={value[0]}
                            onChange={({ target }) =>
                                setEditedValue(target?.value.trim())
                            }
                            onBlur={update}
                        />
                    )}
                </div>
            )}
            {!isEditing && (
                <div
                    onClick={() => setIsEditing(true)}
                    className="flex items-center w-full h-full cursor-pointer">
                    <span>
                        {value[0] !== "" ? (
                            value[0]
                        ) : (
                            <span className="body-sm">
                                &lt;{t("empty", "empty")}&gt;
                            </span>
                        )}
                    </span>
                </div>
            )}
            {errors && (
                <div
                    ref={dialogRef}
                    className="absolute invisible p-2 text-sm text-white border border-white rounded-lg shadow-md bg-red z-full">
                    <span className="font-bold title-sm">
                        {t("errors", "Errors")}
                    </span>
                    <ul className="list-disc list-inside">
                        {errors.map((error) => (
                            <li key={error.message}>{error.message}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}
export default EditableCell
