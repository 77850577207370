import {
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"
import { useOutsideComponentClickHandler } from "../../../hooks"
import DebounceSearchInput from "../../DebounceSearchInput"
import FixedElement, { FixedPosition, FixedAlignment } from "../../FixedElement"
import {
    DefaultSortFn,
    DEFAULT_SORT_FNS,
    DropdownEntry,
    IDropdownOpt,
    EntriesFilterFn,
    EntriesSortFn,
    setSelectedByKey,
} from "../DropdownSelect/utils"
import { useArrowSelector } from "./hooks"
import { DefaultToggler, ITogglerProps } from "./togglers"
import DropdownOption from "./option"
import { SelectionStatus, getStatfromBool } from "../../../utils/constants"

const DEFAULT_PLACEHOLDER = "---"
const DEFAULT_SEARCH_MIN = 9
const KEY = 0

/**
 * SEARCHING ------------------------------------------------------------------
 */
const DropdownSearch = ({
    setSearchFn,
    debounceDelay = 100,
    searchPlaceholder,
}: {
    setSearchFn: Dispatch<SetStateAction<EntriesFilterFn | undefined>>
    debounceDelay?: number
    searchPlaceholder?: string
}) => {
    const [searchText, setSearchText] = useState<string>()

    useEffect(() => {
        if (!searchText) return setSearchFn(undefined)
        const normSearch = searchText.trim().toLowerCase()
        const searchFn = (entry: DropdownEntry) => {
            const [, entryOpt] = entry
            if (!entryOpt?.label) return false
            return entryOpt.label.toLowerCase().includes(normSearch)
        }
        setSearchFn(() => searchFn)
    }, [searchText])

    return (
        <div className="sticky top-0 bg-white p-2 min-w-[160px] z-20">
            <DebounceSearchInput
                autoFocus={true}
                onSearch={setSearchText}
                ms={debounceDelay}
                placeholder={searchPlaceholder}
            />
        </div>
    )
}

/**
 * TOGGLER --------------------------------------------------------------------
 **/
/**
 * OPTIONS --------------------------------------------------------------------
 **/
/**
 * DROPDOWN (CORE) ------------------------------------------------------------
 **/
interface ICoreDropdownProps<S = Set<string>> {
    options: Record<string, IDropdownOpt | string>
    optionIcons?: Record<string, ReactNode>
    sort?: EntriesSortFn | DefaultSortFn
    placeholder?: string
    position?: FixedPosition
    align?: FixedAlignment
    selected: S
    setSelected: Dispatch<SetStateAction<S>>
    leadingIcon?: ReactNode
    trailingIcon?: ReactNode
    canSearch?: boolean
    searchMin?: number // The minimum amount of options before showing the search bar
    customToggler?: FC<ITogglerProps>
    disabled?: boolean
    canClear?: boolean
    selectAllOption?: string
    searchPlaceholder?: string
    multi?: boolean
}
const CoreDropdownSelect = ({
    options,
    sort,
    optionIcons,
    selected: selectedKeys,
    setSelected,
    placeholder = DEFAULT_PLACEHOLDER,
    position = "bottom",
    align = "left",
    leadingIcon,
    trailingIcon,
    multi = true,
    canSearch: _canSearch = true,
    searchMin = DEFAULT_SEARCH_MIN,
    customToggler: CustomToggler = DefaultToggler,
    disabled = false,
    canClear = false,
    selectAllOption,
    searchPlaceholder,
}: ICoreDropdownProps) => {
    const [open, setOpen] = useState(false)
    const [searchFn, setSearchFn] = useState<EntriesFilterFn | undefined>()
    const toggle = () => setOpen((prevOpen) => !prevOpen)
    const outsideClickRef = useOutsideComponentClickHandler(() => {
        setOpen(false)
    })

    /* METHODS > START */
    const convertToDropdownOpt = (
        prev: DropdownEntry[],
        entry: [string, string | IDropdownOpt]
    ): DropdownEntry[] => {
        const [key, oldVal] = entry
        if (typeof oldVal === "string") {
            const newVal: IDropdownOpt = {
                label: oldVal,
            }
            // Append the optional icon if it exists
            if (optionIcons?.[key]) {
                newVal["icon"] = (
                    <span className="w-4 h-4 fill-gray-60 shrink-0">
                        {optionIcons[key]}
                    </span>
                )
            }
            return [...prev, [key, newVal]]
        }
        return [...prev, [key, oldVal]]
    }
    /* METHODS < END */

    /* MEMO > START */
    const parsedOptions: DropdownEntry[] = useMemo(() => {
        let entries = Object.entries(options).reduce(convertToDropdownOpt, [])
        // Filter entries
        if (searchFn) {
            entries = entries.filter(searchFn)
        }
        // Sort entries
        let compareFn = sort as EntriesSortFn | undefined
        if (typeof sort === "string") compareFn = DEFAULT_SORT_FNS[sort]
        if (compareFn) entries.sort(compareFn)

        return entries
    }, [options, optionIcons, searchFn]) // NOTE: implicit `optionIcons` dependency

    const { selected: arrowSelection, ref: arrowSelectorRef } =
        useArrowSelector(parsedOptions, open)

    const keyActionMap: Record<string, Function> = useMemo(
        () => ({
            Enter: (event: KeyboardEvent) => {
                if (event.type === "keyup") {
                    if (!arrowSelection) return
                    setSelected(setSelectedByKey(arrowSelection[KEY]))
                    if (!multi) toggle()
                } else if (event.type === "keydown") {
                    event.preventDefault()
                }
            },
        }),
        [arrowSelection, multi]
    )
    const handleKeyPress = (event: KeyboardEvent) => {
        if (!keyActionMap[event.key]) return
        keyActionMap[event.key](event)
    }
    const optionsRef = useCallback(
        (_node: HTMLElement | null) => {
            // Pass the node to the arrow nav ref
            const node = arrowSelectorRef(_node)
            // Bind enter event for option selection
            if (node) {
                // Prevent click default
                window.addEventListener("keydown", handleKeyPress)
                // Choose with enter
                window.addEventListener("keyup", handleKeyPress)
            } else {
                // Remove prevent click default
                window.removeEventListener("keydown", handleKeyPress)
                // Remove choose with enter
                window.removeEventListener("keyup", handleKeyPress)
            }
            return node
        },
        [arrowSelection, arrowSelectorRef]
    )

    const selectAll: Dispatch<SetStateAction<Set<string>>> = () => {
        const allSelected = parsedOptions.length === selectedKeys.size
        if (allSelected) setSelected(new Set())
        else setSelected(new Set(Object.keys(options)))
    }

    const { selected, areAllSelected } = useMemo(() => {
        let areAllSelected = SelectionStatus.SOME
        const allSelected = parsedOptions.length === selectedKeys.size
        const noneSelected = selectedKeys.size === 0
        if (allSelected) areAllSelected = SelectionStatus.FULL
        else if (noneSelected) areAllSelected = SelectionStatus.EMPTY

        return {
            areAllSelected,
            selected: Object.fromEntries(
                parsedOptions.filter(([key]) => selectedKeys.has(key))
            ),
        }
    }, [parsedOptions, selectedKeys])
    const canSearch = useMemo(() => {
        const optsArr = Object.keys(options)
        return _canSearch && optsArr.length > searchMin
    }, [_canSearch, options, searchMin])

    /* MEMO < END */

    return (
        <div ref={outsideClickRef}>
            <FixedElement
                open={open}
                position={position}
                align={align}
                parentElement={
                    <CustomToggler
                        selected={selected}
                        parsedOptions={parsedOptions}
                        open={open}
                        toggle={toggle}
                        leadingIcon={leadingIcon}
                        trailingIcon={trailingIcon}
                        placeholder={placeholder}
                        multi={multi}
                        disabled={disabled}
                        clearFunction={
                            canClear ? () => setSelected(new Set()) : undefined
                        }
                    />
                }>
                <div
                    ref={optionsRef}
                    className={[
                        "bg-white z-30 rounded-lg elevation-2",
                        "h-fit overflow-y-auto max-h-[400px]",
                        "border-[1px] border-gray-14 mt-0.5",
                        "flex flex-col",
                        "relative",
                        canSearch ? "pb-2" : "py-2",
                    ].join(" ")}>
                    {canSearch && (
                        <DropdownSearch
                            setSearchFn={setSearchFn}
                            searchPlaceholder={searchPlaceholder}
                        />
                    )}
                    {multi && selectAllOption && (
                        <DropdownOption
                            optKey="all"
                            opt={{ label: selectAllOption, icon: null }}
                            selected={areAllSelected}
                            setSelected={selectAll}
                            multi={multi} // always true here
                            toggle={toggle}
                            hovered={false} // TODO: keyboard support for selectAllOption
                        />
                    )}
                    {parsedOptions.map(([optKey, opt]) => (
                        <DropdownOption
                            key={optKey}
                            optKey={optKey}
                            opt={opt}
                            toggle={toggle}
                            selected={getStatfromBool(!!selected[optKey])}
                            hovered={arrowSelection?.[KEY] === optKey}
                            setSelected={setSelected}
                            multi={multi}
                        />
                    ))}
                </div>
            </FixedElement>
        </div>
    )
}
/**
 * WRAPPER --------------------------------------------------------------------
 **/
type NullableString = string | null
type StatePayload<S = Set<string>> = S | SetStateAction<S>

type IMultiSelectProps<S = Set<string>> = Omit<ICoreDropdownProps<S>, "multi">
type ISingleSelectProps = IMultiSelectProps<NullableString | undefined>
type ILegacyMultiSelectProps = IMultiSelectProps<Record<string, boolean>>
type IArrayMultiSelectProps = IMultiSelectProps<
    NullableString[] | null | undefined
>
type SupportedMultiProps =
    | IArrayMultiSelectProps
    | IMultiSelectProps
    | ILegacyMultiSelectProps

const isStringTag = (x: unknown, tag: string) =>
    Object.prototype.toString.call(x) === tag

const areProps = {
    arrayMultiSelect: (
        _props: SupportedMultiProps
    ): _props is IArrayMultiSelectProps => {
        const props = _props as IArrayMultiSelectProps
        return isStringTag(props.selected, "[object Array]")
    },
    legacyMultiSelect: (
        _props: SupportedMultiProps
    ): _props is ILegacyMultiSelectProps => {
        const props = _props as ILegacyMultiSelectProps
        return isStringTag(props.selected, "[object Object]")
    },
    multiSelect: (_props: SupportedMultiProps): _props is IMultiSelectProps => {
        const props = _props as IMultiSelectProps
        return isStringTag(props.selected, "[object Set]")
    },
}

const convertSetSelected = {
    fromSingle: (props: ISingleSelectProps, prevPayload: StatePayload) => {
        let _selected = new Set<string>()
        if (typeof prevPayload === "function")
            _selected = prevPayload(_selected)

        // Convert the Set<string> to string
        const selection = _selected.values().next().value
        // Update the external state
        props.setSelected(selection)
    },
    fromArray: (props: IArrayMultiSelectProps, prevPayload: StatePayload) => {
        let _selected = prevPayload
        if (typeof prevPayload === "function" && props.selected) {
            const oldSelection = new Set(
                props.selected.filter((x) => !!x) as string[]
            )
            _selected = prevPayload(oldSelection)
        }

        props.setSelected(Array.from(_selected as Set<string>))
    },
    fromLegacy: (props: ILegacyMultiSelectProps, prevPayload: StatePayload) => {
        let _selected = prevPayload
        if (typeof prevPayload === "function" && props.selected)
            _selected = prevPayload(new Set(Object.keys(props.selected)))

        props.setSelected(
            Array.from(_selected as Set<string>).reduce(
                (acc, curr) => {
                    acc[curr] = true
                    return acc
                },
                {} as Record<string, boolean>
            )
        )
    },
}
export const SingleDropdownSelect = (origProps: ISingleSelectProps) => {
    const selected = useMemo(() => {
        return new Set([origProps.selected])
    }, [origProps.selected])

    const setSelected: Dispatch<SetStateAction<Set<string>>> = useCallback(
        (multiSelection) => {
            convertSetSelected.fromSingle(origProps, multiSelection)
        },
        [origProps.setSelected, origProps.selected]
    )

    const newProps = {
        ...origProps,
        multi: false,
        selected,
        setSelected,
    } as ICoreDropdownProps

    return <CoreDropdownSelect {...newProps} />
}

export const MultiDropdownSelect = (origProps: SupportedMultiProps) => {
    const selected = useMemo(() => {
        if (areProps.arrayMultiSelect(origProps)) {
            return new Set(origProps.selected)
        }
        if (areProps.legacyMultiSelect(origProps)) {
            return new Set(Object.keys(origProps.selected))
        }
        if (areProps.multiSelect(origProps)) {
            return origProps.selected
        }

        return new Set<string>()
    }, [origProps.selected])

    const setSelected: Dispatch<SetStateAction<Set<string>>> = useCallback(
        (multiSelection) => {
            // ArrayMultiSelect
            if (areProps.arrayMultiSelect(origProps))
                convertSetSelected.fromArray(origProps, multiSelection)
            // Legacy MultiSelect (Boolean Dictionary)
            else if (areProps.legacyMultiSelect(origProps))
                convertSetSelected.fromLegacy(origProps, multiSelection)
            // MultiSelect
            else origProps.setSelected(multiSelection)
        },
        [origProps.setSelected, origProps.selected]
    )

    const newProps = {
        ...origProps,
        multi: true,
        selected,
        setSelected,
    } as ICoreDropdownProps

    return <CoreDropdownSelect {...newProps} />
}
