import { FC } from "react"
import { useTranslate } from "@tolgee/react"
import { format } from "date-fns"

import { Chart } from "../../Chart"

/* Components */
import { LineIcon } from "../../components/Icons/LineIcon"
import { AreaIcon } from "../../components/Icons/AreaIcon"
import { DefaultLegend, TypeLegend, onRenderDefaultLegends } from "../../components/Legends"
import { Wrapper } from "../../components/Wrapper"

/* Helper functions */
import { onRenderDefaultXLabel, onRenderDefaultYLabel } from "../../components/Labels"
import { onRenderDefaultTitle } from "../../components/Title"
import { onRenderDefaultXTick, onRenderDefaultYTick } from "../../components/Ticks"

/* Data Sources */
import { DataSourceChartPlotComponents, DataSourceMetaData } from "./data/DataSourceChartPlotComponents"
import { DATA_SOURCE_VIZUALIZATION_OPTIONS } from "./data/types"

/* 
    The purpose of this wrapper is to implement all onRenderFn 
    externally in order to achieve full typesafety inside <Chart />
    via generic components / factory pattern via the prop metadata:TMetaData
*/

export interface ChartSeasonalProps {
    chartPlotComponents: DataSourceChartPlotComponents
}

export const ChartSeasonal: FC<ChartSeasonalProps> = ({ chartPlotComponents }) => {
    const { t } = useTranslate()

    /* Pending to move this functions to a more feasible folder */
    const helperSeasonalLegendValue = (metadata: DataSourceMetaData): string => {
        /* There should be a better way to access this information */
        /* Also, we need to standardize the naming convention for the titles in tolgee */
        if (metadata.visualization === DATA_SOURCE_VIZUALIZATION_OPTIONS.LINE) {
            return t(metadata.type)
        } else if (metadata.visualization === DATA_SOURCE_VIZUALIZATION_OPTIONS.AREA) {
            if (metadata.opacity == 0.2) {
                return t('HistorialAvg90')
            } else if (metadata.opacity == 0.30000000000000004) {
                return t('HistorialAvg50')
            }
        }
        return t('')
    }

    const helperSeasonalLegendIcon = (metadata: DataSourceMetaData): JSX.Element => {
        if (metadata.visualization === DATA_SOURCE_VIZUALIZATION_OPTIONS.LINE) {
            return <LineIcon backgroundColor={metadata.color} opacity={metadata.opacity} />
        } else if (metadata.visualization === DATA_SOURCE_VIZUALIZATION_OPTIONS.AREA) {
            return <AreaIcon backgroundColor={metadata.color} opacity={metadata.opacity} />
        }
        return <></>
    }

    const onRenderSeasonalLegendValues = (values: TypeLegend<DataSourceMetaData>[]) => {
        const valuesIcon: DefaultLegend[] = values.map((value) => ({
            id: value.id,
            value: helperSeasonalLegendValue(value.metadata),
            icon: helperSeasonalLegendIcon(value.metadata)
        }))
        return onRenderDefaultLegends(valuesIcon)
    }

    return (
        <div className="w-full h-[480px]">
            <Wrapper>
                <Chart
                    title={chartPlotComponents.title}
                    plotComponents={chartPlotComponents.plotComponents}
                    data={chartPlotComponents.data}
                    axisLabels={chartPlotComponents.axisLabels}
                    isXAxisLabelHidden
                    onRenderYLabelValue={onRenderDefaultYLabel}
                    onRenderXLabelValue={onRenderDefaultXLabel}
                    onRenderYAxisTickValue={(value, index, length) => onRenderDefaultYTick(value)}
                    onRenderXAxisTickValue={(value, index, length) => {
                        const dateFormat = (index === 0 || index === length - 1) ? 'MMM dd' : 'dd'
                        const date = format(new Date(value), dateFormat)
                        return onRenderDefaultXTick(date)
                    }}
                    onRenderTitleValue={onRenderDefaultTitle}
                    onRenderLegendValues={onRenderSeasonalLegendValues}
                    /* Example of an inplace cb fn with metadata<T> typesafety */
                    onRenderTooltipPlotComponentValues={(values) => {
                        const linePlotComponent = values.filter((value) => value.metadata.visualization === DATA_SOURCE_VIZUALIZATION_OPTIONS.LINE)
                        const date = new Date(String(values[0].x))
                        const month = format(date, 'MMM')
                        const day = format(date, 'd')
                        return (
                            <div className="bg-white shadow-xl p-4 rounded-md">
                                <div className="flex justify-start items-center space-x-4">
                                    <div className="flex flex-col items-center justify-center">
                                        <p className="headline-lg text-gray-60">{month}</p>
                                        <p className="headline-sm text-gray-60">{day}</p>
                                    </div>
                                    <div>
                                        {linePlotComponent.map((value) => (
                                            <div key={value.id} className="space-y-2">
                                                <div className="space-x-2 flex items-center justify-start">
                                                    <LineIcon
                                                        /* Dynamic access to (generic) metadata with full typesafety */
                                                        backgroundColor={value.metadata.color}
                                                        opacity={value.metadata.opacity}
                                                    />
                                                    <span className="text-gray-60">{value.y}</span>
                                                </div>
                                            </div>

                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                />
            </Wrapper>
        </div>
    )
}