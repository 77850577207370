import { useTranslate } from "@tolgee/react"

function AssetCategorySection({
    name,
    assetNames,
}: {
    name: string
    assetNames: string[]
}) {
    const { t } = useTranslate()

    return (
        <div className="divide-y divide-gray-14">
            <h6 className="title-sm text-gray-90 pb-2">{name}</h6>
            <div className="grid grid-cols-4 w-full gap-y-3 py-4 px-8">
                {assetNames.map((assetName) => (
                    <div
                        className="flex flex-row items-center gap-3"
                        key={"asset-" + assetName}>
                        <span className="bg-accent-medium rounded-full h-2 w-2"></span>
                        <div className="body-lg text-gray-90">
                            {t(assetName, assetName)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AssetCategorySection
