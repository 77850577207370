// import { useTranslate } from "@tolgee/react"
import { ReactNode, useContext } from "react"

import {
    useProviderBase,
    YieldOutlookContext,
} from "../../../hooks/YieldOutlook/useProviderBase"
import { yieldOutlookQuerySet } from "../../../utils/networking/yield"

export const useRiskOutlook = () => useContext(YieldOutlookContext)

const RiskOutlookProvider = ({
    children,
}: Readonly<{ children: ReactNode }>) => {
    /* PROVIDERS ------------------------------------------------------------ */
    // const { t } = useTranslate()
    /* HOOKS ---------------------------------------------------------------- */
    const providerValue = useProviderBase({
        querySet: yieldOutlookQuerySet,
        productStatusKey: "risk_status",
        navigateToFirstModel: false,
    })

    return (
        <YieldOutlookContext.Provider value={providerValue}>
            {children}
        </YieldOutlookContext.Provider>
    )
}

export default RiskOutlookProvider
