import { useTranslate } from "@tolgee/react"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useAssets } from "../../../providers"
import { useAccount } from "../../../providers/AccountProvider"
import { IAccountAsset } from "../../../types"
import AssetButton from "../components/AssetButton"
import AssetCategorySection from "../components/AssetCategorySection"
import OnboardingCoolSVG from "../components/OnboardingCoolSVG"
import PageHeader from "../components/PageHeader"

const CAI_ASSETS: Record<string, string[]> = {
    grainsAndCereals: ["Barley", "Corn", "Oat", "Rice", "Sorghum", "Wheat"],
    oilseeds: ["Canola", "Soybean"],
    legumesPulses: ["Alfalfa", "Chickpea", "Favabean", "Mungbean", "Pea"],
    rootAndStemVegetables: [
        "Potato",
        "Carrot",
        "Onion",
        "Sweet Potato",
        "Beets",
    ],
    fruitsAndNuts: [
        "Apple",
        "Avocado",
        "Cherry",
        "Citrus",
        "Mango",
        "Olive",
        "Peach",
        "Pear",
        "Pomegranate",
        "Walnut",
        "Cucumber",
        "Squash",
    ],
    berries: ["Blueberries", "Raspberry", "Strawberry"],
    leafyAndCruciferousVegetables: ["Lettuce", "Spinach", "Broccoli"],
    cashCrops: ["Cotton", "Sugarcane", "Tobacco", "Coffee"],
    specialtyCrops: ["Cranberry"],
}

function sortAssetsByIsSetup(
    a: IAccountAsset,
    b: IAccountAsset
) {
    if (a.is_setup && b.is_setup) return 0
    if (a.is_setup) return 1
    if (b.is_setup) return -1
    return 0
}

function OnboardingAssets() {
    const { t } = useTranslate()

    const { selectedAccount, accountsObject } = useAccount()
    const { allAssets: assets, isLoadingAssets, accountAssets } = useAssets()

    const navigate = useNavigate()

    const sortedAssetsArray = useMemo(() => {
        if (!assets || !accountAssets) return []

        return Object.values(accountAssets)
            .sort(sortAssetsByIsSetup)
            .map((accountAsset) => assets[accountAsset.asset_id])
    }, [assets, accountAssets])

    return (
        <>
            <div
                className={[
                    "w-full grow min-h-0 h-full overflow-y-auto",
                    "flex flex-col relative",
                ].join(" ")}>
                <div className="h-[540px] bg-gray-1.5 shrink-0 flex flex-col border-b border-gray-14">
                    <div className="flex flex-row grow">
                        <div className="flex flex-row items-center justify-start pl-32 -mt-6 grow">
                            <div className="w-fit h-fit flex flex-col gap-6 max-w-[660px]">
                                <h1 className="display-sm text-gray-90">
                                    {selectedAccount &&
                                    accountsObject?.[selectedAccount]?.name
                                        ? t("welcomeCLIENT", "Welcome!", {
                                              client: accountsObject[
                                                  selectedAccount
                                              ].name,
                                          })
                                        : t("welcome", "Welcome!")}
                                </h1>
                                <p className="body-lg text-gray-90">
                                    {t(
                                        "letsSetupYourAccount",
                                        "Let's set up the available assets for your account."
                                    )}{" "}
                                    <b>
                                        {t(
                                            "clickAnAssetBelowToGetStarted",
                                            "Click an asset below to get started."
                                        )}
                                    </b>
                                </p>

                                <div className="relative">
                                    <div className="flex flex-row flex-wrap items-center pb-6 overflow-y-auto gap-2 max-h-48">
                                        {(isLoadingAssets || !assets) &&
                                            Array.from("abcde").map((aux) => (
                                                <AssetButton
                                                    key={
                                                        "loading-assets-" + aux
                                                    }
                                                    name={t("loading")}
                                                    isLoading
                                                />
                                            ))}
                                        {!isLoadingAssets &&
                                            assets &&
                                            sortedAssetsArray.map((asset) => {
                                                if (!asset) return null
                                                const assetId = asset.id
                                                const disabled =
                                                    !accountAssets ||
                                                    !accountAssets[assetId] ||
                                                    accountAssets[assetId]
                                                        .is_setup

                                                return (
                                                    <AssetButton
                                                        handleClick={() =>
                                                            navigate(
                                                                assetId +
                                                                    "/risk-profiles"
                                                            )
                                                        }
                                                        assetIsSetup={disabled}
                                                        key={asset.id}
                                                        name={asset.name}
                                                    />
                                                )
                                            })}
                                    </div>
                                    <div className="inset-x-0 bottom-0 absolute h-6 bg-gradient-to-b from-transparent to-gray-1.5 pointer-events-none"></div>
                                </div>
                            </div>
                        </div>

                        <div className="flex-row items-end justify-end hidden pr-6 xl:flex">
                            <OnboardingCoolSVG />
                        </div>
                    </div>
                </div>

                <div className="bg-white grow h-fit pt-9">
                    <div className="sticky top-0 px-32 pt-2 pb-4 bg-white/60 backdrop-blur-lg">
                        <PageHeader
                            title={t(
                                "assetTemplateLibrary",
                                "Asset Template Library"
                            )}
                            subtitle={t(
                                "ifYouWantMoreAssetsContactUs",
                                "If you like to add more assets from this library or a new custom asset, please contact us"
                            )}
                            secondaryAction={undefined}
                            primaryAction={undefined}
                        />
                    </div>
                    <div className="px-32">
                        <div className="flex flex-col pt-6 pb-4 gap-16">
                            {Object.keys(CAI_ASSETS).map((key) => (
                                <AssetCategorySection
                                    key={key}
                                    name={t(key)}
                                    assetNames={CAI_ASSETS[key]}
                                />
                            ))}
                        </div>

                        <div className="flex flex-row justify-center py-8">
                            <img
                                src="/images/climateai/ClimateAi-new-logo.png"
                                className="w-36"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OnboardingAssets
