import React from "react"
import Color from "color"
export interface ProbabilityHoverProps {
    title?: string
    rightTitle?: string
    leftTitle?: string
    leftHeader?: string
    rightHeader?: string
    items?: ProbabilityHoverItemProps[]
}

function ProbabilityHover(props: Readonly<ProbabilityHoverProps>) {
    const { title, leftHeader, rightHeader, items, rightTitle, leftTitle } =
        props
    return (
        <div className="flex flex-wrap w-[275px] text-[12px]">
            <div className="w-full border-b-[1px] border-gray-14">
                <h1 className="font-bold text-[14px]">{title ?? ""}</h1>
                {rightTitle && leftTitle && (
                    <div className="flex flex-col w-[100%] text-gray-78 font-bold text-[12px]">
                        <div className="flex justify-between">
                            <span>{leftTitle}</span>
                            <span>{rightTitle}</span>
                        </div>
                    </div>
                )}
                {rightHeader && leftHeader && (
                    <div className="flex mb-[6px] w-[100%] justify-between text-gray-60 text-[12px]">
                        <h1>{leftHeader ?? ""}</h1>
                        <h1 className="">{rightHeader ?? ""}</h1>
                    </div>
                )}
            </div>
            {items?.map((item) => (
                <ProbabilityHoverItem
                    key={`probability-hover-item-${item.title}`}
                    {...item}
                />
            ))}
        </div>
    )
}

export interface ProbabilityHoverItemProps {
    title: string
    value: string
    description?: string
    active?: boolean
    color?: string
    icon?: React.ComponentType
}

function ProbabilityHoverItem(props: Readonly<ProbabilityHoverItemProps>) {
    const { title, description, value, active, color, icon: Icon } = props
    return (
        <div
            style={
                active
                    ? {
                          borderRadius: "5px",
                          border: "solid",
                          backgroundColor: Color(color).alpha(0.1).hexa(),
                          color: Color(color).darken(0.2).hex(),
                          borderColor: Color(color).alpha(0.4).hexa(),
                      }
                    : {}
            }
            className="w-[100%] flex justify-between py-[5px] px-[10px] items-center border-b-[1px] border-solid border-[#DBDDDF] text-[#666D74]">
            <div className="flex items-center">
                <div
                    className="w-[18px] h-[18px]"
                    style={{ fill: active ? color : "#666D74" }}>
                    {Icon && <Icon />}
                </div>
                <div className="ml-[12px]">
                    <h1 className="font-[600]">{title}</h1>
                    <h1>{description}</h1>
                </div>
            </div>
            <h1 className="font-bold text-[12px]">{value}</h1>
        </div>
    )
}

export default ProbabilityHover
