import { Row, Table } from "@tanstack/react-table"
import { IVariety } from "../../../types"
import { T, useTranslate } from "@tolgee/react"
import { AssetTableActions } from "./components"
import { useUnits } from "../../../providers/UnitConversionProvider"
import { useAuth } from "../../../providers"
import { CheckboxCell } from "../../../climateui/components/Table/tableUtils"
import { getRowSelectStatus, getTableSelectStatus } from "../../../utils/tables"
import { Button, TooltipV2 } from "../../../climateui/components"
import { useNavigate } from "react-router-dom"
import { hasInitialDate, isCaiDefaultVariety } from "../../../utils"

/*
 * These are harcoded fallback names
 * for the attributes. In the future, if
 * we end up supporting custom attributes,
 * this won't be able to cover user-defined
 * strings and should be removed in favor of
 * another solution.
 *
 * - with love, minaya@climate.ai
 */
const FALLBACK_STRINGS: Record<string, string> = {
    gdd_base_temp: "GDD Base Temp",
    gdd_max_threshold: "GDD Max Threshold",
    gdd_min_threshold: "GDD Min Threshold",
}
const CustomAttribute = ({
    attrKey,
    value,
}: {
    attrKey: string
    value: string
}) => {
    /* Since we have preset custom attributes we can make the assumption that
     * the values represent temperatures in celcius, however, in the future we
     * should support custom units for these attributes */

    const { convertUnits } = useUnits()
    const { user } = useAuth()
    const parsedValue = parseFloat(value)
    const val = !isNaN(parsedValue)
        ? convertUnits(
              parsedValue,
              { units_metric: "C", units_imperial: "F" },
              "convertUnits"
          ).toFixed(2)
        : 0
    return (
        <div>
            <T key={attrKey}>{FALLBACK_STRINGS[attrKey]}</T> {Number(val)}&nbsp;
            <span>{user?.unit_type ? "°C" : "°F"}</span>
        </div>
    )
}
function TimelineButton({ variety }: { variety: IVariety }) {
    const { t } = useTranslate()
    const navigate = useNavigate()

    return (
        <TooltipV2
            position="left"
            content={
                <div className="w-32 whitespace-normal">
                    {t(
                        "editAssetInitialDateToAccessTimeline",
                        "Please edit the asset and add an initial date in order to access the Timeline"
                    )}
                </div>
            }
            doShow={!isCaiDefaultVariety(variety) && !hasInitialDate(variety)}>
            <Button
                disabled={
                    isCaiDefaultVariety(variety) || !hasInitialDate(variety)
                }
                label="Timeline"
                type="secondary"
                onClick={() => navigate(variety.id + "/timeline")}
            />
        </TooltipV2>
    )
}
export const columns = [
    {
        id: "select",
        header: ({ table }: { table: Table<IVariety> }) => (
            <CheckboxCell<IVariety>
                rowData={null}
                status={getTableSelectStatus(table)}
                onChange={table.getToggleAllRowsSelectedHandler()}
                isGlobal
            />
        ),
        cell: ({ row }: { row: Row<IVariety> }) => (
            <CheckboxCell
                rowData={row.original}
                status={getRowSelectStatus(row)}
                onChange={row.getToggleSelectedHandler()}
                isDisabled={() => row.original.is_default}
            />
        ),
    },
    {
        id: "asset",
        accessorKey: "asset.name",
        header: () => <T key="asset">Asset</T>,
        accessorFn: (variety: IVariety) => variety.asset.name,
    },
    {
        id: "name",
        accessorKey: "name",
        header: () => <T key="name">Name</T>,
        cell: ({ row }: { row: Row<IVariety> }) => {
            const variety = row.original
            return (
                <span className={variety.name === "Default" ? "text-gray-30" : ""}>
                    {variety.name}
                </span>
            )
        },
    },
    {
        accessorKey: "description",
        header: () => <T key="description">Description</T>,
    },
    {
        id: "attributes",
        header: () => <T key="attributes">Attributes</T>,
        cell: ({ row }: { row: Row<IVariety> }) => {
            const variety = row.original
            return (
                <div className="flex flex-col">
                    {variety.variety_metadata.map(({ id, key, value }) => (
                        <div key={id}>
                            <CustomAttribute
                                attrKey={key}
                                value={value}
                            />
                        </div>
                    ))}
                </div>
            )
        },
    },
    {
        id: "actions",
        cell: ({ row }: { row: Row<IVariety> }) => (
            <AssetTableActions data={row.original} />
        ),
    },
]

export const timelineColumn = {
    id: "timeline",
    cell: ({ row }: { row: Row<IVariety> }) => (
        <TimelineButton variety={row.original} />
    ),
}
