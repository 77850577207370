import { useTranslate } from "@tolgee/react"
import { useIsFlagEnabled } from "../../../../hooks";

const items: { labelKey: string; color: string }[] = [
    {
        labelKey: "aboveNormal",
        color: "#23AF41",
    },
    {
        labelKey: "withinNormal",
        color: "#FDB600",
    },
    {
        labelKey: "belowNormal",
        color: "#E42437",
    },
    {
        labelKey: "noSignal",
        color: "gray",
    }
]

const DirectionalPinsMapLegend = ({ title }: { title?: string }) => {
    const { t } = useTranslate()
    const isNewDirectionalityToggleEnabled = !!useIsFlagEnabled("experiment_yield_outlook_new_directionality_logic")
    return (
        <div className="left-2 bottom-10 p-3 absolute z-20 rounded-sm shadow-md bg-white min-w-[92.71px] min-h-[100px]">
            {title && (
                <h2 className="mb-2 font-medium body-md text-gray-90 w-[92.71px]">
                    {title}
                </h2>
            )}
            <div className="flex flex-col gap-1">
                {items.map(({ labelKey, color }) => {
                    if(labelKey === "noSignal" && !isNewDirectionalityToggleEnabled) return null
                    return (
                        <div
                            className="flex items-center gap-2 body-sm"
                            key={labelKey}>
                            <div
                                style={{
                                    backgroundColor: color,
                                    borderWidth: "1.5px",
                                    borderColor: color,
                                }}
                                className="w-3 h-3"></div>
                            {t(labelKey)}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default DirectionalPinsMapLegend
