const WorldSearchIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <g clipPath="url(#clip0_2156_3420)">
                <path
                    d="M19.3 16.9C19.7 16.2 20 15.4 20 14.5C20 12 18 10 15.5 10C13 10 11 12 11 14.5C11 17 13 19 15.5 19C16.4 19 17.2 18.7 17.9 18.3L21.1 21.5L22.5 20.1L19.3 16.9ZM15.5 17C14.1 17 13 15.9 13 14.5C13 13.1 14.1 12 15.5 12C16.9 12 18 13.1 18 14.5C18 15.9 16.9 17 15.5 17ZM12 20V22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C16.84 2 20.87 5.44 21.8 10H19.73C19.09 7.54 17.33 5.53 15 4.59V5C15 6.1 14.1 7 13 7H11V9C11 9.55 10.55 10 10 10H8V12H10V15H9L4.21 10.21C4.08 10.79 4 11.38 4 12C4 16.41 7.59 20 12 20Z"
                    fill={props.fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_2156_3420">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default WorldSearchIcon
