import { useMemo } from "react"
import { useTranslate } from "@tolgee/react"
import TableWidget, {
    ITableWidgetColumn,
    ITableWidgetProps,
} from "../../../../../climateui/components/Widgets/Table/TableWidget"
import { IRiskProfileAssetMetadata, IVariety } from "../../../../../types"
import { formatConditionDescription } from "../../../Alerts/utils"
import { useRiskSettingAssetData } from "../../../../../hooks/useStageRiskProfileAssetDataQuery"
import { useRiskSettingData } from "../../../../../hooks/useRiskSettingStageRiskProfileQuery"
const numFormatter = Intl.NumberFormat(undefined, {
    maximumFractionDigits: 1,
})

function AlertSummaryTableWidget(props: ITableWidgetProps) {
    const {
        data,
        columns,
        title,
        loading,
        empty,
        error,
        reload,
        isPaginated = true,
        errorMessage,
        reloadMessage,
        noResultsMessage,
        tryAnotherFilterMessage,
    } = props
    const { t } = useTranslate()

    const { data: riskSettingData } = useRiskSettingData(
        data?.map((d) => d.processing_run.risk_setting_id) ?? []
    )
    const { data: riskSettingAssetData, isLoading: riskSettingAssetDataLoading} = 
        useRiskSettingAssetData(riskSettingData)

    const constructVarietyDescription = (assetMetadata: IRiskProfileAssetMetadata) => {
        if(!assetMetadata) return "No asset associated"
        const assetName = assetMetadata.stage?.variety?.asset?.name ?? '';
        const varietyName = assetMetadata.stage?.variety?.name ?? '';
        return `${assetName} (${varietyName})`;
    }

    const _data: any[] = useMemo(() => {
        if (!data || !riskSettingAssetData) return []
        
        const processedData: any[] = []
        
        data.forEach((result) => {
            const alertRiskProfile = result.processing_run.risk_profile
            if (!alertRiskProfile) return
                        
            const varietyLinkedToAlert = riskSettingAssetData[result.processing_run.risk_setting_id]
            const varietyDescription = constructVarietyDescription(varietyLinkedToAlert)

            const hazardProfilesDescription =
                alertRiskProfile.hazard_profiles?.results
                    .map((profile: any) => {
                        if (!profile) return
                        const description = formatConditionDescription(
                            t,
                            alertRiskProfile?.type,
                            profile.hazard_variable.readable_name,
                            profile.conditional,
                            profile.window,
                            numFormatter.format(profile.threshold),
                            profile.hazard_variable.units
                        )
                        return description
                    })
                    .join(", ")
            const hazardProfilesIcon =
                alertRiskProfile.hazard_profiles?.results.length > 1
                    ? "stacked"
                    : alertRiskProfile.hazard_profiles?.results[0].hazard_variable
                        .dashboard_variable

            processedData.push({
                ...result,
                hazard_profiles_description: {
                    icon: hazardProfilesIcon,
                    description: hazardProfilesDescription,
                },
                variety_description: varietyDescription
            })
        })

        return processedData
    }, [data, riskSettingAssetData, riskSettingAssetDataLoading, t])

    const _columns = useMemo(() => {
        if (!columns) return []
        const hazardProfilesColumn: ITableWidgetColumn = {
            propName: "variable",
            header: t("variable"),
            type: "text",
            selector: {
                icon: `{{ hazard_profiles_description.icon }}`,
                text: `{{ hazard_profiles_description.description }}`,
                delimeter: ",",
            },
        }
        const varietiesColumn: ITableWidgetColumn = {
            propName: "assets",
            header: t("assets"),
            type: "text",
            selector: {
                text: `{{ variety_description }}`,
            },
        }
        const locationColumn = columns.filter((column) =>
            column.propName.includes("location")
        )
        const riskProfileColumn = columns.filter((column) =>
            column.propName.includes("risk")
        )
        const periodColumn = columns.filter((column) =>
            column.propName.includes("period")
        )

        return [
            ...locationColumn,
            varietiesColumn,
            ...riskProfileColumn,
            hazardProfilesColumn,
            ...periodColumn,
        ]
    }, [data, columns])

    return (
        <TableWidget
            data={_data}
            columns={_columns}
            loading={loading || riskSettingAssetDataLoading}
            title={title}
            error={error}
            empty={empty}
            reload={reload}
            isPaginated={isPaginated}
            errorMessage={errorMessage}
            reloadMessage={reloadMessage}
            noResultsMessage={noResultsMessage}
            tryAnotherFilterMessage={tryAnotherFilterMessage}
        />
    )
}

export default AlertSummaryTableWidget
