const DashboardIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M19 5V7H15V5H19ZM9 5V11H5V5H9ZM19 13V19H15V13H19ZM9 17V19H5V17H9ZM21 4C21 3.44772 20.5523 3 20 3H14C13.4477 3 13 3.44772 13 4V8C13 8.55228 13.4477 9 14 9H20C20.5523 9 21 8.55228 21 8V4ZM11 4C11 3.44772 10.5523 3 10 3H4C3.44772 3 3 3.44772 3 4V12C3 12.5523 3.44772 13 4 13H10C10.5523 13 11 12.5523 11 12V4ZM21 12C21 11.4477 20.5523 11 20 11H14C13.4477 11 13 11.4477 13 12V20C13 20.5523 13.4477 21 14 21H20C20.5523 21 21 20.5523 21 20V12ZM11 16C11 15.4477 10.5523 15 10 15H4C3.44772 15 3 15.4477 3 16V20C3 20.5523 3.44772 21 4 21H10C10.5523 21 11 20.5523 11 20V16Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default DashboardIcon
