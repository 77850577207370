const UploadIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18Z"
                fill={props.fill}
            />
            <path
                d="M7 9L8.41 10.41L11 7.83L11 16L13 16L13 7.83L15.59 10.41L17 9L12 4L7 9Z"
                fill={props.fill}
            />
        </svg>
    )
}
export default UploadIcon
