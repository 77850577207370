const ChevronBarIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M16.59 5.59L12 10.18L7.41 5.59L6 7L12 13L18 7L16.59 5.59ZM6 18L6 16L18 16L18 18L6 18Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default ChevronBarIcon
