import { ILabel } from "../../types"
import Chip from "../Chip"

export interface LabelItemProps {
    label: ILabel
    onDelete?: (label: ILabel) => void
    onClick?: () => void
}

function LabelItem({ label, onDelete, onClick }: LabelItemProps) {
    const { name, color } = label

    return (
        <Chip
            value={("#" + name).toLowerCase()}
            onRemove={onDelete ? () => onDelete(label) : undefined}
            onClick={onClick}
            color={color}
        />
    )
}

export default LabelItem
