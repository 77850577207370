import * as React from "react"
import { SVGProps } from "react"

const MinTemperatureIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-inherit"
        viewBox="0 0 24 24"
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 13V5a3 3 0 0 0-6 0v8a5 5 0 1 0 6 0Zm-3-9a1 1 0 0 0-1 1v9.17A3.001 3.001 0 0 0 12 17h6a3.001 3.001 0 0 0-2-2.83V5a1 1 0 0 0-1-1Z"
            fill={props.fill}
        />
        <path
            d="M10 9H4v2h6V9Z"
            fill={props.fill}
        />
    </svg>
)

export default MinTemperatureIcon
