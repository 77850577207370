import { useContext, useMemo, useState } from "react"
import { CancelIcon } from "../../../../../climateui/icons"
import EmptyModal from "../../../../../climateui/providers/Modal/EmptyModal"
import { useTranslate } from "@tolgee/react"
import {
    Button,
    DebounceSearchInput,
    MultiSelectFilter,
    Table,
} from "../../../../../climateui/components"
import { buildLocationColumns } from "../../../Locations/adminLocationsTableUtils"
import { LocationsContext } from "../../../../../providers/LocationsProvider"
import {
    AlertSettingInput,
    IInsightsLocation,
} from "../../../../../types"
import { useMutation } from "react-query"
import queryClient, {
    alertSettingsQuerySet,
} from "../../../../../utils/networking"
import { IPrimitivesDictionary } from "../../../../../climateui/types"
import { PRODUCT_IDS } from "../../../../../utils/constants"
import { useAccount } from "../../../../../providers"

const columns = buildLocationColumns(["selection", "name", "region", "labels"])
const LocationSelectionModal = ({
    open,
    riskProfileId,
    onClose: _onClose,
}: {
    open: boolean
    riskProfileId?: string
    onClose: () => void
}) => {
    /* HOOKS > START */
    const { locations, locationRegions } = useContext(LocationsContext)
    const { selectedAccount } = useAccount()
    const { t } = useTranslate()
    /* HOOKS < END */

    /* NETWORKING > START */
    const { mutateAsync: addAlerts } = useMutation(
        (payloads: AlertSettingInput[]) =>
            alertSettingsQuerySet.post(
                "//",
                // TODO: Update generic method to accept arrays as well
                payloads as unknown as IPrimitivesDictionary
            )
    )
    const locationLabels = useMemo(() => {
        const labels: Record<string, string> = {}
        locations.forEach((location) => {
            location.labels.forEach((_label) => {
                if (typeof _label === "string") return
                const label = _label
                if (!label.id) return
                if (labels[label.id]) return
                labels[label.id] = label.name
            })
        })
        return labels
    }, [locations])
    /* NETWORKING < END */

    /* STATE > START */
    const [rowSelection, setRowSelection] = useState({})
    const [globalFilter, setGlobalFilter] = useState("")
    const [columnFilters, setColumnFilters] = useState<
        { id: string; value: string[] | unknown }[]
    >([])
    const [addingNewSettings, setAddingNewSettings] = useState(false)
    /* STATE > FILTERS > START */
    const [labelFilter, setLabelFilter] = useState<{ [key: string]: boolean }>()
    const [regionFilter, setRegionFilter] = useState<{
        [key: string]: boolean
    }>()
    /* STATE > FILTERS < END */
    /* STATE > REGIONS > START */
    /* STATE > REGIONS < END */
    /* STATE < END */

    /* UTILS > START */
    const onClose = () => {
        setRowSelection({})
        return _onClose()
    }
    const createAlerts = () => {
        setAddingNewSettings(true)
        const rowSet = new Set(Object.keys(rowSelection))
        const selectedLocations = locations.filter((_, idx) => {
            return rowSet.has(`${idx}`)
        })
        const payloads = selectedLocations.reduce(
            (prev: AlertSettingInput[], curr: IInsightsLocation) => {
                if (!curr.id || !riskProfileId) return prev
                return [
                    ...prev,
                    {
                        risk_profile_id: riskProfileId,
                        location_id: curr.id,
                        product_id: PRODUCT_IDS.ALERT_SETTINGS,
                        status: "active",
                        start_date: "01-01",
                        end_date: "12-31",
                    },
                ]
            },
            []
        )
        addAlerts(payloads).then(() => {
            setAddingNewSettings(false)
            queryClient.invalidateQueries(["locations"])
            queryClient.invalidateQueries(["riskProfiles", selectedAccount])
            onClose()
        })
    }
    /* UTILS < END */

    return (
        <EmptyModal
            open={open}
            customStyle={{
                maxHeight: "90vh",
            }}>
            <div className="flex flex-col divide-y divide-gray-14 h-[100%] overflow-y-hidden">
                {/* HEADER > START */}
                <div className="flex flex-row items-center justify-between px-3 py-2 title-sm grow-0 max-h-[20%]">
                    <h2>{t("selectLocations", "Select Locations")}</h2>
                    <div className="flex flex-row items-center w-[45%]">
                        <span className="w-full">
                            <DebounceSearchInput
                                ms={200}
                                onSearch={setGlobalFilter}
                                onSubmit={setGlobalFilter}
                                placeholder={t("search")}
                            />
                        </span>
                        <span
                            role="button"
                            tabIndex={0}
                            onClick={onClose}
                            className="ml-3 cursor-pointer w-7 h-7 fill-gray-60">
                            <CancelIcon />
                        </span>
                    </div>
                </div>
                {/* HEADER < END */}
                {/* BODY > START */}
                <div className="grow flex flex-col h-[70%] overflow-hidden">
                    {/* BODY > FILTERS > START */}
                    <div className="flex flex-row p-3 relative max-h-[20%] grow-0">
                        {/* TODO: Use plural version of i18n */}
                        <span className="mr-1">
                            <MultiSelectFilter
                                filterName={t("region")}
                                leftRightClass="left-0"
                                options={
                                    locationRegions as Record<string, string>
                                }
                                setSelected={(selection) => {
                                    // TODO: I believe that the list of columns will be ever growing if we just
                                    // keep concatenating new filter definitions. My guess is that internally
                                    // the Tanstack table just pays attention to the IDs but there should be a better
                                    // way.

                                    setRegionFilter(selection)
                                    if (Object.keys(selection).length > 0) {
                                        setColumnFilters([
                                            ...columnFilters,
                                            {
                                                id: "region",
                                                value: Object.keys(selection),
                                            },
                                        ])
                                    } else {
                                        setColumnFilters([
                                            ...columnFilters,
                                            {
                                                id: "region",
                                                value: [],
                                            },
                                        ])
                                    }
                                }}
                                selected={regionFilter}
                                placeholder={t("region", {
                                    count: 2,
                                })}
                                canSearch={true}
                                searchPlaceholder={t("search")}
                                disabled={
                                    !(
                                        locationRegions &&
                                        Object.keys(locationRegions).length > 0
                                    )
                                }
                            />
                        </span>
                        <span className="ml-1">
                            <MultiSelectFilter
                                filterName={t("label")}
                                leftRightClass="left-0"
                                options={locationLabels}
                                setSelected={(selection) => {
                                    // TODO: I believe that the list of columns will be ever growing if we just
                                    // keep concatenating new filter definitions. My guess is that internally
                                    // the Tanstack table just pays attention to the IDs but there should be a better
                                    // way.
                                    setLabelFilter(selection)
                                    if (Object.keys(selection).length > 0) {
                                        setColumnFilters([
                                            ...columnFilters,
                                            {
                                                id: "labels",
                                                value: Object.keys(selection),
                                            },
                                        ])
                                    } else {
                                        setColumnFilters([
                                            ...columnFilters,
                                            {
                                                id: "labels",
                                                value: [],
                                            },
                                        ])
                                    }
                                }}
                                selected={labelFilter}
                                canSearch={true}
                                placeholder={t("label", {
                                    count: 2,
                                })}
                                searchPlaceholder={t("search")}
                                disabled={
                                    !(
                                        locationLabels &&
                                        Object.keys(locationLabels).length > 0
                                    )
                                }
                            />
                        </span>
                    </div>
                    {/* BODY > FILTERS < END */}
                    {/* BODY > TABLE > START */}
                    <div className="px-3 overflow-hidden grow">
                        <Table
                            data={locations}
                            columns={columns}
                            state={{
                                rowSelection,
                                globalFilter,
                                columnFilters,
                                hiddenColumns: [],
                            }}
                            setRowSelection={setRowSelection}
                            setGlobalFilter={setGlobalFilter}
                            setColumnFilters={setColumnFilters}
                        />
                    </div>
                    {/* BODY > TABLE < END */}
                </div>
                {/* BODY < END */}
                {/* FOOTER > START */}
                <div className="flex flex-row items-center justify-end flex-1 p-3 grow-0">
                    <Button
                        label={t("continue", "Continue")}
                        onClick={() => createAlerts()}
                        disabled={
                            Object.keys(rowSelection).length === 0 ||
                            addingNewSettings
                        }
                    />
                </div>
                {/* FOOTER < END */}
            </div>
        </EmptyModal>
    )
}
export default LocationSelectionModal
