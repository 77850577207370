import { useState } from "react"
import WatchIcon from "../../../../../climateui/icons/WatchIcon"
import WarningIcon from "../../../../../climateui/icons/WarningIcon"
import { useTranslate } from "@tolgee/react"
import CategoryCard, { ITextSegment } from "../CategoryCard"

export interface IAlertCategoriesCardStyles {
    backgroundColor: string
    color: string
    borderColor: string
    iconComponent: JSX.Element
    iconCard: JSX.Element
    description: ITextSegment[]
    borderRadius: string
}

const CategorySelector = ({
    selectedCategory,
    handleSelectCategory,
}: {
    selectedCategory: string
    handleSelectCategory: (category: string) => void
}) => {
    const { t } = useTranslate()
    const [showHoverCategory, setShowHoverCategory] = useState("")

    const alertCategoriesStyles: Record<string, IAlertCategoriesCardStyles> = {
        warning: {
            backgroundColor: "#FCE9EB",
            color: "#BF1728",
            borderColor: "#F4A7AF",
            iconComponent: <WarningIcon />,
            iconCard: <WarningIcon fill="#BF1728" />,
            description: [
                { text: t("A Warning is an "), isBold: false },
                { text: t("extreme weather "), isBold: true },
                { text: t("event with a "), isBold: false },
                { text: t("high likelihood "), isBold: true },
                { text: t("of happening."), isBold: false },
            ],
            borderRadius: "4px 0px 0px 4px",
        },
        watch: {
            backgroundColor: "#FFF8E5",
            color: "#CC9300",
            borderColor: "#FEE299",
            iconCard: <WatchIcon fill="#CC9300" />,
            iconComponent: <WatchIcon />,
            description: [
                { text: t("A Watch is a "), isBold: false },
                { text: t("moderate weather "), isBold: true },
                { text: t("event with a "), isBold: false },
                {
                    text: t("medium-high likelihood "),
                    isBold: true,
                },
                { text: t("of happening."), isBold: false },
            ],
            borderRadius: "0px 4px 4px 0px",
        },
    }

    const handleButtonClick = (category: string) => {
        handleSelectCategory(category)
    }

    return (
        <div className="relative flex items-center">
            {["warning", "watch"].map((category) => {
                if (!selectedCategory) return
                return (
                    <div key={category}>
                        <button
                            className="flex gap-x-1 text-[#666D74] font-roboto text-sm font-medium py-[5px] pl-[6px] pr-[10px] border border-solid border-gray-14"
                            style={{
                                borderRadius:
                                    alertCategoriesStyles[category]
                                        .borderRadius,
                                borderColor:
                                    selectedCategory === category
                                        ? alertCategoriesStyles[
                                              selectedCategory
                                          ].borderColor
                                        : "#DBDDDF",
                                color:
                                    selectedCategory === category
                                        ? alertCategoriesStyles[
                                              selectedCategory
                                          ].color
                                        : "#666D74",
                                backgroundColor:
                                    selectedCategory === category
                                        ? alertCategoriesStyles[
                                              selectedCategory
                                          ].backgroundColor
                                        : "white",
                            }}
                            onMouseEnter={() => setShowHoverCategory(category)}
                            onMouseLeave={() => setShowHoverCategory("")}
                            onClick={() => handleButtonClick(category)}>
                            <span
                                className="w-[20px] h-[20px]"
                                style={{
                                    fill:
                                        selectedCategory === category
                                            ? alertCategoriesStyles[
                                                  selectedCategory
                                              ].color
                                            : "#666D74",
                                }}>
                                {alertCategoriesStyles[category].iconComponent}
                            </span>
                            <h1>{t(category)}</h1>
                        </button>
                        {showHoverCategory &&
                            showHoverCategory === category && (
                                <div
                                    style={{
                                        position: "absolute",
                                        left: 0,
                                        top: "100%",
                                        zIndex: 1000,
                                        marginTop: "8px",
                                    }}>
                                    <CategoryCard
                                        icon={
                                            alertCategoriesStyles[
                                                showHoverCategory
                                            ].iconCard
                                        }
                                        title={t(category)}
                                        description={
                                            alertCategoriesStyles[
                                                showHoverCategory
                                            ].description
                                        }
                                    />
                                </div>
                            )}
                    </div>
                )
            })}
        </div>
    )
}

export default CategorySelector
