import { useTranslate } from "@tolgee/react"
import { useContext, useEffect, useMemo } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { IStep } from "../../climateui/components/Steps"
import { useMutation } from "react-query"
import {
    BoxIcon,
    LocationPinIcon,
    WarnTriangleIcon,
} from "../../climateui/icons"
import { ModalContext } from "../../climateui/providers"
import FullScreen from "../../components/FullScreen"
import { StepsNavigationLayout } from "../../layouts"
import { useAuth } from "../../providers"
import { useToast } from "../../climateui/providers/Toast/ToastContextProvider"
import { useAccount } from "../../providers/AccountProvider"
import { assetDependentRoutes } from "./routes"
import queryClient, { accountIsOnboardedPOST } from "../../utils/networking"

const STEPS = [
    {
        tolgeeKey: "assets",
        icon: <BoxIcon />,
    },
    {
        tolgeeKey: "riskProfiles",
        icon: <WarnTriangleIcon />,
    },
    {
        tolgeeKey: "locations",
        icon: <LocationPinIcon />,
    },
]
const allowedPathTerminations = ["onboarding", "risk-profiles", "locations"]

function OnboardingFlow() {
    const { t } = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()
    const { selectedAccount, accountsObject } = useAccount()
    const auth = useAuth()
    const { confirmationModal } = useContext(ModalContext)
    const { enqueueAlert } = useToast()

    const steps = useMemo(() => {
        const steps: IStep[] = []
        STEPS.forEach((step) => {
            steps.push({
                name: t(step.tolgeeKey),
                icon: step.icon,
            })
        })
        return steps
    }, [])

    useEffect(() => {
        const paths = location.pathname.split("/")
        const lastPath = paths[paths.length - 1]
        // => /onboarding/SOME_ASSET_ID
        if (paths.length === 3 && !allowedPathTerminations.includes(lastPath))
            navigate(lastPath + "/risk-profiles")
        // => /onboarding/SOME_ASSET_ID/
        if (paths.length === 4 && lastPath === "")
            navigate(paths[paths.length - 2] + "/risk-profiles")
    }, [])

    const currentStep = useMemo(() => {
        let step = 0
        const paths = location.pathname.split("/")
        const lastPath = paths[paths.length - 1]
        assetDependentRoutes.forEach((route, index) => {
            if (route.path === lastPath) {
                step = index + 1
            }
        })
        return step
    }, [location])

    const account = useMemo(() => {
        if (!selectedAccount || !accountsObject) return undefined

        return accountsObject[selectedAccount]
    }, [selectedAccount, accountsObject])

    const { mutateAsync: markUserAsOnboarded } = useMutation(
        () => accountIsOnboardedPOST(selectedAccount as string, true),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["accounts"]).then(() => navigate('/admin'))
            },
            onError: () => {
                enqueueAlert(
                    t(
                        "thereWasAnErrorMarkingUserAsOnboarded",
                        "There was a problem while processing your onboarding. Please try again."
                    )
                )
            },
        }
    )

    return (
        <FullScreen>
            <StepsNavigationLayout
                steps={steps}
                currentStep={currentStep}
                left={
                    <div className="w-36">
                        <img src="/images/climateai/ClimateAi-new-logo.png" />
                    </div>
                }
                onCancel={
                    account?.is_onboarded
                        ? () =>
                              confirmationModal({
                                  title: t("goOutOfOnboardingModalTitle"),
                                  text: t("goOutOfOnboardingModalText"),
                                  onContinue: () => navigate("/admin"),
                                  onCancelLabel: t("cancel"),
                                  onContinueLabel: t("goOut"),
                              })
                        : () => markUserAsOnboarded()
                }>
                <Outlet />
            </StepsNavigationLayout>
        </FullScreen>
    )
}

export default OnboardingFlow
