import { PrimitiveAny } from "../climateui/types"

export type FilterCondition = {
    field_name: string
    operator: string
    field_value?: PrimitiveAny | PrimitiveAny[] | FilterCondition
}
export type IFilter = {
    filter_by: {
        and?: FilterCondition[]
        or?: FilterCondition[]
    }
}

export const andFilterPayload = (
    fieldName: string,
    operator: string,
    value?: PrimitiveAny | PrimitiveAny[],
    prevFilter?: IFilter
) => {
    let filter: IFilter = {
        filter_by: {},
    }
    if (prevFilter) filter = { ...prevFilter }
    if (!filter.filter_by.and) filter.filter_by.and = []
    const _tmp = new Set<FilterCondition>(filter.filter_by.and)
    _tmp.add({
        field_name: fieldName,
        operator: operator,
        field_value: value,
    })
    filter.filter_by.and = Array.from(_tmp)
    return filter
}

// TODO: Create an auxiliary method to avoid code duplication
export const orFilterPayload = (
    fieldName: string,
    operator: string,
    value?: PrimitiveAny | PrimitiveAny[],
    prevFilter?: IFilter
) => {
    let filter: IFilter = {
        filter_by: {},
    }
    if (prevFilter) filter = { ...prevFilter }
    if (!filter.filter_by.or) filter.filter_by.or = []
    const _tmp = new Set<FilterCondition>(filter.filter_by.or)
    _tmp.add({
        field_name: fieldName,
        operator: operator,
        field_value: value,
    })
    filter.filter_by.or = Array.from(_tmp)
    return filter
}
