import { ReactNode, useContext, useMemo } from "react"
import { DateTime } from "luxon"
import { DatePickerContext, DateValidatorFn } from "./DatePicker"
import TooltipV2 from "../TooltipV2"
import { isWithinWeekdayHoveredRange } from "./utils"
import { LUXON_VIEW_MODE_KEY } from "."

export interface ICalendarCell {
    date?: DateTime
    isHeader?: boolean
    current?: boolean
    disabled?: boolean
    onClick?: (cell: ICalendarCell) => void
    label?: ReactNode
    size?: {
        width: number | string
        height: number | string
    }
    validator?: DateValidatorFn
    invalidMessage?: string
}

const CalendarCell = (props: ICalendarCell) => {
    const {
        size,
        date,
        label,
        isHeader,
        current,
        disabled,
        onClick,
        validator,
        invalidMessage,
    } = props
    const {
        selectedDate,
        endDate,
        range,
        rangeWeekdayStart,
        currHoverDate,
        setHoverDate,
        canGoUp,
        viewMode,
    } = useContext(DatePickerContext)

    const isEnabled = useMemo(() => {
        if (disabled) return false
        if (!validator) return true
        return validator(date, {
            range,
            rangeWeekdayStart,
        })
    }, [disabled, validator, date, range, rangeWeekdayStart])

    const isSelected = (() => {
        const matchStartDate = selectedDate && date?.equals(selectedDate)
        const matchEndDate =
            !rangeWeekdayStart && endDate && date?.equals(endDate)
        return matchStartDate ?? matchEndDate
    })()
    const getIsWithinSelectedRange = () => {
        if (!selectedDate || !endDate || !date) return false
        if (selectedDate <= date && date <= endDate) return true
        if (!viewMode) return false
        const diffKey = LUXON_VIEW_MODE_KEY[viewMode + 1]
        if (!diffKey) return false
        const projectedDate = date.plus({ [diffKey]: 1 })
        return (
            !canGoUp &&
            selectedDate <= projectedDate &&
            projectedDate <= endDate
        )
    }
    const isWithinSelectedRange = getIsWithinSelectedRange()
    const isWithinHoveredRange =
        !rangeWeekdayStart &&
        typeof range === "number" &&
        currHoverDate &&
        date &&
        currHoverDate <= date &&
        date < currHoverDate.plus({ days: range })

    let classes = [
        "p-2",
        "rounded",
        "flex",
        "flex-col",
        "leading-1",
        "items-center",
        "justify-center",
        "border-[1px]",
        size ? `w-${size.width} h-${size.height}` : "w-8 h-8",
        isSelected ? "text-white" : "",
        isSelected && isEnabled ? "bg-accent" : "",
        isSelected && !isEnabled ? "bg-gray-30" : "",
        !isSelected && isWithinSelectedRange && isEnabled
            ? "bg-accent-light"
            : "",
        isWithinSelectedRange && !isEnabled ? "bg-gray-3" : "",
        isHeader ? "text-gray-30 cursor-default" : "",
        label && isEnabled && !isHeader ? "hover:border-accent" : "",
        current && !isSelected ? "text-accent" : "",
        !isHeader && !isEnabled ? "cursor-not-allowed text-gray-14" : "",
    ]
    if (
        isEnabled &&
        !isSelected &&
        (isWithinHoveredRange ||
            isWithinWeekdayHoveredRange({
                range,
                rangeWeekdayStart,
                hoveredDate: currHoverDate,
                currDate: date,
            }))
    ) {
        classes.push("border-gray-14 rounded-0")
    } else {
        classes.push("border-transparent")
    }

    return (
        <TooltipV2
            doShow={!isEnabled && !!invalidMessage}
            position="top"
            content={invalidMessage}>
            <button
                tabIndex={0}
                className={classes.join(" ")}
                onMouseEnter={() => {
                    if (!isEnabled) return
                    setHoverDate(date)
                }}
                onMouseLeave={() => {
                    if (!isEnabled) return
                    setHoverDate(undefined)
                }}
                onClick={() => {
                    if (!onClick || !isEnabled) return
                    onClick(props)
                }}>
                {label}
            </button>
        </TooltipV2>
    )
}
export default CalendarCell
