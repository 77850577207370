import * as React from "react"
import { SVGProps } from "react"

const MeanPrecipitationIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-inherit"
        viewBox="0 0 25 24"
        {...props}>
        <path
            d="M17.89 8.43A5.503 5.503 0 0 0 12.5 4c-2.06 0-3.92 1.14-4.87 2.97l-.5.95-1.07.11A3.973 3.973 0 0 0 2.5 12a4 4 0 0 0 2.34 3.64l-.49 1.962A6.008 6.008 0 0 1 .5 12c0-3.09 2.34-5.64 5.35-5.96A7.496 7.496 0 0 1 12.5 2a7.49 7.49 0 0 1 7.35 6.04c2.6.18 4.65 2.32 4.65 4.96 0 2.76-2.24 5-5 5h-1.25l.5-2h.75c1.65 0 3-1.35 3-3a2.98 2.98 0 0 0-2.78-2.96l-1.53-.11-.3-1.5Z"
            fill={props.fill}
        />
        <path
            d="M15.5 21h-2l2-8h2l-2 8ZM13.5 13h-2l-2 8h2l2-8ZM7.5 21h-2l2-8h2l-2 8Z"
            fill={props.fill}
        />
    </svg>
)

export default MeanPrecipitationIcon
