import { useTranslate } from "@tolgee/react"
import { useContext, useEffect, useState } from "react"
import Table from "../../../climateui/components/Table/Table"
import { usePaginationFlag } from "../../../hooks"
import { useAccount } from "../../../providers/AccountProvider"
import { LocationsContext } from "../../../providers/LocationsProvider"
import { IInsightsLocation } from "../../../types"
import { buildLocationColumns } from "../../Admin/Locations/adminLocationsTableUtils"
import LocationsTableFilters from "../../Admin/Locations/components/LocationsTableFilters"
import { explorationToolLocationsColumns } from "./explorationUtils"

const columns = buildLocationColumns(explorationToolLocationsColumns)

export default function ExplorationToolLocations() {
    const { t } = useTranslate()
    const { locations, loadingLocations, tableFilteredCallback } =
        useContext(LocationsContext)
    const { selectedAccount } = useAccount()

    const [globalFilter, setGlobalFilter] = useState("")
    const [rowSelection, setRowSelection] = useState({})
    const [columnFilters, setColumnFilters] = useState<
        { id: string; value: string[] | unknown }[]
    >([])

    const [doTableFilterCallbackCount, setDoTableFilterCallbackCount] =
        useState(0)
    useEffect(() => {
        // INFO: doTableFilterCallbackCount is useful to update the map pins
        // to what the table is showing. Maybe this could be done less erizo.
        // TOIMPROVE
        setDoTableFilterCallbackCount(doTableFilterCallbackCount + 1)
    }, [locations, globalFilter, columnFilters])

    const pageSizeOpts = usePaginationFlag({
        rowTolgeeKey: "locations",
    })

    return (
        <div className="flex flex-col w-full h-full p-4 border-t border-gray-14 gap-3">
            <div className="flex flex-row flex-wrap items-center justify-between gap-1 shrink-0 grow-0">
                <div className="flex flex-row flex-wrap items-center gap-1">
                    <LocationsTableFilters
                        setColumnFilters={setColumnFilters}
                        columnFilters={columnFilters}
                        showAccountFilter={false}
                        showAssetFilter
                    />
                </div>

                {/*
                <LocationsCount
                    count={locations.length}
                />
                */}
            </div>
            <div className="overflow-y-auto grow">
                <Table<IInsightsLocation>
                    data={locations}
                    columns={columns}
                    state={{
                        rowSelection,
                        globalFilter,
                        columnFilters,
                        hiddenColumns: ["account"],
                    }}
                    getRowId={(
                        location: IInsightsLocation,
                        index: number
                    ) => location?.id || index.toString()}
                    setRowSelection={setRowSelection}
                    setGlobalFilter={setGlobalFilter}
                    setColumnFilters={setColumnFilters}
                    rowSelectionFilter={(row) => {
                        return row.original.account_id === selectedAccount
                    }}
                    noDataMessage={
                        loadingLocations
                            ? t("loadingLocations", "Loading locations...")
                            : t(
                                "thereAreNoLocationsClickOnNewLocation",
                                "There are no locations, click on New location to add one."
                            )
                    }
                    extraClasses=""
                    paginationOptions={pageSizeOpts}
                    tableFilteredCallback={tableFilteredCallback}
                    doTableFilterCallbackCount={doTableFilterCallbackCount}
                />
            </div>
        </div>
    )
}
