import { ReactNode } from "react"

function ProgressBar({
    sections,
    progress = 0,
    widthClass = "w-full",
    transitionClass = "transition-all",
    content = null,
}: {
    sections: number
    progress: number
    widthClass?: string
    transitionClass?: string
    content?: ReactNode
}) {
    progress = Math.min(progress, sections)
    let orangeBarWidth = (100 / sections) * progress
    orangeBarWidth = Math.min(orangeBarWidth, 100)

    return (
        <div className={`relative flex flex-row items-center justify-between ${widthClass} h-1 group bg-gray-14`}>
            <div
                className={`absolute left-0 h-1 bg-accent ${transitionClass}`}
                style={{ width: orangeBarWidth + "%" }}></div>
            {content}
        </div>
    )
}

export default ProgressBar
