import { useTranslate } from "@tolgee/react"
import { gql } from "graphql-request"
import { useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import {
    ArrayParam,
    BooleanParam,
    StringParam,
    useQueryParam,
    withDefault,
} from "use-query-params"
import {
    Checkbox,
    MultiSelectFilter,
    SingleSelectFilter,
} from "../../../../climateui/components"
import WidgetWrapper from "../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import { GenericPageHeader } from "../../../../components"
import { useAccount, useAssets } from "../../../../providers"
import { IRegion } from "../../../../types"
import { useYieldOutlook } from "../../YieldOutlook/provider"
import { RegionalYieldOutlookTableWidget } from "../../../../components/YieldOutlook/YieldOutlookTableWidget"
import YieldOutlookMap from "../../../../components/YieldOutlook/YieldOutlookMap"
import YieldOutlookSummary from "../../../../components/YieldOutlook/YieldOutlookSummary"
import YieldOutlookChange from "../../../../components/YieldOutlook/YieldOutlookChange"
import { SingleDropdownSelect } from "../../../../climateui/components/Inputs"
import OutlookDateSelector from "../../../../components/YieldOutlook/OutlookDateSelector"
import { DateTime } from "luxon"
import PastReportsSwitch from "../components/PastReportsSwitch"
import YieldOutlookStage from "../../../../components/YieldOutlook/YieldOutlookStage"
import RollingOutlookTable from "../../../../components/YieldOutlook/RollingOutlookTable"
import useYieldUnits from "../../../../hooks/YieldOutlook/useYieldUnits"
import { NOON } from "../../../../utils/dates"
import { DateValidatorFn } from "../../../../climateui/components/DatePicker/DatePicker"
import {
    RollingOutlookData,
    YieldReportSiteCell,
} from "../../../../components/YieldOutlook/RollingOutlookTable/utils"
import { useIsFlagEnabled } from "../../../../hooks"

const getRegionsAndStatesDict = (
    availableStates: IRegion[],
    countries: Record<string, IRegion>
) => {
    const statesRegionsDict: Record<string, Record<string, string>> = {}
    const regionsDict: Record<string, string> = {}
    const countriesIdsForAssetModel = new Set()
    availableStates.forEach((state) => {
        countriesIdsForAssetModel.add(state.parent_id)
    })

    if (Object.keys(countries)?.length > 0) {
        Object.keys(countries).forEach((country) => {
            if (country && countriesIdsForAssetModel.has(country)) {
                regionsDict[country] = countries[country].name
            }
        })
    }

    if (availableStates?.length > 0) {
        availableStates.forEach((region: IRegion) => {
            if (region.id && region.parent_id) {
                if (!statesRegionsDict[region.parent_id]) {
                    statesRegionsDict[region.parent_id] = {}
                }
                statesRegionsDict[region.parent_id][region.id] = region.name
            }
        })
    }

    return { statesRegionsDict, regionsDict }
}

const YieldOutlookDashboard = () => {
    const { t } = useTranslate()
    const { selectedAccount } = useAccount()
    const { allAssets } = useAssets()
    const { assetId } = useParams()
    const assetModelName = allAssets?.[assetId as string]?.name

    const { states, assetModels, countries, isLoading } = useYieldOutlook()

    const selectedAssetModels = useMemo(() => {
        if (!assetId || !assetModels) return []
        return assetModels.filter((model) => model.asset_id === assetId)
    }, [assetId, assetModels])

    const { statesRegionsDict, regionsDict } = useMemo(() => {
        if (selectedAssetModels.length === 0)
            return { statesRegionsDict: {}, regionsDict: {} }

        // Create a set of just the region ids
        const selectedModelsRegionIDs = new Set(
            selectedAssetModels.map((model) => model.region_id)
        )

        return getRegionsAndStatesDict(
            states.filter(
                (state) => state.id && selectedModelsRegionIDs.has(state.id)
            ),
            countries
        )
    }, [selectedAssetModels, states, countries])

    /*
     * Will be used later when adding schedules to Yield Dashboards :)
     * const yieldOutlookURL = new URL(window.location.href)
     * const urlParams = new URLSearchParams(yieldOutlookURL.search)
     */

    const regionParam = withDefault(StringParam, Object.keys(regionsDict)[0])
    const previouslySelectedRegion = useRef<string | undefined>(undefined)
    const [selectedRegion, setSelectedRegion] = useQueryParam(
        "region",
        regionParam
    )

    // TEMPORARY
    const isNewDirectionalityToggleEnabled = !!useIsFlagEnabled(
        "experiment_yield_outlook_new_directionality_logic"
    )
    const [toggleNewDirectonalityLogic, setToggleNewDirectonalityLogic] =
        useState(!!isNewDirectionalityToggleEnabled)

    const statesParam = withDefault(ArrayParam, [])
    const [_selectedStates, setSelectedStates] = useQueryParam(
        "state",
        statesParam
    )

    const selectedStatesIDs =
        _selectedStates.length === 0 && statesRegionsDict[selectedRegion]
            ? Object.keys(statesRegionsDict[selectedRegion])
            : _selectedStates

    // The asset model that belongs to the selected parent region, i.e. country
    const selectedRegionAssetModel = selectedAssetModels.find(
        ({ region_id }) => region_id === selectedRegion
    )
    // Set the previouslySelectedRegion if not set
    useEffect(() => {
        if (!previouslySelectedRegion.current) {
            previouslySelectedRegion.current = selectedRegion
        }
    }, [selectedRegion])

    const dateParam = withDefault(
        StringParam,
        selectedRegionAssetModel?.newest_seasonal_date ??
            DateTime.now().set(NOON).toUTC().startOf("week").toISODate()
    )
    const [date, setDate] = useQueryParam("date", dateParam)

    // Set the date to the newest available data based on the selected region
    // asset model
    useEffect(() => {
        // Exit if there hasn't been a region (country) change
        if (
            !previouslySelectedRegion.current ||
            previouslySelectedRegion.current === selectedRegion
        )
            return
        setDate(selectedRegionAssetModel?.newest_seasonal_date)
    }, [selectedRegionAssetModel])

    const dateValidator: DateValidatorFn = (_date) => {
        if (!selectedRegionAssetModel || !_date) return false

        // These init_time string dates are assumed to be in
        // ISO Date format, i.e. yyyy-MM-dd
        const {
            oldest_seasonal_date: oldestDateStr,
            newest_seasonal_date: newestDateStr,
        } = selectedRegionAssetModel

        const utcDate = _date.set(NOON).toUTC()

        let isBeyondOldest = true
        let isBeforeNewest = true

        if (oldestDateStr) {
            isBeyondOldest =
                DateTime.fromISO(oldestDateStr, {
                    zone: "utc",
                }) <= utcDate
        }
        if (newestDateStr) {
            isBeforeNewest =
                utcDate <
                DateTime.fromISO(newestDateStr, {
                    zone: "utc",
                }).plus({ weeks: 1 }) // adding a week since, init_time is the start of the week
        }
        return isBeyondOldest && isBeforeNewest
    }
    const pastReportsParam = withDefault(BooleanParam, false)
    const [showPastReports, setShowPastReports] = useQueryParam(
        "past",
        pastReportsParam
    )

    useEffect(() => {
        if (!selectedAccount) return
        setSelectedStates([])
    }, [selectedAccount])

    const { unitOptions, units, setUnits } = useYieldUnits(
        assetModels ?? [],
        selectedRegion,
        assetId ?? "",
        isLoading,
        t
    )

    const filteredStates = statesRegionsDict[selectedRegion]
        ? Object.keys(statesRegionsDict[selectedRegion])
        : []
    const isSingleCountryModel = filteredStates?.[0] === selectedRegion

    const yieldDashboardFilters = useMemo(() => {
        return [
            {
                propName: "current_time",
                // validate if today is between the available data timewindow
                value:
                    (dateValidator(DateTime.now(), {}) && date) ||
                    DateTime.now().toISODate(),
            },
            {
                propName: "init_time",
                value: date,
            },
            {
                propName: "asset_ids",
                value: [assetId],
                loading: isLoading,
            },
            {
                propName: "country_id",
                value: selectedRegion,
                loading: isLoading,
            },
            {
                propName: "state_ids",
                value: selectedStatesIDs,
                loading: isLoading,
            },
            {
                propName: "units",
                value: units,
                loading: isLoading,
            },
        ]
    }, [
        selectedRegion,
        selectedStatesIDs,
        statesRegionsDict,
        assetId,
        units,
        isLoading,
    ])

    const rollingYieldOutlookTableColumns = [
        {
            id: "region",
            header: t("stateProvince", "State/Province"),
            accessorFn: (original: RollingOutlookData) =>
                original.territory.name,
            cell: YieldReportSiteCell,
        },
    ]

    return (
        <div
            className="w-full h-full p-8 overflow-y-scroll bg-gray-1.5"
            id="yield-dashboard-container">
            <GenericPageHeader
                pageTitle={
                    assetModelName && !isLoading ? (
                        <div>
                            {t("regionalYield", "{asset} Regional Yield", {
                                asset: assetModelName,
                            })}
                        </div>
                    ) : (
                        <div className="text-gray-30">
                            {t("loadingYieldDashboard")}
                        </div>
                    )
                }
            />
            <div className="mb-[14px] flex flex-wrap gap-2 align-baseline -mt-5">
                <SingleSelectFilter
                    key="region"
                    placeholder="Region"
                    leftRightClass="left-0"
                    selected={selectedRegion}
                    setSelected={(v) => {
                        setSelectedRegion(v)
                        setSelectedStates([])
                    }}
                    options={regionsDict}
                />
                {!isSingleCountryModel && (
                    <MultiSelectFilter
                        filterName={t("state")}
                        key="states"
                        searchPlaceholder="Search State"
                        canSearch
                        placeholder="State"
                        leftRightClass="left-0"
                        selected={[..._selectedStates].reduce((prev, curr) => {
                            if (curr) prev[curr] = true
                            return prev
                        }, {} as { [key: string]: boolean })}
                        setSelected={(v) => {
                            setSelectedStates(Object.keys(v))
                        }}
                        options={statesRegionsDict[selectedRegion]}
                    />
                )}
                <OutlookDateSelector
                    initialDate={DateTime.fromISO(date)}
                    onChange={(startDate) => {
                        if (!startDate) return
                        setDate(startDate.set(NOON).toUTC().toISODate())
                    }}
                    dateValidator={dateValidator}
                />
                <SingleDropdownSelect
                    options={unitOptions}
                    selected={units.replace("/", "_")}
                    setSelected={setUnits}
                />
                <PastReportsSwitch
                    selected={showPastReports}
                    setSelected={setShowPastReports}
                />
                {isNewDirectionalityToggleEnabled && (
                    <div className="ddBox flex flex-row items-center transition-all duration-75 min-w-[60px] pl-[8px] pr-[2px] w-fit h-[32px] border-[1px] rounded-sm cursor-pointer disabled:cursor-not-allowed enabled:focus:outline-accent disabled:bg-gray-5 disabled:text-gray-30 disabled:fill-gray-30  border-gray-14 hover:enabled:border-gray-30 bg-white fill-gray-60 text-gray-60">
                        <Checkbox
                            status={
                                toggleNewDirectonalityLogic ? "full" : "empty"
                            }
                            onChange={() =>
                                setToggleNewDirectonalityLogic(
                                    !toggleNewDirectonalityLogic
                                )
                            }
                        />

                        <div className="label-lg grow whitespace-nowrap">
                            New Directionality Logic
                        </div>
                    </div>
                )}
            </div>
            <div className="flex gap-[14px] mb-[14px]">
                <div className="w-3/4 bg-white border rounded-lg font-roboto border-1 border-gray-14">
                    <YieldOutlookMap
                        type="monitor"
                        filters={yieldDashboardFilters}
                        selectors={{
                            toggleNewDirectonalityLogic:
                                toggleNewDirectonalityLogic,
                        }}
                    />
                </div>
                <div className="w-1/3 grid gap-[14px] grid-cols-1 grid-rows-3">
                    <YieldOutlookSummary
                        type="monitor"
                        filters={yieldDashboardFilters}
                        selectors={{
                            title: `${t(
                                "countryYieldOutlookWidgetTitle"
                            )} - ${t("weighted", "Weighted")}`,
                            $data: "yield_outlook_model.results[0].stats",
                        }}
                    />
                    <YieldOutlookChange
                        type="monitor"
                        filters={yieldDashboardFilters}
                        selectors={{
                            $data: "yield_outlook_model.results[0].stats",
                            title: `${t("expectedChangeWidgetTitle")} - ${t(
                                "weighted",
                                "Weighted"
                            )}`,
                            units,
                        }}
                    />
                    <YieldOutlookStage
                        type="monitor"
                        filters={yieldDashboardFilters}
                        selectors={{
                            title: t("currentStage"),
                            $data: "yield_outlook_model.results[0]",
                        }}
                    />
                </div>
            </div>
            <div>
                <WidgetWrapper
                    component={RegionalYieldOutlookTableWidget}
                    selectedFilters={[
                        "state_ids",
                        "asset_ids",
                        "units",
                        "init_time",
                    ]}
                    query={gql`
                        query (
                            $state_ids: [String]
                            $asset_ids: [String]
                            $units: String
                            $init_time: String
                        ) {
                            yield_outlook_model(
                                filter: {
                                    region_ids: $state_ids
                                    asset_ids: $asset_ids
                                }
                            ) {
                                results {
                                    production_fraction_state
                                    territory: region {
                                        id
                                        name
                                        resolution
                                    }
                                    stats: seasonal_stats(
                                        filter: {
                                            units: $units
                                            init_time: $init_time
                                        }
                                    ) {
                                        results {
                                            tercile_probabilities
                                            outlook_distribution
                                            impact_drivers {
                                                name
                                                mean_impact
                                            }
                                            historical {
                                                yield_terciles
                                            }
                                            deviation_mean
                                            outlook_mean
                                        }
                                    }
                                }
                            }
                        }
                    `}
                    selectors={{
                        title: t("yieldWidgetTable"),
                        $data: "yield_outlook_model.results[]",
                        errorMessage: t("errorLoadingData"),
                        reloadMessage: t("clickReload"),
                        noResultsMessage: t("noResultCurrentFilters"),
                        tryAnotherFilterMessage: t("tryAnotherFilter"),
                        columns: [
                            {
                                // STATE/REGION
                                propName: "state",
                                header: `${t("state", "State")}/${t(
                                    "province",
                                    "Province"
                                )}`,
                                type: "text",
                                selector: {
                                    text: "{{ territory.name }}",
                                },
                                columns: [
                                    {
                                        propName: "state",
                                        type: "text",
                                    },
                                ],
                            },
                        ],
                        units,
                        toggleNewDirectonalityLogic:
                            toggleNewDirectonalityLogic,
                    }}
                    filters={yieldDashboardFilters}
                />
            </div>
            <div className="my-3">
                <RollingOutlookTable
                    filters={yieldDashboardFilters}
                    customColumns={rollingYieldOutlookTableColumns}
                    show={showPastReports}
                    outlookDate={date}
                    loading={isLoading}
                />
            </div>
        </div>
    )
}

export default YieldOutlookDashboard
