import { useTranslate } from "@tolgee/react"
import { GenericPageHeader } from "../../../../components"
import { useAccount, useAssets } from "../../../../providers"
import { useParams } from "react-router-dom"
import { gql } from "graphql-request"
import { useRiskOutlook } from "../provider"
import { SingleSelectFilter } from "../../../../climateui/components"
import RiskOverviewScoreDisplay from "../components/RiskOverviewScoreDisplay"
import RiskScoreDisplay from "../components/RiskScoreDisplay"
import { useEffect, useMemo, useRef } from "react"
import { IRegion } from "../../../../types"
import {
    ArrayParam,
    StringParam,
    useQueryParam,
    withDefault,
} from "use-query-params"
import { DateTime } from "luxon"
import WidgetWrapper from "../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import ToggleSelector from "../../../../climateui/components/Inputs/ToggleSelector"
import {
    filterStagesByCurrentDate,
    getHazardConfig,
    getStageConfig,
} from "../riskOutlookUtils"
import RiskOutlookShadedRegionsMap from "../../../../components/widgets/RiskOutlookShadedRegionsMap"
import RiskOutlookChart from "../components/RiskOutlookChart"

const getRegionsAndStatesDict = (
    availableStates: IRegion[],
    countries: Record<string, IRegion>
) => {
    const statesRegionsDict: Record<string, Record<string, string>> = {}
    const regionsDict: Record<string, string> = {}
    const countriesIdsForAssetModel = new Set()
    availableStates.forEach((state) => {
        countriesIdsForAssetModel.add(state.parent_id)
    })

    if (Object.keys(countries)?.length > 0) {
        Object.keys(countries).forEach((country) => {
            if (country && countriesIdsForAssetModel.has(country)) {
                regionsDict[country] = countries[country].name
            }
        })
    }

    if (availableStates?.length > 0) {
        availableStates.forEach((region: IRegion) => {
            if (region.id && region.parent_id) {
                if (!statesRegionsDict[region.parent_id]) {
                    statesRegionsDict[region.parent_id] = {}
                }
                statesRegionsDict[region.parent_id][region.id] = region.name
            }
        })
    }

    return { statesRegionsDict, regionsDict }
}

const riskScoresQuery = `#graphql
query (
    $assetModelId: String!
    $init_time: String!
) {
    yield_outlook_model(
        filter: {
            crop_model_ids: [$assetModelId]
        }
    ) {
        results {
            region_id
            default_geography {
                id
                hazards {
                    id
                    hazard_name
                    risk_outlook_seasonal_stats {
                        plot(
                            configs: [
                                { date: "date", y: "index_value", y1: "forecast_contribution" }
                            ]
                        ) {
                            lastValue {
                                date
                                y
                                y1
                            }
                        }
                    }
                    # TODO: Hardcoded risk bounds for now
                }
            }
            asset_id
            stages(
                filter: {
                    current_date: $init_time
                }
            ) {
                stage_name
                start_date
                end_date
            }
        }
    }
}
`

const RiskOutlookDashboard = () => {
    const { t } = useTranslate()
    const { selectedAccount } = useAccount()
    const { allAssets } = useAssets()
    const { assetId } = useParams()
    const assetModelName = allAssets?.[assetId as string]?.name

    const { states, assetModels, countries, isLoading } = useRiskOutlook()

    const selectedAssetModels = useMemo(() => {
        if (!assetId || !assetModels) return []
        return assetModels.filter((model) => model.asset_id === assetId)
    }, [assetId, assetModels])

    const { statesRegionsDict, regionsDict } = useMemo(() => {
        if (selectedAssetModels.length === 0)
            return { statesRegionsDict: {}, regionsDict: {} }

        // Create a set of just the region ids
        const selectedModelsRegionIDs = new Set(
            selectedAssetModels.map((model) => model.region_id)
        )

        return getRegionsAndStatesDict(
            states.filter(
                (state) => state.id && selectedModelsRegionIDs.has(state.id)
            ),
            countries
        )
    }, [selectedAssetModels, states, countries])

    const regionParam = withDefault(StringParam, Object.keys(regionsDict)[0])
    const previouslySelectedRegion = useRef<string | undefined>(undefined)
    const [selectedRegion, setSelectedRegion] = useQueryParam(
        "region",
        regionParam
    )

    const stateParam = withDefault(StringParam, "")
    const [selectedState, setSelectedState] = useQueryParam("state", stateParam)

    // The asset model that belongs to the selected parent region, i.e. country
    const selectedRegionAssetModel = selectedAssetModels.find(
        ({ region_id }) =>
            region_id === selectedState || region_id === selectedRegion
    )

    // Set the previouslySelectedRegion if not set
    useEffect(() => {
        if (!previouslySelectedRegion.current) {
            previouslySelectedRegion.current = selectedRegion
        }
    }, [selectedRegion])

    useEffect(() => {
        if (!selectedAccount) return
        setSelectedState("")
    }, [selectedAccount])

    const filteredStates = statesRegionsDict[selectedRegion]
        ? Object.keys(statesRegionsDict[selectedRegion])
        : []
    const isSingleCountryModel = filteredStates?.[0] === selectedRegion

    const availableHazards = useMemo(() => {
        if (!selectedRegionAssetModel?.default_geography?.hazards) return []
        return selectedRegionAssetModel.default_geography.hazards
    }, [selectedRegionAssetModel?.default_geography?.hazards])

    // Declare dashboard filters
    const dashboardFilters = useMemo(
        () => [
            {
                propName: "assetModelId",
                value: selectedRegionAssetModel?.id,
                loading: isLoading,
            },
            {
                propName: "init_time",
                value:
                    selectedRegionAssetModel?.default_geography
                        ?.newest_risk_date ??
                    DateTime.now().toUTC().toISODate(),
                loading: false,
            },
        ],
        [selectedRegionAssetModel?.id, isLoading]
    )

    const stageName = useMemo(() => {
        if (
            !selectedRegionAssetModel?.stages ||
            !selectedRegionAssetModel?.default_geography?.newest_risk_date
        ) {
            // out of season by default
            return "out_of_season"
        }

        const filteredStages = filterStagesByCurrentDate(
            selectedRegionAssetModel.stages,
            selectedRegionAssetModel.default_geography.newest_risk_date
        )
        return filteredStages[0]?.stage_name
    }, [
        selectedRegionAssetModel?.stages,
        selectedRegionAssetModel?.default_geography?.newest_risk_date,
    ])
    const isOutOfSeason = stageName === "out_of_season"

    // Declare options for toggle selector
    const chartDisplayOptions = {
        0: {
            key: "daily",
            value: "Upcoming Risk",
            disabled: isOutOfSeason,
            description: isOutOfSeason
                ? "Model is out of season, no upcoming risk data available."
                : "6 Month Forecast",
        },
        1: {
            key: "historical",
            value: "Full Season Risk",
            description: "Historical Risk and Analog Years",
        },
    }

    const DatasetParam = withDefault(
        ArrayParam,
        (isOutOfSeason ? ["1"] : ["0"]) as string[]
    )
    const [selectedDataset, setSelectedDataset] = useQueryParam(
        "dataset",
        DatasetParam
    )

    const selectedDatasetString = useMemo(() => {
        // If out of season, force historical view
        if (isOutOfSeason) return "historical"

        const index = parseInt(selectedDataset[0] ?? "0")
        return (
            chartDisplayOptions[index as keyof typeof chartDisplayOptions]
                ?.key ?? "historical"
        )
    }, [selectedDataset, isOutOfSeason])

    return (
        <div
            className="w-full h-full p-8 overflow-y-scroll bg-gray-1.5"
            id="risk-dashboard-container">
            <GenericPageHeader
                pageTitle={
                    assetModelName && !isLoading ? (
                        <div>
                            {t("regionalRisk", "{asset} Regional Risk", {
                                asset: assetModelName,
                            })}
                        </div>
                    ) : (
                        <div className="text-gray-30">
                            {t("loadingYieldDashboard")}
                        </div>
                    )
                }
            />

            <div className="mb-[14px] flex flex-wrap gap-2 align-baseline -mt-5">
                <SingleSelectFilter
                    key="region"
                    placeholder="Region"
                    leftRightClass="left-0"
                    selected={selectedRegion}
                    setSelected={(v) => {
                        setSelectedRegion(v)
                        setSelectedState("")
                    }}
                    options={regionsDict}
                />
                {!isSingleCountryModel && (
                    <SingleSelectFilter
                        key="state"
                        placeholder={t("state")}
                        leftRightClass="left-0"
                        selected={selectedState}
                        setSelected={(v) => {
                            setSelectedState(v)
                        }}
                        options={statesRegionsDict[selectedRegion]}
                    />
                )}
                <ToggleSelector
                    options={chartDisplayOptions}
                    selectedOptions={selectedDataset as string[]}
                    updateSelectedOptions={(currElement: string) => {
                        if (selectedDataset.includes(currElement)) {
                            // If the current element is already selected and it's the only one, do nothing
                            if (selectedDataset.length === 1) {
                                return
                            }
                            // Otherwise, remove it from the selection
                            const newOptions = selectedDataset.filter(
                                (option) => option !== currElement
                            )
                            setSelectedDataset(newOptions)
                        } else {
                            // If the current element is not selected, select it and deselect others
                            setSelectedDataset([currElement])
                        }
                    }}
                />
            </div>

            <div className="flex flex-col gap-[14px] mb-[14px]">
                <div className="flex gap-[14px]">
                    <div className="grid w-5/12 gap-[14px]">
                        <div className="bg-white border rounded-lg font-roboto border-1 border-gray-14 p-[14px]">
                            <WidgetWrapper
                                component={RiskOverviewScoreDisplay}
                                query={riskScoresQuery}
                                filters={dashboardFilters}
                                selectors={{
                                    $data: "yield_outlook_model.results[]",
                                    yProperty:
                                        selectedDatasetString === "historical"
                                            ? "y"
                                            : "y1",
                                    title:
                                        selectedDatasetString === "historical"
                                            ? `Comulative Risk for the ${
                                                  getStageConfig(stageName)
                                                      ?.label
                                              } Season`
                                            : "Overall Upcoming Risk",
                                }}
                            />
                        </div>
                        <div className="bg-white border rounded-lg font-roboto border-1 border-gray-14 p-[14px]">
                            <WidgetWrapper
                                component={RiskScoreDisplay}
                                query={riskScoresQuery}
                                filters={dashboardFilters}
                                selectors={{
                                    $data: "yield_outlook_model.results[]",
                                    yProperty:
                                        selectedDatasetString === "historical"
                                            ? "y"
                                            : "y1",
                                    title: "Risk Breakdown by Hazard",
                                }}
                            />
                        </div>
                    </div>

                    <div className="w-7/12 bg-white border rounded-lg font-roboto border-1 border-gray-14">
                        <WidgetWrapper
                            component={RiskOutlookShadedRegionsMap}
                            query={gql`
                                query ($assetModelId: String!) {
                                    yield_outlook_model(
                                        filter: {
                                            crop_model_ids: [$assetModelId]
                                        }
                                    ) {
                                        results {
                                            region {
                                                id
                                                name
                                                geojson
                                                resolution
                                            }
                                            ds_locations_geographies {
                                                id
                                                entry_type
                                                entry_id
                                                ds_location {
                                                    lat
                                                    lon
                                                }
                                            }
                                            default_geography {
                                                hazards {
                                                    hazard_key
                                                    hazard_name
                                                    risk_outlook_seasonal_stats {
                                                        plot(
                                                            configs: [
                                                                {
                                                                    date: "season_year_end"
                                                                    y: "index_value"
                                                                    y1: "forecast_contribution"
                                                                }
                                                            ]
                                                        ) {
                                                            lastValue {
                                                                date
                                                                y
                                                                y1
                                                                meta
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            `}
                            filters={dashboardFilters}
                            selectors={{
                                $data: "yield_outlook_model.results[]",
                                yProperty:
                                    selectedDatasetString === "historical"
                                        ? "y"
                                        : "y1",
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 grid-rows-2 gap-[14px]">
                    {availableHazards.map((hazard) => {
                        const riskHazard = getHazardConfig(hazard.hazard_name)
                        const { hazard_risk, color, title } = riskHazard

                        return (
                            <div
                                key={`${hazard_risk}-charts`}
                                className="w-full">
                                {selectedDatasetString === "historical" ? (
                                    <div className="w-full bg-white border rounded-lg font-roboto border-1 border-gray-14 p-[14px]">
                                        <WidgetWrapper
                                            key="yearly-graph"
                                            component={RiskOutlookChart}
                                            query={`#graphql
                                                query($assetModelId: String!) {
                                                    risk_outlook_model: yield_outlook_model(filter: { crop_model_ids: [$assetModelId] }) {
                                                        results {
                                                            default_geography {
                                                                risk_outlook_annotations(filter: { hazard_id: "${hazard.id}" }) {
                                                                    tooltip_daily
                                                                    tooltip_seasonal
                                                                    caption_daily
                                                                    caption_seasonal
                                                                }
                                                                risk_outlook_seasonal_stats(filter: { hazard_id: "${hazard.id}" }) {
                                                                    plot(configs: [
                                                                        {
                                                                            id: "${hazard_risk}-line",
                                                                            visualization: "line",
                                                                            color: "${color}",
                                                                            date: "season_year_end",
                                                                            y: "index_value",
                                                                            yMin: "probability_low",
                                                                            yMid: "probability_medium",
                                                                            yMax: "probability_high"
                                                                        }
                                                                    ]) {
                                                                        id
                                                                        visualization
                                                                        color
                                                                        opacity
                                                                        points {
                                                                            date
                                                                            y
                                                                            yMin
                                                                            yMid
                                                                            yMax
                                                                            meta
                                                                        }
                                                                        lastValue {
                                                                            date
                                                                            y
                                                                            yMin
                                                                            yMid
                                                                            yMax
                                                                            meta
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            `}
                                            selectors={{
                                                $data: "risk_outlook_model.results[0].default_geography.risk_outlook_seasonal_stats",
                                                $highlightedDate: isOutOfSeason
                                                    ? undefined
                                                    : "risk_outlook_model.results[0].default_geography.risk_outlook_seasonal_stats.plot[0].lastValue.date",

                                                $description:
                                                    "risk_outlook_model.results[0].default_geography.risk_outlook_annotations.caption_seasonal",
                                                $tooltip:
                                                    "risk_outlook_model.results[0].default_geography.risk_outlook_annotations.tooltip_seasonal",
                                                title: `Historical Context for ${title}`,
                                                icon: hazard.hazard_name,
                                                mainColor: color,
                                                domain: [0, 100],
                                                // TODO: Remove hardcoded bounds
                                                lowerBound: 70,
                                                upperBound: 90,
                                                //
                                                legends: [
                                                    {
                                                        type: "line",
                                                        label: "Observed",
                                                        color: color,
                                                    },
                                                    ...(isOutOfSeason
                                                        ? []
                                                        : [
                                                              {
                                                                  type: "dot",
                                                                  label: t(
                                                                      "current_season_forecast",
                                                                      "Current season forecast"
                                                                  ),
                                                                  color: color,
                                                              },
                                                          ]),
                                                ],
                                                xTooltipLabel: "Year",
                                                yTooltipLabel: title + " Index",
                                                xTickFormatter: (
                                                    value: string
                                                ) => {
                                                    const date =
                                                        DateTime.fromFormat(
                                                            value,
                                                            "yyyy"
                                                        )
                                                    return date.toFormat("yyyy")
                                                },
                                                xAxisInterval:
                                                    "preserveStartEnd",
                                                adjustYAxis: false,
                                                isHistorical: true,
                                            }}
                                            filters={dashboardFilters}
                                        />
                                    </div>
                                ) : (
                                    <div className="w-full bg-white border rounded-lg font-roboto border-1 border-gray-14 p-[14px]">
                                        <WidgetWrapper
                                            key="yearly-graph"
                                            component={RiskOutlookChart}
                                            query={`#graphql
                                                query($assetModelId: String!, $init_time: String!) {
                                                    risk_outlook_model: yield_outlook_model(filter: { crop_model_ids: [$assetModelId] }) {
                                                        results {
                                                            stages {
                                                                start_date
                                                                end_date
                                                                stage_name
                                                            }
                                                            default_geography {
                                                                risk_outlook_annotations(filter: { hazard_id: "${hazard.id}" }) {
                                                                    tooltip_daily
                                                                    tooltip_seasonal
                                                                    caption_daily
                                                                    caption_seasonal
                                                                }
                                                                risk_outlook_daily_stats(filter: { hazard_id: "${hazard.id}", init_time: $init_time }) {
                                                                    results {
                                                                        data {
                                                                            plot(
                                                                                configs: [  
                                                                                    {
                                                                                        id: "${hazard_risk}-line-2",
                                                                                        visualization: "line",
                                                                                        color: "${color}",
                                                                                        date: "date",
                                                                                        y: "value",
                                                                                        yMin: "probability_low",
                                                                                        yMid: "probability_mid",
                                                                                        yMax: "probability_high"
                                                                                    }
                                                                                ]
                                                                            ) {
                                                                                id
                                                                                visualization
                                                                                color
                                                                                opacity
                                                                                points {
                                                                                    date
                                                                                    y
                                                                                    yMin
                                                                                    yMid
                                                                                    yMax
                                                                                }
                                                                                lastValue {
                                                                                    date
                                                                                    y
                                                                                    yMin
                                                                                    yMid
                                                                                    yMax
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            `}
                                            selectors={{
                                                $data: "risk_outlook_model.results[0].default_geography.risk_outlook_daily_stats.results[0].data",
                                                $stages:
                                                    "risk_outlook_model.results[0].stages",
                                                $description:
                                                    "risk_outlook_model.results[0].default_geography.risk_outlook_annotations.caption_daily",
                                                $tooltip:
                                                    "risk_outlook_model.results[0].default_geography.risk_outlook_annotations.tooltip_daily",
                                                title,
                                                icon: hazard.hazard_name,
                                                dottedSeparatorDate:
                                                    DateTime.now()
                                                        .startOf("day")
                                                        .toISODate(),
                                                verticalLineDate: DateTime.now()
                                                    .startOf("day")
                                                    .toISODate(),
                                                verticalLineColor: "#B3B6BA",
                                                showDots: false,
                                                adjustYAxis: false,
                                                domain: [0, 100],
                                                xTickFormatter: (
                                                    value: string
                                                ) => {
                                                    const date =
                                                        DateTime.fromFormat(
                                                            value,
                                                            "dd-MM-yyyy"
                                                        )
                                                    const today = DateTime.now()
                                                    const dayOfMonth = date.day
                                                    const diffDays = Math.abs(
                                                        date.diff(today, "days")
                                                            .days
                                                    )

                                                    // Show full month name on the 15th of every month
                                                    if (
                                                        dayOfMonth === 15 &&
                                                        diffDays > 10
                                                    ) {
                                                        return date.toFormat(
                                                            "MMM"
                                                        )
                                                    }

                                                    // Return empty string for other days
                                                    return ""
                                                },
                                                lowerBound: 70,
                                                upperBound: 90,
                                                legends: [
                                                    {
                                                        type: "line",
                                                        label: "Observed",
                                                        color: color,
                                                    },
                                                    {
                                                        type: "line-dashed",
                                                        label: "Forecast",
                                                        color: color,
                                                    },
                                                ],
                                                xTooltipLabel: "Date",
                                                yTooltipLabel: title + " Index",
                                            }}
                                            filters={dashboardFilters}
                                        />
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default RiskOutlookDashboard
