import { ReactNode } from "react"

export interface IDropdownOpt {
    label?: string
    icon?: ReactNode
}

export type DropdownEntry = [string, IDropdownOpt]
export type EntriesSortFn = (a: DropdownEntry, b: DropdownEntry) => number
export type EntriesFilterFn = (entry: DropdownEntry) => boolean

export type DefaultSortFn = "lexical-by-key" | "lexical-by-value"
export const DEFAULT_SORT_FNS: Record<DefaultSortFn, EntriesSortFn> = {
    "lexical-by-key": ([keyA], [keyB]) => keyA.localeCompare(keyB),
    "lexical-by-value": ([, { label: labelA }], [, { label: labelB }]) => {
        if (!labelA || !labelB) return 0
        return labelA.localeCompare(labelB)
    },
}

export const setSelectedByKey =
    (selectedKey: string | undefined) => (selection: Set<string>) => {
        if (!selectedKey) return selection
        // Remove the option if it was selected
        if (selection.has(selectedKey)) {
            selection.delete(selectedKey)
            return new Set(selection)
        }
        // Add the option with the rest
        selection.add(selectedKey)
        return new Set(selection)
    }
