import {
    ChillHoursStressRiskIcon,
    ColdRiskIcon,
    CumulativeDroughtRiskIcon,
    CumulativePrecipitationRiskIcon,
    DroughtRiskIcon,
    DrySoilRiskIcon,
    ExcessiveSolarRadiationRiskIcon,
    HeatStressRiskIcon,
    DefaultRiskIcon,
    HighChillHoursRiskIcon,
    HighHumidityRiskIcon,
    HighSoilTempRiskIcon,
    HighWindDayRiskIcon,
    InsufficientChillTempRiskIcon,
    InsufficientHighTempRiskIcon,
    InsufficientSolarRadiationRiskIcon,
    LowChillHoursRiskIcon,
    LowHumidityRiskIcon,
    LowSoilTempRiskIcon,
    LowWindDayRiskIcon,
    PrecipitationRiskIcon,
    WetSoilRiskIcon,
    WindGustRiskIcon,
} from "."

import getVariableIcon from "../../utils/icons/getVariableIcon"
import StackedRiskIcon from "./StackedRiskIcon"

// Leaving RISK_ICON_MAP defined keeping in mind that we might come back to
// using special icons not only dependant on variable but also on its conditional
const RISK_ICON_MAP = {
    t2m_min: {
        ">": <ColdRiskIcon />,
        "<": <InsufficientChillTempRiskIcon />,
    },
    t2m_max: {
        ">": <HeatStressRiskIcon />,
        "<": <InsufficientHighTempRiskIcon />,
    },
    rh: {
        ">": <HighHumidityRiskIcon />,
        "<": <LowHumidityRiskIcon />,
    },
    ws: {
        ">": <HighWindDayRiskIcon />,
        "<": <LowWindDayRiskIcon />,
    },
    tp: {
        ">": <PrecipitationRiskIcon />,
        "<": <DroughtRiskIcon />,
    },
    stl1: {
        ">": <HighSoilTempRiskIcon />,
        "<": <LowSoilTempRiskIcon />,
    },
}
const RiskIconHandler = ({ hazardProfiles }) => {
    try {
        if (hazardProfiles.length > 1) {
            return <StackedRiskIcon />
        }
        const hazardVariable =
            hazardProfiles[0]["hazard_variable"]["dashboard_variable"] ||
            hazardProfiles[0]["hazard_variable"]["zarr_variable"]
        return getVariableIcon(hazardVariable)
    } catch (e) {
        // console.log(" Error ", e)
        return <DefaultRiskIcon />
    }
}

export default RiskIconHandler
