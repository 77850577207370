import { FC, ReactNode } from "react";
import { ErrorBoundary as ErrBound, FallbackProps } from "react-error-boundary";
import { useTranslate } from "@tolgee/react";
import { Disclosure, Transition } from '@headlessui/react'

import { useAccount, useAuth } from "../../providers";
import { Button } from "../../climateui/components";
import { ErrorWrapper, ErrorContent, ErrorDescription, ErrorFooter, ErrorHeader, ErrorTitle } from "./components/Error";
import ErrorConsole from "./components/ErrorConsole";
import { datadogRum } from "@datadog/browser-rum"

const parseError = (err: any): Error => {
    if (err instanceof Error) {
        return err
    }
    const defaultError: Error = new Error('An error ocurred!')
    return defaultError
}


export const ErrorView: FC<FallbackProps> = ({ error }) => {
    const { t } = useTranslate()

    const dataDogRumInternalContext = datadogRum.getInternalContext()

    const auth = useAuth()
    const acc = useAccount()

    const reloadPage = () => {
        location.reload()
    }

    const errorTitle = t('errorTitle', 'Something went wrong')
    const errorDescription = t('errorDescription', 'Try reloading the page, if the error persist please contact us.')
    const errorReloadButton = t('errorReloadButton', 'Reload Page')

    const errorCopyRight = t('errorCopyRight', '©ClimateAi. All rights reserved. Privacy Policy Terms of Use.')
    const errorToggleButtonCollapse = t('errorToggleButtonCollapse', 'Collapse Error log')
    const errorToggleButtonExpand = t('errorToggleButtonExpand', 'Expand Error log')

    const errorUnknownConst = t('unknown', 'Unknown')

    const now = new Date();
    const formattedNow = now.toLocaleDateString(undefined, {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC'
    });

    const errorConsoleDescription = {
        account: acc.selectedAccount ?? errorUnknownConst,
        user: auth.user?.email ?? errorUnknownConst,
        sessionId: dataDogRumInternalContext?.session_id,
        date: formattedNow + ' UTC'
    }

    const getErrorConsoleDescription = () => {
        const accountConsoleDesc = t('account', 'Account')
        const userConsoleDesc = t('user', 'User')
        const sessionConsoleDesc = t('session', 'Session ID')
        const dateConsoleDesc = t('date', 'Date')

        return `${accountConsoleDesc}: ${errorConsoleDescription.account} \n${userConsoleDesc}: ${errorConsoleDescription.user} \n${sessionConsoleDesc}: ${errorConsoleDescription.sessionId} \n${dateConsoleDesc}: ${errorConsoleDescription.date}`
    }


    return (
        <div className="w-full h-screen">
            <ErrorWrapper className="space-y-8">
                <ErrorHeader>
                    <img className="w-auto h-[50px]" src="/images/climateai/ClimateAi-new-logo.png" alt="ClimateAi logo" />
                </ErrorHeader>
                <ErrorContent className="space-y-4">
                    <img src="/images/climateai/error.png" alt="Something went wrong" />
                    <ErrorTitle>{errorTitle}</ErrorTitle>
                    <ErrorDescription className="pb-3">{errorDescription}</ErrorDescription>
                    <Button type="primary" label={errorReloadButton} onClick={reloadPage} />
                </ErrorContent>
                <ErrorFooter>
                    <Disclosure>
                        {({ open }) => (
                            <>
                                <div className={`flex flex-row justify-center items-center space-x-4`}>
                                    <p className="body-md">{errorCopyRight}</p>
                                    <Disclosure.Button className={'link'}>
                                        {open ? errorToggleButtonCollapse : errorToggleButtonExpand}
                                    </Disclosure.Button>
                                </div>
                                {/* Accordion */}
                                <div className={`w-full max-w-2xl transition-transform`}>
                                    <Transition
                                        enter="transition duration-150 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100 "
                                        leave="transition duration-150 ease-out"
                                        leaveFrom="transform scale-100 opacity-100 "
                                        leaveTo="transform scale-90 opacity-0"
                                    ><Disclosure.Panel static>
                                            <div className="mt-16 mb-4">
                                                <ErrorConsole
                                                    description={
                                                        getErrorConsoleDescription()
                                                    }
                                                    error={parseError(error)}
                                                    title={String(error)} />
                                            </div>
                                        </Disclosure.Panel>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Disclosure>
                </ErrorFooter>
            </ErrorWrapper>
        </div >
    )
}

interface IErrorBoundaryProps {
    children: ReactNode
}

const ErrorBoundary: FC<IErrorBoundaryProps> = ({ children }) => {

    const onError = (error: Error, info: React.ErrorInfo) => {
        // Log error to Datadog api
    }

    return (
        <ErrBound
            FallbackComponent={ErrorView}
            onError={onError}
        >
            {children}
        </ErrBound>
    )
}

export default ErrorBoundary;