export interface ICalendarHeaderProps {
    readonly shownDates: string[]
    readonly responsiveWidth: number
    readonly eachSpaceLength: number[]
}

function CalendarHeader(props: ICalendarHeaderProps) {
    const { shownDates, responsiveWidth, eachSpaceLength } = props

    return (
        <>
            {shownDates.map((date, index) => (
                <h1
                    key={date}
                    className="text-center body-sm text-gray-60 truncate px-1"
                    style={{
                        width: eachSpaceLength[index] * responsiveWidth + "px",
                    }}>
                    {date}
                </h1>
            ))}
        </>
    )
}

export default CalendarHeader
