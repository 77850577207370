import { useTranslate } from "@tolgee/react"

function ForbiddenView() {
    const { t } = useTranslate()

    return (
        <div className="flex flex-col items-center justify-center h-full w-full">
            <div className="w-36 -mt-8">
                <img src="/images/climateai/not-view-permissions.png" />
            </div>

            <h1 className="title-lg text-gray-90 pt-4 max-w-[360px] text-center">
                {t(
                    "youDontHaveViewPermissions",
                    "You don't have permissions to view this page"
                )}
            </h1>
        </div>
    )
}

export default ForbiddenView
