import { SVGProps } from "react"

const BelowIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 22 22"
        className={"fill-inherit " + props.className}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.9166 13.8284L14.9352 10.8098L16.2315 12.1062L11 17.3377L5.76855 12.1062L7.06491 10.8098L10.0833 13.8282L10.0833 5.04126L11.9166 5.04126L11.9166 13.8284Z"
            fill={props.fill}
        />
    </svg>
)

export default BelowIcon
