import { useTranslate } from "@tolgee/react"
import { isEmpty } from "lodash"
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react"
import { useMutation } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
    IMapPin,
    MapStyleToggler,
    IMapStyleToggler,
    MAP_DEFAULT_STYLE,
    MAP_STYLE_TOGGLER,
} from "../climateui/components/Map/utils"
import { ToastContext } from "../climateui/providers"
import { ICoordinates } from "../climateui/types"
import { colors } from "../climateui/utils/colors"
import AddLocationModal from "../components/AddLocationModal"
import { IAnalog, IInsightsLocation } from "../types"
import queryClient, { labelPOST, locationPOST } from "../utils/networking"
import { getExplorationLocationMarkerPopup } from "../views/Climate/ExplorationTool/explorationUtils"
import { searchBySimilarityRoute } from "../views/Climate/routes"
import { useAccount } from "./AccountProvider"
import { useAuth } from "./AuthProvider"
import { useLabels } from "./LabelsProvider"
import { useLocations } from "./LocationsProvider"

export interface IExplorationTool {
    selectedLocationId: string | null
    navigateToSearchBySimilarity: (
        locationId: string,
        basePath?: string
    ) => void
    selectedLocationPin: IMapPin | null
    addToMyLocations: (analog: IAnalog) => void
    mapStyleToggler: MapStyleToggler
    currentMapStyleToggler: IMapStyleToggler
    setCurrenMapStyleToggler: (nextStyleToggler: IMapStyleToggler) => void
    addedAnalogs: Record<string, IInsightsLocation>
    customFlyTo?: ICoordinates
    flyToLoc: (coords: ICoordinates) => void
}

export const ExplorationToolContext = createContext({} as IExplorationTool)

export const useExplorationTool = () => useContext(ExplorationToolContext)

function ExplorationToolProvider({ children }: { children: ReactNode }) {
    const navigate = useNavigate()
    const { t } = useTranslate()
    const [params, setParams] = useSearchParams()
    const auth = useAuth()
    const { selectedAccount } = useAccount()
    const selectedLocationId = params.get("locationId")
    const { locationsObj, locations } = useLocations()
    const { labels, queryLabels } = useLabels()
    const [currentMapStyleToggler, setCurrenMapStyleToggler] = useState(
        MAP_STYLE_TOGGLER[MAP_DEFAULT_STYLE]
    )
    const [addedAnalogs, setAddedAnalogs] = useState<
        Record<string, IInsightsLocation>
    >({})

    const { mutateAsync: addLabel } = useMutation(labelPOST)

    useEffect(() => {
        if (!locationsObj || isEmpty(locationsObj) || !selectedLocationId)
            return

        if (!locationsObj[selectedLocationId]) setParams({})
    }, [selectedAccount, locationsObj])

    const navigateToSearchBySimilarity = (
        locationId: string,
        basePath = "./"
    ) => {
        navigate(
            basePath +
                searchBySimilarityRoute.path +
                "?locationId=" +
                locationId +
                "&instantSearch=" +
                locationId
        )
    }

    const selectedLocationPin: IMapPin | null =
        selectedLocationId && locationsObj && locationsObj[selectedLocationId]
            ? {
                  lat: locationsObj[selectedLocationId].latitude,
                  lon: locationsObj[selectedLocationId].longitude,
                  pinStyle: "map-pin-white.png",
                  popup: getExplorationLocationMarkerPopup,
                  data: {
                      ...locationsObj[selectedLocationId],
                      regionName:
                          locationsObj[selectedLocationId].region?.full_name,
                      reference: t("reference", "Reference"),
                  },
                  events(marker) {
                      return {
                          mouseenter: () => marker.togglePopup(),
                          mouseleave: () => marker.togglePopup(),
                      }
                  },
              }
            : null

    const [analog, setAnalog] = useState<IAnalog>()
    const addToMyLocations = (analog: IAnalog) => {
        setAnalog(analog)
    }

    const exploreLocationLabel = useMemo(() => {
        if (!analog) return undefined

        const loc = locations.find(
            (loc) =>
                loc.latitude === analog.refLat &&
                loc.longitude === analog.refLon
        )
        if (!loc) return undefined

        const labelName = `${t("explore", "Explore").toLowerCase()}_${loc.name
            .replaceAll(" ", "_")
            .toLowerCase()}`
        const label = labels.find((label) => label.name === labelName)
        if (label) return label

        addLabel({
            name: labelName,
            color: colors.cyan.DEFAULT,
            account_id: selectedAccount,
        }).finally(() => {
            queryLabels()
        })
        return undefined
    }, [analog, labels])

    const [customFlyTo, setCustomFlyTo] = useState<ICoordinates>()

    const value = useMemo(
        () => ({
            selectedLocationId,
            selectedLocationPin,
            navigateToSearchBySimilarity,
            addToMyLocations,
            currentMapStyleToggler,
            setCurrenMapStyleToggler,
            mapStyleToggler: MAP_STYLE_TOGGLER,
            addedAnalogs,
            customFlyTo,
            flyToLoc: setCustomFlyTo,
        }),
        [
            selectedLocationId,
            selectedLocationPin,
            currentMapStyleToggler,
            addedAnalogs,
            customFlyTo,
        ]
    )

    const { enqueueAlert } = useContext(ToastContext)
    const { mutate: addLocation } = useMutation(locationPOST)

    return (
        <ExplorationToolContext.Provider value={value}>
            {children}
            {selectedAccount && auth.hasRole(selectedAccount, "admin") && (
                <AddLocationModal
                    lockLatLon
                    showCount={false}
                    open={
                        analog !== undefined &&
                        exploreLocationLabel !== undefined
                    }
                    customCoords={analog ?? undefined}
                    customLabels={
                        exploreLocationLabel ? [exploreLocationLabel] : []
                    }
                    cancel={() => setAnalog(undefined)}
                    handleSave={(location: IInsightsLocation) => {
                        addLocation(location, {
                            onSuccess: async () => {
                                if (!analog) return
                                enqueueAlert(t("locationAddedSuccessfully"))
                                const key = analog.lat + "," + analog.lon
                                setAddedAnalogs({
                                    ...addedAnalogs,
                                    [key]: location,
                                })
                                queryClient.invalidateQueries(["locations"])
                                setAnalog(undefined)
                            },
                            onError: async () => {
                                enqueueAlert(
                                    t(
                                        "thereWasAnErrorAddingLocation",
                                        "There was an error adding the location."
                                    )
                                )
                                setAnalog(undefined)
                            },
                        })
                    }}
                />
            )}
        </ExplorationToolContext.Provider>
    )
}

export default ExplorationToolProvider
