import { ILinePlotComponent, IAreaPlotComponent, IDirectionalityPlotComponent, PlotComponentTypes } from "../entities/PlotComponents/types"
export type TypeChartPlotUnion<TMetaData> = ILinePlotComponent<TMetaData> | IAreaPlotComponent<TMetaData> | IDirectionalityPlotComponent<TMetaData>

export type TypeMapChart<TMetaData> = Map<string, TypeChartPlotUnion<TMetaData>>

/* Helper function */
export function mergeDoubleYValues(y0: number, y1: number): number[] {
    return [y0, y1]
}

// This can be moved to a global helper file
export function areEqualValues<T>(vals: T[]): boolean {
    const map = new Map<T, number>()
    for (let i = 0; i < vals.length; i++) {
        if (map.has(vals[i])) {
            const counter = map.get(vals[i])
            if (counter) {
                map.set(vals[i], counter + 1)
            }
        } else {
            map.set(vals[i], 1)
        }
    }

    if (map.size === 1) {
        return true
    }
    return false
}

/* Chart components typeguards */
export function isLinePlotComponent<TMetaData>(chart: TypeChartPlotUnion<TMetaData>): chart is ILinePlotComponent<TMetaData> {
    return chart.type === PlotComponentTypes.Line
}

export function isAreaPlotComponent<TMetaData>(chart: TypeChartPlotUnion<TMetaData>): chart is IAreaPlotComponent<TMetaData> {
    return chart.type === PlotComponentTypes.Area
}

export function isDirectionalityPlotComponent<TMetaData>(chart: TypeChartPlotUnion<TMetaData>): chart is IDirectionalityPlotComponent<TMetaData> {
    return chart.type === PlotComponentTypes.Directionality
}
