import ReactDOMServer from "react-dom/server"
import { addDays, addMonths } from "../../../utils/chart"
import getColorAndSymbol from "../../../utils/icons/getColorAndSymbol"

export const monthsArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

export const renderGenericDirectionality = (value = 0) => {
    return ReactDOMServer.renderToStaticMarkup(
        <div className="w-full h-full p-[2px]">
            {getColorAndSymbol(value.toString(), "chart", {
                above: "green",
                below: "red",
                within: "yellow-dark",
            })}
        </div>
    )
}

export const obtainGenericGraphData = (item, extraAttrs) => {
    // type of viz
    const type = item.visualization
    let directionalityFlag = false

    // date(str) -> x
    const points = item.points.map((point) => {
        const date = new Date(point.date)

        const _point = {
            x: date.getTime(),
            // remove null values
            ...Object.fromEntries(
                Object.entries(point).filter(([_, val]) => val !== null)
            ),
            visible: true,
        }

        // if directionality increase the point size
        if (point.directionality !== null) {
            directionalityFlag = true
            _point.radius = 20
        }

        return _point
    })

    const resultPlot = {
        ...{ line: LINE_VIZ_DEFAULTS }[type],
        type,
        points,
        ...extraAttrs,
    }

    // add directionality function to result plot
    if (directionalityFlag)
        resultPlot.renderCustomPoint = ({ directionality }) =>
            renderGenericDirectionality(directionality)

    return resultPlot
}

// UTIL Functions =============================================================
const obtainLinePointsData = (item) => {
    const defaultAttr =
        ("ensemble_mean" in item.points[0].attributes && "ensemble_mean") ||
        Object.keys(item.points[0].attributes)[0]

    return item.points.map((point) => {
        const date = new Date(point.date)
        return {
            x: date.getTime(),
            y: point.attributes[defaultAttr],
            attributes: point.attributes,
            visible: true,
        }
    })
}

export const getLargestAttribute = (attributes) => {
    let largestValue = Number.NEGATIVE_INFINITY
    let largestAttribute = null

    for (const key in attributes) {
        if (
            attributes.hasOwnProperty(key) &&
            typeof attributes[key] === "number" &&
            !["quantile_0.33", "quantile_0.67"].includes(key)
        ) {
            if (attributes[key] > largestValue) {
                largestValue = attributes[key]
                largestAttribute = key
            }
        }
    }

    return largestAttribute
}

export const getDirection = (attribute) => {
    switch (attribute) {
        case "clim_prob_0.00-0.33":
        case "0":
            return -1
        case "clim_prob_0.33-0.67":
        case "0.33":
            return 0
        case "clim_prob_0.67-1.00":
        case "0.66":
            return 1
    }
}

const obtainDirectionalityPointsData = (item, allItems) => {
    //
    const linePointsData = obtainLinePointsData(item)
    const climatologyData = allItems.filter((i) => {
        return i.variable == item.variable && i.dataset == "climatology"
    })

    return linePointsData.map((p) => {
        // get direction
        const pointIndex = linePointsData.indexOf(p)

        const attributes = {
            ...p.attributes,
            ...climatologyData[0].points[pointIndex].attributes,
        }
        delete attributes.ensemble_mean
        const largestAttr = getLargestAttribute(attributes)
        const direction = getDirection(largestAttr)

        return {
            direction: direction,
            radius: 20,
            ...p,
            attributes: attributes,
            item_data: item,
        }
    })
}

const obtainAreaPointsData = (item) => {
    const stat1 = item.statistics[0]
    const stat2 = item.statistics[2] //Item statistics now include a middle data used to display extra information in our
    //csv, however it is not required for FE visualization

    return item.points.map((point) => {
        const date = new Date(point.date)
        const val1 = point.attributes[stat1]
        const val2 = point.attributes[stat2]
        return {
            x: date.getTime(),
            y0: Math.min(val1, val2),
            y: Math.max(val1, val2),
        }
    })
}

// TODO make this dynamic later
// for now: hardcoding this function with fixed quantiles
const obtainCandlePointsData = (item) => {
    return item.points.map((point) => {
        const date = new Date(point.date)

        return {
            x: date.getTime(),
            y0: point.attributes["quantile_0.05"],
            y1: point.attributes["quantile_0.95"],
            yMax: point.attributes["quantile_0.75"],
            yMid: point.attributes["quantile_0.50"],
            yMin: point.attributes["quantile_0.25"],
            item_data: item,
        }
    })
}

const LINE_VIZ_DEFAULTS = {
    "type": "line",
    "alwaysOnTop": true,
    "visible": true,
    "stroke-width": 2,
}

const DIRECT_VIZ_DEFAULTS = {
    "type": "directionality",
    "alwaysOnTop": true,
    "visible": true,
    "stroke-width": 2,
}

// MAIN Functions =============================================================
export const obtainGraphData = (item, allItems, granularity, extraAttrs) => {
    const visualization = item.visualization

    switch (visualization) {
        case "line":
            return {
                ...LINE_VIZ_DEFAULTS,
                points: obtainLinePointsData(item),
                ...extraAttrs,
            }
        case "directionality":
            return {
                ...DIRECT_VIZ_DEFAULTS,
                points: obtainDirectionalityPointsData(item, allItems),
                // render here the custom point
                renderCustomPoint: ({ direction }) => {
                    return ReactDOMServer.renderToStaticMarkup(
                        <div className="w-full h-full p-[2px]">
                            {getColorAndSymbol(direction.toString(), "chart", {
                                above: "#666D74",
                                below: "#666D74",
                                within: "#666D74",
                            })}
                        </div>
                    )
                },
                ...extraAttrs,
            }
        case "area":
            return {
                type: "area",
                points: obtainAreaPointsData(item),
                ...extraAttrs,
            }
        case "candle":
            return {
                type: "candlestick",
                points: obtainCandlePointsData(item, granularity),
                ...extraAttrs,
            }
    }

    return {}
}

export const obtainGraphExtraProps = (data, visualizations, granularity) => {
    let result = {}

    if (visualizations.includes("line")) {
        const dataPoints = data[0].points
        const xTicksCount = dataPoints.length || 0
        const xDateMax =
            dataPoints && xTicksCount
                ? dataPoints[xTicksCount - 1].x
                : new Date()
        const xDateMin =
            dataPoints && xTicksCount ? dataPoints[0].x : new Date()
        result = {
            ...result,
            xTicksCount,
            xDateMax,
            xDateMin,
            centerTicks: false,
        }
    } else if (visualizations.includes("candle")) {
        const dataPoints = data[0].points
        const xTicksCount = dataPoints.length || 0

        let startDate = new Date(dataPoints[0].x)
        let endDate = new Date(startDate.getTime())

        if (granularity === "monthly") {
            // startDate.setDate(-31)
            startDate = addDays(startDate, -31)

            endDate.setMonth(startDate.getMonth() + dataPoints.length + 2)
            endDate.setDate(1)
        } else if (granularity === "weekly") {
            startDate = addDays(startDate, 0)
            endDate = addDays(new Date(dataPoints[dataPoints.length - 1].x), 8)
        }

        result = {
            ...result,
            xTicksCount,
            xDateMax: endDate,
            xDateMin: startDate,
        }
    }

    return result
}

export function groupCandleItems(candleItems) {
    const candleMap = new Map()
    const availableColors = []
    // loop through every point in the candles, check if exists, add it to candle dates
    candleItems.forEach((item) => {
        availableColors.push(item.color)
        item.points.forEach((point) => {
            // check if point date exists in map
            if (!candleMap.has(point.x)) candleMap.set(point.x, [])

            const pointDate = candleMap.get(point.x)
            pointDate.push(point)
        })
    })

    const points = []
    candleMap.forEach((values = [], key) => {
        points.push({
            x: key,
            values,
        })
    })

    const baseItem = {
        ...candleItems[0],
        color: (_, groupIndex) => {
            return availableColors[groupIndex]
        },
        points,
    }

    return baseItem
}

export function getXPastTimestep(date, granularity) {
    let _date = new Date(date.getTime())
    switch (granularity) {
        case "weekly":
            // last week
            _date.setDate(_date.getDate() - 8)
            break
        case "monthly":
            // first day of the previous month
            _date.setMonth(_date.getMonth() - 1)
            _date.setDate(1)
            break
    }
    return _date
}

export function getXFutureTimestep(date, granularity) {
    let _date = new Date(date.getTime())
    switch (granularity) {
        case "weekly":
            // next week
            _date.setDate(date.getDate() + 8)
            break
        case "monthly":
            // first day of the next month
            _date.setMonth(date.getMonth() + 1)
            _date.setDate(1)
            break
    }
    return _date
}

export function getMiddleDate(startDate, endDate) {
    const difference = endDate.getTime() - startDate.getTime()
    const middle = startDate.getTime() + difference / 2

    return new Date(middle)
}
