import { useEffect, useState } from "react"
import PaginationControls from "./PaginationControls"
import Table, { IReactTable } from "./Table"

/* CONSTS */
const DEFAULT_PAGE = 1
const DEFAULT_PAGE_SIZE = 10

export interface IPaginationState {
    page: number // current page
    pageSize: number // how many records per page, (per_page in the CAI API)
}
export interface IPaginatedTable<T> extends IReactTable<T> {
    initialPage?: number
    initialPageSize?: number
    outOfText?: string // The text between the current page and the total pages, e.g. "of" or "/"
    totalRecords: number // The total amount of records being paginated
    onPageChange: (paginateState: IPaginationState) => void
    pageSizeOpts?: { [key: number]: string } // The options for the page size selection
}

const defaultPageSizeOpts = {
    5: `5 ${"rows"}`,
    10: `10 ${"rows"}`,
    20: `20 ${"rows"}`,
}

function PaginatedTable<T>(props: IPaginatedTable<T>) {
    const {
        initialPage,
        initialPageSize,
        totalRecords,
        onPageChange,
        outOfText,
        pageSizeOpts = defaultPageSizeOpts,
    } = props
    const [pagination, _setPagination] = useState<IPaginationState>({
        page: initialPage ?? DEFAULT_PAGE,
        pageSize: initialPageSize ?? DEFAULT_PAGE_SIZE,
    })
    const totalPages = (() => Math.ceil(totalRecords / pagination.pageSize))()
    const setPagination = (pagination: IPaginationState) => {
        _setPagination(pagination)
        onPageChange(pagination)
    }
    const changePage = (pageStep: number) => {
        const stepIsWithinBounds =
            (pageStep < 0 && pagination.page + pageStep > 0) ||
            (pageStep > 0 && pageStep + pagination.page <= totalPages)

        if (stepIsWithinBounds) {
            setPagination({
                ...pagination,
                page: pagination.page + pageStep,
            })
        }
    }
    const goToFirst = () => {
        setPagination({
            ...pagination,
            page: 1,
        })
    }
    const goToLast = () => {
        setPagination({
            ...pagination,
            page: totalPages,
        })
    }

    useEffect(() => {
        const paginationUpdate = {
            page: initialPage,
            pageSize: initialPageSize,
        }
        if (!initialPage) {
            delete paginationUpdate.page
        }
        if (!initialPageSize) {
            delete paginationUpdate.pageSize
        }
        if (Object.keys(paginationUpdate)) {
            setPagination({
                ...pagination,
                ...(paginationUpdate as IPaginationState),
            })
        }
    }, [initialPage, initialPageSize])
    return (
        <div className="flex flex-col h-full">
            <div className="shrink-0 max-h-[90%]">
                <Table<T> {...props} />
            </div>

            <PaginationControls
                selectedPageSize={pagination.pageSize}
                paginationOptions={pageSizeOpts}
                handlePageSizeChange={(size) => {
                    setPagination({
                        page: 1,
                        pageSize: size,
                    })
                }}
                handleFirstClick={goToFirst}
                handlePrevClick={() => changePage(-1)}
                handleNextClick={() => changePage(1)}
                handleLastClick={goToLast}
                backwardDisabled={pagination.page === 1}
                forwardDisabled={pagination.page === totalPages}
                currentPage={pagination.page}
                totalPages={totalPages}
                outOfText={outOfText ?? "of"}
            />
        </div>
    )
}

export default PaginatedTable
