import { ReactNode, Fragment, useRef, useCallback } from "react"
import { createPortal } from "react-dom"
import { Transition } from "@headlessui/react"
import {
    useIsRefVisible,
    useResponsiveFixedPositionAndAlignment,
} from "../../hooks"
import {
    FixedPosition,
    FixedAlignment,
    getChildPosAndAlign,
    getOrCreatePortal,
} from "./utils"
import useObserveBoundingRect from "../../hooks/useObserveBoundingRect"
import { getRect } from "../../utils/dom"

//
// FIXED ELEMENT --------------------------------------------------------------
//
function FixedElement({
    children,
    open,
    parentElement,
    position = "bottom", // content position relative to parent borders
    align = "center", // content alignment relative to parent center
    animationDelayClass = "",
    parentVisibilityThreshold = 0.5,
    pointerEventsNone = false,
    floatingMargin,
}: Readonly<{
    children: ReactNode
    open: boolean
    parentElement: ReactNode
    position?: FixedPosition
    align?: FixedAlignment
    animationDelayClass?: string
    parentVisibilityThreshold?: number
    pointerEventsNone?: boolean
    floatingMargin?: number
}>) {
    const parentRef = useRef<HTMLDivElement | null>(null)
    const fixedRef = useRef<HTMLDivElement | null>(null)
    const isParentVisible = useIsRefVisible(
        parentRef,
        parentVisibilityThreshold
    )

    const { fixedElementRef: responsiveElementRef } =
        useResponsiveFixedPositionAndAlignment(floatingMargin)

    const compoundFixedRef = useCallback((node: HTMLDivElement) => {
        responsiveElementRef(node)
        fixedRef.current = node
        return node
    }, [])

    let fixedRootClasses = "relative"
    if (!parentElement) fixedRootClasses += " w-full h-full"

    const parentRect = useObserveBoundingRect(parentRef, {
        events: ["resize", "scroll"],
    })
    const parentStyle = {
        top: parentRect.top,
        left: parentRect.left,
        width: parentRect.width,
        height: parentRect.height,
    }

    // Create or get fixed layer
    const portalLayer = getOrCreatePortal()

    const fixedRect = getRect(fixedRef)
    return (
        <div
            className={fixedRootClasses}
            ref={parentRef}>
            {parentElement}
            {createPortal(
                <Transition
                    show={open && isParentVisible}
                    as={Fragment}>
                    {/* ANCHOR */}
                    <Transition.Child
                        as={Fragment}
                        enter={"ease-out duration-100 " + animationDelayClass}
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div
                            style={parentStyle}
                            className={[
                                "absolute",
                                "z-full",
                                "pointer-events-none",
                            ].join(" ")}>
                            <div
                                className={[
                                    pointerEventsNone
                                        ? "pointer-events-none"
                                        : "pointer-events-auto",
                                    "transition-transform ease-linear relative",
                                    "w-max h-max",
                                ].join(" ")}
                                style={{
                                    ...getChildPosAndAlign(
                                        position,
                                        align,
                                        parentRect,
                                        fixedRect
                                    ),
                                }}
                                ref={compoundFixedRef}>
                                {children}
                            </div>
                        </div>
                    </Transition.Child>
                </Transition>,
                portalLayer
            )}
        </div>
    )
}

export default FixedElement
