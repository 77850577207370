import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import "./mapbox.css"

import { useCallback, useEffect, useRef, useState } from "react"
import CustomMapControls from "./CustomMapControls"
import {
    delay,
    initializeMap,
    IMap,
    MAP_DEFAULT_STYLE,
    MAP_STYLE_TOGGLER,
    getMarkerElement,
    setupPins,
    setupHeatmap,
    removeHeatmap,
    removeLines,
    setupLines,
    getPinsBounds,
} from "./utils"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import mapboxgl from "./mapboxgl"

export default function Map({
    pins = [],
    heatmap = [],
    lines = [],
    children,
    mapDefaultStyle = MAP_DEFAULT_STYLE,
    mapStyleToggler = MAP_STYLE_TOGGLER,
    canToggleMapStyle = true,
    mapConfigs,
    searchPlaceholder = "Search",
    coords,
    setCoords,
    language = "en",
    boundingBoxCallback,
    actionsDelay = 200,
    doFitToPins = true,
    animate = true,
    hasControls = true,
    hasGeocoder = true,
    styleChangeCallback,
    customFlyTo,
    lockLatLon = false,
}: IMap) {
    const [map, setMap] = useState<mapboxgl.Map>()
    const [mapStyle, setMapStyle] = useState<string>(
        mapDefaultStyle || MAP_DEFAULT_STYLE
    )
    const mapContainerRef = useRef<HTMLDivElement>(null)

    // Pins state to be able to remove them
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setMarkers] = useState<mapboxgl.Marker[]>([])
    // "New location" marker state to avoid recreating it every render
    const [marker, setMarker] = useState<mapboxgl.Marker>()
    // Geocoder state to avoid recreating it every render
    const [geocoder, setGeocoder] = useState<MapboxGeocoder>()

    // MAP INIT ---------------------------------------------------------------
    useEffect(() => {
        const newMap = initializeMap({
            layer: mapStyle,
            mapContainerRef: mapContainerRef,
            ...mapConfigs,
        })
        setMap(newMap)

        return () => {
            newMap?.remove()
        }
    }, [])

    // UTIL FUNCTIONS ---------------------------------------------------------
    const removeLayers = (map: mapboxgl.Map) => {
        removeHeatmap(map)
        removeLines(map)
    }

    const setupLayers = (map: mapboxgl.Map) => {
        delay(actionsDelay).then(() => {
            setupHeatmap({
                map,
                heatmap,
                ...mapStyleToggler[mapStyle].heatmapConfig,
            })
            setupLines({ map, lines, ...mapStyleToggler[mapStyle].linesConfig })
        })
    }

    const _setCoords = useCallback(
        (lon: number, lat: number) => {
            if (!coords || !setCoords || lockLatLon) return
            // INFO: only setCoords if coords and setCoords were provided
            setCoords({
                lat,
                lon,
            })
        },
        [setCoords, coords]
    )

    const setupGeocoder = useCallback(
        (map: mapboxgl.Map, searchPlaceholder: string) => {
            const newGeocoder =
                geocoder ??
                new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    mapboxgl: mapboxgl,
                    marker: false,
                })

            if (!geocoder) {
                newGeocoder
                    .on("result", (e) => {
                        _setCoords(
                            +e.result.center[0].toFixed(4),
                            +e.result.center[1].toFixed(4)
                        )
                    })
                    .on("clear", () => {
                        map.jumpTo({ center: [0, 0], zoom: 0 })
                        if (boundingBoxCallback) boundingBoxCallback()
                    })

                map.addControl(newGeocoder, "top-right")

                setGeocoder(newGeocoder)
            }

            newGeocoder.setPlaceholder(searchPlaceholder)
        },
        [geocoder]
    )

    const setupNewLocationMarker = useCallback(
        (map: mapboxgl.Map) => {
            if (coords && setCoords) {
                const newMarker =
                    marker ??
                    new mapboxgl.Marker({
                        element: getMarkerElement({
                            pinStyle: "map-new-location-marker.png",
                            lat: coords.lat,
                            lon: coords.lon,
                        }),
                        anchor: "bottom",
                        draggable: !lockLatLon,
                    })
                        .setLngLat(coords)
                        .addTo(map)

                if (!marker) {
                    newMarker.on("dragend", () => {
                        const lngLat = newMarker.getLngLat()
                        _setCoords(lngLat.lng, lngLat.lat)
                    })

                    setMarker(newMarker)
                }
            } else {
                if (marker) {
                    marker.remove()
                    setMarker(undefined)
                }
            }
        },
        [marker, _setCoords]
    )

    const resize = useCallback(() => {
        if (!map) return
        map.resize()
    }, [map])

    const updateLanguage = (map: mapboxgl.Map) => {
        map.setLayoutProperty("country-label", "text-field", [
            "get",
            `name_${language}`,
        ])
    }

    const setupEvents = (map: mapboxgl.Map) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map.on("click", (e: any) => {
            _setCoords(+e.lngLat.lng.toFixed(4), +e.lngLat.lat.toFixed(4))
        })
            .on("load", () => {
                updateLanguage(map)
            })
            .on("idle", () => {
                resize() // Resizes canvas after window resize
                // Shares the map bounds as long as it has height and width
                if (!boundingBoxCallback) return
                const canvasHeight = mapContainerRef.current?.clientHeight || 0
                const canvasWidth = mapContainerRef.current?.clientHeight || 0
                if (canvasHeight + canvasWidth === 0)
                    return boundingBoxCallback()

                return boundingBoxCallback(map.getBounds())
            })
    }

    // MAP SETUP (AFTER INIT) -------------------------------------------------
    useEffect(() => {
        if (!map) return

        setupNewLocationMarker(map)
        setupEvents(map)

        window.addEventListener("force-resize", resize)
        return () => window.removeEventListener("force-resize", resize)
    }, [map, _setCoords, resize])

    // COORDS EFFECT ----------------------------------------------------------
    useEffect(() => {
        if (!map) return
        if (!marker || !coords || !coords.lat || !coords.lon || !setCoords)
            return

        marker.setLngLat([coords.lon, coords.lat])
    }, [coords, map])

     //Commented out because it's not used in the new version (Search bar is not used in the new version)
    // GEOCODER CHANGE EFFECT -------------------------------------------------
    // useEffect(() => {
    //     if (!map || !hasGeocoder) return

    //     setupGeocoder(map, searchPlaceholder)
    // }, [map, hasGeocoder, searchPlaceholder])

    // LANGUAGE CHANGE EFFECT -------------------------------------------------
    useEffect(() => {
        if (!map || !map.isStyleLoaded()) return

        updateLanguage(map)
    }, [map, language])

    // STYLE CHANGE EFFECT ----------------------------------------------------
    useEffect(() => {
        if (!map) return

        // Since layers are removed when style changes,
        // we clear and setup every layer but pins when it happens.
        removeLayers(map)
        map.setStyle(mapStyle)
        setupLayers(map)

        if (styleChangeCallback) styleChangeCallback(mapStyle)
    }, [mapStyle, map])

    // MAP ELEMENTS (PINS, HEATMAP, LINES) EFFECTS ----------------------------
    useEffect(() => {
        if (!map) return

        removeHeatmap(map)
        delay(actionsDelay).then(() => {
            setupHeatmap({
                map,
                heatmap,
                ...mapStyleToggler[mapStyle].heatmapConfig,
            })
        })
    }, [heatmap, map])

    useEffect(() => {
        if (!map) return

        removeLines(map)
        delay(actionsDelay).then(() => {
            setupLines({ map, lines, ...mapStyleToggler[mapStyle].linesConfig })
        })
    }, [lines, map])

    const _setMarkers = useCallback((newMarkers: mapboxgl.Marker[]) => {
        return setMarkers((prev) => {
            prev.forEach((marker) => marker.remove())
            return newMarkers
        })
    }, [])

    useEffect(() => {
        if (!map) return

        _setMarkers([])
        setupPins(map, pins, _setMarkers)
    }, [pins, map])

    // FIT TO PINS EFFECT -----------------------------------------------------
    useEffect(() => {
        if (!map || !doFitToPins || !pins || pins.length === 0) return

        try {
            map.fitBounds(getPinsBounds(pins), {
                padding: 80,
                maxZoom: 6,
                animate,
            })
        } catch (err) {
            console.error(err)
        }
    }, [doFitToPins, map, pins])

    // CUSTOM FLY TO EFFECT ---------------------------------------------------
    useEffect(() => {
        if (!map || !customFlyTo) return

        map.flyTo({ center: customFlyTo, zoom: 4 })
    }, [customFlyTo, map])

    // CSS --------------------------------------------------------------------
    useEffect(() => {
        // TODO: test why this line is needed
        mapContainerRef.current?.classList.remove("mapboxgl-map")
    }, [mapContainerRef])

    return (
        <div className="relative w-full h-full overflow-hidden">
            <div
                className="w-full h-full"
                ref={mapContainerRef}
            />

            {map && hasControls && (
                <>
                    <CustomMapControls
                        map={map}
                        toggleStyle={
                            canToggleMapStyle
                                ? () => {
                                      setMapStyle(
                                          mapStyleToggler[mapStyle].nextStyle
                                      )
                                  }
                                : undefined
                        }
                        miniMap={
                            mapStyleToggler[mapStyle].styleMiniImgPath ? (
                                <div className="w-[60px] h-[60px]">
                                    <img
                                        src={
                                            mapStyleToggler[mapStyle]
                                                .styleMiniImgPath
                                        }
                                    />
                                </div>
                            ) : undefined
                        }
                    />
                    {children}
                </>
            )}
        </div>
    )
}
