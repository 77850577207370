import { useQuery } from "react-query"
import { suggestionsPOST } from "../utils/networking/asset"

const useSuggestedStagesAndRisksQuery = (defaultVarietyId?: string) => {
    return useQuery({
        queryKey: ["suggestions", defaultVarietyId],
        enabled: !!defaultVarietyId,
        queryFn: () =>
            defaultVarietyId ? suggestionsPOST(defaultVarietyId) : null,
    })
}

export default useSuggestedStagesAndRisksQuery
