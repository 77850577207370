import { useContext, useMemo } from "react"
import { IVariable } from "../../types"
import { RiskProfilesContext } from "../../providers/RiskProfilesProvider"
import { useAlerts } from "../../providers"
import { IPrimitivesDictionary } from "../../climateui/types"

export default function useDashboardRisks(
    selectedLocations: string | string[],
    selectedVarieties: (string | null)[],
    selectedVariables: (string | null)[],
    variables: Record<string, IVariable>,
    varietiesDict: IPrimitivesDictionary,
    isDefault: boolean
) {
    let _selectedLocations: string[] = []

    if (selectedLocations && typeof selectedLocations === "string")
        _selectedLocations = [selectedLocations]
    else if (selectedLocations && Array.isArray(selectedLocations))
        _selectedLocations = [...selectedLocations]

    const { riskProfiles, loadingRisks } = useContext(RiskProfilesContext)
    const { alertSettings, loadingAlertSettings } = useAlerts()

    const { alertSettingsByVariable, varietiesIds } = useMemo(() => {
        const result: Record<string, string[]> = {}
        const alertSettingsByRiskId: Record<string, string[]> = {}

        // filter alert settings by location ids
        const alertSettingsByLocation = alertSettings.filter((setting) =>
            _selectedLocations.includes(setting.location_id)
        )

        // initialize the variable result
        Object.keys(variables).forEach((v: string) => {
            result[v] = []
        })

        // initialize settings by risk id
        for (const alertSetting of alertSettingsByLocation) {
            if (!alertSettingsByRiskId[alertSetting.risk_profile_id])
                alertSettingsByRiskId[alertSetting.risk_profile_id] = []

            alertSettingsByRiskId[alertSetting.risk_profile_id].push(
                alertSetting.id
            )
        }

        // if no selected varieties and no dashboard list of varieties
        // no filter
        let varietiesToFilter: (string | null)[] = []

        // if varieties selected, filter by those
        if (selectedVarieties.length > 0) varietiesToFilter = selectedVarieties
        // if no varieties, but existing varieties in dashboard
        else if (
            !isDefault &&
            selectedVarieties.length === 0 &&
            Object.keys(varietiesDict).length > 0
        ) {
            varietiesToFilter = Object.keys(varietiesDict)
        }

        riskProfiles.forEach((r) => {
            // risk profile does not have a variety that corresponds
            // to one of the selected
            if (
                varietiesToFilter.length > 0 &&
                !r.varieties.find((v) => [...varietiesToFilter].includes(v.id))
            )
                return

            r.hazard_profiles.forEach((h) => {
                if (
                    h.hazard_variable.dashboard_variable &&
                    h.hazard_variable.dashboard_variable in variables
                ) {
                    const settings = alertSettingsByRiskId[r.id] ?? []
                    result[h.hazard_variable.dashboard_variable] =
                        result[h.hazard_variable.dashboard_variable].concat(
                            settings
                        )
                }
            })
        })

        // Precipitation and Sum Precipitation Alerts will be shown together for now, while
        // Sum Precipitation Chart is ready to be displayed
        result["precipitation"] = result["precipitation"].concat(
            result["precipitation_sum"]
        )

        return {
            alertSettingsByVariable: result,
            varietiesIds: varietiesToFilter,
        }
    }, [
        riskProfiles,
        alertSettings,
        _selectedLocations,
        selectedVarieties,
        varietiesDict,
        variables,
    ])

    // When no variables selected, use all alert settings available
    const alertSettingsIds = useMemo(() => {
        if (
            loadingRisks ||
            loadingAlertSettings ||
            (!loadingRisks &&
                !loadingAlertSettings &&
                riskProfiles.length === 0)
        )
            return []
        else if (
            !loadingRisks &&
            !loadingAlertSettings &&
            selectedVariables.length === 0
        ) {
            return Object.values(alertSettingsByVariable).flat()
        } else {
            return selectedVariables
                .map((v) => v && alertSettingsByVariable[v])
                .filter((s) => !!s)
                .flat()
        }
    }, [
        selectedVariables,
        riskProfiles,
        alertSettings,
        alertSettingsByVariable,
        loadingAlertSettings,
    ])

    return {
        alertSettingsByVariable,
        loadingRisks,
        loadingAlertSettings,
        riskProfiles,
        alertSettings,
        alertSettingsIds,
        varietiesIds,
    }
}
