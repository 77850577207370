export const TemperatureIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M15 13V5C15 4.20435 14.6839 3.44129 14.1213 2.87868C13.5587 2.31607 12.7956 2 12 2C11.2044 2 10.4413 2.31607 9.87868 2.87868C9.31607 3.44129 9 4.20435 9 5V13C8.16047 13.6296 7.54033 14.5075 7.22743 15.5092C6.91453 16.5108 6.92473 17.5856 7.25658 18.5811C7.58844 19.5767 8.22512 20.4426 9.07645 21.0562C9.92778 21.6698 10.9506 22 12 22C13.0494 22 14.0722 21.6698 14.9236 21.0562C15.7749 20.4426 16.4116 19.5767 16.7434 18.5811C17.0753 17.5856 17.0855 16.5108 16.7726 15.5092C16.4597 14.5075 15.8395 13.6296 15 13ZM12 4C12.2652 4 12.5196 4.10536 12.7071 4.29289C12.8946 4.48043 13 4.73478 13 5V9H11V5C11 4.73478 11.1054 4.48043 11.2929 4.29289C11.4804 4.10536 11.7348 4 12 4Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const SoilTemperatureIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.67637 20H2.5V22H8.26935C7.616 21.4466 7.07255 20.7675 6.67637 20ZM16.7306 22H22.5V20H18.3236C17.9274 20.7675 17.384 21.4466 16.7306 22Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.0495 17H22.5V15H18.7385C18.9405 15.6305 19.0495 16.3025 19.0495 17ZM6.26142 15C6.05947 15.6305 5.95044 16.3025 5.95044 17H2.5V15H6.26142Z"
                fill={props.fill}
            />
            <path
                d="M15.5 13V5C15.5 4.20435 15.1839 3.44129 14.6213 2.87868C14.0587 2.31607 13.2956 2 12.5 2C11.7044 2 10.9413 2.31607 10.3787 2.87868C9.81607 3.44129 9.5 4.20435 9.5 5V13C8.66047 13.6296 8.04033 14.5075 7.72743 15.5092C7.41453 16.5108 7.42473 17.5856 7.75658 18.5811C8.08844 19.5767 8.72512 20.4426 9.57645 21.0562C10.4278 21.6698 11.4506 22 12.5 22C13.5494 22 14.5722 21.6698 15.4236 21.0562C16.2749 20.4426 16.9116 19.5767 17.2434 18.5811C17.5753 17.5856 17.5855 16.5108 17.2726 15.5092C16.9597 14.5075 16.3395 13.6296 15.5 13ZM12.5 4C12.7652 4 13.0196 4.10536 13.2071 4.29289C13.3946 4.48043 13.5 4.73478 13.5 5V9H11.5V5C11.5 4.73478 11.6054 4.48043 11.7929 4.29289C11.9804 4.10536 12.2348 4 12.5 4Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const PrecipitationIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M17.89 8.43C17.38 5.86 15.12 4 12.5 4C10.44 4 8.58 5.14 7.63 6.97L7.13 7.92L6.06 8.03C4.03 8.24 2.5 9.95 2.5 12C2.5 13.6177 3.45904 15.0103 4.83985 15.6406L4.34948 17.6021C2.09955 16.7361 0.5 14.5524 0.5 12C0.5 8.91 2.84 6.36 5.85 6.04C7.1 3.64 9.61 2 12.5 2C16.14 2 19.17 4.59 19.85 8.04C22.45 8.22 24.5 10.36 24.5 13C24.5 15.76 22.26 18 19.5 18H18.25L18.75 16H19.5C21.15 16 22.5 14.65 22.5 13C22.5 11.45 21.28 10.14 19.72 10.04L18.19 9.93L17.89 8.43Z"
                fill={props.fill}
            />
            <path
                d="M15.5 21H13.5L15.5 13H17.5L15.5 21Z"
                fill={props.fill}
            />
            <path
                d="M13.5 13H11.5L9.5 21H11.5L13.5 13Z"
                fill={props.fill}
            />
            <path
                d="M7.5 21H5.5L7.5 13H9.5L7.5 21Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const RelativeHumidityIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 13.8C4.5 10.48 7.17 6.55 12.5 2C17.83 6.55 20.5 10.48 20.5 13.8C20.5 18.78 16.7 22 12.5 22C8.3 22 4.5 18.78 4.5 13.8ZM18.4973 14H6.5027C6.5009 13.9337 6.5 13.867 6.5 13.8C6.5 11.46 8.45 8.36 12.5 4.66C16.55 8.36 18.5 11.45 18.5 13.8C18.5 13.867 18.4991 13.9337 18.4973 14Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const WaterBudgetIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <rect
                x="12"
                y="13"
                width="7"
                height="2"
                transform="rotate(90 12 13)"
                fill={props.fill}
            />
            <rect
                x="8"
                y="16"
                width="4"
                height="2"
                transform="rotate(90 8 16)"
                fill={props.fill}
            />
            <rect
                x="16"
                y="10"
                width="10"
                height="2"
                transform="rotate(90 16 10)"
                fill={props.fill}
            />
            <path
                d="M7 11.4C8.66 11.4 10 10.06 10 8.4C10 6.4 7 3 7 3C7 3 4 6.4 4 8.4C4 10.06 5.34 11.4 7 11.4Z"
                fill={props.fill}
            />
            <rect
                x="20"
                y="7"
                width="13"
                height="2"
                transform="rotate(90 20 7)"
                fill={props.fill}
            />
        </svg>
    )
}

export const EvapotranspirationIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            className="fill-inherit"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.58515 11.2414C6.73515 10.2257 6.48515 9.21 5.91515 8.41286L5.84515 8.33571C4.81515 7.03714 4.34515 5.30143 4.54515 3.55286L4.60515 3H6.49515L6.40515 3.75857C6.25515 4.77429 6.50515 5.79 7.07515 6.58714L7.14515 6.67714C8.17515 7.96286 8.65515 9.69857 8.44515 11.4471L8.38515 12H6.47515L6.58515 11.2414Z"
                fill={props.fill}
            />
            <path
                d="M10.5851 11.2414C10.7351 10.2257 10.4851 9.21 9.91515 8.41286L9.84515 8.33571C8.81515 7.03714 8.34515 5.30143 8.54515 3.55286L8.60515 3H10.4951L10.4051 3.75857C10.2551 4.77429 10.5051 5.79 11.0751 6.58714L11.1451 6.67714C12.1751 7.96286 12.6551 9.69857 12.4451 11.4471L12.3851 12H10.4751L10.5851 11.2414Z"
                fill={props.fill}
            />
            <path
                d="M7.5 18C4.73858 18 2.5 15.7614 2.5 13H3.5C6.26142 13 8.5 15.2386 8.5 18H7.5Z"
                fill={props.fill}
            />
            <path
                d="M15.5 13C15.5 15.7614 13.2614 18 10.5 18H9.5C9.5 15.2386 11.7386 13 14.5 13H15.5Z"
                fill={props.fill}
            />
            <path
                d="M2.5 19H20.5V21H2.5V19Z"
                fill={props.fill}
            />
            <path
                d="M18.5 3L22.5 7H19.5V16H17.5V7H14.5L18.5 3Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const SolarRadiationIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M12.5 7C9.74 7 7.5 9.24 7.5 12C7.5 14.76 9.74 17 12.5 17C15.26 17 17.5 14.76 17.5 12C17.5 9.24 15.26 7 12.5 7Z"
                fill={props.fill}
            />
            <path
                d="M11.5 1V5L13.5 5L13.5 1L11.5 1Z"
                fill={props.fill}
            />
            <path
                d="M11.5 23L13.5 23V19H11.5L11.5 23Z"
                fill={props.fill}
            />
            <path
                d="M19.5 11V13H23.5V11L19.5 11Z"
                fill={props.fill}
            />
            <path
                d="M5.5 11L1.5 11L1.5 13H5.5V11Z"
                fill={props.fill}
            />
            <path
                d="M4.37 5.28L6.84 7.75L8.25 6.34L5.78 3.87L4.37 5.28Z"
                fill={props.fill}
            />
            <path
                d="M6.84 16.24L4.37 18.71L5.78 20.12L8.25 17.65L6.84 16.24Z"
                fill={props.fill}
            />
            <path
                d="M19.22 3.87L16.75 6.34L18.16 7.75L20.63 5.28L19.22 3.87Z"
                fill={props.fill}
            />
            <path
                d="M23.4999 22.9999V16.9999L21.2071 19.2928L18.1568 16.2426L16.7426 17.6568L19.7928 20.707L17.4999 22.9999H23.4999Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const GrowingDegreeDaysIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <rect
                x="11.5"
                y="5"
                width="2"
                height="13"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.25739 7.24268L12.5 3L16.7427 7.24268H13.5V11.5563H11.5V7.24268H8.25739ZM4.5 9C4.5 11.7614 6.73859 14 9.5 14H10.5C10.5 11.2386 8.26141 9 5.5 9H4.5ZM15.5 16C18.2614 16 20.5 13.7614 20.5 11H19.5C16.7386 11 14.5 13.2386 14.5 16H15.5ZM21.5 18H3.5V20H21.5V18Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const SoilMoistureIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <rect
                x="3.5"
                y="18"
                width="18"
                height="2"
                fill={props.fill}
            />
            <rect
                x="3.5"
                y="13"
                width="18"
                height="2"
                fill={props.fill}
            />
            <path
                d="M12.5 10.4C14.16 10.4 15.5 9.06 15.5 7.4C15.5 5.4 12.5 2 12.5 2C12.5 2 9.5 5.4 9.5 7.4C9.5 9.06 10.84 10.4 12.5 10.4Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const WindSpeedIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M15 18C15 19.65 13.65 21 12 21C10.35 21 9 19.65 9 18H11C11 18.55 11.45 19 12 19C12.55 19 13 18.55 13 18C13 17.45 12.55 17 12 17H2.5V15H12C13.65 15 15 16.35 15 18ZM19.5 5.5C19.5 3.57 17.93 2 16 2C14.07 2 12.5 3.57 12.5 5.5H14.5C14.5 4.67 15.17 4 16 4C16.83 4 17.5 4.67 17.5 5.5C17.5 6.33 16.83 7 16 7H2.5V9H16C17.93 9 19.5 7.43 19.5 5.5ZM19 11H2.5V13H19C19.83 13 20.5 13.67 20.5 14.5C20.5 15.33 19.83 16 19 16V18C20.93 18 22.5 16.43 22.5 14.5C22.5 12.57 20.93 11 19 11Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const MaxWindSpeedIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M14.5 18C14.5 19.65 13.15 21 11.5 21C9.85 21 8.5 19.65 8.5 18H10.5C10.5 18.55 10.95 19 11.5 19C12.05 19 12.5 18.55 12.5 18C12.5 17.45 12.05 17 11.5 17H2V15H11.5C13.15 15 14.5 16.35 14.5 18ZM19 5.5C19 3.57 17.43 2 15.5 2C13.57 2 12 3.57 12 5.5H14C14 4.67 14.67 4 15.5 4C16.33 4 17 4.67 17 5.5C17 6.33 16.33 7 15.5 7H10V9H15.5C17.43 9 19 7.43 19 5.5ZM18.5 11H2V13H18.5C19.33 13 20 13.67 20 14.5C20 15.33 19.33 16 18.5 16V18C20.43 18 22 16.43 22 14.5C22 12.57 20.43 11 18.5 11Z"
                fill={props.fill}
            />
            <path
                d="M9 6H3V8H9V6Z"
                fill={props.fill}
            />
            <path
                d="M7 10L7 4L5 4L5 10L7 10Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const ChillHoursIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.17 13L2 13L2 11L6.17 11L2.93 7.76L4.34 6.34L9 11L11 11L11 9L6.34 4.34L7.76 2.93L11 6.17L11 2L13 2L13 6.17L13 9L13 11L13 13L13 15L13 17.83L13 22L11 22L11 17.83L7.76 21.07L6.34 19.66L11 15L11 13L9 13L4.34 17.66L2.93 16.24L6.17 13Z"
                fill={props.fill}
            />
            <path
                d="M15 21.542C19.0571 20.2677 22 16.4774 22 11.9998C22 7.52215 19.0571 3.73186 15 2.4576L15 4.58131C15.6937 4.86211 16.3391 5.2374 16.9201 5.6912L15.4915 7.11978C16.0368 7.5106 16.5139 7.99054 16.9014 8.53831L18.3311 7.10864C19.185 8.21235 19.7568 9.54547 19.9381 10.9998L17.917 10.9998C17.9716 11.325 18 11.6591 18 11.9998C18 12.3405 17.9716 12.6746 17.917 12.9998L19.9381 12.9998C19.756 14.4605 19.18 15.7989 18.3198 16.9055L16.8907 15.4764C16.5016 16.0229 16.0231 16.5014 15.4765 16.8906L16.9056 18.3197C16.3283 18.7684 15.688 19.1398 15 19.4183L15 21.542Z"
                fill={props.fill}
            />
        </svg>
    )
}
