import { useState, KeyboardEvent, useContext, useMemo, FocusEvent } from "react"
import {
    IDashboardFilters,
    IDashboard,
    IEmailDashboard,
    IScheduleFilters,
    IScheduler,
} from "../../../../types"
import DASHBOARD_VARIABLES from "../../Dashboards/dashboardVariables"
import {
    IBooleanDictionary,
    IPrimitivesDictionary,
} from "../../../../climateui/types"
import { SingleSelectFilter, Button } from "../../../../climateui/components"
import { useTranslate } from "@tolgee/react"
import { GenericPageHeader } from "../../../../components"
import { CancelIcon, PlusIcon } from "../../../../climateui/icons"
import queryClient, {
    dashboardSEND,
    schedulePOST,
    schedulePUT,
} from "../../../../utils/networking"
import { ToastContext } from "../../../../climateui/providers"
import { useAccount } from "../../../../providers/AccountProvider"
import { useAuth } from "../../../../providers"
import { USER_TIMEZONES } from "../../../Users/usersTableUtils"
import ScheduleFilters from "./ScheduleFilters"
import useDashboardLocations from "../../../../hooks/Dashboards/useDashboardLocations"
import { DEFAULT_DASHBOARD_TEMPLATE_IDS } from "../../Dashboards/utils"

export const COMMON_INPUT_CLASSES =
    "px-1 border border-gray-14 focus:outline-none focus:border-accent cursor-pointer focus:cursor-text rounded-md hover:border-gray-14 placeholder:text-gray-30"

interface IScheduleForm {
    isOpen: boolean
    sendNow: boolean
    dashboard?: IDashboard
    schedule?: IScheduler
    onCancel: () => void
    reloadSchedules: () => void
}

export function OpenScheduleForm({
    sendNow,
    dashboard,
    schedule,
    onCancel = () => void 0,
    reloadSchedules,
}: IScheduleForm) {
    const { t } = useTranslate()
    const { enqueueAlert } = useContext(ToastContext)
    const { selectedAccount } = useAccount()
    const auth = useAuth()
    const variables = DASHBOARD_VARIABLES

    const { allLocationsDict, customLocations } = useDashboardLocations(
        dashboard?.locations ?? [],
        DEFAULT_DASHBOARD_TEMPLATE_IDS.includes(dashboard?.id ?? "")
    )
    const customLocationsDict = {} as IPrimitivesDictionary
    const customRegionsDict = {} as IPrimitivesDictionary

    // populate the custom regigons/locations with the dashboard data
    customLocations.forEach((loc) => {
        if (!loc.id || !allLocationsDict[loc.id]) return

        customLocationsDict[loc.id] = loc.name
        const regionId = loc.region?.id ?? ""
        customRegionsDict[regionId] = loc.region?.name ?? ""
    })

    const populateFilterSet = (filterSet: IDashboardFilters[]) => {
        const singleFilterSet: IScheduleFilters = {}
        const variablesInFilterSet: IBooleanDictionary = {}
        const regionsInFilterSet: IBooleanDictionary = {}
        filterSet.forEach((filter: IDashboardFilters) => {
            if (filter.prop === "locId") {
                singleFilterSet.locId = filter.value
            }
            if (filter.prop === "region") {
                const regionId = Object.keys(customRegionsDict).find(
                    (regionKey) => {
                        return customRegionsDict[regionKey] === filter.value
                    }
                ) as string
                regionsInFilterSet[regionId] = true
            }
            if (filter.prop === "scope") {
                singleFilterSet.scope = filter.value
            }
            if (filter.prop === "variables") {
                variablesInFilterSet[filter.value] = true
            }
            if (filter.prop === "directionality") {
                singleFilterSet.directionality = filter.value
            }
        })
        singleFilterSet.variables = variablesInFilterSet
        singleFilterSet.regions = regionsInFilterSet

        return singleFilterSet
    }

    const {
        scheduleLocation,
        scheduleRegions,
        scheduleScope,
        scheduleVariables,
        scheduleFilterSets,
        scheduleRecurrence,
        scheduleDistribution,
        scheduleEmails,
        scheduleHour,
        newSchedule,
    } = useMemo(() => {
        let scheduleLocation = Object.keys(customLocationsDict)[0]
        const scheduleRegions: IBooleanDictionary = {}
        let scheduleScope = "daily"
        let scheduleRecurrence = "daily"
        let scheduleDistribution = "0"
        let scheduleHour = 6
        let newSchedule = {}
        const scheduleFilterSets: IScheduleFilters[] = []
        const scheduleVariables: IBooleanDictionary = {}
        const scheduleEmails: IBooleanDictionary = {}
        if (schedule?.id) {
            scheduleHour = schedule.day_hour as number
            if (schedule.filter_groups) {
                schedule.filter_groups.forEach((filterSet) => {
                    const singleFilterSet = populateFilterSet(filterSet)
                    scheduleFilterSets.push(singleFilterSet)
                })
            }
            if (schedule?.run_daily) {
                scheduleRecurrence = "daily"
            }
            if ((schedule?.week_day as number) >= 0) {
                scheduleRecurrence = "weekly"
            }
            if ((schedule?.month_day as number) > 0) {
                scheduleRecurrence = "monthly"
            }
            schedule?.emails &&
                schedule.emails.map((email) => {
                    scheduleEmails[email] = true
                })
        } else {
            const urlParams = new URLSearchParams(location.search)
            for (const [key, value] of urlParams) {
                if (key === "scope") {
                    scheduleScope = value
                } else if (key === "locId") {
                    scheduleLocation = value
                } else if (key === "region") {
                    const regionId = Object.keys(customRegionsDict).find(
                        (regionKey) => customRegionsDict[regionKey] === value
                    )
                    scheduleRegions[regionId as string] = true
                } else if (key === "variables") {
                    scheduleVariables[value] = true
                } else if (key === "directionality") {
                    scheduleDistribution = value
                }
            }
            newSchedule = {
                dashboard_id: dashboard?.id,
                account_id: selectedAccount,
                user_id: auth.user?.id,
                created_by_email: auth.user?.email,
                subject: `${dashboard?.title} ${t("scheduledReport")}`,
                body: "",
            }
        }

        return {
            scheduleLocation,
            scheduleRegions,
            scheduleScope,
            scheduleVariables,
            scheduleFilterSets,
            scheduleRecurrence,
            scheduleDistribution,
            scheduleEmails,
            scheduleHour,
            newSchedule,
        }
    }, [schedule])

    const [workingSchedule, setWorkingSchedule] = useState<IScheduler>(
        schedule ?? newSchedule
    )

    const [scheduleBodyCharacters, setScheduleBodyCharacters] = useState(
        schedule?.body?.length ?? 0
    )

    const templateFilters: IScheduleFilters = {
        scope: scheduleScope,
        directionality: scheduleDistribution,
        variables:
            Object.keys(scheduleVariables).length === 0
                ? { temp_max: true, temp_min: true, precipitation: true }
                : scheduleVariables,
        locId: dashboard?.dtype === "Location" ? scheduleLocation : "",
        regions:
            dashboard?.dtype === "Regional"
                ? Object.keys(scheduleRegions).reduce((prev, curr) => {
                      if (curr) prev[curr] = true
                      return prev
                  }, {} as { [key: string]: boolean })
                : {},
    }

    const [allFiltersSet, setAllFiltersSet] = useState(
        Object.keys(scheduleFilterSets).length === 0
            ? ([templateFilters] as IScheduleFilters[])
            : scheduleFilterSets
    )

    const allVariablesSelected: IBooleanDictionary = {}
    Object.keys(variables).forEach((v) => {
        if (v !== "precipitation_sum") {
            allVariablesSelected[v] = true
        }
    })

    const [recurrence, setRecurrence] = useState(scheduleRecurrence)

    const [selectedDay, setSelectedDay] = useState(
        schedule?.week_day?.toString() ?? "0"
    )

    const [emailsList, setEmailsList] = useState(scheduleEmails)

    const daysDict = {
        0: "Sunday",
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
    } as IPrimitivesDictionary

    const scheduleRecurrenceDict = {
        daily: t("daily"),
        weekly: t("weekly"),
        monthly: t("monthly"),
    } as IPrimitivesDictionary

    //Day of the Month
    const dayNumbersDict = {} as IPrimitivesDictionary

    for (let day = 1; day < 32; day++) {
        dayNumbersDict[day] = day.toString()
    }

    const [dayNumber, setDayNumber] = useState(
        schedule?.month_day?.toString() ?? "15"
    )

    //Time Period and Hour of the Day
    const [hourOfRecurrence, setHourOfRecurrence] = useState(
        scheduleHour > 12
            ? (scheduleHour - 12).toString()
            : scheduleHour.toString()
    )
    const [timePeriod, setTimePeriod] = useState(
        scheduleHour >= 12 ? "PM" : "AM"
    )

    // if schedule is new, set user timezone as default
    const { user } = useAuth()

    const initialTimezone = useMemo(() => {
        let currTimezone = workingSchedule.timezone ?? user?.timezone
        currTimezone = Object.keys(USER_TIMEZONES).find(
            (k) => USER_TIMEZONES[k] === currTimezone
        )

        return currTimezone ?? "UTC"
    }, [])

    const [timezone, setTimezone] = useState<string>(initialTimezone)

    const hoursLabels = [...Array(12).keys()]
    const hoursDict = {} as IPrimitivesDictionary
    for (const hour of hoursLabels) {
        hoursDict[hour + 1] = [hour + 1].toString() + ":00"
    }

    const handleKeyPress = () => {
        const emailAddress = document.getElementById(
            "email_addresses"
        ) as HTMLInputElement
        setEmailsList({
            ...emailsList,
            [emailAddress.value]: true,
        })
        emailAddress.value = ""
        setWorkingSchedule({
            ...workingSchedule,
            emails: Object.keys(emailsList).filter((email) => {
                return emailsList[email]
            }),
        })
    }

    const sendDashboard = async () => {
        const project = process.env.REACT_APP_PROJECT_NAME
        const refresh_token = localStorage.getItem(
            `climateai-${project}-refresh-token`
        ) as string
        const dashboardURLsArray: string[] = []
        allFiltersSet.forEach((filterSet) => {
            const dashboardURL = new URL(window.location.href)
            const urlParams = new URLSearchParams(dashboardURL.search)
            urlParams.delete("variables")
            urlParams.delete("scope")
            urlParams.delete("locId")
            urlParams.delete("region")
            urlParams.delete("directionality")
            urlParams.delete("varieties")

            if (selectedAccount) {
                urlParams.set("account-id", selectedAccount)
            }
            if (filterSet.locId) {
                urlParams.set("locId", filterSet.locId)
            }
            if (filterSet.scope) urlParams.set("scope", filterSet.scope)
            if (filterSet.directionality && dashboard?.dtype === "Location")
                urlParams.set("directionality", filterSet.directionality)

            dashboardURL.search = urlParams.toString()
            if (filterSet.variables) {
                Object.keys(filterSet.variables).forEach((v) => {
                    dashboardURL.search += "&variables=" + v
                })
            }
            if (filterSet.regions) {
                Object.keys(filterSet.regions).forEach((v) => {
                    dashboardURL.search += "&region=" + customRegionsDict[v]
                })
            }

            if (dashboardURL.href.includes("localhost")) {
                const split_url = dashboardURL.href.split("seasonal")
                dashboardURL.href =
                    "https://climate-platform-dev.web.app/seasonal" +
                    split_url[1]
            }
            dashboardURLsArray.push(dashboardURL.href)
        })

        const dashboardToSend: IEmailDashboard = {
            dashboard_urls: dashboardURLsArray,
            dashboard_url: dashboardURLsArray[0],
            refresh_token: refresh_token,
            dashboard_name: "",
            dashboard_title: dashboard?.title,
            account_id: selectedAccount,
            user_id: auth.user?.id,
            emails: Object.keys(emailsList).filter((email) => {
                return emailsList[email]
            }),
            subject: workingSchedule.subject,
            body: workingSchedule.body,
        }

        await dashboardSEND(dashboardToSend).then(() =>
            enqueueAlert(
                t("DASHBOARDSentSuccesfully", "Dashboard Sent Succesfully!")
            )
        )
        onCancel()
    }

    const postSchedule = async () => {
        const scheduleFilterSets: IDashboardFilters[][] = []
        allFiltersSet.forEach((filter) => {
            const variablesFilter: IDashboardFilters[] = []
            Object.keys(filter.variables as IBooleanDictionary).forEach(
                (variable) => {
                    variablesFilter.push({
                        prop: "variables",
                        value: variable,
                    })
                }
            )
            const regionsFilter: IDashboardFilters[] = []
            Object.keys(filter.regions as IBooleanDictionary).forEach(
                (region) => {
                    regionsFilter.push({
                        prop: "region",
                        value: customRegionsDict[region],
                    })
                }
            )

            const locRegionFilter =
                dashboard?.dtype === "Location"
                    ? [
                          {
                              prop: "locId",
                              value: filter.locId,
                          },
                      ]
                    : regionsFilter

            scheduleFilterSets.push([
                ...variablesFilter,
                ...locRegionFilter,
                {
                    prop: "scope",
                    value: filter.scope,
                },
                {
                    prop: "directionality",
                    value: filter.directionality,
                },
            ])
        })

        // pHour = parsedHour
        const pHour = parseInt(hourOfRecurrence)

        let hour
        // 12AM is 0
        if (pHour === 12 && timePeriod === "AM") hour = 0
        // add 12 to any hour in the PM, except for 12
        else if (pHour !== 12 && timePeriod === "PM") hour = pHour + 12
        // leave as it is any hour in the AM except for 12, and 12 in the PM
        else hour = pHour

        const updatedSchedule = {
            ...workingSchedule,
            run_daily: recurrence === "daily",
            timezone: USER_TIMEZONES[timezone],
            month_day: recurrence === "monthly" ? parseInt(dayNumber) : -1,
            week_day: recurrence === "weekly" ? parseInt(selectedDay) : -1,
            day_hour: hour,
            emails: Object.keys(emailsList).filter((email) => {
                return emailsList[email]
            }),
            filters: [],
            filter_groups: scheduleFilterSets,
            updated_by_email: auth.user?.email,
        }

        if (!schedule?.id) {
            await schedulePOST(updatedSchedule)
                .then(() => {
                    queryClient.invalidateQueries([
                        "schedules",
                        selectedAccount,
                    ])
                    reloadSchedules()
                    enqueueAlert(
                        t(
                            "SCHEDULECreatedSuccessfully",
                            "Schedule created successfully!",

                            { schedule: workingSchedule?.subject }
                        )
                    )
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            schedulePUT(updatedSchedule)
                .then(() => {
                    queryClient.invalidateQueries([
                        "schedules",
                        selectedAccount,
                    ])
                    reloadSchedules()
                    enqueueAlert(
                        t(
                            "SCHEDULEEditedSuccessfully",
                            "Schedule edited successfully!",
                            {
                                schedule: workingSchedule?.subject,
                            }
                        )
                    )
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        onCancel()
    }

    const deleteFilterSet = (rowSelection: number) => {
        const existingFilters =
            allFiltersSet.filter((filter, index) => {
                return index !== rowSelection
            }) || []

        setAllFiltersSet(existingFilters)
    }

    const updateFilterSet = (
        rowSelection: number,
        singleFilterSet: IScheduleFilters,
        filterToUpdate: string,
        valueToUpdate: string | IBooleanDictionary
    ) => {
        let updatedFilterSet: IScheduleFilters = singleFilterSet

        // SPECIFIC CONDITIONS FOR DIRECTIONALITY AND SCOPE:
        // Daily scope doesn't have directionality visualization
        if (
            filterToUpdate === "directionality" &&
            singleFilterSet.scope === "daily" &&
            valueToUpdate === "1"
        ) {
            updatedFilterSet.scope = "weekly"
        } else if (
            filterToUpdate === "scope" &&
            (!singleFilterSet.scope || valueToUpdate === "daily")
        ) {
            updatedFilterSet.directionality = "0"
        }
        updatedFilterSet = {
            ...updatedFilterSet,
            [filterToUpdate]: valueToUpdate,
        }

        const updateAllFilterSets = allFiltersSet
        updateAllFilterSets[rowSelection] = updatedFilterSet
        setAllFiltersSet([...updateAllFilterSets])
    }

    return (
        <div className="fixed inset-0 z-50 w-full h-full bg-gray-100 grid place-content-center bg-opacity-20">
            <div className="bg-white border rounded-lg border-gray-14 px-5 py-2 w-[600px] h-[650px] overflow-y-auto font-roboto divide-y divide-gray-14">
                <div className="-mb-5">
                    <GenericPageHeader
                        pageTitle={
                            sendNow ? "New Email Setup" : "New Report Schedule"
                        }
                        right={
                            <div
                                onClick={() => onCancel()}
                                className="w-6 h-6 cursor-pointer fill-gray-60">
                                <CancelIcon />
                            </div>
                        }
                        bottom={undefined}></GenericPageHeader>
                </div>
                <div>
                    <div
                        key="DashboardFilters"
                        className="my-3">
                        <h1 className="mb-3 font-medium text-gray-90 text-[14px]">
                            Dashboard Filters
                        </h1>
                        {allFiltersSet.map((filterSet, index) => {
                            return (
                                <div
                                    className="flex flex-wrap w-full my-2 align-baseline gap-2"
                                    key={"filterSet"}>
                                    <ScheduleFilters
                                        dashboard={dashboard ?? {}}
                                        locationsRegionsDict={
                                            dashboard?.dtype === "Location"
                                                ? customLocationsDict
                                                : customRegionsDict
                                        }
                                        scheduleFilters={filterSet}
                                        rowSelection={index}
                                        deleteFilterSet={deleteFilterSet}
                                        updateFilterSet={updateFilterSet}
                                    />
                                </div>
                            )
                        })}
                        <button
                            onClick={() => {
                                setAllFiltersSet([
                                    ...allFiltersSet,
                                    templateFilters,
                                ])
                            }}
                            className="flex flex-row items-center my-2 cursor-pointer text-accent body-sm">
                            <span className="w-4 h-4 fill-accent">
                                <PlusIcon />
                            </span>
                            {t("add", "Add")}
                        </button>
                    </div>
                    {sendNow ? (
                        <></>
                    ) : (
                        <div
                            key="RecurrenceFilters"
                            className="my-3">
                            <h1 className="mb-3 font-medium text-gray-90 text-[14px]">
                                Recurrence
                            </h1>
                            <div className="flex align-baseline gap-2">
                                <div className="flex-wrap text-[16px] text-gray-90">
                                    <h1 className="text-gray-60 text-[12px]">
                                        Send every
                                    </h1>
                                    <SingleSelectFilter
                                        placeholder={t("recurrence")}
                                        selected={recurrence}
                                        setSelected={(v) =>
                                            setRecurrence(v.toString())
                                        }
                                        leftRightClass="left-0"
                                        options={
                                            {
                                                ...scheduleRecurrenceDict,
                                            } as { [key: string]: string }
                                        }
                                        canSearch={false}
                                        keepOrder={true}
                                    />
                                </div>
                                {recurrence === "weekly" ? (
                                    <div className="flex-wrap text-gray-90">
                                        <h1 className="text-gray-60 text-[12px]">
                                            Day
                                        </h1>
                                        <SingleSelectFilter
                                            placeholder={t("day")}
                                            searchPlaceholder={t("search")}
                                            selected={selectedDay}
                                            setSelected={(v) =>
                                                setSelectedDay(v.toString())
                                            }
                                            leftRightClass="left-0"
                                            options={
                                                {
                                                    ...daysDict,
                                                } as { [key: string]: string }
                                            }
                                            canSearch={false}
                                            keepOrder={true}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {recurrence === "monthly" ? (
                                    <div className="flex-wrap text-gray-90">
                                        <h1 className="text-gray-60 text-[12px]">
                                            Day
                                        </h1>
                                        <SingleSelectFilter
                                            placeholder="15"
                                            selected={dayNumber}
                                            setSelected={(d) =>
                                                setDayNumber(d.toString())
                                            }
                                            leftRightClass="left-0"
                                            options={
                                                {
                                                    ...dayNumbersDict,
                                                } as { [key: string]: string }
                                            }
                                            canSearch={false}
                                            keepOrder={true}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div className="flex-wrap text-gray-90">
                                    <h1 className="text-gray-60 text-[12px]">
                                        Time
                                    </h1>
                                    <div className="flex">
                                        <SingleSelectFilter
                                            placeholder="hour"
                                            selected={hourOfRecurrence}
                                            setSelected={(h) => {
                                                setHourOfRecurrence(
                                                    h.toString()
                                                )
                                            }}
                                            options={
                                                {
                                                    ...hoursDict,
                                                } as { [key: number]: string }
                                            }
                                            keepOrder={true}
                                        />
                                        <SingleSelectFilter
                                            placeholder="timePeriod"
                                            selected={timePeriod}
                                            setSelected={(p) => {
                                                setTimePeriod(p.toString())
                                            }}
                                            options={
                                                { AM: "AM", PM: "PM" } as {
                                                    [key: string]: string
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex-wrap text-gray-90">
                                    <h1 className="text-gray-60 text-[12px]">
                                        Timezone
                                    </h1>
                                    <div className="flex">
                                        <SingleSelectFilter
                                            canSearch
                                            placeholder="timePeriod"
                                            selected={timezone}
                                            setSelected={(p) => {
                                                setTimezone(p)
                                            }}
                                            options={USER_TIMEZONES}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        key="ReportContent"
                        className="my-5">
                        <h1 className="mb-3 font-medium text-gray-90 text-[14px]">
                            Report Content
                        </h1>
                        <h1 className="text-gray-60 text-[12px] mt-3 mb-1">
                            Send report to
                        </h1>
                        <div className="flex flex-wrap py-2 px-1 border border-gray-14 rounded-md align-middle items-center w-full min-h-[45px]">
                            {Object.keys(emailsList)
                                .filter((email) => {
                                    return emailsList[email]
                                })
                                .map((email) => {
                                    return (
                                        <li
                                            key={email}
                                            className="m-1 flex text-[14px] font-medium p-1 border rounded-sm border-accent-medium bg-accent-light text-accent">
                                            {email}
                                            <span
                                                onClick={() => {
                                                    const _emailsList = {
                                                        ...emailsList,
                                                    }
                                                    delete _emailsList[email]
                                                    setEmailsList(_emailsList)
                                                }}
                                                className="w-3 ml-2 cursor-pointer fill-accent">
                                                <CancelIcon />
                                            </span>
                                        </li>
                                    )
                                })}
                            <input
                                id="email_addresses"
                                type="email"
                                multiple
                                placeholder="user@climate.ai"
                                className={
                                    "w-[25ch] m-2 text-[12px] text-gray-90 break-normal outline-none"
                                }
                                onKeyUp={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (
                                        e.code === "Enter" ||
                                        e.code === "Space"
                                    ) {
                                        handleKeyPress()
                                    }
                                }}
                                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                                    if (e.target.value !== "") handleKeyPress()
                                }}
                            />
                        </div>
                        <h1 className="text-gray-60 text-[12px] mt-3 mb-1">
                            Email subject
                        </h1>
                        <input
                            className={
                                "w-full px-3 h-[45px] text-[12px] text-gray-90 break-normal" +
                                COMMON_INPUT_CLASSES
                            }
                            onChange={(e) =>
                                setWorkingSchedule({
                                    ...workingSchedule,
                                    subject: e.target.value,
                                })
                            }
                            value={workingSchedule.subject}
                        />
                        <div className="w-full mt-3">
                            <h1
                                className={`text-[12px] mb-1
                                    ${
                                        scheduleBodyCharacters >= 250
                                            ? "text-red"
                                            : "text-gray-60"
                                    }
                                        `}>
                                Email body (optional)
                            </h1>
                            <textarea
                                style={{ padding: "10px" }}
                                placeholder={
                                    "Share recommendations and insights with your team."
                                }
                                defaultValue={workingSchedule.body}
                                maxLength={250}
                                className={
                                    "w-full h-[60px] px-3 text-[12px] text-gray-90 break-normal" +
                                    COMMON_INPUT_CLASSES +
                                    (scheduleBodyCharacters >= 250
                                        ? " focus:border-red"
                                        : "")
                                }
                                onBlur={(e) => {
                                    if (e.target.value !== "")
                                        setWorkingSchedule({
                                            ...workingSchedule,
                                            body: e.target.value,
                                        })
                                }}
                                onChange={(e) => {
                                    setScheduleBodyCharacters(
                                        e.currentTarget.value.length
                                    )
                                }}
                            />
                            {scheduleBodyCharacters === 0 ? (
                                <h1 className="w-full my-1 label-sm text-gray-60 text-end">
                                    max 250 characters
                                </h1>
                            ) : (
                                <h1
                                    className={`w-full label-sm text-end my-1 ${
                                        scheduleBodyCharacters >= 250
                                            ? "text-red"
                                            : "text-gray-60"
                                    }`}>
                                    {scheduleBodyCharacters}/250
                                </h1>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex justify-end w-full">
                    <Button
                        disabled={
                            !workingSchedule.subject ||
                            Object.keys(emailsList).length === 0 ||
                            !allFiltersSet.every((filterSet) => {
                                return (
                                    Object.keys(
                                        filterSet.variables as IBooleanDictionary
                                    ).length !== 0
                                )
                            })
                        }
                        label={sendNow ? "Send" : "Save"}
                        extraClasses="mt-2"
                        onClick={async () => {
                            if (sendNow) {
                                await sendDashboard()
                            } else {
                                await postSchedule()
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
