import { IPolygonGroup } from "./types"
import { createRoot } from "react-dom/client"
import mapboxgl from "../mapboxgl"
import bbox from "geojson-bbox"
import Color from "color"
import _ from "lodash"
import { MutableRefObject, RefObject } from "react"

const DEFAULT_OPACITY = 0.25
const DEFAULT_STROKE_WIDTH = 1.5

const COLOR_DESATURATE_AMOUNT = 0.25
const COLOR_DARKEN_AMOUNT = 0.1
const DEFAULT_DEBOUNCE_DELAY = 10 // milliseconds

export const appendPolygonGroup = (
    map: mapboxgl.Map,
    polyGroupId: string,
    polyGroup: IPolygonGroup
) => {
    removePolygonGroup(map, polyGroupId)
    map.addSource(polyGroupId, { type: "geojson", data: polyGroup.geojson })
    addFills(map, polyGroupId, polyGroup)
    addStrokes(map, polyGroupId, polyGroup)
    let popup: mapboxgl.Popup | null = null
    const popupComponent = polyGroup.popupComponent
    if (popupComponent) {
        const popupDOMContainer = document.createElement("div")
        const popupRoot = createRoot(popupDOMContainer)
        map.on("click", polyGroupId, (e) => {
            if (popup) {
                popup.remove()
            }
            map.getCanvas().style.cursor = "pointer"
            const feature = e.features?.[0]
            if (!feature) return
            const polygon = feature.geometry as GeoJSON.Polygon
            const bounds = bbox(polygon)
            if (Math.abs(bounds[0]) === Infinity) return
            const width = Math.abs(bounds[0] - bounds[2])
            const height = Math.abs(bounds[1] - bounds[3])
            const center = [bounds[0] + width / 2, bounds[1] + height / 2]

            popupRoot.render(popupComponent(feature))
            popup = new mapboxgl.Popup({
                closeButton: false,
            })
                .setLngLat(center)
                .setDOMContent(popupDOMContainer)
                .addTo(map)
        })
        map.on("mouseenter", polyGroupId, (e) => {
            if (!e.features?.[0]) return
            map.getCanvas().style.cursor = "pointer"
        })
        map.on("mouseleave", polyGroupId, () => {
            map.getCanvas().style.cursor = ""
        })
    }
    return true
}
export const removePolygonGroup = (map: mapboxgl.Map, polyGroupId: string) => {
    if (!map.getSource(polyGroupId)) return
    map.removeLayer(polyGroupId)
    map.removeLayer(`${polyGroupId}-outline`)
    map.removeSource(polyGroupId)
}
export const addFills = (
    map: mapboxgl.Map,
    polyGroupId: string,
    polyGroup: IPolygonGroup
) => {
    map.addLayer({
        id: polyGroupId,
        type: "fill",
        source: polyGroupId,
        layout: {},
        paint: {
            "fill-color": polyGroup.style.fillColor,
            "fill-opacity": polyGroup.style.opacity ?? DEFAULT_OPACITY,
        },
    })
}
export const addStrokes = (
    map: mapboxgl.Map,
    polyGroupId: string,
    polyGroup: IPolygonGroup
) => {
    let strokeColor = polyGroup.style.strokeColor
    if (!strokeColor) {
        strokeColor = Color(polyGroup.style.fillColor)
            .desaturate(COLOR_DESATURATE_AMOUNT)
            .darken(COLOR_DARKEN_AMOUNT)
            .hex()
    }
    map.addLayer({
        id: `${polyGroupId}-outline`,
        type: "line",
        source: polyGroupId,
        layout: {},
        paint: {
            "line-width": polyGroup.style.strokeWidth ?? DEFAULT_STROKE_WIDTH,
            "line-color": strokeColor,
        },
    })
}

const hasExpectedSize = (
    dimensions: { width: number; height: number },
    expectedSize: { width: number; height: number }
): boolean =>
    dimensions.width === expectedSize.width &&
    dimensions.height === expectedSize.height

const areSameSized = (rects: (DOMRect | undefined | null)[]) => {
    if (rects.length < 2) return true

    const expectedSize = rects[0]
    if (expectedSize == null) return false

    return rects.every((currRect, idx) => {
        // Skip the first element
        if (idx === 0) return true
        if (currRect) return hasExpectedSize(currRect, expectedSize)
        return false
    })
}
const handleResize = _.debounce(
    ({
        containerRef,
        prevContainerRect,
        mapboxMap,
    }: {
        containerRef: RefObject<HTMLElement | undefined>
        prevContainerRect: MutableRefObject<DOMRect | undefined>
        mapboxMap: mapboxgl.Map
    }) => {
        // If the map is loading, exit
        if (!mapboxMap.areTilesLoaded()) return

        const containerRect = containerRef.current?.getBoundingClientRect()

        // If the containers are the same size, exit
        if (areSameSized([prevContainerRect.current, containerRect])) return

        // Update & resize
        mapboxMap.resize()
        prevContainerRect.current = containerRect
    },
    DEFAULT_DEBOUNCE_DELAY
)
export const setupMap = ({
    containerRef,
    mapboxConfig,
    prevContainerRect,
}: {
    containerRef: RefObject<HTMLElement | undefined>
    mapboxConfig: mapboxgl.MapboxOptions | undefined
    prevContainerRect: MutableRefObject<DOMRect | undefined>
}) => {
    // Initialize the map
    const mapboxMap = new mapboxgl.Map(mapboxConfig)

    mapboxMap.dragRotate.disable()
    mapboxMap.touchZoomRotate.disableRotation()

    mapboxMap.on("render", () =>
        handleResize({ containerRef, prevContainerRect, mapboxMap })
    )
    return mapboxMap
}
