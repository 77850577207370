import select from "@gizt/selector"

import extractContent from "./extractContent"
import extractFunctionCalls, {
    DefaultFuncsKey,
    defaultFuncs,
} from "./extractFunctionCalls"

function interpolate(str: string, data: any = {}) {
    return str.replace(/\{\{([^}]+)\}\}/g, (match) => {
        // find expression between {{}} and remove all spaces
        const expression = match.slice(2, match.length - 2).replace(/\s/g, "")

        // if expression doesn't exist
        if (!expression) return ""

        // look for first function call
        const fnCall = extractFunctionCalls(expression)[0]
        if (fnCall !== undefined && fnCall.args.length > 0) {
            const selectedArgs = fnCall.args.map((arg) => {
                const { value, type } = extractContent(arg)

                switch (type) {
                    case "string":
                        return value
                    case "number":
                        return Number(value)
                    case "boolean":
                        return Boolean(value)
                    case "expression":
                        return select(value, data)
                }
            })
            // execute default fn
            return defaultFuncs[fnCall.name as DefaultFuncsKey](...selectedArgs)
        } else {
            const result = select(expression, data)
            if (result === undefined) return match

            // interpolation returns a string
            return result && result.toString()
        }
    })
}

export default interpolate
