export type DefaultFuncsKey = "Date" | "Math.max"

export const defaultFuncs: { [K in DefaultFuncsKey]: (...args: any[]) => any } =
    {
        "Date": (...args) => {
            const [value, locale = "en-US"] = args
            return new Date(value).toLocaleDateString(locale, {
                month: "short",
                day: "numeric",
                year: "numeric",
            })
        },
        "Math.max": (value: string) => null,
    }

function extractFunctionCalls(str: string): { name: string; args: string[] }[] {
    // regular expression to match a function call
    const regex = /\b(\w+)\(([^)]*)\)/g

    // array to store the results
    const matches: { name: string; args: string[] }[] = []

    // loop over the matches
    let match
    while ((match = regex.exec(str))) {
        // extract the name and the arguments
        const name = match[1]
        const args = match[2]
            .split(",")
            .map((arg) => arg.trim())
            .filter((arg) => arg.length > 0)

        // check if the arguments contain any nested function calls
        const nestedMatches = extractFunctionCalls(match[2])
        if (nestedMatches.length > 0) {
            // if there are nested function calls, add them to the matches array
            matches.push(...nestedMatches)
        } else {
            // if there are no nested function calls, add the current function call to the matches array
            matches.push({ name, args })
        }
    }

    return matches
}

export default extractFunctionCalls
