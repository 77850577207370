import { Translate } from "../../../components"
import {
    IRiskProfile,
    IHazardProfile,
    IVariety,
} from "../../../types"
import { RiskProfileTableActions } from "./components/RiskProfilesTableActions"
import { Row, Table } from "@tanstack/react-table"
import { T, useTranslate } from "@tolgee/react"
import LabelItem from "../../../climateui/components/Labels/LabelItem"
import { UnitConversionContext } from "../../../providers/UnitConversionProvider"
import { useContext } from "react"
import { CheckboxCell } from "../../../climateui/components/Table/tableUtils"
import { getRowSelectStatus, getTableSelectStatus } from "../../../utils/tables"
import { TooltipV2 } from "../../../climateui/components"
import { capitalizeFirstCharacter } from "../../../utils/wordHelper"
import { useAssets } from "../../../providers"

interface IThresholdExtractProps {
    hazardProfile: IHazardProfile;
    type?: any; 
    riskType?: string;
    opts?: {
        detailed?: boolean;
    };
}

const VarietyCell = ({
    variety: { asset, name },
    isLastOne,
}: {
    variety: IVariety
    isLastOne: boolean
}) => {
    const fullName = `${asset.name} (${name})`
    return (
        <div>
            <span>{fullName}</span>
            <span>{!isLastOne ? ", " : ""}</span>
        </div>
    )
}
const LocationCell = ({ row }: { row: Row<IRiskProfile> }) => {
    const activeLocations = row.original.active_risk_settings?.results?.reduce(
        (prev: Set<string>, { location_id }) => {
            if (location_id) prev.add(location_id)
            return prev
        },
        new Set<string>()
    )?.size
    return (
        <div className="relative flex justify-start w-full pr-3">
            {activeLocations ?? 0}
        </div>
    )
}
const parseConditional = (conditional: string, riskType: string | undefined) => {
    if (riskType === 'ds_defined') {
        switch (conditional) {
            case "<":
                return <T keyName="below">Below</T>; 
            case ">":
                return <T keyName="above">Above</T>; 
            case "=":
            default:
                return
        }
    } else {
        switch (conditional) {
            case "<":
                return <T keyName="lessThan">Less than</T>;
            case ">":
                return <T keyName="greaterThan">Greater than</T>;
            case "=":
            default:
                return 
        }
    }
}
const formatter = Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
})
export const ThresholdExtract = ({
    hazardProfile,
    riskType,
    opts,
}: IThresholdExtractProps) => {
    const conditional = parseConditional(hazardProfile.conditional, riskType)
    const { convertUnits, getUnit } = useContext(UnitConversionContext)
    const isRelative = hazardProfile.type.toLowerCase() === "relative"
    const unit = isRelative ? "%" : getUnit(hazardProfile.hazard_variable)
    const threshold = isRelative
        ? hazardProfile.threshold * 100
        : convertUnits(
            hazardProfile.threshold,
            hazardProfile.hazard_variable,
            "convertUnits"
        )

    const getConsecutiveDayString = (window: number) => {
        if (window > 1 ){
            return "consecutive days"
        }
        return "consecutive day"
    }

    if (riskType === "ds_defined") {
        return (
            <>
                <span>
                    {hazardProfile.hazard_variable.readable_name}
                </span>{" "}
                <span className="font-bold">
                    {conditional}
                </span>{" "}
                <T keyName="locationHistoricalAvergage">historical average</T>{" "}
                <T keyName="for">for</T>{" "}
                <span className="font-bold">{hazardProfile.window}</span>{" "}
                {/* TODO: get dynamic aggregation string */}
                <T keyName="consecutiveDays">{getConsecutiveDayString(hazardProfile.window)}</T>
            </>
        );
    }
    return (
        <>
            {opts?.detailed && (
                <T keyName={hazardProfile.hazard_variable.readable_name} />
            )}{" "}
            <span className={opts?.detailed ? "lowercase" : ""}>
                {conditional}
            </span>{" "}
            <span className="font-bold">
                {formatter.format(threshold)}
                {unit !== "%" ? " " + unit : unit}
            </span>{" "}
            {isRelative && <T keyName="ofHistorical">of historical</T>}
            <T keyName="for">for</T>{" "}
            <span className="font-bold">{hazardProfile.window}</span>{" "}
            {/* TODO: get dynamic aggregation string */}
            <T keyName="consecutiveDays">{getConsecutiveDayString(hazardProfile.window)}</T>
        </>
    )
}

const RiskProfileStagesCell = ({
    riskProfile,
}: {
    riskProfile: IRiskProfile
}) => {
    const { t } = useTranslate()
    const { varieties } = useAssets()

    const noStagesComponent = (
        <TooltipV2
            position="left"
            contentClass=""
            content={
                <div className="h-fit w-44">
                    {t(
                        "toAssignStagesToARiskProfile",
                        "To assign stages to a risk profile, add the risk profile to the Timeline of an asset"
                    )}
                </div>
            }>
            <div className="text-gray-30 whitespace-nowrap">
                {t("noStages", "No stages")}
            </div>
        </TooltipV2>
    )

    if (!riskProfile.stages || riskProfile.stages.length === 0)
        return noStagesComponent

    const stages = [...(riskProfile.stages ?? [])]
    stages.sort((a, b) => {
        if (!varieties?.[a.variety_id]) return -1
        if (!varieties?.[b.variety_id]) return -1

        const a_variety = varieties[a.variety_id]
        const b_variety = varieties[b.variety_id]

        if (a_variety.asset.name > b_variety.asset.name) return 1
        else if (a_variety.asset.name < b_variety.asset.name) return -1
        return 0
    })
    const countPerStage: Record<string, number> = {}
    stages.forEach((stage) => {
        if (varieties?.[stage.variety_id]) {
            const stageName = stage.name.toLowerCase()
            if (!countPerStage[stageName]) countPerStage[stageName] = 0
            countPerStage[stageName] += 1
        }
    })
    const stageIds = Object.keys(countPerStage)

    if (stageIds.length === 0) return noStagesComponent

    return (
        <TooltipV2
            position="left"
            contentClass=""
            content={
                <div
                    className="flex flex-col overflow-y-auto gap-1 w-fit h-fit min-w-44"
                    style={{ scrollbarColor: "rgba(0, 0, 0, 0)" }}>
                    {stages.map((stage) => {
                        if (!varieties?.[stage.variety_id]) return
                        const variety = varieties[stage.variety_id]
                        const fullName = `${variety.asset.name} (${variety.name})`
                        return (
                            <div key={stage.id}>
                                {fullName} - {stage.name}
                            </div>
                        )
                    })}
                </div>
            }>
            <div className="flex flex-wrap items-center gap-2">
                {stageIds.map((stageName, index) => {
                    const count = countPerStage[stageName]
                    const countDisplay = count > 1 ? ` (${count})` : ""
                    const commaDisplay = index < stageIds.length - 1 ? "," : ""
                    return (
                        <div
                            className="whitespace-nowrap"
                            key={
                                "riskProfileStages-" +
                                riskProfile.id +
                                "-" +
                                stageName
                            }>
                            {capitalizeFirstCharacter(stageName)}
                            {countDisplay}
                            {commaDisplay}
                        </div>
                    )
                })}
            </div>
        </TooltipV2>
    )
}

export const riskProfileStagesCell = {
    id: "stages",
    header: () => <Translate labelKey="stages" />,
    cell: ({ row }: { row: Row<IRiskProfile> }) => (
        <RiskProfileStagesCell riskProfile={row.original} />
    ),
}

export const columns = [
    {
        id: "select",
        header: ({ table }: { table: Table<IRiskProfile> }) => (
            <CheckboxCell<IRiskProfile>
                rowData={null}
                status={getTableSelectStatus(table)}
                onChange={table.getToggleAllRowsSelectedHandler()}
                isGlobal
            />
        ),
        cell: ({ row }: { row: Row<IRiskProfile> }) => (
            <CheckboxCell
                rowData={row.original}
                status={getRowSelectStatus(row)}
                onChange={row.getToggleSelectedHandler()}
            />
        ),
    },
    {
        accessorKey: "name",
        header: () => <Translate labelKey="name" />,
        meta: {
            sortable: true,
        },
    },
    {
        id: "hazard_variable",
        cell: ({ row }: { row: Row<IRiskProfile> }) => {
            const { t } = useTranslate()
            return (
                <div className="flex flex-col gap-2">
                    {row.original.hazard_profiles.map(
                        (hazard: IHazardProfile) => (
                            <div key={hazard.id}>
                                {t(hazard.hazard_variable.readable_name)}
                            </div>
                        )
                    )}
                </div>
            )
        },
        header: () => <Translate labelKey="variable" />,
        meta: {
            sortable: true,
        },
    },
    {
        id: "threshold",
        // TODO: Use the context unit sytem
        cell: ({ row }: { row: Row<IRiskProfile> }) => (
            <div className="flex flex-col gap-2">
                {row.original.hazard_profiles.map((hazardProfile) => (
                    <div key={hazardProfile.id}>
                        <ThresholdExtract
                            hazardProfile={hazardProfile}
                            riskType={row.original.type}  
                            opts={{ detailed: true }}  
                        />
                    </div>
                ))}
            </div>
        ),
        header: () => <Translate labelKey="threshold" />,
    },
    {
        id: "assets",
        header: () => <Translate labelKey="assets" />,
        cell: ({ row }: { row: Row<IRiskProfile> }) => {
            if (!row.original.varieties || row.original.varieties?.length === 0)
                return (
                    <div className="text-gray-30">
                        <T key="none">None</T>
                    </div>
                )
            const sortedVarieties = row.original.varieties.sort((a, b) => {
                if (a.name > b.name) return 1
                if (a.name < b.name) return -1
                return 0
            })
            return (
                <div className="flex flex-wrap">
                    {sortedVarieties.map((variety, idx) => (
                        <div key={variety.id}>
                            <VarietyCell
                                variety={variety}
                                isLastOne={
                                    idx === row.original.varieties.length - 1
                                }
                            />
                        </div>
                    ))}
                </div>
            )
        },
    },
    {
        id: "labels",
        cell: ({ row }: { row: Row<IRiskProfile> }) => (
            <div className="flex flex-wrap">
                {row.original.labels.map((label) => (
                    <span
                        className="m-1"
                        key={label.id}>
                        <LabelItem label={label} />
                    </span>
                ))}
            </div>
        ),
        header: () => <Translate labelKey="label" />,
    },
    {
        id: "locationsCount",
        header: () => <Translate labelKey="activeLocations" />,
        cell: LocationCell,
    },
    {
        id: "actions",
        cell: ({ row }: { row: Row<IRiskProfile> }) => (
            <RiskProfileTableActions rowData={row.original} />
        ),
    },
]

export const RISK_PROFILES_BULK_CSV_HEADER_COLUMN_DATA = [
    {
        labelKey: "#",
        width: "w-[38px] text-gray-30",
        extraStyle: "",
        type: "number",
        required: true,
    },
    {
        labelKey: "name",
        width: "w-[207px]",
        extraStyle: " text-left pl-[5px]",
        type: "string",
        required: true,
    },
    {
        labelKey: "variable",
        width: "w-[141px]",
        extraStyle: " text-left pl-[5px]",
        type: "string",
        required: true,
    },
    {
        labelKey: "riskCondition",
        width: "w-[119px]",
        extraStyle: " text-left pl-[5px]",
        type: "string",
        required: true,
    },
    {
        labelKey: "type",
        width: "w-[106px]",
        extraStyle: " text-left pl-[5px]",
        type: "string",
        required: false,
    },
    {
        labelKey: "threshold",
        width: "w-[106px]",
        extraStyle: " text-left pl-[5px]",
        type: "number",
        required: false,
    },
    {
        labelKey: "units",
        width: "w-[120px]",
        extraStyle: " text-left pl-[5px]",
        type: "string",
        required: false,
    },
    {
        labelKey: "days",
        width: "w-[120px]",
        extraStyle: " text-left pl-[5px]",
        type: "number",
        required: false,
    },
    {
        labelKey: "assets",
        width: "w-[142px]",
        extraStyle: " text-left pl-[5px]",
        type: "string",
        required: false,
    },
    {
        labelKey: "labels",
        width: "w-[142px]",
        extraStyle: " text-left pl-[5px]",
        type: "string",
        required: false,
    },
    {
        labelKey: "probability",
        width: "w-[116px]",
        extraStyle: " text-left pl-[5px]",
        type: "percentage",
        required: true,
    },
    {
        labelKey: "impactFunctionName",
        width: "w-[116px]",
        extraStyle: " text-left pl-[5px]",
        type: "string",
        required: true,
    },
    {
        labelKey: "impactFunctionInitial",
        width: "w-[80px]",
        extraStyle: " text-left pl-[5px]",
        type: "percentage",
        required: true,
    },
    {
        labelKey: "impactFunctionMarginal",
        width: "w-[80px]",
        extraStyle: " text-left pl-[5px]",
        type: "percentage",
        required: true,
    },
    {
        labelKey: "impactFunctionMax",
        width: "w-[80px]",
        extraStyle: " text-left pl-[5px]",
        type: "percentage",
        required: true,
    },
]

// TODO Make these use translations instead of hard coded vars
export const TEMPLATE_RISK_CSV_DATA = [
    [
        "Name",
        "Variable",
        "Risk Condition",
        "Type",
        "Threshold",
        "Units",
        "Days",
        "Assets",
        "Labels (optional)",
        "Probability",
        "Impact Function Name",
        "Initial Impact",
        "Marginal Impact",
        "Max Impact",
    ],
    [
        "Cold Stress (Sowing)",
        "Max Temperature",
        "Greater than",
        "Absolute",
        3,
        "C",
        1,
        "Avocado (Hass)-Avocado (Pinkerton)",
        "Risky-West Fields",
        "80%",
        "Constant impact over consecutive days",
        "-2%",
        "-2%",
        "-100%",
    ],
]
