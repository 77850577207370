import { useState, useEffect } from "react"
import { GenericPageHeader } from "../../../../components"
import {
    Button,
    DebounceSearchInput,
    Table,
} from "../../../../climateui/components"
import { useTranslate } from "@tolgee/react"
import { IDashboard } from "../../../../types"
import { columns } from "../DashboardsTableUtils"
import { useDashboard } from "../../../../providers/DashboardProvider"
import { ResponsivePaddingWrapper } from "../../../../layouts/TabLayout"
import { SortingState } from "@tanstack/react-table"
import { PlusIcon } from "../../../../climateui/icons"

const NewDashboardView = () => {
    const { goToNewDashboard, dashboards, loadingDashboards } = useDashboard()

    const [globalFilter, setGlobalFilter] = useState("")
    const [rowSelection, setRowSelection] = useState({})
    const { t } = useTranslate()
    const [sortedDashboards, setSortedDashboards] = useState(
        [] as IDashboard[]
    )
    const [sorting, setSorting] = useState<SortingState>([])

    const sortFunction = (
        dashboards: IDashboard[],
        variable: string,
        val: number
    ) => {
        dashboards.sort(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (a: { [key: string]: any }, b: { [key: string]: any }) =>
                a[variable] > b[variable] ? val : -val
        )
    }

    const sortTableData = (
        dashboards: IDashboard[],
        variable: string,
        behavior: boolean
    ) => {
        sortFunction(dashboards, variable, behavior ? -1 : 1)
        setSortedDashboards(dashboards)
    }

    useEffect(() => {
        const dashboardsCopy = [...dashboards]
        let sortingVariable = ""
        if (sorting && sorting.length > 0) {
            const sortingBehavior = sorting[0].desc
            sortingVariable = sorting[0].id
            sortTableData(dashboardsCopy, sortingVariable, sortingBehavior)
        } else {
            sortFunction(dashboardsCopy, "title", 1)
            setSortedDashboards(dashboardsCopy)
        }
    }, [sorting, dashboards])

    return (
        <ResponsivePaddingWrapper>
            <div className="flex flex-col h-full overflow-hidden grow">
                <GenericPageHeader
                    pageTitle={t("seasonalDashboards")}
                    right={
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-48">
                                <DebounceSearchInput
                                    placeholder={t("search")}
                                    onSearch={setGlobalFilter}
                                />
                            </div>
                            <Button
                                label={t("newDashboard")}
                                onClick={goToNewDashboard}
                                icon={<PlusIcon />}
                            />
                        </div>
                    }
                    bottom={undefined}
                />
                <div className="overflow-y-auto grow">
                    <Table<IDashboard>
                        columns={columns}
                        data={sortedDashboards}
                        state={{
                            globalFilter,
                            rowSelection,
                            columnFilters: [],
                            hiddenColumns: [],
                            sorting,
                        }}
                        setSorting={setSorting}
                        setGlobalFilter={setGlobalFilter}
                        setRowSelection={setRowSelection}
                        noDataMessage={
                            loadingDashboards
                                ? "Loading Dashboards"
                                : "No Dashboards"
                        }
                        extraClasses="overflow-y-scroll"
                        enableMultiSort={false}
                    />
                </div>
            </div>
        </ResponsivePaddingWrapper>
    )
}

export default NewDashboardView
