import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo, useState } from "react"
import { Button, Table } from "../../../../climateui/components"
import { GenericPageHeader } from "../../../../components"
import { ResponsivePaddingWrapper } from "../../../../layouts/TabLayout"
import { useLocations } from "../../../../providers/LocationsProvider"
import { usePlanningTool } from "../../../../providers/PlanningToolProvider"
import { IInsightsLocation } from "../../../../types"
import { buildLocationColumns } from "../../../Admin/Locations/adminLocationsTableUtils"
import LocationsTableFilters from "../../../Admin/Locations/components/LocationsTableFilters"
import { planAssetsColumns } from "../plansTableUtils"
import { usePlan } from "../PlanProvider"
import EmptyModal from "../../../../climateui/providers/Modal/EmptyModal"
import climateAILogoIconAnimation from "../../../../components/ClimateAi_Symbol_Animation.json"
import Lottie from "lottie-react"

const columns = buildLocationColumns(planAssetsColumns, {
    labels: {
        expandable: true,
    },
})

function PlanAssets() {
    const { goToStep } = usePlanningTool()
    const { locationsObj } = useLocations()
    const { performPrepopulation, workingPlan, isStageManagerEnabled } =
        usePlan()

    const [prepopulating, setPrepopulating] = useState(false)
    const [globalFilter, setGlobalFilter] = useState("")
    const [rowSelection, setRowSelection] = useState({})
    const [columnFilters, setColumnFilters] = useState<
        { id: string; value: string[] | unknown }[]
    >([])

    const { selectedLocations, canContinue } = useMemo(() => {
        const selectedLocations: IInsightsLocation[] = []
        let canContinue = true

        const selectedLocationsAssetVarieties =
            workingPlan?.selectedLocationsAssetVarieties || {}
        Object.keys(selectedLocationsAssetVarieties).forEach(
            (selectedLocationId: string) => {
                selectedLocations.push(locationsObj[selectedLocationId])
                const selectedLocationAssets =
                    selectedLocationsAssetVarieties[selectedLocationId]
                if (Object.keys(selectedLocationAssets).length === 0)
                    canContinue = false
            }
        )
        return {
            selectedLocations,
            canContinue,
        }
    }, [workingPlan?.selectedLocationsAssetVarieties])

    const next = async () => {
        setPrepopulating(true)
        await performPrepopulation()
        setPrepopulating(false)
    }
    const prev = () => {
        goToStep("locations")
    }

    useEffect(() => {
        // If no data, go back
        const selectedLocationsAssetVarieties =
            workingPlan?.selectedLocationsAssetVarieties

        if (
            !selectedLocationsAssetVarieties ||
            Object.keys(selectedLocationsAssetVarieties).length === 0
        )
            goToStep("locations")
    }, [workingPlan?.selectedLocationsAssetVarieties])

    const { t } = useTranslate()

    return (
        <ResponsivePaddingWrapper extraClasses="p-3 px-4 sm:p-3 sm:px-5 elevation-1 border-r border-gray-5">
            <div className="flex flex-col h-full overflow-hidden grow gap-2">
                <GenericPageHeader
                    pageTitle={t("assignAssetsToEachLocation")}
                    extraClasses="[&_h2]:title-sm"
                    right={
                        <div className="flex flex-row items-center gap-2">
                            <Button
                                type="secondary"
                                label={t("back")}
                                onClick={prev}
                            />

                            <Button
                                label={t("continue")}
                                onClick={next}
                                disabled={!canContinue}
                            />
                        </div>
                    }
                    bottom={
                        <LocationsTableFilters
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            locationsSubset={selectedLocations}
                        />
                    }
                />
                <div className="overflow-y-auto grow">
                    <Table<IInsightsLocation>
                        data={selectedLocations}
                        columns={columns}
                        state={{
                            rowSelection,
                            globalFilter,
                            columnFilters,
                            hiddenColumns: [],
                        }}
                        getRowId={(
                            location: IInsightsLocation,
                            index: number
                        ) => location?.id || index.toString()}
                        setRowSelection={setRowSelection}
                        setGlobalFilter={setGlobalFilter}
                        setColumnFilters={setColumnFilters}
                        extraClasses=""
                    />
                </div>
            </div>
            <EmptyModal
                open={prepopulating}
                customClasses="h-fit w-[320px] relative rounded-lg bg-white p-5">
                <div className="w-16">
                    {/* TODO: implement whitelabel logic. Ask Marco. */}
                    <Lottie animationData={climateAILogoIconAnimation} />
                </div>
                <h3 className="title-sm text-gray-90 mt-2">
                    {t("loadingPlan", "Loading Plan...")}
                </h3>
                <p className="body-md text-gray-60 mt-3">
                    {isStageManagerEnabled
                        ? t(
                            "prepopulationDescriptionStageManager",
                            "We are importing stages & risk profiles from your Asset Timeline(s) to give you a starting point for your plans."
                        )
                        : t(
                            "prepopulationDescriptionGeneral",
                            "We are importing stages & risk profiles from our templates to give you a starting point for your plans."
                        )}
                </p>
            </EmptyModal>
        </ResponsivePaddingWrapper>
    )
}

export default PlanAssets
