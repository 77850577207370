import * as d3 from "d3"
import { FeatureCollection, Feature } from "geojson"
import { removeLayer, removeSource } from "./map"

export interface IMapHeatmapPoint {
    lat: number
    lon: number
    value: number // Analogs metric
}

export interface IStyleHeatmapConfig {
    color: string
}

interface IHeatmapOptions extends Partial<IStyleHeatmapConfig> {
    map: mapboxgl.Map
    heatmap: IMapHeatmapPoint[]
    minValue?: number
    maxValue?: number
    pointWidthAndHeight?: number
}

const HEATMAP_SOURCE_ID = "rectangular-heatmap-points-source"
const HEATMAP_LAYER_ID = "rectangular-heatmap-points-layer"

export const setupHeatmap = ({
    map,
    heatmap,
    minValue = 0,
    maxValue = 10,
    pointWidthAndHeight = 1,
    color = "#0c7667",
}: IHeatmapOptions) => {
    if (!heatmap || heatmap.length === 0) return

    const scale = d3.scalePow().domain([minValue, maxValue]).range([0, 1])

    const features: Feature[] = heatmap.map((heatmapPoint) => {
        const lat = heatmapPoint.lat
        const lng = heatmapPoint.lon
        const longitudeWidth = pointWidthAndHeight
        const latitudeHeight = pointWidthAndHeight
        const leftTop = [lng - longitudeWidth / 2, lat + latitudeHeight / 2]
        const rightTop = [lng + longitudeWidth / 2, lat + latitudeHeight / 2]
        const rightBot = [lng + longitudeWidth / 2, lat - latitudeHeight / 2]
        const leftBottom = [lng - longitudeWidth / 2, lat - latitudeHeight / 2]

        const scaledOpacity = scale(heatmapPoint.value)

        return {
            type: "Feature",
            properties: {
                opacity: scaledOpacity,
                color,
                type: "rectangularHeatmap",
                longitudeWidth,
                latitudeHeight,
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [leftTop, rightTop, rightBot, leftBottom, leftTop],
                ],
            },
        }
    })

    const data: FeatureCollection = {
        type: "FeatureCollection",
        features: features,
    }

    map.addSource(HEATMAP_SOURCE_ID, {
        type: "geojson",
        data: data,
    })

    map.addLayer({
        id: HEATMAP_LAYER_ID,
        type: "fill",
        source: HEATMAP_SOURCE_ID,
        layout: {},
        paint: {
            "fill-color": ["get", "color"],
            "fill-opacity": ["get", "opacity"],
            "fill-outline-color": "rgba(0,0,0,0)",
        },
    })
}

export const removeHeatmap = (map: mapboxgl.Map) => {
    removeLayer(map, HEATMAP_LAYER_ID)
    removeSource(map, HEATMAP_SOURCE_ID)
}
