const AnalogsSearchIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M8.00004 6.5C8.00004 7.32843 7.32847 8 6.50004 8C5.67161 8 5.00004 7.32843 5.00004 6.5C5.00004 5.67157 5.67161 5 6.50004 5C7.32847 5 8.00004 5.67157 8.00004 6.5Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.50002 14.6615L7.81962 13.5029C9.0633 12.4109 10.0782 11.324 10.7941 10.2385C11.5064 9.15851 12 7.96089 12 6.69C12 3.33583 9.43348 1 6.50002 1C3.56656 1 1 3.33583 1 6.69C1 7.96089 1.49369 9.15851 2.20594 10.2385C2.92182 11.324 3.93674 12.4109 5.18042 13.5029L6.50002 14.6615ZM10 6.69C10 8.184 8.83191 9.9525 6.50002 12C4.16813 9.9525 3 8.184 3 6.69C3 4.449 4.66251 3 6.50002 3C8.33753 3 10 4.449 10 6.69Z"
                fill={props.fill}
            />
            <path
                d="M11.5 14.5H17.5V16.5H11.5V14.5Z"
                fill={props.fill}
            />
            <path
                d="M17.5 10.5H11.5V12.5H17.5V10.5Z"
                fill={props.fill}
            />
            <path
                d="M14 7.52054V5.51537C14.1654 5.50518 14.3321 5.5 14.5001 5.5C18.9184 5.5 22.5001 9.08172 22.5001 13.5C22.5001 15.6038 21.688 17.5179 20.3602 18.946L23.7071 22.2928L22.2929 23.7071L18.8202 20.2344C17.5742 21.0354 16.0914 21.5 14.5001 21.5C11.1633 21.5 8.30374 19.4572 7.10368 16.554L8.73214 15.1582C9.4516 17.6654 11.7616 19.5 14.5001 19.5C17.8138 19.5 20.5001 16.8137 20.5001 13.5C20.5001 10.1863 17.8138 7.5 14.5001 7.5C14.3317 7.5 14.1649 7.50694 14 7.52054Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default AnalogsSearchIcon
