import { IConfigLinePlotComponent, ILinePlotComponent, ILinearPlotComponentDataSource, PlotComponentTypes } from "./types"

/* Pending to remove config prop from domain, to coupled */
export class LinePlotComponent<TMetaData> implements ILinePlotComponent<TMetaData> {
    id: string
    config: IConfigLinePlotComponent // Contains the configuration that should be used to render the component
    x: string[]
    y: number[]
    type: PlotComponentTypes.Line
    /* Pending to add TypePlotComponentLabels and replace label props */
    xLabel: string
    yLabel: string
    xLabelId: string
    yLabelId: string
    metadata: TMetaData // Contains all the metadata that is included in the component

    constructor(plotDataSource: ILinearPlotComponentDataSource, metadata: TMetaData, config: IConfigLinePlotComponent) {
        this.id = plotDataSource.id
        this.type = PlotComponentTypes.Line
        this.xLabel = plotDataSource.xLabel
        this.yLabel = plotDataSource.yLabel
        this.xLabelId = plotDataSource.xLabelId
        this.yLabelId = plotDataSource.yLabelId
        this.x = plotDataSource.x
        this.y = plotDataSource.y
        this.config = config
        this.metadata = metadata
    }
}
