const AsteriskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 7.82966L4.50033 10L2.92685 8.79796L4.42651 6.62762L2 5.79863L2.60102 3.85369L5.02753 4.68269V2H6.97247V4.68269L9.39898 3.85369L10 5.79863L7.57349 6.62762L9.07315 8.79796L7.49967 10L6 7.82966Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default AsteriskIcon
