import { useSeasonalCalendar } from "../../../providers/SeasonalCalendarProvider"

const daysPerWeek = 7

function Week({
    weekNumber,
    customWidth = daysPerWeek,
    showWeekNumber = false,
    whiteColorPercentage = 100,
    isHovered = false,
}: {
    weekNumber: number
    customWidth?: number
    showWeekNumber?: boolean
    whiteColorPercentage?: number
    isHovered?: boolean
}) {
    const { pxPerDay } = useSeasonalCalendar()

    const showLabel = showWeekNumber ? customWidth > 5 : false

    return (
        <div
            className={
                "flex flex-row items-center justify-center h-full shrink-0 grow-0"
            }
            style={{
                width: pxPerDay * customWidth + "px",
                background:
                    "linear-gradient(90deg, " +
                    (isHovered ? "#F7F8F8 " : "white ") +
                    whiteColorPercentage +
                    "%, #f7f8f8 0%)",
            }}>
            {showLabel && (
                <span className="body-sm text-gray-60">
                    {weekNumber % 52 === 0 ? 52 : weekNumber % 52}
                </span>
            )}
        </div>
    )
}

function WeeklyTimeline({
    showWeekNumber = false,
    isHovered = false,
}: {
    showWeekNumber?: boolean
    isHovered?: boolean
}) {
    const {
        weeksOffset,
        weekNumbers,
        furthestForecastDayNumber,
        isEditingCalendar,
    } = useSeasonalCalendar()

    function handleBackgroundColor(
        furthestForecastDayNumber: number,
        firstNumberDayOfWeek: number,
        lastNumberDayOfWeek: number
    ) {
        // Check if the current week contains the furthest forecast day we have
        if (
            furthestForecastDayNumber > firstNumberDayOfWeek &&
            furthestForecastDayNumber <= lastNumberDayOfWeek
        ) {
            // Calculate the percentage of the cell we need to paint white
            const offset = furthestForecastDayNumber - firstNumberDayOfWeek
            return (offset / 7) * 100
        } else if (furthestForecastDayNumber < firstNumberDayOfWeek) {
            // Paint 0% of the cell white
            return 0
        }
        // Paint all the cell white
        return 100
    }

    return (
        <div
            className={
                "relative h-full flex flex-row items-center divide-x divide-gray-5 z-10"
            }>
            {weeksOffset > 0 && (
                <Week
                    isHovered={isHovered}
                    weekNumber={weekNumbers[0] === 1 ? 52 : weekNumbers[0] - 1}
                    customWidth={weeksOffset}
                    showWeekNumber={showWeekNumber}
                />
            )}
            {weekNumbers.map((weekNumber: number, index: number) => {
                const customWidth =
                    index + 1 === weekNumbers.length && weeksOffset > 0
                        ? daysPerWeek - weeksOffset
                        : daysPerWeek

                // Calculate First and Last number day of the current week
                const firstNumberDayOfWeek = (weekNumber - 1) * 7
                const lastNumberDayOfWeek = weekNumber * 7

                let whiteColorPercentage = 100
                // Avoid the call if null, meaning provider hasn't finished calculating it
                if (furthestForecastDayNumber && !isEditingCalendar) {
                    whiteColorPercentage = handleBackgroundColor(
                        furthestForecastDayNumber,
                        firstNumberDayOfWeek,
                        lastNumberDayOfWeek
                    )
                }

                return (
                    <Week
                        key={"week-" + weekNumber}
                        isHovered={isHovered}
                        weekNumber={weekNumber}
                        customWidth={customWidth}
                        showWeekNumber={showWeekNumber}
                        whiteColorPercentage={whiteColorPercentage}
                    />
                )
            })}
        </div>
    )
}

export default WeeklyTimeline
