import * as React from "react"
import { SVGProps } from "react"

const MeanTemperatureIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-inherit"
        viewBox="0 0 24 24"
        {...props}>
        <path
            d="M15 13V5a3 3 0 0 0-6 0v8a5 5 0 1 0 6 0Zm-3-9a1 1 0 0 1 1 1v4h-2V5a1 1 0 0 1 1-1Z"
            fill={props.fill}
        />
    </svg>
)

export default MeanTemperatureIcon
