import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import config from "./utils/config"
import http from "./climateui/utils/http"
import { datadogRum } from "@datadog/browser-rum"

console.log(config.api_url)
http.initializeHttpModule(config.api_url)

if (
    (config.env === "prod" || config.env === "production") &&
    location.hostname !== "localhost"
) {
    console.log = () => null
    console.info = () => null
    console.warn = () => null
    console.debug = () => null
}
// TODO: Create generalized way of configuring modules
// e.g. http.setConfig({apiUrl: "klk"})

// Sudo Listener
/* TODO: Fix sudo after revamping auth
const localhost = ["http://localhost:3000", "http://127.0.0.1:3000"]
window.addEventListener("message", (ev) => {
    if (ev.origin !== config.admin_platform && !localhost.includes(ev.origin))
        return
    try {
        const data = JSON.parse(ev.data)
        storeUserToken(data.tokens)
        const project = process.env.REACT_APP_PROJECT_NAME
        localStorage.setItem(
            `climateai-${project}-access-token`,
            data.sudoedToken
        )
        localStorage.setItem(`climateai-sudoed-token`, data.sudoedToken)
        localStorage.setItem("climateai-sudoed-user", data.sudoedUser)
    } catch (err) {
        console.debug("[SUDO PROVIDER]: Couldn't parse event data", err)
    }
})
*/

// Check for access-tokens and account-id in query params
// If found, save them in local storage
window.onload = () => {
    const refresh_token = new URLSearchParams(window.location.search).get(
        "refresh-token"
    )
    const account_id = new URLSearchParams(window.location.search).get(
        "account-id"
    )
    const project = process.env.REACT_APP_PROJECT_NAME

    if (refresh_token)
        localStorage.setItem(
            `climateai-${project}-refresh-token`,
            refresh_token
        )

    if (account_id)
        localStorage.setItem(
            `climateai-${project}-last-selected-account`,
            account_id
        )
}

console.warn(
    "RUNNING: " +
        config.env +
        (config.isLocalBackend ? " with local back-end" : "")
)
console.warn(
    "REMEMBER: React.StrictMode will render twice. Comment it to test production behavior"
)

datadogRum.init({
    applicationId: "c9161311-82f3-4786-949d-16899f3f488f",
    clientToken: "pub569efdb801619924574ab5dbb2740924",
    site: "us5.datadoghq.com",
    service: "climate-platform",
    env: config.env,
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
})
datadogRum.startSessionReplayRecording()

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
