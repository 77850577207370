import { FC } from "react"

interface DefaultLabelProps {
    value: string
}

export const DefaultTitleLabel: FC<DefaultLabelProps> = ({ value }) => {
    return (
        <h4 className="truncate body-md text-gray-60">{value}</h4>
    )
}

export const DefaultYLabel: FC<DefaultLabelProps> = ({ value }) => {
    return (
        <div className="flex justify-center align-start">
            <DefaultTitleLabel value={value} />
        </div>
    )
}

export const DefaultXLabel: FC<DefaultLabelProps> = ({ value }) => {
    return (
        <div className="flex justify-center align-center">
            <DefaultTitleLabel value={value} />
        </div>
    )
}

/* Helper functions */
export const onRenderDefaultXLabel = (value: string) => {
    return (
        <DefaultXLabel value={value} />
    )
}

export const onRenderDefaultYLabel = (value: string) => {
    return (
        <DefaultYLabel value={value} />
    )
}