import { useRef, useEffect, RefObject, LegacyRef } from "react"
import { PORTAL_DIV_ID } from "../components/FixedElement/utils"

const parentHasId = (element: HTMLElement, id: string) => {
    let currEl: HTMLElement | null = element
    while (currEl !== null) {
        if (currEl.id === id) return true
        currEl = currEl.parentElement
    }
    return false
}
/**
 * Hook that executes handler when you click outside
 * of the passed ref or when you click Esc.
 */
function useOutsideComponentClickHandler<T = HTMLDivElement>(
    handler: () => void,
    excludedIds = [PORTAL_DIV_ID]
): RefObject<T> | LegacyRef<T> | undefined {
    const ref = useRef<HTMLElement>(null)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const target = event.target as HTMLElement
            const toast_container = target.classList.contains(
                "Toastify__toast-container"
            )
            const shouldExclude = excludedIds.some((id) =>
                parentHasId(target, id)
            )
            if (
                ref.current &&
                !(ref.current as Node).contains(target) &&
                !toast_container &&
                !shouldExclude
            ) {
                handler()
            }
        }

        function handleKeyDown(event: KeyboardEvent) {
            if (event.key === "Escape") handler()
        }

        document.addEventListener("mousedown", handleClickOutside)
        document.addEventListener("keyup", handleKeyDown)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
            document.removeEventListener("keyup", handleKeyDown)
        }
    }, [ref, handler])

    return ref as RefObject<T>
}

export default useOutsideComponentClickHandler
