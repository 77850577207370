export const delay = (timeout: number) =>
    new Promise((resolve) => setTimeout(resolve, timeout))

export const setupElementEvents = (
    element: HTMLElement,
    eventsObj: Record<string, (event?: Event) => void>
) => {
    Object.keys(eventsObj).forEach((key: string) => {
        element.addEventListener(key, eventsObj[key])
    })
}

export * from "./map"
export * from "./pins"
export * from "./heatmap"
export * from "./lines"
