import {
    DragEvent,
    ReactNode,
    RefObject,
    useEffect,
    useRef,
    useState,
} from "react"
import { getClientBrowserName } from "../utils"

function hideElement(
    e: DragEvent<HTMLDivElement>,
    elementRef: RefObject<HTMLDivElement>,
    setAuxElement: (el: HTMLDivElement) => void
) {
    if (!elementRef || elementRef.current === undefined) return
    const fakeLink = (elementRef.current as HTMLDivElement).cloneNode(
        true
    ) as HTMLDivElement
    fakeLink.style.opacity = "0"
    setAuxElement(fakeLink)
    document.body.appendChild(fakeLink)
    ;(e as DragEvent<HTMLDivElement>)?.dataTransfer?.setDragImage(
        fakeLink,
        0,
        0
    )
}

function DraggableElement({
    children,
    draggable = true,
    draggingCallback,
    dragEndCallback,
}: {
    children: ReactNode
    draggable: boolean
    draggingCallback: (pxMoved: number) => void
    dragEndCallback: () => void
}) {
    const [initialX, setInitialX] = useState<number>(0)
    const [auxElement, setAuxElement] = useState<HTMLDivElement>()

    const auxRef = useRef() as RefObject<HTMLDivElement>

    const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        setInitialX(e.pageX)
        hideElement(e, auxRef, setAuxElement)
    }
    // Drag event pageX WILL NOT work on Firefox
    const handleDragging = (e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (e.pageX === 0) return

        const pxMoved = e.pageX - initialX
        draggingCallback(pxMoved)
    }
    const handleDragEnd = (e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        dragEndCallback()
        setInitialX(0)
        document.body.removeChild(auxElement as Node)
    }

    // FIREFOX
    const browser = getClientBrowserName()
    useEffect(() => {
        if (browser !== "firefox" || initialX === 0) return
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleFirefoxMove = (e: any) => {
            e.stopPropagation()
            if (e.pageX === 0) return

            const pxMoved = e.pageX - initialX
            draggingCallback(pxMoved)
        }
        window.addEventListener("dragover", handleFirefoxMove)

        return () => {
            window.removeEventListener("dragover", handleFirefoxMove)
        }
    }, [initialX])

    return (
        <div
            className="flex flex-row items-center"
            ref={auxRef}
            draggable={draggable}
            onDragStart={handleDragStart}
            onDrag={handleDragging}
            onDragEnd={handleDragEnd}>
            {children}
        </div>
    )
}

export default DraggableElement
