import { FC, ReactNode } from "react"

interface WrapperProps {
    children: ReactNode
}

export const Wrapper: FC<WrapperProps> = ({ children }) => {
    return (
        <div className="bg-white border border-gray-14 w-full h-full p-4 rounded-2xl">
            {children}
        </div>
    )
}