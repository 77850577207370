import { Dispatch, ReactNode, SetStateAction } from "react"
import { IBooleanDictionary } from "../../types"
import Tooltip from "../Tooltip"

interface IconOption {
    icon: ReactNode
    label?: string
}

const isIconOption = (option: ReactNode | IconOption): option is IconOption => {
    const iconOption = option as IconOption
    return !!option && !!iconOption.icon
}

const IconSelect = ({
    options,
    selection,
    setSelection,
    enableMultiSelect = true,
    iconSize = 32,
    tooltipPosition = "top",
    clearable = false,
}: {
    options: Record<string, ReactNode | IconOption>
    selection: IBooleanDictionary
    setSelection: Dispatch<SetStateAction<IBooleanDictionary>>
    enableMultiSelect?: boolean
    iconSize?: number
    tooltipPosition?: string
    clearable?: boolean
}) => {
    const optionEntries = Object.entries(options)

    return (
        <div className="flex flex-row items-center justify-center">
            {optionEntries.map(([key, _option], idx) => {
                if (!_option) return

                let option = _option as IconOption
                if (!isIconOption(_option)) {
                    option = {
                        icon: _option,
                    }
                }
                return (
                    <div key={key}>
                        <Tooltip
                            doShow={!!option.label}
                            key={key}
                            position={tooltipPosition}
                            content={option.label ?? key}>
                            <button
                                onClick={() => {
                                    setSelection((oldSelection) => {
                                        if (!enableMultiSelect)
                                            return {
                                                [key]:
                                                    !selection[key] ||
                                                    !clearable,
                                            }
                                        return {
                                            ...oldSelection,
                                            [key]: !selection[key],
                                        }
                                    })
                                }}
                                style={{
                                    height: `${iconSize}px`,
                                }}
                                className={[
                                    "p-1 px-3 border-[1px] border-x-[0.5px] transition-all duration-200 relative",
                                    selection[key]
                                        ? "border-accent-medium text-accent bg-accent-light fill-accent"
                                        : "border-gray-14 bg-white fill-gray-60 hover:fill-accent hover:text-accent text-gray-60 ",
                                    idx === 0 && "rounded-l-md",
                                    idx === optionEntries.length - 1 &&
                                        "rounded-r-md",
                                ].join(" ")}>
                                {option.icon}
                            </button>
                        </Tooltip>
                    </div>
                )
            })}
        </div>
    )
}

export default IconSelect
