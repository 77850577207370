import * as React from "react"
import { SVGProps } from "react"

const MeanSoilMoistureIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-inherit"
        viewBox="0 0 25 24"
        {...props}>
        <path
            fill={props.fill}
            d="M3.5 18h18v2h-18zM3.5 13h18v2h-18zM12.5 10.4c1.66 0 3-1.34 3-3 0-2-3-5.4-3-5.4s-3 3.4-3 5.4c0 1.66 1.34 3 3 3Z"
        />
    </svg>
)

export default MeanSoilMoistureIcon
