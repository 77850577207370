import { IPrimitivesDictionary } from "../../../../climateui/types"
import { IRiskProfile, riskProfileToInput } from "../../../../types"
import { riskProfileQuerySet } from "../../../../utils/networking"

export const bulkDelete = (riskProfiles: IRiskProfile[]) => {
    const requests = riskProfiles.map((riskProfile) =>
        riskProfileQuerySet.delete(`/${riskProfile.id}`)
    )
    return Promise.all(requests)
}

export const bulkUpdate = (
    riskProfiles: IRiskProfile[],
    payload: IPrimitivesDictionary
) => {
    const requests = riskProfiles.map((riskProfile) =>
        riskProfileQuerySet.put(`/${riskProfile.id}`, {
            ...riskProfileToInput(riskProfile),
            ...payload,
        })
    )
    return Promise.all(requests)
}

export const bulkUpdateLabels = (
    riskProfiles: IRiskProfile[],
    labels: string[],
    replace?: boolean
) => {
    if (replace) return bulkUpdate(riskProfiles, { labels })

    const requests = riskProfiles.map((riskProfile) => {
        const mergedLabels = new Set(
            riskProfile.labels.map((label) => label.id)
        )
        labels.forEach((labelID) => mergedLabels.add(labelID))
        return riskProfileQuerySet.put(`/${riskProfile.id}`, {
            ...riskProfileToInput(riskProfile),
            labels: Array.from(mergedLabels),
        })
    })

    return Promise.all(requests)
}
