import { LngLatBoundsLike } from "mapbox-gl"
import { setupElementEvents } from "./index"
import ReactDOMServer from "react-dom/server"

import mapboxgl from "../mapboxgl"

type pinStyle =
    | "map-generic-pin.png"
    | "map-alert-pin.png"
    | "map-new-location-marker.png"
    | "map-pin-white.png"
    | "map-pin.png"
    | "map_alert_watch_pin.png"
    | "map-pin-alert-selected.png"

export interface IMapPin {
    lat: number
    lon: number
    id?: string | number
    pinStyle?: pinStyle | string
    category?: string
    getCount?: (pin: IMapPin) => number | undefined
    data?: any // eslint-disable-line
    popup?: (pin: IMapPin) => mapboxgl.Popup | undefined
    events?: (
        marker: mapboxgl.Marker
    ) => Record<string, (event?: Event) => void>
}

export const getMarkerElement = (pin: IMapPin) => {
    const el = document.createElement("div")
    el.className =
        "flex justify-center cursor-pointer p-0 border-0 w-[30px] h-[36px] bg-no-repeat bg-bottom bg-contain"
    const genericPinStyle = "url(/images/map-generic-pin.png)"
    const pinStyle = pin.pinStyle
        ? "url(/images/" + pin.pinStyle + ")"
        : genericPinStyle

    const textColorClass =
        pin.pinStyle === "map_alert_watch_pin.png" ? "text-black" : "text-white"
    el.style.backgroundImage = pinStyle

    if (!pin?.getCount) return el
    const count = pin.getCount(pin)
    if (count === undefined) return el

    if (count === 0) {
        el.style.zIndex = "0"
        el.style.backgroundImage = genericPinStyle
        el.style.opacity = "40%"
    } else {
        el.style.zIndex = "2"
        el.style.backgroundImage = pinStyle
        el.style.opacity = "100%"
    }
    el.innerHTML =
        count != 0
            ? `<div class="label-sm ${textColorClass} mt-[6px] pointer-events-none">${count}</div>`
            : ""
    return el
}

export const getMarker = (map: mapboxgl.Map, pin: IMapPin) => {
    try {
        return new mapboxgl.Marker({
            element: getMarkerElement(pin),
            anchor: "bottom",
        })
            .setLngLat([pin.lon, pin.lat])
            .setPopup(pin.popup ? pin.popup(pin) : undefined)
            .addTo(map)
    } catch (e) {
        console.error(e)
        return null
    }
}

export const setupPins = (
    map: mapboxgl.Map,
    pins: IMapPin[],
    setMarkers: (markers: mapboxgl.Marker[]) => void
) => {
    const _markers: mapboxgl.Marker[] = []
    for (const pin of pins) {
        const marker = getMarker(map, pin)
        if (!marker) continue
        _markers.push(marker)

        if (!pin.events) continue
        const events = pin.events(marker)
        setupElementEvents(marker.getElement(), events)
    }
    setMarkers(_markers)
}

const GenericPopup = ({ pin }: { pin: IMapPin }) => {
    return (
        <div className="overflow-hidden flex flex-col items-stretch max-w-[280px]">
            <div className="flex flex-row items-center justify-between pb-[4px] space-x-2 h-[20px]">
                <h6 className="text-[16px] text-gray-90 shrink grow whitespace-nowrap truncate">
                    {pin.lat}, {pin.lon}
                </h6>
            </div>
        </div>
    )
}

export const getGenericPopup = (pin: IMapPin) => {
    return new mapboxgl.Popup({
        className: "roboto z-full",
        maxWidth: "360px",
        offset: 0,
        closeButton: false,
    }).setHTML(ReactDOMServer.renderToStaticMarkup(<GenericPopup pin={pin} />))
}

export const getPinsBounds = (pins: IMapPin[]) => {
    let minX = 180
    let maxX = -180
    let minY = 90
    let maxY = -90
    pins.forEach((pin) => {
        if (pin.lon < minX) minX = pin.lon
        if (pin.lat < minY) minY = pin.lat
        if (pin.lon > maxX) maxX = pin.lon
        if (pin.lat > maxY) maxY = pin.lat
    })
    return [minX, minY, maxX, maxY] as LngLatBoundsLike
}
