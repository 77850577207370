import { flexRender, Header, HeaderGroup } from "@tanstack/react-table"
import SortButton from "../SortButton"
import { getMaxHeaderDepth, subHeadersAreEmpty } from "../tableUtils"
import { ICustomTableState } from "../types"

const TableHeader = <T = unknown,>({
    state,
    header,
}: {
    state?: ICustomTableState
    headerGroup: HeaderGroup<T>
    header: Header<T, unknown>
}) => {
    const sortColumn = () => {
        if (!header.column.columnDef.meta?.sortable) return
        header.column.toggleSorting(undefined)
    }
    if (
        state?.hiddenColumns?.includes(header.id) ||
        header.column.parent?.id === header.id ||
        // NOTE: This condition DOES NOT remove the extra <tr> added by the
        // header group
        (!header.column.columnDef.header && header.depth > 1)
    )
        return null

    const maxHeaderDepth = getMaxHeaderDepth(header)
    const rowSpan = subHeadersAreEmpty(header) ? maxHeaderDepth + 1 : 1

    return (
        <th
            key={header.id}
            rowSpan={rowSpan}
            colSpan={header.subHeaders.length || 1}
            className={[
                "h-10 sticky top-0 z-10 max-w-[150px]",
                "bg-gray-3 text-gray-90",
                "border-gray-14",
                "px-1",
                header.depth > 1
                    ? "body-sm font-[400]"
                    : "font-medium text-left",
                header.column.columnDef.meta?.sortable ? "cursor-pointer" : "",
                rowSpan - 1 === maxHeaderDepth ? "border-b" : "",
            ].join(" ")}
            onClick={sortColumn}>
            <div>
                <div
                    className={[
                        header.column.columnDef.meta?.sortable
                            ? "inline-block"
                            : "",
                    ].join(" ")}>
                    {header.isPlaceholder && !header.column.parent
                        ? null
                        : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                          )}
                </div>
                {header.column.columnDef.meta?.sortable && (
                    <SortButton sortDir={header.column.getIsSorted()} />
                )}
            </div>
        </th>
    )
}
export default TableHeader
