import WeeklyTimeline from "./WeeklyTimeline"
import Risk from "./Risk"
import { EDITION_ACTIONS } from "./utils"
import {
    IPlannedRisk,
    IStageLeft,
    IStrategy,
} from "../../../types"
import { useSeasonalCalendar } from "../../../providers/SeasonalCalendarProvider"

function Risks({
    strategy,
    updateStrategy,
    open,
    globalCalendarLeft,
    stageLeft,
}: {
    strategy: IStrategy
    updateStrategy: (strategy: IStrategy) => void
    open: boolean
    globalCalendarLeft: number
    stageLeft?: IStageLeft
}) {
    const strategyRisks = strategy.planned_risks ?? []
    const { hoveredPlannedRisk, setHoveredPlannedRisk } = useSeasonalCalendar()

    return (
        <div
            className={[
                "shrink-0",
                "transition-all duration-75 border-t",
                open
                    ? "border-gray-5 max-h-max"
                    : "max-h-0 border-transparent overflow-hidden",
            ].join(" ")}>
            {strategyRisks.map((risk: IPlannedRisk, index: number) => (
                <div
                    key={"risk-row-" + risk.frontend_id}
                    className={["h-8 relative"].join(" ")}
                    onMouseEnter={() => setHoveredPlannedRisk(risk.id)}>
                    <WeeklyTimeline
                        isHovered={hoveredPlannedRisk === risk.id}
                    />
                    <Risk
                        risk={risk}
                        globalCalendarLeft={
                            globalCalendarLeft +
                            (risk.stage_id &&
                            stageLeft &&
                            risk.stage_id === stageLeft.stage_id
                                ? stageLeft.left
                                : 0)
                        }
                        updateRisk={(newRisk: IPlannedRisk) => {
                            const newRisks = [...(strategyRisks ?? [])]
                            const newEndDate = new Date(
                                newRisk.start_date ?? ""
                            )
                            newEndDate.setDate(
                                newEndDate.getDate() + (newRisk.duration ?? 0)
                            )
                            newRisks[index] = {
                                ...newRisk,
                                end_date: newEndDate,
                                action:
                                    newRisk.action ?? EDITION_ACTIONS.edited,
                            }
                            updateStrategy({
                                planned_risks: newRisks,
                            })
                        }}
                    />
                </div>
            ))}
        </div>
    )
}

export default Risks
