import { ReactNode, useMemo } from "react"
import { ArrowBottom, CancelIcon } from "../../../icons"
import { DropdownEntry, IDropdownOpt } from "./utils"

export interface ITogglerProps {
    open: boolean
    toggle: () => void
    selected?: Record<string, IDropdownOpt>
    disabled?: boolean
    placeholder?: string
    leadingIcon?: ReactNode
    trailingIcon?: ReactNode
    multi?: boolean
    clearFunction?: () => void
    parsedOptions?: DropdownEntry[]
}
interface IBaseToggler {
    toggle: () => void
    selectedStr: string

    extraClasses?: string

    disabled?: boolean
    leadingIcon?: ReactNode
    trailingIcon?: ReactNode
    selectedCount?: number
}

enum TogglerStyles {
    EMPTY = "border-gray-14 hover:enabled:border-gray-30 bg-white fill-gray-60 text-gray-60",
    SELECTED = "fill-accent bg-accent-light text-accent border-accent-medium hover:border-accent",
    OPEN = "border-accent",
}

const BaseToggler = ({
    toggle,
    selectedStr,
    extraClasses = "",
    disabled,
    leadingIcon,
    trailingIcon = <ArrowBottom />,
    selectedCount,
}: IBaseToggler) => {
    return (
        <button
            tabIndex={0}
            disabled={disabled}
            className={[
                "ddBox",
                "flex flex-row items-center",
                "transition-all duration-75",
                "min-w-[60px] pl-[8px] pr-[2px]",
                "w-fit h-[32px]",
                "border-[1px] rounded-sm",
                "cursor-pointer disabled:cursor-not-allowed",
                "enabled:focus:outline-accent",
                "disabled:bg-gray-5 disabled:text-gray-30 disabled:fill-gray-30",
                extraClasses,
            ].join(" ")}
            onClick={toggle}>
            {/* LEADING ICON */}
            {leadingIcon && (
                <span className="w-5 h-5 shrink-0 grow-0 mr-1 -ml-1">
                    {leadingIcon}
                </span>
            )}
            {/* SELECTED STRING */}
            <span className="truncate label-lg grow whitespace-nowrap">
                {selectedStr}
            </span>
            {/* SELECTED COUNT */}
            {selectedCount != null && (
                <span className="shrink-0 grow-0">({selectedCount})</span>
            )}
            {/* TRAILING ICON */}
            <span className="w-5 h-5 shrink-0 grow-0">{trailingIcon}</span>
        </button>
    )
}

// TODO: Find a better way (to reduce code duplication) of abstracting DefaultToggler
export const LegacyMultiSelectLikeToggler = ({
    open,
    selected,
    disabled,
    toggle,
    placeholder = "",
    leadingIcon,
    trailingIcon = <ArrowBottom />,
    clearFunction,
    parsedOptions,
    multi, // Allways multi
}: ITogglerProps) => {
    if (!multi)
        throw new Error(
            "LegacyMultiSelectLikeToggler: Meant to be used always as multi select!"
        )

    const selectedOpts = useMemo(() => {
        const options = Object.values(selected ?? {})
        if (!options || options.length === 0) return []
        return options
    }, [selected])

    const selectedStr = useMemo(() => {
        if (selectedOpts.length === 0) return placeholder
        if (selectedOpts.length === parsedOptions?.length) return placeholder
        return selectedOpts.map((selectedOpt) => selectedOpt.label).join(", ")
    }, [selectedOpts])

    const selectStateClasses = useMemo(() => {
        const classes = {
            bg: "bg-white",
            border: "border-gray-14",
            fill: "fill-gray-60",
            text: "text-gray-30", // placeholder text
        }

        if (selectedOpts.length > 0) {
            classes.text = "text-gray-90"
        }
        if (open) classes.border = "border-accent"

        return Object.values(classes).join(" ")
    }, [open, selectedOpts])

    const actualTrailingIcon = useMemo<ReactNode>(() => {
        if (selected && Object.keys(selected).length > 0 && clearFunction)
            return (
                <div
                    className={[
                        "w-full h-full fill-gray-60 transition-all",
                        "hover:fill-red",
                    ].join(" ")}
                    onClick={clearFunction}>
                    <CancelIcon />
                </div>
            )

        return trailingIcon
    }, [trailingIcon, clearFunction])

    return (
        <BaseToggler
            toggle={toggle}
            selectedStr={selectedStr}
            extraClasses={selectStateClasses}
            disabled={disabled}
            leadingIcon={leadingIcon}
            trailingIcon={actualTrailingIcon}
            selectedCount={
                selectedOpts.length > 1 &&
                selectedOpts.length !== parsedOptions?.length
                    ? selectedOpts.length
                    : undefined
            }
        />
    )
}

export const DefaultToggler = ({
    open,
    selected,
    disabled,
    toggle,
    placeholder = "",
    leadingIcon,
    trailingIcon = <ArrowBottom />,
    multi,
    clearFunction,
    parsedOptions,
}: ITogglerProps) => {
    const selectedOpts = useMemo(() => {
        const options = Object.values(selected ?? {})
        if (!options || options.length === 0) return []
        return options
    }, [selected])

    const selectedStr = useMemo(() => {
        if (selectedOpts.length === 0) return placeholder
        if (!multi) return selectedOpts[0].label as string
        return placeholder
    }, [selectedOpts])

    const multiSelectClasses = useMemo(() => {
        if (!multi) return ""
        const classes = []
        if (open) classes.push(TogglerStyles.OPEN)
        if (selectedOpts.length > 0) classes.push(TogglerStyles.SELECTED)
        else classes.push(TogglerStyles.EMPTY)
        return classes.join(" ")
    }, [multi, open, selectedOpts])

    const singleSelectClasses = useMemo(() => {
        if (multi) return ""
        return [
            open
                ? "border-accent"
                : "border-gray-14 hover:enabled:border-gray-30",
            "text-lg text-gray-60 bg-white fill-gray-60",
        ].join(" ")
    }, [multi, open, selectedOpts])

    const actualTrailingIcon = useMemo<ReactNode>(() => {
        if (selected && Object.keys(selected).length > 0 && clearFunction)
            return (
                <div
                    className={[
                        "w-full h-full fill-accent-medium transition-all",
                        "hover:fill-accent",
                    ].join(" ")}
                    onClick={clearFunction}>
                    <CancelIcon />
                </div>
            )

        return trailingIcon
    }, [trailingIcon, clearFunction])

    return (
        <BaseToggler
            toggle={toggle}
            selectedStr={selectedStr}
            extraClasses={singleSelectClasses + " " + multiSelectClasses}
            disabled={disabled}
            leadingIcon={leadingIcon}
            trailingIcon={actualTrailingIcon}
            selectedCount={
                selectedOpts.length > 0 && multi
                    ? selectedOpts.length
                    : undefined
            }
        />
    )
}
