function OnboardingCoolSVG() {
    return (
        <svg
            width="645"
            height="324"
            viewBox="0 0 645 324"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 324H644.5L557.001 219.719H522.501L465.637 165.27L298.043 238.923H210.505L0 324Z"
                fill="url(#paint0_linear_5210_6414)"
            />
            <path
                d="M163 258.661H368.145V269.879H135L163 258.661Z"
                fill="url(#paint1_linear_5210_6414)"
            />
            <path
                d="M112.5 279H318.174V289.198H87.0005L112.5 279Z"
                fill="url(#paint2_linear_5210_6414)"
            />
            <path
                d="M63.4995 299H279.039V309.198H38.4995L63.4995 299Z"
                fill="url(#paint3_linear_5210_6414)"
            />
            <path
                d="M416 187H489L500 198.217H390.5L416 187Z"
                fill="url(#paint4_linear_5210_6414)"
            />
            <path
                d="M367.5 208.217H510.5L521 218.499H344L367.5 208.217Z"
                fill="url(#paint5_linear_5210_6414)"
            />
            <path
                d="M602 273H443V283.282H610.5L602 273Z"
                fill="url(#paint6_linear_5210_6414)"
            />
            <path
                d="M583.5 251H423V261.282H592L583.5 251Z"
                fill="url(#paint7_linear_5210_6414)"
            />
            <path
                d="M322 228.5H564L573.5 238.698H298.5L322 228.5Z"
                fill="url(#paint8_linear_5210_6414)"
            />
            <path
                d="M620.5 295H463V305.198H629L620.5 295Z"
                fill="url(#paint9_linear_5210_6414)"
            />
            <path
                opacity="0.5"
                d="M463 323L405.814 276.002H347L297.788 240H51"
                stroke="url(#paint10_linear_5210_6414)"
                strokeWidth="2.25763"
            />
            <path
                d="M171.451 68.2555C167.801 81.8773 153.799 89.961 140.177 86.3111C126.556 82.6611 118.472 68.6596 122.122 55.0378C125.772 41.416 139.773 33.3322 153.395 36.9822C167.017 40.6321 175.101 54.6337 171.451 68.2555Z"
                fill="url(#paint11_linear_5210_6414)"
            />
            <path
                d="M344.583 10.7914H344.036L343.697 10.3625L343.552 10.1781C339.107 4.55402 332.333 1.27335 325.165 1.27335H324.453C310.413 1.27335 298.062 10.5511 294.151 24.0357L293.915 24.8501H293.067H290.844C278.111 24.8501 266.635 32.3544 261.485 43.8866H356.424C366.511 43.8866 373.779 34.2104 370.968 24.5228C368.608 16.3881 361.157 10.7914 352.686 10.7914H344.583Z"
                stroke="url(#paint12_linear_5210_6414)"
                strokeWidth="2.25763"
            />
            <path
                d="M236.507 70.6044H249.97C257.707 70.6044 264.729 75.1275 267.929 82.1716C272.715 92.7103 265.011 104.672 253.436 104.672H99.6847C107.21 87.0729 124.506 75.658 143.647 75.658H151.529L153.505 71.4658C161.115 55.319 177.362 45.0155 195.212 45.0152C212.528 45.0149 228.384 54.7163 236.266 70.1341L236.507 70.6044Z"
                stroke="url(#paint13_linear_5210_6414)"
                strokeWidth="2.25763"
            />
            <path
                opacity="0.5"
                d="M144.871 105L100 162.108"
                stroke="url(#paint14_linear_5210_6414)"
                strokeWidth="2.25763"
            />
            <path
                opacity="0.5"
                d="M184.643 105L140.792 162.108"
                stroke="url(#paint15_linear_5210_6414)"
                strokeWidth="2.25763"
            />
            <path
                opacity="0.5"
                d="M225.435 105L180.564 162.108"
                stroke="url(#paint16_linear_5210_6414)"
                strokeWidth="2.25763"
            />
            <path
                opacity="0.6"
                d="M465.123 124.654V165.01L456.455 156.017H424.768C422.274 156.017 420.252 153.995 420.252 151.502V124.654C420.252 122.16 422.274 120.139 424.768 120.139H460.608C463.102 120.139 465.123 122.16 465.123 124.654Z"
                fill="url(#paint17_linear_5210_6414)"
            />
            <rect
                x="440.648"
                y="126.258"
                width="4.07917"
                height="14.2771"
                fill="#FBFBFC"
            />
            <path
                opacity="0.6"
                d="M390.366 155.52V219.366L376.772 205.263H326.52C322.919 205.263 320 202.344 320 198.743V155.52C320 151.919 322.919 149 326.52 149H383.846C387.447 149 390.366 151.919 390.366 155.52Z"
                fill="url(#paint18_linear_5210_6414)"
            />
            <rect
                x="351.613"
                y="158.178"
                width="7.13855"
                height="22.4354"
                fill="#FBFBFC"
            />
            <rect
                x="351.613"
                y="187.746"
                width="7.13855"
                height="6.11876"
                fill="#FBFBFC"
            />
            <rect
                x="440.648"
                y="144.614"
                width="4.07917"
                height="4.07917"
                fill="#FBFBFC"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_5210_6414"
                    x1="179.134"
                    y1="510.503"
                    x2="359.979"
                    y2="-84.0264"
                    gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor="#00AA94"
                        stopOpacity="0.95"
                    />
                    <stop
                        offset="0.702902"
                        stopColor="#00AA94"
                        stopOpacity="0.0692708"
                    />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_5210_6414"
                    x1="82.0948"
                    y1="244.474"
                    x2="89.1591"
                    y2="300.057"
                    gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor="#FBFBFC"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="1"
                        stopColor="#FBFBFC"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_5210_6414"
                    x1="33.2122"
                    y1="266.103"
                    x2="39.0884"
                    y2="316.767"
                    gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor="#FBFBFC"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="1"
                        stopColor="#FBFBFC"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_5210_6414"
                    x1="-5.92345"
                    y1="286.103"
                    x2="-0.0473177"
                    y2="336.767"
                    gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor="#FBFBFC"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="1"
                        stopColor="#FBFBFC"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_5210_6414"
                    x1="354.114"
                    y1="172.814"
                    x2="363.508"
                    y2="227.684"
                    gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor="#FBFBFC"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="1"
                        stopColor="#FBFBFC"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_5210_6414"
                    x1="304.521"
                    y1="195.213"
                    x2="312.11"
                    y2="245.847"
                    gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor="#FBFBFC"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="1"
                        stopColor="#FBFBFC"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_5210_6414"
                    x1="665.321"
                    y1="259.996"
                    x2="657.731"
                    y2="310.63"
                    gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor="#FBFBFC"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="1"
                        stopColor="#FBFBFC"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_5210_6414"
                    x1="645.321"
                    y1="237.996"
                    x2="637.731"
                    y2="288.63"
                    gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor="#FBFBFC"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="1"
                        stopColor="#FBFBFC"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_5210_6414"
                    x1="238.191"
                    y1="215.603"
                    x2="244.068"
                    y2="266.267"
                    gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor="#FBFBFC"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="1"
                        stopColor="#FBFBFC"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_5210_6414"
                    x1="747.962"
                    y1="282.103"
                    x2="742.086"
                    y2="332.767"
                    gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor="#FBFBFC"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="1"
                        stopColor="#FBFBFC"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_5210_6414"
                    x1="295.632"
                    y1="240"
                    x2="131.888"
                    y2="243.846"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#004957" />
                    <stop
                        offset="0.458333"
                        stopColor="#004957"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_5210_6414"
                    x1="119.356"
                    y1="13.5236"
                    x2="172.842"
                    y2="116.57"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F06000" />
                    <stop
                        offset="1"
                        stopColor="#F06000"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_5210_6414"
                    x1="318.941"
                    y1="-3.30711"
                    x2="318.941"
                    y2="45.0158"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#004957" />
                    <stop
                        offset="1"
                        stopColor="#004957"
                        stopOpacity="0.48"
                    />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_5210_6414"
                    x1="188.917"
                    y1="40.4256"
                    x2="188.917"
                    y2="104.673"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#004957" />
                    <stop
                        offset="1"
                        stopColor="#004957"
                        stopOpacity="0.48"
                    />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_5210_6414"
                    x1="122.435"
                    y1="105"
                    x2="122.435"
                    y2="162.108"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0098B3" />
                    <stop
                        offset="1"
                        stopColor="#0098B3"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_5210_6414"
                    x1="162.718"
                    y1="105"
                    x2="162.718"
                    y2="162.108"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0098B3" />
                    <stop
                        offset="1"
                        stopColor="#0098B3"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_5210_6414"
                    x1="203"
                    y1="105"
                    x2="203"
                    y2="162.108"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0098B3" />
                    <stop
                        offset="1"
                        stopColor="#0098B3"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint17_linear_5210_6414"
                    x1="456.455"
                    y1="145.633"
                    x2="418.213"
                    y2="97.7032"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#004957" />
                    <stop
                        offset="1"
                        stopColor="#004957"
                        stopOpacity="0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint18_linear_5210_6414"
                    x1="376.772"
                    y1="188.981"
                    x2="316.802"
                    y2="113.817"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#004957" />
                    <stop
                        offset="1"
                        stopColor="#004957"
                        stopOpacity="0"
                    />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default OnboardingCoolSVG
