import { useTranslate } from "@tolgee/react"
import { ComputerSettingsCoolSVG } from "../components"

function AccountNotOnboarded() {
    const { t } = useTranslate()

    return (
        <div className="w-full grow flex flex-row items-center justify-center">
            <div className="flex flex-col items-center -mt-8">
                <ComputerSettingsCoolSVG />
                <h1 className="title-lg mt-14">
                    {t(
                        "yourAccountIsBeingConfigured",
                        "Your account is being configured"
                    )}
                </h1>
                <p className="body-lg w-80 text-center text-gray-60 mt-3">
                    {t(
                        "accountBeingConfiguredInstructions",
                        "Please try again once your account has been set up by your admin."
                    )}
                </p>
            </div>
        </div>
    )
}

export default AccountNotOnboarded
